import React, { useEffect, useState } from "react";
import CheckBox from './../../../../components/CheckBox/CheckBox';
import { addEmailPreferences, overlayLoader } from '../../../../store/actions/Settings';
import { connect } from 'react-redux';


function EmailPreferences(props) {

  const { preferences, addEmailPreferences, savePref, overlayLoader } = props

  let [mailNotification, setMailNotification] = React.useState({
    "login": false,
    "deposit": false,
    "withdraw": false,
    "favProperty": false,
    "newProperty": false
  })

  useEffect(() => {
    if(preferences){
      let x =preferences.email
      setMailNotification({...x})
    }
  }, []);

  const handelNotification = (event) => {
    const targated = event.target.name.replace(/ /g, "");
    let oldmailNotification = mailNotification
    oldmailNotification[targated] = event.target.checked
    setMailNotification({...mailNotification,[targated]:event.target.checked})
  }

  const saveEmailPref = () => {
    overlayLoader({status:true, loadingMessage:'Saving your Email Preferences.'})
    addEmailPreferences(mailNotification);
    savePref()
  }

  return (
    <div className="investor-perfrences">
      <h5>Email Preferences</h5>
      <div className="preferences">
        <div className="investor-interests">
          <div className="d-flex flex-wrap">
            {
              Object.entries(mailNotification).map((value,index)=>{
                return(
                  <CheckBox key={index} name={value[0].replace(/([a-z])([A-Z])/, '$1 $2')} checked={value[1]} handelCheckBox={handelNotification} />
                )
              })
            }
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <button className="custom-btn" type="button" onClick={() => saveEmailPref()}>Save</button>
        <button className="custom-btn" type="button" onClick={() => savePref()}>Cancel</button>
      </div>
    </div>

  );
}

const mapStateToProps = (state) => {
  return {
    preferences: state.Settings.preferences || {},
  }
}
const mapDispatchToProps= {addEmailPreferences, overlayLoader};

export default connect(mapStateToProps, mapDispatchToProps)(EmailPreferences)

var initialState = {
    profile: "",
    scanQR: "",
    preferences: "",
    gaLoading: false,
    showGAModal: false,
    showSmsModal: false,
    showVerificationModal: false,
    showOtpLoader: false,
    kycMessage: "",
    allNotifications: "",
    groupNotifications: "",
    favouritees: "",
    userDocs: "",
    overlayLoading: false,
    loadingMessage: 'Loading',
    withdrawAuth: false,
    mediaList: []
};
const Settings = (state = initialState, { type, payload }) => {
    switch (type) {
        case "RESET_SETTINGS":
            return initialState

        case "GET_PROFILE_RESPONSE":
            return {
                ...state,
                profile: payload
            };
        case "GET_ALL_MEDIA":
            return {
                ...state,
                mediaList: payload
            };
        case "GET_PREFERENCES":
            return {
                ...state,
                preferences: payload
            }

        case "GOOGLE_AUTHENTICATION_RESPONSE":
            return {
                ...state,
                scanQR: payload.qrData
            };

        case "SHOW_GA_MODAL":
            return {
                ...state,
                showGAModal: payload
            };

        case "OTP_LOADER":
            return {
                ...state,
                showOtpLoader: payload
            };

        case 'OVERLAY_LOADER':
            return {
                ...state,
                overlayLoading: payload.status,
                error: undefined,
                loadingMessage: payload.loadingMessage,
            }

        case 'WITHDRAW_AUTHENTICATED':
            return {
                ...state,
                withdrawAuth: payload.status,
            }

        case "GA_LOADER":
            return {
                ...state,
                gaLoading: payload
            };

        case "SMS_MODAL":
            return {
                ...state,
                showSmsModal: payload
            };

        case "SEND_SMS_RESPONSE":
            return {
                ...state,
                showSmsModal: payload,
            };

        case "VERIFICATION_MODAL":
            return {
                ...state,
                showVerificationModal: payload
            };

        case "VERIFY_TWO_FACTOR_RESPONSE":
            return {
                ...state,
                gaLoading: false
            };

        case "ALL_NOTIFICATIONS":
            return {
                ...state,
                allNotifications: payload
            };

        case "GROUP_NOTIFICATIONS":
            return {
                ...state,
                groupNotifications: payload
            };

        case "FAVOURITEES":
            return {
                ...state,
                favouritees: payload
            };

        case "USER_DOCS":
            return {
                ...state,
                userDocs: payload
            };

        default:
            return state;
    }
};
export default Settings;
import React, { Component } from 'react'
import { connect } from "react-redux";
import NumberFormat from 'react-number-format';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { favouriteProperties } from "../../../store/actions/Auth";
import { favourite, overlayLoader } from "../../../store/actions/Settings";
import { Link } from 'react-router-dom';
import DashboardLayout from "./../DashboardLayout";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

class Favorites extends Component {

  state = {
    sortBy: "",
    count: 1,
    favourites: this.props.favourites
  }

  componentWillReceiveProps(nextProps) {
    // Any time props.email changes, update state.
    if (nextProps.favourites !== this.props.favourites) {
      this.setState({
        favourites: nextProps.favourites
      });
    }
  }

  handelTab = (activeNo, type) => {
    let favourites = []
    if (type != "all") {
      favourites = this.props.favourites.filter(item => item.categories == type)
    } else {
      favourites = this.props.favourites
    }
    this.setState({
      count: activeNo,
      favourites,
    })
  }

  componentDidMount() {
    this.props.favouriteProperties({ status: "launched" })
    this.props.overlayLoader({status:true, loadingMessage:'Loading Your Favourite Properties'})
  }

  favourite = (id) => {
    this.props.favourite({ payload: id, onFavourites: false })
  }

  render() {

    const { handelTab } = this
    const { sortBy, count, favourites } = this.state

    return (
      <>
        <DashboardLayout active={"favorites"} {...this.props}>
          <section className="favorites-wrap">
            <div className="container">
              <div className="favorites">
                <div className="type-tabs">
                  <h5 className="title">Property Type</h5>
                  <div className={`type  ${count == 1 ? ' active' : ""}`} onClick={() => handelTab(1, 'all')}>
                    <h6>All</h6>
                    <figure>
                      <img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/all-icon.png" />
                    </figure>
                    <CheckIcon />
                  </div>
                  <div className={`type  ${count == 2 ? ' active' : ""}`} onClick={() => handelTab(2, 'residential')}>
                    <h6>residential</h6>
                    <figure>
                      <img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/residential-icon.png" />
                    </figure>
                    <CheckIcon />
                  </div>
                  <div className={`type  ${count == 3 ? ' active' : ""}`} onClick={() => handelTab(3, 'multifamily')}>
                    <h6>multifamily</h6>
                    <figure>
                      <img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/multifamily-icon.png" />
                    </figure>
                    <CheckIcon />
                  </div>
                  <div className={`type  ${count == 4 ? ' active' : ""}`} onClick={() => handelTab(4, 'mixedUse')}>
                    <h6>mixedUse</h6>
                    <figure>
                      <img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/mixedUse-icon.png" />
                    </figure>
                    <CheckIcon />
                  </div>
                  <div className={`type  ${count == 5 ? ' active' : ""}`} onClick={() => handelTab(5, 'Office')}>
                    <h6>office</h6>
                    <figure>
                      <img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/office-icon.png" />
                    </figure>
                    <CheckIcon />
                  </div>
                  <div className={`type  ${count == 6 ? ' active' : ""}`} onClick={() => handelTab(6, 'warehouse')}>
                    <h6>warehouse</h6>
                    <figure>
                      <img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/warehouse-icon.png" />
                    </figure>
                    <CheckIcon />
                  </div>
                  <div className={`type  ${count == 7 ? ' active' : ""}`} onClick={() => handelTab(7, 'industrial')}>
                    <h6>industrial</h6>
                    <figure>
                      <img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/industrial-icon.png" />
                    </figure>
                    <CheckIcon />
                  </div>
                </div>
                <div className="cards-list">
                  <div className="d-flex justify-content-between">
                    <h5 className="title">Favorite Properties</h5>
                    {/*
                      <div>
                          <h5 className="title d-inline-block mt-2 mr-3 mb-0">Sort by</h5>
                          <FormControl variant="outlined" className={`select ${classes.formControl}`}>
                              <InputLabel id="demo-simple-select-outlined-label">
                              Price
                              </InputLabel>
                              <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              value={sortBy}
                              type="text"
                              name="sortBy"
                              onChange={handleChange}
                              // labelWidth={110}
                              >
                              <MenuItem value="1000">1,000</MenuItem>
                              </Select>
                          </FormControl>
                      </div>
                    */}
                  </div>
                  {favourites.length > 0 ? (
                    favourites.map(item => {
                      return (
                        <div className="fav-card">
                          <figure className="fav-img">
                            <img src={item.banner.location} alt={item.banner.name} />
                          </figure>
                          <div className="fav-content">
                            <div>
                              <h4 className="fav-title">{item.title}</h4>
                              <p className="fav-subtitle">{item.location}, {item.state}</p>
                              <h5 className="fav-title2">{item.tagetedROR}% IRR over 10 years</h5>
                              {/* <p className="fav-subtitle2"><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/available-icon.png")} /> Tokens Available: {item['availableTokens']}</p> */}
                              <span className="g-color">{item['offeringStage']}</span>
                            </div>
                            <div className="pt-4">
                              <div className=" detail">
                                <div className="m-0">
                                  <img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/units-icon.png" />
                                  <span>Units</span>
                                  <span>{item.units}</span>
                                </div>
                              </div>
                              <div className=" detail">
                                <div className=" m-0">
                                  <img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/debt-icon.png" />
                                  <span>Debt</span>
                                  <span>{item.deptSwap}%</span>
                                </div>
                              </div>
                              <div className="detail">
                                <div className="m-0">
                                  <img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/noi-icon.png" />
                                  <span>NOI</span>
                                  <NumberFormat value={item.noiPercent} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <span>{value}</span>} />
                                </div>
                              </div>
                              <Link to={`/single-property?id=${item._id}`} className="custom-btn">Details</Link>
                            </div>
                            <FavoriteIcon onClick={() => this.favourite(item._id)} className="heart-icon" />
                          </div>
                        </div>
                      )
                    })
                  ) :
                    <div className="fav-card">
                      <div className="fav-content">
                        <div className={'col-12 text-center mt-5'}>
                          <img
                            src="https://tknismtest.s3.amazonaws.com/non-compressed-images/47.png"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </section>
        </DashboardLayout>
      </>
    )
  }
}

const mapStateToProps = ({ Auth }) => {
  let { favourites } = Auth
  return { favourites: favourites ? favourites : [] }
}


const mapDispatchToProps = {
  favouriteProperties,
  favourite,
  overlayLoader
}

export default connect(mapStateToProps, mapDispatchToProps)(Favorites)

import { connect } from "react-redux";
import React, { Component } from 'react';
import EventBus from 'eventing-bus';
import {Modal, ModalBody} from 'reactstrap';
import DashboardLayout from "./../DashboardLayout";
import { userDashboard, handleDwollaModal } from "../../../store/actions/Auth";
import Balance from './Balance';
import Deposit from './Deposit';
import DWHistory from './DWHistory';
import { withRouter } from 'react-router-dom';
import Withdrawal from './Withdrawal';
import $ from 'jquery'

class Wallet extends Component
{
  constructor()
  {
    super()
    this.state = { activeTab: "Balance", activeDWHistory:'deposite', dwollaVerificationModal:false }
  }

  setActiveTab = (activeTab)=> this.setState({ activeTab })

  renderComponent = (state) =>
  {
    if (state.activeTab === "Balance")
    return <Balance />
    else if (state.activeTab === "Deposit")
    return <Deposit />
    else if (state.activeTab === "Withdraw")
    return <Withdrawal />
    else if (state.activeTab === "DWHistory")
    return <DWHistory active={this.state.activeDWHistory}/>
  }

  componentDidMount () {
    const { step } = this.props.dashHeadData
    EventBus.on("paymentDeposited", ()=> this.setState({activeTab: 'DWHistory'}));
    EventBus.on("paymentWithdrawed", ()=> this.setState({activeTab: 'DWHistory', activeDWHistory: 'withdraw'}));
    EventBus.on("withDrawRequested", ()=> this.setState({activeTab: 'DWHistory', activeDWHistory: 'wires'}));
    $('.scroll-to-top').on('click',function(){
      $('html, body').animate({
        scrollTop: 0
      }, 1500);
    })
    if(this.props.location.state)
    this.setState({ activeTab: this.props.location.state.forPage })
    // if (step != undefined && step == 1 || step == 2 && this.props.location.pathname == '/dashboard/wallet') {
    //   this.props.history.push("/dashboard")
    // }
  }

  dwollaVerificationModal=()=>{
    this.setState({dwollaVerificationModal: !this.state.dwollaVerificationModal})
  }

  toggle = e => {
    const { handleDwollaModal, dwollaModal } = this.props;
    handleDwollaModal(!dwollaModal);
  };

  updateProfile=()=>{
    this.props.handleDwollaModal(false)
    this.props.history.push({
      pathname: "/dashboard/settings",
      state: { dwollaVerification: true }
    })
    // EventBus.publish("toProfile", "dwollaVerification");
  }

  render() {
    let { dwollaVerification, dwollaReason } = this.props.dashHeadData;
    let { activeTab } = this.state
    return (
      <div className="page-wrapper">
        <DashboardLayout active='wallet' {...this.props}>
          <section className="tabs-wallet-sec">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item"><a id="balance" onClick={() => this.setActiveTab("Balance")} className={activeTab === 'Balance' ? "nav-link active" : "nav-link"} > Balance</a></li>
                    <li className="nav-item"><a id="deposit" onClick={() => this.setActiveTab("Deposit")} className={activeTab === 'Deposit' ? "nav-link active" : "nav-link"} > Deposit</a ></li>
                    <li className="nav-item"><a id="withdraw" onClick={() => this.setActiveTab("Withdraw")} className={activeTab === 'Withdraw' ? "nav-link active" : "nav-link"} > Withdraw</a ></li>
                    {/*
                      <li className="nav-item"><a id="withdraw" onClick={() => dwollaVerification == "Active" ? this.setActiveTab("Withdraw") : this.props.handleDwollaModal(true)}
                      className={activeTab === 'Withdraw' ? "nav-link active" : "nav-link"} > Withdraw</a></li>
                      */}
                      <li className="nav-item"><a id="DWHistory" onClick={() => this.setActiveTab("DWHistory")} className={activeTab === 'DWHistory' ? "nav-link active" : "nav-link"} > History</a></li>
                      {/* <li className="nav-item"><a className={activeTab === 'Distribution' ? "nav-link active" : "nav-link"} > Distribution History</a></li> */}
                    </ul>
                    {this.renderComponent(this.state)}
                  </div>
                </div>
              </div>
              <Modal
                modalClassName="modal-mini modal-info"
                toggle={this.toggle}
                isOpen={this.props.dwollaModal}
                >
                <div className="modal-header justify-content-center">
                  <div className="modal-profile">
                    <img className='modalLogoImg' src="https://tknismtest.s3.amazonaws.com/non-compressed-images/favicon.png.png" alt="" />
                  </div>
                </div>
                <ModalBody>
                  <h6>Your account verification has failed.</h6>
                  {dwollaReason ? <div className="verificationDescription"><span>Reason:</span> {dwollaReason}</div> : ""}
                  {dwollaVerification == "Deactive" ? '' : <button className="custom-btn" onClick={this.updateProfile}>Update</button>}
                </ModalBody>
              </Modal>
            </section>
          </DashboardLayout>
          {/* <div className="scroll-to-top scroll-to-target" ><i className="fa fa-arrow-up" aria-hidden="true"></i></div> */}
        </div>
      );
    }
  }

  const mapDispatchToProps = { userDashboard, handleDwollaModal };

  const mapStateToProps = (state) => {
    return {
      dashHeadData: state.Auth.dashHeadData || {} ,
      dwollaModal: state.Auth.dwollaModal,
    }
  }
  export default connect(mapStateToProps, mapDispatchToProps)(Wallet);

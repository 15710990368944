import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import NumberFormat from 'react-number-format'
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';

import Loader from "../../../../components/AppLoader"
import { getWalletData, getWalletDataResponse, getBalance } from "../../../../store/actions/Auth"
import { overlayLoader } from "../../../../store/actions/Settings";

class Balance extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page:0,
      rowsPerPage:10,
      showBalance: false,
     }
    props.getBalance()
    props.overlayLoader({status:true, loadingMessage:'Getting Your Balance'})
  }

  handleChangePage = (event, newPage) => {
    this.setState({page:newPage})
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage : parseInt(event.target.value, 10) });
    this.setState({page:0})
  }


  render() {
    let classes = {}, EthBalance =  [];
    let { rowsPerPage, page } = this.state;
    EthBalance =  this.props.balance.filter((item) => item['name'] == 'Ethereum'  )
    let balance =  this.props.balance.filter((item) => item['name'] !== 'Ethereum'  )

    return (
      <div className="table-page p-0 pt-3">
        <div className="shadow-none activity-tabel MuiTableContainer-root">
        <div className="header-table">
          <h6>Ethereum Balance</h6>
          <div className="icon">
            <i className="fab fa-ethereum"></i>
            <span>{EthBalance.length > 0 && EthBalance[0]['balance']} ETH</span>
          </div>
        </div>
          <div className="scroll-div">
             <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Symbol</TableCell>
                <TableCell align="center">Name</TableCell>
                <TableCell align="center">Balance</TableCell>
                <TableCell align="center">In Hold</TableCell>
                <TableCell align="center">Total</TableCell>
              </TableRow>
            </TableHead >
            <TableBody>
              {(rowsPerPage > 0
                ? balance.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : balance
              ).map((item) => {
                return <TableRow key={item['balance']}>
                  <TableCell align="center">
                    <Link to={item['tokenId'] ? `/single-property?id=${item['tokenId']['propertyId']}` : '#'} style={{color: 'rgba(0, 0, 0, 0.87)'}}>
                      <img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/49.png" alt="" />
                      {item['symbol']}
                    </Link>
                  </TableCell>
                  <TableCell align="center">{item['name']}</TableCell>
                  <TableCell align="center"><NumberFormat value={item['balance']} displayType={'text'} thousandSeparator={true} /></TableCell>
                  <TableCell align="center"><NumberFormat value={item['inOrder']} displayType={'text'} thousandSeparator={true} /></TableCell>
                  <TableCell align="center"><NumberFormat value={Number(item['balance']) + Number(item['inOrder'])} displayType={'text'} thousandSeparator={true} /></TableCell>
                </TableRow>
              })}
            </TableBody>
          </Table>
              <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                  colSpan={3}
                  count={balance.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  ActionsComponent={this.TablePaginationActions}
                />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = { getWalletData, getWalletDataResponse, getBalance, overlayLoader };

const mapStateToProps = (state) => {
  return { balance: state.Auth.walletBalance ? state.Auth.walletBalance : [] }
}
export default connect(mapStateToProps, mapDispatchToProps)(Balance);

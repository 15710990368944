import React, { Component } from 'react';
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import Banner from '../../components/Banner'


class PrivacyPolicy extends Component {
    state = {  }
    render() { 
        return ( 
            <>
                <Navbar page='Privacy Policy' {...this.props}/>
                <Banner
                    heading = 'Privacy Policy'
                />
                <div className="agreements-wrapper">
                    <div className="container">
                        <div className="agreements">
                            <p>Last Revised: 6/13/2020</p>  
                            <p>
                                Tokenism LLC (collectively with its subsidiaries, “Tokenism,” “Company,” “we,” “us,” and “our,”) respects
                                your privacy and is committed to protecting your privacy through our compliance with this Website Privacy
                                Policy (the “Policy”). This Policy should be read in conjunction with our Website Terms of Use, into which
                                this Policy is incorporated by reference. 
                            </p>
                            <p>This Policy describes:</p>
                            <ul className="dots">
                                <li>
                                    <p>
                                        The types of information we collect from you or that you may provide when you visit our website
                                        available at: <a href="https://www.tknism.io/">https://www.tknism.io/</a>, as well as any websites and blogs directly owned by
                                        Tokenism (collectively, our “Website”). 
                                    </p>
                                </li>
                                <li>
                                    <p>
                                    Our practices for collecting, using, maintaining, protecting, and disclosing that information. 
                                    </p>
                                </li>
                            </ul>
                            <p>
                                Please read this Policy carefully to understand our practices regarding your information and how we will
                                treat it. If you do not agree with our policies and practices, then please do not use our Website. By using
                                our Website, you agree to the terms of this Policy. This Policy may change from time to time (see below,
                                “Changes to this Policy”). Your continued use of our Website after we make changes is deemed to be
                                acceptance of those changes, so please check the Policy periodically for updates. 
                            </p>
                            <h3>What We Collect and How We Collect It</h3>
                            <p>
                                To ensure that we provide you with the best possible experience, we will store, use, and share
                                information about you in accordance with this Policy. 
                            </p>
                            <p><i>Information You Provide to Us</i></p>
                            <p>
                                Personal Information is any information that can be used to individually identify you from a larger group,
                                such as data including, but not limited to, your:
                            </p>
                            <ul className="dots">
                                <li>first and last name</li>
                                <li>email address</li>
                                <li>telephone number</li>
                                <li>government-issued identification numbers</li>
                                <li>investment experience and preferences</li>
                                <li>country of citizenship</li>
                                <li>financial account information</li>
                                <li>transaction information</li>
                                <li>date of birth</li>
                                <li>social security number</li>
                                <li>mailing address</li>
                                <li>comments or messages provided in free text boxes</li>
                            </ul>
                            <p>You may provide us Personal Information when you: </p>
                            <ul className="dots">
                             <li>request information</li>
                            <li>request online verification of your identity</li>
                            <li>subscribe to our emails</li>
                            <li>make an investment</li>
                            <li>identify property for sale</li>
                            <li>register yourself with the Website.</li>
                            </ul> 
                            <p>
                                The information that you provide in each case will vary. In some cases, we may ask you to create a
                                username and password that should only be known to you. 
                            </p>
                            <p>
                                If you request that we verify your accredited investor status through your professional or financial advisor,
                                then that party may provide us with information about you, such as whether you meet legal criteria to
                                qualify as an accredited investor. We may also receive Personal Information from third-party brokerdealers or other financial professionals. Professional or financial advisors also provide us with information
                                about themselves, such as their contact information and licensure information. 
                            </p>
                            <p>
                                <span>Important Notice About Consumer Information.</span> Tokenism may collect Personal Information, with your
                                    consent, to be used by Tokenism’s trusted third-party partners to complete background checks as ordered
                                    by Tokenism customers. Tokenism is not a “consumer reporting agency” or “reseller” as those terms are
                                    defined under the Fair Credit Reporting Act (“FCRA”). Tokenism does not process, assemble, or merge
                                    any Personal Information to create or modify any consumer report. Furthermore, Tokenism plays no role
                                    in any eligibility determination based on the Personal Information processed as part of the identity
                                    verification process. Accordingly, the FCRA does not apply to Tokenism’s processing of Personal
                                    Information as described in this Policy. Regardless, your Personal Information will be processed by
                                    Tokenism in accordance with this Policy unless otherwise agreed in writing. 
                            </p>
                            <h3>Automated Information Collection</h3>
                            <p>
                                In addition to the information that you provide to us, we may also collect information about you during your
                                visit to our Website. We collect this information using automated tools that are detailed below. These tools
                                may collect information about your behavior and your computer system, such as your internet address (IP
                                Address), the pages you have viewed, and the actions you have taken while using the Website. Some of
                                the tools we use to automatically collect information about you may include:
                            </p>
                            <p>
                                (a) Cookies. A “cookie” is a small data file transmitted from a website to your device’s hard drive.
                                Cookies are usually defined in one of two ways, and we may use both of them: 
                            </p>
                            <ul>
                                <li>
                                    <p>session cookies, which do not stay on your device after you close your browser, and</p>
                                </li>
                                <li>
                                    <p>persistent cookies, which remain on your device until you delete them or they expire. </p>
                                    <p>We use the following categories of cookies on our Website.</p>
                                    <ul>
                                        <li>
                                            <p>
                                                <span>Strictly Necessary Cookies.</span> These cookies are essential in order to enable
                                                you to move around the Website and use its features. Without these cookies,
                                                services you have requested, such as maintaining a record of your purchased
                                                items (e.g. a shopping cart), cannot be provided.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <span>Performance Cookies.</span> These cookies collect anonymous information on how
                                                people use our Website to help us understand how you arrive at our site, browse
                                                or use our Website and highlight areas where we can improve, such as
                                                navigation. The data stored by these cookies never shows personal details from
                                                which your individual identity can be established.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <span>Functionality Cookies.</span> These cookies remember choices you make such as
                                                the country from which you visit our Website, your preferred language, and your
                                                search parameters. This information can then be used to provide you with an
                                                experience more appropriate to your selections and to make your visits to our
                                                Website more tailored to your preferences. The information in these cookies may 
                                                be anonymized. These cookies cannot track your browsing activity on other
                                                websites.
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                <span>Targeting Cookies or Advertising Cookies.</span> These cookies collect information
                                                about your browsing habits in order to make advertising more relevant to you and
                                                your interests. They are also used to limit the number of times you see an
                                                advertisement as well as help measure the effectiveness of an advertising
                                                campaign. The cookies are usually placed by third-party advertising networks.
                                                These cookies remember the websites you visit and that information is shared
                                                with other parties such as advertisers. 
                                            </p>
                                        </li>
                                    </ul>
                                    <p>
                                        Of course, if you do not wish to have cookies on your devices, you may turn them off at any time
                                        by modifying your internet browser’s settings. However, by disabling cookies on your device, you
                                        may be prohibited from full use of the Website’s features or lose access to some functionality.
                                    </p>
                                </li>
                                <li>
                                  <p>
                                    <span>Google Analytics.</span> The Website sends aggregated, non-Personal Information to Google Analytics
                                    for the purpose of providing us with the ability to conduct technical and statistical analysis on the
                                    Website’s performance. For more information on how Google Analytics supports the Website and
                                    uses information sent from the Website, please review Google’s privacy policy available at
                                    <a href="https://policies.google.com/technologies/partner-sites">https://policies.google.com/technologies/partner-sites</a>.
                                  </p>
                                </li>  
                                <li>
                                    <p>
                                        <span>Web Beacons.</span> A Web Beacon is an electronic image. Web Beacons can track certain things
                                        from your computer and can report activity back to a web server allowing us to understand some
                                        of your behavior. If you choose to receive emails from us, we may use Web Beacons to track
                                        your reaction to our emails. We may also use them to track if you click on the links and at what
                                        time and date you do so. Some of our third-party marketing partners may use Web Beacons to
                                        track your interaction with online advertising banners on our Website. This information is only
                                        collected  in aggregate form and will not be linked to your Personal Information. Please note that
                                        any image file on a webpage can act as a Web Beacon.
                                    </p>
                                </li> 
                                <li>
                                    <p>
                                        <span>Embedded Web Links.</span> Links provided in our emails and, in some cases, on third-party websites
                                        may include tracking technology embedded in the link. The tracking is accomplished through a
                                        redirection system. The redirection system allows us to understand how the link is being used by
                                        email recipients. Some of these links will enable us to identify that you have personally clicked on
                                        the link and this may be attached to the Personal Information that we hold about you. This data is
                                        used to improve our service to you and to help us understand the performance of our marketing
                                        campaigns.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <span>Third-party Websites and Services.</span> We work with a number of service providers of marketing
                                        communications technology. These service providers may use various data collection methods to
                                        improve the performance of the marketing campaigns we are contracting them to provide. The
                                        information collected can be gathered on our Website and also on the websites where our
                                        marketing communications are appearing. For example, we may collect data where our banner
                                        advertisements are displayed on third-party websites. 
                                    </p>
                                </li>
                            </ul>
                            <h3>Your Choices and Selecting Your Privacy Preferences</h3>
                            <p>We want to provide you with relevant information that you have requested.</p>
                            <p>
                                If we provide subscription-based services, such as email newsletters, we will allow you to make choices
                                about what information you provide at the point of information collection or at any time after you have
                                received a communication from us while you are subscribed. Transactional or service-oriented messages 
                                are usually excluded from such preferences, as such messages are required to respond to your requests
                                or to provide goods and services, and are not intended for the purposes of marketing.
                            </p>
                            <p>
                                We will not intentionally send you email newsletters and marketing emails unless you consent to receive
                                such marketing information. After you request to receive these emails, you may opt out of them at any time
                                by selecting the “unsubscribe” link at the bottom of each email. Please note that by opting out or
                                unsubscribing you may affect other services you have requested we provide to you, in which email
                                communication is a requirement of the service provided. 
                            </p>
                            <p>
                                <span>Text Messaging.</span> You may have the opportunity to receive SMS or "text" messages, pre-recorded voice
                                messages or auto-dialed phone calls from Tokenism, its affiliates and related entities as well as third parties.
                                Such messaging may be used to authenticate your identity or mobile device, as well as provide you
                                informational updates about services or products you may have requested. In providing your mobile device
                                number or cell phone number to Tokenism, you knowingly consent to such communications from Tokenism
                                or for Tokenism to use your cell phone number or mobile device number in accordance with Tokenism’s
                                Terms of Use. In providing your number, you represent that you have the authority to agree to receive text
                                messages at the telephone number that you provide to Tokenism, or from which you sent the text message
                                request to us. You further acknowledge that no purchase is required to opt into this service, and you may
                                opt out at any time by following instructions from Tokenism and as described in the Terms of Use.
                            </p>
                            <p>
                                To discontinue receiving SMS messages from Tokenism at any time, reply STOP or email STOP to
                                unsubscribe@tknism.io or call +1 844 2TKNISM For help, reply HELP or text HELP to
                                unsubscribe@tknism.io or call +1 844 2TKNISM. 
                            </p>
                            <p>
                                Any such communications you receive from us will be administered in accordance with your preferences
                                and this Policy. 
                            </p>
                            <h3>Accuracy and Access to Your Personal Information</h3>
                            <p>
                                We strive to maintain and process your information accurately. We have processes in place to maintain all
                                of our information in accordance with relevant data governance frameworks and legal requirements. We
                                employ technologies designed to help us maintain information accuracy on input and processing. 
                            </p>
                            <p>
                                Where we can provide you access to your Personal Information in our possession, we will always ask you
                                for a username and password to help protect your privacy and security. We recommend that you keep
                                your password safe, that you change it periodically, and that you do not disclose it to any other person or
                                allow any other person to use it. 
                            </p>
                            <p>
                                To view and change the Personal Information that you have provided to us, you can log in to your
                                account and follow the instructions on that webpage, or contact us directly for assistance.
                            </p>
                            <h3>Information of Minors</h3>
                            <p>
                                We do not intentionally seek to gather information from individuals under the age of eighteen. We do not
                                target the Website to minors, and would not expect them to be engaging with our Website or services. We
                                encourage parents and guardians to provide adequate protection measures to prevent minors from
                                providing information unwillingly on the internet. If we are aware of any Personal Information that we have
                                collected about minors, we will take steps to securely remove it from our systems. 
                            </p>
                            <h3>How We Use Your Information</h3>
                            <p>
                                The information we gather and that you provide is collected to provide you information and the services
                                you request, in addition to various other purposes, including, but not limited to: 
                            </p>
                            <ul className="dots">
                                <li>Assisting you with items such as personalized experiences, facilitation of product usage, and enforcement of Terms of Use. </li>
                                <li> Managing identity verification of, and performing, KYC and AML checks on users</li>
                                <li>Managing accreditation verification of our investors.</li>
                                <li>Preventing malicious activity and providing you with a secure experience.</li>
                                <li>Providing service and support for services you request.</li>
                                <li>Providing marketing communications that are effective and optimized for you.</li>
                                <li>Keeping you up-to-date with the latest benefits available from us.</li>
                                <li>Preventing unwanted messages or content.</li>
                                <li>Measuring the performance of our marketing programs.</li>
                                <li>Contacting you about services and offers that are relevant to you. </li>
                            </ul>
                            <h3>How We Share Your Information</h3>
                            <p>
                                We do not sell or lease your information to any third party. We may disclose your Personal Information to
                                our trusted third-party business partners in accordance with this Policy. We work with a number of partners
                                that help us process your requests, deliver customer service and support, send email marketing
                                communications, and provide experiences that you have come to expect from us. We will share your
                                Personal Information with these third parties in order to fulfill the service that they provide to us. These
                                third-party partners are under contract to keep your Personal Information secure and not to use it for any
                                reason other than to fulfill the service we have requested from them. 
                            </p>
                            <p>
                                Your use of the Website constitutes acceptance of Plaid’s Privacy Policy. Plaid is one of our trusted thirdparty service providers that facilitates the transfer of your Personal Information to and from financial
                                institutions. In using the Website, you expressly authorize and grant to Plaid the right, power, and authority
                                to act on your behalf to access and transmit your information as reasonably necessary for Plaid to provide
                                services to you on Tokenism’s behalf. 
                            </p>
                            <p>
                                We may share certain information with financial institutions and our other partners to provide the service
                                features that you elect to use. For example, if you provide your online login information for financial
                                accounts to us, we may disclose that information to your financial institution or its intermediary to establish
                                a connection between your financial account and your Website account, and thereby obtain access to your
                                financial records. Similarly, if you provide any credit card information or bank account information to pay
                                for services available through Tokenism, that information will be processed by a trusted third-party, PCIDSS compliant payment processor. 
                            </p>
                            <p>
                                Except as described in this Policy, we will not share your information with third parties without your notice
                                and consent, unless it is under one of the following circumstances: 
                            </p>
                            <ul>
                                <li>Responding to duly authorized information requests from law enforcement or other governmental authorities.</li>
                                <li>Complying with any law, regulations, subpoena, or court order.</li>
                                <li>Investigating and helping prevent security threats, fraud, or other malicious activity.</li>
                                <li>Enforcing or protecting the rights and properties of Tokenism or its subsidiaries.</li>
                                <li>Protecting the rights or personal safety of Tokenism’s employees. </li>
                            </ul>
                            <p>
                                <span>Broker-Dealer.</span> Certain investment opportunities that are available through the Website may be offered by
                                a broker-dealer, which may be an affiliate of Tokenism or an unaffiliated third-party. Broker-dealers are
                                securities professionals that are registered with the US Securities and Exchange Commission and are
                                members of FINRA. We may disclose Personal Information to broker-dealers in connection with their
                                offerings. Where an offering is made by a broker-dealer and you seek to participate in that offering, we are
                                required to disclose Personal Information to that broker-dealer to comply with applicable US laws. The
                                broker-dealer’s use of Personal Information is subject to its Privacy Policy. The offering documents will
                                disclose the broker-dealer associated with an offering before you make an investment through the Website.
                            </p>
                            <p>
                                <span>Issuers.</span> We may disclose Personal Information to the issuer of an investment in which you have signed a
                                subscription agreement or otherwise invested. For example, when you make an investment with an issuer,
                                we may disclose information such as name and contact details, Social Security Number or tax identification
                                number, and results of investor verification and confirmation that an investor is eligible to participate in the
                                offering. If you seek to contact the issuer through the Website (e.g., to ask a question), we will disclose your
                                contact information to the issuer. An issuer typically needs this information for its own legal compliance and
                                to maintain its ledger of investors. However, we do not control how an issuer uses the Personal Information
                                that we provide to it.
                            </p>
                            <p>
                                <span>Financial Disclosures.</span> We may disclose Personal Information to our auditors, accountants, attorneys, CPAs
                                and other professional advisors, insurance companies and brokers and financial institutions, as necessary
                                in the course of the professional services that they render to us. If Tokenism is involved in a merger,
                                acquisition, financing due diligence, reorganization, bankruptcy, receivership, sale of Tokenism assets or
                                transition of service to another provider, Personal Information may be disclosed or transferred as part of
                                such a transaction as permitted by applicable law or contract. We may also disclose Personal Information
                                to third parties as required by law or subpoena or if we reasonably believe that such action is necessary to
                                (a) comply with applicable law and the reasonable requests of law enforcement; (b) to protect the security
                                or integrity of our Website or Services; and/or (c) to exercise or protect the rights, property, or personal
                                safety of Tokenism, our visitors, or others, including by protecting, investigating and deterring against
                                fraudulent, harmful, unauthorized, unethical or illegal activity, and by enforcing the terms and conditions
                                that govern our Website or Services.
                            </p>
                            <p>
                                There are circumstances where Tokenism may decide to buy, sell, or reorganize its business in selected
                                countries. Under these circumstances, it may be necessary to share or receive Personal Information with
                                prospective or actual partners or affiliates. In such circumstances, Tokenism will ensure your information is
                                used in accordance with this Policy. 
                            </p>
                            <h3>Third-party Websites</h3>
                            <p>
                                This Policy does not apply to websites or other domains that are maintained or operated by third parties
                                or our affiliates. Our Website may link to third-party websites and services, but these links are not
                                endorsements of these sites, and this Policy does not extend to them. In some instances, you may click
                                on links that appear to be hosted by Tokenism and include the Tokenism logo, but instead are hosted and
                                processed by third-party websites. Because this Policy is not enforced on these third-party websites, we
                                encourage you to read any posted privacy policy of the third-party website before using the service or site
                                and providing any information. 
                            </p>
                            <h3>Your California Rights</h3>
                            <p>
                                Pursuant to California Civil Code Section § 1798.83, we will not disclose or share your Personal
                                Information with third parties for the purposes of third-party marketing to you without your prior consent.
                            </p>
                            <p>
                                Other than as disclosed in this Policy, the Website does not track users over time and across third-party
                                websites to provide targeted advertising. Therefore, the Website does not operate any differently when it
                                receives Do Not Track (“DNT”) signals from your internet web browser. 
                            </p>
                            <p>
                                If you are a California consumer, as defined by the California Consumer Privacy Act of 2018, you may be
                                afforded additional rights with respect to your “Personal Information” as that term is explicitly defined
                                under California law. Any Personal Information we collect is collected for the commercial purpose of
                                effectively providing our services to you, as well as enabling you to learn more about, and benefit from,
                                our services. You may exercise each of your rights as identified below, subject to our verification of your
                                identity. 
                            </p>
                            <p>
                               <span>Access.</span> You may email us at <a href="mailto:info@tknism.io">info@tknism.io</a> to request a copy of the Personal Information our Website
                                databases currently contain.
                            </p>
                            <p>
                                <span>Prohibit Data Sharing.</span> When applicable, you may prohibit the sharing of your Personal Information by
                                submitting a request via email to <a href="mailto:info@tknism.io">info@tknism.io</a>. In your email, please explain how you wish us to prohibit
                                the sharing of your Personal Information, and which categories of third parties you want to prohibit from
                                receiving your Personal Information. When such prohibitions are not possible to provide our services to
                                you, we will advise you accordingly. You can then choose to exercise any other rights under this Policy.
                            </p>
                            <p>
                                <span>Portability.</span> Upon request and when possible, we can provide you with copies of your Personal Information.
                                    You may submit a request via email to <a href="mailto:info@tknism.io">info@tknism.io</a>. When such a request cannot be honored, we will
                                    advise you accordingly. You can then choose to exercise any other rights under this Policy.
                            </p>
                            <p>
                                <span>Deletion.</span> If you should wish to cease use of our Website and have your Personal Information deleted from
                                our Website, then you may submit a request by emailing us at <a href="mailto:info@tknism.io">info@tknism.io</a>. Upon receipt of such a
                                request for deletion, we will confirm receipt and will confirm once your Personal Information has been
                                deleted. Where applicable, we will ensure such changes are shared with trusted third parties.
                            </p>
                            <p>
                                In addition to the email address provided above, you may also submit requests at the following toll-free
                                telephone number: +1 844 2TKNISM (+1844 285 6476)
                            </p>
                            <p>
                                We do not sell your Personal Information. If we ever decide to sell Personal Information, we will update you
                                via this Policy and include a link entitled “Do Not Sell My Personal Information,” to provide you with an
                                opportunity to opt out of sales of your Personal Information.
                            </p>
                            <p>
                                If a California resident exercises his or her rights under California law, including the CCPA, we shall not
                                discriminate against that California resident by denying our goods or services, charging different prices or
                                rates to similarly situated consumers, providing a different level or quality of our goods or services, or taking
                                any other adverse action.
                            </p>
                            <h3>For Website Visitors Outside of the United States</h3>
                            <p>
                                We do not warrant or represent this Policy or the Website’s use of your Personal Information complies with
                                the laws of any particular jurisdiction. Furthermore, to provide you with our services, we may store, process,
                                and transmit information in the United States and other locations around the world, including countries that
                                may not have the same privacy and security laws as yours. Regardless of the country in which such
                                information is stored, we will process your Personal Information in accordance with this Policy. 
                            </p>
                            <h3>For Website Visitors in the European Union (“EU”) and United Kingdom</h3>
                            <p>
                                Under the General Data Protection Regulation (Regulation (EU) 2016/679 of the European Parliament and
                                of the Council of 27 April 2016, or “GDPR”), individuals in the EU and United Kingdom are afforded specific
                                rights with respect to their Personal Information, or “personal data” as defined under the GDPR or the Data
                                Protection Act 2018. For the purposes of this Policy, Tokenism operates as a data controller. Any personal
                                data we collect from you is processed in the United States and under the terms of this Policy. 
                            </p>
                            <p>
                                Any personal data we collect from you is processed in the legitimate interest of our business and
                                providing our services to you as the lawful means of such processing. You may always withdraw your
                                consent to our use of your personal data as described below. We will only retain your personal data for
                                the time necessary to provide you the information and services to which you have consented, to comply
                                with the law and in accordance with your rights below. 
                            </p>
                            <p>The Data Controller is:</p>
                            <p>
                                NAME: Tokenism LLC<br/>
                                ADDRESS: 3825 Edwards Rd<br/>
                                #103, Cincinnati, OH 45209<br/>
                                EMAIL ADDRESS: <a href="mailto:info@tknism.io">info@tknism.io</a>
                            </p>
                            <p>You can exercise any of the following rights, subject to verification of your identity, by notifying us as
                                described below: </p>
                                <ul className="dots">
                                    <li>
                                        <p>
                                            <span>Access.</span> You may email us at <a href="mailto:info@tknism.io">info@tknism.io</a> to request a copy of the personal data our Website
                                            databases currently contain. 
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <span>Automated Processing and Decision-Making.</span> You may email us at <a href="mailto:info@tknism.io">info@tknism.io</a> to request that
                                            we stop using your personal data for automated processing, such as profiling. In your email,
                                            please explain how you wish us to restrict automated processing of your personal data. When
                                            such restrictions are not possible, we will advise you accordingly. You can then choose to
                                            exercise any other rights under this Policy, to include withdrawing your consent to the processing
                                            of your personal data.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <span>Correction or Rectification.</span> You can correct what personal data our Website database currently
                                            contains by accessing your account directly, or by emailing us at <a href="mailto:info@tknism.io">info@tknism.io</a> to request that
                                            we correct or rectify any personal data that you have provided to us. We may not accommodate
                                            a request to change information if we believe the change would violate any law or legal
                                            requirement or cause information to be incorrect. Where applicable, we will ensure such changes
                                            are shared with trusted third parties. 
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <span>Restrict Processing.</span> When applicable, you may restrict the processing of your personal data by
                                            submitting a request via email to <a href="mailto:info@tknism.io">info@tknism.io</a>. In your email, please explain how you wish us
                                            to restrict processing of your personal data. When such restrictions are not possible, we will
                                            advise you accordingly. You can then choose to exercise any other rights under this Policy, to
                                            include withdrawing your consent to the processing of your personal data. Where applicable, we
                                            will ensure such changes are shared with trusted third parties. 
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <span>Object to Processing.</span> When applicable, you have the right to object to the processing of your
                                            personal data by submitting a request via email to <a href="mailto:info@tknism.io">info@tknism.io</a>. When such objections are not
                                            possible, we will advise you accordingly. You can then choose to exercise any other rights under
                                            this Policy, to include withdrawing your consent to the processing of your personal data. Where
                                            applicable, we will ensure such changes are shared with trusted third parties. 
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <span>Portability.</span> Upon request and when possible, we can provide you with copies of your personal
                                            data. You may submit a request via email to <a href="mailto:info@tknism.io">info@tknism.io</a>. When such a request cannot be
                                            honored, we will advise you accordingly. You can then choose to exercise any other rights under
                                            this Policy, to include withdrawing your consent. Where applicable, we will ensure such changes
                                            are shared with any trusted third parties.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <span>Withdraw Consent.</span> At any time, you may withdraw your consent to our processing of your
                                            personal data through this Website by notifying us via email at <a href="mailto:info@tknism.io">info@tknism.io</a>. Using the same
                                            email address associated with your Website account, simply type the words “WITHDRAW
                                            CONSENT” in the subject line of your email. Upon receipt of such a withdrawal of consent, we
                                            will confirm receipt and proceed to stop processing your personal data. Where applicable, we will
                                            ensure such changes are shared with trusted third parties.
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <span>Erasure.</span> If you should wish to cease use of our Website and have your personal data deleted
                                            from our Website, then you may submit a request by emailing us at <a href="mailto:info@tknism.io">info@tknism.io</a>. Upon receipt
                                            of such a request for erasure, we will confirm receipt and will confirm once your personal data has
                                            been deleted. Where applicable, we will ensure such changes are shared with trusted third
                                            parties. 
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <span>Submit Complaints or Questions.</span> If you wish to raise a complaint on how we have handled your
                                            personal data, you can contact us as described below. If you reside in a European Union
                                            member state, you may also lodge a complaint with the supervisory authority in your country.
                                        </p>
                                    </li>
                                </ul>
                                <h3>Changes to this Policy</h3>
                                <p>
                                If we make any changes to this Policy, a revised Policy will be posted on this webpage and the date of the
                                    change will be reported in the “Last Revised” block above. You can get to this page from any of our
                                    webpages by clicking on the “Privacy Policy” link (usually at the bottom of the screen).
                                </p>
                                <h3>Safeguarding the Information We Collect</h3>
                                <p>
                                    We use reasonable technical, administrative, and physical safeguards in order to protect your Personal
                                    Information against accidental loss and from unauthorized access, use, alteration, and disclosure.
                                    However, we can never promise 100% security. You have a responsibility, as well, to safeguard your
                                    information through the proper use and security of any online credentials used to access your Personal
                                    Information, such as a username and password. If you believe your credentials have been compromised,
                                    please change your password. Please also notify us of any unauthorized use.
                                </p>
                                <h3>How to Contact Us</h3>
                                <p>We value your opinions and welcome your feedback. To contact us about this Policy or your Personal
                                Information, please contact us at</p>
                                <p>
                                    Tokenism LLC<br/>
                                    3825 Edwards Rd., #103<br/>
                                    Cincinnati, OH 45209<br/>
                                    +1 844 2TKNISM (+1 844 285 6476)<br/>
                                    <a href="mailto:info@tknism.io">info@tknism.io</a>
                                </p>
                        </div>
                    </div>
                </div> 
                <Footer />
            </>
        );
    }
}
 
export default PrivacyPolicy;
import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import FeaturedListing from "../../components/FeaturedListing";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
// import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Slider from "@material-ui/core/Slider";
import { connect } from "react-redux";
import EventBus from "eventing-bus";

// import "./listing.css";

const RangeSlider = withStyles({
  root: {
    color: '#3880ff',
    height: 2,
    padding: '15px 0',
  },
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: '#fff',
    marginTop: -8,
    marginLeft: -14,
    '&:focus, &:hover, &$active': {
      boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      // Reset on touch devices, it doesn't add specificity
    },
  },
  active: {},
  valueLabel: {
    '& *': {
      background: 'transparent',
      color: '#000',
      marginTop: "46px",
      transform: 'none'
    },
  },
  track: {
    height: 2,
  },
  rail: {
    height: 2,
    opacity: 0.5,
    backgroundColor: '#bfbfbf',
  },
  mark: {
    backgroundColor: '#bfbfbf',
    height: 8,
    width: 1,
    marginTop: -3,
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor',
  },
})(Slider);

class Listing extends Component {
  constructor() {
    super();
    this.state = {
      value: [1000, 5000],
      count: 1,
      hideRange: true,
      setRange: [1000, 5000],
      TypeOfInvestment: "",
      TypeOfProperty: "",
      filteredList: "",
      favourites: false
    };
    window.scrollTo(0, 0);
    EventBus.on("onFavourites", this.onFavourites);
  }

  onFavourites = () => {
    this.handelFilter("Favorites", 4)
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    if (nextProps !== prevState) {
      let { location, listedProperties, favourites } = nextProps;
      if (location && location.state && location.state.TypeOfInvestment && listedProperties) {
        if (prevState.favourites) {
          return { filteredList: favourites }
        } else {
          var filtered = listedProperties.filter(function (item) {
            return (
              item.stage === location.state.TypeOfInvestment &&
              item.categories === '' &&
              item.tokenId.lastPrice >= 1000 &&
              item.tokenId.lastPrice <= 10000
            );
          });
          return { filteredList: filtered }
        }
      }
    }
    else return null; // Triggers no change in the state
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue, setRange: newValue });
  };

  valuetext = value => {
    return `$${value}`;
  };

  handleSelect = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSearch = () => {
    const { TypeOfInvestment, TypeOfProperty, setRange } = this.state;
    // console.log(
    //   "search result for :: ",
    //   TypeOfInvestment,
    //   TypeOfProperty,
    //   setRange
    // );

    if (TypeOfInvestment === "" && TypeOfProperty === "") {
      this.setState({ filteredList: '' });
    } else {
      var filtered = this.props.listedProperties.filter(function (item) {
        return (
          item.stage === TypeOfInvestment &&
          item.categories === TypeOfProperty &&
          item.tokenId.lastPrice >= setRange[0] &&
          item.tokenId.lastPrice <= setRange[1]
        );
      });
      this.setState({ filteredList: filtered });
    }
  };

  handelFilter = (type, activeNo) => {
    if (type == "Favorites") {
      // var filtered = this.props.favourites.filter(function(item) {
      //   return (
      //     item.stage === type
      //   );
      // });
      this.setState({ filteredList: this.props.favourites, favourites: true });
    } else if (type == "All") {
      this.setState({ filteredList: this.props.listedProperties, favourites: false });
    } else {
      var filtered = this.props.listedProperties.filter(function (item) {
        return (
          item.stage === type
        );
      });
      this.setState({ filteredList: filtered, favourites: false });
    }
    this.setState({ count: activeNo })
  }

  render() {

    const { count } = this.state
    const pathName = this.props.location.pathname

    return (
      <div className="page-wrapper">
        <Navbar page="listing" {...this.props} />
        {/* <Banner
          heading="Featured Listings"
          subHeading="Lorem ipsum dolor sit amet, consectetur adipisicing elit do eiusmod tempor consectetur"
        /> */}

        <section className="feature-listing-sec">
          <div style={{ maxWidth: "1124px", width: "100%" }}>
            <div className="row">
              <div className={`col-12 feature-listing-tabs  ${!this.props.auth && 'justify-content-center'}`}>
                <h5 className={count == 1 ? ' active' : ""} onClick={() => this.handelFilter("All", 1)}>All</h5>
                <h5 className={count == 2 ? ' active' : ""} onClick={() => this.handelFilter("Stabilized Income", 2)}>Stabilized Income</h5>
                <h5 className={count == 3 ? ' active' : ""} onClick={() => this.handelFilter("Re Developed Projects", 3)}>Re Developed</h5>
                <h5 className={count == 4 ? ' active' : ""} onClick={() => this.handelFilter("Ground Up Development", 4)}>Ground Up Development</h5>
                {this.props.auth && <h5 className={count == 5 ? 'ml-auto active' : "ml-auto"} onClick={() => this.handelFilter("Favorites", 5)}>Favorites</h5>}
              </div>
              <div className="col-12">
                <div className="tp-main-search">
                  <div className="row justify-content-center">
                    <div className="col-lg-3 col-md-4">
                      <div className="listing-select">
                        <FormControl variant="outlined">
                          <InputLabel id="demo-simple-select-outlined-label">
                            Type of Investment
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            type="text"
                            name="TypeOfInvestment"
                            labelWidth={140}
                            onChange={this.handleSelect}
                          >
                            <MenuItem value={""}><em>All</em></MenuItem>
                            <MenuItem value={"Stabilized Income"}>
                              Stabilized Income
                            </MenuItem>
                            <MenuItem value={"Re Developed Projects"}>
                              Re Developed Projects
                            </MenuItem>
                            <MenuItem value={"Ground Up Development"}>
                              Ground Up Development
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-4">
                      <div className="listing-select">
                        <FormControl variant="outlined">
                          <InputLabel id="demo-simple-select-outlined-label">
                            Type of Property
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            type="text"
                            name="TypeOfProperty"
                            labelWidth={120}
                            onChange={this.handleSelect}
                          >
                            <MenuItem value={""}>All</MenuItem>
                            <MenuItem value={"Office"}>Office</MenuItem>
                            <MenuItem value={"Multifamily"}>
                              Multifamily
                            </MenuItem>
                            <MenuItem value={"Mixed Use"}>Mixed Use</MenuItem>
                            <MenuItem value={"Hospitality"}>
                              Hospitality
                            </MenuItem>
                            <MenuItem value={"Retail"}>Retail</MenuItem>
                            <MenuItem value={"Warehouse"}>Warehouse</MenuItem>
                            <MenuItem value={"Industrial"}>Industrial</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-4">
                      <div className="range-container text-center w-100 text-area">
                        <a
                          className="btn btn-style-two"
                          style={
                            this.state.setRange.length === 0
                              ? { textAlign: "left", color: "#757575" }
                              : { textAlign: "center", color: "#676869" }
                          }
                          onClick={() =>
                            this.setState({ hideRange: !this.state.hideRange })
                          }
                        >
                          <i className="ti-search"></i>{" "}
                          {this.state.setRange.length === 0
                            ? "Token Price"
                            : `$${this.state.setRange[0]} - $${this.state.setRange[1]}`}
                        </a>
                        <div
                          hidden={this.state.hideRange}
                          className="slider-container"
                        >
                          <p className="price-range-tite">Price Range</p>
                          <RangeSlider
                            defaultValue={this.state.value}
                            valueLabelDisplay="on"
                            onChange={this.handleChange}
                            aria-labelledby="range-slider"
                            valueLabelFormat={this.valuetext}
                            step={500}
                            min={1000}
                            max={5000}
                          // marks={this.marks}
                          />

                          <div
                            className="tp-search-single-wrap text-center w-100 text-area"
                            style={{
                              margin: "0px",
                              padding: "0px",
                              marginTop: "20px"
                            }}
                            onClick={() =>
                              this.setState({
                                hideRange: !this.state.hideRange,
                                setRange: this.state.value
                              })
                            }
                          >
                            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                              <a
                                className="custom-btn"
                                style={{
                                  width: "85px",
                                  height: "29px",
                                  padding: "0px",
                                  textDecoration: "none",
                                  color: "white !important",
                                  textAlign: 'center'
                                }}
                              >
                                <i
                                  className="ti-search"
                                  style={{ textTransform: "none" }}
                                ></i>{" "}
                              Done
                            </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-2 col-md-3 order-12">
                      <div
                        className="tp-search-single-wrap text-center w-100 text-area"
                        onClick={this.handleSearch}
                      >
                        <a className="custom-btn mt-md-4 mt-lg-0">
                          <i className="ti-search"></i> Search
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <FeaturedListing path={pathName} filteredList={this.state.filteredList} favourites={this.state.favourites} />

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = state => {
  let { listedProperties, favourites, auth } = state.Auth;
  return { listedProperties, favourites, auth };
};

export default connect(mapStateToProps, null)(Listing);

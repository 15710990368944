import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { CountryRegionData } from 'react-country-region-selector';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const terminatedRegion ="American Samoa"

const regionData = CountryRegionData.filter((region) =>  region[0] != terminatedRegion)


export const Step2 = ({handelSteps,value,selectCountry}) => {
    const classes = useStyles();

    return (
        <div className="step1">
            <button className="back-btn"  onClick={() => handelSteps('Back')}><i className="fas fa-angle-left"></i></button>
            <div className="step-content">
                <h5>Select Your Country?</h5>
                <p className="red-line">Please select the country where you currently reside.</p>
                <FormControl variant="outlined" className={classes.formControl}>
                    {/* <InputLabel id="demo-simple-select-outlined-label">Country</InputLabel> */}
                    <Select
                    // labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={value}
                    onChange={(e) => selectCountry(e.target.value)}
                    >
                    <MenuItem value={value}>
                        <em>{value}</em>
                    </MenuItem>
                    {regionData.map((item, index)=>{
                      return <MenuItem value={item}>{item[0]}</MenuItem>
                    })}
                    </Select>
                </FormControl>
            </div>
            <button className="next-btn"  onClick={() => handelSteps('Next')}><i className="fas fa-angle-right"></i></button>
            <div className="current-step">
                <h3>02</h3>
                <span>/04</span>
            </div>
        </div>
    )
}

import React, { Component } from 'react';
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import Banner from '../../components/Banner'
import { Player } from 'video-react';
import {Link} from 'react-router-dom'
// import "~node_modules/video-react/dist/video-react.css";
// import Video from './../../theme/video/Tokenism-video.mp4'
import './../../theme/css/video-react.css'; // import css


class HowItWorks extends Component {
  constructor() {
    super()
    this.state = {
      expandedTab: '1',
      expanded: false,
      isOpen: false,
      buyer: true,
      seller: false,
      list:false,
      isPlay:true,
      currentUrl:"https://tokenismworks.tknism.io"
    }
    window.scrollTo(0, 0);
  }

  toggle = (e) => {
    this.setState({ expandedTab: e, expanded: !this.state.expanded })
  }

  handelSwitch = (active) => {
    const current = this.state[active]
    this.setState({
      buyer: false,
      seller: false,
      list:false
    })
    this.setState({
      [active]: !current,
    })
  }


  handelCurrentVideo = (activeUrl,active) => {
    const myTabs = document.querySelectorAll('.listed-video')
    this.setState({
      currentUrl:activeUrl,
    })
    for (var i = 0; i < myTabs.length; i++) {
			myTabs[i].classList.remove("active");
		}
    active.className = "listed-video active"
  }

  render() {
    const { isOpen, buyer, seller, list, currentUrl, isPlay } = this.state
    const { handelSwitch } = this
    return (

      <div className="page-wrapper">
        <Navbar
          page='how-it-works'
          {...this.props}
        />
        <Banner
          heading='How It Works'
          subHeading='Lorem ipsum dolor sit amet, consectetur adipisicing elit do eiusmod tempor consectetur'
        />
        <section className="video-secion">
          <div className="container">
            <div className="row">
              <div className="col-md-8 p-sm-0">
                    <div className='player-wrapper'>
                    <div className='react-player'>
                      {currentUrl && (
                        <Player autoPlay key={currentUrl}>
                          <source src={currentUrl} />
                        </Player>
                      )}
                    </div>
                  </div>
              </div>
              <div className="col-md-4 p-sm-0 position-relative">
                  <div className="playlist">
                      <h4 className="title">Video's Playlist</h4>
                      <div className="list">
                        <div className="listed-video active" onClick={(e)=> this.handelCurrentVideo("https://tokenismworks.tknism.io",e.target) }>
                          <figure>
                              <img src="https://i.ytimg.com/vi/E3MXbjGoP0c/hqdefault.jpg" />
                          </figure>
                          <div className="video-info">
                            <h6>How Tokenism Works</h6>
                            <p>Guide to user wallet</p>
                          </div>
                        </div>
                        <div className="listed-video" onClick={(e)=> this.handelCurrentVideo("https://ourtechnology.tknism.io",e.target) }>
                          <figure>
                              <img src="https://i.ytimg.com/vi/E3MXbjGoP0c/hqdefault.jpg" />
                          </figure>
                          <div className="video-info">
                            <h6>Our Technology</h6>
                            <p>A quick and easy way to get approved</p>
                          </div>
                        </div>
                        {/*
                          <div className="listed-video" onClick={(e)=> this.handelCurrentVideo("https://tokenismworks.tknism.io",e.target) }>
                            <figure>
                                <img src="https://i.ytimg.com/vi/E3MXbjGoP0c/hqdefault.jpg" />
                            </figure>
                            <div className="video-info">
                              <h6>Real estate Investment now in your reach</h6>
                              <p>How to start investing</p>
                            </div>
                          </div>
                          <div className="listed-video" onClick={(e)=> this.handelCurrentVideo("https://ourtechnology.tknism.io",e.target) }>
                            <figure>
                                <img src="https://i.ytimg.com/vi/E3MXbjGoP0c/hqdefault.jpg" />
                            </figure>
                            <div className="video-info">
                              <h6>Hassle Free Investing  & Liquidity</h6>
                              <p>Inspirational Music For Creative People</p>
                            </div>
                          </div>
                        */}

                        {/* <div className="listed-video" onClick={(e)=> this.handelCurrentVideo("https://tknismtest.s3.amazonaws.com/non-compressed-images/compressedImages/Tokenism+video+3.mp4",e.target) }>
                          <figure>
                              <img src="https://i.ytimg.com/vi/E3MXbjGoP0c/hqdefault.jpg" />
                          </figure>
                          <div className="video-info">
                            <h6>afsdas</h6>
                            <p>sdgadsdgafgfdgadfg</p>
                          </div>
                        </div> */}
                      </div>
                    </div>
              </div>
            </div>
          </div>
        </section>
        <section className="buyer-seller-sec sec-padding">
            <div className="buyer-seller">
              <div className="container">
                  <div className="switch" style={{background : `url('https://tknismtest.s3.amazonaws.com/non-compressed-images/bg-buildings.png') no-repeat `, backgroundSize: "100% 100%"}}>
                    <div onClick={() => handelSwitch('buyer')} className={`switch-tab ${buyer ? ' isActive' : null}`}>
                      <span>Buy</span>
                    </div>
                    <div onClick={() => handelSwitch('seller')} className={`switch-tab ${seller ? ' isActive' : null}`}>
                      <span>Trade</span>
                    </div>
                    <div onClick={() => handelSwitch('list')} className={`switch-tab ${list ? ' isActive' : null}`}>
                      <span>List</span>
                    </div>
                  </div>
              </div>
              {buyer && (
                <div className="buyer-journy">
                <div className="container">
                  <h2 className="title">Start Investing with as little as $1000</h2>
                  <p className="para">
                    Tokenism’s unique platform enables investors to browse investment opportunities,
                    review due diligence documents in detail, and make an educated decision on which
                    property to invest in. You can start investing in any asset of your choosing in
                    6 simple steps!
                  </p>
                  <div className="journy" style={{background : `url('https://tknismtest.s3.amazonaws.com/non-compressed-images/journy-bg.png') no-repeat center`}}>
                    <div className="row">
                      <div className="col-lg-10 col-md-11 col-sm-11">
                        <div className="step1">
                          <div className="step-header">
                            <span>01</span>
                            <h3>Signup</h3>
                          </div>
                          <p>
                            Begin by clicking the “Get Started” button and creating your account. Select a Starter, Core, or Advanced account as well as an investment plan based on your predicted initial investment. Then enter your email address and some basic contact information to complete Sign up!
                          </p>
                        </div>
                        <figure className="step-icon">
                          <img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/list.png" />
                        </figure>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-10 col-md-11 col-sm-11 offset-lg-2 offset-md-1 offset-sm-1">
                        <figure className="step-icon">
                          <img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/verification-icon.png" />
                        </figure>
                        <div className="step1">
                          <div className="step-header">
                            <span>02</span>
                            <h3>Verify your Identity</h3>
                          </div>
                          <p>
                            Look out for an email in your inbox to verify your account. Don’t worry, we keep your personal information safe and secure during your online verification and approval process. After completing an SEC-mandated KYC & AML process your third-party accreditation approval can take up to 48 hours.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-10 col-md-11 col-sm-11">
                        <div className="step1">
                          <div className="step-header">
                            <span>03</span>
                            <h3>Deposit Funds</h3>
                          </div>
                          <p>
                            Once you’re approved, you can begin investing! Our online wallet is as secure as it gets and depositing funds is straightforward. Our platform uses technology that allows you to connect directly to your accounts. Your information is encrypted and we never see it. Simply connect your Debit/Credit card, a Bank Account or deposit using PayPal.
                          </p>
                        </div>
                        <figure className="step-icon">
                          <img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/wallet-icon.png" />
                        </figure>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-10 col-md-11 col-sm-11 offset-lg-2 offset-md-1 offset-sm-1">
                        <figure className="step-icon">
                          <img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/bower-icon.png" />
                        </figure>
                        <div className="step1">
                          <div className="step-header">
                            <span>04</span>
                            <h3>Find your favorite property</h3>
                          </div>
                          <p>
                            Browse seamlessly through our intuitive listings page and view investment opportunities across various categories. You can filter by type of asset, location, or token price! Review all the investment offering material and due diligence documents if you so choose. We believe in transparency and supply you with all you need to make an informed decision on the kind of investment you would like to make.
                           </p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-10 col-md-11 col-sm-11">
                        <div className="step1">
                          <div className="step-header">
                            <span>05</span>
                            <h3>Make your Investment</h3>
                          </div>
                          <p>
                            Buying a property backed token is as easy as a few clicks.  Once you have decided on an asset to invest in simply click “Invest Now” and enter the number of tokens you would like to buy for the property.
                          </p>
                        </div>
                        <figure className="step-icon">
                          <img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/buy-icon.png" />
                        </figure>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-10 col-md-11 col-sm-11 offset-lg-2 offset-md-1 offset-sm-1">
                        <figure className="step-icon">
                          <img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/dividands-icon.png" />
                        </figure>
                        <div className="step1">
                          <div className="step-header">
                            <span>06</span>
                            <h3>Enjoy your Returns!</h3>
                          </div>
                          <p>
                          You will receive your rental income in the form of quarterly dividends directly into your tokenism wallet. You can choose to reinvest your money or transfer it to your bank account securely and quickly.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
              )}
              {seller && (
                <div className="buyer-journy">
                  <div className="container">
                    {/* <img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/journy-building.png" /> */}
                    <h2 className="title">Trading your Tokens</h2>
                    <p className="para">
                      So you purchased your first asset backed security tokens on our platform and now you want to sell them? We make that easy. Our marketplace allows you to be just a few clicks from a new buyer for your investment all the time
                    </p>
                    <div className="journy" style={{background : `url('https://tknismtest.s3.amazonaws.com/non-compressed-images/journy-bg.png') no-repeat center`}}>
                      <div className="row">
                        <div className="col-lg-10 col-md-11 col-sm-11">
                          <div className="step1">
                            <div className="step-header">
                              <span>01</span>
                              <h3>List your Tokens</h3>
                            </div>
                            <p>
                                List your tokens by clicking the “List Tokens” tab located within your portfolio. Select the asset you wish to list from, type the quantity of tokens you want to list and the price you’d be willing to sell them for. Once you’re satisfied, click “Sell”.
                             </p>
                          </div>
                          <figure className="step-icon">
                            <img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/submit-icon.png" />
                          </figure>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-10 col-md-11 col-sm-11 offset-lg-2 offset-md-1 offset-sm-1">
                          <figure className="step-icon">
                            <img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/verification.png" />
                          </figure>
                          <div className="step1">
                            <div className="step-header">
                              <span>02</span>
                              <h3>Start Receiving Offers</h3>
                            </div>
                            <p>
                                You can view new and open offers by clicking the “Deal Room” tab in your portfolio. We’ll also send you an email to make sure you don’t miss an offer.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-10 col-md-11 col-sm-11">
                          <div className="step1">
                            <div className="step-header">
                              <span>03</span>
                              <h3>Accept an Offer</h3>
                            </div>
                            <p>
                                We use smart contracts to make accepting offers easy and secure. Once an offer is accepted it takes less than 60 seconds for your token to transfer ownership allowing you to receive the sales proceeds. Simply click “Accept” when you’re ready or counter the offer with a price you like better. Each party has a maximum of 2 counter offers per trade.
                            </p>
                          </div>
                          <figure className="step-icon">
                            <img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/track-icon.png" />
                          </figure>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-10 col-md-11 col-sm-11 offset-lg-2 offset-md-1 offset-sm-1">
                          <figure className="step-icon">
                            <img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/finalize-icon.png" />
                          </figure>
                          <div className="step1">
                            <div className="step-header">
                              <span>04</span>
                              <h3>Your Money Fast</h3>
                            </div>
                            <p>
                              The money from the transaction will transfer to your Tokenism wallet immediately with no restrictions or delays. To view your funds click the “Wallet” tab on your Tokenism Dashboard.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-10 col-md-11 col-sm-11">
                          <div className="step1">
                            <div className="step-header">
                              <span>05</span>
                              <h3>Check Out your Wallet</h3>
                            </div>
                            <p>
                                Unlike with traditional securities or bank accounts you don’t have to wait until the next day or 48 hours for the transaction to settle. You have unrestricted access to the full amount of money in your digital Tokenism wallet and can start using it right away.
                            </p>
                          </div>
                          <figure className="step-icon">
                            <img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/track-icon.png" />
                          </figure>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-10 col-md-11 col-sm-11 offset-lg-2 offset-md-1 offset-sm-1">
                          <figure className="step-icon">
                            <img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/finalize-icon.png" />
                          </figure>
                          <div className="step1">
                            <div className="step-header">
                              <span>06</span>
                              <h3>Reinvest or Withdraw</h3>
                            </div>
                            <p>
                              Any money you have in your tokenism wallet can be used for investing in other properties by buying more security tokens from initial offerings or listed by their owners!  If you need the money elsewhere you can withdraw your funds by transferring them into your connected bank account at any time.
                            </p>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              )}
              {list && (
                <div className="buyer-journy">
                  <div className="container">
                    <h2 className="title">Selling your property</h2>
                    <p className="para">
                      Looking to sell or get partial liquidity from an asset that you own? Tokenism allows you to sell all or part of your property at the price you want and more efficiently. No need to disturb your tenants with all the hassle a traditional sale brings like inconvenient showings or multiple inspections. With the magic of tokenizing, you don’t have to sell your entire asset to have access to a small portion of its value!
                    </p>
                    <div className="journy" style={{background : `url('https://tknismtest.s3.amazonaws.com/non-compressed-images/journy-bg.png') no-repeat center`}}>
                    <div className="row">
                      <div className="col-lg-10 col-md-11 col-sm-11">
                        <div className="step1">
                          <div className="step-header">
                            <span>01</span>
                            <h3>Complete the listing form</h3>
                          </div>
                          <p>
                            Our online listing questionnaire takes about 5 minutes to fill out. Submit some basic information about yourself & your property by clicking the “Submit your Property” button found on the Tokenism Homepage. Someone from the Tokenism team will contact you within 48 hours of your submission.
                          </p>
                        </div>
                        <figure className="step-icon">
                          <img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/list.png" />
                        </figure>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-10 col-md-11 col-sm-11 offset-lg-2 offset-md-1 offset-sm-1">
                        <figure className="step-icon">
                          <img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/offers-icon.png" />
                        </figure>
                        <div className="step1">
                          <div className="step-header">
                            <span>02</span>
                            <h3>Provide asset details</h3>
                          </div>
                          <p>
                            The due diligence team from Tokenism will request for more details about your Asset or project along with pricing and how much of your asset you want to sell. Upon reviewing the information, the team will give you their analysis, price opinion and listing terms.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-10 col-md-11 col-sm-11">
                        <div className="step1">
                          <div className="step-header">
                            <span>03</span>
                            <h3>Sign a listing and Tokenization Contract</h3>
                          </div>
                          <p>
                            The tokenization team will prepare your offering material while you sign off on the listing contract with our legal team. Set the minimum and maximum percentage of the property you want to sell as well as the money you want for it.
                          </p>
                        </div>
                        <figure className="step-icon">
                          <img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/contract-icon.png" />
                        </figure>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-10 col-md-11 col-sm-11 offset-lg-2 offset-md-1 offset-sm-1">
                        <figure className="step-icon">
                          <img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/wallet.png" />
                        </figure>
                        <div className="step1">
                          <div className="step-header">
                            <span>04</span>
                            <h3>View your property listing</h3>
                          </div>
                          <p>
                            While our compliance and legal teams register the securities offered with the concerned regulators, your property listing will be created, approved by you, uploaded to our site, and shared on the platform for investors to view with a coming soon status.  All of our investors are notified of new listings prior to the listing going live. Your listing will be set to go live 90 days after the initial posting.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-10 col-md-11 col-sm-11">
                        <div className="step1">
                          <div className="step-header">
                            <span>05</span>
                            <h3>Go LIVE!</h3>
                          </div>
                          <p>
                          Congratulations! Your listing is LIVE! Once the listing goes live you will have access to a property owner dashboard where you will be able to see your sales goal, how many tokens have sold, and how many tokens remain to reach your maximum sale.
                          </p>
                        </div>
                        <figure className="step-icon">
                          <img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/withdraw-icon.png" />
                        </figure>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-10 col-md-11 col-sm-11 offset-lg-2 offset-md-1 offset-sm-1">
                        <figure className="step-icon">
                          <img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/buy-token-icon.png" />
                        </figure>
                        <div className="step1">
                          <div className="step-header">
                            <span>06</span>
                            <h3>Receive your Funds & Close on the Property </h3>
                          </div>
                          <p>
                            As soon as the minimum goal is achieved the acquisition team sets a date for transfer of title and you receive your funds in your bank account and remaining tokens in your Tokenism wallet! Sit back and enjoy the returns from your fractional ownership!
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>
              )}
            <div className="text-center bg-white">
                <Link to='/starter-packages' className="custom-btn py-2 px-4">Get Started</Link>
            </div>
            </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default HowItWorks;

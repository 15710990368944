import Web3 from 'web3';
import axios from "axios";
import EventBus from 'eventing-bus';
import { connect } from "react-redux";
import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import { Modal, ModalBody } from 'reactstrap';
import NumberFormat from 'react-number-format';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import TableContainer from '@material-ui/core/TableContainer';
import InputAdornment from '@material-ui/core/InputAdornment';
import TablePagination from '@material-ui/core/TablePagination';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import Loader from '../AppLoader';
import { getBalance } from "../../store/actions/Auth"
import { investNow } from "../../store/actions/Property";
import { otpLoader, overlayLoader } from "../../store/actions/Settings";
import { web3, providerInject, providerDev, magic } from '../../store/web3';
import { sellOffers, buyOffer, buyCounter } from "../../store/actions/Exchange";
import { TKUSDABI, TKUSDAddress, networkId, network } from "../../store/contract";

class BuyToken extends Component {

  state = {
    page: 0,
    message: '',
    loader: true,
    isOpen: false,
    loading: false,
    rowsPerPage: 10,
    quantityToken: 0,
    selectedPrice: 0,
    selectedData: {},
    selectedQuantity: 0,
    isCountering: false,
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.sellOffers({ data: this.props.propertyData['property']['_id'] })
    this.props.getBalance()
    this.props.otpLoader(true)
  }

  componentWillReceiveProps({ sellData }) {
    if (this.props.showOtpLoader == false) this.setState({ loader: false })
  }

  getNonce = () => {
    return new Promise(async (resolve, reject) => {
      try {
        let { data } = await axios.get(`/wallet/getNonce`);
        return resolve(data['body']);
      } catch (e) { reject(e) }
    })
  }

  remNonce = (nonce) => {
    return new Promise(async (resolve, reject) => {
      try {
        let { data } = await axios.get(`/wallet/remNonce/${nonce}`);
        return resolve();
      } catch (e) { reject(e) }
    })
  }

  buyTokens = async (index) => {
    try {

      let { contractAddress, price } = this.props['propertyData']['sto'];
      let amount = price * this.state.quantityToken;

      // let totalAmount = (amount + (amount * 0.02)).toString();
      let totalAmount = (amount).toString();

      if (parseFloat(this.props.dashChartData.overview) < parseFloat(amount)) {
        EventBus.publish("error", 'You have insufficient Balance');
        return;
      }

      if (parseFloat(this.state.quantityToken) <= 0) {
        EventBus.publish("error", `Quantity must be greater than zero.`);
        return;
      }
      // this.setState({ loading: true });
      if (localStorage.getItem('ETH-PROVIDER') == 'metamask') this.props.overlayLoader({ status: true, loadingMessage: 'Please Sign Transaction to Buy Tokens' })
      else this.props.overlayLoader({ status: true, loadingMessage: 'Investing Tokens' })

      // web3.setProvider(providerInject);
      let web3Injected = new Web3(providerInject);
      let from = (await web3Injected.currentProvider.enable())[0];
      let TKUSDData = new web3Injected.eth.Contract(TKUSDABI, TKUSDAddress);
      let buyFee = (await TKUSDData.methods.transferWithData(
        contractAddress,
        web3.utils.toHex(totalAmount),
        web3.utils.toHex(this.state.quantityToken)).estimateGas({ from })) * 2;

      let userGas = (await web3Injected.eth.getBalance(from));
      if (userGas < buyFee) {
        EventBus.publish("error", "Not enough gas to perform this transaction.");
        // this.setState({ loading: false })
        return;
      }

      if(localStorage.getItem('ETH-PROVIDER') == 'formatic'){
        const isLoggedIn = await magic.user.isLoggedIn();
        if(!isLoggedIn) {
          this.props.history.push('/');
          EventBus.publish('tokenExpired');
          return;
        }
      }

      let receipt = await web3Injected.eth.sendTransaction({
        from,
        value: 0,
        to: TKUSDAddress,
        gas: buyFee,
        gasPrice: web3.utils.toHex(web3.utils.toWei('100', 'gwei')),
        // data: TKUSD.methods.transfer(contractAddress, web3.utils.toHex(amount)).encodeABI(),
        data: TKUSDData.methods.transferWithData(
          contractAddress,
          web3.utils.toHex(totalAmount),
          web3.utils.toHex(this.state.quantityToken)).encodeABI(),
        // web3.utils.hexToBytes(web3.utils.toHex(this.state.quantityToken))).encodeABI(),
      }).on('transactionHash', () => EventBus.publish("info", 'Your transaction is Mining'));
      let data = {
        NetwordId: networkId,
        quantity: this.state.quantityToken,
        transactionHash: receipt['transactionHash'],
        id: this.props.propertyData.property['_id'],
      }
      this.props.investNow(data);
      // document.getElementById('quantity0').value = 0
      this.setState({ quantityToken: 0 })
      // setTimeout(() => this.setState({ loading: false }), 2000);
    } catch (e) {
      EventBus.publish("error", e['message']);
      Sentry.captureMessage(e['message'] + " buyTokens");
      // this.setState({ loading: false })
      this.props.overlayLoader({ status: false, loadingMessage: '' })
    }
  }

  buyOffer = async (offer, quantity, index) => {
    let nonce = '';
    try {
      let { exchangeContract, exchangeABI, unitPrice, fee, hideOptions } = offer;
      let total = unitPrice * quantity;
      let payfee = total * (fee / 100);
      let totalPayable = total + payfee;
      totalPayable = parseFloat(totalPayable).toFixed(0);

      if (parseFloat(this.props['dashChartData']['overview']) < parseFloat(totalPayable)) {
        EventBus.publish("error", `You have insufficient balance to place this order.`);
        return;
      }

      if (parseFloat(quantity) <= 0) {
        EventBus.publish("error", `Quantity must be greater than zero.`);
        return;
      }

      if (hideOptions) {
        EventBus.publish("error", `You have already countered this offer`);
        return;
      }

      // this.setState({ loader: true, message: 'Please Sign Transaction to Buy Tokens' });
      if (localStorage.getItem('ETH-PROVIDER') == 'metamask') this.props.overlayLoader({ status: true, loadingMessage: 'Please Sign Transaction to Buy Tokens' })
      else this.props.overlayLoader({ status: true, loadingMessage: 'Investing Tokens' })

      let webDev = new Web3(providerDev);
      let web3Injected = new Web3(providerInject);
      webDev.eth.handleRevert = true;

      let from = (await webDev.eth.getAccounts())[0];
      let buyer = (await web3Injected.currentProvider.enable())[0];

      let TKUSDData = new web3Injected.eth.Contract(TKUSDABI, TKUSDAddress);
      await TKUSDData.methods.approve(exchangeContract, totalPayable).send({ from: buyer });

      const ExchangeInstance = new webDev.eth.Contract(exchangeABI, exchangeContract);

      let buyFee = (await ExchangeInstance.methods.buyTokens(quantity, buyer).estimateGas({ from })) * 2;

      nonce = await this.getNonce();
      webDev.eth.sendTransaction({
        from,
        nonce,
        value: 0,
        gas: buyFee,
        to: exchangeContract,
        data: ExchangeInstance.methods.buyTokens(webDev.utils.toHex(quantity), buyer).encodeABI(),
      })
        .on('transactionHash', (hash) => console.log(hash))
        .on('receipt', async buyReceipt => {
          let data = {
            network,
            id: this.props.propertyData.property['_id'],
            transactionHash: buyReceipt['transactionHash'],
          }
          this.props.buyOffer(data);
          let stateName = 'quantity' + index;
          this.setState({ [stateName]: '' })
          // setTimeout(() => this.setState({ loader: false, message: '' }), 2000);
        })
        .on('error', async e => {
          // this.setState({ loader: false, message: '' });
          this.props.overlayLoader({ status: false, loadingMessage: '' })
          EventBus.publish("error", `Error while Buying Tokens`);
        });

    } catch (e) {
      // this.setState({ loader: false, message: '' });
      Sentry.captureMessage(e['message'] + ' buyOffer');
      this.props.overlayLoader({ status: false, loadingMessage: '' })
      EventBus.publish("error", e['message']);
      // EventBus.publish("error", `Error while Buying Tokens`);
    }
  }

  counterOffer = async () => {
    let nonce = '';
    try {
      let { selectedData, selectedPrice, selectedQuantity } = this.state;
      let { exchangeContract, exchangeABI, fee, hideOptions } = selectedData;

      let total = selectedPrice * selectedQuantity;
      let payfee = total * (fee / 100);
      let totalPayable = parseFloat(total + payfee).toFixed(0);

      if (parseFloat(this.props['dashChartData']['overview']) < parseFloat(total)) {
        EventBus.publish("error", `You have insufficient balance to place this order.`);
        return;
      }

      if (parseFloat(selectedQuantity) <= 0) {
        EventBus.publish("error", `Quantity must be greater than zero.`);
        return;
      }

      if (hideOptions) {
        EventBus.publish("error", `You have already countered this offer`);
        return;
      }

      // this.setState({ loader: true, message: 'Please Sign Transaction to Place Offer', isOpen: !this.state.isOpen });
      this.setState({ isOpen: !this.state.isOpen });
      this.props.overlayLoader({ status: true, loadingMessage: 'Placing your counter offer' })

      if(localStorage.getItem('ETH-PROVIDER') == 'formatic'){
        const isLoggedIn = await magic.user.isLoggedIn();
        if(!isLoggedIn) {
          this.props.history.push('/');
          EventBus.publish('tokenExpired');
          return;
        }
      }

      let webDev = new Web3(providerDev);
      let web3Injected = new Web3(providerInject);
      console.log('web3Injected', web3Injected);
      webDev.eth.handleRevert = true;
      let from = (await webDev.eth.getAccounts())[0];
      let buyer = (await web3Injected.currentProvider.enable())[0];
      console.log('buyer', buyer);

      // web3.setProvider(providerInject);
      let TKUSDData = new web3Injected.eth.Contract(TKUSDABI, TKUSDAddress);
      await TKUSDData.methods.approve(exchangeContract, totalPayable).send({ from: buyer });

      const ExchangeInstance = new webDev.eth.Contract(exchangeABI, exchangeContract);
      // const ExchangeInstance = new webDev.eth.Contract(exchangeABI, exchangeContract);

      console.log('ExchangeInstance', ExchangeInstance);

      // let buyFee = (await ExchangeInstance.methods.buyerOffer(selectedQuantity, selectedPrice, buyer).estimateGas({ from })) * 2;
      // console.log('buyFee', buyFee);

      nonce = await this.getNonce();
      console.log('nonce', nonce);

      let buyReceipt = await webDev.eth.sendTransaction({
        from,
        nonce,
        value: 0,
        // gas: buyFee,
        to: exchangeContract,
        data: ExchangeInstance.methods.buyerOffer(web3.utils.toHex(selectedQuantity), web3.utils.toHex(selectedPrice), buyer).encodeABI(),
      }).on('transactionHash', (hash) => console.log(hash));
      let data = {
        network,
        transactionHash: buyReceipt['transactionHash'],
        id: this.props['propertyData']['property']['_id']
      }
      console.log('before buyCounter');

      this.props.buyCounter({ data, history: this.props.history });
    } catch (e) {
      console.log('catch e', e);
      EventBus.publish("error", e['message']);
      Sentry.captureMessage(e['message'] + ' counterOffer');
      this.props.overlayLoader({ status: false, loadingMessage: 'Placing your counter offer' });
    }
  }

  handelModal = () => this.setState({ isOpen: !this.state.isOpen });

  countering = (index) => {
    let counteringName = 'isCountering' + index;
    if (this.state[counteringName] == undefined) this.setState({ [counteringName]: true })
    else this.setState({ [counteringName]: !this.state[counteringName], buttonId:counteringName })
  }

  handelNumber = (e, index) => {
    let counterValueName = 'counterValue' + index;
    this.setState({ [counterValueName]: e.target.value })
  }

  changePrice = (type, index, tokenAvailable) => {
    const target = 'quantity' + index
    const elm = document.getElementById(target)
    if (type == 'subtract' && elm.value > 0) {
      elm.value = --elm.value
      this.setState({ quantityToken: this.state.quantityToken - 1 })
    }
    else if (type == "add") {
      if (tokenAvailable > this.state.quantityToken) this.setState({ quantityToken: this.state.quantityToken + 1 })
    }
  }

  changeQuantity = (type, index, tokensAvailable) => {
    const target = 'quantity' + index;
    const elm = document.getElementById(target);
    let stateName = 'quantity' + index;
    if (type == 'subtract' && elm.value > 0) {
      elm.value = --elm.value
      this.setState({ [stateName]: elm.value })
    }
    else if (type == "add") {
      if (tokensAvailable > elm.value) {
        elm.value = ++elm.value
        this.setState({ [stateName]: elm.value })
      }
    }
  }

  handleFormChangeQuantity = (value, index) => {
    let stateName = 'quantity' + index + 1;
    this.setState({ [stateName]: value.target.value })
  }

  handleCounter = (selectedQuantity, selectedPrice, selectedData, stateName) => {
    if (!selectedQuantity || selectedQuantity <= 0) {
      EventBus.publish("error", 'Quantity must be greater than zero');
      return;
    }

    if (parseFloat(selectedPrice).toString().indexOf(".") != -1) {
      EventBus.publish("error", 'Counter offer should be integer');
      return;
    }

    if (parseFloat(selectedPrice) > parseFloat(selectedData['unitPrice'])) {
      EventBus.publish("error", 'Counter offer must be less than original price');
      return;
    }

    let total = selectedQuantity * selectedPrice;
    if (parseFloat(this.props.dashChartData.overview) < parseFloat(total)) {
      EventBus.publish("error", 'You have insufficient Balance');
      return;
    }

    this.setState({ selectedQuantity, selectedPrice, selectedData, [stateName]: !this.state[stateName], isOpen: !this.state.isOpen })
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage })
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
    this.setState({ page: 0 })
  }

  handleFormChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    const classes = {};
    let today = new Date();
    let { showOtpLoader } = this.props;
    let { message, loader } = this.state;
    let sellData = this.props.sellData || null;
    let walletBalance = this.props.walletBalance || null;
    let balance = walletBalance.length > 0 ? walletBalance.filter(({ symbol }) => symbol == "TKUSD") : null
    let availableBalance = balance ? balance[0].balance : null
    let { overview } = this.props.dashChartData || null;
    let { property, sto } = this.props.propertyData || {};
    let tokenAvailable = sto && sto.tokenOnSale ? sto.tokenUnSold : 0;
    let startDate = sto && sto.startDate ? new Date(sto['startDate']) : 0;
    let soldPercentage = sto && sto.tokenOnSale ? (tokenSold / sto.totalTokens) * 100 : 0;
    let tokenSold = sto && sto.tokenOnSale ? parseInt(sto.tokenSold) + (sto.totalTokens - sto.tokenOnSale) : 0;
    const { rowsPerPage, page } = this.state

    return (
      <TableContainer component={Paper} className="activity-tabel buy-token">
        <div className="activity-header">
          <div className="balance"><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/coins-icon.png" alt="" />
          Available Balance: {' '}
            <span>
              <NumberFormat displayType={'text'} thousandSeparator={true} value={availableBalance} renderText={value => <span>{value}</span>} />
            </span>
          </div>
        </div>
        <div className="scroll-div-invest-modal">
          {showOtpLoader || loader ? <Loader message={message} /> :
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center"><div className="d-flex">Details <div><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/up-down-arrows.png" /> </div> </div></TableCell>
                  <TableCell align="center"><div className="d-flex">Price <div><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/up-down-arrows.png" /> </div></div></TableCell>
                  <TableCell align="center"><div className="d-flex">Available <div><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/up-down-arrows.png" /> </div></div></TableCell>
                  <TableCell align="center"><div className="d-flex">Quantity <div><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/up-down-arrows.png" /> </div></div></TableCell>
                  <TableCell align="center"><div className="d-flex">Total price <div><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/up-down-arrows.png" /> </div></div></TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>

                {(this.props.propertyData && this.props.propertyData['sto']['status'] != 'Sold') &&
                  <TableRow className="about-property">
                    <TableCell>
                      <div className="property">
                        <img src={property['banner']['location']} />
                        <div>
                          <h5> {property['title']} </h5>
                          <span> {property['description']} </span>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="center"><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/icons/49.png" alt="" />
                      <NumberFormat displayType={'text'} thousandSeparator={true} value={sto.price} renderText={value => <span>{value}</span>} />
                    </TableCell>
                    <TableCell align="center">{tokenAvailable}</TableCell>
                    <TableCell align="center">
                      <span>
                        <span className="signs pr-2" onClick={() => this.changePrice("subtract", 0, tokenAvailable)}>-</span>
                        <NumberFormat placeholder="" id="quantity0" variant="outlined" name="quantityToken" value={this.state.quantityToken} onChange={this.handleFormChange} className='colorBlack quantity' thousandSeparator={true} />
                        <span className="signs" onClick={() => this.changePrice("add", 0, tokenAvailable)}>+</span>
                      </span>
                    </TableCell>
                    <TableCell align="center"><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/49.png" alt="" />{this.state.quantityToken * sto.price}
                      {/* <span className="incl-fee">incl fee.</span> */}
                    </TableCell>
                    <TableCell>
                      <button className="custom-btn d-block w-100" onClick={this.buyTokens} disabled={this.state.loading}>
                        {!this.state.loading ? 'Invest Now' : <i className="fa fa-spinner fa-spin fa-1x fa-fw" />}
                      </button>
                    </TableCell>
                  </TableRow>
                }

                {sellData.length > 0 ?
                  (rowsPerPage > 0
                    ? sellData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : sellData
                  ).map((row, index) => (
                    <>
                    <Modal
                      modalClassName="modal-mini modal-info uploaded confirm-counter"
                      isOpen={this.state.isOpen}
                    >
                      <div className="modal-header justify-content-center">
                        <div className="modal-profile">
                          <img className='modalLogoImg' src="https://tknismtest.s3.amazonaws.com/non-compressed-images/Logo0.png" alt="" />
                        </div>
                        <div className="right">
                          <img
                            onClick={this.handelModal}
                            className="modalLogoImg"
                            src="https://tknismtest.s3.amazonaws.com/non-compressed-images/close-icon.png"
                            alt=""
                          />
                        </div>
                      </div>
                      <ModalBody>
                        <div className="scroll-div">
                          <Table aria-label="table">
                            <TableHead>
                              <TableRow>
                                <TableCell align="center">Seller</TableCell>
                                <TableCell align="center">Property</TableCell>
                                <TableCell align="center">Fee</TableCell>
                                <TableCell align="center">Quantity</TableCell>
                                <TableCell align="center">Offer Price</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow>
                                <TableCell align="center">{this.state['selectedData']['userId'] && this.state['selectedData']['userId']['firstName']} {this.state['selectedData']['userId'] && this.state['selectedData']['userId']['lastName']}</TableCell>
                                <TableCell align="center">{property['title']}</TableCell>
                                <TableCell align="center">{(this.state['selectedQuantity'] * this.state['selectedPrice']) * (this.state['selectedData']['fee'] / 100)}</TableCell>
                                <TableCell align="center">{this.state['selectedQuantity']}</TableCell>
                                <TableCell align="center">{this.state['selectedPrice']}</TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </div>
                        {console.log('buttonId', this.state)}
                        <button className="custom-btn" id={`button${index + 1}`} onClick={this.counterOffer}>confirm</button>
                        <button className="btn" onClick={this.handelModal}>Cancel</button>
                      </ModalBody>
                    </Modal>
                      <TableRow key={row['name']}>
                        <TableCell component="th" scope="row">
                          <div className="property">
                            <span className="circle" style={{ textTransform: "capitalize" }}>
                              {row['userId']['firstName'].charAt(0)}
                            </span>
                            <h5>
                              {row['userId']['firstName']} {row['userId']['lastName']}
                            </h5>
                          </div>
                        </TableCell>
                        <TableCell align="center"><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/icons/49.png" alt="" />
                          <NumberFormat displayType={'text'} thousandSeparator={true} value={row.unitPrice} renderText={value => <span>{value}</span>} />
                        </TableCell>
                        <TableCell align="center">{row['amount']}</TableCell>
                        <TableCell align="center">
                          <span>
                            <span className="signs pr-2" onClick={() => this.changeQuantity("subtract", index + 1, row['amount'])}>-</span>
                            <NumberFormat placeholder="" id={`quantity${index + 1}`} variant="outlined" name={`quantity${index + 1}`} value={this.state[`quantity${index + 1}`] || ''} onChange={this.handleFormChange} className='colorBlack quantity' thousandSeparator={true} />
                            <span className="signs" onClick={() => this.changeQuantity("add", index + 1, row['amount'])}>+</span>
                          </span>
                        </TableCell>
                        <TableCell align="center"><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/49.png" alt="" />
                          {row['unitPrice'] * (this.state[`quantity${index + 1}`]) || 0}
                          {/* <span className="incl-fee">incl fee.</span> */}
                        </TableCell>

                        {row.userId._id !== localStorage.getItem('id') &&
                          <TableCell align="center">
                            <button className="action-btn d-block mb-2" type="button" onClick={() => this.buyOffer(row, ((this.state[`quantity${index + 1}`]) || 0), index + 1)}>
                              Buy Now
              </button>
                            {this.state[`isCountering${index + 1}`] ?
                              <div>
                                <FormControl variant="outlined" className='w-100'>
                                  <OutlinedInput
                                    name="phone"
                                    type="number"
                                    margin="dense"
                                    value={this.state[`counterValue${index + 1}`]}
                                    onChange={(value) => this.handelNumber(value, index + 1)}
                                    style={{ height: '40px' }}
                                    startAdornment={<InputAdornment position="start"><i className="fa fa-close" onClick={() => this.countering(index + 1)}></i></InputAdornment>}
                                  />
                                  <button onClick={() => this.handleCounter(this.state[`quantity${index + 1}`], this.state[`counterValue${index + 1}`], row, `isCountering${index + 1}`)} className="action-btn">
                                    <i className="fa fa-chevron-right"></i>
                                  </button>

                                </FormControl>
                              </div>
                              :
                              <button className="action-btn counter d-block mb-0" onClick={() => this.countering(index + 1)}>
                                counter offer
              </button>
                            }
                          </TableCell>
                        }
                      </TableRow>

                    </>
                  )) :
                  <>
                    <TableRow>
                      <TableCell colSpan={6}>
                        <div className={'col-12 text-center mt-5'}>
                          <img
                            src="https://tknismtest.s3.amazonaws.com/non-compressed-images/47.png"
                            alt=""
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  </>
                }
              </TableBody>
            </Table>}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
            colSpan={1}
            count={sellData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            ActionsComponent={this.TablePaginationActions}
          />
        </div>
      </TableContainer>
    )
  }
}

const mapStateToProps = ({ Exchange, Auth, Settings }) => {
  let { showOtpLoader } = Settings;

  return {
    showOtpLoader,
    sellData: Exchange.sellData || {},
    walletBalance: Auth.walletBalance || {},
  }
}

const mapDispatchToProps = { investNow, sellOffers, buyOffer, buyCounter, getBalance, otpLoader, overlayLoader };
export default connect(mapStateToProps, mapDispatchToProps)(BuyToken);

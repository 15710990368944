import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Link } from "react-router-dom";

class IdentityVerification extends Component {
  state = {
    expand: false
  }

  toggle = () => this.setState({ expand: !this.state.expand });

  render() {
    let { expand } = this.state;
    let { dashHeadData } = this.props;
    let { step } = this.props.dashHeadData || {};
    return (
      <div className="row" id="identity">
        <div className="col-12">
          <div className="auth-container">
            <div className='profile-header' onClick={this.toggle}>
              <i className={expand ? "fa fa-chevron-down fa-rotate-270" : "fa fa-chevron-down"}></i>
              <h4 style={{ color: '#1A1E2C', marginLeft: "20px" }}>Identity Verification</h4>
            </div>
            <div hidden={expand} className='row profile-detail-container'>
              <div className="col-lg-10 col-md-9 col-sm-8 col-12">
                <p className='auth-desc'>In order to protect your account and ensure the security of your assets, verify your identity for withdrawal</p>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-4 col-12 mt-3 mt-sm-0">
                {step > 1 ?
                  <button className="custom-btn-disabled">Verified</button>
                  :
                  dashHeadData && dashHeadData.isKycVerified == "InReview"
                  ?
                  <button className="custom-btn-warning">In Review</button>
                  :
                  dashHeadData && dashHeadData.isKycVerified == "Verified" && dashHeadData.dwollaStatus == "document"
                  ?
                  <button className="custom-btn-warning">In Review</button>
                  :
                  <Link to="/kyc" className="custom-btn-danger">Verify</Link>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ Auth }) => {
  let { dashHeadData } = Auth;
  return { dashHeadData }
}

export default connect(mapStateToProps, null)(IdentityVerification);

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function SelectInput({values,label,value,handleChange}) {
  const classes = useStyles();
  return (
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="tkn-select">{label}</InputLabel>
        <Select
          native
          defaultValue={value}
          value={value}
          onChange={handleChange}
          inputProps={{
            id: 'tkn-select',
          }}
        >
          {values.map((item ,index) => {
            return <option key={index} value={item}>{item}</option>
          })}

        </Select>
      </FormControl>
  );
}

import React from "react";
import { connect } from "react-redux";
import { getKyc} from "../../../store/actions/Settings";

const Step4 = ({handelSteps,handelModal,Wallet,frontImage,doctype,getKyc,history}) => {

    return (
        <div className="step1">
            <button className={`back-btn mt-sm-3 mt-md-0 ${frontImage ? " next-btn" : "" }`} onClick={() => handelSteps('Back')}><i className="fas fa-angle-left"></i></button>
            <div className="step-content d-flex justify-content-between">
                <div className="">
                    <h5>Please follow the prompts to upload the requested images of your previously selected identification document along with a selfie for facial recognition.</h5>
                    <p className="red-line">Required</p>
                </div>
                <div className="text-right">
                        <button className="capture-btn" onClick={() => handelModal()} >Capture Images</button>
                </div>
            </div>
            <div className="current-step">
                <h3>04</h3>
                <span>/04</span>
            </div>
        </div>
    )
}

const mapStateToProps = ({ Wallet}) => {
    let { backImage, frontImage } = Wallet;
    return {
        Wallet,
        backImage,
        frontImage
    };
  };

  const mapDispatchToProps = {
    getKyc,
  };

  
  export default connect(mapStateToProps, mapDispatchToProps)(Step4);
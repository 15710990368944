import React, { Component } from 'react';
import DashHeader from '../../../components/DashHeader';
import { connect } from "react-redux";
import Footer from '../../../components/Footer';

class DashboardLayout extends Component {
    state = {  }
    render() {
       let { publicAddress } = this.props.dashHeadData;

        return (
            <>
                <DashHeader
                    publicAddress={publicAddress}
                    {...this.props}
                />
                {this.props.children}
                <Footer />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return { dashHeadData: state.Auth.dashHeadData || {} }
  }

export default connect(mapStateToProps)(DashboardLayout);

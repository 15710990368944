import React, { Component } from 'react';
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import Banner from '../../components/Banner'


class CookiesPolicy extends Component {
    state = {  }
    render() { 
        return ( 
            <>
                <Navbar page='Cookies Policy' {...this.props}/>
                <Banner
                    heading = 'Cookies Policy'
                />
                <div className="agreements-wrapper">
                    <div className="container">
                        <div className="agreements">
                            <h2>Tokenism Cookie Policy</h2>
                            <p>
                               Tokenism   (“Tokenism”)   uses   cookies   to   collect   information   about   your   online preferences.   Cookies   are   small   pieces   of   information   sent   by   a   web   server   to   a   web browser which allows the server to uniquely identify the browser on each page. These small  pieces  of  information   are   transmitted   from   a   website   to   your   computer’s   hard drive.  Cookies can either be session cookies, which do not stay on your computer afte ryou   close   your   browser,   or   they   can   be   persistent   cookies,   which   remain   on   your computer   until   you   delete   them   or   they   expire.     Tokenism   may   use   both   types   ofcookies,   in   addition   to   the   following   categories   of   cookies   on   our   website, <a href="https://www.tknism.io" target="_blank" >https://www.tknism.io</a>
                            </p>
                            <h3>a. Strictly Necessary Cookies</h3>
                            <p>
                               These cookies are essential in order to enable you to move around the Website and use its   features.   Without   these   cookies,   services   you   have   requested,   such  as maintaining   a   record   of   your   purchased   items   (e.g.   a   shopping   cart),   cannot   be provided.
                            </p>
                            <h3>b. Performance Cookies</h3>
                            <p>These   cookies   collect   anonymous   information   on   how   people   use   our   Website   to help   us   understand   how   customers   arrive   at   our   site,   browse   or   use   our   site   and highlight  areas where   we  can  improve,  such   as  navigation. The   data   stored  by  the secookies   never   shows   personal   details   from   which   your   individual   identity   can   be established.</p>
                            <h3>c. Functionality Cookies</h3>
                            <p>
                               These cookies remember choices you make such as account login information, the country from which you visit our Website, your preferred language, and your search parameters. This information can then be used to provide you with an experience more appropriate to your selections and to make your visits to our Website more tailored to your preferences. The information in these cookies may be anonymised.  These cookies cannot track your browsing activity on other websites.
                            </p>
                            <h3>d. Targeting cookies or advertising cookies</h3>
                            <p>
                            These  cookies collect  information  about  your  browsing  habits  in   order to   make advertising   more   relevant   to   you   and   your   interests.   They   are   also   used   to   limit   the number of times you see an advertisement as well as help measure the effectiveness of an   advertising   campaign.     The   cookies   are   usually   placed   by   third   party   advertising networks.     These   cookies   remember   the   websites   you   visit   and   that   information   is shared with other parties such as advertisers.
                            </p>
                            <p>
                                Many browsers automatically accept cookies by default.  Of course, if you do not wish to have cookies on your devices, you may turn them off, or selectively accept 22820309.1
                                some cookies while refusing others, at any time by modifying your Internet browser’s settings. However, by disabling cookies on your device, you may be prohibited from full use of the Website’s features or lose access to some functionality. To find out more about cookies please visit:  <a href="https://www.youronlinechoices.eu" target="_blank" >www.youronlinechoices.eu</a>.
                            </p>
                        </div>
                    </div>
                </div> 
                <Footer />
            </>
        );
    }
}
 
export default CookiesPolicy;
import axios from "axios";
import EventBus from 'eventing-bus';
import { connect } from 'react-redux';
import React, { Component } from 'react';
// import PlaidLink from "react-plaid-link";

import './index.css';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import { plaidUpload, plaidInfo } from '../../store/actions/Auth';

class Plaid extends Component {
  constructor(props) {
    super(props)
    this.state = { transactions: [] };
    window.scrollTo(0, 0);
    props.plaidInfo();
  }

  onSuccess = (public_token, metadata) => {
    this.props.plaidUpload(metadata)
    // axios.post("/auth/public_token", {public_token});
  }

  onExit = (e) => {
    EventBus.publish("error", `Exited from plaid without payment`);
  }

  getTransactions = () => {
    axios.get("/wallet/transactions").then(res => {
      this.setState({ transactions: res.data });
    });
  }

  getInfo = () => {
    axios.get("/wallet/plaidInfo").then(({ data }) => {
    });
  }

  render() {
    let { plaidData } = this.props;
    return (
      <div className="page-wrapper">
        <Navbar {...this.props} />
        <div style={{ textAlign: 'center' }} className='pt-5 mt-5'>
          {plaidData ?
            <div>
              <h3>
                Plaid {plaidData.ENV} Env
                </h3>
              {/* <PlaidLink
                env={plaidData['ENV']}
                product={plaidData['PRODUCTS']}
                publicKey={plaidData['PUBLIC_KEY']}
                clientName={plaidData['CLIENT_NAME']}
                onExit={this.onExit}
                onSuccess={this.onSuccess}
                className="test mt-5 btn"
              >
                Connect your bank!
                </PlaidLink> */}
            </div>
            : null}
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let { plaidData } = state.Auth;
  return { plaidData };
}

const mapDispatchToProps = { plaidUpload, plaidInfo };

export default connect(mapStateToProps, mapDispatchToProps)(Plaid);

import Web3 from 'web3';
import { Magic } from 'magic-sdk';
import { envoirnment } from './config';
import HDWalletProvider from '@truffle/hdwallet-provider';

let providerInject;
let token = localStorage.getItem('token');
let type = localStorage.getItem('ETH-PROVIDER');

let magic;
if(process['env']['REACT_APP_NETWORK'] === 'mainnet')
  magic = new Magic(`${process['env']['REACT_APP_MAGIC_KEY']}`);
else magic = new Magic(`${process['env']['REACT_APP_MAGIC_KEY']}`, { network: "kovan" });

if (type == 'formatic' && token && token !== '') {
  providerInject = magic['rpcProvider'];
}
else providerInject = window['ethereum'];

let web3 = new Web3(providerInject);

web3.eth.handleRevert = true;
let walletIndex = envoirnment === 'development' ? 5
  : envoirnment === 'staging' ? 6
    : envoirnment === 'prototype' ? 7
    : envoirnment === 'production' && process['env']['REACT_APP_NETWORK'] === 'mainnet' ? 1 : 8;
    // : envoirnment === 'production' && process['env']['REACT_APP_NETWORK'] === 'mainnet' ? 3 : 8;

const providerDev = new HDWalletProvider(
  process['env']['REACT_APP_WALLET_SEED'],
  `https://proxy.tknism.io/https://kovan.infura.io/v3/${process['env']['REACT_APP_INFURA_KEY']}`,
  walletIndex
);

const emptyProvider = async function () {
  if (await magic.user.isLoggedIn())
    await magic.user.logout();
}

const setProvider = function (e) {
  return new Promise((resolve, reject)=> {
    if (localStorage.getItem('ETH-PROVIDER') == 'formatic' || e == 'formatic')
      providerInject = magic['rpcProvider'];
    else providerInject = window['ethereum'];
    web3 = new Web3(providerInject);
    web3.eth.handleRevert = true;
    resolve();
  })
}

export { web3, providerDev, providerInject, emptyProvider, magic, setProvider };

import React, { Component } from "react";

class TwoFA extends Component {
  constructor() {
    super();
    this.state = {
      expand: false
    };
  }

  toggle =()=> this.setState({ expand: !this.state.expand });

  render()
  {
    let { expand } = this.state;
    return (
      <div className="row" id="auth">
        <div className="col-12">
          <div className="auth-container">
            <div className="profile-header" onClick={this.toggle}>
              <i
                className={
                  expand
                    ? "fa fa-chevron-down fa-rotate-270"
                    : "fa fa-chevron-down"
                }
              ></i>
            <h4 style={{ color: "#1A1E2C", marginLeft: "20px" }}>Multi-Factor Authentication</h4>
            </div>
            <div hidden={expand} className="row profile-detail-container">
              <div className="col-lg-6 p-0 col-md-12">
                <div className="row m-0">
                  <div className="col-md-8 p-0 col-sm-8 col-12">
                    <h5 className="auth-title">Google Authentication</h5>
                    <p className="auth-desc">
                      Used for security modifications
                    </p>
                  </div>
                  <div className="col-md-4 col-sm-4 col-12 my-3 my-sm-0">
                    {this.props.profile.twoFaEnabled ? (
                      <button
                        className="custom-btn-disabled"
                        onClick={() => this.props.disableGoogleAuth("GA")}
                      >
                        Disable
                      </button>
                    ) : (
                      <button
                        className="custom-btn"
                        onClick={() => this.props.handleGoogleAuth("GA")}
                      >
                        Enable
                      </button>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-lg-6 p-0 col-md-12 mt-md-3 mt-lg-0">
                <div className="row m-0">
                  <div className="col-md-8 p-0 col-sm-8 col-12">
                    <h5 className="auth-title">Text Authentication</h5>
                    <p className="auth-desc">
                      Used for security modifications
                    </p>
                  </div>
                  <div className="col-md-4 col-sm-4 col-12 mt-3 mt-sm-0">
                    {this.props.profile.smsVerifyEnabled ? (
                      <button
                        className="custom-btn-disabled"
                        onClick={() => this.props.disableSmsAuth()}
                      >
                        Disable
                      </button>
                    ) : (
                      <button
                        className="custom-btn"
                        onClick={() => this.props.handleGoogleAuth("SMS")}
                      >
                        Enable
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TwoFA;

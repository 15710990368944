import React, { Component } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { deleteNotification} from "../../store/actions/Settings";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

const classes = useStyles;

class PopOver extends Component {

  state = {anchorEl : null}

  handleClick = (event) => {
    this.setState( {anchorEl : event.currentTarget} );
  };

  handleClose = () => {
    this.setState( {anchorEl : null} );
  };

  handleDelete = () => {
    this.setState( {anchorEl : null} );
    this.props.deleteNotification({ data:this.props._id });
  };

  render(){
    const open = Boolean(this.state.anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
      <div>
        <figure aria-describedby={id} variant="contained" color="primary" onClick={this.handleClick} className="m-0">
          <img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/options-icon.png" />
        </figure>
        <Popover
          className="pop-over"
          id={id}
          open={open}
          anchorEl={this.state.anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          >
          <Typography onClick={this.handleDelete} className={classes.typography}>Remove from notifications</Typography>
        </Popover>
      </div>
    );
  }
}


const mapDispatchToProps = {
  deleteNotification
};

export default connect(null, mapDispatchToProps)(PopOver);

import React from 'react';
import moment from "moment";
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';

import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import TablePagination from '@material-ui/core/TablePagination';

import { getHistoryDW } from "../../../../store/actions/Auth";
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { otpLoader, overlayLoader } from "../../../../store/actions/Settings";
import Loader from '../../../../components/AppLoader';

class InvestHistory extends React.Component {
  state = {
    filter: "1y",
    rowsPerPage:10,
    page:0,
    history:this.props.history && this.props.history.Invest  ? this.props.history.Invest : [],
    message: 'Loading your Invest History',
  }

  componentDidMount() {
    this.props.overlayLoader({status:true, loadingMessage:'Loading your Invest History'})
    this.props.getHistoryDW();
    // this.props.otpLoader(true)
  }

  componentWillReceiveProps(props) {
    if(props && props.history) {
      this.setState({history: props.history.Invest})
    }
  }

  handleChangePage = (event, newPage) => {
    this.setState({page:newPage})
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage : parseInt(event.target.value, 10) });
    this.setState({page:0})
  }

  // static getDerivedStateFromProps = (nextProps, prevState) => {
  //   if (nextProps.transactionHistoryData !== prevState.transactionHistoryData) {
  //     let { transactionHistoryData } = nextProps;
  //     if (transactionHistoryData) return { transactionHistoryData };
  //   }
  //   else return null; // Triggers no change in the state
  // }

  selectTimePeriod = (value) => this.setState({ value })

  render() {

    const classes = {};
    const { value, rowsPerPage, page, message } = this.state;
    let { history, showOtpLoader } = this.props;
    return (
      <div className="scroll-div">
        {showOtpLoader ? <Loader message={message} /> :
        <TableContainer component={Paper} className="transaction-tabel">
          <div className="transaction-header">
          </div>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Property</TableCell>
                <TableCell align="center">AMOUNT</TableCell>
                <TableCell align="center">STATUS</TableCell>
                <TableCell align="center">Offering Close</TableCell>
                <TableCell align="center">Invest DATE</TableCell>
                <TableCell align="center">Transaction</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.history && this.state.history.length > 0 ?
                (rowsPerPage > 0
                  ? this.state.history.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : this.state.history
                ).map((row) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {row.name}
                      <div className="about-propert ">
                        <Link to={`/single-property?id=${row['propertyId']}`} style={{ textDecoration: 'none' }}><img className="coin-image" src="https://tknismtest.s3.amazonaws.com/non-compressed-images/49.png" alt="" />{row['symbol']} </Link>
                      </div>
                    </TableCell>
                    <TableCell align="center"><NumberFormat value={row['amount']} displayType={'text'} thousandSeparator={true} /></TableCell>
                    <TableCell align="center">{row.status}</TableCell>
                    <TableCell align="center">{moment(row['closeTime']).format('lll')}</TableCell>
                    <TableCell align="center">{moment(row['createdAt']).format('lll')}</TableCell>
                    <TableCell align="center"><a href={row.viewTransaction} target="_blank">View</a></TableCell>
                  </TableRow>
                )):
                this.state.history && this.state.history.length != 0 ?
                <TableRow>
                  <TableCell colSpan={12}>
                    <Loader />
                  </TableCell>
                 </TableRow>
                :
                <TableRow>
                <TableCell colSpan={8}>
                    <div className={'col-12 text-center mt-5'}>
                      <img
                        src="https://tknismtest.s3.amazonaws.com/non-compressed-images/47.png"
                        alt=""
                        />
                    </div>
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
          <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              colSpan={3}
              rowsPerPage={rowsPerPage}
              page={page}
              count={this.state.history ? this.state.history.length : 0}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              ActionsComponent={this.TablePaginationActions}
            />
        </TableContainer>
        }

      </div>
    );
  }
}

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

const TablePaginationActions = (props) => {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}


const mapStateToProps = ({ Auth, Settings }) => {
  let { walletHistoryData } = Auth;
  let { showOtpLoader } = Settings;

  return { history: walletHistoryData || {}, showOtpLoader };
};
const mapDispatchToProps = { getHistoryDW, otpLoader, overlayLoader };
export default connect(mapStateToProps, mapDispatchToProps)(InvestHistory);

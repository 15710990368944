
(function($) {
	
	"use strict";

	
	
	//Update Header Style and Scroll to Top
	function headerStyle() {
		if($('.main-header').length){
			var windowpos = $(window).scrollTop();
			var siteHeader = $('.main-header');
			var sticky_header = $('.main-header .sticky-header');
			var scrollLink = $('.scroll-to-top');
			if (windowpos > 55) {
				siteHeader.addClass('fixed-header');
				sticky_header.addClass("animated slideInDown");
				scrollLink.fadeIn(300);
			} else {
				siteHeader.removeClass('fixed-header');
				sticky_header.removeClass("animated slideInDown");
				scrollLink.fadeOut(300);
			}
		}
	}


	$(function(){
		var lastScrollTop = 0, delta = 5;
		$(window).scroll(function(){
			var nowScrollTop = $(this).scrollTop();
			var siteHeader = $('.main-header');
			var isUP = nowScrollTop > lastScrollTop
			if(Math.abs(lastScrollTop - nowScrollTop) >= delta){
				if (!isUP){
					siteHeader.removeClass('isUp');
				}else{
					siteHeader.addClass('isUp');
				}
			lastScrollTop = nowScrollTop;
			}
		});
	});

	$(document).on("wheel", "input[type=number]", function (e) {
		$(this).blur();
	});


	// $(function(){

	// $(".fa fa-arrow-up").on("click", function() {
	// 	console.log('click')
	// 	$("body").scrollTop(0);
	// });

	// })

	$(window).scroll(function() {
		var header = $(document).scrollTop();
		var headerHeight = $(".stcikybanner").outerHeight();
		if (header > headerHeight) {
		  $(".stcikybanner").addClass("fixed");
		} else {
		  $(".stcikybanner").removeClass("fixed");
		}
	  });


	//Submenu Dropdown Toggle
	if($('.main-header li.dropdown ul').length){
		$('.main-header li.dropdown').append('<div class="dropdown-btn"><span class="fa fa-angle-down"></span></div>');
		
		//Dropdown Button
		$('.main-header li.dropdown .dropdown-btn').on('click', function() {
			$(this).prev('ul').slideToggle(500);
		});
	}
	
	
	//News Carousel
	if ($('.news-carousel').length) {
		$('.news-carousel').owlCarousel({
			loop:true,
			margin:30,
			nav:true,
			smartSpeed: 700,
			autoplay: true,
			navText: [ '<span class="icon"><img src="./images/21.png"></span>', '<span class="icon"><img src="./images/20.png"></span>' ],
			responsive:{
				0:{
					items:1
				},
				767:{
					items:1
				},
				1024:{
					items:1
				}
			}
		});    		
	}
	


/* ==========================================================================
   When document is Scrollig, do
   ========================================================================== */
	
	$(window).on('scroll', function() {
		headerStyle();
		
	});


})(window.jQuery);



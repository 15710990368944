import axios from "axios";
import EventBus from "eventing-bus";
import { userDashHeader } from "../actions/Auth";
import { put, all, takeLeading, call } from "redux-saga/effects";
import { profileResponse, otpLoader, gaLoader, handleVerificationModal } from "../actions/Settings";
import { favouriteProperties } from "../actions/Auth";
import { overlayLoader, withdrawAuthenticated } from "../actions/Settings";

function* onGoogleAuthentication() {
    const { error, response } = yield call(getCall, "/user/enable2Factor");
    if (error) EventBus.publish("error", error["response"]["data"]["message"]);
    if (response) {
        yield put({
            type: "GOOGLE_AUTHENTICATION_RESPONSE",
            payload: response.data.body
        });
    }
}

function* getAllEvents() {
    const { error, response } = yield call(getCall, "/event/getAllEvents");
    if (error) EventBus.publish("error", error["response"]["data"]["message"]);
    if (response) {
        yield put({
            type: "GET_ALL_MEDIA",
            payload: response.data.body
        });
    }
}

function* onVerify2Factor({ payload }) {
    yield put(gaLoader(true));
    const { error, response } = yield call(postCall, { path: "/user/verify2Factor", body: payload });
    yield put(gaLoader(false));
    if (error) EventBus.publish("error", error["response"]["data"]["message"]);
    else if (response) {
        EventBus.publish("success", response.data.message);
        yield put(profileResponse(response["data"]["body"]));
        yield put({
            type: "SHOW_GA_MODAL",
            payload: false
        });
    }
}

function* verifySMSLink({ payload }) {
    const { error, response } = yield call(postCall, { path: "/user/smsImageLink", body: payload });
    if (error) EventBus.publish("error", error["response"]["data"]["message"]);
    else if (response) EventBus.publish("success", response['data']['message']);
}

function* onVerifyOTP({ payload }) {
    // yield put(otpLoader(true));
    const { error, response } = yield call(postCall, { path: "/user/enableSms", body: payload });
    yield put(otpLoader(false));
    if (error) EventBus.publish("error", error["response"]["data"]["message"]);
    else if (response) {
        EventBus.publish("success", response.data.message);
        yield put(profileResponse(response["data"]["body"]));
        yield put(handleVerificationModal(false));
    }
}

function* onDisable2Factor() {
    const { error, response } = yield call(getCall, "/user/disable2Factor");
    if (error) EventBus.publish("error", error["response"]["data"]["message"]);
    if (response) {
        EventBus.publish("success", response.data.message);
        yield put(profileResponse(response["data"]["body"]));
    }
}

function* verifyKycLink({ history }) {
    const { error, response } = yield call(getCall, "/user/submitKYC");
    if (error) EventBus.publish("error", error["response"]["data"]["message"]);
    if (response) {
        EventBus.publish("success", response.data.message);
        history.push('/dashboard')
    }
}

function* uploadAvatar({ payload }) {
    const { error, response } = yield call(postCall, { path: "/user/uploadAvatar", body: payload });
    if (error) EventBus.publish("error", error["response"]["data"]["message"]);
    else if (response) {
        yield put(userDashHeader());
        yield put(profileResponse(response["data"]["body"]));
    }
}

function* onSendSMS({ payload }) {
    const { error, response } = yield call(postCall, { path: "/user/sendSMS", body: payload });
    if (error) EventBus.publish("error", error["response"]["data"]["message"]);
    else if (response) {
        yield put({
            type: "SEND_SMS_RESPONSE",
            payload: false
        });
        yield put(handleVerificationModal(true));

        // yield put(userDashboard());
        // yield put(profileResponse(response["data"]["body"]));
    }
}

function* onDisableSmsAuth() {
    const { error, response } = yield call(getCall, "/user/disableSmsAuth");
    if (error) EventBus.publish("error", error["response"]["data"]["message"]);
    else if (response) {
        EventBus.publish("success", response.data.message);
        yield put(profileResponse(response["data"]["body"]));
    }
}

function* getProfile() {
    const { error, response } = yield call(getCall, "/user/myProfile");
    if (error) EventBus.publish("error", error["response"]["data"]["message"]);
    else if (response) {
        yield put(userDashHeader());
        yield put(profileResponse(response["data"]["body"]));
    }
}

function* getAllPreferences() {
    const { error, response } = yield call(getCall, "/user/preferences");
    if (error) EventBus.publish("error", error["response"]["data"]["message"]);
    else if (response) {
        yield put({ type: "GET_PREFERENCES", payload: response["data"]["body"] });
    }
}

function* addInvestorPreferences({ payload }) {
    const { error, response } = yield call(postCall, { path: "/user/investPreferences", body: payload });
    if (error) EventBus.publish("error", error["response"]["data"]["message"]);
    else if (response) {
        yield put({ type: "GET_ALL_PREFERENCES", payload: response["data"]["body"] });
        yield put({ type: "OVERLAY_LOADER", payload: { status: false, loadingMessage: '' } })
    }
}

function* userSupport({ payload, history }) {
    const { error, response } = yield call(postCall, { path: "/user/support", body: payload });
    if (error) EventBus.publish("error", error["response"]["data"]["message"]);
    else if (response) {
        EventBus.publish("success", response['data']['message']);
        history.push('/help-center')
    }
}

function* addEmailPreferences({ payload }) {
    const { error, response } = yield call(postCall, { path: "/user/emailPreferences", body: payload });
    if (error) EventBus.publish("error", error["response"]["data"]["message"]);
    else if (response) {
        yield put({ type: "GET_ALL_PREFERENCES", payload: response["data"]["body"] });
        yield put({ type: "OVERLAY_LOADER", payload: { status: false, loadingMessage: '' } });
    }
}

function* getAllNotifications() {
    const { error, response } = yield call(getCall, "/notification");
    if (error) EventBus.publish("error", error["response"]["data"]["message"]);
    else if (response) {
        yield put({ type: "ALL_NOTIFICATIONS", payload: response["data"]["body"] });
    }
}

function* getGroupNotifications() {
    const { error, response } = yield call(getCall, "/notification/group");
    if (error) EventBus.publish("error", error["response"]["data"]["message"]);
    else if (response) {
        yield put({ type: "GROUP_NOTIFICATIONS", payload: response["data"]["body"] });
    }
}

function* viewAllNotifications() {
    const { error, response } = yield call(getCall, "/notification/view");
    if (error) EventBus.publish("error", error["response"]["data"]["message"]);
    else if (response) {}
}

function* deleteNotification({ payload }) {
    const { error, response } = yield call(deleteCall, "/notification/" + payload.data);
    if (error) EventBus.publish("error", error["response"]["data"]["message"]);
    else if (response) {
        if (response) {
            yield put({ type: "GET_GROUP_NOTIFICATIONS" });
            yield put({ type: "GET_ALL_NOTIFICATIONS" });
        }
    }
}

function* viewSingleNotification({ payload }) {
    const { error, response } = yield call(getCall, "/notification/viewSingle/" + payload.data);
    if (error) EventBus.publish("error", error["response"]["data"]["message"]);
    else if (response) {
        if (response) {
            yield put({ type: "GET_ALL_NOTIFICATIONS" });
        }
    }
}

function* favourite({ payload, onFavourites }) {
    const { error, response } = yield call(getCall, "/property/addFavorite/" + payload);
    if (error) EventBus.publish("error", error["response"]["data"]["message"]);
    else if (response) {
        setTimeout(() => EventBus.publish("success", response.data.message), 500);
        yield put(favouriteProperties({ status: "launched", onFavourites: onFavourites }));

        // if(onFavourites) setTimeout(() => EventBus.publish("onFavourites", onFavourites), 1500);
    }
}

function* getUserDocs() {
    const { error, response } = yield call(getCall, "/property/userDoc");
    if (error) EventBus.publish("error", error["response"]["data"]["message"]);
    else if (response) {
        // setTimeout(() => EventBus.publish("success", response.data.message), 500);
        yield put({ type: "USER_DOCS", payload: response["data"]["body"] });
    }
    yield put(overlayLoader({ status: false, loadingMessage: '' }));
}

function* sendSMSDeposit({ payload }) {
    const { error, response } = yield call(getCall, "/user/authSMS/" + payload);
    if (error) EventBus.publish("error", error["response"]["data"]["message"]);
}

function* authSMS({ payload }) {
    yield put({ type: "OVERLAY_LOADER", payload: { status: true, loadingMessage: 'Authenticating Withdrawal' } })
    const { error, response } = yield call(postCall, { path: "/user/authSMS", body: payload });
    if (error) {
        EventBus.publish("error", error["response"]["data"]["message"])
        yield put({ type: "OVERLAY_LOADER", payload: { status: false, loadingMessage: '' } })
    } else if (response) {
        yield put(withdrawAuthenticated({ status: true }));
        // EventBus.publish("withdrawAuthenticated", "withdraw");
        EventBus.publish("success", response['data']['message']);
        yield put({ type: "OVERLAY_LOADER", payload: { status: true, loadingMessage: 'Transaction Processing' } })
            // EventBus.unregisterCallbacksForEvent('withdrawAuthenticated');
    }
}

function* actionWatcher() {
    yield takeLeading("UPLOAD_AVATAR", uploadAvatar);
    yield takeLeading("GET_PROFILE", getProfile);
    yield takeLeading("VERIFY_SMS_LINK", verifySMSLink);
    yield takeLeading("GOOGLE_AUTHENTICATION", onGoogleAuthentication);
    yield takeLeading("VERIFY_TWO_FACTOR", onVerify2Factor);
    yield takeLeading("DISABLE_GOOGLE_AUTH", onDisable2Factor);
    yield takeLeading("SEND_SMS", onSendSMS);
    yield takeLeading("VERIFY_OTP", onVerifyOTP);
    yield takeLeading("DISABLE_SMS_AUTH", onDisableSmsAuth);
    yield takeLeading("GET_KYC", verifyKycLink);
    yield takeLeading("USER_SUPPORT", userSupport);
    yield takeLeading("INVESTOR_PREFERENCES", addInvestorPreferences);
    yield takeLeading("EMAIL_PREFERENCES", addEmailPreferences);
    yield takeLeading("GET_ALL_PREFERENCES", getAllPreferences);
    yield takeLeading("GET_ALL_NOTIFICATIONS", getAllNotifications);
    yield takeLeading("GET_GROUP_NOTIFICATIONS", getGroupNotifications);
    yield takeLeading("VIEW_ALL_NOTIFICATIONS", viewAllNotifications);
    yield takeLeading("DELETE_NOTIFICATION", deleteNotification);
    yield takeLeading("VIEW_SINGLE_NOTIFICATION", viewSingleNotification);
    yield takeLeading("FAVOURITE_ITEM", favourite);
    yield takeLeading("GET_MEDIA", getAllEvents);
    yield takeLeading("GET_USER_DOCS", getUserDocs);
    yield takeLeading("SEND_SMS_DEPOSIT", sendSMSDeposit);
    yield takeLeading("AUTH_SMS", authSMS);
}

function postCall({ body, path }) {
    return axios
        .post(path, body)
        .then(response => ({ response }))
        .catch(error => {
            if (error.response.status === 401) {
                EventBus.publish("tokenExpired");
                return;
            }
            return { error };
        });
}

function getCall(path) {
    return axios
        .get(path)
        .then(response => ({ response }))
        .catch(error => {
            if (error.response.status === 401) {
                EventBus.publish("tokenExpired");
                return;
            }
            return { error };
        });
}

function deleteCall(path) {
    return axios
        .delete(path)
        .then(response => ({ response }))
        .catch(error => {
            if (error.response.status === 401) {
                EventBus.publish("tokenExpired");
                return;
            }
            return { error };
        });
}


export default function* rootSaga() {
    yield all([actionWatcher()]);
}
import axios from "axios";
import EventBus from "eventing-bus";
import { getBalance } from "../actions/Auth";
import { put, all, takeLeading, call } from "redux-saga/effects";
import { getAllNotifications, otpLoader, overlayLoader } from "../actions/Settings";
import { responseUserTokenBalance, sellOffersReceived, responseActiveOffers, responseTransactionHistory, responseUserListingBalance } from "../actions/Exchange";

function* getUserTokenBalance() {
  const { error, response } = yield call(getCall, "/wallet/balance");
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else if (response) yield put(responseUserTokenBalance(response["data"]["body"]));
}

function* getUserListingBalance() {
  const { error, response } = yield call(getCall, "/wallet/listing");
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else if (response) yield put(responseUserListingBalance(response["data"]["body"]));
  yield put(overlayLoader({ status: false, loadingMessage: '' }));
}

function* activeOffers() {
  const { error, response } = yield call(getCall, "/exchange/active");
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else if (response) yield put(responseActiveOffers(response["data"]["body"]));
  yield put(overlayLoader({ status: false, loadingMessage: '' }));
}

function* cancelContract({ payload }) {
  const { error, response } = yield call(postCall, { path: `/exchange/cancelContract`, body: payload });
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else if (response) EventBus.publish("success", response["data"]["message"]);
  yield put({ type: 'ACTIVE_OFFERS' });
  EventBus.publish("tokensListed", "transactionHistory");
}

function* cancelOffer({ payload }) {
  const { error, response } = yield call(postCall, { path: `/exchange/cancelOffer`, body: payload });
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else if (response) EventBus.publish("success", response["data"]["message"]);
  yield put({ type: 'ACTIVE_OFFERS' });
  EventBus.publish("tokensListed", "transactionHistory");
}

function* acceptCounter({ payload }) {
  const { error, response } = yield call(postCall, { path: `/exchange/acceptCounter`, body: payload });
  if (error)
    EventBus.publish("error", error["response"]["data"]["message"]);
  else if (response) EventBus.publish("success", response["data"]["message"]);
  yield put({ type: 'ACTIVE_OFFERS' });
  // yield put(otpLoader(false));
  yield put(overlayLoader({ status: false, loadingMessage: '' }));
  EventBus.publish("tokensListed", "transactionHistory");
}

function* rejectCounter({ payload }) {
  const { error, response } = yield call(postCall, { path: `/exchange/rejectCounter`, body: payload });
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else if (response) EventBus.publish("success", response["data"]["message"]);
  yield put({ type: 'ACTIVE_OFFERS' });
  // yield put(otpLoader(false));
  yield put(overlayLoader({ status: false, loadingMessage: '' }));
  EventBus.publish("tokensListed", "transactionHistory");
}

function* counterSeller({ payload }) {
  const { error, response } = yield call(postCall, { path: `/exchange/counterSeller`, body: payload });
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else if (response) EventBus.publish("success", response["data"]["message"]);
  yield put({ type: 'ACTIVE_OFFERS' });
  // yield put(otpLoader(false));
  yield put(overlayLoader({ status: false, loadingMessage: '' }));
}

function* counterBuyer({ payload }) {
  const { error, response } = yield call(postCall, { path: `/exchange/counterBuyer`, body: payload });
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else if (response) EventBus.publish("success", response["data"]["message"]);
  yield put({ type: 'ACTIVE_OFFERS' });
  // yield put(otpLoader(false));
  yield put(overlayLoader({ status: false, loadingMessage: '' }));
}

function* sellTokens({ payload }) {
  const { error, response } = yield call(postCall, { path: `/exchange/sellTokens`, body: payload });
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else if (response) {
    EventBus.publish("success", response["data"]["message"]);
    EventBus.publish("tokensListed", "dealRoom");
    yield put(getAllNotifications());
  }
  yield put(overlayLoader({ status: false, loadingMessage: '' }));

}

function* buyOffer({ payload }) {
  const { error, response } = yield call(postCall, { path: `/exchange/buyOffer`, body: payload });
  if (error) {
    EventBus.publish("error", error["response"]["data"]["message"]);
    yield put(overlayLoader({ status: false, loadingMessage: '' }));
  }
  else if (response) {
    EventBus.publish("success", response["data"]["message"]);
    yield put(getBalance());
    yield put({ type: 'SELL_OFFERS', payload: payload['id'] })
    yield put(overlayLoader({ status: false, loadingMessage: '' }));
  }
}

function* buyCounter({ payload, history }) {
  const { error, response } = yield call(postCall, { path: `/exchange/buyCounter`, body: payload });
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else if (response) {
    EventBus.publish("success", response["data"]["message"]);
    setTimeout(() => {
      history.push("/dashboard/portfolio");
      setTimeout(() => EventBus.publish("tokensListed", "dealRoom"), 300)
    }, 500);
    yield put(overlayLoader({ status: false, loadingMessage: '' }));
  }
}

function* sellOffers({ payload }) {
  const { error, response } = yield call(getCall, `/exchange/sellOffers/${payload}`);
  if (error) {
    EventBus.publish("error", error["response"]["data"]["message"]);
    yield put(otpLoader(false));
  }
  else if (response) {
    yield put(otpLoader(false));
    yield put(sellOffersReceived(response["data"]["body"]));
  }
}

function* transactionHistory() {
  const { error, response } = yield call(getCall, `/exchange/history`);
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else if (response) {
    yield put(responseTransactionHistory(response["data"]["body"]));
  }
  yield put(overlayLoader({ status: false, loadingMessage: '' }));
}

function* actionWatcher() {
  yield takeLeading("BUY_OFFER", buyOffer);
  yield takeLeading("BUY_COUNTER", buyCounter);
  yield takeLeading("SELL_TOKENS", sellTokens);
  yield takeLeading("SELL_OFFERS", sellOffers);
  yield takeLeading("CANCEL_OFFER", cancelOffer);
  yield takeLeading("CANCEL_CONTRACT", cancelContract);
  yield takeLeading("ACTIVE_OFFERS", activeOffers);
  yield takeLeading("REJECT_COUNTER", rejectCounter);
  yield takeLeading("COUNTER_BUYER", counterBuyer);
  yield takeLeading("COUNTER_SELLER", counterSeller);
  yield takeLeading("ACCEPT_COUNTER", acceptCounter);
  yield takeLeading("TRANSACTION_HISTORY", transactionHistory);
  yield takeLeading("GET_USER_TOKEN_BALANCE", getUserTokenBalance);
  yield takeLeading("GET_USER_LISTING_BALANCE", getUserListingBalance);
}

function postCall({ body, path }) {
  return axios
    .post(path, body)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) {
        EventBus.publish("tokenExpired");
        return;
      }
      return { error };
    });
}

function getCall(path) {
  return axios
    .get(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) {
        EventBus.publish("tokenExpired");
        return;
      }
      return { error };
    });
}

export default function* rootSaga() {
  yield all([actionWatcher()]);
}

import React, { Component } from 'react';

import { Modal, ModalBody } from 'reactstrap';

class ModalCustom extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            isOpen: false,
            modalLogin:false
        }
        window.scrollTo(0, 0);
    }

    toggle = ()=>{
      this.setState({modalLogin:!this.state.modalLogin})
    }


    render() {
        return (
            <div className="page-wrapper">
            <ModalCustom>
            {
              this.props.loginModal
              ?
              <Modal
                modalClassName="modal-mini modal-info"
                toggle={this.toggle}
                isOpen={this.state.modalLogin}
                >
                <div className="modal-header justify-content-center">
                  <div className="modal-profile">
                    <img className='modalLogoImg' src="https://tknismtest.s3.amazonaws.com/non-compressed-images/favicon.png.png" alt="" />
                  </div>
                </div>
                <ModalBody>
                  <p>Look's like you are not logged in.</p>
                </ModalBody>
                <div className="modal-footer">
                  <div className="invest-btn-area">
                      <a href="#" className="thm-btn btn-style-modal"> Login </a>
                  </div>

                  <div className="invest-btn-area">
                      <a href="#" className="thm-btn btn-style-modal"> Get Started </a>
                  </div>


                </div>
              </Modal>
              :
              null
            }
            </ModalCustom>

            </div>
        );
    }
}


export default ModalCustom;

import React, { Component } from 'react';

import axios from "axios";
import EventBus from 'eventing-bus';
import { connect } from 'react-redux';
import { PlaidLink } from "react-plaid-link";
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import { loginRequest, signAddBank, logout, plaidUpload, signSkipBank, plaidSetup } from '../../../store/actions/Auth';
import ReactGA from 'react-ga';

const brnv = require('bank-routing-number-validator');

class AccountDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pageContent: "",
      isDisabled: false,
      plaidCheck: true,
      manualCheck: false,
      isRoutingValid: true,
      accountsMatched: true,
      isValidName: true,
      formData: {
        routingNo: '',
        accountNo: ''
      }
    }
    props.plaidSetup(this.props.signData.hash);
    window.scrollTo(0, 0);
  }

  componentDidMount() {
    
    if(this.props.allSignData.step2.citizenship == "United States"){
      EventBus.on("AccountDetails", () => this.setState({ isDisabled: false }));
      if (this.props.allSignData.step3 && Object.keys(this.props.allSignData.step3).length > 0) {
        const { formData } = this.state;
        let myStep = this.props.allSignData.step3
        formData['accountType'] = myStep.accountType;
        formData['bankName'] = myStep.bankName;
        formData['routingNo'] = myStep.routingNo;
        formData['accountNo'] = myStep.accountNo;
        formData['confirmAccountNumber'] = myStep.accountNo;
        if (this.props.allSignData.step3.hasOwnProperty('accountNo')) {
          this.setState({ plaidCheck: false, manualCheck: true });
        }
        this.setState({ formData });
      }
      ReactGA.pageview('/signup-account-details');
    }else {
        this.skipAccounts()
    }
  }

  onSuccess = (public_token, metadata) => {
    this.setState({ isDisabled: true });
    metadata['hash'] = this.props.signData.hash;
    this.props.plaidUpload(metadata)
    // axios.post("/auth/public_token", {public_token});
    // EventBus.publish("success", `Successfully attached Bank Account!`);
  }

  onEvent = (e) => {
  }

  onExit = (e) => {
    // EventBus.publish("error", `Exited from plaid without payment`);
  }

  handleAddBank = (value) => {
    const { formData } = this.state;
    formData['hash'] = this.props.signData.hash;
    if (this.state.formData.confirmAccountNumber != this.state.formData.accountNo) {
      EventBus.publish("error", `Account Number is not same.`);
      this.setState({ accountsMatched: false });
    } else {
      if (this.state.isValidName) {
        this.props.signAddBank(formData)
        this.setState({ isDisabled: true });
      }
    }
  }



  skipAccounts = (value) => {
    this.setState({ isDisabled: true });
    this.props.signSkipBank(this.props.signData.hash)
  }

  handleFormChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
    if (event.target.name == 'routingNo') {
      let isRoutingValid = brnv.ABARoutingNumberIsValid(event.target.value) && event.target.value.length === 9
      this.setState({ isRoutingValid: isRoutingValid });
    } else if (event.target.name == 'bankName') {
      if (event.target.value !== '' && event.target.value !== undefined && !/[^a-zA-Z ]/.test(event.target.value)) this.setState({ isValidName: true })
      else this.setState({ isValidName: false })
    }
  }


  // handleFormChangeAccountNo = (event)=>
  // {
  //   console.log('event', event);
  //   const { formData } = this.state;
  //   formData[event.target.name] = event.target.value;
  //
  //   const script = document.createElement("script");
  //
  //   script.src = "https://path/to/us-bank-account-validator.js";
  //   script.async = true;
  //
  //   document.body.appendChild(script);
  //
  //   var accountValidation = valid.accountNumber('4111');
  //
  //   if (accountValidation.isValid) {
  //     console.log('valid');
  //   } else if (!accountValidation.isPotentiallyValid) {
  //     console.log('invalid');
  //
  //   }
  // }

  handleAccountMatch = () => {
    if (this.state.formData.confirmAccountNumber != this.state.formData.accountNo) {
      this.setState({ accountsMatched: false })
    } else {
      this.setState({ accountsMatched: true })
    }
  }

  handleCheckManual = (event) => {
    if (event.target.value == 'true') {
      this.setState({ manualCheck: false, plaidCheck: false });
    } else this.setState({ manualCheck: true, plaidCheck: false });
  }

  handleCheckPlaid = (event) => {
    if (event.target.value == 'true') {
      this.setState({ plaidCheck: false, manualCheck: false });
    } else {
      this.setState({ plaidCheck: true, manualCheck: false });
    }
  }

  // ins_1000000
  goBack = () => this.props.onBack(2);

  handleChange = (e) => {
    e.preventDefault();
  };

  render() {
    console.log('this.props', this.props.allSignData);
    let { plaidDataSignUp } = this.props;

    return (
      <section className="signUp-sec">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="content-area">
                <div className="pb-2">
                  <h1 className="signUpInnerTitles">Account Details</h1>
                  <hr />
                </div>
                <div className="col-12" >
                  <ValidatorForm className="validatorForm">
                    <div className="row">
                      <div className="col-12">
                        <FormControlLabel
                          checked={this.state.plaidCheck}
                          value={this.state.plaidCheck}
                          onChange={() => this.setState({ plaidCheck: !this.state.plaidCheck, manualCheck: false })}
                          className="formHeading"
                          control={<Checkbox color="primary" />}
                          label="Connect your bank automatically"
                          labelPlacement="end"
                          errormessages={['Please agree to the terms']}
                          name="plaidCheck"
                        />
                      </div>
                      {
                        this.state.plaidCheck
                          ?
                          <div className="row pl-3 pr-3">
                            {
                              plaidDataSignUp['link_token'] &&
                              <>
                            <div className="col-sm-6 col-md-6 col-lg-4 pb-3">
                              <div className="plaidBoxes">
                                  <PlaidLink
                                    token={plaidDataSignUp['link_token']}
                                    onExit={this.onExit}
                                    onSuccess={this.onSuccess}
                                    type="button"
                                    style={{ border: "0px !important" }}
                                    >
                                    <a className="pt-1">
                                      <img height={100} width={100} src="https://tknismtest.s3.amazonaws.com/non-compressed-images/bank-of-america.svg" alt="" />
                                    </a>
                                  </PlaidLink>
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-4">
                              <div className="plaidBoxes">
                                <PlaidLink
                                  token={plaidDataSignUp['link_token']}
                                  onExit={this.onExit}
                                  onSuccess={this.onSuccess}
                                  type="button"
                                  style={{ border: "0px !important" }}
                                  >
                                  <a className="pt-1">
                                    <img height={100} width={100} src="https://tknismtest.s3.amazonaws.com/non-compressed-images/chase.svg" alt="" />
                                  </a>
                                </PlaidLink>
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-4">
                              <div className="plaidBoxes">
                                <PlaidLink
                                  token={plaidDataSignUp['link_token']}
                                  onExit={this.onExit}
                                  onSuccess={this.onSuccess}
                                  type="button"
                                  style={{ border: "0px !important" }}
                                  >
                                  <a className="pt-1">
                                    <img height={100} width={100} src="https://tknismtest.s3.amazonaws.com/non-compressed-images/wells-fargo.svg" alt="" />
                                  </a>
                                </PlaidLink>
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-4 pb-3">
                              <div className="plaidBoxes">
                                <PlaidLink
                                  token={plaidDataSignUp['link_token']}
                                  onExit={this.onExit}
                                  onSuccess={this.onSuccess}
                                  type="button"
                                  style={{ border: "0px !important" }}
                                  >
                                  <a className="pt-1">
                                    <img height={100} width={100} src="https://tknismtest.s3.amazonaws.com/non-compressed-images/ally-sm2.svg" alt="" />
                                  </a>
                                </PlaidLink>
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-4">
                              <div className="plaidBoxes">
                                <PlaidLink
                                  token={plaidDataSignUp['link_token']}
                                  onExit={this.onExit}
                                  onSuccess={this.onSuccess}
                                  type="button"
                                  style={{ border: "0px !important" }}
                                  >
                                  <a className="pt-1">
                                    <img height={100} width={100} src="https://tknismtest.s3.amazonaws.com/non-compressed-images/usaa.svg" alt="" />
                                  </a>
                                </PlaidLink>
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-4">
                              <div className="plaidBoxes">
                                <PlaidLink
                                  token={plaidDataSignUp['link_token']}
                                  onExit={this.onExit}
                                  onSuccess={this.onSuccess}
                                  type="button"
                                  style={{ border: "0px !important" }}
                                  >
                                  <a className="pt-1">
                                    <img height={100} width={100} src="https://tknismtest.s3.amazonaws.com/non-compressed-images/pnc.svg" alt="" />
                                  </a>
                                </PlaidLink>
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-4 pb-3">
                              <div className="plaidBoxes">
                                <PlaidLink
                                  token={plaidDataSignUp['link_token']}
                                  onExit={this.onExit}
                                  onSuccess={this.onSuccess}
                                  type="button"
                                  style={{ border: "0px !important" }}
                                  >
                                  <a className="pt-1">
                                    <img height={100} width={100} src="https://tknismtest.s3.amazonaws.com/non-compressed-images/citi.svg" alt="" />
                                  </a>
                                </PlaidLink>
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-4">
                              <div className="plaidBoxes">
                                <PlaidLink
                                  token={plaidDataSignUp['link_token']}
                                  onExit={this.onExit}
                                  onSuccess={this.onSuccess}
                                  type="button"
                                  style={{ border: "0px !important" }}
                                  >
                                  <a className="pt-1">
                                    <img height={100} width={100} src="https://tknismtest.s3.amazonaws.com/non-compressed-images/charles-schwab.svg" alt="" />
                                  </a>
                                </PlaidLink>
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-4">
                              <div className="plaidBoxes">
                                <PlaidLink
                                  token={plaidDataSignUp['link_token']}
                                  onExit={this.onExit}
                                  onSuccess={this.onSuccess}
                                  type="button"
                                  style={{ border: "0px !important" }}
                                  >
                                  <a className="pt-1">
                                    <img height={100} width={100} src="https://tknismtest.s3.amazonaws.com/non-compressed-images/td.svg" alt="" />
                                  </a>
                                </PlaidLink>
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-4 pb-3">
                              <div className="plaidBoxes">
                                <PlaidLink
                                  token={plaidDataSignUp['link_token']}
                                  onExit={this.onExit}
                                  onSuccess={this.onSuccess}
                                  type="button"
                                  style={{ border: "0px !important" }}
                                  >
                                  <a className="pt-1">
                                    <img height={100} width={100} src="https://tknismtest.s3.amazonaws.com/non-compressed-images/usbank2.svg" alt="" />
                                  </a>
                                </PlaidLink>
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-4">
                              <div className="plaidBoxes">
                                <PlaidLink
                                  token={plaidDataSignUp['link_token']}
                                  onExit={this.onExit}
                                  onSuccess={this.onSuccess}
                                  type="button"
                                  style={{ border: "0px !important" }}
                                  >
                                  <a className="pt-1">
                                    <img height={100} width={100} src="https://tknismtest.s3.amazonaws.com/non-compressed-images/navy-federal.svg" alt="" />
                                  </a>
                                </PlaidLink>
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-4">
                              <div className="plaidBoxes" style={{ height: 100, paddingTop: '30px' }}>
                                <PlaidLink
                                  token={plaidDataSignUp['link_token']}
                                  onExit={this.onExit}
                                  onSuccess={this.onSuccess}
                                  type="button"
                                  style={{ border: "0px !important" }}
                                  >
                                  <i className="fa fa-search"></i> <span>Search</span>
                                </PlaidLink>
                              </div>
                            </div>
                            </>
                          }

                            {/* <div className="col-sm-6 col-md-6 col-lg-4 pb-3">
                              <div className="plaidBoxes">
                                <PlaidLink
                                  env={plaidData['ENV']}
                                  clientName="Tokenism"
                                  product={plaidData['PRODUCTS']}
                                  publicKey={plaidData['PUBLIC_KEY']}
                                  onExit={this.onExit}
                                  onSuccess={this.onSuccess}
                                  onEvent={this.onEvent}
                                  className="plaidLink"
                                  type="button"
                                >
                                  <img height={100} width={100} src="https://tknismtest.s3.amazonaws.com/non-compressed-images/bank-of-america.svg" alt="" />
                                </PlaidLink>
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-4">
                              <div className="plaidBoxes">
                                <PlaidLink
                                  env={plaidData['ENV']}
                                  clientName="Tokenism"
                                  product={plaidData['PRODUCTS']}
                                  publicKey={plaidData['PUBLIC_KEY']}
                                  onExit={this.onExit}
                                  onSuccess={this.onSuccess}
                                  onEvent={this.onEvent}
                                  className="plaidLink"
                                  type="button"
                                >
                                  <img height={100} width={100} src="https://tknismtest.s3.amazonaws.com/non-compressed-images/chase.svg" alt="" />
                                </PlaidLink>
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-4">
                              <div className="plaidBoxes">
                                <PlaidLink
                                  clientName="Tokenism"
                                  env={plaidData['ENV']}
                                  product={plaidData['PRODUCTS']}
                                  publicKey={plaidData['PUBLIC_KEY']}
                                  onExit={this.onExit}
                                  onSuccess={this.onSuccess}
                                  onEvent={this.onEvent}
                                  className="plaidLink"
                                  type="button"
                                >
                                  <img height={100} width={100} src="https://tknismtest.s3.amazonaws.com/non-compressed-images/wells-fargo.svg" alt="" />
                                </PlaidLink>
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-4 pb-3">
                              <div className="plaidBoxes">
                                <PlaidLink
                                  clientName="Tokenism"
                                  env={plaidData['ENV']}
                                  product={plaidData['PRODUCTS']}
                                  publicKey={plaidData['PUBLIC_KEY']}
                                  onExit={this.onExit}
                                  onSuccess={this.onSuccess}
                                  onEvent={this.onEvent}
                                  className="plaidLink"
                                  type="button"
                                >
                                  <img height={100} width={100} src="https://tknismtest.s3.amazonaws.com/non-compressed-images/ally-sm2.svg" alt="" />
                                </PlaidLink>
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-4">
                              <div className="plaidBoxes">
                                <PlaidLink
                                  clientName="Tokenism"
                                  env={plaidData['ENV']}
                                  product={plaidData['PRODUCTS']}
                                  publicKey={plaidData['PUBLIC_KEY']}
                                  onExit={this.onExit}
                                  onSuccess={this.onSuccess}
                                  onEvent={this.onEvent}
                                  className="plaidLink"
                                  type="button"
                                >
                                  <img height={100} width={100} src="https://tknismtest.s3.amazonaws.com/non-compressed-images/usaa.svg" alt="" />
                                </PlaidLink>
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-4">
                              <div className="plaidBoxes">
                                <PlaidLink
                                  clientName="Tokenism"
                                  env={plaidData['ENV']}
                                  product={plaidData['PRODUCTS']}
                                  publicKey={plaidData['PUBLIC_KEY']}
                                  onExit={this.onExit}
                                  onSuccess={this.onSuccess}
                                  onEvent={this.onEvent}
                                  className="plaidLink"
                                  type="button"
                                >
                                  <img height={100} width={100} src="https://tknismtest.s3.amazonaws.com/non-compressed-images/pnc.svg" alt="" />
                                </PlaidLink>
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-4 pb-3">
                              <div className="plaidBoxes">
                                <PlaidLink
                                  clientName="Tokenism"
                                  env={plaidData['ENV']}
                                  product={plaidData['PRODUCTS']}
                                  publicKey={plaidData['PUBLIC_KEY']}
                                  onExit={this.onExit}
                                  onSuccess={this.onSuccess}
                                  onEvent={this.onEvent}
                                  className="plaidLink"
                                  type="button"
                                >
                                  <img height={100} width={100} src="https://tknismtest.s3.amazonaws.com/non-compressed-images/citi.svg" alt="" />
                                </PlaidLink>
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-4">
                              <div className="plaidBoxes">
                                <PlaidLink
                                  clientName="Tokenism"
                                  env={plaidData['ENV']}
                                  product={plaidData['PRODUCTS']}
                                  publicKey={plaidData['PUBLIC_KEY']}
                                  onExit={this.onExit}
                                  onSuccess={this.onSuccess}
                                  onEvent={this.onEvent}
                                  className="plaidLink"
                                  type="button"
                                >
                                  <img height={100} width={100} src="https://tknismtest.s3.amazonaws.com/non-compressed-images/charles-schwab.svg" alt="" />
                                </PlaidLink>
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-4">
                              <div className="plaidBoxes">
                                <PlaidLink
                                  clientName="Tokenism"
                                  env={plaidData['ENV']}
                                  product={plaidData['PRODUCTS']}
                                  publicKey={plaidData['PUBLIC_KEY']}
                                  onExit={this.onExit}
                                  onSuccess={this.onSuccess}
                                  onEvent={this.onEvent}
                                  className="plaidLink"
                                  type="button"
                                >
                                  <img height={100} width={100} src="https://tknismtest.s3.amazonaws.com/non-compressed-images/td.svg" alt="" />
                                </PlaidLink>
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-4 pb-3">
                              <div className="plaidBoxes">
                                <PlaidLink
                                  clientName="Tokenism"
                                  env={plaidData['ENV']}
                                  product={plaidData['PRODUCTS']}
                                  publicKey={plaidData['PUBLIC_KEY']}
                                  onExit={this.onExit}
                                  onSuccess={this.onSuccess}
                                  onEvent={this.onEvent}
                                  className="plaidLink"
                                  type="button"
                                >
                                  <img height={100} width={100} src="https://tknismtest.s3.amazonaws.com/non-compressed-images/usbank2.svg" alt="" />
                                </PlaidLink>
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-4">
                              <div className="plaidBoxes">
                                <PlaidLink
                                  clientName="Tokenism"
                                  env={plaidData['ENV']}
                                  product={plaidData['PRODUCTS']}
                                  publicKey={plaidData['PUBLIC_KEY']}
                                  onExit={this.onExit}
                                  onSuccess={this.onSuccess}
                                  onEvent={this.onEvent}
                                  className="plaidLink"
                                  type="button"
                                >
                                  <img height={100} width={100} src="https://tknismtest.s3.amazonaws.com/non-compressed-images/navy-federal.svg" alt="" />
                                </PlaidLink>
                              </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-4">
                              <div className="plaidBoxes" style={{ height: 100, paddingTop: '30px' }}>
                                <PlaidLink
                                  clientName="Tokenism"
                                  env={plaidData['ENV']}
                                  product={plaidData['PRODUCTS']}
                                  publicKey={plaidData['PUBLIC_KEY']}
                                  onExit={this.onExit}
                                  onSuccess={this.onSuccess}
                                  onEvent={this.onEvent}
                                  className="plaidLink"
                                  type="button"
                                >
                                  <i className="fa fa-search"></i> <span>Search</span>
                                </PlaidLink>
                              </div>
                            </div>
                         */}
                          </div>
                          :
                          ''
                      }

                      {/*
                        this.state.plaidCheck
                        ?
                        <PlaidLink
                          className="thm-btn btn-style-one m-3"
                                            env= {plaidData['ENV']}
                          product= {plaidData['PRODUCTS']}
                          publicKey= {plaidData['PUBLIC_KEY']}
                          onExit={this.onExit}
                          onSuccess={this.onSuccess}
                          className="plaidLink"
                          type="button"
                          >
                          Continue with plaid!
                        </PlaidLink>
                        :
                        null
                      */}
                    </div>

                  </ValidatorForm>
                </div>

                <div className="col-12" >
                  <ValidatorForm className="validatorForm" onSubmit={(value) => this.handleAddBank(value)} autoComplete="off">
                    <div className="row">

                      <div className="col-12">
                        <FormControlLabel
                          checked={this.state.manualCheck}
                          value={this.state.manualCheck}
                          onChange={() => this.setState({ plaidCheck: false, manualCheck: !this.state.manualCheck })}
                          className="formHeading"
                          control={<Checkbox color="primary" />}
                          label="Manually add Bank Account"
                          labelPlacement="end"
                          errormessages={['Please agree to the terms']}
                          name="manualCheck"
                        />
                      </div>

                      {
                        this.state.manualCheck
                          ?
                          <div className="row">


                            <div className="col-12 pt-3">
                              <FormControl variant="outlined"
                                label="Account Type"
                                margin="dense"
                                className="MyTextField"
                                fullWidth
                              >
                                <InputLabel htmlFor="outlined-age-native-simple">
                                  Account Type
                              </InputLabel>
                                <Select
                                  labelWidth={97}
                                  type="text"
                                  name="accountType"
                                  value={this.state.formData.accountType}
                                  onChange={this.handleFormChange}
                                >
                                  <li value="savings" className="p-2 selectOptions">Savings</li>
                                  <li value="checking" className="p-2 selectOptions">Checking</li>
                                </Select>
                              </FormControl>
                            </div>

                            <div className="col-12 pt-3">
                              <TextValidator
                                className="MyTextField"
                                fullWidth
                                inputProps={{ maxLength: 20 }}
                                label="Bank Name"
                                onChange={this.handleFormChange}
                                name="bankName"
                                error={!this.state.isValidName}
                                type="text"
                                margin="dense"
                                variant="outlined"
                                validators={['required']}
                                errormessages={['Bank Name cannot be empty']}
                                value={this.state.formData.bankName}
                              />
                            </div>

                            <div className="col-12 pt-3">
                              <TextValidator
                                className="MyTextField"
                                fullWidth
                                inputProps={{ maxLength: 20 }}
                                label="Routing Number"
                                onChange={this.handleFormChange}
                                name="routingNo"
                                type="number"
                                margin="dense"
                                variant="outlined"
                                validators={['required']}
                                errormessages={['Routing Number cannot be empty']}
                                value={this.state.formData.routingNo}
                              />
                              {!this.state.isRoutingValid ? <span className="errorMessage">Routing number not valid</span> : ''}

                            </div>

                            <div className="col-12 pt-3">
                              <TextValidator
                                autoComplete="off"
                                className="MyTextField"
                                fullWidth
                                inputProps={{ minLength: 10, maxLength: 20 }}
                                label="Account Number"
                                onChange={this.handleFormChange}
                                onBlur={this.handleAccountMatch}
                                name="accountNo"
                                type="number"
                                margin="dense"
                                variant="outlined"
                                validators={['required']}
                                errormessages={['Account Number cannot be empty']}
                                value={this.state.formData.accountNo}
                                onCut={this.handleChange}
                                onCopy={this.handleChange}
                                onPaste={this.handleChange}
                              />
                            </div>

                            <div className="col-12 pt-3">
                              <TextValidator
                                autoComplete='off'
                                onpaste="return false;"
                                className="MyTextField"
                                fullWidth
                                inputProps={{ minLength: 10, maxLength: 20 }}
                                label="Confirm Account Number"
                                onChange={this.handleFormChange}
                                onBlur={this.handleAccountMatch}
                                name="confirmAccountNumber"
                                type="number"
                                margin="dense"
                                variant="outlined"
                                validators={['required']}
                                errormessages={['Confirm Account Number cannot be empty']}
                                value={this.state.formData.confirmAccountNumber}
                                onCut={this.handleChange}
                                onCopy={this.handleChange}
                                onPaste={this.handleChange}
                              />
                              {!this.state.accountsMatched ? <span className="errorMessage">Account Number is not same.</span> : ''}
                            </div>
                          </div>
                          :
                          null

                      }
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-4 col-lg-4 pt-3">
                        <div className="loginBtnDiv">
                          <div className="outer-box" >
                            <button type="button" name="skipButton" value="skipButton" onClick={() => this.goBack()} className="thm-btn btn-style-one-outline mr-1" disabled={this.state.isDisabled}>
                              {!this.state.isDisabled ? 'Back' : <i className="fa fa-spinner fa-spin fa-1x fa-fw" />}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-4 col-lg-4 pt-3">
                        <div className="loginBtnDiv">
                          <div className="outer-box" >
                            <button type="button" name="skipButton" value="skipButton" onClick={(value) => this.skipAccounts(value)} className="thm-btn btn-style-one mr-1" disabled={this.state.isDisabled}>
                              {!this.state.isDisabled ? 'Skip' : <i className="fa fa-spinner fa-spin fa-1x fa-fw" />}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-4 col-lg-4 pt-3">
                        <div className="loginBtnDiv">
                          {this.state.manualCheck &&
                            <div className="outer-box" >
                              <button type="submit" name="submitButton" value="submitButton" className="thm-btn btn-style-one mr-1" disabled={this.state.isDisabled}>
                                {!this.state.isDisabled ? 'Continue' : <i className="fa fa-spinner fa-spin fa-1x fa-fw" />}
                              </button>
                            </div>
                          }

                        </div>
                      </div>
                    </div>
                  </ValidatorForm>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = ({ Auth }) => {
  let { plaidDataSignUp, auth } = Auth;
  return {
    auth,
    plaidDataSignUp,
    allSignData: Auth.signData,
    signData: Auth.signData ? Auth.signData.info : [],
  }
}

const mapDispatchToProps = { loginRequest, signAddBank, logout, plaidUpload, signSkipBank, plaidSetup };
export default connect(mapStateToProps, mapDispatchToProps)(AccountDetails);

import moment from 'moment';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { CountryRegionData } from 'react-country-region-selector';
import { updateProfile, submitPremiumRequest } from '../../../../store/actions/Auth';
import Countries from "../../../../DataFiles/Countries";
import validator from 'validator';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import TextField from '@material-ui/core/TextField';

const cropper = React.createRef(null);

class Profile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      profileImage: props.user.avatar,
      expand: false,
      modalCropper: false,
      modalPremium:false,
      labelWidth: 90,
      regions: [],
      isValidState: true,
      isValidNumber:true,
      countryCode:'+1',
      countryShortCode:'US',
      reason:'',
      formData: {
        stateName: " ",
        zipCode: "",
        city: "",
        state: '',
        phone:'',
        citizenshipLabel: 'United States',
      }
    }
  }

  componentWillReceiveProps(props) {
    if (props.user) {
      const { formData } = this.state;
      formData['zipCode'] = props.user.zipCode;
      formData['city'] = props.user.city;
      formData['stateName'] = props.user.state ? props.user.state.name : '';

      let phoneNo = props.user.phone
      this.setState({countryCode: props.user.phoneCode, countryShortCode:props.user.citizenship ? props.user.citizenship.short : ''})
      if(props.user && props.user.phoneCode) formData['phone'] = phoneNo.split(props.user.phoneCode);
      this.setState({ formData });
      if (props.editProfile) {
        let filteredCountry = ''
        let filteredCountries = CountryRegionData.filter(x => {
          if (x[0] == "United States") filteredCountry = x
        })
        let regions = []
        filteredCountry[2].split("|").map(regionPair => {
          let [name, regionShortCode] = regionPair.split("~");
          if (name !== "American Samoa")
            regions.push({
              'name': name,
              'short': regionShortCode,
            });
        })
        this.setState({ regions: regions, lockFields: false })
      }
    }
  }

  handleUpload = (e) => document.getElementById("imgupload").click();

  handleChangeFile = (e) => {
    this.setState({ imageSelected: URL.createObjectURL(e.currentTarget.files[0]) })
    this.toggleModal()
  }

  uploadImage = async (e) => {
    if (cropper.current) {
      let imageToUpload = cropper.current.getCroppedCanvas().toDataURL()

      let res = await fetch(imageToUpload);
      let blob = await res.blob();
      let file = new File([blob], "File name", { type: "image/png" });

      let data = new FormData();
      data.append('avatar', file);

      this.props.handleUploadImage(data);
      this.setState({ profileImage: imageToUpload, modalCropper: false });
    }
  }

  toggle = () => this.setState({ expand: !this.state.expand });
  toggleModal = () => this.setState({ modalCropper: !this.state.modalCropper })
  toggleModalPremium = () => this.setState({ modalPremium: !this.state.modalPremium })
  handleChange = (event) => this.setState({[event.target.name]: event.target.value})

  handleFormChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
    if (event.target.name === 'state') {
      if (event.target.value !== '' && event.target.value !== undefined) this.setState({ isValidState: true })
      else this.setState({ isValidState: false })
    }
  }

  submitPremiumRequest = () =>{
    this.props.submitPremiumRequest({reason:this.state.reason})
    this.toggleModalPremium()
  }

  updateProfile = () => {
    const { formData } = this.state;
    if (formData.stateName) {
      const state = this.state.regions.find(({ name }) => name == this.state.formData.stateName)

      formData['phone'] = this.state.countryCode + this.state.formData.phone;

      formData['state'] = state;
      this.props.updateProfile(this.state.formData)
    } else {
      if (formData.stateName) this.setState({ isValidState: true })
      else this.setState({ isValidState: false })
    }
  }

  handlePhoneChange = (event, countryData)=>
  {
    let filteredCode = Countries.filter(({ name }) => name == this.state.formData.citizenshipLabel)
    let countryCode = filteredCode[0].phone
    // let number = event.replace(/-/g,"").replace(/ /g, "").replace("(","").replace(")","")
    let phone = '+' + countryCode + parseInt(event.target.value, 10)
    let isValidPhoneNumber = validator.isMobilePhone(phone, this.state.locale)
    this.isValidNumber = isValidPhoneNumber
    this.setState({isValidNumber:isValidPhoneNumber})
    const { formData } = this.state;
    formData['phone'] = parseInt(event.target.value, 10);
    this.setState({ formData });
  }

  countryToFlag(isoCode) {
    return typeof String.fromCodePoint !== 'undefined'
      ? isoCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397))
      : isoCode;
  }

  render() {
    let { user } = this.props;
    let { profileImage, expand } = this.state;
    profileImage = profileImage || user['avatar'];
    console.log('$$$$$user', user);
    return (
      <div className="row">
        <div className="col-12">
          {user && Object.keys(user).length !== 0 ?
            <div className="profile-container">
              <div className='profile-header'>
                <div className="pointer d-flex align-items-center w-50" onClick={this.toggle}>
                  <i className={expand ? "fa fa-chevron-down fa-rotate-270" : "fa fa-chevron-down"}></i>
                  {this.props.editProfile ? <h4 style={{ color: '#1A1E2C', marginLeft: "20px" }}>Update Profile</h4> : <h4 style={{ color: '#1A1E2C', marginLeft: "20px" }}>Profile</h4>}
                </div>

                  <div className="pointer d-flex justify-content-end w-50">
                  {
                  user.type == "Basic" && user.premiumRequest && user.premiumRequest.status == 'Pending'?
                  <button
                  className="custom-btn-warning tooltip-warning">
                  <span className="tooltiptext-warning">Pending</span>
                  </button>
                  :
                  user.type == "Basic" && user.premiumRequest && user.premiumRequest.status == 'Rejected'?
                  <button
                  className="custom-btn-danger tooltip-danger">
                  <span className="tooltiptext-danger">Rejected</span>
                  </button>
                  :
                  user.type == "Basic" && !user.premiumRequest ?
                  <button onClick={() => this.toggleModalPremium()}
                  className="thm-btn btn-style-one">
                  Upgrade To Premium
                  </button>
                  : ''
                  }
                  </div>

                {/* <button className="custom-btn ml-auto">Investing Prefrences</button> */}
              </div>
              <input onChange={this.handleChangeFile} accept="image/png, image/jpeg" type="file" id="imgupload" style={{ display: "none" }} />
              <Modal
                modalClassName="modal-lg modal-info"
                toggle={this.toggleModal}
                isOpen={this.state.modalCropper}
              >
                <div className="modal-header justify-content-center">
                  <div className="modal-profile">
                    <h3>
                      Crop Image
                  </h3>
                  </div>
                </div>
                <ModalBody>
                  <Cropper
                    ref={cropper}
                    src={this.state.imageSelected}
                    style={{ height: 400, width: '100%' }}
                    aspectRatio={2 / 2}
                    guides={false}
                  />
                </ModalBody>
                <div className="row" style={{ padding: '1rem' }}>
                  <div className="invest-btn-area col-6 offset-3">
                    <button onClick={() => this.uploadImage()} className="thm-btn btn-style-one">Update Image</button>
                  </div>
                </div>
              </Modal>

              <Modal
                modalClassName="modal-mini modal-info uploaded"
                toggle={this.toggleModalPremium}
                isOpen={this.state.modalPremium}
                >
                <div className="modal-header justify-content-center">
                  <div className="modal-profile">
                    <img className='modalLogoImg' src="https://tknismtest.s3.amazonaws.com/non-compressed-images/favicon.png.png" alt="" />
                  </div>
                </div>
                <ModalBody>
                  <p className="mt-3">Tell us a reason why do you want to upgrade to premium.</p>
                      <TextField
                        className="mt-3 w-75"
                        value={this.state.reason}
                        onChange={this.handleChange}
                        name="reason"
                        id="standard-basic"
                        label="Reason for request"
                        />
                </ModalBody>
                <div className="row" style={{ padding: '1rem' }}>
                  <div className="invest-btn-area col-6 offset-3">
                    <button onClick={() => this.submitPremiumRequest()} className="thm-btn btn-style-one">Submit Request</button>
                  </div>
                </div>
              </Modal>

              <div hidden={expand} className='row profile-detail-container'>
                <div className='col-md-12 col-lg-2 col-sm-12 profile-detail'
                  style={{ cursor: "pointer" }}
                  onClick={this.handleUpload}>
                  <img src={profileImage} style={{ width: "120px", height: "120px", borderRadius: "50%", background: 'gray' }} />
                  <div className="edit-icon">
                    <i className="fa fa-pencil"></i>
                  </div>
                </div>
                {this.props.editProfile
                  ?
                  <div className='col-md-12 col-lg-10 row'>
                    <ValidatorForm className="validatorForm col-12 row" onSubmit={this.updateProfile}>
                      <div className='col-md-4 col-sm-4 profile-detail'>
                        <TextValidator
                          className="MyTextField"
                          fullWidth
                          label="Zip Code"
                          onChange={this.handleFormChange}
                          name="zipCode"
                          type="text"
                          margin="dense"
                          variant="outlined"
                          validators={['required']}
                          errormessages={['Zip Code can not be empty']}
                          value={this.state.formData.zipCode}
                        />
                      </div>
                      <div className='col-md-4 col-sm-4 profile-detail'>
                        <TextValidator
                          className="MyTextField"
                          fullWidth
                          label="City"
                          onChange={this.handleFormChange}
                          name="city"
                          type="text"
                          margin="dense"
                          variant="outlined"
                          validators={['required']}
                          errormessages={['City can not be empty']}
                          value={this.state.formData.city}
                        />
                      </div>
                      <div className='col-md-4 col-sm-4 profile-detail'>
                        <FormControl variant="outlined"
                          label="State"
                          margin="dense"
                          className={this.state.lockFields ? "cursor-not-allowed MyTextField" : 'MyTextField'}
                          fullWidth
                        >
                          <InputLabel htmlFor="outlined-age-native-simple">
                            State
                          </InputLabel>
                          <Select
                            native
                            error={!this.state.isValidState}
                            disabled={this.state.lockFields}
                            labelWidth={this.state.labelWidth - 40}
                            type="text"
                            name="stateName"
                            value={this.state.formData.stateName}
                            onChange={this.handleFormChange}
                            validators={['required']}
                            autoComplete='off'
                          >
                            <option value="" className="pl-2 selectOptions"></option>
                            {
                              this.state.regions.map(option => {
                                return <option value={option.name} className="pl-2 selectOptions">
                                  {option.name}
                                </option>
                              })
                            }
                          </Select>
                        </FormControl>

                      </div>

                      <div className='col-md-4 col-sm-4 profile-detail mt-3'>
                        <FormControl variant="outlined">
                          <InputLabel>
                            Phone
                          </InputLabel>
                          <OutlinedInput
                            id="outlined-error-helper-text"
                            fullWidth
                            error={!this.state.isValidNumber}
                            label="phone"
                            labelWidth={35}
                            name="phone"
                            type="number"
                            margin="dense"
                            validators={['isNumberValid', 'required']}
                            value={this.state.formData.phone}
                            onChange={this.handlePhoneChange}
                            errormessages={['Number can not be empty']}
                            autoComplete='off'
                            startAdornment={<InputAdornment position="start"
                              >
                              {this.state.countryCode} {this.countryToFlag(this.state.countryShortCode)}
                            </InputAdornment>}
                            aria-describedby="outlined-weight-helper-text"
                            />
                          {!this.state.isValidNumber ? <span className="errorMessage">Invalid Number</span>:''}
                        </FormControl>
                      </div>

                      <div className='col-md-12 col-sm-12 profile-detail pt-3'>
                        <button className='custom-btn' style={{ height: "47px", width: '160px' }}>Update</button>
                      </div>
                    </ValidatorForm>
                  </div>
                  :
                  <div className='col-md-12 col-lg-10 row'>
                    <div className='col-md-4 col-sm-12 profile-detail'>
                      <div className="profile-dec-row">
                        <p className='profile-label'>Email:</p>
                        <p className='profile-data'>{user['email']}</p>
                      </div>

                      <div className="profile-dec-row">
                        <p className='profile-label'>Phone:</p>
                        <p className='profile-data'>{user['phone']}</p>
                      </div>
                      <div className="profile-dec-row">
                        <p className='profile-label'>Zip Code:</p>
                        <p className='profile-data'>{user['zipCode']}</p>
                      </div>
                      <div className="profile-dec-row">
                        <p className='profile-label'>Address:</p>
                        <p className='profile-data'>{user['address']}</p>
                      </div>

                    </div>
                    <div className='col-md-4 col-sm-12 profile-detail'>
                      <div className="profile-dec-row">
                        <p className='profile-label'>First Name:</p>
                        <p className='profile-data'>{user['firstName']}</p>
                      </div>
                      <div className="profile-dec-row">
                        <p className='profile-label'>City:</p>
                        <p className='profile-data'>{user['city']}</p>
                      </div>

                      <div className="profile-dec-row">
                        <p className='profile-label'>Citizenship:</p>
                        <p className='profile-data'>{user['citizenship']['name']}</p>
                      </div>
                      <div className="profile-dec-row">
                        <p className='profile-label'>Account Type:</p>
                        <p className='profile-data'>{user['type']}</p>
                      </div>
                    </div>
                    <div className='col-md-4 col-sm-12 profile-detail'>
                      <div className="profile-dec-row">
                        <p className='profile-label'>Last Name:</p>
                        <p className='profile-data'>{user['lastName']}</p>
                      </div>
                      <div className="profile-dec-row">
                        <p className='profile-label'>State:</p>
                        <p className='profile-data'>{user['state']['name']}</p>
                      </div>
                      <div className="profile-dec-row">
                        <p className='profile-label'>DOB:</p>
                        <p className='profile-data'>{moment(user['dob']).format("LL")}</p>
                      </div>
                    </div>
                    <div className='col-md-8 col-sm-12 profile-detail'>
                      <div className="profile-dec-row">
                        <p className='profile-label'>Wallet:</p>
                        <p className='profile-data'>{user['publicAddress']}</p>
                      </div>
                    </div>
                  </div>
                }

              </div>

              {/* <div className="row" style={{ justifyContent: "flex-end", paddingRight: '50px' }}>
            <button className='custom-btn' style={{ height: "47px", width: '160px' }}>Edit</button>
            </div> */}
            </div>
            : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ Settings }) => {
  let { profile } = Settings;
  return {
    user: profile || {}
  }
}
const mapDispatchToProps = { updateProfile, submitPremiumRequest };

export default connect(mapStateToProps, mapDispatchToProps)(Profile);

import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import {  makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    color: '#8cc63f',
    paddingBottom:0,
    paddingTop:0,
    '&$checked': {
      color: '#8cc63f',
    },
  },
  checked: {},
}));



export default function CheckBox({value,name,checked,handelCheckBox}) {
  const classes = useStyles();
  return (
      <FormControlLabel
        control={
        <Checkbox 
        checked={checked}
        onChange={handelCheckBox}
        name={name}
        value={value}
        classes={{
          root: classes.root,
          checked: classes.checked,
        }}
        />
        }
        label={name}
      />
  );
}
import axios from 'axios';
import { ApiUrl } from './config';
import { detect } from 'detect-browser';
import jwt_decode from 'jwt-decode';
import EventBus from 'eventing-bus';

checkToken();
setInterval(() => checkToken(), 5000);
function checkToken() {
  let token = localStorage.getItem('token');
  if (token) {
    let decoded = jwt_decode(token);
    let expiry = decoded['exp'] * 1000;
    let timeNow = (new Date()).getTime();
    if (expiry <= timeNow) EventBus.publish('tokenExpired', true);
  }
}

setTimeout(() => {
  (async () => {
    const browser = await detect();
    if (browser) axios.defaults.headers.common['agent'] = `${browser.name[0].toUpperCase() + browser.name.slice(1)} V${browser.version} (${browser.os})`;
    let agent = axios.defaults.headers.common['agent'];
    // let auth = axios.defaults.headers.common['Authorization'];

    // delete axios.defaults.headers.common['agent'];
    // delete axios.defaults.headers.common['Authorization'];
    let url = new URL("https://api.ipstack.com/check"),
    params = {access_key:'d62575e0bca41db85969420858fadf99'}
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    fetch(url)
    let response = await fetch(url)
      .then((response) => {
        if (response['status'] == 200) return response.json()
      })
      .then((data) => {
        localStorage.setItem('IP', data['ip'])
        axios.defaults.headers.common['city'] = data['city'];
        axios.defaults.headers.common['zipcode'] = data['zip'];
        axios.defaults.headers.common['ipaddress'] = data['ip'];
        axios.defaults.headers.common['countryname'] = data['country_name'];
        axios.defaults.headers.common['countrycode'] = data['country_code'];
        axios.defaults.headers.common['internet'] = data['connection']['isp'];
        axios.defaults.headers.common['continentcode'] = data['continent_code'];
        axios.defaults.headers.common['continentname'] = data['continent_name'];
        axios.defaults.headers.common['callcode'] = data['location']['calling_code'];
      })
      .catch(err => {
      })
    // await axios.get('https://api.ipstack.com/check', { params: { access_key: 'd62575e0bca41db85969420858fadf99' } })
    //   .then(({ data }) => {
    //     console.log('axios data $$$$', data);
    //     localStorage.setItem('IP', data['ip'])
    //     axios.defaults.headers.common['city'] = data['city'];
    //     axios.defaults.headers.common['zipcode'] = data['zip'];
    //     axios.defaults.headers.common['ipaddress'] = data['ip'];
    //     axios.defaults.headers.common['countryname'] = data['country_name'];
    //     axios.defaults.headers.common['countrycode'] = data['country_code'];
    //     axios.defaults.headers.common['internet'] = data['connection']['isp'];
    //     axios.defaults.headers.common['continentcode'] = data['continent_code'];
    //     axios.defaults.headers.common['continentname'] = data['continent_name'];
    //     axios.defaults.headers.common['callcode'] = data['location']['calling_code'];
    //   }).catch(e => console.log(`Error While Fetching API = `, e));

    axios.defaults.headers.common['agent'] = agent;
    // if (auth) axios.defaults.headers.common['Authorization'] = auth;
  })();
}, 5000)

axios.defaults.baseURL = ApiUrl;

let token = localStorage.getItem('token');
if (token) axios.defaults.headers.common['Authorization'] = `bearer ${token}`;

export const setToken = token => {
  if (token) axios.defaults.headers.common['Authorization'] = `bearer ${token}`;
  else delete axios.defaults.headers.common['Authorization'];
};

export const setHash = hash => {
  if (hash) axios.defaults.headers.common['hash'] = hash;
  else delete axios.defaults.headers.common['hash'];
};

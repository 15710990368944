import React, {
  Component
}
from 'react';
import EventBus from 'eventing-bus';

import {
  ValidatorForm, TextValidator
}
from 'react-material-ui-form-validator';
import {
  Modal, ModalBody
}
from 'reactstrap';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  loginRequest, signupRequest, logout
}
from '../../../store/actions/Auth';
import {
  connect
}
from 'react-redux';
import validator from 'validator';
import Countries from "../../../DataFiles/Countries";

import 'react-flags-select/css/react-flags-select.css';
import {
  Link
}
from "react-router-dom";
import ReactGA from 'react-ga';

class BasicInfo1 extends Component {
  constructor() {
    super()
    this.state = {
      pageContent: "",
      isDisabled: false,
      agreeCheck: false,
      isValid: true,
      isValidFirst: true,
      isValidLast: true,
      isValidEmail: true,
      emailVerificationModal: false,
      locale: ['ar-AE', 'ar-BH', 'ar-DZ', 'ar-EG', 'ar-IQ', 'ar-JO',
        'ar-KW', 'ar-SA', 'ar-SY', 'ar-TN', 'be-BY', 'bg-BG', 'bn-BD',
        'cs-CZ', 'de-DE', 'de-AT', 'da-DK',
        'el-GR', 'en-AU', 'en-CA', 'en-GB', 'en-GG', 'en-GH', 'en-HK',
        'en-MO', 'en-IE', 'en-IN', 'en-KE', 'en-MT', 'en-MU', 'en-NG',
        'en-NZ', 'en-RW', 'en-SG', 'en-UG', 'en-US', 'en-TZ',
        'en-ZA', 'en-ZM', 'en-PK', 'es-EC', 'es-ES', 'es-MX', 'es-PA',
        'es-PY', 'es-UY', 'et-EE', 'fa-IR', 'fi-FI', 'fj-FJ', 'fr-FR',
        'fr-GF', 'fr-GP', 'fr-MQ', 'fr-RE', 'he-IL', 'hu-HU',
        'id-ID', 'it-IT', 'ja-JP', 'kk-KZ', 'ko-KR', 'lt-LT', 'ms-MY',
        'nb-NO', 'ne-NP', 'nl-BE', 'nl-NL', 'nn-NO', 'pl-PL', 'pt-PT',
        'pt-BR', 'ro-RO', 'ru-RU', 'sl-SI', 'sk-SK', 'sr-RS',
        'sv-SE', 'th-TH', 'tr-TR', 'uk-UA', 'vi-VN', 'zh-CN', 'zh-HK',
        'zh-MO', 'zh-TW'
      ],
      formData: {
        email: '',
        lastName: '',
        firstName: '',
        // phoneNo:'',
        // number:'',
        // residenceLabel:1,
      }
    }
    window.scrollTo(0, 0);
  }

  componentDidMount() {
    EventBus.on("BasicInfo1", () => this.setState({
      isDisabled: false
    }));
    EventBus.on("BasicInfoEmail", this.handleNewSignUp);
    EventBus.on("restartSignUp", this.handleSignUp);
    ValidatorForm.addValidationRule('isOnlySpecialCharater', (value) => {
      var pattern = /^[^a-zA-Z0-9]+$/;
      return (!pattern.test(value))
    })
    ReactGA.pageview('/signup-1');
  }

  countryToFlag(isoCode) {
    return typeof String.fromCodePoint !== 'undefined' ?
      isoCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(
        0) + 127397)) :
      isoCode;

  }

  handleNewSignUp = (e) => {
    this.setState({
      isDisabled: false
    })
    this.setState({
      emailVerificationModal: true,
      VerificationMessage: e
    })
  }

  emailVerificationModal = () => {
    this.setState({
      emailVerificationModal: !this.state.emailVerificationModal
    })
  }

  handleSignUp = () => {
    var alphaExp = /^[a-zA-Z]+$/;
    if (this.state.agreeCheck && this.state.isValidFirst && this.state.isValidLast &&
      this.state.isValidEmail) {
      this.setState({
        isDisabled: true
      });
      const {
        formData
      } = this.state;
      formData['investPackage'] = this.props.starterDetails.package;
      formData['investPlan'] = this.props.starterDetails.plan;
      // formData['phoneNo'] = '+' + this.state.formData.residenceLabel + parseInt(this.state.formData.number, 10);
      this.setState({
        formData
      });
      this.props.step1(formData)
      this.props.signupRequest(formData)
    } else {
      if (this.state.formData.email) this.setState({
        isValidEmail: true
      })
      else this.setState({
        isValidEmail: false
      })
      if (this.state.formData.lastName.match(alphaExp)) this.setState({
        isValidLast: true
      })
      else this.setState({
        isValidLast: false
      })
      if (this.state.formData.firstName.match(alphaExp)) this.setState({
        isValidFirst: true
      })
      else this.setState({
          isValidFirst: false
        })
        // if(this.state.formData.phoneNo) this.setState({isValid:true})
        // else this.setState({isValid:false})
        // EventBus.publish("error", 'Please fill out the fields.')
    }
  }

  handleFormChange = (event) => {
    const {
      formData
    } = this.state;

    formData[event.target.name] = event.target.value;
    this.setState({
      formData
    });
    if (event.target.name === 'firstName') {
      if (event.target.value !== '' && event.target.value !== undefined) this
        .setState({
          isValidFirst: true
        })
      else this.setState({
        isValidFirst: false
      })
    }
    if (event.target.name === 'lastName') {
      if (event.target.value !== '' && event.target.value !== undefined) this
        .setState({
          isValidLast: true
        })
      else this.setState({
        isValidLast: false
      })
    }
    if (event.target.name === 'email') {
      if (event.target.value !== '' && event.target.value !== undefined && (
          /^[^\s@]+@[^\s@]+\.[^\s@]+$/).test(event.target.value)) this.setState({
        isValidEmail: true
      })
      else this.setState({
        isValidEmail: false
      })
    }
    // if(event.target.name === 'number'){
    //   if(event.target.value !== '' && event.target.value !== undefined) this.setState({isValid:true})
    //   else this.setState({isValid:false})
    // }
  }


  handlePhoneChange = (event, countryData) => {
    let upperCaseCode = countryData ? countryData.countryCode.toUpperCase() :
      ''
    let countryCode = countryData ? 'en-' + upperCaseCode : ''
    // let number = event.replace(/-/g,"").replace(/ /g, "").replace("(","").replace(")","")
    let number = '+' + this.state.formData.residenceLabel + parseInt(event.target
      .value, 10)
    let isValidPhoneNumber = validator.isMobilePhone(number, this.state.locale)
    this.isValid = isValidPhoneNumber
    this.setState({
      isValid: isValidPhoneNumber
    })
    const {
      formData
    } = this.state;
    formData['number'] = parseInt(event.target.value, 10);
    this.setState({
      formData
    });
  }

  onSelectFlag = (countryCode) => {
    const {
      formData
    } = this.state;
    let filteredCode = Countries.filter(({
      short
    }) => short == countryCode)
    formData['residenceLabel'] = filteredCode[0].phone;
    this.setState({
      formData
    });
    this.onFlagChange()
  }

  onFlagChange = (event, countryData) => {
    if (this.state.formData.number) {
      let number = '+' + this.state.formData.residenceLabel + parseInt(this.state
        .formData.number, 10)
      let isValidPhoneNumber = validator.isMobilePhone(number, this.state.locale)
      this.isValid = isValidPhoneNumber
      this.setState({
        isValid: isValidPhoneNumber
      })
      const {
        formData
      } = this.state;
      formData['number'] = parseInt(this.state.formData.number, 10);
      this.setState({
        formData
      });
    }
  }

  // handlePhoneChange = (event, countryData)=>
  // {
  //   console.log('countryData', event, this.state.formData.residenceLabel);
  //   let upperCaseCode = countryData ? countryData.countryCode.toUpperCase() : ''
  //   let countryCode = countryData ? 'en-' + upperCaseCode : ''
  //   console.log('number', event, countryData, upperCaseCode, countryCode);
  //   let number = event.replace(/-/g,"").replace(/ /g, "").replace("(","").replace(")","")
  //   console.log('number', number);
  //   let isValidPhoneNumber = validator.isMobilePhone(number, this.state.locale)
  //   this.isValid = isValidPhoneNumber
  //   this.setState({isValid:isValidPhoneNumber})
  //   const { formData } = this.state;
  //   formData['phone'] = event;
  //   this.setState({ formData });
  // }

  checkNumberValidation = () => {
    this.setState({
      isValid: this.isValid
    })
  }

  handleCheck = (event) => {
    this.setState({
      agreeCheck: !this.state.agreeCheck
    });
  }

  handleLoginSignup = (e) => {
    if (typeof window.ethereum === 'undefined')
      alert('Looks like you need to install MetaMask to get started.')
    else {
      window.ethereum.enable().then((response) => {
        const {
          formData
        } = this.state;
        formData['publicAddress'] = response[0];
        formData['phoneNo'] = '+' + this.state.formData.residenceLabel +
          this.state.formData.number
        this.setState({
          formData
        });
        // if (e === 'login') this.props.loginRequest({data: response[0], history: this.props.history})
        // else if (e === 'signup') this.props.signupRequest(response[0])
        // this.props.signupRequest(response[0])
      }).catch((reason) => {
        if (reason === 'User rejected provider access') alert(
          'Login Request Rejected')
        else alert('There was an issue signing you in.')
      });
    }
  }


  render() {
    const label = ( < div className = "check-label" > By checking this box you agree to Tokenism specific <Link to = "/terms-of-use" target = "_blank" > Terms of Use </Link> and <Link target = "_blank" to="/privacy-policy">Privacy Policy</Link> as well as our partner Dwolla's <a style={{color:'#8cc63f'}} href="https://www.dwolla.com/legal/tos/" target="_blank"> Terms of Service </a> and <a style={{color:'#8cc63f'}} href="https://www.dwolla.com/legal/privacy/" target="_blank"> Privacy Policy</a>.</div > )
    return (
    <section className = "signUp-sec">
      <div className = "container">
      <div className = "row basic-Info-1">
      <div className = "col-12">
      <div className = "content-area">
        <div className = "pb-2">
          <h1> Basic Info </h1> <hr/>
        </div>
      <Modal modalClassName = "modal-mini modal-info uploaded"
      toggle={this.emailVerificationModal}
      isOpen ={this.state.emailVerificationModal}
      >
      <div className = "modal-header justify-content-center" >
        <div className = "modal-profile">
            <img className = 'modalLogoImg' src="https://tknismtest.s3.amazonaws.com/non-compressed-images/favicon.png.png" alt = "" />
        </div>
        <div className="right">
          <img onClick = {this.emailVerificationModal} className = "modalLogoImg" src ="https://tknismtest.s3.amazonaws.com/non-compressed-images/close-icon.png" alt = "" />
        </div>
      </div>
      <ModalBody className = "pt-5">
      <h5 className = "title" > Verify Your Email </h5>
      <div className="verificationDescription" >
        We have sent you an email verification link. Please verify your email to continue the signup.
      </div>
      </ModalBody >

      </Modal>

      <ValidatorForm className = "validatorForm"
         onSubmit = {() => this.handleSignUp()}
        >
      <div className = "row">
        <div className="col-12 pb-1">
          <span className = "formBlurHeading" > To Invest, Please Create an Account </span>
        </div>

      <div className ="col-sm-12 col-md-12 col-lg-6 pt-3" >
      <TextValidator className = "MyTextField"
        fullWidth
        inputProps = {{
          minLength: 2,
          maxLength: 26
        }}
      error = {!this.state.isValidFirst}
      label = "First Name"
      onChange = {this.handleFormChange}
      name = "firstName"
      type = "text"
      margin = "dense"
      variant = "outlined"
      validators = {
        ['required', 'matchRegexp:^[A-Za-z ]+$']
      }
      errormessages = {
        ['First Name can not be empty',
          "Special Chrachters and Numbers are not allowed"
        ]
      }
      value = {
        this.state.formData.firstName
      }
      autocomplete = "off"
      />
      </div>
      <div className = "col-sm-12 col-md-12 col-lg-6 pt-3">
      <TextValidator className = "MyTextField"
        fullWidth
        inputProps = {{
          minLength: 2,
          maxLength: 26
        }}
      error={!this.state.isValidLast}
      label = "Last Name"
      onChange = {this.handleFormChange}
      name = "lastName"
      type = "text"
      margin = "dense"
      variant = "outlined"
      validators = {
        ["required", 'isOnlySpecialCharater']
      }
      errorMessages = {["Last Name can not be empty", "Should not contain only special chrachters"]}
      value = {
        this.state.formData.lastName
      }
      />
      </div>

      <div className = "col-12 pt-3">
      < TextValidator className = "MyTextField"
      fullWidth
      error ={!this.state.isValidEmail}
      label = "Email"
      onChange = {
        this.handleFormChange
      }
      name = "email"
      type = "text"
      margin = "dense"
      variant = "outlined"
      validators = {
        ['required', 'isEmail']
      }
      errormessages = {
        ['Email can not be empty', 'Email is not valid']
      }
      value = {
        this.state.formData.email
      }
      />
      </div>
      {
        /*
                              <div className="col-12 pt-3">
                              <FormControl variant="outlined" className="col-12">
                                <InputLabel>
                                  Phone
                                </InputLabel>
                                <OutlinedInput
                                  error={!this.state.isValid}
                                  id="outlined-error-helper-text"
                                  label="amount"
                                  labelWidth={30}
                                  name="number"
                                  type="number"
                                  validators={['required']}
                                  errormessages={['Phone can not be empty']}
                                  onChange={this.handlePhoneChange}
                                  startAdornment={<InputAdornment position="start"
                                  >
                                  <ReactFlagsSelect
                                    countries={Countries.map(x => x.short)}
                                    type="button"
                                    optionsSize={18}
                                    name="residenceLabel"
                                    searchable={true}
                                    showSelectedLabel={false}
                                    defaultCountry="US"
                                    placeholder=""
                                    onSelect={this.onSelectFlag}
                                    value={this.state.formData.residenceLabel}
                                    onBlur={this.onFlagChange}
                                     />

                                  </InputAdornment>}
                                  aria-describedby="outlined-weight-helper-text"
                                />
                                {!this.state.isValid ? <span className="errorMessage">Invalid Number</span>:''}
                              </FormControl>
                            </div>
                            */
      }


      {
        this.state.formData.publicAddress ?
          <div className = "col-12 pt-3" >
            <TextValidator
              className = "MyTextField"
              fullWidth
              disabled
              label = "Public Address"
              name = "publicAddress"
              type = "text"
              margin = "dense"
              variant = "outlined"
              validators = {
                ['required']
              }
              value = {
                this.state.formData.publicAddress
              }
              />
        </div> :
        null
      }

      <div className = "col-12 pt-3" >
      <FormControlLabel value = {
        this.state.agreeCheck
      }
      onChange = {
        this.handleCheck
      }
      className = "formBlurHeading"
      control = { < Checkbox color = "primary" /> }
      label = {
        label
      }
      labelPlacement = "end"
      validators = {
        ['required']
      }
      errormessages = {
        ['Please agree to the terms']
      }
      name = "agreeCheck" />
      </div>

      </div>

      { /*<p style={forgotPasswordText} onClick={this.handleForgotPassword}>forgot password?</p>*/ }
      <div className = "loginBtnDiv pt-2" >

      <div className = "outer-box" >
      <button className = {
        `thm-btn btn-style-one mr-1 ${!this.state.agreeCheck ? 'disabled' : ''}`
      }
      disabled = {
        this.state.isDisabled
      } > {
        !this.state.isDisabled ? 'Agree & Continue' : < i className =
          "fa fa-spinner fa-spin fa-1x fa-fw" / >
      } < /button>

      </div>

      {
        /*<p className="dontHaveAcc">Don't have an Account?
                              <span
                              onClick={()=>this.handleSignup()}
                              style={{color:"#577d23", fontWeight: 600, cursor:"pointer"}}> Signup</span>
                              </p>*/
      }
      </div>
      </ValidatorForm >
      {
        /*
                            !this.state.formData.publicAddress
                            ?
                            <div className="outer-box" >
                            <button onClick={() =>this.handleLoginSignup()} className="thm-btn btn-style-one mr-1" style={{width: '60%'}}>
                            {!this.state.isDisabled ? 'Connect ETH Wallet' : <i className="fa fa-spinner fa-spin fa-1x fa-fw"/>}
                            </button>
                            </div>
                            :
                            ''
                            */
      }

      </div>
       </div>
       </div>
       </div>
       </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.Auth.auth,
    signData: state.Auth.signData
  }
}

const mapDispatchToProps = {
  loginRequest, signupRequest, logout
};
export default connect(mapStateToProps, mapDispatchToProps)(BasicInfo1);

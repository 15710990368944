import Web3 from 'web3';
import axios from "axios";
import EventBus from 'eventing-bus';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import Select from '@material-ui/core/Select';
import NumberFormat from 'react-number-format';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import Loader from '../../../../components/AppLoader';
import { ExchangeData } from '../../../../store/contract';
import { web3, providerInject, providerDev, magic } from './../../../../store/web3';
import { getUserTokenBalance, sellTokens, getUserListingBalance } from '../../../../store/actions/Exchange';
import { overlayLoader } from '../../../../store/actions/Settings';
import { TKUSDAddress, WhitelistAddress, WhitelistABI, network } from '../../../../store/contract';
import * as Sentry from '@sentry/browser';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: { marginTop: theme.spacing(2) },
}));

class ListTokens extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fee: 0, // 2% Fee
      price: '',
      symbol: '',
      quantity: '',
      loader: false,
      properties: [],
      selectedProp: {},
      message: 'Loading your properties...'
    }
    props.getUserTokenBalance();
    props.getUserListingBalance();
    props.overlayLoader({status:true, loadingMessage:'Loading your properties'})

  }

  componentDidMount() {
    ValidatorForm.addValidationRule('isQuantity', (value) => {
      if (value > (Number(this.state.selectedProp['balance']) || 0)) return false;
      return true;
    });
  }

  componentWillMount() {
    ValidatorForm.removeValidationRule('isQuantity');
  }

  componentWillReceiveProps({ listingData }) {
    this.setState({ loader: false, properties: listingData.filter(({ symbol }) => symbol !== 'TKUSD' && symbol !== 'ETH') }, () =>
      this.setState({ selectedProp: this.state.properties[0] || {}, symbol: this.state.properties[0] ? this.state.properties[0]['symbol'] : '' }));
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });

    if (event.target.name === 'symbol') {
      let selected = this.props.listingData.filter(({ symbol }) => symbol == event.target.value)[0] || {};
      this.setState({ selectedProp: selected, quantity: "" });
    }
  };

  getNonce = () => {
    return new Promise(async (resolve, reject) => {
      try {
        let { data } = await axios.get(`/wallet/getNonce`);
        return resolve(data['body']);
      } catch (e) { reject(e) }
    })
  };

  sellProperty = async () => {
    let currentNonce = '';
    try {
      let expiry = 0;
      let { price, quantity, fee, symbol, selectedProp } = this.state;
      let { tokenId } = selectedProp;

      this.props.overlayLoader({status:true, loadingMessage:'Setting up environment on blockchain to sell token'})
      // this.setState({ loader: true, message: 'Setting up envoirnment on blockchain to sell token' });

      if(localStorage.getItem('ETH-PROVIDER') == 'formatic'){
        const isLoggedIn = await magic.user.isLoggedIn();
        if(!isLoggedIn) {
          this.props.history.push('/');
          EventBus.publish('tokenExpired');
          return;
        }
      }

      let webDev = new Web3(providerDev);
      let web3Injected = new Web3(providerInject);

      let from = (await webDev.eth.getAccounts())[0];
      let seller = (await web3Injected.currentProvider.enable())[0];
      let Exchange = new webDev.eth.Contract(ExchangeData['ABI']);

      let exchangeDeploy = Exchange.deploy({
        data: ExchangeData['ByteCode'], arguments: [
          tokenId['contractAddress'],
          TKUSDAddress,
          expiry,
          seller,
          WhitelistAddress
        ]
      });

      console.log(`*******From Address = ${from}`);
      console.log(`*******From Balance = ${await web3Injected.eth.getBalance(from)}`);

      // let nonceExchange = (await webDev.eth.getTransactionCount(from, 'pending'));
      let nonceExchange = await this.getNonce();
      currentNonce = nonceExchange;

      let exchangeFee = Math.round(await exchangeDeploy.estimateGas() * 1.2);
      exchangeDeploy.send({ from, gas: exchangeFee, nonce: nonceExchange })
        .on('transactionHash', hash => console.log(`=`))
        .on('receipt', async exchangeReceipt => {

          let daysAdd = 86400000 * 90000000; // 90 Days
          let expiryDate = (new Date()).getTime() + daysAdd;
          expiryDate = parseFloat(expiryDate / 1000).toFixed(0);

          const Whitelist = new webDev.eth.Contract(WhitelistABI, WhitelistAddress);
          const whitelistFee = (await Whitelist.methods.addWhitelistedUser(exchangeReceipt['contractAddress'], true, true, expiryDate).estimateGas({ from })) * 2;
          // await Whitelist.methods.addWhitelistedUser(exchangeReceipt['contractAddress'], true, false, 0).send({ from, gas: whitelistFee });

          // let nonceWhitelist = (await webDev.eth.getTransactionCount(from, 'pending'));
          let nonceWhitelist = await this.getNonce();
          currentNonce = nonceWhitelist;

          let whitelistRecepit = await webDev.eth.sendTransaction({
            from,
            value: 0,
            gas: whitelistFee,
            to: WhitelistAddress,
            nonce: nonceWhitelist,
            data: Whitelist.methods.addWhitelistedUser(exchangeReceipt['contractAddress'], true, true, expiryDate).encodeABI(),
          });
          if(localStorage.getItem('ETH-PROVIDER') == 'metamask') this.props.overlayLoader({status:true, loadingMessage:'Please allow us to List your Security Tokens on our platform'})
          else this.props.overlayLoader({status:true, loadingMessage:'Signing your transaction'})
          // this.props.overlayLoader({status:true, loadingMessage:'Please allow us to List your Security Tokens on our platform...'})
          // this.setState({ message: 'Please allow us to List your Security Tokens on our platform...' });
          const ST = new web3Injected.eth.Contract(tokenId['abi'], tokenId['contractAddress']);
          await ST.methods.approve(exchangeReceipt['contractAddress'], quantity).send({ from: seller }).on('error', error => {
            // this.setState({ loader: false, message: '' });

            EventBus.publish("error", error['message']);
          });

          const ExchangeInstance = new webDev.eth.Contract(ExchangeData['ABI'], exchangeReceipt['contractAddress']);
          let sellFee = (await ExchangeInstance.methods.sellTokens(price, quantity).estimateGas({ from })) * 2;
          // let sellReceipt = await ExchangeInstance.methods.sellTokens(price, quantity).send({ from, gas: sellFee});

          // let nonceSell = (await webDev.eth.getTransactionCount(from, 'pending'));
          let nonceSell = await this.getNonce();
          currentNonce = nonceSell;

          let sellReceipt = await webDev.eth.sendTransaction({
            from,
            value: 0,
            gas: sellFee,
            nonce: nonceSell,
            to: exchangeReceipt['contractAddress'],
            data: ExchangeInstance.methods.sellTokens(web3.utils.toHex(price), web3.utils.toHex(quantity)).encodeABI(),
          });

          let data = {
            fee,
            network,
            sellHash: sellReceipt['transactionHash'],
            exchangeContract: exchangeReceipt['contractAddress'],
          }
          this.props.overlayLoader({status:true, loadingMessage:'We are recording your data from blockchain.'})
          this.props.sellTokens(data);
          // setTimeout(() => this.setState({ loader: false, message: '' }), 2000);
        }).on('error', error => {
          this.props.overlayLoader({status:false, loadingMessage:''})

          if(error['message'].indexOf('Insufficient funds') >= 0){
            Sentry.captureMessage(error['message'] + ' listTokens');
            EventBus.publish("error", 'Something went wrong. Please contact customer support.');
          } else EventBus.publish("error", error['message']);
        });
    } catch (e) {
      // this.setState({ loader: false, message: '' });
      this.props.overlayLoader({status:false, loadingMessage:''})
      EventBus.publish("error", e['message']);
      // EventBus.publish("error", `Error while Listing Tokens`);
    }
  }

  render() {
    const classes = useStyles;
    let { properties, symbol, selectedProp, price, quantity, fee, message, loader } = this.state;

    return (
      <section className="list-tokens">
        {loader ? <Loader message={message} /> :
          <>
            <ValidatorForm onSubmit={this.sellProperty}>
              <FormControl variant="outlined" fullWidth className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Select Property
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={symbol}
                  type="text"
                  name="symbol"
                  onChange={this.handleChange}
                  labelWidth={110}
                >
                  <MenuItem value="No Property"><em>No Property</em></MenuItem>
                  {properties.map((item, index) => {
                    return <MenuItem id={`property${index}`} value={item['symbol']}>{item['name']}</MenuItem>
                  })}
                </Select>
              </FormControl>
              <div className="row mt-5">
                <div className="col-5 label">Balance</div>
                <div className="col-7 label"><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/49.png" alt="" />
                  {'  '}{selectedProp['balance'] || 0}
                  <span>{'  '}{selectedProp['symbol']}</span>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-5 label">Price TKUSD</div>
                <div className="col-md-7">
                  <FormControl className={`${classes.root} inputs mt-3 mt-md-0`} noValidate autoComplete="off">
                    <TextValidator
                      name='price'
                      value={price}
                      type="number"
                      onChange={this.handleChange}
                      id="outlined-secondary-price"
                      variant="outlined"
                      color="secondary"
                      validators={['required']}
                      placeholder="Price TKUSD"
                      validators={['minNumber:1', 'required']}
                      errorMessages={['Price must be greater than 0', 'Price cannot be empty']}
                    />
                  </FormControl>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-5 label">Quantity</div>
                <div className="col-md-7">
                  <FormControl className={`${classes.root} inputs mt-3 mt-md-0`} noValidate autoComplete="off">
                    <TextValidator
                      type='number'
                      name='quantity'
                      value={quantity}
                      color='secondary'
                      variant='outlined'
                      placeholder='Quantity'
                      onWheel={event => event.currentTarget.blur()}
                      onChange={this.handleChange}
                      id='outlined-secondary-quantity'
                      validators={['minNumber:1', 'required', 'isQuantity']}
                      errorMessages={['Quantity must be greater than 0', 'Quantity cannot be empty', 'Quantity cannot be greater than balance']}
                    />
                  </FormControl>
                  <button type='button' onClick={() => this.setState({ quantity: selectedProp['balance'] || 0 })} className="action-btn ml-xl-3 mt-3 mt-xl-0">Max Sell</button>
                </div>
              </div>
              <div className="row mt-5 pt-5 sell-section">
                <div className="col-sm-5">
                  <p className="read-line" >* {fee}% of your total amount is deducted as a fee by Tokenism</p>
                </div>
                <div className="col-sm-7 row">
                  <div className="col-4 col-sm-6 col-md-3">
                    <span className="label">Sub Total : </span>
                  </div>
                  <div className="col-8 col-sm-6 col-md-9">
                    <span className="label-custom">
                      <span>
                        <NumberFormat displayType={'text'} thousandSeparator={true} value={price * quantity} renderText={value => <span>{value}</span>} />
                        {' '}TKUSD
                      </span>
                    </span>
                  </div>
                  <div className="col-4 col-sm-6 col-md-3">
                    <span className="label">Fee<span className="text-danger">*</span> : </span>
                  </div>
                  <div className="col-8 col-sm-6 col-md-9">
                    <span className="label-custom">
                      <span>{(fee / 100) * (price * quantity)}{' '}TKUSD</span>
                    </span>
                  </div>
                  <hr className="col-12 divider" />
                  <div className="col-4 col-sm-6 col-md-3">
                    <span className="label">Total : </span>
                  </div>
                  <div className="col-8 col-sm-6 col-md-9">
                    <span className="label-custom">
                      <span>
                        <NumberFormat displayType={'text'} thousandSeparator={true} value={(price * quantity) - ((fee / 100) * (price * quantity))} renderText={value => <span>{value}</span>} />
                        {' '}TKUSD
                      </span>
                    </span>
                  </div>
                  <button type='submit' className="custom-btn">Sell</button>
                </div>
              </div>
            </ValidatorForm>
          </>}
      </section>
    )
  }
}

const mapStateToProps = ({ Exchange }) => {
  let { listingData } = Exchange;
  return { listingData };
};
const mapDispatchToProps = { getUserTokenBalance, sellTokens, getUserListingBalance, overlayLoader };
export default connect(mapStateToProps, mapDispatchToProps)(ListTokens);

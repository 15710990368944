import React, { Component } from 'react';
import { connect } from 'react-redux';
var Spinner = require('react-spinkit');


class Loader extends Component {
    state = {  }
    render() { 
        return (
            <div hidden={!this.props.overlayLoading} className="loader-overlay">
                <div className="loader-logo">
                    <img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/Logo0.png" alt="tokenism" />
                </div>
                <div className="loader-body">
                    <Spinner name="line-scale-party" color="#8CC63F"/>
                    <p className="message">{this.props.loadingMessage}</p>
                </div>
                <div className="loader-footer">
                    <p><strong>©</strong> 2020 Tokenism, LLC. All Rights Reserved.</p>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ Settings }) => {
    let { overlayLoading, loadingMessage } = Settings;
    return {
      overlayLoading,
      loadingMessage
    };
  };
 
export default connect(mapStateToProps)(Loader);
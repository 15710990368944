import React, { Component } from 'react';
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import Banner from '../../components/Banner'
import LazyLoad from 'react-lazyload';

class Technology extends Component
{
  constructor()
  {
    super()
    this.state = {}
    window.scrollTo(0,0);
  }

  render()
  {
    return (
      <div className="page-wrapper">
        <Navbar page='technology' {...this.props}/>
        <Banner
          heading = 'Technology'
          subHeading = 'Lorem ipsum dolor sit amet, consectetur adipisicing elit do eiusmod tempor consectetur'
          />

        <section className="fluid-section-one">
          <div className="container-fluid clearfix">
            <LazyLoad once >
              <div className="image-column wow fadeInRight animated" style={{backgroundImage: `url("https://tknismtest.s3.amazonaws.com/webImages/34.jpg")`, visibility: "visible", animationName: "fadeInRight"}}>
                <figure className="image-box"><img src="https://tknismtest.s3.amazonaws.com/webImages/34.jpg" alt="" /></figure>
              </div>
            </LazyLoad>
            <div className="content-column">
              <div className="inner-column">
                <div className="row clearfix">
                  <div className="text-column">
                    <div className="inner-box" style={{backgroundImage: `url("https://tknismtest.s3.amazonaws.com/webImages/30.png")`}}>
                      <h4>Real-time Marketplace  </h4>
                      <p>Tokenism lowers traditional real estate market barriers by allowing for fractionalized ownership via
                        tokenization. For a $1k minimum investment, investors can purchase security tokens in the form of
                        an asset-backed, token that can be traded much like a stock with similar liquidity.</p>
                      <p>These tokens can then be freely-traded on the Tokenism marketplace allowing investors access to
                        easy liquidity. We can compress the months-long closing and settlement period of real estate assets
                        to mere seconds, while reducing the settlement period for any publicly-traded REIT or security from
                        T+2 days to a few seconds.
                      </p>
                      <p>On our platform Investors have full access to a comprehensive &amp; real time resource of asset
                        performance data, due diligence documents, developer information, financial data, and other
                        information related to each investable asset on our platform.
                      </p>
                      <p>In addition to buying and selling fractionalized real estate, investors also earn rental income
                        distributions on a quarterly basis.
                      </p>
                      {/* <a href="#" className="thm-btn btn-style-one">View Detail <i className="fa fa-plus"></i></a> */}

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="fluid-section-two">
          <div className="container-fluid clearfix">
            <div className="content-column">
              <div className="inner-column">
                <div className="row clearfix">

                  <div className="text-column">
                    <div className="inner-box" style={{backgroundImage: `url("https://tknismtest.s3.amazonaws.com/webImages/30.png")`}}>
                      <h4>What is Blockchain and how we use the technology </h4>
                      <p>
                        Blockchain is the underlying technology of digital currencies. In basic terms, blockchains are records of data stored and managed on networks of computers around the world called nodes. This decentralization of data ensures that no single entity can control the recording and storage process. Once data has been recorded onto a blockchain it cannot be changed or altered in any way. This is achieved through the use of cryptographic algorithms.
                      </p>
                      {/* <a href="#" className="thm-btn btn-style-one">View Detail <i className="fa fa-plus"></i></a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoad once >
              <div className="image-column wow fadeInRight animated" style={{backgroundImage: `url("https://tknismtest.s3.amazonaws.com/webImages/31.jpg")`, visibility: "visible", animationName: "fadeInRight"}}>
                <figure className="image-box"><img src="https://tknismtest.s3.amazonaws.com/webImages/31.jpg" alt="" /></figure>
              </div>
            </LazyLoad>

          </div>
        </section>
        <section className="fluid-section-three">
          <div className="container-fluid clearfix">
            <LazyLoad once >
              <div className="image-column wow fadeInRight animated" style={{backgroundImage: `url("https://tknismtest.s3.amazonaws.com/webImages/32.jpg")`, visibility: "visible", animationName: "fadeInRight"}}>
                <figure className="image-box"><img src="https://tknismtest.s3.amazonaws.com/webImages/32.jpg" alt="" /></figure>
              </div>
            </LazyLoad>

            <div className="content-column">
              <div className="inner-column">
                <div className="row clearfix">
                  <div className="text-column">
                    <div className="inner-box" style={{backgroundImage: `url("https://tknismtest.s3.amazonaws.com/webImages/30.png")`}}>
                      <h4>What is a stable Coin & why is it compliant/secure  </h4>
                      <p>
                        While BitCoin and other cryptocurrencies continue to rise in popularity, they all suffer from high volatility in their valuations. It is common to see the price of a cryptocurrency moving in excess of 10 percent in either direction within a span of a few hours. Because of this, cryptocurrencies have remained unreliable for everyday use in transactions.
                      </p>
                      {/* <a href="#" className="thm-btn btn-style-one">View Detail <i className="fa fa-plus"></i></a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="fluid-section-two">
          <div className="container-fluid clearfix">
            <div className="content-column">
              <div className="inner-column">
                <div className="row clearfix">
                  <div className="text-column">
                    <div className="inner-box" style={{backgroundImage: `url("https://tknismtest.s3.amazonaws.com/webImages/30.png")`}}>
                      <h4>Cutting edge AI  </h4>
                      <p>
                        Tokenism is an innovative AI and blockchain-based platform for fractionalized real estate investing! Tokenism has
                        developed a proprietary AI engine for due diligence and real-time processing and reporting property management
                        data for each of the assets on its platform!
                      </p>
                      <p>More over the AI backed system would monitor and project performance
                        and possible report any upcoming problems with the building including tenancy & Maintenance issues utilizing utility
                        usages and expense trends! Imagine a building where management could know of a leak or an outage before a
                        tenant even reports an issue. Property management utilizing AI will take efficiency and optimal performance to a
                        new level!
                      </p>
                      {/* <a href="#" className="thm-btn btn-style-one">View Detail <i className="fa fa-plus"></i></a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <LazyLoad once >
              <div className="image-column wow fadeInRight animated" style={{backgroundImage: `url("https://tknismtest.s3.amazonaws.com/webImages/33.jpg")`, visibility: "visible", animationName: "fadeInRight"}}>
                <figure className="image-box"><img src="https://tknismtest.s3.amazonaws.com/webImages/33.jpg" alt="" /></figure>
              </div>
            </LazyLoad>

          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default Technology;

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import {
  favourite,
} from "../../store/actions/Settings";
import { connect } from "react-redux";
import Tooltip from '@material-ui/core/Tooltip';

class SinglePropertyItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      item: props.item
    }
  }
  handleMouseEnter = (value) => this.setState({ [value]: true });
  handleMouseLeave = (value) => this.setState({ [value]: false });

  favourite = (id, onFavourites) => {
    this.props.item.favourited = !this.props.item.favourited
    this.setState({ item: this.props.item })
    this.props.favourite({ payload: id, onFavourites: onFavourites })
  }

  render() {
    const { item } = this.state;
    const sto = item['stoId'] || {};
    const { favourites } = this.props;
    return (
      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-10 position-relative">
        <Link id={'property' + item._id} to={(sto['status'] === 'Canceled' || sto['status'] === 'Closed') ? '' : `/single-property?id=${item._id}`} className={(sto['status'] === 'Canceled' || sto['status'] === 'Closed') ? 'cursor-default' : ""}>
          <div className="property">
            <div className="property-img">
                <img className="img" src={item.banner.location} alt={item.banner.name} />
              <div className={(sto['status'] === 'Canceled' || sto['status'] === 'Closed') ? "status-cancel" : "status"}><span>{item['offeringStage']}</span></div>
            </div>
            <h3 className="property-name">{item['title']}</h3>
            <p className="property-adr">Ohio, {item['city']} </p>
            <Tooltip title="Targeted Rate Of Return" placement="bottom-start" >
              <h4 className="property-ir">{item['tagetedROR']}% IRR Over 10 years</h4>
            </Tooltip>
            <div className="d-flex detail">
              <div className="m-0">
                <img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/percentage-icon.png" />
                <span>Units</span>
              </div>
              <span>{item.units}</span>
            </div>
            <div className="d-flex detail">
              <div className=" m-0">
                <img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/cashback-icon.png" />
                <span>Debt</span>
              </div>
              <span>{item.deptSwap}%</span>
            </div>
            <div className="d-flex detail">
              <Tooltip title="Net Operating Income" >
                <div className="m-0">
                  <img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/growth-icon.png" />
                  <span>NOI</span>
                </div>
              </Tooltip>
              <NumberFormat value={item.noiPercent} displayType={'text'} thousandSeparator={true} prefix={'$'} renderText={value => <span>{value}</span>} />
            </div>
          </div>
        </Link>
        {this.props.auth &&
          <>
            {item.favourited || favourites ?
              <span id="propertyItem-addFav" className="add-fav active" onClick={(value) => this.favourite(item._id, favourites ? true : false)}><FavoriteIcon /></span>
              : <span id="propertyItem-removeFav" className="add-fav" onClick={(value) => this.favourite(item._id, false)}><FavoriteBorderIcon /></span>
            }</>
        }

      </div>
    );
  }
}


const mapDispatchToProps = {
  favourite
};

const mapStateToProps = ({ Auth }) => {
  let { auth } = Auth;
  return { auth }
}

export default connect(mapStateToProps, mapDispatchToProps)(SinglePropertyItem);

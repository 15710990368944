import { css } from "@emotion/core";
import { connect } from "react-redux";
import React, { Component } from "react";
import { Modal, ModalBody } from "reactstrap";
import FadeLoader from "react-spinners/FadeLoader";
import validator from "validator";
import MuiPhoneInput from "material-ui-phone-number";
import DashboardLayout from "./../DashboardLayout";

import TwoFA from "./2FA";
import Profile from "./Profile";
import Trust from "./Trust";
import Entity from "./Entity";
import Joint from "./Joint";
import { userDashboard } from "../../../store/actions/Auth";
import { getAllPreferences } from "../../../store/actions/Settings";

import IdentityVerification from "./IdentityVerification";
import InvestorPreferences from "./InvestorPreferences/index";
import EmailPreferences from "./EmailPreferences/index";
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

import {
  handleUploadImage,
  googleAuthEnable,
  verify2Factor,
  handleGAModal,
  disableGoogleAuth,
  sendSMS,
  handleSMSModal,
  handleVerificationModal,
  verifyOTP,
  disableSmsAuth
} from "../../../store/actions/Settings";
import AccreditationVerification from "./AccreditationVerification";
import $ from 'jquery';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import Button from '@material-ui/core/Button';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: { marginTop: theme.spacing(2) },
}));

let count = 0;

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValid: true,
      isInvestor:false,
      isEmail:false,
      expand:false,
      phone: props.profile.phone,
      v1: "",
      v2: "",
      v3: "",
      v4: "",
      v5: "",
      v6: "",
      showOtpLoader: false,
      seconds: 59,
      editProfile:false,
      smsLoading:false,
      top: false,
      left: false,
      bottom: false,
      right: false,
      locale: [
        "ar-AE",
        "ar-BH",
        "ar-DZ",
        "ar-EG",
        "ar-IQ",
        "ar-JO",
        "ar-KW",
        "ar-SA",
        "ar-SY",
        "ar-TN",
        "be-BY",
        "bg-BG",
        "bn-BD",
        "cs-CZ",
        "de-DE",
        "de-AT",
        "da-DK",
        "el-GR",
        "en-AU",
        "en-CA",
        "en-GB",
        "en-GG",
        "en-GH",
        "en-HK",
        "en-MO",
        "en-IE",
        "en-IN",
        "en-KE",
        "en-MT",
        "en-MU",
        "en-NG",
        "en-NZ",
        "en-RW",
        "en-SG",
        "en-UG",
        "en-US",
        "en-TZ",
        "en-ZA",
        "en-ZM",
        "en-PK",
        "es-EC",
        "es-ES",
        "es-MX",
        "es-PA",
        "es-PY",
        "es-UY",
        "et-EE",
        "fa-IR",
        "fi-FI",
        "fj-FJ",
        "fr-FR",
        "fr-GF",
        "fr-GP",
        "fr-MQ",
        "fr-RE",
        "he-IL",
        "hu-HU",
        "id-ID",
        "it-IT",
        "ja-JP",
        "kk-KZ",
        "ko-KR",
        "lt-LT",
        "ms-MY",
        "nb-NO",
        "ne-NP",
        "nl-BE",
        "nl-NL",
        "nn-NO",
        "pl-PL",
        "pt-PT",
        "pt-BR",
        "ro-RO",
        "ru-RU",
        "sl-SI",
        "sk-SK",
        "sr-RS",
        "sv-SE",
        "th-TH",
        "tr-TR",
        "uk-UA",
        "vi-VN",
        "zh-CN",
        "zh-HK",
        "zh-MO",
        "zh-TW"
      ]
    };
  }

  componentDidMount(){
    $('.scroll-to-top').on('click',function(){
      $('html, body').animate({
        scrollTop: 0
      }, 1500);
      })
      this.props.getAllPreferences()
      const myId = window.location.hash.slice(1)
        let elem = document.getElementById(myId)
      if (window.location.hash){
        elem.scrollIntoView({ behavior: 'smooth'});
      }
  }

  componentWillReceiveProps(props) {
    if (count === 0) {
      this.setState({ phone: props.profile.phone });
      count = 1;
    }
    if(props.location.state){
      if(props.location.state.dwollaVerification) this.setState({editProfile:true})
      else this.setState({editProfile:false})
    }
    this.setState({ showOtpLoader: props.showOtpLoader });
  }

  handleGoogleAuth = ev => {
    this.setState({ v1: "", v2: "", v3: "", v4: "", v5: "", v6: "" });
    if (ev === "GA") {
      this.props.handleGAModal(true);
      this.props.googleAuthEnable(true);
    } else this.toggleSmsModal();
  };

  toggle = () => this.props.handleGAModal(!this.props.showGAModal);
  toggleSmsModal = () => this.props.handleSMSModal(!this.props.showSmsModal);
  toggleVerificationModal = () =>{
    this.props.handleVerificationModal(!this.props.showVerificationModal);
    this.setState({smsLoading:false})
  }


  handleCode = e => {
    if (e.target.name === "v1") {
      var input = document.getElementById("v2");
      input.focus();
      input.select();
    } else if (e.target.name === "v2") {
      var input = document.getElementById("v3");
      input.focus();
      input.select();
    } else if (e.target.name === "v3") {
      var input = document.getElementById("v4");
      input.focus();
      input.select();
    } else if (e.target.name === "v4") {
      var input = document.getElementById("v5");
      input.focus();
      input.select();
    } else if (e.target.name === "v5") {
      var input = document.getElementById("v6");
      input.focus();
      input.select();
    }

    this.setState({ [e.target.name]: e.target.value }, () => {
      const { v1, v2, v3, v4, v5, v6 } = this.state;

      if (
        v1 !== "" &&
        v2 !== "" &&
        v3 !== "" &&
        v4 !== "" &&
        v5 !== "" &&
        v6 !== ""
      ) {
        this.props.verify2Factor({ token_code: v1 + v2 + v3 + v4 + v5 + v6 });
      }
    });
  };

  handleVerificationCode = e => {
    if (e.target.name === "v1") {
      var input = document.getElementById("v2");
      input.focus();
      input.select();
    } else if (e.target.name === "v2") {
      var input = document.getElementById("v3");
      input.focus();
      input.select();
    } else if (e.target.name === "v3") {
      var input = document.getElementById("v4");
      input.focus();
      input.select();
    }

    this.setState({ [e.target.name]: e.target.value }, () => {
      const { v1, v2, v3, v4 } = this.state;
      if (v1 !== "" && v2 !== "" && v3 !== "" && v4 !== "") {
        this.setState({ showOtpLoader: true });
        clearInterval(this.interval)
        this.props.verifyOTP({ sms_code: v1 + v2 + v3 + v4 });
      }
    });
  };

  handlePhoneChange = (event, countryData) => {
    let upperCaseCode = countryData
      ? countryData.countryCode.toUpperCase()
      : "";
    let countryCode = countryData ? "en-" + upperCaseCode : "";
    let isValidPhoneNumber = validator.isMobilePhone(event, this.state.locale);
    this.isValid = isValidPhoneNumber;
    this.setState({ isValid: isValidPhoneNumber });
    this.setState({ phone: event });
  };

  handleSendSMS = () => {
    clearInterval(this.interval)
    this.props.sendSMS({ phone: this.state.phone });
    this.setState({ seconds: 59, smsLoading: true }, () => {
      if (this.state.seconds > 1) {
        this.interval = setInterval(() => this.tick(), 1000);
      }
    });
  };

  tick() {
    if (this.state.seconds >= 1) {
      this.setState({seconds: this.state.seconds-1})
    } else {
      clearInterval(this.interval);
    }
  }

  renderGoogleAuthModal = () => {
    return (
      <Modal
        modalClassName="modal-mini modal-info settings-rule modal-barCode"
        toggle={this.toggle}
        isOpen={this.props.showGAModal}
      >
        <div className="modal-header justify-content-center">
          <div className="modal-profile">
            <img
              className="modalLogoImg"
              src="https://tknismtest.s3.amazonaws.com/non-compressed-images/favicon.png.png"
              alt=""
            />
          </div>
        </div>
        <ModalBody>
          <div className="modal-body-container">
            <img className="bar-code-img" src={this.props.scanQR} alt="" />

            <p className="scanbarcode-title">Scan your barcode</p>
            <p className="verification-title">Enter your verification Code</p>
            <div className="code-input-container">
              <input
                name="v1"
                id="v1"
                onChange={this.handleCode}
                className="code-input"
                type="text"
                maxLength={1}
              />
              <input
                name="v2"
                id="v2"
                onChange={this.handleCode}
                className="code-input"
                type="text"
                maxLength={1}
              />
              <input
                name="v3"
                id="v3"
                onChange={this.handleCode}
                className="code-input"
                type="text"
                maxLength={1}
              />
              <div style={{ width: "10px" }}></div>
              <input
                name="v4"
                id="v4"
                onChange={this.handleCode}
                className="code-input"
                type="text"
                maxLength={1}
              />
              <input
                name="v5"
                id="v5"
                onChange={this.handleCode}
                className="code-input"
                type="text"
                maxLength={1}
              />
              <input
                name="v6"
                id="v6"
                onChange={this.handleCode}
                className="code-input"
                type="text"
                maxLength={1}
              />
            </div>
            <div className="loading-container">
              <FadeLoader
                css={override}
                size={150}
                color={"#8cc63f"}
                loading={this.props.gaLoading}
              />
            </div>
            <p className="downlaod-desc">
              Download the app to scan the barcode
            </p>
            <div className="store-btn-container">
              <a
                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en"
                target="_blank"
              >
                <img
                  className="store-btn"
                  src="https://tknismtest.s3.amazonaws.com/non-compressed-images/play-store.png"
                  alt=""
                />
              </a>
              <a
                href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                target="_blank"
              >
                <img
                  className="store-btn"
                  src="https://tknismtest.s3.amazonaws.com/non-compressed-images/app-store.png"
                  alt=""
                />
              </a>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  };

  renderSMSAuthModal = () => {
    return (
      <Modal
        modalClassName="modal-mini modal-info settings-rule modal-barCode"
        toggle={this.toggleVerificationModal}
        isOpen={this.props.showVerificationModal}
      >
        <div className="modal-header justify-content-center">
          <div className="modal-profile">
            <img
              className="modalLogoImg"
              src="https://tknismtest.s3.amazonaws.com/non-compressed-images/favicon.png.png"
              alt=""
            />
          </div>
        </div>
        <ModalBody>
          <div className="modal-body-container">
            <p className="sms-title">Enter OTP</p>
            <p
              className="verification-title"
              style={{ marginTop: "10px", width: "80%" }}
            >
              We have sent you verification code Via SMS for Text authentication
            </p>

            {this.state.seconds === 0 ? (
              <p className="resend-title" onClick={this.handleSendSMS}>
                Resend verification code
              </p>
            ) : (
              <p className="resend-title">
                Resend verification code in {this.state.seconds}
              </p>
            )}
            <div className="code-input-container">
              <input
                name="v1"
                id="v1"
                onChange={this.handleVerificationCode}
                className="code-input"
                type="text"
                maxLength={1}
              />
              <input
                name="v2"
                id="v2"
                onChange={this.handleVerificationCode}
                className="code-input"
                type="text"
                maxLength={1}
              />
              <input
                name="v3"
                id="v3"
                onChange={this.handleVerificationCode}
                className="code-input"
                type="text"
                maxLength={1}
              />
              <input
                name="v4"
                id="v4"
                onChange={this.handleVerificationCode}
                className="code-input"
                type="text"
                maxLength={1}
              />
            </div>
            <div className="loading-container">
              <FadeLoader
                css={override}
                size={150}
                color={"#8cc63f"}
                loading={this.state.showOtpLoader}
              />
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  };

  renderSMSModal = () => {
    return (
      <Modal
        modalClassName="modal-mini modal-info settings-rule modal-barCode"
        toggle={this.toggleSmsModal}
        isOpen={this.props.showSmsModal}
      >
        <div className="modal-header justify-content-center">
          <div className="modal-profile">
            <img
              className="modalLogoImg"
              src="https://tknismtest.s3.amazonaws.com/non-compressed-images/favicon.png.png"
              alt=""
            />
          </div>
        </div>
        <ModalBody>
          <div className="modal-body-container">
            <p className="sms-title">SMS Verification</p>
            <p
              className="verification-title"
              style={{ marginTop: "10px", width: "80%" }}
            >
              Please enter you mobile number
            </p>

            <div className="phone-container">
              <MuiPhoneInput
                autoFormat={false}
                disableAreaCodes={true}
                className="MyTextField"
                fullWidth
                label="Phone"
                name="phone"
                margin="dense"
                variant="outlined"
                disabled
                defaultCountry="us"
                error={!this.state.isValid}
                onChange={this.handlePhoneChange}
                validators={["required", this.state.isValid]}
                errormessages={[
                  "Phone number can not be empty",
                  "Phone Number Not Valid"
                ]}
                value={this.state.phone}
              />
              {/* {!this.state.isValid ? (
                <span className="errorMessage">Invalid Number</span>
              ) : (
                ""
              )} */}
            </div>
            {this.state.smsLoading
            ?
            <button className="send-btn">
              <i className="fa fa-spinner fa-spin fa-1x fa-fw" />
            </button>
            :
            <button className="send-btn" onClick={this.handleSendSMS}>
              Send SMS
            </button>
            }

          </div>
        </ModalBody>
      </Modal>
    );
  };

  handelPreferences = (activePref) => {
    this.setState({
      isInvestor:false,
      isEmail:false
    })
    if (activePref) {
      this.setState({
        [activePref]:!this.state[activePref]
      })
    }
  }

  handelSavePref = () => {
    this.setState({
      isInvestor:false,
      isEmail:false
    })
    const elem = document.getElementById("preferences")
    elem.scrollIntoView({ behavior: 'smooth'});
  }

  togglePreferences =  () => this.setState({ expand: !this.state.expand });

  toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    this.setState({[anchor]: open})
    // setState({ ...state, [anchor]: open });
  };


  list = (anchor) => {
  const classes = useStyles;
  return (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: this.state.anchor === 'top' || this.state.anchor === 'bottom',
      })}
      role="presentation"
      onClick={this.toggleDrawer(this.state.anchor, false)}
      onKeyDown={this.toggleDrawer(this.state.anchor, false)}
    >
    <Joint/>
    </div>
  );
};


  render() {
    const { state } = this
    const { isInvestor,isEmail,expand, anchor, open } = this.state
    const classes = useStyles;

    return (
      <div className="page-wrapper">
        <DashboardLayout active="settings" {...this.props}>
        {this.renderGoogleAuthModal()}
        {this.renderSMSAuthModal()}
        {this.renderSMSModal()}

        <section className="tabs-wallet-sec settings-rule">
          <div className="container">
            {/*
              <div>
                {['right'].map((anchor) => (
                  <React.Fragment key={anchor}>
                    <Button onClick={this.toggleDrawer(anchor, true)}>+ New Account</Button>
                    <SwipeableDrawer
                      anchor={anchor}
                      open={state[anchor]}
                      onClose={this.toggleDrawer(anchor, false)}
                      onOpen={this.toggleDrawer(anchor, true)}
                    >
                      {this.list(anchor)}
                    </SwipeableDrawer>
                  </React.Fragment>
                ))}
              </div>
            */}

            <Profile
              handleUploadImage={val => this.props.handleUploadImage(val)}
              editProfile={this.state.editProfile}
            />
            <div className="auth-container" id="preferences" >
                <div className='profile-header' onClick={this.togglePreferences}>
                    <i className={expand ? "fa fa-chevron-down fa-rotate-270" : "fa fa-chevron-down"}></i>
                    <h4 style={{ color: '#1A1E2C', marginLeft: "20px" }}>Preferences & Email Notifications</h4>
                </div>
                {
                  !isInvestor && isEmail || isInvestor && !isEmail || !isInvestor && !isEmail && (
                   <div hidden={expand} className="row profile-detail-container">
                      <div className="col-lg-6 col-12">
                        <div className="row align-items-center">
                        <div className="col-md-8 col-8">
                          <h5 className="auth-title">Investing Preferences</h5>
                        </div>
                        <div className="col-md-4 col-4">
                        <button className="custom-btn" type="button" onClick={()=>this.handelPreferences('isInvestor')} >Edit</button>
                        </div>
                      </div>
                      </div>
                      <div className="col-lg-6 col-12 mt-3 mt-lg-0">
                        <div className="row align-items-center">
                        <div className="col-md-8 col-8">
                          <h5 className="auth-title">Email Notifications</h5>
                        </div>
                        <div className="col-md-4 col-4">
                        <button className="custom-btn" type="button" onClick={()=>this.handelPreferences('isEmail')} >Edit</button>
                        </div>
                      </div>
                      </div>
                   </div>
                  )
                }
                <div hidden={expand}>
                {isInvestor && <InvestorPreferences savePref={this.handelSavePref} />}
                 {isEmail && <EmailPreferences savePref={this.handelSavePref} />}
                 </div>
            </div>

            <TwoFA
              profile={this.props.profile}
              disableGoogleAuth={this.props.disableGoogleAuth}
              disableSmsAuth={this.props.disableSmsAuth}
              handleGoogleAuth={val => this.handleGoogleAuth(val)}
            />

            <IdentityVerification />
            <AccreditationVerification {...this.props} />
          </div>
        </section>

        {/* <div className="scroll-to-top scroll-to-target" data-target="html">
          <i className="fa fa-arrow-up" aria-hidden="true"></i>
        </div> */}
        </DashboardLayout>
      </div>
    );
  }
}

const mapDispatchToProps = {
  userDashboard,
  handleUploadImage,
  googleAuthEnable,
  verify2Factor,
  handleGAModal,
  disableGoogleAuth,
  sendSMS,
  handleSMSModal,
  handleVerificationModal,
  verifyOTP,
  disableSmsAuth,
  getAllPreferences
};

const mapStateToProps = state => {
  return {
    profile: state.Settings.profile || {},
    scanQR: state.Settings.scanQR || {},
    gaLoading: state.Settings.gaLoading,
    showGAModal: state.Settings.showGAModal,
    showSmsModal: state.Settings.showSmsModal,
    showOtpLoader: state.Settings.showOtpLoader,
    showVerificationModal: state.Settings.showVerificationModal
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Settings);

// require('dotenv').config();
import React from 'react';
import { hydrate, render } from 'react-dom';
import { logger } from 'redux-logger';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware } from 'redux';
// import { persistStore, persistReducer } from "redux-persist";

import './index.css';
import App from './app';
import rootSaga from './store/sagas';
import rootReducer from './store/reducers';
import storage from "redux-persist/lib/storage";
// import { PersistGate } from "redux-persist/integration/react";
require('dotenv').config();

const sagaMiddleware = createSagaMiddleware();
// const persistConfig = { key: "root", storage };
// const persistedReducer = persistReducer(persistConfig, rootReducer);
// const store = createStore(persistedReducer, applyMiddleware(sagaMiddleware, logger));
const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));
// let persistor = persistStore(store);

sagaMiddleware.run(rootSaga);
render(
  <Provider store={store}>
    {/* <PersistGate persistor={persistor}> */}
      <App />
    {/* </PersistGate> */}
  </Provider>,
  document.getElementById('root'),
);
if (module.hot) module.hot.accept(App);

import { connect } from "react-redux";
import React, { Component } from 'react';
import { loginRequest, signupRequest } from "../../store/actions/Auth";
import LazyLoad from 'react-lazyload';

class Banner extends Component
{
  constructor()
  {
    super()
    this.state = {}
  }

  render()
  {
    return (
      <LazyLoad once>
      <div className="page-title style-three page-banner">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="page-content text-left">
                  <h1>{this.props.heading}</h1>
                  {
                    this.props.blogTitle
                    ?
                    <h3>{this.props.blogTitle}</h3>
                    :
                    ''
                  }
                </div>
              </div>
          </div>
        </div>
      </div>
      </LazyLoad>
    );
  }
}
const mapDispatchToProps =
{
  loginRequest,
  signupRequest,
};
export default connect(null,mapDispatchToProps)(Banner);

import React, { Component } from 'react';

import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import Banner from '../../components/Banner'
import { Modal, ModalBody } from 'reactstrap';
import LazyLoad from 'react-lazyload';

class OurTeam extends Component {
  constructor() {
    super()
    this.state = {
      hideNav: false,
      teamInfoModal: false,
      teamAndPartners:{
        team: [
          { title: 'Mohsin Masud', name: 'Mohsin Masud', designation: 'Founder & CEO', description: 'Mohsin has over a decade of experience in Real estate investments & development, having run a successful Investment having studied at York University, Harvard Business School, Graduate school of Design at Harvard University and most recently at Said Business School at University of Oxford Mohsin brings a wealth of real estate investment experience and a unique perspective to the needs and usability of the platform.  He has singlehandedly built-up a successful RE Investment firm in the Midwest and currently has multimillion dollar assets under management.', imageUrl: "https://tknismtest.s3.amazonaws.com/webImages/MianMohsin1.jpg" },
          { title: 'Zian van Wijk', name: 'Zian van Wijk', designation: 'Chief Technology Officer', description: 'Zian is an internationally experienced technical leader with a strong background in financial services and security markets. He specializes in aligning business strategy with technology, while still maintaining his hands-on technical skills. He has architected and built the core engine that has processed more than $2 billions of factored receivables for a New York-based fin-tech startup, and helped turn around an $80 million dollar failing project for New York City. He has a Master’s degree in the Management of Technology in Financial Services from the Polytechnic Institute of New York University.', imageUrl: "https://tknismtest.s3.amazonaws.com/non-compressed-images/Zian.png"},
          { title: 'Justin Jensen', name: 'Justin Jensen', designation: 'Head of Strategy', description: "Justin gained over 5 years of strategy consulting experience with the World Economic Forum, and as part of Oliver Wyman's Financial Services practice in New York, Stockholm, and Dubai. Justin is currently an MBA Candidate at The Wharton School of Business, majoring in Finance and Real Estate, and has an additional Masters in Economic Development from the University of Cambridge.",imageUrl: "https://tknismtest.s3.amazonaws.com/non-compressed-images/Justin.png" },
          { title: 'kiran Arif', name: 'Kiran Arif', designation: 'Founding Team Member', description: 'Design Guru and Serial Entrepreneur, with an indelible reputation for delivering services and client based solutions that improve organization margins. Recognized for improving procedures and systems increasing profitability. Broad experience in stream-lining operations, organization of innovative fund-raising, mobilizing top-tier talent to create high performing teams in challenging situations.', imageUrl: "https://tknismtest.s3.amazonaws.com/webImages/Kiran.jpg" },
          { title: 'Muhammad Jawad', name: 'Muhammad Jawad', designation: 'Director offshore operations', description: 'Over Twelve years of experience in information systems, processes management, security & servers management; Jawad has managed multiple teams spread across the globe and is bringing his expertise to Tokenism to ensure seamless operations coordination between the offshore teams.', imageUrl: "https://tknismtest.s3.amazonaws.com/webImages/Jawad.jpg" },
          { title: 'Muhammad Mousa', name: 'Muhammad Mousa', designation: 'Tech Lead', description: 'Mousa has over 5 years of experience in Full Stack Development with NodeJS, Solidity, ReactJS, Angular6. He previously developed multiple leading Crypto Exchanges and hard fork coins.', imageUrl: "https://tknismtest.s3.amazonaws.com/non-compressed-images/Mousa.png" },
          { title: 'René Bastón', name: 'René Bastón', designation: 'Advisor', description: 'René Bastón has over 20 years of experience in innovation, alliance building, public/private collaborations, entrepreneurship, technology transfer and commercialization. He spent years as a management consultant for systems integrator, Ernst & Young; has created a number of public/private partnerships; and has founded startups based on the transfer of university, NIH, and NIST technologies.', imageUrl: "https://tknismtest.s3.amazonaws.com/webImages/Rene.png" },
          { title: 'Bassam Q', name: 'Bassam Q', designation: 'Advisor, Marketing Strategy', description: 'Bassam is currently the Creative Excellence director at Coca Cola China and has over a decade’s worth of experience in marketing and sales strategy. His areas of expertise include product development, brand & identify management, advertising, media and digital/social.', imageUrl: "https://tknismtest.s3.amazonaws.com/non-compressed-images/bassam.jpg" },
          { title: 'Ann T Mooney', name: 'Ann T Mooney', designation: 'Advisor', description: 'Ann is an 18-yr P&G veteran who made her mark as a commercially-savvy marketer & strategist. She launched Rising Moon Consulting in 2009. Clients include an impressive array of C-Suite executives - from Fortune100 to high-growth start-ups. Ann’s deep understanding of the inner workings of each gives her a unique lens on how to bridge the challenging divide to drive accelerated growth though innovation.', imageUrl: "https://tknismtest.s3.amazonaws.com/webImages/Ann.jpg" },
          { title: 'Omar A. Butt', name: 'Omar A. Butt', designation: 'Advisor', description: 'Omar brings nearly two decades of Digital, Data and Analytics experience. He has spent year as an advisor with top global consulting firms and a practitioner at non-profits and startups across North America, Europe and Africa. Omar has been a trusted digital advisor for some of the Fortune 500 companies and a number of technology startups.', imageUrl: "https://tknismtest.s3.amazonaws.com/webImages/Omar.jpg" },
          { title: 'Mian Mustafa', name: 'Mian Mustafa', designation: 'Advisor', description: 'Mustafa has eight years of experience in financial services, working with bulge bracket banks in Toronto and New York. Currently, within his role in investment banking, Mustafa advises companies on M&A and capital markets transactions across multiple industries in Canada. Mustafa holds a Bachelor of Arts from McGill University and an MBA from Ivey Business School at the University of Western Ontario.', imageUrl: "https://tknismtest.s3.amazonaws.com/webImages/Mustafa.jpeg" },
          { title: 'Koji Sadoo', name: 'Koji Sadoo', designation: 'Advisor', description: 'Koji is a strategy & finance manager and consultant with 15+ years of global experience in consumer goods, prestige beauty, automotive, and toy industries at Procter & Gamble. He also brings an entrepreneurial mindset and advanced skills in Business planning, strategic revenue generation, Sales, and Multi-functional project management', imageUrl: "https://tknismtest.s3.amazonaws.com/webImages/Koji1.jpg" },
          { title: 'Deja Williams', name: 'Deja Williams', designation: 'Digital & Marketing Coordinator', description: 'Deja is a digital marketer and content strategist with a background in public relations. She has worked at some of the best PR & Marketing firms within the real estate sector such as Rubenstein, in New York City. She earned an undergraduate degree in Communication from North Carolina State University and a Master’s in Integrated Marketing with a digital concentration from New York University.', imageUrl: "https://tknismtest.s3.amazonaws.com/compressedImages/deja.JPG" },
          { title: 'Danial Shakeel', name: 'Danial Shakeel', designation: 'Front End Development Lead', description: 'Danial is a Front End Engineer with extensive experience in front end development and coding. Previously, Danial worked as a Front End Developer at SOFIT Consultancy and received a master’s degree in Information Technology from Bahauddin Zakariya University.', imageUrl: "https://tknismtest.s3.amazonaws.com/webImages/dani+copy.jpg" },
          { title: 'Nasir Ali', name: 'Nasir Ali', designation: 'Blockchain Developer', description: 'Software Engineer with more than 3 years of experience in blockchain technology using different platforms Ethereum, EOS and Hyperledger fabric. He has worked on ERC-20,ERC-721, ERC-1400,ERC-1132, ERC1404, crowdfunding, dApps, Exchanges and many more projects using solidity for smart contracts', imageUrl: "https://tknismtest.s3.amazonaws.com/non-compressed-images/Nasir1.png" },
          { title: 'Hammad Tariq', name: 'Hammad Tariq', designation: 'Front End Engineer', description: 'Innovative Front End engineer with over 3 years of experience building and maintaining responsive websites in the recruiting industry. Proficient in HTML5, CSS3, JavaScript and its libraries and frameworks like React JS and Vue JS Plus Next Js and Nuxt JS.', imageUrl: "https://tknismtest.s3.amazonaws.com/non-compressed-images/hammad1.png" },
          { title: 'Adeel Saleem', name: 'Adeel Saleem', designation: 'Sr Automation Engineer', description: 'Adeel has over 5 years of experience as a Software Quality Assurance Engineer. He has added value to the project with his skills in automated and manual testing. He has worked on different automation tools such as Seleinum, Jmetre, Postman and others.', imageUrl: "https://tknismtest.s3.amazonaws.com/compressedImages/adeel.jpeg" },
          {title: 'Irij Mahmood', name: 'Irij Mahmood', designation: 'Visual Graphic Designer', description: "Irij is a visual and motion graphic designer with a bachelor's degree in Graphic Design. She has 4+ years of experience in UI, UX, print media, social media, logo design, branding, and all sorts of visual designs along with 2D animation and post-production.", imageUrl: "https://tknismtest.s3.amazonaws.com/webImages/irij+photo.jpg" },
        ],
        partners:[
          { title: 'Taft Law', name: 'Taft Law', designation: 'Council & Compliance', description: 'Taft is a modern law firm comprised of more than 600 attorneys who have been delivering exceptional legal services in virtually every area of law and across industries since 1885. With a one-class partnership and decentralized leadership structure, Taft has 11 offices primarily located in the Midwest.', imageUrl: "https://tknismtest.s3.amazonaws.com/webImages/taftTest.png" },
          { title: 'MCM CPAS', name: 'MCM CPAS', designation: 'Council & Compliance', description: 'MCM is a full-service business strategy consulting firm. We work across multiple verticals and specialize in various industry areas.', imageUrl: "https://tknismtest.s3.amazonaws.com/mcm.png" },
          { title: 'IdentityMind', name: 'IdentityMind', designation: 'IdentityMind', description: 'IdentityMind proivdes the weapons you need to fight and win against financial crime throughout the lifecycle of your customers.', imageUrl: "https://tknismtest.s3.amazonaws.com/webImages/id.jpg" },
          { title: 'EarlyIQ', name: 'EarlyIQ', designation: 'EarlyIQ', description: 'EarlyIQ is the leading provider of simple, secure online diligence and compliance solutions for private financial markets.', imageUrl: "https://tknismtest.s3.amazonaws.com/webImages/earlyIq.jpg" },
          { title: 'DWOLLA', name: 'DWOLLA', designation: 'DWOLLA', description: 'Dwolla, Inc. is an agent of Veridian Credit Union and all funds associated with your account in our network are held in one or more pooled accounts at Veridian Credit Union. These funds may not be eligible for share insurance by the National Credit Union Share Insurance Fund. Dwolla, Inc. is the operator of a software platform that communicates user instructions for funds transfers to Veridian Credit Union.', imageUrl: "https://tknismtest.s3.amazonaws.com/webImages/dwolla.jpg" },
          // { title: 'MCM CPAs & Advisors', name: 'MCM CPAs & Advisors', designation: 'Strategy Consulting & Accountants', description: 'As a large regional CPA firm, MCM LLP provides highly specialized services to a broad range of clients. Privately and publicly held businesses, not-for-profits, small businesses and individuals across the region and throughout the country. MCM combines the technical and industry expertise of larger firms without sacrificing the attention and personal service of a local firm. We help our clients reach their goals through insight, innovation and experience. MCM continues to grow, yet maintains the same friendly, approachable style of doing business that its clients have come to rely on and expect.', imageUrl: "https://tknismtest.s3.amazonaws.com/webImages/28.jpg" },
        ]
      }
    }
    window.scrollTo(0, 0);
  }
  componentDidMount() {
    if (!this.props.match.isExact) this.setState({ hideNav: true })
  }

  closeModal = () => this.setState({ teamInfoModal: false });
  handleMouseEnter = (value) => this.setState({ [value]: true });
  handleMouseLeave = (value) => this.setState({ [value]: false });

  toggleModal = (value) => {
    this.setState({ modalData: this.state.teamAndPartners.team[value] });
    this.setState({ teamInfoModal: !this.state.teamInfoModal });
  }

  render() {
    return (
      <div className="page-wrapper">
        <Navbar page='our-team' hideNav={this.state.hideNav} {...this.props} />
        <Banner
          heading='About Us'
          subHeading='Lorem ipsum dolor sit amet, consectetur adipisicing elit do eiusmod tempor consectetur'
        />
        <section className="about-sec">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="content-area text-center">
                  <div className="sec-title text-center">
                    <h2 data-animation-child className="title-fill" data-animation="title-fill-anim" data-text="About Company">OUR MISSION</h2>
                  </div>
                  <p>We believe that commercial real estate investing should be straightforward and accessible to everyone.</p>
                  <p>By tokenizing assets on our unique blockchain-based platform, we democratize investing and streamline the entire process, offering both investors and asset owners a more efficient way to invest.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="team-sec">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="sec-title text-center">
                  <h2 data-animation-child className="title-fill" data-animation="title-fill-anim" data-text="Board of Directors">OUR TEAM</h2>
                </div>
              </div>
              {this.state.teamAndPartners.team.map( (member, index ) => {
                  return (
                    <div className="team-column text-center col-sm-6 col-md-4 col-lg-3">
                      <div className="team-inner"
                        onClick={() => this.toggleModal(index)}
                        onMouseEnter={() => this.setState({ [`member${index+1}`]: true })}
                        onMouseLeave={() => this.setState({ [`member${index+1}`]: false })}
                      >
                        <LazyLoad once>
                          <div className="image-box">
                            <figure><img src={member.imageUrl} alt={member.name} /></figure>
                          </div>
                        </LazyLoad>
                        <div className="text-column">
                          <h3>{member.name}</h3>
                          <p>{member.designation}</p>
                          {
                            this.state[`member${index+1}`] ? <p className="detailsButton" onClick={() => this.toggleModal(index)}>Details</p> : ''
                          }
                        </div>
                      </div>
                    </div>
                  )
              })}
            </div>
          </div>
        </section>

        <section className="partners-sec">
          <div className="container">
            <div className="row">
              <div className="row pt-5">
                <div className="col-12">
                  <div className="sec-title text-center">
                    <h2 data-animation-child className="title-fill" data-animation="title-fill-anim" data-text="Board of Directors">Our Partners</h2>
                  </div>
                </div>
                {this.state.teamAndPartners.partners.map( partner => {
                  return (
                <div className="team-column text-center">
                  <div className="partner-inner">
                    <LazyLoad once>
                      <div className="image-box">
                        <figure><img src={partner.imageUrl} alt={partner.name} /></figure>
                      </div>
                    </LazyLoad>
                  </div>
                </div>
                  )})
                }
              </div>
            </div>
          </div>
        </section>
        <Modal
          modalClassName="team modal-lg moda-info"
          toggle={this.teamInfoModal}
          isOpen={this.state.teamInfoModal}
        >
          <ModalBody>
            <div className="justify-content-center row">
              <div className="offset col-xl-11 col-lg-11 col-md-11 col-sm-11">
                <img height={130} className="imageModal" src={this.state.modalData ? this.state.modalData.imageUrl : ''} alt="" />
              </div>
              <div className="right">
                <i className="fa fa-close" onClick={this.closeModal} style={{ color: '#8cc63f' }}></i>
              </div>
              <div className="modalLargeHeading pt-3">
                {this.state.modalData ? this.state.modalData.name : ''}
              </div>
            </div>
            <hr />
            <span className="modalLargeDescription">
              {this.state.modalData ? this.state.modalData.description : ''}
            </span>
          </ModalBody>
        </Modal>
        <Footer />
      </div>
    );
  }
}
export default OurTeam;

import React, { Component } from 'react'

import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import { getSignData } from '../../store/actions/Auth';
import { connect } from 'react-redux';

class SignUpProcess extends Component
{
  constructor()
  {
    super()
    this.state = {}
    window.scrollTo(0,0);
  }

  componentDidMount(){
    this.props.getSignData('')
  }

  setPackage =(e)=>{
    this.props.history.push({
      pathname: '/investment-plans',
      state: { package: e }
    })
  }

  render()
  {
    return (
        <div className="page-wrapper">
            <Navbar page= 'signup-process' {...this.props}/>
            <section className="tob-banner style-three" style={{backgroundColor: '#f1f2f7'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="banner-where-area">
                                <h2>Where would you like to begin?</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pricing-propety">
                <div className="container">
                    <div className="row">
                        <div className="row clearfix  justify-content-center">
                            <div className="col-10 col-lg-4 col-md-6 col-sm-12">
                                <div className="service-inner text-center">
                                    <h3>Starter</h3>
                                    <p>With a $1,000 minimum initial investment, getting started with real estate investing has never been easier.</p>
                                    <div className="bottom-area">
                                        <p>Target Diversification</p>
                                        <h2>1 - 5 Properties</h2>
                                        <p>Typical initial investment</p>
                                        <h2>$1,000 - $ 5,000</h2>
                                        <a href="#" onClick={(e) => { e.preventDefault(); this.setPackage('Starter')}} className="select-btn thm-btn btn-style-one">Select</a>
                                    </div>
                                    <a href="#" className="round-btn"><i className="fa fa-angle-right"></i></a>
                                </div>
                            </div>
                            <div className="col-10 col-lg-4 col-md-6 col-sm-10">
                                <div className="service-inner text-center">
                                    <h3>Core</h3>
                                    <p>A solid foundation. Diversify across multiple projects and tailor your portfolio to your investment objectives.</p>
                                    <div className="bottom-area">
                                        <p>Target Diversification</p>
                                        <h2>5 - 10 Properties</h2>
                                        <p>Typical initial investment</p>
                                        <h2>$5,000 - $10,000</h2>
                                        <a href="#" onClick={(e) => { e.preventDefault(); this.setPackage('Core')}} className="select-btn thm-btn btn-style-one">Select</a>
                                    </div>
                                    <a className="round-btn"><i className="fa fa-angle-right"></i></a>
                                </div>
                            </div>
                            <div className="col-10 col-lg-4 col-md-6 col-sm-10">
                                <div className="service-inner text-center">
                                    <h3>Advanced</h3>
                                    <p>Everything you get with Core, plus diversify into more sophisticated strategies with higher potential returns.</p>
                                    <div className="bottom-area">
                                        <p>Target Diversification</p>
                                        <h2>25+ Properties</h2>
                                        <p>Typical initial investment</p>
                                        <h2>$25,000+</h2>
                                        <a href="#" onClick={(e) => { e.preventDefault();this.setPackage('Advanced')}} className="select-btn thm-btn btn-style-one">Select</a>
                                    </div>
                                    <a className="round-btn"><i className="fa fa-angle-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
  }
}

const mapStateToProps = (state)=>
{
  return {
    signData: state.Auth.signData
  }
}

const mapDispatchToProps= {getSignData};
export default connect(mapStateToProps, mapDispatchToProps)(SignUpProcess);

import axios from "axios";
import EventBus from "eventing-bus";
import { put, all, takeLeading, call } from "redux-saga/effects";

import { getBanks } from "../actions/Wallet";
import { getHistoryDW } from "../actions/Auth";
import { otpLoader, overlayLoader } from "../actions/Settings";

function* submitWiresRequest({ payload }) {
  console.log('payload', payload);
  const { error, response } = yield call(postCall, {
    path: `/wallet/reqWithdrawal`,
    body: payload
  });
  EventBus.publish("withdrawLoader");
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else if (response) {
    yield put({
      type: "USER_DASHBOARD",
    });
    EventBus.publish("withDrawRequested", "DWHistory");
    EventBus.publish("success", response.data.message)
  };
  yield put(overlayLoader({status:false, loadingMessage:''}));
}

function* onGetWires() {
  const { response } = yield call(getCall, "/wallet/wireDetails");
  if (response)
    yield put({
      type: "GET_WIRES_RESPONSE",
      payload: response["data"]["body"]
    });
    yield put(overlayLoader({status:false, loadingMessage:''}));
}

function* sendWireEmail() {
  const { response } = yield call(getCall, "/wallet/wireDetailsEmail");
  if (response){
    EventBus.publish("success", response.data.message)
    yield put(overlayLoader({status:false, loadingMessage:''}));
  }
}

function* onAddBankAccount({ payload }) {
  yield put(overlayLoader({status:true, loadingMessage:'Processing'}));
  // yield put(otpLoader(true));
  const { error, response } = yield call(postCall, {
    path: "/wallet/banks",
    body: payload
  });
  if (error) {
    yield put(otpLoader(false));
    EventBus.publish("error", error["response"]["data"]["message"]);
    yield put(overlayLoader({status:false, loadingMessage:''}));
  }
  else if (response) {
    EventBus.publish("success", response.data.message);
    // yield put({
    //   type: "GET_USER_BANKS_RESPONSE",
    //   payload: response["data"]["body"]
    // });

    yield put({ type: "GET_USER_BANKS" });
    yield put(overlayLoader({status:false, loadingMessage:''}));
    yield put(otpLoader(false));
  }
}

function* withdraw({ payload }) {
  const { error, response } = yield call(postCall, {
    path: `/wallet/withdraw/${payload["bankId"]}`,
    body: payload
  });
  EventBus.publish("withdrawLoader");
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else if (response) {
    yield put({
      type: "USER_DASHBOARD",
    });
    EventBus.publish("paymentWithdrawed", "DWHistory");
    EventBus.publish("success", response.data.message)
  };
  yield put(overlayLoader({status:false, loadingMessage:''}));
}

function* onDepositeBankPayment({ payload }) {
  const { error, response } = yield call(postCall, {
    path: `/wallet/bankDeposit/${payload["bankId"]}`,
    body: { amount: payload.amount, smsVerifyKey:payload.smsVerifyKey }
  });
  EventBus.publish("bankDeposit");

  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else if (response) {
    yield put({
      type: "USER_DASHBOARD",
    });
    EventBus.publish("paymentDeposited", "DWHistory");
    EventBus.publish("success", response.data.message);
  }
  yield put(overlayLoader({status:false, loadingMessage:''}));

}

function* onGetBanks() {
  const { response } = yield call(getCall, "/wallet/banks");
  if (response)
    yield put({
      type: "GET_USER_BANKS_RESPONSE",
      payload: response["data"]["body"]
    });
    yield put(overlayLoader({status:false, loadingMessage:''}));
}

function* onDeleteBank({ payload }) {
  const { error, response } = yield call(
    deleteCall,
    "/wallet/banks/" + payload
  );
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else if (response) {
    EventBus.publish("success", response.data.message);
    yield put({
      type: "GET_USER_BANKS_RESPONSE",
      payload: response["data"]["body"]
    });
  }
}

function* autoPlaid({ payload }) {
  const { error, response } = yield call(postCall, { path: `/wallet/autoPlaid`, body: payload });
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  if (response) EventBus.publish("success", response['data']['message']);
  EventBus.publish("AccountDetails");
  yield put(getBanks());
}

function* verifyMicro({ payload }) {
  const { error, response } = yield call(postCall, { path: `/wallet/verifyBanks`, body: payload });
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  if (response) {
    yield put(getBanks());
    EventBus.publish("success", response['data']['message']);
  }
}


function* getEmailVerification() {
  const {error, response } = yield call(getCall, '/wallet/withdrawalMail');
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  if (response) {
    EventBus.publish("success", response['data']['message']);
  }
}

function* getSmsVerification() {
  const {error, response } = yield call(getCall, "/wallet/withdrawalSms");
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  if (response) {
    EventBus.publish("success", response['data']['message']);
  }
}

function* withdrawlVerification({ payload }) {
  const { error, response } = yield call(postCall, { path: `/wallet/verifyWithdrawal`, body: payload });
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  if (response) {
    EventBus.publish("success", response['data']['message']);
    yield put({
      type: "WITHDRAWL_VERIFIED",
      payload: response["data"]["code"]
    });
  }
}

function* cancelDeposit({ payload }) {
  yield put(overlayLoader({status: true, loadingMessage: 'Canceling your deposit...'}));
  const { error, response } = yield call(getCall, `/wallet/bankDeposit/${payload}`);
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  if (response) EventBus.publish("success", response['data']['message']);
  yield put(overlayLoader({status:false, loadingMessage:''}));
  setTimeout(function* () {
    yield put(getHistoryDW());
  }, 500);
}

function* actionWatcher() {
  yield takeLeading("SUBMIT_WIRES_REQUEST", submitWiresRequest);
  yield takeLeading("SEND_WIRE_EMAIL", sendWireEmail);
  yield takeLeading("GET_WIRES", onGetWires);
  yield takeLeading("AUTO_PLAID", autoPlaid);
  yield takeLeading("WITHDRAW_FUNDS", withdraw);
  yield takeLeading("VERIFY_MICRO", verifyMicro);
  yield takeLeading("DELETE_BANK", onDeleteBank);
  yield takeLeading("GET_USER_BANKS", onGetBanks);
  yield takeLeading("CANCEL_BANK", cancelDeposit);
  yield takeLeading("ADD_BANK_ACCOUNT", onAddBankAccount);
  yield takeLeading("DEPOSITE_BANK_PAYMENT", onDepositeBankPayment);
  yield takeLeading("WITHDRAWL_VERIFICATION", withdrawlVerification);
  yield takeLeading("GET_EMAIL_CODE", getEmailVerification);
  yield takeLeading("GET_SMS_CODE", getSmsVerification);
}

function postCall({ body, path }) {
  return axios
    .post(path, body)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401){
      EventBus.publish("tokenExpired");
      return ;
      }
      return { error };
    });
}

function getCall(path) {
  return axios
    .get(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401){
      EventBus.publish("tokenExpired");
      return ;
      }
      return { error };
    });
}

function deleteCall(path) {
  return axios
    .delete(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401){
      EventBus.publish("tokenExpired");
      return ;
      }
      return { error };
    });
}

export default function* rootSaga() {
  yield all([actionWatcher()]);
}

export const addBankAccount = data => ({
  type: "ADD_BANK_ACCOUNT",
  payload: data
});

export const withdraw = data => ({
  type: "WITHDRAW_FUNDS",
  payload: data
});

export const getBanks = data => ({
  type: "GET_USER_BANKS",
  payload: data
});

export const getWires = () => ({
  type: "GET_WIRES"
});

export const getEmailVerification = () => ({
  type: "GET_EMAIL_CODE"
});

export const getSmsVerification = () => ({
  type: "GET_SMS_CODE"
});

export const withdrawlVerification = data => ({
  type: "WITHDRAWL_VERIFICATION",
  payload: data
});

export const sendWireEmail = () => ({
  type: "SEND_WIRE_EMAIL"
});

export const resetWallet = () => ({
  type: 'RESET_WALLET'
});

export const autoPlaid = (data) => ({
  type: 'AUTO_PLAID',
  payload: data
});

export const deleteBank = data => ({
  type: "DELETE_BANK",
  payload: data
});

export const cancelDeposit = data => ({
  type: "CANCEL_BANK",
  payload: data
});

export const depositeBankPayment = data => ({
  type: "DEPOSITE_BANK_PAYMENT",
  payload: data
});

export const updateDeposit = data => ({
  type: "UPDATE_DEPOSIT",
  payload: data
});

export const verifyMicro = data => ({
  type: "VERIFY_MICRO",
  payload: data
});

export const imageUploaded = data => ({
  type: "IMAGE_UPLOADED",
  payload: data
});

export const submitWiresRequest = data => ({
  type: "SUBMIT_WIRES_REQUEST",
  payload: data
});

import React from "react";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

export const Step3 = ({handelSteps,selectDocType,value}) => {

    return (
        <div className="step1">
            <button className="back-btn"  onClick={() => handelSteps('Back')}><i className="fas fa-angle-left"></i></button>
            <div className="step-content">
                <h5>Select your document type</h5>
                <p className="red-line">Select a photo identification type to submit for verification. Please make sure the document that aligns with what your selected country offers.</p>
                <FormControl component="fieldset" className="radio-lists">
                    <RadioGroup aria-label="gender" name="gender1" value={value} onChange={(e) => selectDocType(e.target.value)}>
                        <FormControlLabel value="DL" control={<Radio />} label="Driver's Licence" />
                        <FormControlLabel value="PP" control={<Radio />} label="Passport" />
                        <FormControlLabel value="RP" control={<Radio />} label="Residence Permit" />
                        <FormControlLabel value="ID" control={<Radio />} label="Government issued Identity Card" />
                    </RadioGroup>
                </FormControl>
            </div>
            <button className="next-btn"  onClick={() => handelSteps('Next')}><i className="fas fa-angle-right"></i></button>
            <div className="current-step">
                <h3>03</h3>
                <span>/04</span>
            </div>
        </div>
    )
}

import Web3 from 'web3';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

import $ from "jquery";
import EventBus from "eventing-bus";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import Radium, {StyleRoot} from 'radium';
import { flipInX } from 'react-animations';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import { isSafari, isFirefox, deviceType, isBrowser, isMobile } from "react-device-detect";
import { RPCError } from 'magic-sdk';
import * as Sentry from '@sentry/browser';

import AppLoader from '../AppLoader'

import {
  loginRequest,
  signupRequest,
  logout,
  handleLoginWalletModal,
  verify2FAuth,
  verifySmsAuth,
  checkEmail
} from "../../store/actions/Auth";

import { resetExchange } from "../../store/actions/Exchange";
import { resetSettings,  overlayLoader } from "../../store/actions/Settings";
import { resetWallet } from "../../store/actions/Wallet";

import { web3, providerInject, setProvider, magic } from '../../store/web3';

const styles = {
  flipInX: {
    animation: 'x 2s',
    animationName: Radium.keyframes(flipInX, 'flipInX')
  }
}

const useStyles = makeStyles(theme=>({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  drawerItems:{
    paddingLeft: theme.spacing(2),
    color:'#808080',
    fontSize:'0.8rem'
  },
  drawerSubItems:{
    paddingLeft: theme.spacing(2),
    color:'#a4a4a4',
    fontSize:'0.8rem'
  },
  singleItem:{
    paddingLeft: theme.spacing(2),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  center:{
    justifyContent: 'center'
  }
}));

function Navbar(props) {
  const classes = useStyles();
  // const [metaFound, setMetaFound] = useState([]);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
    metaFound: false,
    isLoading: true,
    isLogging:false,
    invest:false,
    resource:false,
    selectedMethod: "login",
    toggleEmailLogin:true,
    isValidEmail:false
  });

  useEffect(() => {
    if (window.ethereum) setState({ ...state, metaFound: true });
    // setMetaFound(true);

    else setState({ ...state, metaFound: false });

    if ($(".main-header li.dropdown ul").length) {
      $(".main-header li.dropdown").append(
        '<div className="dropdown-btn"><span className="fa fa-angle-down"></span></div>'
      );
      $(".main-header li.dropdown .dropdown-btn").on("click", () =>
      $(this)
      .prev("ul")
      .slideToggle(500)
    );
  }

  EventBus.on("2faApi", data => {
    props.verify2FAuth({ data, history: props.history });
  });

  EventBus.on("ContinueMagicLogin", data => {
    console.log('ContinueMagicLogin data', data);
    magicRender('formatic', data)
  });

  EventBus.on("verifySmsAuth", data => {
    props.verifySmsAuth({ data, history: props.history });
  });

  ValidatorForm.addValidationRule('isEmail', (value) => {
    const isValid = (/\S+@\S+\.\S+/).test(value)
    return isValid
  })

}, []);


const handleLogout = () => {
  props.logout();
  props.resetExchange();
  props.resetSettings();
  props.resetWallet();
  setTimeout(() => window.location.reload(), 500);
}

const toggle = e => {
  const { handleLoginWalletModal, showLoginModal } = props;
  setState({ ...state, email:'' });
  handleLoginWalletModal(!showLoginModal);
};

const handleClick = async e => {
  localStorage.setItem('ETH-PROVIDER', e);
  if (e === "metamask") {
    await setProvider(e)
    // window.web3 = new Web3(window.ethereum);
    if (state.isLoading) {
      props.overlayLoader({status:true, loadingMessage:'Fetching Wallet Details.'})

      const isLoggedIn = await magic.user.isLoggedIn();
      if(isLoggedIn) await magic.user.logout();

      toggle();
      handleLogin(e);
    }
  } else setState({ ...state, toggleEmailLogin:false });
};

const magicRender = async (e, email) =>{
  props.overlayLoader({status:true, loadingMessage:'Fetching Wallet Details.'})
  setState({ ...state, email:email });

  if(await magic.user.isLoggedIn())
  await magic.user.logout();
  console.log('state.email', email);
  const req =  magic.auth.loginWithMagicLink({ email: email });
  req.on("email-sent", () => { console.log('email-sent') })
  .then(async DIDToken => {
    await setProvider('formatic');
    handleLogin('formatic');
  })
  .once("email-not-deliverable", () => {
    props.overlayLoader({status:false, loadingMessage:''})
    EventBus.publish("error", `Email Is Not Correct.`);
  })
  .catch(error => {
    props.overlayLoader({status:false, loadingMessage:''})
    EventBus.publish("error", error);
    if (error instanceof RPCError) {
      console.log('RPCError', RPCError.data);
      Sentry.captureMessage(RPCError.data + ' ' + ' magicError');
    }
  })
  .on("error", error => {
    props.overlayLoader({status:false, loadingMessage:''})
    EventBus.publish("error", error);
  });
}

const handleLogin = async (e) => {
  try{
    console.log('e is', e);
    props.handleLoginWalletModal(false);
    let web3Injected = ''
    if(e == 'metamask'){
      web3Injected = new Web3(window['ethereum']);
    }else{
      web3Injected = new Web3(magic['rpcProvider']);
    }
    let address = (await web3Injected.currentProvider.enable())[0];
    console.log('address', address);
    let netId = await web3Injected.eth.net.getId();
    console.log('netId', netId);

    if (netId == process['env']['REACT_APP_NETWORK_ID']) {
      props.loginRequest({
        data: address,
        history: props['history']
      });
      props.overlayLoader({status:true, loadingMessage:'Signing You in...'})
      localStorage.setItem('ETH-PROVIDER', e);
      localStorage.setItem('ETH-ID', address);
    }
    else {
      props.overlayLoader({status:false, loadingMessage:''})
      EventBus.publish("info", `Please switch to ${process['env']['REACT_APP_NETWORK']} network before login`);
    }
  }catch(e){
    console.log('login error', e);
    Sentry.captureMessage('login error', e);
    props.overlayLoader({status:false, loadingMessage:''});
    if (e === "User rejected provider access") alert("Login Request Rejected");
    else EventBus.publish("error", `Something went wrong. Please reload and try again!`);
    if (e instanceof RPCError) {
      console.log('RPCError', RPCError.data);
      Sentry.captureMessage('magicError', + ' ' + RPCError.data);
    }
  }
};

const handleLoginSignup = async e => {
  setState({ ...state, selectedMethod: "login" });
  props.handleLoginWalletModal(true);
};

const toggleDrawer = (side, open) => event => {
  if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift'))
  return;

  setState({ ...state, [side]: open });
};

const handleChange = (event) => {
  setState({ ...state, [event.target.name]:event.target.value });
};

const handleCancel = (event) => {
  setState({ ...state, toggleEmailLogin:true });
};

const handleNext = (event) => {
  if(state.email){
    props.checkEmail(state.email)
    // magicRender('formatic')
    // setState({ ...state, toggleEmailLogin:true });
  }else{
    setState({ ...state, isValidEmail:true });
  }

};

const sideList = side => (
  <div
    className={classes.list}
    style={{marginTop:'80px'}}
    role="presentation"
    onKeyDown={toggleDrawer(side, false)}
    onClick={toggleDrawer(side, false)}
    id="navbar-toggle-drawer"
    >
    <Divider />
    <List>

      <ListItem className={classes.center}>
        {!props.auth ||
          props.auth === undefined ||
          props.auth === null ||
          props.auth === "" ? (
            <div className="outer-box">
              <Link id="navbar-starter" to="/starter-packages">
                <button className="custom-btn">Get Started</button>
              </Link>
            </div>
          ) : (
            <div className="outer-box">
              <button id="navbar-drawer-logout" id="navbar-logout" onClick={() => handleLogout()} className="custom-btn">Logout</button>
            </div>
          )}
        </ListItem >

        <ListItem className={classes.center}>
          {!props.auth ||
            props.auth === undefined ||
            props.auth === null ||
            props.auth === "" ? (
              <div className="outer-box">
                <button id="navbar-drawer-login" onClick={() => handleLoginSignup("login")} className="custom-btn-disabled">Login</button>
              </div>
            ) : (
              <div className="outer-box">
                <Link id="navbar-drawer-dashboard" to="/dashboard">
                  <button className="custom-btn-disabled">Dashboard</button>
                </Link>
              </div>
            )}
          </ListItem >
          <Divider />

          <ListItem >
            <ListItemText>
              <Link id="navbar-drawer-home" to="/" className={classes.drawerItems}>Home</Link>
            </ListItemText>
          </ListItem>
          <ListItem >
            <ListItemText >
              <span className={classes.drawerItems}>Why Invest</span>
            </ListItemText>
          </ListItem>
          <Collapse in={!state.invest} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested}>
                <ListItemText>
                  <Link id="navbar-drawer-benefits" className={classes.drawerSubItems} to="/benefits">Benefits</Link>
                </ListItemText>
              </ListItem>
              <ListItem button className={classes.nested}>
                <ListItemText>
                  <Link id="navbar-drawer-technology" className={classes.drawerSubItems} to="/technology">Technology</Link>
                </ListItemText>
              </ListItem>
            </List>
          </Collapse>

          <ListItem >
            <ListItemText>
              <Link id="navbar-drawer-listings" className={classes.drawerItems} to="/listing">Listings</Link>
            </ListItemText>
          </ListItem>

          <ListItem >
            <ListItemText>
              <Link id="navbar-drawer-howItWorks" className={classes.drawerItems} to="/how-it-works">How It Works</Link>
            </ListItemText>
          </ListItem>

          <ListItem >
            <ListItemText>
              <span className={classes.drawerItems}>Resources</span>
            </ListItemText>
          </ListItem>

          <Collapse in={!state.resource} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested}>
                <ListItemText>
                  <Link id="navbar-drawer-investing101" className={classes.drawerSubItems} to="/investing101">Investing 101</Link>
                </ListItemText>
              </ListItem>

              <ListItem button className={classes.nested}>
                <ListItemText>
                  <Link id="navbar-drawer-aboutUs" className={classes.drawerSubItems} to="/about-us">About Us</Link>
                </ListItemText>
              </ListItem>
              <ListItem button className={classes.nested}>
                <ListItemText>
                  <Link className={classes.drawerSubItems} to="/events">Events & Media</Link>
                </ListItemText>
              </ListItem>
              <ListItem button className={classes.nested}>
                <ListItemText>
                  <Link id="navbar-drawer-helpCenter" className={classes.drawerSubItems} to="/help-center">Help Center - FAQ's</Link>
                </ListItemText>
              </ListItem>
              <ListItem button className={classes.nested}>
                <ListItemText>
                  <Link id="navbar-drawer-contactUs" className={classes.drawerSubItems} to="/contact-us">Contact us</Link>
                </ListItemText>
              </ListItem>
            </List>
          </Collapse>
        </List>
        <Divider />
      </div>
    );

    return (
      <div>
        <SwipeableDrawer
          className="main-header-drawer"
          anchor="right"
          open={state.right}
          onClose={toggleDrawer('right', false)}
          onOpen={toggleDrawer('right', true)}
          >
          {sideList('right')}
        </SwipeableDrawer>
        <header className="main-header header-style-one">
          <Modal
            modalClassName="login-modal"
            toggle={toggle}
            isOpen={props.showLoginModal}
            >
            <ModalBody>
              <div className="login-left">
                <img
                  src="https://tknismtest.s3.amazonaws.com/non-compressed-images/tokenism-text.png"
                  className="tokenism-text"
                  />
                <div className="left-banner">
                  <img
                    src="https://tknismtest.s3.amazonaws.com/non-compressed-images/whiteHalf.png"
                    className="login-logo"
                    />
                </div>
              </div>

              <div className="login-right">
                <p className="row right-title">
                  {state.selectedMethod === "login" ? "Login" : "Sign Up"}
                </p>

                <p className="row right-description">
                  All that you need to interact with our platform is an
                  E-Wallet
                </p>
                <p
                  className="row right-download"
                  style={
                    state.metaFound
                    ? { marginTop: "30px" }
                    : { background: "none" }
                  }
                  >
                  {
                    state.metaFound && state.selectedMethod === "login"
                    ? "Login via"
                    : !state.metaFound && state.selectedMethod && !isSafari === "login"
                    ? "Download Now"
                    : "Login via"
                  }
                </p>

                {
                  state.toggleEmailLogin
                  ?
                  <StyleRoot style={styles.flipInX}>

                    <div className="row donwload-container">

                      <div
                        onClick={() => handleClick("formatic")}
                        id="navbar-select-magic"
                        className="donwload-btn"
                        style={{ margin: "0px 10px 0px 0px" }}
                        >
                        <p className="icon-title-1">Using Email</p>
                        <img
                          src="https://tknismtest.s3.amazonaws.com/non-compressed-images/favicon.png.png"
                          className="donwload-icon"
                          />
                        <p className="icon-title">Wallet</p>
                      </div>

                      {!isSafari && !isMobile && !isFirefox?
                        <div
                          onClick={() => {
                            if (!state.metaFound)
                            window.location.href = "https://metamask.io/";
                            else handleClick("metamask");
                          }}
                          id="navbar-select-metamask"
                          className="donwload-btn"
                          style={{ margin: "0px 10px 0px 0px" }}
                          >
                          <p className="icon-title-1">Using E-Wallet</p>
                          <img
                            src="https://tknismtest.s3.amazonaws.com/non-compressed-images/meta-icon.png"
                            className="donwload-icon"
                            />
                          <p className="icon-title">MetaMask</p>
                        </div>
                        :''
                      }
                      {/*
                        {isMobile ?
                        <div className="donwload-btn"
                        style={{ margin: "0px 10px 0px 0px" }}
                        onClick={() => {
                        handleClickCoinbase("coinbase")
                        }}
                        >
                        <p className="icon-title-1">Through Mobile App</p>
                        <img
                        src="https://tknismtest.s3.amazonaws.com/non-compressed-images/coinbase-icon.png"
                        className="donwload-icon"
                        />
                        <p className="icon-title">Coinbase Wallet</p>
                        </div>
                        :null}
                        */}
                      </div>
                    </StyleRoot>

                    :
                    <div className="row donwload-container col-12 p-0 pt-3">

                      <ValidatorForm className="validatorForm w-100" onSubmit={handleNext}>
                        <div className="row">
                          <StyleRoot className="col-8">
                            <div className="pt-2" style={styles.flipInX}>
                              <TextValidator
                                id="navbar-magic-email"
                                className="MyTextField"
                                fullWidth
                                label="Email"
                                onChange={handleChange}
                                name="email"
                                type="email"
                                margin="dense"
                                variant="outlined"
                                validators={['required', 'isEmail']}
                                errorMessages={['Email can not be empty', 'Email is not valid']}
                                value={state.email}
                                />
                            </div>
                          </StyleRoot>
                          <StyleRoot className="col-4 mt-4">
                            <div style={styles.flipInX}>
                              <button id="navbar-magic-next" className="custom-btn mr-2"><i className="fa fa-chevron-right"></i></button>
                              <button id="navbar-magic-cancel" type="button" onClick={() => handleCancel()} className="custom-btn close-icon"> <i className="fa fa-close"></i></button>
                            </div>
                          </StyleRoot>
                        </div>

                      </ValidatorForm>

                    </div>

                  }

                  {state.selectedMethod === "login" ? (
                    <p className="have-acc">
                      Don't have an account?{" "}
                      <span className="login-span">
                        <Link className="login-span" id="navbar-noAccount" to='/starter-packages' onClick={toggle}>Sign up</Link>
                      </span>
                    </p>
                  ) : (
                    <p className="have-acc">
                    </p>
                  )}
                  <div className="login-modal-footer">
                    <div>
                      <img
                        src="https://tknismtest.s3.amazonaws.com/non-compressed-images/secure.png"
                        className="secure-icon"
                        />
                    </div>
                    <div className="secure-content">
                      <p className="secure-title">Non-custodial & Secure</p>
                      <p className="secure-desc">
                        We do not own your private keys and cannot access your funds
                      </p>
                    </div>
                  </div>
                </div>

                {/* <div
                  className="modal-footer row footerOptions"
                  style={{ justifyContent: "center !important" }}
                  >
                  <div className="invest-btn-area col-col">
                  {state.metaFound ? (
                  <a
                  onClick={() => {
                  state.metaFound
                  ? this.handleClick("metamask")
                  : console.log("disabled");
                  }}
                  href="#"
                  className="thm-btn btn-style-modal"
                  style={
                  this.state.metaFound
                  ? {}
                  : { cursor: "not-allowed", background: "lightgray" }
                  }
                  >
                  MetaMask
                  </a>
                  ) : null}
                  </div>
                  </div> */}
                </ModalBody>
              </Modal>
              <Modal
                modalClassName="loader-modal"
                toggle={toggle}
                isOpen={state.isLogging}
                >
                <ModalBody>
                  <div className="loader-center">
                    <AppLoader />
                  </div>

                </ModalBody>
              </Modal>

              <div className="header-lower">
                <div className="container">
                  <div className="main-box clearfix">
                    <div className="logo-box pull-left">
                      <div className="logo">
                        {props.hideNav ? (
                          <Link to="/">
                            <img
                              src="https://tknismtest.s3.amazonaws.com/non-compressed-images/logo.png"
                              alt=""
                              title=""
                              />
                          </Link>
                        ) : (
                          <Link to="/">
                            <img
                              src="https://tknismtest.s3.amazonaws.com/non-compressed-images/logo.png"
                              alt=""
                              title=""
                              />
                          </Link>
                        )}
                      </div>
                    </div>
                    {props.hideNav ? null : (
                      <div
                        className="nav-outer clearfix pull-right"
                        style={{ display: "flex", justifyContent: "flex-end" }}
                        >
                        <nav
                          className="main-menu navbar-expand-md "
                          // style={{ marginRight: "75px" }}
                          >

                          <div className="navbar-header">
                            <button
                              className="navbar-toggler"
                              type="button"
                              id="navbar-drawer-toggle"
                              onClick={toggleDrawer('right', !state.right)}
                              >
                              <span className="fa fa-bars" aria-hidden="true"></span>
                            </button>
                          </div>

                          <div
                            className="collapse navbar-collapse clearfix"
                            id="navbarSupportedContent"
                            >
                            <ul className="navigation clearfix">
                              <li className={props.page === "home" ? "current" : ""}>
                                <Link to="/">Home</Link>
                              </li>
                              <li
                                className={
                                  props.page === "benefits" ||
                                  props.page === "reviews-and-ratings" ||
                                  props.page === "technology"
                                  ? "current dropdown"
                                  : "dropdown"
                                }
                                >
                                <Link to="#">Why Invest</Link>
                                <ul>
                                  <li>
                                    <Link id="navbar-header-benefits" to="/benefits">Benefits</Link>
                                  </li>
                                  <li>
                                    <Link id="navbar-header-technology" to="/technology">Technology</Link>
                                  </li>
                                </ul>
                              </li>
                              <li className={props.page === "listing" ? "current" : ""}>
                                <Link id="navbar-header-listing" to="/listing">Listings</Link>
                              </li>
                              <li
                                className={props.page === "how-it-works" ? "current" : ""}
                                >
                                <Link id="navbar-header-howItWorks" to="/how-it-works">How It Works</Link>
                              </li>
                              <li
                                className={
                                  props.page === "investing101" ||
                                  props.page === "about-us" ||
                                  props.page === "help-center"
                                  ? "current dropdown"
                                  : "dropdown"
                                }
                                >
                                <Link to="#">Resources</Link>
                                <ul>
                                  <li>
                                    <Link id="navbar-header-investing101" to="/investing101">Investing 101</Link>
                                  </li>
                                  <li>
                                    <Link id="navbar-header-aboutUs" to="/about-us">About Us</Link>
                                  </li>
                                  <li>
                                    <Link to="/events">Events & Media</Link>
                                  </li>
                                  <li>
                                    <Link id="navbar-header-helpCenter" to="/help-center">Help Center - FAQ's</Link>
                                  </li>

                                  <li><Link id="navbar-header-contactUs" to="/contact-us">Contact us</Link></li>
                                </ul>
                              </li>
                            </ul>
                          </div>

                        </nav>
                        {!props.auth ||
                          props.auth === undefined ||
                          props.auth === null ||
                          props.auth === "" ? (
                            <div className="collapseButtons outer-box">
                              <Link
                                onClick={() => handleLoginSignup("login")}
                                id="navbar-header-login"
                                to="#"
                                className="thm-btn btn-style-one mr-1"
                                >
                                Login
                              </Link>
                              <Link
                                to="/starter-packages"
                                className="thm-btn btn-style-one"
                                id="navbar-header-starter"
                                >
                                Get Started
                              </Link>
                            </div>
                          ) : (
                            <div className="collapseButtons outer-box">
                              <Link
                                to="/dashboard"
                                id="navbar-header-dashboard"
                                className="thm-btn btn-style-one mr-1"
                                >
                                Dashboard
                              </Link>
                              <Link
                                onClick={() => handleLogout()}
                                id="navbar-header-logout"
                                to="#"
                                className="thm-btn btn-style-one"
                                >
                                Logout
                              </Link>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {props.hideNav ? null : (
                  <div className="sticky-header" hidden={props.stickyNavbar}>
                    <div className="container">
                      <div className="main-box clearfix">
                        <div className="logo pull-left">
                          <Link to="/">
                            <img
                              src="https://tknismtest.s3.amazonaws.com/non-compressed-images/logo-small.png"
                              alt=""
                              title=""
                              />
                          </Link>
                        </div>
                        <div className="pull-right">
                          <nav
                            className="main-menu navbar-expand-md "
                            // style={{ marginRight: "75px" }}
                            >
                            <div className="navbar-header">
                              <button
                                style={{ marginTop: "9px", marginRight: "-9%" }}
                                className="navbar-toggler"
                                type="button"
                                id="navbar-sticky-toggle-drawer"
                                onClick={toggleDrawer('right', !state.right)}
                                >
                                <span className="fa fa-bars" aria-hidden="true"></span>
                              </button>
                            </div>
                            <div
                              className="collapse navbar-collapse clearfix"
                              id="navbarSupportedContent"
                              >
                              <ul className="navigation clearfix">
                                <li className={props.page === "home" ? "current" : ""}>
                                  <Link to="/">Home</Link>
                                </li>
                                <li
                                  className={
                                    props.page === "benefits" ||
                                    props.page === "reviews-and-ratings" ||
                                    props.page === "technology"
                                    ? "current dropdown"
                                    : "dropdown"
                                  }
                                  >
                                  <Link to="#">Why Invest</Link>
                                  <ul>
                                    <li>
                                      <Link id="navbar-sticky-benefits" to="/benefits">Benefits</Link>
                                    </li>
                                    <li>
                                      <Link id="navbar-sticky-technology" to="/technology">Technology</Link>
                                    </li>
                                  </ul>
                                </li>
                                <li className={props.page === "listing" ? "current" : ""}>
                                  <Link id="navbar-sticky-listing" to="/listing">Listings</Link>
                                </li>
                                <li
                                  className={props.page === "how-it-works" ? "current" : ""}
                                  >
                                  <Link id="navbar-sticky-howItWorks" to="/how-it-works">How It Works</Link>
                                </li>
                                <li
                                  className={
                                    props.page === "investing101" ||
                                    props.page === "about-us" ||
                                    props.page === "help-center"
                                    ? "current dropdown"
                                    : "dropdown"
                                  }
                                  >
                                  <Link to="#">Resources</Link>
                                  <ul>
                                    <li>
                                      <Link id="navbar-sticky-investing101" to="/investing101">Investing 101</Link>
                                    </li>
                                    <li>
                                      <Link id="navbar-sticky-aboutUs" to="/about-us">About Us</Link>
                                    </li>
                                    <li>
                                      <Link to="/events">Events & Media</Link>
                                    </li>
                                    <li>
                                      <Link id="navbar-sticky-helpCenter" to="/help-center">Help Center - FAQ's</Link>
                                    </li>

                                    <li><Link id="navbar-sticky-contactUs" to="/contact-us">Contact us</Link></li>
                                  </ul>
                                </li>
                              </ul>
                            </div>
                          </nav>
                          {!props.auth ||
                            props.auth === undefined ||
                            props.auth === null ||
                            props.auth === "" ? (
                              <div className="outer-box">
                                <Link
                                  onClick={() => handleLoginSignup("login")}
                                  id="navbar-header-sticky-login"
                                  to=""
                                  className="thm-btn btn-style-one mr-1"
                                  >
                                  Login
                                </Link>
                                <Link
                                  id="navbar-header-sticky-starter"
                                  to="/starter-packages"
                                  className="thm-btn btn-style-one"
                                  >
                                  Get Started
                                </Link>
                              </div>
                            ) : (
                              <div className="outer-box">
                                <Link
                                  id="navbar-header-sticky-dashboard"
                                  to="/dashboard"
                                  className="thm-btn btn-style-one mr-1"
                                  >
                                  Dashboard
                                </Link>
                                <Link
                                  onClick={() => handleLogout()}
                                  id="navbar-header-sticky-logout"
                                  to="#"
                                  className="thm-btn btn-style-one"
                                  >
                                  Logout
                                </Link>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </header>
              </div>
            );
          }

          const mapStateToProps = state => {
            return {
              auth: state.Auth.auth,
              showLoginModal: state.Auth.showLoginModal
            };
          };

          const mapDispatchToProps = {
            loginRequest,
            signupRequest,
            verify2FAuth,
            logout,
            handleLoginWalletModal,
            verifySmsAuth,
            resetExchange,
            resetSettings,
            resetWallet,
            overlayLoader,
            checkEmail
          };
          export default connect(mapStateToProps, mapDispatchToProps)(Navbar);

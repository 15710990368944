import React, { Component } from 'react';

class ListingBanner extends Component{
  state = {
    count : 0
  }

  navigateToListing=(activeNo)=>{
    this.setState({count:activeNo})
  }

  render()
  {
    const { count } = this.state
    return (
      <div className="listing-top">
        <div className="row clearfix">
          <div className="col-4" onClick = {()=> {this.props.handleStagePress("Stabilized Income");this.navigateToListing(1)}}>
            <div className={`listing-box ${count == 1 ? ' active' : ""}  `}>
              <div className="image-box">
                <figure>
                  <div className="upperLine"></div>
                  {/* <LazyLoad once > */}
                    <img className="listingBannerImage" src="https://tknismtest.s3.amazonaws.com/webImages/2.jpg" alt="" />
                  {/* </LazyLoad> */}
                  <div className="content-area">
                    <h3>Stabilized Income</h3>
                  </div>
                </figure>
              </div>
            </div>
          </div>
          <div className="col-4" onClick = {()=> {this.props.handleStagePress("Re Developed Projects");this.navigateToListing(2)}}>
            <div className={`listing-box ${count == 2 ? ' active' : ""}  `} >
              <div className="image-box">
                <figure>
                  <div className="upperLine"></div>
                  {/* <LazyLoad once > */}
                    <img className="listingBannerImage" src="https://tknismtest.s3.amazonaws.com/webImages/3.jpg" alt="" />
                  {/* </LazyLoad> */}
                  <div className="content-area">
                    <h3>Re Developed Projects</h3>
                  </div>
                </figure>
              </div>
            </div>
          </div>
          <div className="col-4" onClick = {()=> {this.props.handleStagePress("Ground Up Development");this.navigateToListing(3);}}>
            <div className={`listing-box ${count == 3 ? ' active' : ""}  `} >
              <div className="image-box">
                <figure>
                  <div className="upperLine"></div>
                  {/* <LazyLoad once > */}
                    <img className="listingBannerImage" src="https://tknismtest.s3.amazonaws.com/webImages/4.jpg" alt="" />
                  {/* </LazyLoad> */}
                  <div className="content-area">
                    <h3>Ground Up Development</h3>
                  </div>
                </figure>
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ListingBanner;

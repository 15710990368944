import { connect } from "react-redux";
import React, { Component } from 'react';

import BankAccount from './BankAccount';
import Main from './Main';
import Wires from './Wires';
import { userDashHeader, userDashChart } from "../../../../store/actions/Auth";

class Withdrawal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedPaymentMethod: "none",
      renderPaymentComponent: ''
    }
    props.userDashHeader();
  }

  selectMethod = (e) => {
    if (e == "none")
      this.setState({ selectedPaymentMethod: e });
    else if (e == "bankAccount")
      this.setState({ renderPaymentComponent: <BankAccount onSelectMethod={(value) => this.selectMethod(value)} />, selectedPaymentMethod: e })
  }

  selectMethod = (e)=>
  {
    if (e == "none")
    this.setState({ selectedPaymentMethod: e });
    else if (e == "bankAccount")
    this.setState({ renderPaymentComponent: <BankAccount onSelectMethod={(value) => this.selectMethod(value)} />, selectedPaymentMethod:e })
    else if (e == "wires")
    this.setState({ renderPaymentComponent: <Wires onSelectMethod={(value) => this.selectMethod(value)}  />, selectedPaymentMethod:e })
  }


  render()
  {
    const {selectedPaymentMethod, renderPaymentComponent} = this.state;
    return (
      selectedPaymentMethod === "none"
      ? <Main onSelectMethod={(value)=> this.selectMethod(value)}/>
    : renderPaymentComponent
  );
}
}

const mapDispatchToProps = { userDashHeader };

const mapStateToProps = (state) => {
  return { dashHeadData: state.Auth.dashHeadData || {}, wires: state.Wallet.wires}
}
export default connect(mapStateToProps, mapDispatchToProps)(Withdrawal);

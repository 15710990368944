import { connect } from "react-redux";
import React, { Component } from 'react';

import Main from './Main';
import Paypal from './Paypal';
import Wires from './Wires';
import DebitCard from './DebitCard';
import BankAccount from './BankAccount';
import { userDashHeader, userDashChart } from "../../../../store/actions/Auth";

class Deposit extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {
      selectedPaymentMethod: "none",
      renderPaymentComponent: ''
    }
    props.userDashHeader();
    props.userDashChart();
  }

  selectMethod = (e)=>
  {
    if (e == "none")
    this.setState({ selectedPaymentMethod: e });
    else if (e == "debitCard")
    this.setState({ renderPaymentComponent: <DebitCard onSelectMethod={(value) => this.selectMethod(value)} />, selectedPaymentMethod:e })
    else if (e == "bankAccount")
    this.setState({ renderPaymentComponent: <BankAccount onSelectMethod={(value) => this.selectMethod(value)} />, selectedPaymentMethod:e })
    else if (e == "paypal")
    this.setState({ renderPaymentComponent: <Paypal onSelectMethod={(value) => this.selectMethod(value)}  />, selectedPaymentMethod:e })
    else if (e == "wires")
    this.setState({ renderPaymentComponent: <Wires onSelectMethod={(value) => this.selectMethod(value)}  />, selectedPaymentMethod:e })
  }

  render()
  {
    const {selectedPaymentMethod, renderPaymentComponent} = this.state;
    return (
      selectedPaymentMethod === "none"
      ? <Main onSelectMethod={(value)=> this.selectMethod(value)}/>
    : renderPaymentComponent
  );
}
}

// const mapDispatchToProps = { userDashboard };

// const mapStateToProps = (state) => {
//     return { dashData: state.Auth.dashData || {} }
// }

const mapDispatchToProps = { userDashHeader, userDashChart };

// const mapStateToProps = state => {
//   return { userBanks: state.Wallet.userBanks || [], dashData: state.Auth.dashData || {} };
// };
export default connect(null, mapDispatchToProps)(Deposit);

import React, { useEffect, useState } from "react";
import CheckBox from './../../../../components/CheckBox/CheckBox';
import SelectInput from './../../../../components/SelectInput/SelectInput'
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import { connect } from 'react-redux';
import { addInvestorPreferences, overlayLoader } from '../../../../store/actions/Settings';
import Chip from '@material-ui/core/Chip';

const Property_types = ['Hospitality','Industrial','Warehouse','Retail','Mixed Use','Multifamily','Office']
const regions = ['Western','Southern','Southwest','Southeast','Northwest','Northeast','Midwest','My State','US']
const markets = ['Primary','Secondary','Tertiary']
const objectives = ['Aggressive','Growth','Income']


function InvestorPreferences(props) {

// const InvestorPreferences = ({savePref}) => {
let [propertType, setPropertType] = React.useState({
  "Hospitality": false,
  "Industrial": false,
  "Warehouse": false,
  "Mixed Use": false,
  "Multifamily": false,
  "Office":false
});

let [region, setRegion] = React.useState({
  "Western": false,
  "Southern": false,
  "Southwest": false,
  "Southeast": false,
  "Northwest": false,
  "Northeast":false,
  "Midwest":false,
  "My State":false,
  "US":false
});

let [market, setMarket] = React.useState({
  "Primary": false,
  "Secondary": false,
  "Tertiary": false,
});

let [objective, setObjective] = React.useState({
  "Aggressive": false,
  "Growth": false,
  "Income": false,
});
  // const [propertType, setPropertType] = React.useState([]);
  // const [region, setRegion] = React.useState([]);
  // const [market, setMarket] = React.useState([]);
  // const [objective, setObjective] = React.useState([]);
  const [totalInvestment, setTotalInvestment] = React.useState('Undecided');
  const [risk, setRisk] = React.useState('Undecided');
  const [amountInvestment, setAmountInvestment] = React.useState('Undecided');
  const [investorsPreferences, setInvestorPreferences] = React.useState([]);

  const [type, setType] = React.useState([]);
  const [markt, setMarkt] = React.useState([]);
  const [object, setObject] = React.useState([]);
  const [reg, setReg] = React.useState([]);

  useEffect(() => {
    if(props.preferences){
      if(props.preferences.property){
        setType(props.preferences.property.interests.type);
        setMarkt(props.preferences.property.interests.marketSize);
        setObject(props.preferences.property.interests.objectives);
        setReg(props.preferences.property.interests.region);
        setInvestorPreferences(props.preferences.property);
        props.preferences.property.interests.type.map(x=>{
          Property_types.map(i=>{
            if(i == x ) propertType[x] = true
          })
        })

        props.preferences.property.interests.region.map(x=>{
          regions.map(i=>{
            if(i == x ) region[x] = true
          })
        })
        props.preferences.property.interests.marketSize.map(x=>{
          markets.map(i=>{
            if(i == x ) market[x] = true
          })
        })
        props.preferences.property.interests.objectives.map(x=>{
          objectives.map(i=>{
            if(i == x ) objective[x] = true
          })
        })
        // setRegion([...props.preferences.property.interests.region])
        // setMarket([...props.preferences.property.interests.marketSize])
        // setObjective([...props.preferences.property.interests.objectives])
        if(props.preferences.property.investments){
          setTotalInvestment(props.preferences.property.investments.perYear)
          setAmountInvestment(props.preferences.property.investments.perProject);
          setRisk(props.preferences.property.investments.riskTolerance);
        }
      }
    }
  }, []);

  const handleRegion = (event) => {
    setRegion(event.target.value);
  };
  const handleMarket = (event) => {
    setMarket(event.target.value);
  };
  const handleObjective = (event) => {
    setObjective(event.target.value);
  };

  const saveInvestorPref = () => {
    props.overlayLoader({status:true, loadingMessage:'Saving your Investor Preferences.'})
    let marketChecked= []
    let objectiveChecked= []
    let regionChecked= []
    let propertTypeChecked= []
      Object.entries(market).map((value,index)=>{
        if(value[1] == true) marketChecked.push(value[0])
      })
      Object.entries(objective).map((value,index)=>{
        if(value[1] == true) objectiveChecked.push(value[0])
      })
      Object.entries(region).map((value,index)=>{
        if(value[1] == true) regionChecked.push(value[0])
      })
      Object.entries(propertType).map((value,index)=>{
        if(value[1] == true) propertTypeChecked.push(value[0])
      })
        let formData = {
          "type": propertTypeChecked,
          "region": regionChecked,
          "marketSize" : marketChecked,
          "objectives" : objectiveChecked,
          "perYear": totalInvestment,
          "perProject": amountInvestment,
          "riskTolerance": risk
        }
        props.addInvestorPreferences(formData)
        props.savePref()
  }

  // const handelNotification = (event) => {
  //   setMailNotification({...mailNotification,[event.target.name]:event.target.checked})
  // }


  const handleProperty = (event) => {
    setPropertType(event.target.value);
  };

  const handelCheckProperty = (event) => {
    let oldpropertType = propertType
    oldpropertType[event.target.name] = event.target.checked
    setPropertType({...propertType,[event.target.name]:event.target.checked})
  }

  const handelCheckRegion = (event) => {
    let oldRegion = region
    oldRegion[event.target.name] = event.target.checked
    setRegion({...region,[event.target.name]:event.target.checked})
    // setRegion([...region,event.target.name])
  }
  const handelCheckMarket = (event) => {
    let oldMarket = market
    oldMarket[event.target.name] = event.target.checked
    setMarket({...market,[event.target.name]:event.target.checked})
    // setMarket([...market,event.target.name])
  }
  const handelCheckObjective = (event) => {
    let oldObjective = objective
    oldObjective[event.target.name] = event.target.checked
    setObjective({...objective,[event.target.name]:event.target.checked})
    // setObjective([...objective,event.target.name])
  }


  const handleTotalInvest = (event) => {
    setTotalInvestment(event.target.value);
  };
  const handleAmountInvest = (event) => {
    setAmountInvestment(event.target.value);
  };
  const handleRisk = (event) => {
    setRisk(event.target.value);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const savePreferences =(e)=>{
  // props.addInvestorPreferences()
  }

  const handleChangeType = (event) => {
    setType(event.target.value);
  };
  const handleChangeMarket = (event) => {
    setMarkt(event.target.value);
  };
  const handleChangeObjective = (event) => {
    setObject(event.target.value);
  };
  const handleChangeRegion = (event) => {
    setReg(event.target.value);
  };

  return (
    <div className="investor-perfrences">
        <h5>Investor Preferences</h5>
        <div className="preferences">
                <div className="investor-objectives mobo-interests">
                  <FormControl fullWidth >
                    <InputLabel id="demo-mutiple-checkbox-label">Property Type</InputLabel>
                    <Select
                      labelId="demo-mutiple-checkbox-label"
                      id="demo-mutiple-checkbox"
                      multiple
                      input={<Input />}
                      MenuProps={MenuProps}
                      value={type}
                      onChange={handleChangeType}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <em>Placeholder</em>;
                          }
                          return selected.join(', ');
                        }}
                    >
                    {
                      Object.entries(propertType).map((value,index)=>(
                        <MenuItem key={index} value={value[0]}>
                          <CheckBox key={index} name={value[0]} checked={value[1]} handelCheckBox={handelCheckProperty}/>
                        </MenuItem>
                      ))
                    }
                    </Select>
                  </FormControl>
                  <FormControl fullWidth >
                    <InputLabel id="demo-mutiple-checkbox-label">Region</InputLabel>
                    <Select
                      labelId="demo-mutiple-checkbox-label"
                      id="demo-mutiple-checkbox"
                      multiple
                      input={<Input />}
                      MenuProps={MenuProps}
                      value={reg}
                      onChange={handleChangeRegion}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <em>Placeholder</em>;
                          }
                          return selected.join(', ');
                        }}
                    >
                    {
                      Object.entries(region).map((value,index)=>(
                        <MenuItem key={index} value={value[0]}>
                          <CheckBox key={index} name={value[0]} checked={value[1]} handelCheckBox={handelCheckRegion}/>
                        </MenuItem>
                      ))
                    }
                    </Select>
                  </FormControl>
                  <FormControl fullWidth >
                    <InputLabel id="demo-mutiple-checkbox-label">Market</InputLabel>
                    <Select
                      labelId="demo-mutiple-checkbox-label"
                      id="demo-mutiple-checkbox"
                      multiple
                      input={<Input />}
                      MenuProps={MenuProps}
                      value={markt}
                      onChange={handleChangeMarket}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <em>Placeholder</em>;
                          }
                          return selected.join(', ');
                        }}
                    >
                    {
                      Object.entries(market).map((value,index)=>(
                        <MenuItem key={index} value={value[0]}>
                          <CheckBox key={index} name={value[0]} checked={value[1]} handelCheckBox={handelCheckMarket}/>
                        </MenuItem>
                      ))
                    }
                    </Select>
                  </FormControl>
                  <FormControl fullWidth >
                    <InputLabel id="demo-mutiple-checkbox-label">Objectives</InputLabel>
                    <Select
                      labelId="demo-mutiple-checkbox-label"
                      id="demo-mutiple-checkbox"
                      multiple
                      input={<Input />}
                      MenuProps={MenuProps}
                      value={object}
                      onChange={handleChangeObjective}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <em>Placeholder</em>;
                          }
                          return selected.join(', ');
                        }}
                    >
                    {
                      Object.entries(objective).map((value,index)=>(
                        <MenuItem key={index} value={value[0]}>
                          <CheckBox key={index} name={value[0]} checked={value[1]} handelCheckBox={handelCheckObjective}/>
                        </MenuItem>
                      ))
                    }
                    </Select>
                  </FormControl>

                </div>
                <div className="investor-interests">
                      <div className="interests">
                        <h5 className="title">Property type</h5>

                        {/*
                          {Property_types.map( name => {
                            return <CheckBox name={name} handelCheckBox={handelCheckProperty}  />
                          })}
                        */}
                        {
                          Object.entries(propertType).map((value,index)=>{
                            return <CheckBox key={index} name={value[0]} checked={value[1]} handelCheckBox={handelCheckProperty} />
                          })
                        }
                      </div>
                      <div className="interests">
                        <h5 className="title">Region</h5>
                        {/*
                        {regions.map( name => {
                          return <CheckBox name={name} handelCheckBox={handelCheckRegion} />
                        })}
                        */}

                        {
                          Object.entries(region).map((value,index)=>{
                            return <CheckBox key={index} name={value[0]} checked={value[1]} handelCheckBox={handelCheckRegion} />
                          })
                        }
                      </div>
                          <div className="interests">
                            <h5 className="title">Market size</h5>
                            {/*
                            {markets.map( name => {
                              return <CheckBox name={name} handelCheckBox={handelCheckMarket} />
                            })}
                            */}

                            {
                              Object.entries(market).map((value,index)=>{
                                return <CheckBox key={index} name={value[0]} checked={value[1]} handelCheckBox={handelCheckMarket} />
                              })
                            }
                          </div>
                          <div className="interests">
                            <h5 className="title">Objectives</h5>
                            {/*
                            {objectives.map( name => {
                              return <CheckBox name={name} handelCheckBox={handelCheckObjective} />
                            })}
                            */}

                            {
                              Object.entries(objective).map((value,index)=>{
                                return <CheckBox key={index} name={value[0]} checked={value[1]} handelCheckBox={handelCheckObjective} />
                              })
                            }
                          </div>
                    </div>
                <div className="investor-objectives">
                  <SelectInput onChange name="totalInvestment" value={totalInvestment} handleChange={handleTotalInvest} label="Expected Total Investment in Next Twelve Months" values={['Less than $5,000','$5,000 - $49,999','$50,000 - $99,999','$100,000 - $499,999','$500,000+']} />
                  <SelectInput name="amountInvestment" value={amountInvestment} handleChange={handleAmountInvest} label="Expected Investment Amount Per Project" values={['Less than $5,000','$5,000 - $49,999','$50,000 - $99,999','$100,000 - $499,999','$500,000+']} />
                  <SelectInput name="risk" label="Risk Tolerance" value={risk} handleChange={handleRisk} values={['Undecided','Risk Averse','Moderate','Risk Tolerant']} />
                </div>
                <div className="d-flex justify-content-end">
                  <button className="custom-btn" type="button" onClick={() => saveInvestorPref()}>Save</button>
                  <button className="custom-btn" type="button" onClick={() => props.savePref()}>Cancel</button>
                </div>
        </div>
    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    preferences: state.Settings.preferences || {},
  }
  // return {svgArr: state.svgArr}
}
const mapDispatchToProps= {addInvestorPreferences, overlayLoader};

export default connect(mapStateToProps, mapDispatchToProps)(InvestorPreferences)

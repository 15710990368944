import React, { Component } from 'react'
import { faqData } from './../../DataFiles/FaqData'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import Banner from '../../components/Banner'
import { Link } from 'react-router-dom'

class HelpCenter extends Component
{
    constructor()
    {
        super()
        this.state = {
            expandedTab: 0,
            expanded: false,
            activeCategory:'aboutTokenism',
            activeIndex:0,
            searchText:''
        }
        window.scrollTo(0, 0);
    }

    toggle = (e)=> this.setState({ expandedTab: e, expanded: !this.state.expanded })
     
    handelFaqData = (activeCategory, activeIndex) => this.setState({activeCategory, activeIndex})
    
    handelFaqSearch = (searchText) => this.setState({searchText})

    render()
    {
        const { handelFaqData, handelFaqSearch } = this
        const { activeCategory, activeIndex } = this.state

        let data = [...faqData]
        data = data[activeIndex][activeCategory].filter( item => {
            return (
                item.question.toLowerCase().indexOf(this.state.searchText.toLowerCase()) !== -1
            )
       } )

        return (
            <div className="page-wrapper">
                <Navbar
                    page='help-center'
                    {...this.props}
                />
                <Banner
                    heading='Help Center'
                    subHeading='Lorem ipsum dolor sit amet, consectetur adipisicing elit do eiusmod tempor consectetur'
                />
                <div className="faqs-sec">
                    <div className="container">
                        <div className="sec-title pt-3 text-center">
                            <h2 data-animation-child className="title-fill" data-animation="title-fill-anim" data-text="About Company">Frequently Asked Questions</h2>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item">
                                        <button className={`custom-btn ${ activeCategory == 'aboutTokenism' ? 'active' : ''} `} onClick={() => handelFaqData('aboutTokenism', 0)} >ABOUT TOKENISM</button>
                                    </li>
                                    <li className="nav-item">
                                        <button className={`custom-btn ${ activeCategory == 'investingBasics' ? 'active' : ''} `} onClick={() => handelFaqData('investingBasics', 1)}>INVESTING BASICS</button>
                                    </li>
                                    <li className="nav-item">
                                        <button className={`custom-btn ${ activeCategory == 'accountManagement' ? 'active' : ''} `} onClick={() => handelFaqData('accountManagement', 2)}>ACCOUNT MANAGEMENT</button>
                                    </li>
                                    <li className="nav-item">
                                        <button className={`custom-btn ${ activeCategory == 'assetOwners' ? 'active' : ''} `} onClick={() => handelFaqData('assetOwners', 3)}>ASSET OWNERS</button>
                                    </li>
                                    <li className="nav-item">
                                        <button className={`custom-btn ${ activeCategory == 'security' ? 'active' : ''} `} onClick={() => handelFaqData('security', 4)}>SECURITY</button>
                                    </li>
                                </ul>
                                <div className="search-box">
                                    <form className="search-form">
                                            <input type="text" onChange={(e) => handelFaqSearch(e.target.value)} placeholder="Enter keywords here..." name="keyword" />
                                            <button className="custom-btn" type='button'> Search</button>
                                    </form>
                                </div>
                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="home-tab">
                                        <ul className="accordion-box">
                                            {data.map( (item , index ) => {
                                                return (
                                                    <li className="accordion block">
                                                        <div className={this.state.expandedTab == index ? "acc-btn active" : "acc-btn"} onClick={() => this.toggle(index)}>
                                                            <div className="icon-outer">
                                                                <i className="icon icon_right fa fa-plus" aria-hidden="true"></i>
                                                                <i className="icon icon_down fa fa-minus" aria-hidden="true"></i>
                                                            </div>
                                                            {
                                                                item.question
                                                            }
                                                        </div>
                                                        <div className={this.state.expandedTab == index ? "acc-content current" : "acc-content"}>
                                                            <div className="content">
                                                            <div className="text" dangerouslySetInnerHTML={ { __html: item.answer } } />
                                                            </div>
                                                        </div>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                               
                                </div>
                                <p className="bottom-subtitle">Don’t see your question? Ask <Link to='/contact-us'>Here</Link> and we’ll get back to you!</p>
                            </div>
                        </div>
                    </div>
                </div>

                 <Footer/>
            </div>
        );
    }
}

export default HelpCenter;

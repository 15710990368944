import $ from "jquery";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import React, { Component, Fragment } from "react";
import { getProfile, getInvestorPreferences, getAllNotifications, viewSingleNotification } from "../../store/actions/Settings";
import { Redirect } from 'react-router';
import {
  logout,
  showIframeFn,
  submitAccredation,
  userDashHeader,
  userDashChart,
  handelUserMessage
} from "../../store/actions/Auth";
import { resetExchange } from "../../store/actions/Exchange";
import { resetSettings } from "../../store/actions/Settings";
import { resetWallet } from "../../store/actions/Wallet";
import * as typeformEmbed from "@typeform/embed";
// import "./dashHead.css";
import EventBus from 'eventing-bus'
import Box from '@material-ui/core/Box';
import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import moment from 'moment';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import {isMobile, isTablet} from 'react-device-detect';
import Loader from "./../AppLoader";

const classes = {
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  drawerItems: {
    paddingLeft: 20,
    color: '#808080',
    fontSize: '0.8rem'
  },
  drawerSubItems: {
    paddingLeft: 20,
    color: '#a4a4a4',
    fontSize: '0.8rem'
  },
  singleItem: {
    paddingLeft: 20,
  },
  nested: {
    paddingLeft: 40,
  },
  center: {
    justifyContent: 'center'
  }
}


class DashHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showGAModal: false,
      loading: true,
      redirect: false,
      dwollaVerification: true,
      invest: false,
      resource: false,
      top: false,
      left: false,
      bottom: false,
      right: false,
    };
    if (!props.onlyNav) {
      props.getProfile();
      props.userDashHeader();
      props.userDashChart();
      props.getInvestorPreferences()
    }
    props.getAllNotifications();
  }

  sellProperty = () => {
    setTimeout(()=> {
      typeformEmbed.makePopup(
        // 'https://admin.typeform.com/to/IoM1zM',
        "https://admin.typeform.com/to/u9WlbK/",
        {
          autoOpen: true,
          autoClose: 5,
          hideScrollbars: true,
          onSubmit: () => this.props.submitProperty()
        }
      );
    }, 3000);

  };

  componentDidMount() {
    if ($(".main-header li.dropdown ul").length) {
      $(".main-header li.dropdown").append(
        '<div class="dropdown-btn"><span class="fa fa-angle-down"></span></div>'
      );
      $(".main-header li.dropdown .dropdown-btn").on("click", () =>
        $(this)
          .prev("ul")
          .slideToggle(500)
      );
    }
    EventBus.on("toProfile", () => this.setState({ redirect: true, }));
    EventBus.on("profileUpdated", () => this.setState({ redirect: true, dwollaVerification: false }));

  }
  setActive = () => {
  }

  accredationSubmit = status => {
    this.props.showIframeFn(true);
    if (status === "In Complete") this.props.submitAccredation();
  };

  kyc = () => {
    setTimeout(() => {
      this.props.history.push("/kyc");
    }, 500);
  }

  handleLogout = () => {
    this.props.logout();
    this.props.resetExchange();
    this.props.resetSettings();
    this.props.resetWallet();
    setTimeout(() => window.location.reload(), 500);
  };

  toggleDrawer = (side, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    this.setState({ [side]: open });
  }

  sideList = side => {
    return (
      <>
        <div
          style={classes.list, { marginTop: '20px' }}
          role="presentation"
          onKeyDown={this.toggleDrawer(side, false)}
          onClick={this.toggleDrawer(side, false)}
        >
          <Link to="/" style={{ marginLeft: '20px' }} >
            <img
              style={{ maxWidth: '200px' }}
              src="https://tknismtest.s3.amazonaws.com/non-compressed-images/logo.png"
              alt=""
              title=""
            />
          </Link>
          <Divider style={{ marginTop: '20px' }} />
          <List>
            <ListItem >
              <ListItemText>
                <Link id="dashHeader-side-home" to="/" style={classes.drawerItems}>Home</Link>
              </ListItemText>
            </ListItem>
            <ListItem >
              <ListItemText >
                <span id="dashHeader-side-whyInvest" style={classes.drawerItems}>Why Invest</span>
              </ListItemText>
            </ListItem>
            <Collapse in={!this.state.invest} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button style={classes.nested}>
                  <ListItemText>
                    <Link id="dashHeader-side-benefits" style={classes.drawerSubItems} to="/benefits">Benefits</Link>
                  </ListItemText>
                </ListItem>
                <ListItem button style={classes.nested}>
                  <ListItemText>
                    <Link id="dashHeader-side-reviews" style={classes.drawerSubItems} to="#">Reviews & Ratings</Link>
                  </ListItemText>
                </ListItem>
                <ListItem button style={classes.nested}>
                  <ListItemText>
                    <Link id="dashHeader-side-technology" style={classes.drawerSubItems} to="/technology">Technology</Link>
                  </ListItemText>
                </ListItem>
              </List>
            </Collapse>

            <ListItem >
              <ListItemText>
                <Link id="dashHeader-side-listings" style={classes.drawerItems} to="/listing">Listings</Link>
              </ListItemText>
            </ListItem>

            <ListItem >
              <ListItemText>
                <Link id="dashHeader-side-HIW" style={classes.drawerItems} to="/how-it-works">How It Works</Link>
              </ListItemText>
            </ListItem>

            <ListItem >
              <ListItemText>
                <span id="dashHeader-side-resources" style={classes.drawerItems}>Resources</span>
              </ListItemText>
            </ListItem>

            <Collapse in={!this.state.resource} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button style={classes.nested}>
                  <ListItemText>
                    <Link id="dashHeader-side-investing101" style={classes.drawerSubItems} to="/investing101">Investing 101</Link>
                  </ListItemText>
                </ListItem>
                <ListItem button style={classes.nested}>
                  <ListItemText>
                    <Link id="dashHeader-side-about" style={classes.drawerSubItems} to="/about-us">About Us</Link>
                  </ListItemText>
                </ListItem>
                <ListItem button className={classes.nested}>
                  <ListItemText>
                    <Link className={classes.drawerSubItems} to="/events">Events & Media</Link>
                  </ListItemText>
                </ListItem>
                <ListItem button className={classes.nested}>
                  <ListItemText>
                    <Link id="navbar-drawer-helpCenter" className={classes.drawerSubItems} to="/help-center">Help Center - FAQ's</Link>
                  </ListItemText>
                </ListItem>
                <ListItem button className={classes.nested}>
                  <ListItemText>
                    <Link id="navbar-drawer-contactUs" className={classes.drawerSubItems} to="/contact-us">Contact us</Link>
                  </ListItemText>
                </ListItem>
              </List>
            </Collapse>
          </List>
          <Divider />
        </div>
      </>
    )
  }


  onNotificationClick = (value) => {
    if (value.seen == false) this.props.viewSingleNotification({ data: value._id });
    if (value.method == "new-listing" || value.method == "counter-tokens") {
      setTimeout(() => {
        this.props.history.push("/dashboard/portfolio");
        setTimeout(() => EventBus.publish("tokensListed", "dealRoom"), 300)
      }, 500);
    } else if (value.method == "buy-tokens") {
      setTimeout(() => {
        this.props.history.push("/dashboard/portfolio");
        setTimeout(() => EventBus.publish("tokensListed", "transactionHistory"), 300)
      }, 500);
    } else if (value.method == "tkusd-withdraw") {
      setTimeout(() => {
        this.props.history.push("/dashboard/wallet");
        setTimeout(() => EventBus.publish("paymentWithdrawed", "DWHistory"), 300)
      }, 500);
    } else if (value.method == "tkusd-deposit") {
      setTimeout(() => {
        this.props.history.push("/dashboard/wallet");
        setTimeout(() => EventBus.publish("paymentDeposited", "DWHistory"), 300)
      }, 500);
    } else if (value.method == "counter-tokens") {
      setTimeout(() => {
        this.props.history.push("/dashboard/wallet");
        setTimeout(() => EventBus.publish("tokensListed", "dealRoom"), 300)
      }, 500);
    } else if (value.method == "accept-counter") {
      setTimeout(() => {
        this.props.history.push("/dashboard/portfolio");
        setTimeout(() => EventBus.publish("tokensListed", "transactionHistory"), 300)
      }, 500);
    }
  }

  handelUserMessage = (e, infoMessage, infoHeading) => {
    // e.preventDefault()
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    const data = {
      isOpen: !this.props.isOpen,
      infoMessage,
      infoHeading,
    }
    this.props.handelUserMessage(data)
  }

  render() {
    let { active, dashData, profile, email, lastName, firstName, id, allNotifications, dashHeadData, dashChartData } = this.props;
    let { email: newEmail, step, security, avatar } = dashHeadData || {};
    let { accreditedVerification } = security || {};
    // let { accreditedVerification, email: newEmail, step, user } = dashChartData || {};

    step = step || 1;
    email = email || newEmail;
    accreditedVerification = accreditedVerification || "In Complete";
    let profileImg = profile["avatar"]
      ? profile["avatar"] : dashHeadData && dashHeadData.hasOwnProperty("avatar")
        ? dashHeadData["avatar"] : "https://tknismtest.s3.amazonaws.com/non-compressed-images/28.png";
    let url = `https://www.earlyiq.com/partner/tokenism/investor/one/getstarted?page_template=modal&email=${email}&first_name=${firstName}&last_name=${lastName}&type=tokenism-inv-001&api_key1=nIOPcvJVUnD%2BdcKXh62xygxt%2BTkrixw%2BWOG9S7Tl8fw%3D&partner_ref_id=${id}`;
    console.log('$$$$$ dashData', dashHeadData);
    return (
      <Fragment>
        {this.props.showIframe && (
          <div className="iframe-container">
            <button
              className="coss-iframe-btn"
              onClick={() => this.props.showIframeFn(false)}
            >
              <i
                className="icon fa fa-times-circle"
                style={{ fontSize: "30px", color: "#8DC63F" }}
              ></i>
            </button>
            <div className="iframe-innerContainer">
              <iframe src={url} height="100%" width="100%"></iframe>
            </div>
          </div>
        )}
        <SwipeableDrawer
          className="main-header-drawer"
          anchor="right"
          open={this.state.right}
          onClose={this.toggleDrawer('right', false)}
          onOpen={this.toggleDrawer('right', true)}
        >
          {this.sideList('right')}
        </SwipeableDrawer>
        <header className="main-header header-style-two">
          <div className="header-lower">
            <div className="container">
              <div className="main-box clearfix">
                <div className="logo-box pull-left">
                  <div className="logo">
                    <Link to="/">
                      <img
                        src="https://tknismtest.s3.amazonaws.com/non-compressed-images/logo.png"
                        alt=""
                        title=""
                      />
                    </Link>
                  </div>
                </div>
                <div className="nav-outer clearfix pull-right">
                  <nav className="main-menu navbar-expand-md ">
                    <div className="navbar-header">
                      <button
                        className="navbar-toggler"
                        type="button"
                        // data-toggle="collapse"
                        // data-target="#navbarSupportedContent"
                        // aria-controls="navbarSupportedContent"
                        // aria-expanded="false"
                        // aria-label="Toggle navigation"
                        onClick={this.toggleDrawer('right', !this.state.right)}
                      >
                        <span className="fa fa-bars" aria-hidden="true"></span>
                      </button>
                    </div>
                    <div
                      className="collapse navbar-collapse clearfix"
                      id="navbarSupportedContent"
                    >
                      <ul className="navigation clearfix">
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li className="dropdown">
                          <a className="dropdown-toggle pointerDisabled" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Why Invest
                        </a>
                          <ul className="dropdown-menu m-0" aria-labelledby="navbarDropdown">
                            <li>
                              <Link to="/benefits">Benefits</Link>
                            </li>
                            <li>
                              <Link to="/technology">Technology</Link>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <Link id="dashHeader-swipeable-listings" to="/listing">Listings</Link>
                        </li>
                        <li>
                          <Link id="dashHeader-swipeable-HIW" to="/how-it-works">How It Works</Link>
                        </li>
                        <li className="dropdown">
                          <a className="dropdown-toggle pointerDisabled" href="#" id="dashHeader-swipeable-resources" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Resources
                          </a>
                          <ul className="dropdown-menu m-0" aria-labelledby="navbarDropdown2">
                            <li>
                              <Link id="dashHeader-swipeable-investing101" to="/investing101">Investing 101</Link>
                            </li>
                            <li>
                              <Link id="dashHeader-swipeable-about" to="/about-us">About Us</Link>
                            </li>
                            <li>
                              <Link to="/events">Events & Media</Link>
                            </li>
                            <li>
                              <Link id="dashHeader-swipeable-faq" to="/help-center">Help Center - FAQ's</Link>
                            </li>
                            <li><Link id="dashHeader-swipeable-contact" to="/contact-us">Contact us</Link></li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </nav>
                  <div className="outer-box">
                    {/* <a href="#" className="notify"><i className="icon"><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/26.png")} alt="" /></i></a>*/}
                    <PopupState variant="popover" popupId="demo-popup-popover">
                      {(popupState) => (
                        <div>
                          <a href="#" variant="contained" className="notify" color="primary" {...bindTrigger(popupState)}>
                            <span className={allNotifications && allNotifications.some(el => el.seen == false) ? "notifaction" : ''}></span><i className="icon"><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/27.png" alt="" /></i>
                          </a>
                          <Popover
                            style={{
                              zIndex: 999999,
                            }}
                            {...bindPopover(popupState)}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'center',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'center',
                            }}
                          >
                            <Box className="notification-popup">
                              <h4 className="title">notifications</h4>
                              <div className="scroll">
                                {allNotifications && allNotifications.map((notification) => (
                                  <div className={notification.seen ? "notification" : "notification new"} onClick={(value) => this.onNotificationClick(notification)}>
                                    <figure className="img">
                                      <img src={notification.avatar} />
                                    </figure>
                                    <p className="line" onClick={popupState.close}>{notification.message}</p>
                                    <span className="time">{moment(notification.updatedAt).format('LT')}</span>
                                  </div>
                                ))}
                              </div>
                              <Link to="/notifications" className="view-more" >view more</Link>
                            </Box>
                          </Popover>
                        </div>
                      )}
                    </PopupState>
                    <a id="dashHeader-responsive-logout" onClick={() => this.handleLogout()} className="notify">
                      <i className="icon">
                        <img
                          src="https://tknismtest.s3.amazonaws.com/non-compressed-images/25.png"
                          alt=""
                        />
                      </i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sticky-header">
            <div className="container">
              <div className="main-box clearfix">
                <div className="logo pull-left">
                  <Link to="/">
                    <img
                      src="https://tknismtest.s3.amazonaws.com/non-compressed-images/logo-small.png"
                      alt=""
                      title=""
                    />
                  </Link>
                </div>
                <div className="pull-right">
                  <nav className="main-menu">
                    <div className="navbar-collapse show collapse clearfix">
                      <ul className="navigation clearfix">
                        <li>
                          <Link id="dashHeader-home" to="/">Home</Link>
                        </li>
                        <li className="dropdown">
                          <Link id="dashHeader-invest" to="#">Why Invest</Link>
                          <ul>
                            <li>
                              <Link id="dashHeader-benefits" to="/benefits">Benefits</Link>
                            </li>
                             <li>
                              <Link id="dashHeader-technology" to="/technology">Technology</Link>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <Link id="dashHeader-listings" to="/listing">Listings</Link>
                        </li>
                        <li>
                          <Link id="dashHeader-HIW" to="/how-it-works">How It Works</Link>
                        </li>
                        <li className="dropdown">
                          <Link id="dashHeader-resources" to="#">Resources</Link>
                          <ul>
                            <li>
                              <Link id="dashHeader-investing" to="/investing101">Investing 101</Link>
                            </li>
                            <li>
                              <Link id="dashHeader-about" to="/about-us">About Us</Link>
                            </li>
                            <li>
                                <Link to="/events">Events & Media</Link>
                             </li>
                            <li>
                              <Link id="dashHeader-faq" to="/help-center">Help Center - FAQ's</Link>
                            </li>
                            <li><Link id="dashHeader-contact" to="/contact-us">Contact us</Link></li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </nav>
                  <div className="outer-box">
                    {/*
                    <a href="#" className="notify"><i className="icon"><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/26.png")} alt="" /></i></a>
                    <a href="#" className="notify"><i className="icon"><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/27.png")} alt="" /></i></a>
                    */}
                    <a id="dashHeader-logout" onClick={() => this.handleLogout()} className="notify">
                      <i className="icon">
                        <img
                          src="https://tknismtest.s3.amazonaws.com/non-compressed-images/25.png"
                          alt=""
                        />
                      </i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        {!this.props.onlyNav &&
          <section className="tob-banner-user">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="info-sec">
                    <ul className="left-area">
                      <li>
                        <span>
                          <img
                            src={profileImg}
                            className="avatar"
                            width="34px"
                            height="34px"
                            alt=""
                          />
                        </span>
                        {email}
                      </li>
                    </ul>
                    <div className="right-sec">
                      {step == 1 && (
                        <button
                          className={dashHeadData && dashHeadData.isKycVerified == "InReview" ? "btn btn-outline-warning tooltipWarning mr-1" : dashHeadData && dashHeadData.isKycVerified == "Verified" && dashHeadData.dwollaStatus == "document" ? "btn btn-outline-warning tooltipWarning mr-1" : "btn btn-outline-danger tooltip mr-1"}
                          onClick={(e) => isMobile || isTablet ? this.handelUserMessage(e, 'To continue your identity verification, proceed through a desktop browser.') : this.kyc()}
                        >
                          {/* <Link to="kyc"> */}
                          Identity Verification
                          {/* <span className="tooltiptextWarning">
                            {(user && user.isKycVerified) &&  user.isKycVerified}
                          </span> */}
                          <span className={dashHeadData && dashHeadData.isKycVerified == "InReview" ? "tooltiptextWarning" : dashHeadData && dashHeadData.isKycVerified == "Verified" && dashHeadData.dwollaStatus == "document" ? "tooltiptextWarning" : "tooltiptext"}>
                            {(dashHeadData && dashHeadData.isKycVerified == "Verified" && dashHeadData.dwollaStatus == "document") ? "In Review" : (dashHeadData && dashHeadData.isKycVerified) && dashHeadData.isKycVerified.replace(/([a-z])([A-Z])/, '$1 $2')}
                          </span>
                          {/* </Link> */}
                        </button>
                      )}
                      {step == 2 && (
                        <button
                          className="btn btn-outline-danger tooltip mr-1"
                          onClick={() => window.open(url)}
              // onClick={() => this.accredationSubmit(accreditedVerification) }
                        >
                          Accreditation Verification
                          <span className="tooltiptext">
                            {accreditedVerification}
                          </span>
                        </button>
                      )}
                      <a
                        href="#"
                        className="property"
                        onClick={() => this.sellProperty()}
                      >
                        Submit A Property
                      </a>
                    </div>
                  </div>
                  {dashHeadData && dashHeadData.hasOwnProperty('step') ?
                    <div className="signup-setup">
                      <ul>
                        <li
                          className={
                            step > 1
                              ? "complete"
                              : step === 1
                                ? "previous"
                                : "panding"
                          }
                        >
                          <i className="complete">
                            <img
                              src="https://tknismtest.s3.amazonaws.com/non-compressed-images/36.png"
                              alt=""
                            />
                          </i>
                          <i className="active">
                            <img
                              src="https://tknismtest.s3.amazonaws.com/non-compressed-images/36.png"
                              alt=""
                            />
                          </i>
                          <i className="panding">
                            <img
                              src="https://tknismtest.s3.amazonaws.com/non-compressed-images/36.png"
                              alt=""
                            />
                          </i>
                          <i className="previous">
                            <img
                              src="https://tknismtest.s3.amazonaws.com/non-compressed-images/36.png"
                              alt=""
                            />
                          </i>
                          <img
                            src="https://tknismtest.s3.amazonaws.com/non-compressed-images/ok.png"
                            alt=""
                          />
                          <span>Signup</span>
                        </li>
                        <li
                          className={
                            step === 1
                              ? "active"
                              : step === 2
                                ? "previous"
                                : step > 2
                                  ? "complete"
                                  : step < 1
                                    ? "panding"
                                    : ""
                          }
                        >
                          <i className="complete">
                            <img
                              src="https://tknismtest.s3.amazonaws.com/non-compressed-images/39.png"
                              alt=""
                            />
                          </i>
                          <i className="active">
                            {dashHeadData && dashHeadData.isKycVerified == "InReview" ?
                              <img
                                src="https://tknismtest.s3.amazonaws.com/non-compressed-images/verificationWarning.png"
                                alt=""
                              />
                              :
                              dashHeadData && dashHeadData.isKycVerified == "Verified" && dashHeadData.dwollaStatus == "document"
                              ?
                              <img
                                src="https://tknismtest.s3.amazonaws.com/non-compressed-images/verificationWarning.png"
                                alt=""
                              />
                              :
                              <img
                                src="https://tknismtest.s3.amazonaws.com/non-compressed-images/verificationRed.png"
                                alt=""
                              />
                            }
                          </i>
                          <i className="panding">
                            <img
                              src="https://tknismtest.s3.amazonaws.com/non-compressed-images/37.png"
                              alt=""
                            />
                          </i>
                          <i className="previous">
                            <img
                              src="https://tknismtest.s3.amazonaws.com/non-compressed-images/39.png"
                              alt=""
                            />
                          </i>
                          <i>
                            <img
                              src="https://tknismtest.s3.amazonaws.com/non-compressed-images/34.png"
                              alt=""
                            />
                          </i>
                          {step != 1 ?
                            <img
                              alt=""
                              src={step < 1
                                ? "https://tknismtest.s3.amazonaws.com/non-compressed-images/33.png"
                                : "https://tknismtest.s3.amazonaws.com/non-compressed-images/ok.png"
                              }
                              /> :
                            <a href="#" onClick={(e) => isMobile || isTablet ? this.handelUserMessage(e, 'To continue your identity verification, proceed through a desktop browser.') : this.kyc()}>
                              {dashHeadData && dashHeadData.isKycVerified == "InReview" ?
                                <img
                                  alt=""
                                  src="https://tknismtest.s3.amazonaws.com/non-compressed-images/29Warning.png"
                                />
                                :
                                dashHeadData && dashHeadData.isKycVerified == "Verified" && dashHeadData.dwollaStatus == "document"
                                ?
                                <img
                                  alt=""
                                  src="https://tknismtest.s3.amazonaws.com/non-compressed-images/29Warning.png"
                                />
                                :
                                <img
                                  alt=""
                                  src="https://tknismtest.s3.amazonaws.com/non-compressed-images/remindericon.png"
                                  />
                              }
                              </a>
                            }
                            {dashHeadData && dashHeadData.isKycVerified == "InReview" ? <span style={{color:'#ffc107'}}>Identity Verification</span> : dashHeadData && dashHeadData.isKycVerified == "Verified" && dashHeadData.dwollaStatus == "document" ? <span style={{color:'#ffc107'}}>Identity Verification</span> : <span>Identity Verification</span>}
                        </li>
                        <li
                          className={
                            step === 2
                              ? "active"
                              : step === 3
                                ? "previous"
                                : step > 3
                                  ? "complete"
                                  : step < 2
                                    ? "panding"
                                    : ""
                          }
                        >
                          <i className="complete">
                            <img
                              src="https://tknismtest.s3.amazonaws.com/non-compressed-images/aml-active.png"
                              alt=""
                            />
                          </i>
                          <i className="active">
                            <img
                              src="https://tknismtest.s3.amazonaws.com/non-compressed-images/aml-red.png"
                              alt=""
                            />
                          </i>
                          <i className="panding">
                            <img
                              src="https://tknismtest.s3.amazonaws.com/non-compressed-images/aml-pending.png"
                              alt=""
                            />
                          </i>
                          <i className="previous">
                            <img
                              src="https://tknismtest.s3.amazonaws.com/non-compressed-images/aml-active.png"
                              alt=""
                            />
                          </i>
                          <i>
                            <img
                              src="https://tknismtest.s3.amazonaws.com/non-compressed-images/34.png"
                              alt=""
                            />
                          </i>
                            {
                              step === 2
                                ?
                                <a href="#" onClick={() => window.open(url)}>
                                <img
                                alt=""
                                src="https://tknismtest.s3.amazonaws.com/non-compressed-images/remindericon.png"/>
                                </a>
                                :
                                <img
                                alt=""
                                src={ step < 2
                                      ? "https://tknismtest.s3.amazonaws.com/non-compressed-images/33.png"
                                      : "https://tknismtest.s3.amazonaws.com/non-compressed-images/ok.png"
                                    }/>
                            }
                          <span>Accreditation Verification</span>
                        </li>
                        <li
                          className={
                            step === 3
                              ? "active"
                              : step === 4
                                ? "previous"
                                : step > 4
                                  ? "complete"
                                  : step < 3
                                    ? "panding"
                                    : ""
                          }
                        >
                          <i className="complete">
                            <img
                              src="https://tknismtest.s3.amazonaws.com/non-compressed-images/44.png"
                              alt=""
                            />
                          </i>
                          <i className="active">
                            <img
                              className="activeImage"
                              src="https://tknismtest.s3.amazonaws.com/non-compressed-images/walletRed.png"
                              alt=""
                            />
                          </i>
                          <i className="panding">
                            <img
                              src="https://tknismtest.s3.amazonaws.com/non-compressed-images/45.png"
                              alt=""
                            />
                          </i>
                          <i className="previous">
                            <img
                              src="https://tknismtest.s3.amazonaws.com/non-compressed-images/44.png"
                              alt=""
                            />
                          </i>
                          <i>
                            <img
                              src="https://tknismtest.s3.amazonaws.com/non-compressed-images/34.png"
                              alt=""
                            />
                          </i>
                          {step === 3 ?
                            <Link
                            to={{
                              pathname: "/dashboard/wallet",
                              state: { forPage: "Deposit" }
                            }}>
                              <img alt="" src="https://tknismtest.s3.amazonaws.com/non-compressed-images/remindericon.png" />
                            </Link>
                            :
                            <img
                            alt=""
                            src={step < 3  ?
                                  "https://tknismtest.s3.amazonaws.com/non-compressed-images/33.png"
                                  : "https://tknismtest.s3.amazonaws.com/non-compressed-images/ok.png"
                            }/>
                            }
                          <span>Deposit Funds</span>
                        </li>
                        <li
                          className={
                            step === 4
                              ? "active"
                              : step === 5
                                ? "previous"
                                : step > 5
                                  ? "complete"
                                  : step < 4
                                    ? "panding"
                                    : ""
                          }
                        >
                          <i className="complete">
                            <img
                              src="https://tknismtest.s3.amazonaws.com/non-compressed-images/cart47.png"
                              alt=""
                            />
                          </i>
                          <i className="active">
                            <img
                              src="https://tknismtest.s3.amazonaws.com/non-compressed-images/cartRed.png"
                              alt=""
                            />
                          </i>
                          <i className="panding">
                            <img
                              src="https://tknismtest.s3.amazonaws.com/non-compressed-images/48.png"
                              alt=""
                            />
                          </i>
                          <i className="previous">
                            <img
                              src="https://tknismtest.s3.amazonaws.com/non-compressed-images/cart47.png"
                              alt=""
                            />
                          </i>
                          <i>
                            <img
                              src="https://tknismtest.s3.amazonaws.com/non-compressed-images/34.png"
                              alt=""
                            />
                          </i>
                          {step === 4 ?
                            <Link to="/listing">
                              <img alt="" src="https://tknismtest.s3.amazonaws.com/non-compressed-images/remindericon.png" />
                            </Link>
                            :
                            <img
                            alt=""
                            src={step < 4  ?
                                  "https://tknismtest.s3.amazonaws.com/non-compressed-images/33.png"
                                  : "https://tknismtest.s3.amazonaws.com/non-compressed-images/ok.png"
                            }/>
                            }
                          <span>Buy Property</span>
                        </li>
                      </ul>
                    </div>
                    :
                    <Loader />
                  }
                  <div className="nav-menu-bottom">
                    <ul>
                      <li className={active === "dashboard" ? "active" : ""}>
                        <Link id="toDashboard" to="/dashboard">Dashboard</Link>
                      </li>
                      <li className={active === "wallet" ? "active" : ""}>
                        {step < 3 ?
                          <a onClick={(e) => this.handelUserMessage(e, 'Please complete the identity verification process to access this feature. You will not be able to view your wallet or portfolio until your identity and accreditation status is confirmed. If you need additional assistance or have a concern please consult our concierge or contact us via email', step == 1 ? 'Your Identity Verification is Incomplete' : "Your Accreditation Verification is Incomplete")}>Wallet</a>
                          :
                          <Link id="toWallet" to="/dashboard/wallet">Wallet</Link>
                        }
                      </li>
                      <li className={active === "deal" ? "active" : ""}>
                        {step < 3 ?
                          <a onClick={(e) => {e.preventDefault(); this.handelUserMessage(e, 'Please complete the identity verification process to access this feature. You will not be able to view your wallet or portfolio until your identity and accreditation status is confirmed. If you need additional assistance or have a concern please consult our concierge or contact us via email', step == 1 ? 'Your Identity Verification is Incomplete' : "Your Accreditation Verification is Incomplete")}}>My Portfolio</a>
                          :
                          <Link id="toPortfolio" to="/dashboard/portfolio">My Portfolio</Link>
                         }
                      </li>
                      <li className={active === "documents" ? "active" : ""}>
                        <Link id="toDocuments" to="/dashboard/documents">Documents</Link>
                      </li>
                      <li className={active === "favorites" ? "active" : ""}>
                        <Link id="toFavorites" to="/dashboard/favorites">Favorites</Link>
                      </li>
                      <li className={active === "settings" ? "active" : ""}>
                        <Link id="toSettings" to="/dashboard/settings">Settings</Link>
                      </li>
                      {this.state.redirect && <Redirect push to={{
                        pathname: "/dashboard/settings",
                        state: { dwollaVerification: this.state.dwollaVerification }
                      }} />}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
        }
        </Fragment>
    );
  }
}

const mapStateToProps = ({ Auth, Settings }) => {
  let { profile, allNotifications } = Settings;
  let { dashData, id, email, lastName, firstName, showIframe, isOpen, dashHeadData, dashChartData } = Auth;
  return { dashData, profile, id, email, lastName, firstName, showIframe, allNotifications, isOpen, dashHeadData, dashChartData };
};

const mapDispatchToProps = {
  getProfile,
  userDashHeader,
  userDashChart,
  logout,
  showIframeFn,
  submitAccredation,
  getInvestorPreferences,
  getAllNotifications,
  viewSingleNotification,
  handelUserMessage,
  resetExchange,
  resetSettings,
  resetWallet
};

export default connect(mapStateToProps, mapDispatchToProps)(DashHeader);

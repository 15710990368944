import React, { Component } from 'react';

import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import Banner from '../../components/Banner'
import ListingBanner from '../../components/ListingBanner'
import InvestmentPlans from "../../DataFiles/InvestmentPlans";
import LazyLoad from 'react-lazyload';
import { deviceType } from "react-device-detect";


class Benefits extends Component {
  constructor() {
    super()
    this.state = {}
    window.scrollTo(0, 0);
  }

  handleStageListing = (e) => {
    this.props.history.push({
      pathname: '/listing',
      state: { TypeOfInvestment: e }
    })
  }

  componentDidMount() {

    setTimeout(() => {
      const myId = window.location.hash.slice(1)
      let elem = document.getElementById("timeline")
      if (window.location.hash && deviceType != 'mobile') {
        elem.scrollIntoView({ behavior: 'smooth' });
      } else if (window.location.hash) {
        elem = document.getElementById(myId)
        elem.scrollIntoView({ behavior: 'smooth' });
      }
    }, 500);
  }

  render() {

    return (
      <div className="page-wrapper">
        <Navbar
          page='benefits'
          {...this.props}
        />
        <Banner
          heading='Benefits'
          subHeading='Lorem ipsum dolor sit amet, consectetur adipisicing elit do eiusmod tempor consectetur'
        />
        <div className="benifts-sec mt-3" id="timeline">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="sec-title text-center">
                  <h2 className="title-fill">Key Benefits</h2>
                </div>
              </div>
              <div className="container">
                <div className="row">
                  <div className="service-block col-lg-4 col-md-6 col-sm-6" id="direct">
                    <div className="service-inner">
                      <div className="icon-area">
                        <figure><LazyLoad once ><img style={{ width: "40px", height: "35px" }} src="https://tknismtest.s3.amazonaws.com/compressedImages/1-min.png" alt="" /></LazyLoad></figure>
                      </div>
                      <div className="contenet-are">
                        <h4>Direct Investments</h4>
                        <p>
                          Invest directly in opportunities you handpick from our listings of commercial, and mixed-use properties. No obscure portfolios. Know exactly where your investments are going and how they are performing.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="service-block col-lg-4 col-md-6 col-sm-6" id="return">
                    <div className="service-inner">
                      <div className="icon-area">
                        <figure><LazyLoad once ><img style={{ width: "40px", height: "35px" }} src="https://tknismtest.s3.amazonaws.com/compressedImages/2-min.png" alt="" /></LazyLoad></figure>
                      </div>
                      <div className="contenet-are">
                        <h4>Maximize your Return</h4>
                        <p>
                          Tokenism provides the highest possible returns compared to REITs and crowdfunded investments. We cut out the middlemen, complicated management layers, and portfolio downside risk to provide lucrative direct ownership in single assets. Receive returns consistently with rent or interest payments.
                                    </p>
                      </div>
                    </div>
                  </div>

                  <div className="service-block col-lg-4 col-md-6 col-sm-12" id="investing">
                    <div className="service-inner">
                      <div className="icon-area">
                        <figure><LazyLoad once ><img style={{ width: "45px", height: "35px" }} src="https://tknismtest.s3.amazonaws.com/compressedImages/3-min.png" alt="" /></LazyLoad></figure>
                      </div>
                      <div className="contenet-are">
                        <h4>Hassle-Free Investing</h4>
                        <p>
                          We’ve made investing as simple as a click. No more piles of paperwork, long closing times, calls from tenants, or management headaches. Invest in the properties you want and receive your returns directly to your wallet.
                                    </p>
                      </div>
                    </div>
                  </div>

                  <div className="service-block col-lg-4 col-md-6 col-sm-12" id="liquidity">
                    <div className="service-inner">
                      <div className="icon-area">
                        <figure><LazyLoad once ><img style={{ width: "40px", height: "40px" }} src="https://tknismtest.s3.amazonaws.com/compressedImages/4-min.png" alt="" /></LazyLoad></figure>
                      </div>
                      <div className="contenet-are">
                        <h4>Liquidity</h4>
                        <p>
                          Our marketplace allows you to list and sell your investment tokens at your own convenience. Unlike other funds with long holding periods and penalty fees, Tokenism offers you total liquidity to trade your investments without any of the traditional hassles.
                                    </p>
                      </div>
                    </div>
                  </div>

                  <div className="service-block col-lg-4 col-md-6 col-sm-12" id="secure">
                    <div className="service-inner">
                      <div className="icon-area">
                        <figure><LazyLoad once ><img style={{ width: "40px", height: "32px" }} src="https://tknismtest.s3.amazonaws.com/compressedImages/5-min.png" alt="" /></LazyLoad></figure>
                      </div>
                      <div className="contenet-are">
                        <h4>Security</h4>
                        <p>
                          We provide ultimate security in online real estate investing using digital securities and blockchain. Each holding and transaction is recorded on the immutable and non-erasable blockchain, protecting your investments and trades every step of the way.
                                    </p>
                      </div>
                    </div>
                  </div>

                  <div className="service-block col-lg-4 col-md-6 col-sm-12" id="low-invest">
                    <div className="service-inner">
                      <div className="icon-area">
                        <figure><LazyLoad once ><img style={{ width: "40px", height: "35px" }} src="https://tknismtest.s3.amazonaws.com/compressedImages/6-min.png" alt="" /></LazyLoad></figure>
                      </div>
                      <div className="contenet-are">
                        <h4>Low Investment Threshold </h4>
                        <p>
                          You can start investing with as little as $1,000. Other direct investments have traditionally required a minimum investment of $250,000. We offer investors access to this formerly unavailable investment class along with the ability to diversify your portfolio with affordable tokenized direct investments in the properties of your choosing.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="listing-sec">
          <div className="container">
            <div className="sec-title text-center">
              <h2>Featured Listings</h2>
              <p>Invest in an asset of your choice across a variety of investment & Asset classes</p>
            </div>
            <div className="row">
              <div className="col-12">
                <ListingBanner handleStagePress={this.handleStageListing} {...this.props} />
              </div>
            </div>
          </div>
        </div>

        <div className="trems-sec sec-padding4">
          <div className="container">
            <div className="row">
              <div className="trems-column col-md-6 col-lg-3 col-sm-6">
                <div className="trems-block text-center">
                  <div className="icon-area">
                    <figure><LazyLoad once ><img src="https://tknismtest.s3.amazonaws.com/compressedImages/investment-min.png" alt="" /></LazyLoad></figure>
                  </div>
                  <div className="bottom-column">
                    <h4>{InvestmentPlans[0].heading}</h4>
                    <p>{InvestmentPlans[0].description}</p>
                  </div>
                  <div className="bottom-area-one">
                    <div className="row">
                      <div className="col-6 titles">
                        {InvestmentPlans[0].sub1}
                      </div>
                      <div className="col-6">
                        <div className="barContainer">
                          <div className="BarDivident"></div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 titles">
                        {InvestmentPlans[0].sub2}
                      </div>
                      <div className="col-6">
                        <div className="barContainer">
                          <div className="BarAppreciation"></div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 titles">
                        {InvestmentPlans[0].sub3}
                      </div>
                      <div className="col-6">
                        <div className="barContainer">
                          <div className="BarTotal"></div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 titles">
                        {InvestmentPlans[0].sub4}
                      </div>
                      <div className="col-6 titlesDetails">
                        {InvestmentPlans[0].risk}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="trems-column col-md-6 col-lg-3 col-sm-6 ">
                <div className="trems-block text-center">
                  <div className="icon-area">
                    <figure><LazyLoad once ><img className="growth" src="https://tknismtest.s3.amazonaws.com/compressedImages/profit-min.png" alt="" /></LazyLoad></figure>
                  </div>
                  <div className="bottom-column">
                    <h4>{InvestmentPlans[1].heading}</h4>
                    <p>{InvestmentPlans[1].description}</p>
                  </div>
                  <div className="bottom-area-two">
                    <div className="row">
                      <div className="col-6 titles">
                        {InvestmentPlans[1].sub1}
                      </div>
                      <div className="col-6">
                        <div className="barContainer">
                          <div className="BarDivident"></div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 titles">
                        {InvestmentPlans[1].sub2}
                      </div>
                      <div className="col-6">
                        <div className="barContainer">
                          <div className="BarAppreciation"></div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 titles">
                        {InvestmentPlans[1].sub3}
                      </div>
                      <div className="col-6">
                        <div className="barContainer">
                          <div className="BarTotal"></div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 titles">
                        {InvestmentPlans[1].sub4}
                      </div>
                      <div className="col-6 titlesDetails">
                        {InvestmentPlans[1].risk}
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div className="trems-column col-md-6 col-lg-3 col-sm-6">
                <div className="trems-block text-center">
                  <div className="icon-area">
                    <figure><LazyLoad once ><img className="aggresive" src="https://tknismtest.s3.amazonaws.com/compressedImages/property-min.png" alt="" /></LazyLoad></figure>
                  </div>
                  <div className="bottom-column">
                    <h4>{InvestmentPlans[2].heading}</h4>
                    <p>{InvestmentPlans[2].description}</p>
                  </div>
                  <div className="bottom-area-three">
                    <div className="row">
                      <div className="col-6 titles">
                        {InvestmentPlans[2].sub1}
                      </div>
                      <div className="col-6">
                        <div className="barContainer">
                          <div className="BarDivident"></div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 titles">
                        {InvestmentPlans[2].sub2}
                      </div>
                      <div className="col-6">
                        <div className="barContainer">
                          <div className="BarAppreciation"></div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 titles">
                        {InvestmentPlans[2].sub3}
                      </div>
                      <div className="col-6">
                        <div className="barContainer">
                          <div className="BarTotal"></div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 titles">
                        {InvestmentPlans[2].sub4}
                      </div>
                      <div className="col-6 titlesDetails">
                        {InvestmentPlans[2].risk}
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div className="trems-column col-md-6 col-lg-3 col-sm-6">
                <div className="trems-block text-center">
                  <div className="icon-area">
                    <figure><LazyLoad once ><img src="https://tknismtest.s3.amazonaws.com/compressedImages/long-term-min.png" alt="" /></LazyLoad></figure>
                  </div>
                  <div className="bottom-column">
                    <h4>{InvestmentPlans[3].heading}</h4>
                    <p>{InvestmentPlans[3].description}</p>
                  </div>
                  <div className="bottom-area-four">
                    <div className="row">
                      <div className="col-6 titles">
                        {InvestmentPlans[3].sub1}
                      </div>
                      <div className="col-6">
                        <div className="barContainer">
                          <div className="BarDivident"></div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 titles">
                        {InvestmentPlans[3].sub2}
                      </div>
                      <div className="col-6">
                        <div className="barContainer">
                          <div className="BarAppreciation"></div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 titles">
                        {InvestmentPlans[3].sub3}
                      </div>
                      <div className="col-6">
                        <div className="barContainer">
                          <div className="BarTotal"></div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 titles">
                        {InvestmentPlans[3].sub4}
                      </div>
                      <div className="col-6 titlesDetails">
                        {InvestmentPlans[3].risk}
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Benefits;

import React, { Component } from 'react';
import Footer from '../../components/Footer'
import { Step1 } from "./Step1";
import { Step2 } from "./Step2";
import { Step3 } from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import DashHeader from "../../components/DashHeader";
import { connect } from "react-redux";
import { verifySMSLink } from '../../store/actions/Settings';
import { Modal, ModalBody } from 'reactstrap';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import jwt_decode from 'jwt-decode';
import QRCode from 'qrcode.react';
import { getKyc} from "../../store/actions/Settings";
import { getKycStep } from "../../store/actions/Auth";


import { ApiUrl } from '../../store/config';

class Kyc extends Component {
  state = {
    isLoader:false,
    step: 1,
    doctype: "DL",
    countryName: this.props.countryName,
    shortName: this.props.shortName,
    phone: this.props.phone,
    qrlink: "",
    open: false,
    isUploaded: true,
    number: "",
    imageMessage: this.props.imageMessage,
    isSubmit: false,
  }

  componentDidMount() {
      if ( this.props.isKycVerified != undefined && this.props.isKycVerified == "InReview" && this.props.location.pathname == '/kyc') {
        this.props.history.push("/dashboard")
      }
      this.props.getKycStep()
  }

  componentWillReceiveProps({ phone, countryName, shortName, imageMessage, isKycVerified, currentKycStep }) {
    this.setState({ countryName, shortName, phone, imageMessage })
    if ( isKycVerified != undefined && isKycVerified == "InReview" && this.props.location.pathname == '/kyc') {
      this.props.history.push("/dashboard")
    }
    if (currentKycStep) {
      this.setState({ 
        step: 4,
        open: true,
        imageMessage: "Your documents are already uploaded. Submit to continue.",
        isUploaded: true,
      })
    }

  }


  

  handelDocType = (type) => this.setState({ doctype: type });
  handelCountry = (type) => this.setState({ countryName: type[0], shortName: type[1] });

  handelModal = () => {
    const { doctype, countryName, shortName } = this.state;
    let decodedToken = jwt_decode(localStorage.getItem('token'))
    let hash = new Buffer(`s1XrWeMEc2aJn1tu5HMp_${decodedToken['_id']}_${doctype}_${countryName}_${shortName}_${ApiUrl}`).toString('base64');
    this.setState({
      open: !this.state.open,
      imageMessage: "",
      isUploaded: true,
      // qrlink: `http://192.168.18.123:5000/?${hash}`
      qrlink: `https://verification.tknism.io/?${hash}`
    })
  }

  handelUploadedImage = () => {
    this.setState({
      open: !this.state.open,
      isUploaded: !this.state.isUploaded,
    })
    if (this.state.step == 5) {
      this.setState({ isSubmit: true })
    }
  }

  handelKycSteps = (stepMove) => {
    if (stepMove == 'Next') {
      this.setState({ step: this.state.step + 1 })
    } else if (stepMove == 'Back') {
      this.setState({ step: this.state.step - 1 })
    }
  }

  handelNumber = (e) => this.setState({phone: e.target.value})


  sendSMS = () => {
    let data = {
      phone: this.state.phone,
      link: this.state.qrlink,
    }
    this.props.verifySMSLink(data);
    this.setState({isLoader: !this.state.isLoader})
    setTimeout(() => {
      this.setState({isLoader: !this.state.isLoader})
    }, 10000);
  }

  render() {
    const { handelKycSteps, handelDocType, handelCountry, handelNumber, handelModal, sendSMS, handelUploadedImage } = this
    const { step, doctype, countryName, shortName, open, phone, qrlink, number, isLoader, imageMessage } = this.state
    const { frontImage, getKyc, history } = this.props
    return (
      <div className="page-wrapper">
        <DashHeader {...this.props} active={""} />
        {countryName != null ?
          <div className="kyc">
            <div className="container" >
              {step == 1 && (
                <Step1 handelSteps={handelKycSteps} />
              )}
              {step == 2 && (
                <Step2 value={countryName} selectCountry={handelCountry} handelSteps={handelKycSteps} />
              )}
              {step == 3 && (
                <Step3 value={doctype} selectDocType={handelDocType} handelSteps={handelKycSteps} />
              )}
              {step == 4 && (
                <Step4 doctype={doctype} handelModal={handelModal} handelSteps={handelKycSteps} />
              )}
              {/* {step == 5 && (
                <Step5 history={this.props.history} handelModal={handelModal} handelSteps={handelKycSteps} />
              )} */}
            </div>
          </div>
          :
          <div className="center text-center my-5 py-5">
            <i className="fa fa-spinner fa-spin fa-4x fa-fw" style={{ color: '#8cc63f' }} />
            <br /> <br />
              Loading
            </div>
        }
        <Footer />
        {imageMessage ?
          <Modal
            modalClassName="modal-mini modal-info uploaded"
            isOpen={open}
          >
            <div className="modal-header justify-content-center">
              <div className="modal-profile">
                <img className='modalLogoImg' src="https://tknismtest.s3.amazonaws.com/non-compressed-images/Logo0.png" alt="" />
              </div>
              <div className="right">
                <img
                  onClick={handelUploadedImage}
                  className="modalLogoImg"
                  src="https://tknismtest.s3.amazonaws.com/non-compressed-images/close-icon.png"
                  alt=""
                />
              </div>
            </div>
            <ModalBody>
              <div className="pt-3">
                <h5 className="tittle">Success!</h5>
                <p className="sub-tittle">{imageMessage}</p>
                <button onClick={() => { 
                  this.setState({isLoader:true}); 
                  getKyc({history}); 
                  handelUploadedImage() 
                  }} className="custom-btn mr-2">{isLoader ? <i className="fa fa-spinner fa-spin fa-4x fa-fw" style={{ color: '#8cc63f', fontSize:"24px" }} /> : "Submit"}</button>

                <button className="custom-btn" onClick={handelUploadedImage}>Cancel</button>
              </div>
            </ModalBody>
          </Modal>
          :
          <Modal
            modalClassName="modal-mini modal-info settings-rule modal-barCode kyc-modal"
            toggle={handelModal}
            isOpen={open}
          >
            <div className="modal-header justify-content-center">
              <div className="modal-profile">
                <img
                  className="modalLogoImg"
                  src="https://tknismtest.s3.amazonaws.com/non-compressed-images/favicon.png.png"
                  alt=""
                />
                <div className="right">
                  <img
                    onClick={handelModal}
                    className="modalLogoImg"
                    src="https://tknismtest.s3.amazonaws.com/non-compressed-images/close-icon.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <ModalBody>
              <div className="modal-body-container">
                <p className="scanbarcode-title">Use your Mobile Device to Capture Your Images</p>
                <p className="verification-title">Enter your mobile number below to send a link via SMS</p>
                <div className="number-input-container">
                  <FormControl variant="outlined">
                    <InputLabel>
                      Phone
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-error-helper-text"
                      label="phone"
                      labelWidth={35}
                      max={12}
                      name="phone"
                      type="text"
                      margin="dense"
                      value={this.state.phone}
                      onChange={handelNumber}
                      errormessages={['Number can not be empty']}
                      autoComplete='off'
                      startAdornment={<InputAdornment position="start"
                      >
                      </InputAdornment>}
                      aria-describedby="outlined-weight-helper-text"
                    />
                  </FormControl>
                  <button onClick={sendSMS} disabled={isLoader} >{isLoader ? <i className="fa fa-spinner fa-spin fa-4x fa-fw" style={{fontSize:"24px" }} /> : "SEND SMS"}</button>
                </div>
                <p className="scanbarcode-title">Or<br />Scan your QR Code below<br /></p>
                <QRCode className="my-3" value={qrlink} />
                <p className="downlaod-desc">
                  Download the app to scan the barcode
                    </p>
                <div className="store-btn-container">
                  <a href="https://play.google.com/store/apps/details?id=com.aiscan&hl=en" target="_blank" >
                    <img className="store-btn" src="https://tknismtest.s3.amazonaws.com/non-compressed-images/play-store.png" alt="" />
                  </a>
                  <a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank" >
                    <img className="store-btn" src="https://tknismtest.s3.amazonaws.com/non-compressed-images/app-store.png" alt="" />
                  </a>
                </div>
              </div>
            </ModalBody>
          </Modal>

        }
      </div>
    );
  }
}

const mapStateToProps = ({ Settings, Wallet , Auth }) => {
  return {
    phone: Settings.profile ? Settings.profile.phone : '',
    countryName: Settings.profile ? Settings.profile.citizenship.name : null,
    shortName: Settings.profile ? Settings.profile.citizenship.short : '',
    imageMessage: Wallet.imageMessage ? Wallet.imageMessage : "",
    faceImages: Wallet.faceImages,
    backImage: Wallet.backImage,
    frontImage: Wallet.frontImage,
    isKycVerified: Auth.dashHeadData ? Auth.dashHeadData.isKycVerified : false,
    dashHeadData: Auth.dashHeadData,
    currentKycStep: Auth.currentKycStep
  }
}

const mapDispatchToProps = { 
  verifySMSLink,
  getKyc,
  getKycStep
};

export default connect(mapStateToProps, mapDispatchToProps)(Kyc);

import React, { Component } from 'react';
import { setHash } from '../../../../store/axios';
import moment from 'moment';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import FormControl from '@material-ui/core/FormControl';
import { loginRequest, trustSignInfo, logout, sendSMSSignup } from '../../../../store/actions/Auth';
import { connect } from 'react-redux';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import NumberFormat from 'react-number-format';
import InputLabel from '@material-ui/core/InputLabel';

import Select from '@material-ui/core/Select';
import Countries from "../../../../DataFiles/Countries";
import Geocode from "react-geocode";
import EventBus from 'eventing-bus';
import { CountryRegionData } from 'react-country-region-selector';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import validator from 'validator';
import ReactGA from 'react-ga';
import { Link } from "react-router-dom";
import Files from "react-files";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

class Trust extends Component {
  constructor() {
    super()
    this.state = {
      pageContent: "",
      isLoader:false,
      age:16,
      isDisabled: false,
      agreeCheck: false,
      isValid: true,
      isValidNumber:true,
      selectedDate: new Date('2004-08-18T21:11:54'),
      labelWidth: 90,
      lockFields: true,
      isValidFormat:true,
      country:'',
      isValidCitizenship:true,
      isValidState:true,
      isValidSecurity:true,
      isValidCity:true,
      isValidZip:true,
      isValidOTP:true,
      isValidTrustNaem:true,
      isValidTrustClassification:true,
      isValidJuristdiction:true,
      isValidAddress:true,
      countryCode:'+1',
      countryShortCode:'US',
      regions:[],
      allDocuments:[],
      statesToRemove:[{name:'American Samoa'}, {name: 'District of Columbia'}],
      locale:['ar-AE', 'ar-BH', 'ar-DZ', 'ar-EG', 'ar-IQ', 'ar-JO', 'ar-KW', 'ar-SA', 'ar-SY', 'ar-TN','be-BY', 'bg-BG', 'bn-BD', 'cs-CZ', 'de-DE', 'de-AT', 'da-DK',
      'el-GR', 'en-AU', 'en-CA', 'en-GB', 'en-GG', 'en-GH', 'en-HK', 'en-MO', 'en-IE', 'en-IN', 'en-KE', 'en-MT', 'en-MU', 'en-NG', 'en-NZ', 'en-RW', 'en-SG', 'en-UG', 'en-US', 'en-TZ',
      'en-ZA', 'en-ZM', 'en-PK', 'es-EC', 'es-ES', 'es-MX', 'es-PA', 'es-PY', 'es-UY', 'et-EE', 'fa-IR', 'fi-FI', 'fj-FJ', 'fr-FR', 'fr-GF', 'fr-GP', 'fr-MQ', 'fr-RE', 'he-IL', 'hu-HU',
      'id-ID', 'it-IT', 'ja-JP', 'kk-KZ', 'ko-KR', 'lt-LT', 'ms-MY', 'nb-NO', 'ne-NP', 'nl-BE', 'nl-NL', 'nn-NO', 'pl-PL', 'pt-PT', 'pt-BR', 'ro-RO', 'ru-RU', 'sl-SI', 'sk-SK', 'sr-RS',
      'sv-SE', 'th-TH', 'tr-TR', 'uk-UA', 'vi-VN', 'zh-CN', 'zh-HK', 'zh-MO', 'zh-TW'],
      formData: {
        city: '',
        uploadLater:'',
        residenceLabel: "",
        state: " ",
        stateName: "",
        citizenshipLabel: 'United States',
        address: '',
        number:'',
        otp:'',
        tinType:'SSN'
      }
    }
    window.scrollTo(0, 0);
  }

  componentDidMount() {
    EventBus.on("BasicInfo2Type", () => this.setState({ isDisabled: false }));
    console.log('this.props', this.props.allSignData);
    if (this.props.allSignData.step2 && Object.keys(this.props.allSignData.step2).length > 0) {
      const { formData } = this.state;
      let myStep = this.props.allSignData.step2
      formData['city'] = myStep.city;
      formData['stateName'] = myStep.state;
      formData['address'] = myStep.address;
      formData['zipCode'] = myStep.zipCode;
      formData['securityNumber'] = myStep.securityNumber;
      formData['residenceLabel'] = myStep.residence;
      formData['citizenshipLabel'] = myStep.citizenship;
      formData['number'] = myStep.phoneNo;
      if(this.props.allSignData.businessData){
        const businessData = this.props.allSignData.businessData
        formData['juristdiction'] = businessData.juristdiction;
        formData['trustType'] = businessData.subType;
        formData['tinNumber'] = businessData.tinNumber;
        if(businessData.tinType) formData['tinType'] = businessData.tinType;
        formData['trustClassification'] = businessData.trustClassification;
        formData['trustName'] = businessData.trustName;
      }
      this.setState({ formData });
      this.setCountryState(myStep)
      if(myStep.dob) this.setDate(myStep.dob)
    }else{
      this.setDefaults()
    }
    ValidatorForm.addValidationRule('isNumberValid', (value) => {
      if (value) {
        return false;
      }
      return true;
    });
    ReactGA.pageview('/signup-2');
  }

  // selectCountry (val) {
  //   this.setState({ country: val });
  // }
  //
  // selectRegion (val) {
  //   this.setState({ region: val });
  // }

  submitContact = () => {
    setHash(this.props.signData);
    const { formData } = this.state;
    console.log(this.props.signData.hash, this.props.paramsHash, this.state);
    if(formData.citizenshipLabel && formData.stateName && this.state.isValidNumber && this.state.isValidCity && this.state.isValidAddress && this.state.isValidZip && this.state.isValidOTP ){
      const citizenship = Countries.find(({ name }) => name == this.state.formData.citizenshipLabel)
      const state = this.state.regions.find(({ name }) => name == this.state.formData.stateName)
      var smsVerifyKey = this.state.formData.otp.replace(/-/g, "");
      let phoneNo = '+' + citizenship.phone + this.state.formData.number
      let phoneCode = '+' + citizenship.phone
      formData['citizenship'] = {name: citizenship.name, short:citizenship.short};
      formData['state'] = state;
      formData['dob'] = this.state.selectedDate;
      formData['hash'] = this.props.signData.hash ? this.props.signData.hash : this.props.paramsHash;
      formData['smsVerifyKey'] = smsVerifyKey;

      if (this.state.age >= 18) {
        formData['phoneNo'] = phoneNo
        formData['phoneCode'] = phoneCode
        this.setState({ isDisabled: true })

        let data = new FormData();
        if(this.state.documentData && this.state.documentData[0]){
          data.append('agreement', this.state.documentData[0]);
        }
        data.append('hash', formData.hash);
        data.append('city', formData.city);
        data.append('zipCode', formData.zipCode);
        data.append('citizenship', JSON.stringify(formData.citizenship));
        data.append('address', formData.address);
        data.append('state', JSON.stringify(formData.state));
        data.append('dob', formData.dob);
        data.append('phoneNo', formData.phoneNo);
        data.append('phoneCode', formData.phoneCode);
        data.append('smsVerifyKey', formData.smsVerifyKey);
        data.append('gender', 'male');
        data.append('trustName', formData.trustName);
        data.append('trustClassification', formData.trustClassification);
        data.append('trustType', formData.trustType);
        data.append('tinType', formData.tinType);
        data.append('tinNumber', formData.tinNumber);
        data.append('juristdiction', formData.juristdiction);
        data.append('uploadLater', formData.uploadLater);

        console.log(data.has('agreement'));

        this.props.trustSignInfo(data)
        formData['otp'] = ''
      } else this.setState({ isValid: false })
    }else{
      if(formData.citizenshipLabel) this.setState({isValidCitizenship:true})
      else this.setState({isValidCitizenship:false})
      if(formData.stateName) this.setState({isValidState:true})
      else this.setState({isValidState:false})
      if(this.state.isValidNumber) this.setState({isValidNumber:true})
      else this.setState({isValidNumber:false})
      if(this.state.isValidOTP) this.setState({isValidOTP:true})
      else this.setState({isValidOTP:false})
    }
  }

  signUpOtp = () => {
    if(this.state.isValidNumber && this.state.formData.number){
      this.setState({isLoader:!this.state.isLoader})
      const citizenship = Countries.find(({ name }) => name == this.state.formData.citizenshipLabel)
      let phoneNo = '+' + citizenship.phone + this.state.formData.number
      this.props.sendSMSSignup({hash:this.props.signData.hash, phone:phoneNo})
      setTimeout(() => {
        this.setState({isLoader:!this.state.isLoader})
      }, 10000);
    }else{
      EventBus.publish("error", 'Phone Number Invalid')
    }
  }

  checkValidations=()=>{
    if(!this.state.formData.number) this.setState({isValidNumber:false})
    if(!this.state.formData.stateName) this.setState({isValidState:false})
    if(this.state.age < 18) this.setState({isValid:false})
    // if(this.state.formData.citizenshipLabel === 'United States' && !this.state.formData.securityNumber) this.setState({isValidSecurity:false})
  }

  handleFormChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });

    if(event.target.name === 'state'){
      if(event.target.value !== '' && event.target.value !== undefined ) this.setState({isValidState:true})
      else this.setState({isValidState:false})
    }
    if(event.target.name === 'address'){
      if(event.target.value !== '' && event.target.value !== undefined && !/[^a-zA-Z0-9-,.;'&/.()_#* ]/.test(event.target.value)) this.setState({isValidAddress:true})
      else this.setState({isValidAddress:false})
    }
    if(event.target.name === 'city'){
      if(event.target.value !== '' && event.target.value !== undefined && !/[^a-zA-Z ]/.test(event.target.value)) this.setState({isValidCity:true})
      else this.setState({isValidCity:false})
    }
    if(event.target.name === 'zipCode'){
      if(event.target.value !== '' && event.target.value !== undefined && !/[^a-zA-Z0-9]/.test(event.target.value)) this.setState({isValidZip:true})
      else this.setState({isValidZip:false})
    }
    if(event.target.name === 'otp'){
      if(event.target.value !== '' && event.target.value.length == 7 && event.target.value !== undefined) this.setState({isValidOTP:true})
      else this.setState({isValidOTP:false})
    }

  }

  handleFormChangeCountry = (event) => {
    if(event.target.value){
      let filteredCountry = ''
      let filteredCountries = CountryRegionData.filter(x=>{
        if(x[0] == event.target.value) filteredCountry = x
      })
      /// old function
      let splittedCountry = event.target.value.split(',');
      let regions = []
      // splittedCountry[2].split("|").map(regionPair=>{
      filteredCountry[2].split("|").map(regionPair=>{
        let [name, regionShortCode] = regionPair.split("~");
        if(name !== "American Samoa")
        regions.push({
          name: name,
          short: regionShortCode,
        });
      })

      let filteredCode = Countries.filter(({ name }) => name == event.target.value)
      let countryCode = filteredCode[0].phone
      this.setState({regions:regions, lockFields:false, countryShortCode:filteredCountry[1], countryCode:countryCode})
      const { formData } = this.state;
      formData[event.target.name] = event.target.value;
      this.setState({ formData });
    }
  }

  setCountryState =(e)=>{
    let filteredCountry = ''
    let filteredCountries = CountryRegionData.filter(x=>{
      if(x[0] == e.citizenship) filteredCountry = x
    })
    let regions = []

    filteredCountry[2].split("|").map(regionPair=>{
      let [name, regionShortCode] = regionPair.split("~");
      if(name !== "American Samoa")
      regions.push({
        name: name,
        short: regionShortCode,
      });
    })
    let filteredCode = Countries.filter(({ name }) => name == e.citizenship)
    let countryCode = filteredCode[0].phone
    this.setState({regions:regions, lockFields:false, countryShortCode:filteredCountry[1], countryCode:countryCode})
  }

  setDefaults =(e)=>{
    let filteredCountry = ''
    let filteredCountries = CountryRegionData.filter(x=>{
      if(x[0] == 'United States') filteredCountry = x
    })
    let regions = []
    filteredCountry[2].split("|").map(regionPair=>{
      let [name, regionShortCode] = regionPair.split("~");
      if(name !== "American Samoa")
      regions.push({
        name: name,
        short: regionShortCode,
      });
    })
    this.setState({regions:regions, lockFields:false,})
  }

  setDate = (e) => {
    this.setState({ selectedDate: new Date(e) })
    let today = new Date();
    let birthDate = new Date(e);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    const validFormat = moment(new Date(e), 'MM/DD/YYYY',true).isValid()
    var start = moment(today, "MM/DD/YYYY");
    var end = moment(e, "MM/DD/YYYY");
    let checkValid = moment.duration(start.diff(end)).asDays()
    if (!validFormat) this.setState({isValidFormat:false})
    else if (checkValid <= 6575){
      this.setState({isValidFormat:true});
      age--;
    }
    else this.setState({isValidFormat:true})
     this.setState({ age });
     if (age < '18') this.setState({ isValid: false })
    else this.setState({ isValid: true })
  }

  countryToFlag(isoCode) {
    return typeof String.fromCodePoint !== 'undefined'
      ? isoCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397))
      : isoCode;
  }

  goBack = () => {
    // let path = '/installment-plans/' + this.props.signData.hash
    // this.props.onBack(path);
    EventBus.publish("emptyTab", 'Empty Tab')
  }

  handlePhoneChange = (event, countryData)=>
  {
    let filteredCode = Countries.filter(({ name }) => name == this.state.formData.citizenshipLabel)
    let countryCode = filteredCode[0].phone
    // let number = event.replace(/-/g,"").replace(/ /g, "").replace("(","").replace(")","")
    let number = '+' + countryCode + parseInt(event.target.value, 10)
    let isValidPhoneNumber = validator.isMobilePhone(number, this.state.locale)
    this.isValidNumber = isValidPhoneNumber
    this.setState({isValidNumber:isValidPhoneNumber})
    const { formData } = this.state;
    formData['number'] = parseInt(event.target.value, 10);
    this.setState({ formData });
  }

  handleCategoryImage = e => {
    var imageValue = e.length - 1;
    this.setState({ imageValue: URL.createObjectURL(e[imageValue]) });
    var imageDetail = e[0];
    this.setState({ agreement: imageDetail });
  };

  handleDocumentList = e => {
    // console.log("e :: ", e);
    console.log('e is', e);
    var allDocuments = this.state.allDocuments
    var newList = e.map((x,idx,z)=>{
      if (idx === z.length - 1){
        allDocuments.push(x)
      }
    })
    this.setState({ allDocuments: allDocuments});
    this.handleDocumentFormating(allDocuments)
  };
  handleDocumentFormating = e =>{
    var documentListt = [];
    e.forEach((i, idx, x) => {
      documentListt[idx] = (
        <div className="singleImage" id={idx}>
          <p className="documentName">{i.name}</p>
          <button
            onClick={() => {
              this.handleDocumentListRemove(idx);
            }}
            style={{ fontSize: "14px" }}
            className="btn btn-danger ml-auto iconButton"
            >
            <i className="fa fa-trash" />
          </button>
        </div>
      );
    });
    this.setState({ documentList: documentListt, documentData: e });
  }

  handleDocumentListRemove = e => {
    console.log(e, this.state.documentList);
    var documentsList = this.state.documentList
    var allDocuments = this.state.allDocuments
    this.state.documentList.filter(value => {
      console.log('value', value);
      if(value.props.id == e){
        documentsList.splice(documentsList.indexOf(value), 1);
      }
    });
    this.state.allDocuments.filter((x,idx,z) => {
      if(idx == e){
        allDocuments.splice(allDocuments.indexOf(x), 1);
      }
    });
    console.log('documentsList', documentsList);
    this.setState({ documentList: documentsList, allDocuments:allDocuments });
  };

  handleCheck = (value, name)=>
  {
    console.log(name);
    console.log(value.target.name, value.target.value);
    const { formData } = this.state;
    formData[value.target.name] = name;
    this.setState({ formData });
  }

  handleRemoveImage = () => this.setState({ imageValue: "" });

  render() {
    const { country, region, isLoader } = this.state;
    return (
      <section>
              <div>
                <div className="pb-2">
                  <h5 >Trust Information:</h5>
                </div>
                <ValidatorForm className="validatorForm" onSubmit={this.submitContact}>
                  <div className="row">
                    <div className="col-12">
                      <TextValidator
                        className="MyTextField"
                        fullWidth
                        label="Trust Name"
                        error={!this.state.isValidTrustNaem}
                        onChange={this.handleFormChange}
                        name="trustName"
                        type="text"
                        margin="dense"
                        variant="outlined"
                        validators = {
                          ['required', 'matchRegexp:^[A-Za-z ]+$']
                        }
                        errormessages = {
                          ['Trust Name can not be empty',
                            "Special Chrachters and Numbers are not allowed"
                          ]
                        }
                        value={this.state.formData.trustName}
                        autoComplete='off'
                      />
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6 pt-3">
                      <FormControl variant="outlined"
                        label="Country of citizenshipLabel"
                        margin="dense"
                        className="MyTextField"
                        fullWidth
                      >
                        <InputLabel htmlFor="outlined-age-native-simple">
                          Trust Classification
                          </InputLabel>
                        <Select
                          native
                          error={!this.state.isValidTrustClassification}
                          labelWidth={this.state.labelWidth -22}
                          type="text"
                          name="trustClassification"
                          validators={['required']}
                          value={this.state.formData.trustClassification}
                          onChange={this.handleFormChange}
                          autoComplete='off'
                        >
                          <option value="" className="pl-2 selectOptions"></option>
                            <option value="Grantor" className="pl-2 selectOptions">
                              Grantor
                            </option>
                            <option value="Non-Grantor" className="pl-2 selectOptions">
                              Non-Grantor
                            </option>
                        </Select>
                      </FormControl>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-6 pt-3">

                      <FormControl variant="outlined"
                        label="State"
                        margin="dense"
                        className={this.state.lockFields ? "cursor-not-allowed MyTextField" :'MyTextField'}
                        fullWidth
                      >
                        <InputLabel htmlFor="outlined-age-native-simple">
                          Trust Type
                          </InputLabel>
                        <Select
                          native
                          labelWidth={this.state.labelWidth - 40}
                          type="text"
                          name="trustType"
                          value={this.state.formData.trustType}
                          onChange={this.handleFormChange}
                          validators={['required']}
                          autoComplete='off'
                        >
                        <option value="" className="pl-2 selectOptions"></option>
                          <option value="Revocable" className="pl-2 selectOptions">
                            Revocable
                          </option>
                          <option value="Irrevocable" className="pl-2 selectOptions">
                            Irrevocable
                          </option>
                          <option value="Testamentary" className="pl-2 selectOptions">
                            Testamentary
                          </option>
                        </Select>
                      </FormControl>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-6 pt-2">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          error={!this.state.isValid}
                          className="MyTextField"
                          fullWidth
                          margin="normal"
                          id="date-picker-dialog"
                          label="Date of Formation (MM/DD/YYYY)"
                          format="MM/dd/yyyy"
                          inputVariant="outlined"
                          value={this.state.selectedDate}
                          onChange={(selectedDate) => this.setDate(selectedDate)}
                          autoComplete='off'
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          />
                        {!this.state.isValidFormat ? <span className="errorMessage">Invalid Date Format</span> : ""}
                        {/*!this.state.isValid ? <span className="errorMessage">Age must be above 18</span> : ""*/}
                      </MuiPickersUtilsProvider>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-6 pt-3">

                      <FormControl variant="outlined"
                        label="State"
                        margin="dense"
                        className={this.state.lockFields ? "cursor-not-allowed MyTextField" :'MyTextField'}
                        fullWidth
                        >
                        <InputLabel htmlFor="outlined-age-native-simple">
                          Jurisdiction of Registeration
                        </InputLabel>
                        <Select
                          native
                          error={!this.state.isValidJuristdiction}
                          disabled={this.state.lockFields}
                          labelWidth={this.state.labelWidth + 40}
                          type="text"
                          name="juristdiction"
                          value={this.state.formData.juristdiction}
                          onChange={this.handleFormChange}
                          validators={['required']}
                          autoComplete='off'
                          >
                          <option value="" className="pl-2 selectOptions"></option>
                          {
                            this.state.regions.map(option =>{
                              return <option value={option.name} className="pl-2 selectOptions">
                                {option.name}
                              </option>
                            })
                          }
                        </Select>
                      </FormControl>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-6 pt-3">
                      <FormControl variant="outlined"
                        label="State"
                        margin="dense"
                        className={this.state.lockFields ? "cursor-not-allowed MyTextField" :'MyTextField'}
                        fullWidth
                        >
                        <InputLabel htmlFor="outlined-age-native-simple">
                          TIN Type
                        </InputLabel>
                        <Select
                          native
                          labelWidth={this.state.labelWidth - 40}
                          type="text"
                          name="tinType"
                          value={this.state.formData.tinType}
                          onChange={this.handleFormChange}
                          validators={['required']}
                          autoComplete='off'
                          >
                          <option value="" className="pl-2 selectOptions"></option>
                          <option value="EIN" className="pl-2 selectOptions">
                            EIN
                          </option>
                          <option value="SSN" className="pl-2 selectOptions">
                            SSN
                          </option>
                        </Select>
                      </FormControl>
                    </div>

                    {
                      this.state.formData.tinType == "EIN"
                      ?
                      <div className="col-sm-12 col-md-12 col-lg-12 pt-1 group-form">
                        <div className="pt-1">
                          <h5 >Tax Reporting Information:</h5>
                          <div className="pt-2 pb-1 pl-1 formBlurHeading">
                            This information is required by the IRS for tax reporting purposes.<br />
                          We take every step to keep your personal information safe.  Investor personal information is encrypted and stored in a secure vault away from business systems. We are General Data Protection Regulation (GDPR) and California Consumer Privacy Act (CCPA) compliant. To view our Privacy Policy <Link className="click-here" href='/privacy-policy' target='_blank' >click here</Link>
                              </div>
                          <div className="pb-1 pl-1 formBoldHeading">
                            EIN
                              </div>
                        </div>
                      <div className="col-sm-12 col-md-12 col-lg-8 pt-1 group-form d-flex align-items-center">
                        <NumberFormat label="SSN" error={!this.state.isValidSecurityNumber} validators={['required']} placeholder="    __-_______    " variant="outlined" name='tinNumber' value={this.state.formData.tinNumber} onChange={this.handleFormChange} className='numberFormat-Form mt-0' format="##-#######" />
                        </div>
                      </div>
                      :
                      <div className="col-sm-12 col-md-12 col-lg-12 pt-1 group-form">
                        <div className="pt-1">
                          <h5 >Tax Reporting Information:</h5>
                          <div className="pt-2 pb-1 pl-1 formBlurHeading">
                            This information is required by the IRS for tax reporting purposes.<br />
                          We take every step to keep your personal information safe.  Investor personal information is encrypted and stored in a secure vault away from business systems. We are General Data Protection Regulation (GDPR) and California Consumer Privacy Act (CCPA) compliant. To view our Privacy Policy <Link className="click-here" href='/privacy-policy' target='_blank' >click here</Link>
                              </div>
                          <div className="pb-1 pl-1 formBoldHeading">
                            Social Security Number
                              </div>
                        </div>
                      <div className="col-sm-12 col-md-12 col-lg-8 pt-1 group-form d-flex align-items-center">
                        <NumberFormat label="SSN" error={!this.state.isValidSecurityNumber} validators={['required']} placeholder="    ___-__-____    " variant="outlined" name='tinNumber' value={this.state.formData.tinNumber} onChange={this.handleFormChange} className='numberFormat-Form mt-0' format="###-##-####" />
                        </div>
                      </div>
                    }

                    <div className="col-sm-12 col-md-12 col-lg-12 pt-1 group-form">
                      <div className="pt-1">
                        <h5 >Trust Agreement:</h5>
                          <div className="pb-1 pl-1 formBoldHeading">
                            Please upload the Trust Agreement.
                          </div>
                          <div>
                            <Files
                              style={{ backgroundColor: "#f4f4f4", height: '50px'}}
                              onChange={e => this.handleDocumentList(e)}
                              onError={this.onFilesError}
                              className="dropZone"
                              accepts={["application/pdf"]}
                              maxFileSize={100000000}
                              minFileSize={0}
                              clickable
                            >
                              <div>
                                <p>
                                  Drop the Property Documents here or click to upload it.
                                </p>
                                <p>
                                  Recommended maximum <strong>Size</strong> is{" "}
                                  <strong>25MB</strong>.
                                </p>
                              </div>
                            </Files>
                            </div>

                            <div className="imageListContainer">
                              {this.state.documentList}
                            </div>

                        <div className="pt-2 pb-1 pl-1 formBlurHeading">
                          <FormControlLabel
                            value={this.state.uploadLater}
                            onChange={(value, name) => this.handleCheck(value, name)}
                            className="formHeading"
                            control={<Checkbox color="primary" />}
                            labelPlacement="end"
                            validators={['required']}
                            errormessages={['Please agree to the terms']}
                            name="uploadLater"
                            label="I will provide the Trust Agreement later. I acknowledge that my investment will not be processed until i provide this document."
                            />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-12 pt-1 group-form">
                      <div className="pt-1">
                        <h5 >Trust Address and Phone Number:</h5>
                          <TextValidator
                            className="MyTextField"
                            fullWidth
                            label="Address Line 1"
                            onChange={this.handleFormChange}
                            name="address"
                            type="text"
                            margin="dense"
                            variant="outlined"
                            validators={['required']}
                            errormessages={['City can not be empty']}
                            value={this.state.formData.address}
                            autoComplete='off'
                          />
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-6 pt-3">
                    <TextValidator
                      className="MyTextField"
                      fullWidth
                      label="City"
                      onChange={this.handleFormChange}
                      name="city"
                      type="text"
                      error={!this.state.isValidCity}
                      margin="dense"
                      variant="outlined"
                      validators={['required']}
                      errormessages={['City can not be empty']}
                      value={this.state.formData.city}
                      autoComplete='off'
                    />
                  </div>

                  <div className="col-sm-12 col-md-12 col-lg-6 pt-3">

                    <FormControl variant="outlined"
                      label="State"
                      margin="dense"
                      className={this.state.lockFields ? "cursor-not-allowed MyTextField" :'MyTextField'}
                      fullWidth
                    >
                      <InputLabel htmlFor="outlined-age-native-simple">
                        State
                        </InputLabel>
                      <Select
                        native
                        error={!this.state.isValidState}
                        disabled={this.state.lockFields}
                        labelWidth={this.state.labelWidth - 40}
                        type="text"
                        name="stateName"
                        value={this.state.formData.stateName}
                        onChange={this.handleFormChange}
                        validators={['required']}
                        autoComplete='off'
                      >
                      <option value="" className="pl-2 selectOptions"></option>
                      {
                        this.state.regions.map(option =>{
                          return <option value={option.name} className="pl-2 selectOptions">
                            {option.name}
                          </option>
                        })
                      }
                      </Select>
                    </FormControl>
                  </div>

                    <div className="col-sm-12 col-md-12 col-lg-6 pt-3">
                      <TextValidator
                        className="MyTextField"
                        fullWidth
                        inputProps={{ maxLength:20 }}
                        label="Zip/Postal code"
                        onChange={this.handleFormChange}
                        error={!this.state.isValidZip}
                        name="zipCode"
                        type="text"
                        margin="dense"
                        variant="outlined"
                        validators={['required']}
                        errormessages={['Zip Code can not be empty']}
                        value={this.state.formData.zipCode}
                        autoComplete='off'
                      />
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-8 pt-4 ">
                      <FormControl variant="outlined">
                        <InputLabel>
                          Phone
                        </InputLabel>
                        <OutlinedInput
                          className="signup-phone"
                          id="outlined-error-helper-text"
                          fullWidth
                          error={!this.state.isValidNumber}
                          label="number"
                          labelWidth={35}
                          name="number"
                          type="number"
                          margin="dense"
                          validators={['isNumberValid', 'required']}
                          value={this.state.formData.number}
                          onChange={this.handlePhoneChange}
                          errormessages={['Number can not be empty']}
                          autoComplete='off'
                          startAdornment={<InputAdornment position="start"
                            >
                            {this.state.countryCode} {this.countryToFlag(this.state.countryShortCode)}
                          </InputAdornment>}
                          endAdornment={
                            <InputAdornment position="end">
                                            <button type="button" onClick={this.signUpOtp} className="custom-btn-otp" disabled={isLoader} >{isLoader ? <i className="fa fa-spinner fa-spin fa-4x fa-fw" style={{fontSize:"24px" }} /> : "Verify"}</button>
                            </InputAdornment>}

                          aria-describedby="outlined-weight-helper-text"
                          />
                          {!this.state.isValidNumber ? <span className="errorMessage">Invalid Number</span>:''}
                      </FormControl>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-4 pt-3 group-form">
                      <NumberFormat error={!this.state.isValidOTP} validators={['required']} placeholder=" OTP " variant="outlined" name='otp' value={this.state.formData.otp}
                        onChange={this.handleFormChange} className=' numberFormat-Form numberFormat-OTP' format="#-#-#-#" />
                    </div>

                  </div>
                  <div className="row pt-3">

                    <div className="col-sm-12 col-md-4 col-lg-8 pt-3">
                      <div className="loginBtnDiv">
                        <div className="outer-box" >
                          <button type="button" name="skipButton" value="skipButton" onClick={() => this.goBack()} className="thm-btn btn-style-one-outline mr-1" disabled={this.state.isDisabled}>
                            {!this.state.isDisabled ? 'Back' : <i className="fa fa-spinner fa-spin fa-1x fa-fw" />}
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-4 pt-3">
                      <div className="loginBtnDiv">
                        <div className="outer-box" >
                          <button className="thm-btn btn-style-one mr-1" onClick={this.checkValidations} disabled={this.state.isDisabled}>
                            {!this.state.isDisabled ? 'Continue' : <i className="fa fa-spinner fa-spin fa-1x fa-fw" />}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                </ValidatorForm>
              </div>

      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.Auth.auth,
    signData: state.Auth.signData ? state.Auth.signData.info : [],
    allSignData: state.Auth.signData
  }
}

const mapDispatchToProps = { loginRequest, trustSignInfo, logout, sendSMSSignup };
export default connect(mapStateToProps, mapDispatchToProps)(Trust);

import EventBus from "eventing-bus";
import { connect } from "react-redux";
import React, { Component } from "react";
import { PlaidLink } from "react-plaid-link";
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import { plaidInfo } from '../../../../store/actions/Auth';
import { autoPlaid } from '../../../../store/actions/Wallet';

class CardDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {
      manualAdd: false,
      isValidName: true,
    };
    props.plaidInfo();
  }

  handleChange = event => {
    if (event.target.name == 'bankName') {
      if (event.target.value !== '' && event.target.value !== undefined && !/[^a-zA-Z ]/.test(event.target.value)) {
        this.setState({ isValidName: true })
        this.props.onChange(event, true);
      }
      else {
        this.setState({ isValidName: false })
        this.props.onChange(event, false);
      }
    } else this.props.onChange(event, this.state.isValidName);
  }

  onSuccess = (public_token, metadata) => {
    // this.setState({isDisabled: true});
    // metadata['hash'] = this.props.signData.hash;
    this.props.autoPlaid(metadata);
  }

  onExit = (e) => EventBus.publish("error", `Exited from plaid without payment`);

  render() {
    let { plaidData } = this.props;
    console.log('plaidData', plaidData);
    return (
      <div className="col-lg-6 col-md-12 col-12">
        <div className="row clearfix">
          <p
            hidden={this.props.backOption}
            onClick={() => {
              this.props.goBack();
            }}
          >
            <i
              className="fa fa-arrow-left text-secondary pt-1"
              aria-hidden="true"
            />
          </p>
          <div className="group-form col-lg-7 col-md-12">
            <label>Select Bank</label>
          </div>

          <div className="group-form col-lg-12 col-md-12">
            <div className="title-area row">
              <div className="col-md-12">
                <div className="group-form">
                  <label>To add your bank instantaneously use our Add Automatically feature </label>
                </div>
                <div className="col-md-6 offset-lg-3 offset-md-3">
                  {
                    plaidData && plaidData['link_token'] &&
                    <PlaidLink
                      token={plaidData['link_token']}
                      onSuccess={this.onSuccess}
                      type="button"
                      style={{ border: "0px !important" }}
                    >
                      <button type="button" href="#" className="pt-1 custom-btn">
                        <i className="fa fa-plus" />
                        &nbsp; Add Automatically
                      </button>
                    </PlaidLink>
                  }
                </div>
              </div>
            </div>
            <hr />
            <ValidatorForm className="validatorForm" onSubmit={this.props.onSubmit}>
              <div className="row">
                <div className="group-form col-lg-12 col-md-12">
                  <label>Adding a bank manually will take 2-3 working days for verification </label>
                </div>
                <div className="group-form col-lg-6 col-md-12">
                  <FormControl variant="outlined"
                    label="Account Type"
                    id="select"
                    margin="dense"
                    className="MyTextField"
                    fullWidth
                  >
                    <InputLabel htmlFor="outlined-age-native-simple">
                      Account Type
                    </InputLabel>
                    <Select
                      type="text"
                      name="accountType"
                      id="select"
                      labelWidth={100}
                      onChange={this.handleChange}
                      value={this.props.formData.accountType}
                    >
                      <li value="savings" className="p-2 selectOptions">Savings</li>
                      <li value="checking" className="p-2 selectOptions">Checking</li>
                    </Select>
                  </FormControl>
                </div>
                <div className="group-form col-lg-6 col-md-12">
                  <TextValidator
                    fullWidth
                    className="MyTextField2"
                    onChange={this.handleChange}
                    name="routingNo"
                    type="number"
                    label="Routing Number"
                    margin="dense"
                    variant="outlined"
                    validators={['required']}
                    errormessages={['Routing number can not be empty']}
                    value={this.props.formData.routingNo}
                  />
                </div>
              </div>
              <div className="row">
                <div className="group-form col-lg-6 col-md-12">
                  <TextValidator
                    fullWidth
                    maxlength="19"
                    minlength="4"
                    className="MyTextField2"
                    onChange={this.handleChange}
                    name="accountNo"
                    label="Account Number"
                    type="number"
                    margin="dense"
                    variant="outlined"
                    validators={['required']}
                    value={this.props.formData.accountNo}
                  />
                </div>

                <div className="group-form col-lg-6 col-md-12">
                  <TextValidator
                    fullWidth
                    maxlength="19"
                    minlength="4"
                    className="MyTextField2"
                    onChange={this.handleChange}
                    name="confirmAccountNo"
                    label="Confirm Account Number"
                    type="number"
                    margin="dense"
                    variant="outlined"
                    validators={['required']}
                    value={this.props.formData.confirmAccountNo}
                  />
                  {/*
                    <i className="ok-icon-img">
                      <img
                        src={require("../../../../../theme/images/icons/50.png")}
                        alt=""
                        />
                    </i>
                  */}

                </div>
              </div>
              <div className="row">
                <div className="group-form col-lg-12 col-md-12">
                  <TextValidator
                    fullWidth
                    placeholder="John Doe"
                    className="MyTextField2"
                    onChange={this.handleChange}
                    name="bankName"
                    error={!this.state.isValidName}
                    label="Account Holder Name"
                    type="text"
                    margin="dense"
                    variant="outlined"
                    validators={['required']}
                    value={this.props.formData.bankName}
                  />
                </div>
              </div>
              <button
                className="thm-btn btn-style-one"
              >
                Add Account
              </button>
            </ValidatorForm>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let { plaidData } = state.Auth;
  return { plaidData };
}

const mapDispatchToProps = { plaidInfo, autoPlaid };

export default connect(mapStateToProps, mapDispatchToProps)(CardDetails);

import { connect } from "react-redux";
import React, { Component } from "react";
import ListingBanner from "../ListingBanner";
import AppLoader from '../../components/AppLoader';
import SinglePropertyItem from "../SinglePropertyItem";
import { otpLoader } from "../../store/actions/Settings";
import { favouriteProperties } from "../../store/actions/Auth";

class FeaturedListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryData: []
    };
    this.props.otpLoader(true)
    this.props.favouriteProperties({ status: "launched" });
  }

  requestProperties(value, type) {
    let categoryData = [];
    let { listedProperties } = this.props;
    if (type === "stage" && listedProperties)
      categoryData =
        listedProperties &&
        Array.isArray(listedProperties) &&
        listedProperties.filter(({ stage }) => stage == value);
    else if (type === "categories" && listedProperties)
      categoryData =
        listedProperties &&
        Array.isArray(listedProperties) &&
        listedProperties.filter(({ categories }) => categories == value);
    else categoryData = listedProperties;
    this.setState({ categoryData });
  }

  handleStagePress = e => this.requestProperties(e, "stage");

  componentWillReceiveProps({ listedProperties, filteredList }) {
    if (Array.isArray(filteredList)) {
      this.setState({ categoryData: filteredList });
    }
    else if (Array.isArray(listedProperties))
      this.setState({ categoryData: listedProperties });
  }


  render() {
    return (
      <section className={`listing-sec ${this.props.path == '/listing' ? "feature-listing" : ""}`}>
        <div className="container">
          <div className="text-center" hidden={this.props.hidden}>
            <h2
              data-animation-child
              className="title-fill"
              data-animation="title-fill-anim"
              data-text="About Company"
            >
              Featured Listings
            </h2>
            <div className="Rectangle_17_copy_2"></div>
            {/* <p className="pt-2">
              Invest in an asset of your choice across a variety of investment &
              Asset classes:
            </p> */}
          </div>
          <div className="row">
            {this.props.path != '/listing' &&
              <div className="col-12" hidden={this.props.hidden}>
                <ListingBanner handleStagePress={this.handleStagePress} />
              </div>
            }
            <div className="col-12">
              <div className="d-none d-md-block d-lg-block d-xl-block col-lg-12" >
                <ul
                  className="nav nav-tabs"
                  id="myTab"
                  role="tablist"
                  hidden={this.props.hidden}
                >
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="home-tab"
                      data-toggle="tab"
                      onClick={() =>
                        this.requestProperties("Office", "categories")
                      }
                      href="#tab1"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      <span>Office</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="profile-tab"
                      data-toggle="tab"
                      onClick={() =>
                        this.requestProperties("Multifamily", "categories")
                      }
                      href="#tab2"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                    >
                      <span>Multifamily</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="contact-tab"
                      data-toggle="tab"
                      onClick={() =>
                        this.requestProperties("Mixed Use", "categories")
                      }
                      href="#tab3"
                      role="tab"
                      aria-controls="contact"
                      aria-selected="false"
                    >
                      <span>Mixed Use</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="contact-tab"
                      data-toggle="tab"
                      onClick={() =>
                        this.requestProperties("Hospitality", "categories")
                      }
                      href="#tab4"
                      role="tab"
                      aria-controls="contact"
                      aria-selected="false"
                    >
                      <span>Hospitality</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="contact-tab"
                      data-toggle="tab"
                      onClick={() =>
                        this.requestProperties("Retail", "categories")
                      }
                      href="#tab5"
                      role="tab"
                      aria-controls="contact"
                      aria-selected="false"
                    >
                      <span>Retail</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="contact-tab"
                      data-toggle="tab"
                      onClick={() =>
                        this.requestProperties("Warehouse", "categories")
                      }
                      href="#tab6"
                      role="tab"
                      aria-controls="contact"
                      aria-selected="false"
                    >
                      <span>Warehouse</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="contact-tab"
                      data-toggle="tab"
                      onClick={() =>
                        this.requestProperties("Industrial", "categories")
                      }
                      href="#tab7"
                      role="tab"
                      aria-controls="contact"
                      aria-selected="false"
                    >
                      <span>Industrial</span>
                    </a>
                  </li>
                </ul>
              </div>
              {
                this.props.showOtpLoader
                  ?
                  <AppLoader className="mt-5" />
                  :
                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="tab1"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    >
                      <div className="row clearfix">
                        {/* {this.state.categoryData &&
                      Array.isArray(this.state.categoryData) &&
                      this.state.categoryData.length !== 0
                        ?  */}
                        {this.state.categoryData && this.state.categoryData.map((item, index) => (
                          <SinglePropertyItem
                            {...this.props}
                            item={item}
                            key={index}
                          />
                        ))}
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-10">
                          <div className="inner-box-listing">
                            <div className="image-box">
                              <figure>
                                <img
                                  src="https://tknismtest.s3.amazonaws.com/non-compressed-images/3.jpg"
                                  alt=""
                                />
                              </figure>

                              {/*
                              <div className="overlay-box">
                                <div className="catgory-listing">Sale</div>
                              </div>
                               */}

                            </div>
                            <div className="content-area">
                              <h3>Coming Soon</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

              }
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  let { listedProperties } = state.Auth;
  let { showOtpLoader } = state.Settings;
  return { listedProperties, showOtpLoader };
};

const mapDispatchToProps = { favouriteProperties, otpLoader };

export default connect(mapStateToProps, mapDispatchToProps)(FeaturedListing);

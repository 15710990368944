import { connect } from "react-redux";
import React, { Component } from "react";
import { Modal, ModalBody } from "reactstrap";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormHelperText from '@material-ui/core/FormHelperText';

class ExistingAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: "",
      selectedItemIndex: 0,
      isOpen: false,
      formData: {
        amount1: '',
        amount2: '',
        bankId: ''
      },
      verifyState: {
        failed: {
          color: 'red',
          icon: 'fa-exclamation-circle',
          tag: 'Deposit Verification Failed',
        },
        unverified: {
          color: 'orange',
          tag: 'Awaiting Verification',
          icon: 'fa-exclamation-circle',
        },
        verified: {
          color: 'green',
          tag: 'Verified',
          icon: 'fa-check',
        }
      }
    };
  }

  toggleClass = (id, item) => {
    this.setState({ selectedItemIndex: id });
    this.props.selectedCard(item);
  };

  handleDelete = (e) => this.props.onDelete(e)

  componentDidMount() {
    if (this.props.userBanks && this.props.userBanks.length > 0) this.toggleClass(0, this.props.userBanks[0])
  }

  openModal = (bankId) => {
    let { isOpen, formData } = this.state;
    this.setState({ isOpen: !isOpen, bankId: bankId })
  }

  handleMicroVerification = () => {
    let { amount1, amount2, isOpen } = this.state;
    let data = {
      amount1: amount1,
      amount2: amount2,
      bankId: this.state.bankId
    }
    if (amount1 && amount2) {
      this.props.verifyMicro(data);
      this.setState({ isOpen: !isOpen, amount1: '', amount2: '', bankId: '' })
    }
  }

  render() {
    let { verifyState, isOpen, amount1, amount2 } = this.state;
    let { openModal, handleMicroVerification } = this;
    return (
      <div className="col-lg-6 col-md-12 col-12">
        <Modal
          modalClassName="modal-mini modal-info uploaded"
          toggle={openModal}
          isOpen={isOpen}
        >
          <div className="modal-header justify-content-center">
            <div className="modal-profile">
              <img className='modalLogoImg' src="https://tknismtest.s3.amazonaws.com/non-compressed-images/Logo0.png" alt="" />
            </div>
            <div className="right">
              <img
                onClick={openModal}
                className="modalLogoImg"
                src="https://tknismtest.s3.amazonaws.com/non-compressed-images/close-icon.png"
                alt=""
              />
            </div>
          </div>
          <ModalBody>
            <div className="pt-3">
              <h5 className="title">Micro Payment Verification</h5>
              <p className="para">We sent two micro payments in your account. Verify them to let us know that the manually added Bank Account is yours. You have three attempts to verify your account.</p>
            </div>
            <div className="row pt-3">

              <div className="col-lg-6 col-md-6 col-12 float-right">
                <FormControl variant="outlined">
                  <InputLabel>
                    Deposit 1
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-error-helper-text"
                    error={amount1 > 5000}
                    className="MyTextField2"
                    label="amount"
                    labelWidth={60}
                    name="amount1"
                    type="text"
                    validators={['required']}
                    value={amount1}
                    onChange={e => this.setState({ amount1: e.target.value })}
                    startAdornment={<InputAdornment position="start"
                    >
                      $
                    </InputAdornment>}
                    aria-describedby="outlined-weight-helper-text"
                  />
                </FormControl>
              </div>
              <div className="col-lg-6 col-md-6 col-12 float-left">
                <FormControl variant="outlined">
                  <InputLabel>
                    Deposit 2
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-error-helper-text"
                    error={amount2 > 5000}
                    className="MyTextField2"
                    label="amount"
                    labelWidth={60}
                    name="amount2"
                    type="text"
                    validators={['required']}
                    value={amount2}
                    onChange={e => this.setState({ amount2: e.target.value })}
                    startAdornment={<InputAdornment position="start"
                    >
                      $
                    </InputAdornment>}
                    aria-describedby="outlined-weight-helper-text"
                  />
                </FormControl>
              </div>
            </div>
          </ModalBody>
          <div className="modal-footer">
            <button type="button" onClick={handleMicroVerification} className="custom-btn-full">Verify</button>
          </div>
        </Modal>
        <div className="title-area row">
          <div className="col-md-1">
            <p onClick={() => this.props.onSelectMethod("none")}>
              <i className="fa fa-arrow-left text-secondary" aria-hidden="true" />
            </p>
          </div>
          <div className="col-md-7">
            <h5 className="pt-1">Your Banks</h5>
          </div>
        </div>
        <div className="title-area row" style={{ cursor: "pointer" }}>
          <div className="col-md-7">
            <label className="greyText">Please Select Your Bank</label>
          </div>
          <div className="col-md-4">
            <a className="pt-1" onClick={() => this.props.addCard()}>
              <i className="fa fa-plus" />
              Add Account
            </a>
          </div>
        </div>

        <div className="existingCards">
          {this.props.userBanks.length !== 0
            ? this.props.userBanks.map((item, index) => {
              return (
                <div
                  className={
                    this.state.selectedItemIndex === index
                      ? "card-information bank-info active"
                      : "bank-info card-information"
                  }
                  key={index}
                  onClick={() => this.toggleClass(index, item)}
                >
                  <div className="text-area">
                    {item['status'] === 'unverified' &&
                      <button className="pt-1 custom-btn" onClick={() => this.openModal(item['_id'])}>
                        Verify
                      </button>
                    }
                    {item['status'] &&
                      <p style={{ color: verifyState[item['status']]['color'] }}>
                        <i class={"fa " + verifyState[item['status']]['icon']} aria-hidden="true"></i>
                        {'   '}
                        {verifyState[item['status']]['tag']}

                      </p>
                    }
                    <h4>**** {item.routing.substr(item.routing.length - 4)}</h4>
                    <p>Routing No: {item.routing}</p>
                  </div>
                  <div className="detail-area">
                    <p></p>
                    <h5>{item.name}</h5>
                    <p>{item.category}</p>
                  </div>
                  <div className='bank-delete-icon'>
                    <i className="fa fa-trash" onClick={() => this.handleDelete(item._id)}></i>
                  </div>
                </div>
              );
            })
            : null}
        </div>
        {/* <div className="card-information active">
                    <div className="icon-box">
                        <figure><img src={require("../../../../../theme/images/icons/52.png")} alt="" /></figure>
                    </div>
                    <div className="text-area">
                        <h4>4670 ****  ****  9065   </h4>
                        <p>Card number</p>
                    </div>
                    <div className="detail-area">
                        <a href="#">...</a>
                    </div>
                </div> */}
      </div>
    );
  }
}
export default connect(null, null)(ExistingAccount);

import React, { Component } from 'react';
import { setHash } from '../../../store/axios';
import moment from 'moment';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import FormControl from '@material-ui/core/FormControl';
import { loginRequest, contactSignInfo, logout, sendSMSSignup, setAccountType } from '../../../store/actions/Auth';
import { connect } from 'react-redux';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import NumberFormat from 'react-number-format';
import InputLabel from '@material-ui/core/InputLabel';

import Select from '@material-ui/core/Select';
import Countries from "../../../DataFiles/Countries";
import Geocode from "react-geocode";
import EventBus from 'eventing-bus';
import { CountryRegionData } from 'react-country-region-selector';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import validator from 'validator';
import ReactGA from 'react-ga';
import { Link } from "react-router-dom";


import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

import CheckBox from './../../../components/CheckBox/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import Individual from './Individual';
import Trust from './Trust';
import Joint from './Joint';
import Entity from './Entity';

class BasicInfo2 extends Component {
  constructor() {
    super()
    this.state = {
      // activeTab: "Individual",
      type: "Individual",
      pageContent: "",
      isLoader:false,
      age:16,
      isDisabled: false,
      agreeCheck: false,
      isValid: true,
      isValidNumber:true,
      selectedDate: new Date('2004-08-18T21:11:54'),
      labelWidth: 90,
      lockFields: true,
      isValidFormat:true,
      country:'',
      isValidCitizenship:true,
      isValidState:true,
      isValidSecurity:true,
      isValidCity:true,
      isValidZip:true,
      isValidOTP:true,
      isValidAddress:true,
      countryCode:'+1',
      countryShortCode:'US',
      regions:[],
      statesToRemove:[{name:'American Samoa'}, {name: 'District of Columbia'}],
      locale:['ar-AE', 'ar-BH', 'ar-DZ', 'ar-EG', 'ar-IQ', 'ar-JO', 'ar-KW', 'ar-SA', 'ar-SY', 'ar-TN','be-BY', 'bg-BG', 'bn-BD', 'cs-CZ', 'de-DE', 'de-AT', 'da-DK',
      'el-GR', 'en-AU', 'en-CA', 'en-GB', 'en-GG', 'en-GH', 'en-HK', 'en-MO', 'en-IE', 'en-IN', 'en-KE', 'en-MT', 'en-MU', 'en-NG', 'en-NZ', 'en-RW', 'en-SG', 'en-UG', 'en-US', 'en-TZ',
      'en-ZA', 'en-ZM', 'en-PK', 'es-EC', 'es-ES', 'es-MX', 'es-PA', 'es-PY', 'es-UY', 'et-EE', 'fa-IR', 'fi-FI', 'fj-FJ', 'fr-FR', 'fr-GF', 'fr-GP', 'fr-MQ', 'fr-RE', 'he-IL', 'hu-HU',
      'id-ID', 'it-IT', 'ja-JP', 'kk-KZ', 'ko-KR', 'lt-LT', 'ms-MY', 'nb-NO', 'ne-NP', 'nl-BE', 'nl-NL', 'nn-NO', 'pl-PL', 'pt-PT', 'pt-BR', 'ro-RO', 'ru-RU', 'sl-SI', 'sk-SK', 'sr-RS',
      'sv-SE', 'th-TH', 'tr-TR', 'uk-UA', 'vi-VN', 'zh-CN', 'zh-HK', 'zh-MO', 'zh-TW'],
      formData: {
        accountType:'',
        city: '',
        residenceLabel: "",
        state: " ",
        stateName: "",
        citizenshipLabel: 'United States',
        address: '',
        number:'',
        otp:''
      }
    }
    window.scrollTo(0, 0);
  }

  componentDidMount() {
    EventBus.on("BasicInfo2", this.handleAccountType);
    EventBus.on("emptyTab", this.emptyTab);
    console.log('this.props', this.props.allSignData);
    if (this.props.allSignData.step2 && Object.keys(this.props.allSignData.step2).length > 0) {
      const { formData } = this.state;
      let myStep = this.props.allSignData.step2
      formData['city'] = myStep.city;
      formData['stateName'] = myStep.state;
      formData['address'] = myStep.address;
      formData['zipCode'] = myStep.zipCode;
      formData['securityNumber'] = myStep.securityNumber;
      formData['residenceLabel'] = myStep.residence;
      formData['citizenshipLabel'] = myStep.citizenship;
      formData['number'] = myStep.phoneNo;
      this.setState({ formData, selectedDate : myStep.dob });
      this.setCountryState(myStep)
    }else{
      this.setDefaults()
    }
    ValidatorForm.addValidationRule('isNumberValid', (value) => {
      if (value) {
        return false;
      }
      return true;
    });
    ReactGA.pageview('/signup-2');
  }

  selectCountry (val) {
    this.setState({ country: val });
  }

  selectRegion (val) {
    this.setState({ region: val });
  }

  submitContact = () => {
    setHash(this.props.signData);
    const { formData } = this.state;
    if(formData.citizenshipLabel && formData.stateName && this.state.isValidNumber && this.state.isValidCity && this.state.isValidAddress && this.state.isValidZip && this.state.isValidOTP ){
      const citizenship = Countries.find(({ name }) => name == this.state.formData.citizenshipLabel)
      const state = this.state.regions.find(({ name }) => name == this.state.formData.stateName)
      var smsVerifyKey = this.state.formData.otp.replace(/-/g, "");
      let phoneNo = '+' + citizenship.phone + this.state.formData.number
      let phoneCode = '+' + citizenship.phone
      formData['citizenship'] = {name: citizenship.name, short:citizenship.short};
      formData['state'] = state;
      formData['dob'] = this.state.selectedDate;
      formData['hash'] = this.props.signData.hash ? this.props.signData.hash : this.props.paramsHash;
      formData['smsVerifyKey'] = smsVerifyKey;

      if (this.state.age >= 18) {
        formData['phoneNo'] = phoneNo
        formData['phoneCode'] = phoneCode
        this.setState({ isDisabled: true })
        this.props.contactSignInfo(this.state.formData)
        formData['otp'] = ''
      } else this.setState({ isValid: false })
    }else{
      if(formData.citizenshipLabel) this.setState({isValidCitizenship:true})
      else this.setState({isValidCitizenship:false})
      if(formData.stateName) this.setState({isValidState:true})
      else this.setState({isValidState:false})
      if(this.state.isValidNumber) this.setState({isValidNumber:true})
      else this.setState({isValidNumber:false})
      if(this.state.isValidOTP) this.setState({isValidOTP:true})
      else this.setState({isValidOTP:false})
      // if(this.state.isValidOTP && this.state.formData.otp.length == 7) this.setState({isValidOTP:true})
      // else {
      //   console.log('********', this.state.isValidOTP, this.state.formData.otp.length);
      //   this.setState({isValidOTP:false})
      //   EventBus.publish("error", "Enter a valid verification code.")
      // }
    }
  }

  signUpOtp = () => {
    if(this.state.isValidNumber && this.state.formData.number){
      this.setState({isLoader:!this.state.isLoader})
      const citizenship = Countries.find(({ name }) => name == this.state.formData.citizenshipLabel)
      let phoneNo = '+' + citizenship.phone + this.state.formData.number
      this.props.sendSMSSignup({hash:this.props.signData.hash, phone:phoneNo})
      setTimeout(() => {
        this.setState({isLoader:!this.state.isLoader})
      }, 10000);
    }else{
      EventBus.publish("error", 'Phone Number Invalid')
    }
  }

  checkValidations=()=>{
    if(!this.state.formData.number) this.setState({isValidNumber:false})
    if(!this.state.formData.stateName) this.setState({isValidState:false})
    if(this.state.age < 18) this.setState({isValid:false})
    // if(this.state.formData.citizenshipLabel === 'United States' && !this.state.formData.securityNumber) this.setState({isValidSecurity:false})
  }

  handleFormChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });

    if(event.target.name === 'state'){
      if(event.target.value !== '' && event.target.value !== undefined ) this.setState({isValidState:true})
      else this.setState({isValidState:false})
    }
    if(event.target.name === 'address'){
      if(event.target.value !== '' && event.target.value !== undefined && !/[^a-zA-Z0-9-,.;'&/.()_#* ]/.test(event.target.value)) this.setState({isValidAddress:true})
      else this.setState({isValidAddress:false})
    }
    if(event.target.name === 'city'){
      if(event.target.value !== '' && event.target.value !== undefined && !/[^a-zA-Z ]/.test(event.target.value)) this.setState({isValidCity:true})
      else this.setState({isValidCity:false})
    }
    if(event.target.name === 'zipCode'){
      if(event.target.value !== '' && event.target.value !== undefined && !/[^a-zA-Z0-9]/.test(event.target.value)) this.setState({isValidZip:true})
      else this.setState({isValidZip:false})
    }
    if(event.target.name === 'otp'){
      if(event.target.value !== '' && event.target.value.length == 7 && event.target.value !== undefined) this.setState({isValidOTP:true})
      else this.setState({isValidOTP:false})
    }

  }

  handleFormChangeCountry = (event) => {
    if(event.target.value){
      let filteredCountry = ''
      let filteredCountries = CountryRegionData.filter(x=>{
        if(x[0] == event.target.value) filteredCountry = x
      })
      /// old function
      let splittedCountry = event.target.value.split(',');
      let regions = []
      // splittedCountry[2].split("|").map(regionPair=>{
      filteredCountry[2].split("|").map(regionPair=>{
        let [name, regionShortCode] = regionPair.split("~");
        if(name !== "American Samoa")
        regions.push({
          name: name,
          short: regionShortCode,
        });
      })

      let filteredCode = Countries.filter(({ name }) => name == event.target.value)
      let countryCode = filteredCode[0].phone
      this.setState({regions:regions, lockFields:false, countryShortCode:filteredCountry[1], countryCode:countryCode})
      const { formData } = this.state;
      formData[event.target.name] = event.target.value;
      this.setState({ formData });
    }
  }

  setCountryState =(e)=>{
    let filteredCountry = ''
    let filteredCountries = CountryRegionData.filter(x=>{
      if(x[0] == e.citizenship) filteredCountry = x
    })
    let regions = []

    filteredCountry[2].split("|").map(regionPair=>{
      let [name, regionShortCode] = regionPair.split("~");
      if(name !== "American Samoa")
      regions.push({
        name: name,
        short: regionShortCode,
      });
    })
    let filteredCode = Countries.filter(({ name }) => name == e.citizenship)
    let countryCode = filteredCode[0].phone
    this.setState({regions:regions, lockFields:false, countryShortCode:filteredCountry[1], countryCode:countryCode})
  }

  setDefaults =(e)=>{
    let filteredCountry = ''
    let filteredCountries = CountryRegionData.filter(x=>{
      if(x[0] == 'United States') filteredCountry = x
    })
    let regions = []
    filteredCountry[2].split("|").map(regionPair=>{
      let [name, regionShortCode] = regionPair.split("~");
      if(name !== "American Samoa")
      regions.push({
        name: name,
        short: regionShortCode,
      });
    })
    this.setState({regions:regions, lockFields:false,})
  }

  setDate = (e) => {
    this.setState({ selectedDate: new Date(e) })
    let today = new Date();
    let birthDate = new Date(e);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    const validFormat = moment(new Date(e), 'MM/DD/YYYY',true).isValid()
    var start = moment(today, "MM/DD/YYYY");
    var end = moment(e, "MM/DD/YYYY");
    let checkValid = moment.duration(start.diff(end)).asDays()
    if (!validFormat) this.setState({isValidFormat:false})
    else if (checkValid <= 6575){
      this.setState({isValidFormat:true});
      age--;
    }
    else this.setState({isValidFormat:true})
    this.setState({ age });
    if (age < '18') this.setState({ isValid: false })
    else this.setState({ isValid: true })
  }

  countryToFlag(isoCode) {
    return typeof String.fromCodePoint !== 'undefined'
    ? isoCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;
  }


  goBack = () => {
    let path = '/installment-plans/' + this.props.signData.hash
    this.props.onBack(path);
  }

  handlePhoneChange = (event, countryData)=>
  {
    let filteredCode = Countries.filter(({ name }) => name == this.state.formData.citizenshipLabel)
    let countryCode = filteredCode[0].phone
    // let number = event.replace(/-/g,"").replace(/ /g, "").replace("(","").replace(")","")
    let number = '+' + countryCode + parseInt(event.target.value, 10)
    let isValidPhoneNumber = validator.isMobilePhone(number, this.state.locale)
    this.isValidNumber = isValidPhoneNumber
    this.setState({isValidNumber:isValidPhoneNumber})
    const { formData } = this.state;
    formData['number'] = parseInt(event.target.value, 10);
    this.setState({ formData });
  }

  renderComponent = ({ activeTab }) => {
    // if (activeTab === "Individual")
    //   return <Individual step1={this.handleStep1} starterDetails={this.state.starterDetails ? this.state.starterDetails : ''} />
    // else if (activeTab === "BasicInfo2") return <BasicInfo2 onBack={this.handleGoBack} investmentPlan={this.state.investmentPlan ? this.state.investmentPlan.plan : ''} paramsHash={this.props.match.params.hash} />
    // else if (activeTab === "AccountDetails") return <AccountDetails onBack={this.handleGoBackTab} />
    // else if (activeTab === "Agreements") return <Agreements onBack={this.handleGoBackTab} />
    // else if (activeTab === "ReviewFinish") return <ReviewFinish onBack={this.handleGoBackTab} {...this.props} />
  }

  submitType = () =>{
    console.log('in submitType', this.state.type, this.state.formData.accountType);
    if(this.state.type == 'Individual'){
      let formData = { hash:this.props.signData.hash, account: this.state.type}
      this.props.setAccountType(formData)
      // this.setState({activeTab: <Individual onBack={this.handleGoBack} />})
    }else{
      let formData = { hash:this.props.signData.hash, account: this.state.formData.accountType}
      this.props.setAccountType(formData)

      // if(this.state.formData.accountType == "Entity"){
      //   // this.setState({activeTab: <Trust onBack={this.handleGoBack} />})
      // }else if(this.state.formData.accountType == "Trust"){
      //   // this.setState({activeTab: <Trust onBack={this.handleGoBack} />})
      // }else if(this.state.formData.accountType == "Joint"){
      //   // this.setState({activeTab: <Trust onBack={this.handleGoBack} />})
      // }
    }
  }


  handleAccountType = e =>{
    console.log('e', e);
    if(e == "Entity"){
      this.setState({activeTab: <Entity onBack={this.handleGoBack} />})
    }else if(e == "Trust"){
      this.setState({activeTab: <Trust onBack={this.handleGoBack} />})
    }else if(e == "Joint"){
      this.setState({activeTab: <Joint onBack={this.handleGoBack} />})
    }else if(e == "Individual"){
      this.setState({activeTab: <Individual onBack={this.handleGoBack} />})
    }
  }

  emptyTab = e =>{
    this.setState({activeTab: ""})
  }


  handleCheck = (check, key)=>
  {
    console.log('check, key', check.target.name, key);
    if(check.target.name == 'individual' && key == true){
      console.log('this.state.other');
      this.setState({other: false})
    }else if(check.target.name == 'other' && key == true){
      console.log('this.state.individual');

      this.setState({individual: false})
    }

    this.setState({[check.target.name]: key})
    // const { formData } = this.state;
    // formData.checks[check.target.name].value = key;
    // this.setState({ formData });
  }

  handleChange = (event) => {
    console.log(event.target.name, event.target.value);
    this.setState({[event.target.name]: event.target.value})
    if(event.target.value == "Individual") this.setState({lockFields:true})
    else this.setState({lockFields:false})
        // setValue(event.target.value);
  };

  render() {
    console.log('this.props.allSignData', this.props.allSignData);
    const { country, region, isLoader, type } = this.state;
    return (
      <section className="signUp-sec">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="content-area">
                <div className="pb-2">
                  <h1 className="signUpInnerTitles">Contact Info</h1>
                  <hr />
                </div>

                {
                  this.state.activeTab
                  ?
                  <>
                  {this.state.activeTab}
                  </>
                :
                <>
                <ValidatorForm className="validatorForm" onSubmit={this.submitType}>

                <FormControl component="fieldset">
                  <RadioGroup aria-label="type" name="type" value={this.state.type} onChange={this.handleChange}>
                    <FormControlLabel value="Individual" control={<Radio />} label="Individual" />
                    <FormControlLabel value="Other" control={<Radio />} label="Other" />
                  </RadioGroup>
                </FormControl>
                {
                  type == "Individual"
                  ?
                  null
                  :
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-6 pt-3">
                      <FormControl variant="outlined"
                        label="Account Type"
                        margin="dense"
                        className="MyTextField"
                        fullWidth
                        >
                        <InputLabel htmlFor="outlined-age-native-simple">
                          Account
                        </InputLabel>
                        <Select
                          native
                          error={!this.state.isValidCitizenship}
                          disabled={this.state.lockFields}
                          labelWidth={this.state.labelWidth -22}
                          type="text"
                          name="accountType"
                          validators={['required']}
                          onChange={this.handleFormChange}
                          value={this.state.formData.accountType}
                          autoComplete='off'
                          >
                          <option value="" className="pl-2 selectOptions"></option>
                          <option value="Joint" className="pl-2 selectOptions">Joint</option>
                          <option value="Trust" className="pl-2 selectOptions">Trust</option>
                          <option value="Entity" className="pl-2 selectOptions">Entity</option>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                }

                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-6 pt-3">
                    <div className = "outer-box" >
                      <button className = {
                          `thm-btn btn-style-one mr-1 ${!this.state.agreeCheck ? 'disabled' : ''}`
                        }
                        disabled = {
                          this.state.isDisabled
                        } > {
                          !this.state.isDisabled ? 'Continue' : < i className =
                          "fa fa-spinner fa-spin fa-1x fa-fw" />
                        } 
                        </button>
                      </div>
                    </div>
                  </div>
                </ValidatorForm>
                  </>
              }
            </div>
          </div>
        </div>
      </div>

    </section>
  );
}
}

const mapStateToProps = (state) => {
  return {
    auth: state.Auth.auth,
    signData: state.Auth.signData ? state.Auth.signData.info : [],
    allSignData: state.Auth.signData
  }
}

const mapDispatchToProps = { loginRequest, contactSignInfo, logout, sendSMSSignup, setAccountType };
export default connect(mapStateToProps, mapDispatchToProps)(BasicInfo2);

import { connect } from "react-redux";
import React, { Component } from 'react';
import { getBalance, getWalletDataResponse } from "../../../../store/actions/Auth"
import Loader from "../../../../components/AppLoader"
import NumberFormat from 'react-number-format'
import TablePagination from '@material-ui/core/TablePagination';
import { overlayLoader } from '../../../../store/actions/Settings';

class Balance extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page:0,
      rowsPerPage:10,
      showBalance: false,
    }
    props.getBalance()
    props.overlayLoader({status:true, loadingMessage:'Getting Your Balance'})
  }

  handleChangePage = (event, newPage) => {
    this.setState({page:newPage})
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage : parseInt(event.target.value, 10) });
    this.setState({page:0})
  }

  render() {
    let EthBalance =  [];
    const { rowsPerPage, page } = this.state
    EthBalance =  this.props.balance.filter((item) => item['name'] == 'Ethereum')
    let balance =  this.props.balance.filter((item) => item['name'] !== 'Ethereum')
    return (
      <div className="table-page">
        <div className="header-table">
          <h6>Ethereum Balance</h6>
          <div className="icon">
            <i className="fab fa-ethereum"></i>
            <span id="ethBalance">{EthBalance.length > 0 && EthBalance[0]['balance']} ETH</span>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-fixed">
            <thead>
              <tr>
                <th scope="col">Symbol</th>
                <th scope="col">Name</th>
                <th scope="col">Balance</th>
                <th scope="col">In Hold</th>
                <th scope="col">Total</th>
              </tr>
            </thead >
            <tbody className="scrollbar">
              {
                balance && balance.length !== 0 ?
                (rowsPerPage > 0
                  ? balance.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : balance
                ).map((item, idx, z) => {
                  return <tr key={item['balance']}>
                    <th scope="row">
                      <img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/49.png" alt="" />
                      {item['symbol']}
                    </th>
                    <td id={"name" + idx}>{item['name']}</td>
                    <td id={"balance" + idx}><NumberFormat value={item['balance']} displayType={'text'} thousandSeparator={true} /></td>
                    <td id={"hold" + idx}><NumberFormat value={item['inOrder']} displayType={'text'} thousandSeparator={true} /></td>
                    <td id={"total" + idx}><NumberFormat value={Number(item['balance']) + Number(item['inOrder'])} displayType={'text'} thousandSeparator={true} /></td>
                  </tr>
                }) : <Loader />}
            </tbody>
          </table>
             <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                  colSpan={3}
                  count={balance.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  ActionsComponent={this.TablePaginationActions}
                />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = { getBalance, getWalletDataResponse, overlayLoader };

const mapStateToProps = ({Auth}) => {
  let {walletBalance} = Auth;
  return { balance: walletBalance || [] }
}
export default connect(mapStateToProps, mapDispatchToProps)(Balance);

import React, { Component } from 'react';

var Spinner = require('react-spinkit');

class AppLoader extends Component {

  render() {
    let { message } = this.props;
    return (
      <div className='loader'>
        <div className="loader-container">
          <Spinner name='ball-scale-multiple' className='loader' color="#88be3c" />
        </div>
        {message && <div className="loader-container"> <span className="loading-text">{message}</span></div>}
      </div>
    );
  }
}

export default AppLoader;

import moment from 'moment';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import { getHistoryDW, handelUserMessage } from "../../../../store/actions/Auth";
import { cancelDeposit } from "../../../../store/actions/Wallet";
import TablePagination from '@material-ui/core/TablePagination';
import { overlayLoader } from '../../../../store/actions/Settings';
import { Modal, ModalBody } from "reactstrap";
import './history.css'
class DWHistory extends Component {
  constructor(props) {
    super(props)

    this.state = {
      rowsPerPage: 10,
      page: 0,
      isOpen:false,
      cancelId:"",
      selected: this.props.active ? this.props.active : "deposite"
    }
    props.getHistoryDW()
    props.overlayLoader({ status: true, loadingMessage: 'Loading Your Transaction History' })

  }

  viewTransaction = (e) => window.open(e, '_blank');

  handleSelected = (ev) => this.setState({ selected: ev })

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage })
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
    this.setState({ page: 0 })
  }

  handelUserMessage = (e, infoMessage, infoHeading) => {
    e.preventDefault()
    e.nativeEvent.stopImmediatePropagation();
    const data = {
      isOpen: !this.props.isOpen,
      infoMessage,
      infoHeading,
    }
    this.props.handelUserMessage(data)
  }

  toggle = (type, id) => {
      if (id) this.setState({cancelId:id})
      // if (type == 'cancel') console.log("cancelDeposit id:", this.state.cancelId);
      if (type == 'cancel') this.props.cancelDeposit(this.state.cancelId)
      this.setState({isOpen : !this.state.isOpen})
  }

  render() {
    let { selected, rowsPerPage, page, isOpen } = this.state;
    const { Deposit, Withdrawal, Invest, Wire } = this.props.history
    console.log('Withdrawal', this.state);
    let count = []
    // if (Deposit && Invest) count = selected == 'deposite' || selected == 'withdraw' ? (selected == 'deposite' ? Deposit.length : Withdrawal.length) : Invest.length
    return (
      <div className="table-page">
        <div className="headers-table">
          <ul>
            <li><a onClick={() => this.handleSelected("deposite")} className={`pointer ${this.state.selected == "deposite" && "active"}`}>Deposit</a></li>
            <li>|</li>
            <li><a onClick={() => this.handleSelected("withdraw")} className={`pointer ${this.state.selected == "withdraw" && "active"}`}>Withdrawal</a></li>
            <li>|</li>
            <li><a onClick={() => this.handleSelected("wires")} className={`pointer ${this.state.selected == "wires" && "active"}`}>Wires</a></li>

            {/*
              <li>|</li>
              <li><a onClick={() => this.handleSelected("invest")} className={`pointer ${this.state.selected == "invest" && "active"}`}>Invest</a></li>
            */}

          </ul>
        </div>

        <div className="table-responsive">
          <table className="table table-fixed">
            {selected === 'deposite' || selected === 'withdraw' || selected === 'wires' ?
              <>
                <thead>
                  <tr>
                    <th scope="col">COIN</th>
                    <th scope="col">AMOUNT </th>
                    {/* <th scope="col">FEES </th>*/}

                    { selected === 'wires' ? <th scope="col">Bank</th> : <th scope="col">Bank</th>}
                    <th scope="col">STATUS</th>
                    <th scope="col">DATE</th>
                    { selected === 'wires' ? <th scope="col">Type</th> : <th scope="col">Transaction</th>}
                    { selected === 'deposite' && <th scope="col">Cancel</th> }
                  </tr>
                </thead>
                <tbody className="scrollbar">
                  {this.state.selected == "deposite"
                    ?
                    <>
                      {Array.isArray(Deposit) && Deposit.length !== 0 ?
                        <>
                          {
                            (rowsPerPage > 0
                              ? Deposit.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                              : Deposit
                            ).map((item) => {
                              return (
                                <tr>
                                  <td scope="row"> <img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/49.png" alt="" />TKUSD</td>
                                  <td><NumberFormat value={item.received} displayType={'text'} thousandSeparator={true} /></td>
                                  {/* <td> <NumberFormat value={item.fee} displayType={'text'} thousandSeparator={true} /> </td> */}
                                  <td>{item.method}</td>
                                  <td>{item.status}</td>
                                  <td>{moment(item.createdAt.toLocaleString()).format('lll')}</td>
                                  <td>
                                    {item['status'] === 'pending' || item['status'] === 'cancelled' || item['status'] === 'processing' ?
                                      <a>--</a>
                                      :
                                      <a href={item['viewTransaction']} target="_blank">View</a>
                                    }
                                  </td>
                                  <td>
                                    {item['status'] === 'pending' ? <i class="fa fa-times" aria-hidden="true" onClick={() => this.toggle("",item['_id'])}></i> : '--' }
                                  </td>
                                </tr>
                              )
                            })
                          }
                          <Modal
                          modalClassName="modal-mini modal-info uploaded"
                          toggle={this.toggle}
                          isOpen={isOpen}
                          >
                                <div className="modal-header justify-content-center">
                                  <div className="modal-profile">
                                    <img className='modalLogoImg' src="https://tknismtest.s3.amazonaws.com/non-compressed-images/Logo0.png" alt="" />
                                  </div>
                                  <div className="right">
                                    <img
                                      onClick={this.toggle}
                                      className="modalLogoImg"
                                      src="https://tknismtest.s3.amazonaws.com/non-compressed-images/close-icon.png"
                                      alt=""
                                    />
                                  </div>
                                </div>
                                <ModalBody>
                                  <div className="pt-3">
                                    <p className="para">Are you sure you want to cancel it!</p>
                                    <button className="custom-btn no-bg" onClick={this.toggle} >Cancel</button>
                                    <button className="custom-btn ml-2" onClick={() => this.toggle('cancel')}>Yes</button>
                                  </div>
                                </ModalBody>
                          </Modal>
                        </>
                        :
                        <div className="notfound pt-5">
                          <figure><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/47.png" alt="" /></figure>
                        </div>
                      }
                    </>
                    :
                    this.state.selected == "wires"
                    ?
                    <>
                      {Array.isArray(Wire) && Wire.length !== 0 ?
                        <>
                          {
                            (rowsPerPage > 0
                              ? Wire.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                              : Wire
                            ).map((item) => {
                              return (
                                <tr>
                                  <td scope="row"> <img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/49.png" alt="" />TKUSD </td>
                                  <td> <NumberFormat value={item.amount} displayType={'text'} thousandSeparator={true} /> </td>
                                  {/* <td> <NumberFormat value={item.fee} displayType={'text'} thousandSeparator={true} /> </td> */}
                                  <td> {item.bankName} </td>
                                  <td> {item.status} </td>
                                  <td> {moment(item.createdAt).format('lll')} </td>
                                  <td>
                                    {item.type}
                                  </td>
                                </tr>
                              )
                            })
                          }
                        </>
                        :
                        <div className="notfound pt-5">
                          <figure><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/47.png" alt="" /></figure>
                        </div>
                      }
                    </>
                    :
                    <>
                      {Array.isArray(Withdrawal) && Withdrawal.length !== 0 ?
                        <>
                          {
                            (rowsPerPage > 0
                              ? Withdrawal.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                              : Withdrawal
                            ).map((item) => {
                              return (
                                <tr>
                                  <td scope="row"> <img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/49.png" alt="" />TKUSD </td>
                                  <td> <NumberFormat value={item.received} displayType={'text'} thousandSeparator={true} /> </td>
                                  {/* <td> <NumberFormat value={item.fee} displayType={'text'} thousandSeparator={true} /> </td> */}
                                  <td> {item.method} </td>
                                  <td> {item.status} </td>
                                  <td> {moment(item.createdAt).format('lll')} </td>
                                  <td>
                                    {item['status'] === 'pending' || item['status'] === 'cancelled' || item['status'] === 'processing' ?
                                      <a>--</a>
                                      :
                                      <a href={item['viewTransaction']} target="_blank">View</a>
                                    }
                                  </td>
                                </tr>
                              )
                            })
                          }
                        </>
                        :
                        <div className="notfound pt-5">
                          <figure><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/47.png" alt="" /></figure>
                        </div>
                      }
                    </>

                  }
                </tbody>
              </>
              :
              <>
                <thead>
                  <tr>
                    <th scope="col">Property</th>
                    <th scope="col">AMOUNT </th>
                    <th scope="col">STATUS</th>
                    <th scope="col">Offering Close</th>
                    <th scope="col">Invest DATE</th>
                    <th scope="col">Transaction</th>
                  </tr>
                </thead >
                <tbody className="scrollbar">
                  {Array.isArray(Invest) && Invest.length !== 0 ?
                    <>
                      {
                        (rowsPerPage > 0
                          ? Invest.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          : Invest
                        ).map((item) => {
                          return (
                            <tr>
                              <td scope="row"> <Link to={`/single-property?id=${item['propertyId']}`} style={{ textDecoration: 'none' }}><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/49.png" alt="" />{item['symbol']} </Link></td>
                              <td> <NumberFormat value={item['amount']} displayType={'text'} thousandSeparator={true} /> </td>
                              {/* <td> <NumberFormat value={item.fee} displayType={'text'} thousandSeparator={true} /> </td> */}
                              <td> {item.status} </td>
                              <td> {moment(item['closeTime']).format('lll')} </td>
                              <td> {moment(item['createdAt']).format('lll')} </td>
                              <td> <a href={item.viewTransaction} target="_blank">View</a> </td>
                            </tr>
                          )
                        })
                      }
                    </>
                    :
                    <div className="notfound pt-5">
                      <figure><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/47.png" alt="" /></figure>
                    </div>
                  }
                </tbody>
              </>
            }
          </table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
            colSpan={3}
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            ActionsComponent={this.TablePaginationActions}
          />
        </div>
      </div>
    );
  }

  renderDeposit = () => {
    const { Deposit } = this.props.history;
    if (Array.isArray(Deposit) && Deposit.length !== 0) {
      return Deposit.map((item) => {
        return (
          <tr>
            <td scope="row"> <img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/49.png" alt="" />TKUSD</td>
            <td><NumberFormat value={item.received} displayType={'text'} thousandSeparator={true} /></td>
            {/* <td> <NumberFormat value={item.fee} displayType={'text'} thousandSeparator={true} /> </td> */}
            <td>{item.method}</td>
            <td>{item.status}</td>
            <td>{moment(item.createdAt.toLocaleString()).format('lll')}</td>
            <td>
              {item['status'] === 'pending' ?
                <a href={item['viewTransaction']} target="_blank">View</a>
                :
                <a href='#'>--</a>
              }
            </td>
          </tr>
        )
      })
    }
    else {
      return <div className="notfound pt-5">
        <figure><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/47.png" alt="" /></figure>
      </div>
    }
  }

  renderWithdrawal = () => {
    const { Withdrawal } = this.props.history;
    console.log('Withdrawal', Withdrawal);
    if (Array.isArray(Withdrawal) && Withdrawal.length !== 0)
      return Withdrawal.map((item) => {
        return (
          <tr>
            <td scope="row"> <img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/49.png" alt="" />TKUSD </td>
            <td> <NumberFormat value={item.received} displayType={'text'} thousandSeparator={true} /> </td>
            {/* <td> <NumberFormat value={item.fee} displayType={'text'} thousandSeparator={true} /> </td> */}
            <td> {item.method} </td>
            <td> {item.status} </td>
            <td> {moment(item.createdAt).format('lll')} </td>
            <td> <a href={item.viewTransaction} target="_blank">View</a> </td>
          </tr>
        )
      })
    else
      return (
        <div className="notfound pt-5">
          <figure><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/47.png" alt="" /></figure>
        </div>
      )
  }

  renderInvest = () => {
    const { Invest } = this.props.history;
    if (Array.isArray(Invest) && Invest.length !== 0)
      return Invest.map((item) => {
        return (
          <tr>
            <td scope="row"> <Link to={`/single-property?id=${item['propertyId']}`} style={{ textDecoration: 'none' }}><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/49.png" alt="" />{item['symbol']} </Link></td>
            <td> <NumberFormat value={item['amount']} displayType={'text'} thousandSeparator={true} /> </td>
            {/* <td> <NumberFormat value={item.fee} displayType={'text'} thousandSeparator={true} /> </td> */}
            <td> {item.status} </td>
            <td> {moment(item['closeTime']).format('lll')} </td>
            <td> {moment(item['createdAt']).format('lll')} </td>
            <td> <a href={item.viewTransaction} target="_blank">View</a> </td>
          </tr>
        )
      })
    else
      return (
        <div className="notfound pt-5">
          <figure><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/47.png" alt="" /></figure>
        </div>
      )
  }
}


  const mapDispatchToProps = { getHistoryDW, overlayLoader, handelUserMessage, cancelDeposit  };

const mapStateToProps = ({ Auth }) => {
  let { dummy, walletHistoryData } = Auth;
  return { dummy, history: walletHistoryData || {} }
}
export default connect(mapStateToProps, mapDispatchToProps)(DWHistory);

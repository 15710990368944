import EventBus from "eventing-bus";
import { connect } from "react-redux";
import React, { Component } from "react";
import CardDetails from "./../../CardDetails";
import ExistingAccount from "./../../ExistingAccount";
// import TKUSD from "../../../../../store/contract/TKUSD";
import {
  addBankAccount,
  withdraw,
  deleteBank,
  depositeBankPayment
} from "../../../../../store/actions/Wallet";
import { userDashboard } from "../../../../../store/actions/Auth";
import { ValidatorForm } from 'react-material-ui-form-validator';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import AppLoader from '../../../../../components/AppLoader'
import FormHelperText from '@material-ui/core/FormHelperText';
import NumberFormat from 'react-number-format';
import { getBanks, verifyMicro } from '../../../../../store/actions/Wallet';
import { Modal, ModalBody } from "reactstrap";

import { otpLoader, overlayLoader, sendSMSDeposit } from "../../../../../store/actions/Settings";

import "./bankAccount.css";
class BankAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMatched: false,
      showExisting: props.userBanks.length ? true : false,
      addingAccount: false,
      confirmAccountNo: "",
      isDisabled: false,
      amount: '',
      feePercent: 0.02, // 40 Percent
      selectedBank: "",
      isValidName: false,
      isOpen:false,
      message: "Adding your Bank",
      formData: {
        bankName: "",
        routingNo: "",
        accountNo: "",
        accountType: "savings",
      }
    };
    props.getBanks()
    EventBus.on("bankDeposit", () => this.setState({ isDisabled: false }));
  }

  toggleModal=()=>{
    this.setState({isOpen:!this.state.isOpen})
  }

  handleSubmit = async () => {
    this.setState({ isDisabled: true });
    const { amount } = this.state;

    if (this.state.showExisting) {
      if (this.state.selectedBank === "") {
        EventBus.publish("error", "Select A Bank");
        this.setState({ isDisabled: false });
        return;
      } else if (this.state.amount === 0) {
        EventBus.publish("error", "Fill In amount please");
        this.setState({ isDisabled: false });
        return;
      } else {
        this.setState({ isDisabled: true });
        this.props.overlayLoader({status:true, loadingMessage:'Transaction Processing'})
        const { v1, v2, v3, v4 } = this.state;

        this.props.depositeBankPayment({ amount: parseInt(this.state.amount), bankId: this.state.selectedBank, smsVerifyKey: v1 + v2 + v3 + v4 });
      }
    } else if (!this.state.showExisting) {
      this.setState({ isDisabled: true });
      if (this.state.formData.routingNo === "") {
        EventBus.publish("error", "Add Routing Number");
        this.setState({ isDisabled: false });
        return;
      } else if (this.state.formData.accountNo === "") {
        EventBus.publish("error", "Add Account Number");
        this.setState({ isDisabled: false });
        return;
      } else if (this.state.formData.bankName === "") {
        EventBus.publish("error", "Add Account Holder Name");
        this.setState({ isDisabled: false });
        return;
      } else {
        this.setState({ isDisabled: true });
        // this.props.depositeBankPayment(this.state.formData);
      }
    }
  };

  handleAddAccount = () => {
    if (this.state.formData.accountNo.length < 4) EventBus.publish("error", "Account number must be greater than 3 digits.");
    else if (this.state.formData.accountNo !== this.state.formData.confirmAccountNo) EventBus.publish("error", "Account number did not match.");
    else if (this.state.formData.bankName) {
      // this.props.otpLoader(true)
      // this.setState({ addingAccount: true })
      this.props.addBankAccount(this.state.formData)
    }
    const formData = {
      accountType: "savings",
      bankName: "",
      routingNo: "",
      accountNo: "",
      confirmAccountNo: ''
    }
    setTimeout(() => this.setState({ formData }), 500);
  };

  handleFormChange = (e, isValid) => {
    const { formData } = this.state;
    formData[e.target.name] = e.target.value;
    this.setState({ formData, isValidName: isValid });
    if (
      formData.accountNo !== "" &&
      formData.accountNo == formData.confirmAccountNo
    )
      this.setState({ isMatched: true });
    else this.setState({ isMatched: false });
  };

  goBack = () => {
    if (this.props.userBanks.length) this.setState({ showExisting: true });
    else this.props.onSelectMethod("none");
    const formData = {
      accountType: "savings",
      bankName: "",
      routingNo: "",
      accountNo: ""
    }
    this.setState({ formData })
  };

  selectedCard = bank => this.setState({ selectedBank: bank._id, selectedBankFull:bank });

  componentWillReceiveProps(props) {
    if (props.userBanks.length) this.setState({ showExisting: true, isDisabled: false, addingAccount: false });
    else this.setState({ showExisting: false, isDisabled: false });
  }

  onDelete = e => {
    this.props.deleteBank(e);
  };

  handleFundingSource = () =>{
    let modalHeading = "Money Transfer"
    let modalMessage = "You are depositing amount $ " + this.state.amount + " into Account Tokenism LLC"
    this.setState({isOpen:true, modalHeading:modalHeading, modalMessage:modalMessage, v1: "", v2: "", v3: "", v4: "", v5: "", v6: ""})
    clearInterval(this.interval);
    this.props.sendSMSDeposit('Deposit');
    this.setState({ seconds: 59, smsLoading: true }, () => {
      if (this.state.seconds > 1) {
        this.interval = setInterval(() => this.tick(), 1000);
      }
    });
  }

  tick() {
    if (this.state.seconds >= 1) {
      this.setState({seconds: this.state.seconds-1})
    } else {
      clearInterval(this.interval);
    }
  }

  handleSendSMS = () => {
    clearInterval(this.interval)
    this.props.sendSMSDeposit();
    this.setState({ seconds: 59, smsLoading: true }, () => {
      if (this.state.seconds > 1) {
        this.interval = setInterval(() => this.tick(), 1000);
      }
    });
  };

  handleVerificationCode = e => {
    if (e.target.name === "v1") {
      var input = document.getElementById("v2");
      input.focus();
      input.select();
    } else if (e.target.name === "v2") {
      var input = document.getElementById("v3");
      input.focus();
      input.select();
    } else if (e.target.name === "v3") {
      var input = document.getElementById("v4");
      input.focus();
      input.select();
    }

    this.setState({ [e.target.name]: e.target.value }, () => {
      const { v1, v2, v3, v4 } = this.state;
      if (v1 !== "" && v2 !== "" && v3 !== "" && v4 !== "") {
        // this.setState({ showOtpLoader: true });
        // clearInterval(this.interval)
      }
    });
  };

  render() {
    let { dashChartData, showOtpLoader } = this.props;
    let { overview } = dashChartData;
    let { amount, feePercent, message, isOpen, modalHeading, modalMessage, v1, v2, v3, v4 } = this.state;
    let amountShow = amount || 0;

    return (
      <div className="deposite-page tab-content">
        <Modal
        modalClassName="modal-mini modal-info uploaded"
        toggle={this.toggleModal}
        isOpen={isOpen}
        >
              <div className="modal-header justify-content-center">
                <div className="modal-profile">
                  <img className='modalLogoImg' src="https://tknismtest.s3.amazonaws.com/non-compressed-images/Logo0.png" alt="" />
                </div>
                <div className="right">
                  <img
                    onClick={this.toggleModal}
                    className="modalLogoImg"
                    src="https://tknismtest.s3.amazonaws.com/non-compressed-images/close-icon.png"
                    alt=""
                  />
                </div>
              </div>
              <ModalBody>
                <div className="pt-3">
                  <h5 className="title">{modalHeading}</h5>
                  <p className="para">{modalMessage}</p>
                    {this.state.seconds === 0 ? (
                      <p className="resend-title" onClick={this.handleSendSMS}>
                        Resend verification code
                      </p>
                    ) : (
                      <p className="resend-title">
                        Resend verification code in {this.state.seconds}
                      </p>
                    )}
                    <div className="code-input-container">
                      <input
                        name="v1"
                        id="v1"
                        onChange={this.handleVerificationCode}
                        className="code-input"
                        type="text"
                        maxLength={1}
                      />
                      <input
                        name="v2"
                        id="v2"
                        onChange={this.handleVerificationCode}
                        className="code-input"
                        type="text"
                        maxLength={1}
                      />
                      <input
                        name="v3"
                        id="v3"
                        onChange={this.handleVerificationCode}
                        className="code-input"
                        type="text"
                        maxLength={1}
                      />
                      <input
                        name="v4"
                        id="v4"
                        onChange={this.handleVerificationCode}
                        className="code-input"
                        type="text"
                        maxLength={1}
                      />
                    </div>
                </div>
              </ModalBody>
              <div className="modal-footer">
                <button
                  onClick={this.toggleModal}
                  className="custom-btn no-bg"
                  >
                  Cancel
                </button>
                {
                  v1, v2, v3, v4
                  ?
                  <button
                    onClick={this.handleSubmit}
                    className="custom-btn ml-2"
                    >
                    Authorize
                  </button>
                  :
                  <button
                    disabled={true}
                    className="thm-btn btn-style-one"
                    >
                    <i className="fa fa-ban fa-1x fa-fw" />
                  </button>
                }
              </div>
        </Modal>
        <div className="deposit-tab">
          <div className="row clearfix">
            {showOtpLoader ? <AppLoader message='Transaction Processing' /> :
              <div></div>}
            {this.state.addingAccount ?
              <div className="col-lg-5 offset-lg-4 col-md-12 col-12 pt-5">
                <AppLoader message='Processing' />
              </div>
              :
              <>
                {!this.state.showExisting ? (
                  <CardDetails
                    goBack={this.goBack}
                    onChange={(value, valid) => this.handleFormChange(value, valid)}
                    onSubmit={this.handleAddAccount}
                    {...this.state}
                    {...this.props}
                  />
                ) : (
                    <ExistingAccount
                      selectedCard={value => this.selectedCard(value)}
                      addCard={() => this.setState({ showExisting: false })}
                      userBanks={this.props.userBanks}
                      onDelete={this.onDelete}
                      {...this.props}
                      {...this.state}
                    />
                  )}
              </>
            }


            {this.state.showExisting && <div className="col-lg-5 offset-lg-1 col-md-12 col-12 pt-5">

              <div className="right-confrom-area pb-2">
                {this.state.loading ?
                  <AppLoader message='Transaction Processing' />
                  :
                  <div className="group-form">
                    <div className="row pb-3">
                      <div className="col-6">
                        <span className="balance-text grey-text">Current Balance :</span>
                      </div>
                      <div className="col-6">
                        <NumberFormat
                          value={parseFloat(overview || 0).toFixed(0)}
                          displayType={'text'}
                          thousandSeparator={true}
                          renderText={value => <span className="balance-amount grey-text"> {value}</span>}
                        />
                        <span className="grey-text label-small"> TKUSD</span>
                        {/* <label className="label-default">{parseFloat(overview || 0).toFixed(0)}</label> */}
                      </div>
                    </div>

                    <ValidatorForm className="validatorForm pt-2" onSubmit={this.handleFundingSource}>
                      <div className="row">
                        <div className="col-md-12 col-lg-12">
                          <FormControl variant="outlined">
                            <InputLabel>
                              Amount
                              </InputLabel>
                            <OutlinedInput
                              id="outlined-error-helper-text"
                              error={amount > 5000}
                              className="MyTextField2"
                              label="amount"
                              labelWidth={30}
                              name="amount"
                              type="text"
                              validators={['required']}
                              value={amount}
                              onChange={e => this.setState({ amount: e.target.value })}
                              startAdornment={<InputAdornment position="start"
                              >
                                $
                                </InputAdornment>}
                              aria-describedby="outlined-weight-helper-text"
                            />
                            {amount > 5000 ? <FormHelperText id="outlined-error-helper-text" className="helperText">Should be less than 5000.</FormHelperText> : ''
                            }
                            {amount.indexOf(".") != -1 ? <FormHelperText id="outlined-error-helper-text" className="helperText">Should be integer value.</FormHelperText> : ''}
                          </FormControl>
                        </div>
                      </div>
                      <div className="row justify-content-end">
                        <div className="col-8 pt-4">
                          {/* <div className="row">
                              <div className="col-12">
                                <div className="row">
                                  <div className="col-8">
                                    <span className="label-custom pull-right">Deposit Amount: </span>
                                  </div>
                                  <div className="col-4 nopadding ">
                                    <NumberFormat
                                      value={Number.parseFloat(amountShow).toFixed(0)}
                                      displayType={'text'}
                                      thousandSeparator={true}
                                      renderText={value => <span className="label-custom pull-right">{value}</span>}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 pt-1">
                                <div className="row">
                                  <div className="col-8">
                                    <span className="label-custom pull-right">Transaction Fees: </span>
                                  </div>
                                  <div className="col-4 nopadding">
                                    <NumberFormat
                                      value={Number.parseFloat(amountShow * feePercent).toFixed(0)}
                                      displayType={'text'}
                                      thousandSeparator={true}
                                      renderText={value => <span className="label-custom-red pull-right">{value}</span>}
                                    />

                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr className="divider" /> */}
                          <div className="group-form">
                            <div className="row">
                              <div className="col-8">
                                <span className="label-custom pull-right">You Will Receive: </span>
                              </div>
                              <div className="col-4 nopadding ">
                                <span className="label-custom pull-right">
                                  <span className="grey-text label-small">${' '}</span>
                                  <NumberFormat
                                    value={Number.parseFloat(amountShow).toFixed(0)}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    renderText={value => <span className="pull-right">{value}</span>}
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="group-form d-flex justify-content-center pt-3">
                        {amountShow > 5000 || amount.indexOf(".") != -1
                          ?
                          <button
                            disabled={true}
                            className="thm-btn btn-style-one"
                          >
                            <i className="fa fa-ban fa-1x fa-fw" />
                          </button>
                          :
                          <button
                            disabled={this.state.isDisabled}
                            className="thm-btn btn-style-one"
                          >
                            {!this.state.isDisabled ? (
                              "Confirm & Pay"
                            ) : (
                                <i className="fa fa-spinner fa-spin fa-1x fa-fw" />
                              )}
                          </button>
                        }
                      </div>
                    </ValidatorForm>
                  </div>
                }
              </div>
            </div>}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = { getBanks, addBankAccount, withdraw, deleteBank, depositeBankPayment, userDashboard, otpLoader, overlayLoader, sendSMSDeposit, verifyMicro };

const mapStateToProps = state => {
  return { userBanks: state.Wallet.userBanks || [], dashChartData: state.Auth.dashChartData || {}, showOtpLoader: state.Settings.showOtpLoader };
};
export default connect(mapStateToProps, mapDispatchToProps)(BankAccount);

import Web3 from 'web3';
import EventBus from 'eventing-bus';
import React, { Component } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Modal, ModalBody } from 'reactstrap';
import { signupDwolla, loginRequest, getUserSignData, logout, connectETH, updateUser, handleWalletModal, tkusdInfo, retryDwolla } from '../../../store/actions/Auth';
import { connect } from 'react-redux';
import 'date-fns';
import EditableField from './EditableField'
import AppLoader from '../../../components/AppLoader'
import {
  isSafari,
  isMobile,
  isFirefox
} from "react-device-detect";
import { web3, magic, setProvider } from '../../../store/web3';
import { overlayLoader } from "../../../store/actions/Settings";
import { Link } from "react-router-dom";

class ReviewFinish extends Component {
  constructor() {
    super()
    this.state = {
      metaFound: false,
      pageContent: "",
      isDisabled: false,
      firstName: false,
      lastName: false,
      address: false,
      city: false,
      state: false,
      zipCode: false,
      residence: false,
      citizenship: false,
      agreeCheck: false,
      isLogging: false,
      formData: {},
      userData: {},
      contactData: {},
      selectedDate: new Date('2014-08-18T21:11:54'),
      userDataEditable: [{ key: 'firstName', name: 'First Name' }, { key: 'lastName', name: 'Last Name' }],
      contactDataEditable: [{ key: 'address', name: 'Address' }, { key: 'city', name: 'City' }, { key: 'zipCode', name: 'Zip Code' }, { key: 'securityNumber', name: 'Security Number' }],
    }
    window.scrollTo(0, 0);
  }

  componentDidMount = async () => {
    if (window.ethereum)
      this.setState({ metaFound: true })
    else this.setState({ metaFound: false })
    this.props.tkusdInfo();
    this.props.getUserSignData({ payload: { hash: this.props.signData.hash }, process: "other" });
    EventBus.on("ReviewFinish", () => this.setState({ isDisabled: false }));
    if (await magic.user.isLoggedIn()) await magic.user.logout();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.userSignData) {
      this.setState({ userData: nextProps.userSignData.step1 })
      this.setState({ contactData: nextProps.userSignData.step2 })
    }
  }

  handleFormChange = (name, value) => {
    const { formData } = this.state;
    formData[name] = value;
    this.setState({ formData });
  }

  handleUserChange = (name, value) => {
    const { userData } = this.state;
    userData[name] = value;
    this.setState({ userData });
  }

  handleContactChange = (name, value) => {
    const { contactData } = this.state;
    contactData[name] = value;
    this.setState({ contactData });
  }

  handleLoginSignup = async (e) => {
    await this.props.handleWalletModal(true)
  }

  verifyUser = () => {
    this.props.signupDwolla(this.props.signData.hash);
  }

  retryUser = () => {
    this.props.retryDwolla(this.props.signData.hash);
  }

  handleClick = async (e) => {
    localStorage.setItem('ETH-PROVIDER', e);

    if (e === 'metamask') {

      // window.web3 = new Web3(window.ethereum);
      // let netId = await window.web3.eth.net.getId();
      console.log('process', process['env']['REACT_APP_NETWORK_ID']);
      await setProvider(e);
      let netId = await web3.eth.net.getId();
      if (netId == process['env']['REACT_APP_NETWORK_ID']) {
        this.continueLogin(e)
      } else EventBus.publish("info", `Please switch to ${process['env']['REACT_APP_NETWORK']} network before login`);
    }
    else {
      const req = magic.auth.loginWithMagicLink({ email: this.state.userData.email });
      req
        .on("email-sent", () => { console.log('email-sent') })
        .then(async DIDToken => {
          await setProvider(e);
          // window.web3 = new Web3(magic.rpcProvider);
          this.setState({ isLogging: true })
          this.continueLogin(e)
        })
        .once("email-not-deliverable", () => {
          this.setState({ isLogging: false })
          EventBus.publish("error", `Email Is Not Correct.`);
        })
        .catch(error => {
          this.setState({ isLogging: false })
          EventBus.publish("error", error);
        })
        .on("error", error => {
          this.setState({ isLogging: false })
          EventBus.publish("error", error);
        });
    }
    this.toggle()
  }

  toggle = () => {
    const { handleWalletModal, showModal } = this.props;
    handleWalletModal(!showModal)
  }

  continueLogin = (e) => {
    this.props.overlayLoader({ status: true, loadingMessage: 'Creating your account on Blockchain. It may take a while...'})
    // if (typeof window.web3 === 'undefined' && e === 'metamask')
    console.log('process', e);
    if (typeof web3 === 'undefined' && e === 'metamask') {
      EventBus.publish("info", 'Looks like you need to install MetaMask to get started.')// Show Download Optionss
      // alert('Looks like you need to install MetaMask to get started.') // Show Download Optionss
      this.props.overlayLoader({ status: false, loadingMessage: '' })
    }
    else {
      this.setState({ isDisabled: true })
      let web3Injected = ''
      if(e == 'metamask'){
        web3Injected = new Web3(window['ethereum']);
      }else{
        web3Injected = new Web3(magic['rpcProvider']);
      }
      // let web3Injected = new Web3(magic['rpcProvider']);

      // let address = (await web3Injected.currentProvider.enable())[0];
      web3Injected.currentProvider.enable().then((response) => {
        // web3.currentProvider.enable().then((response) => {
        const { formData } = this.state;
        formData['publicAddress'] = response[0];
        this.setState({ formData, isLogging: false });
        this.checkWallet(response[0], e);
      }).catch((reason) => {
        this.props.overlayLoader({ status: false, loadingMessage: '' })
        this.setState({ isDisabled: false, isLogging: false })
        if (reason === 'User rejected provider access') EventBus.publish("error", 'Login Request Rejected.')
        // else EventBus.publish("error", 'There was an issue signing you in.')
        else EventBus.publish("error", `Something went wrong. Please reload and try again!`);
      });
    }
  }

  checkWallet = async (publicAdd, e) => {
    // let netId = await window.web3.version.getNetwork();
    // if(netId != 42)
    // {
    //   this.setState({isDisabled: false});
    //   EventBus.publish("info", 'Please switch to kovan network before Connecting Wallet');
    //   return;
    // }
    let { TKUSDData } = this.props;
    if (e === 'metamask') {
      // const provider = window.web3.currentProvider;
      const provider = web3.currentProvider;
      provider.sendAsync({
        method: 'metamask_watchAsset',
        params: {
          "type": "ERC20",
          "options": {
            "address": TKUSDData['address'],
            "symbol": 'TKUSD',
            "decimals": 0,
            "image": 'https://staging.tknism.io/favicon.png',
          },
        },
        id: Math.round(Math.random() * 100000),
      }, (err, response) => {
        if (err) {
          this.setState({ isDisabled: false })
          EventBus.publish("error", 'There was a problem adding the token.')
          return;
        }
        this.props.connectETH({ payload: { publicAddress: publicAdd, hash: this.props.signData.hash }, history: this.props.history })
      });
      // .then(response=>
      // {
      //   this.props.connectETH({ payload: { publicAddress: publicAdd, hash: this.props.signData.hash }, history: this.props.history })
      // }).catch(e=>
      // {
      //   this.setState({ isDisabled: false })
      //   EventBus.publish("error", 'There was a problem adding the token.')
      // })
    } else {
      this.props.connectETH({ payload: { publicAddress: publicAdd, hash: this.props.signData.hash }, history: this.props.history })
    }
  }

  editField = (value) => this.setState({ [value]: true })

  saveField = (value) => {
    this.setState({ [value]: false })
    this.props.updateUser({ [value]: this.state.userData[value], hash: this.props.signData.hash })
  }

  saveFieldContact = (value) => {
    this.setState({ [value]: false })
    this.props.updateUser({ [value]: this.state.contactData[value], hash: this.props.signData.hash })
  }

  goBack=()=>{
    this.props.onBack(4);
  }

  render() {
    const item = this.props.userSignData || {};
    const stepItem = this.props.signData || {};
    let step5 = stepItem['step5'] || {};
    let step2 = stepItem['step2'] || {};
    let {contactDataEditable} = this.state

    console.log('this.props.signData', this.props.signData);
    if(step2 && step2.citizenship && step2.citizenship !== "United States") {
      contactDataEditable = contactDataEditable.filter( item => item.key != "securityNumber");
      contactDataEditable.map( item => {
           if(item.key == 'zipCode'){
            item.name = 'postal Code'
           }
        })
    }

    return (
      <section className="signUp-sec">
        <Modal
          modalClassName="modal-mini modal-info"
          toggle={this.toggle}
          isOpen={this.props.showModalOld}
        >
          <div className="modal-header justify-content-center">
            <div className="modal-profile">
              <img className='modalLogoImg' src="https://tknismtest.s3.amazonaws.com/non-compressed-images/favicon.png.png" alt="" />
            </div>
          </div>
          <ModalBody>
            <span onClick={this.toggle}>Login With</span>
          </ModalBody>
          <div className="modal-footer row footerOptions" style={{ justifyContent: 'center !important' }}>
            <div className="invest-btn-area col-col">
              {this.state.metaFound ?
                <a
                  onClick={() => {
                    this.state.metaFound
                      ? this.handleClick('metamask')
                      : console.log("disabled")
                  }}
                  href="#"
                  className="thm-btn btn-style-modal"
                  style={this.state.metaFound ? {} : { cursor: 'not-allowed', background: "lightgray" }}>
                  MetaMask
                </a>
                :
                null
              }
            </div>

            <div className="invest-btn-area col-col">
              <a onClick={() => this.handleClick('formatic')} href="#" className="thm-btn btn-style-modal" > Fortmatic </a>
            </div>
          </div>
        </Modal>

        <Modal
          modalClassName="login-modal"
          toggle={this.toggle}
          isOpen={this.props.showModal}
        >
          <ModalBody>
            <div className="login-left">
              <img
                src="https://tknismtest.s3.amazonaws.com/non-compressed-images/tokenism-text.png"
                className="tokenism-text"
              />
              <div className="left-banner">
                <img
                  src="https://tknismtest.s3.amazonaws.com/non-compressed-images/whiteHalf.png"
                  className="login-logo"
                />
              </div>
            </div>

            <div className="login-right">
              <p className="right-title">
                {this.state.selectedMethod === "login" ? "Login in" : "Sign Up"}
              </p>
              <p hidden={this.state.metaFound || isMobile} className="row right-description">
                All that you need to interact with our platform is an <br />
                E-Wallet
              </p>
              <p
                className="right-download"
                style={
                  this.state.metaFound
                    ? { marginTop: "30px" }
                    : { background: "none" }
                }
              >
                {
                  this.state.metaFound && this.state.selectedMethod === "login"
                    ? "Connect via"
                    : !this.state.metaFound && this.state.selectedMethod === "login"
                      ? "Download Now"
                      : "Connect via"
                }
              </p>

              <div className="row donwload-container">

                <div
                  onClick={() => this.handleClick("formatic")}
                  className="donwload-btn"
                  style={{ margin: "0px 10px 0px 0px" }}
                >
                  <p className="icon-title-1">Using Email</p>
                  <img
                    src="https://tknismtest.s3.amazonaws.com/non-compressed-images/favicon.png.png"
                    className="donwload-icon"
                  />
                  <p className="icon-title">Wallet</p>
                </div>

                {!isSafari && !isFirefox && !isMobile ?
                  <div
                    onClick={() => {
                      if (!this.state.metaFound)
                        window.location.href = "https://metamask.io/";
                      else this.handleClick("metamask");
                    }}
                    className="donwload-btn"
                    style={{ margin: "0px 10px 0px 0px" }}

                  >
                    <p className="icon-title-1">Using E-Wallet</p>

                    <img
                      src="https://tknismtest.s3.amazonaws.com/non-compressed-images/meta-icon.png"
                      className="donwload-icon"
                    />
                    <p className="icon-title">MetaMask</p>
                  </div>
                  : ''
                }
                {/*
                  {isMobile ?
                    <div className="donwload-btn"
                      style={{ margin: "0px 10px 0px 0px" }}
                      onClick={() => {
                        this.handleClickCoinbase("coinbase")
                      }}
                    >
                    <p className="icon-title-1">Using Mobile App</p>
                    <img
                      src="https://tknismtest.s3.amazonaws.com/non-compressed-images/coinbase-icon.png"
                      className="donwload-icon"
                    />
                    <p className="icon-title">Coinbase Wallet</p>
                  </div>
                  :null}
                */}

              </div>

              {/*
                this.state.selectedMethod === "login" ? (
                <p className="have-acc">
                  Don't have an account?{" "}
                  <span className="login-span">Sign up</span>
                </p>
              ) : (
                <p className="have-acc">
                  Already have an account?{" "}
                  <span className="login-span">Log in</span>
                </p>
              )
            */}
              <div className="login-modal-footer">
                <div>
                  <img
                    src="https://tknismtest.s3.amazonaws.com/non-compressed-images/secure.png"
                    className="secure-icon"
                  />
                </div>
                <div className="secure-content">
                  <p className="secure-title">Non-custodial & Secure</p>
                  <p className="secure-desc">
                    We do not own your private keys and cannot access your funds
                  </p>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        {
          !this.state.isLogging
            ?
            <div className="container">
              <div className="row pt-3">
                <div className="col-12">
                  {item['step5'] ?
                    <div className="content-area">
                      <div className="sec-title">
                        <span className="formBlurHeading">You're almost done!</span>
                        <h1 className="signUpInnerTitles">Please review.</h1>
                      </div>

                      <ValidatorForm className="validatorForm">
                        <div className="row">
                          <div className="col-12" >
                            <h6>User Info:</h6>
                            <hr />
                          </div>

                          {
                            this.state.userDataEditable.map(({ key, name }) => {
                              let check = this.state.userData[key];
                              return <div className={this.state[key] === true ? "col-12 p-2" : "col-6 p-2"}>
                                {
                                  !this.state[key]
                                    ?
                                    <div className="pl-3">
                                      <i className="fa fa-pencil pr-1 edit" aria-hidden="true" onClick={(value) => this.editField(key)}></i>
                                      <span className="formBlurHeading text-capitalize" style={{ fontSize: "1rem" }}> <span className="font-weight-bold">{name} - </span>{check}</span>
                                    </div>
                                    :
                                    <div className="row pl-3">
                                      <div className="col-1">
                                        <i className="fa fa-check pt-3 pr-1 check" aria-hidden="true" onClick={(value) => this.saveField(key)}></i>
                                      </div>
                                      <div className="col-10">
                                        <EditableField onChangeValue={this.handleUserChange} labelName={name} value={this.state.userData[key]} title={key} />
                                      </div>
                                    </div>
                                }
                              </div>
                            })
                          }
                          <div className="col-12 pt-3">
                            <h6 >Contact Info:</h6>
                            <hr />
                          </div>
                          {
                            contactDataEditable.map(({ key, name }) => {
                              let check = this.state.contactData[key];
                              return <div className={this.state[key] === true ? "col-12 p-2" : "col-6 p-2"}>
                                {
                                  !this.state[key]
                                    ?
                                    <div className="pl-3">
                                      <i className="fa fa-pencil pr-1 edit" aria-hidden="true" onClick={(value) => this.editField(key)}></i>
                                      <span className="formBlurHeading text-capitalize" style={{ fontSize: "1rem" }}><span className="font-weight-bold">{name} - </span>{check}</span>
                                    </div>
                                    :
                                    <div className="row pl-3">
                                      <div className="col-1">
                                        <i className="fa fa-check pt-3 pr-1 check" aria-hidden="true" onClick={(value) => this.saveFieldContact(key)}></i>
                                      </div>
                                      <div className="col-10">
                                        <EditableField onChangeValue={this.handleContactChange} labelName={name} value={this.state.contactData[key]} title={key} />
                                      </div>
                                    </div>
                                }
                              </div>
                            })
                          }
                        </div>
                        <hr />
                        <div className="row">
                          <div className="col-sm-12 col-md-6 col-lg-6">
                            <div className="loginBtnDiv pt-3">
                              <div className="outer-box" >
                                <button type="button" name="skipButton" value="skipButton" onClick={()=>this.goBack()} className="thm-btn btn-style-one-outline mr-1" disabled = {this.state.isDisabled}>
                                  {!this.state.isDisabled ? 'Back' : <i className="fa fa-spinner fa-spin fa-1x fa-fw"/>}
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-12 col-md-6 col-lg-6">
                            <div className="loginBtnDiv pt-3">
                              {
                                step5['verified'] === 'verified' || step5['verified'] === 'document' || step2['citizenship'] !== 'United States'?
                                <div className="outer-box" >
                                  <button onClick={() => this.handleLoginSignup()} disabled={this.state.isDisabled} className="thm-btn btn-style-one ml-auto d-block">
                                    {!this.state.isDisabled ? 'Create/Connect Wallet' : <i className="fa fa-spinner fa-spin fa-1x fa-fw" />}
                                  </button>
                                </div>
                                :
                                step5['verified'] === 'retry' ?
                                <div className="outer-box" >
                                  <button onClick={() => this.retryUser()} type="button" className="thm-btn btn-style-one ml-auto d-block">
                                    {!this.props.isVerifyUser ? 'Retry Verification' : <i className="fa fa-spinner fa-spin fa-1x fa-fw" />}
                                  </button>
                                </div>
                                :
                                step5['verified'] === 'suspended' ?
                                <div className="outer-box" >
                                  <Link id="navbar-starter" to="/starter-packages">
                                    <button type="button" className="thm-btn btn-style-one ml-auto d-block">
                                      {'You are Suspended'}
                                    </button>
                                  </Link>
                                </div>
                                :
                                <div className="outer-box" >
                                  <button onClick={() => this.verifyUser()} disabled={this.props.isVerifyUser} className="thm-btn btn-style-one ml-auto d-block">
                                    {!this.props.isVerifyUser ? 'Verify Info' : <i className="fa fa-spinner fa-spin fa-1x fa-fw" />}
                                  </button>
                                </div>
                              }
                            </div>
                          </div>
                        </div>

                      </ValidatorForm>
                    </div>
                    :
                    <div className="content-area">
                      <div className="sec-title">
                        <span className="formBlurHeading">You're almost done!</span>
                        <h1 className="signUpInnerTitles">Please review.</h1>
                      </div>
                      <div className="center text-center my-5 py-5">
                        <i className="fa fa-spinner fa-spin fa-5x fa-fw" style={{ color: '#8cc63f' }} />
                        <br /> <br />
                      Loading
                    </div>
                    </div>
                  }
                </div>
              </div>
            </div>
            :
            <AppLoader message='Connecting Wallet' />
        }
      </section>
    );
  }
}

const mapStateToProps = ({ Auth }) => {
  let { auth, showModal, signData, userSignData, TKUSDData, isVerifyUser } = Auth;
  return { auth, showModal, signData, userSignData, TKUSDData, isVerifyUser };
}

const mapDispatchToProps = { signupDwolla, loginRequest, getUserSignData, connectETH, updateUser, logout, handleWalletModal, tkusdInfo, overlayLoader, retryDwolla };
export default connect(mapStateToProps, mapDispatchToProps)(ReviewFinish);

var initialState = {
  balanceData: [],
  listingData: [],
  TKUSDBalance: '',
  activeOffersData: [],
  transactionHistoryData: [],
};

const Exchange = (state = initialState, { type, payload }) => {
  switch (type) {
    case "RESET_EXCHANGE":
      return initialState
    case "RESPONSE_USER_TOKEN_BALANCE":
      return {
        ...state,
        balanceData: payload
      };
    case "RESPONSE_USER_LISTING_BALANCE":
      return {
        ...state,
        listingData: payload
      };
    case "RESPONSE_ACTIVE_OFFERS":
      return {
        ...state,
        TKUSDBalance: payload['balance'],
        activeOffersData: payload['activeData'],
      }
    case "SELL_OFFERS_RECEIVED":
      return {
        ...state,
        sellData: payload
      };

    case "RESPONSE_TRANSACTION_HISTORY":
      return {
        ...state,
        transactionHistoryData: payload
      }
    default:
      return state;
  }
};
export default Exchange;

import React, { Component } from 'react';

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import { getAllBlogs } from "../../store/actions/Auth";
import { connect } from "react-redux";
import moment from 'moment';
import LazyLoad from 'react-lazyload';
import ReactHtmlParser from 'react-html-parser';
import validator from 'validator';

class Investing101Detail extends Component
{
  constructor(props){
    const {query} = require('url').parse(document.location.href, true);
    if (query['id'] == undefined) props.history.push('/')
    else props.getAllBlogs({ _id: query['id'] })

    super()
    this.state = {
      blogs:[],
      shareUrl: query['id'] !== undefined ? "https://staging.tknism.io/investing101-details?id=" + query['id'] : "https://staging.tknism.io/investing101"
    }
    window.scrollTo(0,0);
  }

    componentDidUpdate(){
    if (this.state.blogs != this.props.blogs) {
      this.setState({blogs:this.props.blogs})
    }
  }

  render()
  {
    let { blogs } = this.state;
    return (
      <div className="page-wrapper">
        <Navbar
          page='investing101'
          {...this.props}
          />

        <section className="listing-propety-det sec-padding3">
          <div className="container">
            <div className="row">
              <div className="image-column card shadow col-md-8 p-0">
                {blogs.length > 0 ?
                  <>
                    <div className="detail-sec">
                      <h1 className="title">
                        {blogs[0] ? blogs[0].title: ""}
                      </h1>
                      <ul className="info-sec">
                        <li className="date"><a href="#">
                          {blogs[0] ? moment(blogs[0].createdAt).format('MMM Do YYYY') : ""}, </a></li>
                        <li className="name"><a href="#">Written by {blogs[0] && blogs[0].writtenBy? blogs[0].writtenBy: ""} </a></li>
                      </ul>
                    </div>
                    <LazyLoad once>
                    <div style={{backgroundImage: `url(${blogs[0].cover.location})`, backgroundColor: "#000", height: "480px", backgroundRepeat:'no-repeat', backgroundSize:"cover", backgroundPosition: 'center center'}}>
                    </div>
                  </LazyLoad>
                    <div className="detail-sec">
                      <div style={{fontFamily: "Montserrat, sans-serif !important"}}>
                        {ReactHtmlParser(blogs[0] ? validator.unescape(blogs[0].body): "")}
                      </div>
                      <ul className="social-icons">
                        {/* <li><EmailShareButton url={this.state.shareUrl} ><a href="#" className="gplus"><i className="fa fa-google-plus"></i>Share</a></EmailShareButton></li> */}
                        <li><LinkedinShareButton url={this.state.shareUrl} title={blogs[0] ? blogs[0].title : ""} via="https://staging.tknism.io"><a href="#" style={{"background":"rgb(0, 127, 177)"}} className="gplus"><i className="fa fa-linkedin"></i>Share</a></LinkedinShareButton></li>
                        <li><FacebookShareButton url={this.state.shareUrl} quote={blogs[0] ? blogs[0].title : ""}><a href="#" className="fb"><i className="fa fa-facebook-f"></i>Share</a></FacebookShareButton></li>
                        <li><TwitterShareButton url={this.state.shareUrl} title={blogs[0] ? blogs[0].title : ""} source="https://staging.tknism.io"><a href="#" className="tw"><i className="fa fa-twitter"></i>Share</a></TwitterShareButton></li>
                        {/* <li><EmailShareButton style={{ margin: '0.5rem' }} url={'https://staging.tknism.io/'} type="button"><EmailIcon size={60} round /></EmailShareButton></li> */}
                      </ul>

                    </div>
                  </>
                  :
                  <div className="d-flex justify-content-center align-items-center h-100">
                    <i className="fa fa-spinner fa-spin fa-4x fa-fw" style={{ color: '#8cc63f', fontSize:"44px" }} />
                  </div>
                 }
                </div>
              <div className="fixed-blog col-md-4">
                 <figure>
                     <img src="https://tknismtest.s3.amazonaws.com/blogside-flyer.png" alt="Propy Blog" />
                 </figure>
              </div>
            </div>
          </div>
        </section>
{/*
        <section className="comment-sec sec-padding2">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-3 col-md-12 col-sm-12">
                <div className="contact-form">
                  <h3>Leave a Comment</h3>
                  <p>Your email address wil not be published. Required fields are marked *</p>
                  <form method="post" action="sendemail.php">
                    <div className="row">
                      <div className="form-group col-lg-12 col-md-12">
                        <textarea name="comment" placeholder="Comment *" required></textarea>
                      </div>
                      <div className="form-group col-lg-12 col-md-12">
                        <input type="text" name="name" placeholder="First Name *" required />
                      </div>

                      <div className="form-group col-lg-12 col-md-12">
                        <input type="email" name="email" placeholder="Email *" required />
                      </div>

                      <div className="form-group col-lg-12 col-md-12">
                        <input type="url" name="website" placeholder="Website *" required />
                      </div>


                      <div className="form-group col-lg-12 col-md-12 text-left">
                        <button className="thm-btn btn-style-one" type="submit" name="submit-form">Submit <i className="fa fa-arrow-right"></i></button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
 */}

        <Footer/>

      </div>
    );
  }
}


const mapStateToProps = ({ Auth }) => {
  let { blogs } = Auth;
  return {
    blogs: Auth.blogs ? blogs : [],
  };
};

const mapDispatchToProps = { getAllBlogs };

export default connect(mapStateToProps, mapDispatchToProps)(Investing101Detail);

import EventBus from 'eventing-bus';
import { connect } from "react-redux";
import React, { Component } from 'react';
import { sendWireEmail } from '../../../../../store/actions/Wallet';

let env = process['env']['REACT_APP_SERVER'] === 'production' ? 'production' : 'sandbox';

class Wires extends Component {
    constructor() {
        super();
        this.state = {
            formData: {
                amount: '',
                currency: "USD",
                scales: false
            }
        }
    }

    handleFormChange = (event)=> {
        const { formData } = this.state;
        if (event.target.name == 'scales')
            formData[event.target.name] = event.target.checked;
        else
            formData[event.target.name] = event.target.value;
        this.setState({ formData });
    }

    sendWireEmail= ()=>{
      console.log('sendWireEmail');
      this.props.sendWireEmail()
    }

    render()
    {
        console.log('wires', this.props.wires);
        const { formData } = this.state
        const { wires } = this.props
        return (
            <div className="deposite-page wire">
                <div className="deposit-tab" id="credit">
                      <div className="title row m-0">
                          <p onClick={() => this.props.onSelectMethod("none")}>
                              <i className="fa fa-arrow-left text-secondary" aria-hidden="true" />
                          </p>
                          <h5 className='pt-3'>Following are the details for wires transfer.</h5>
                      </div>
                      <div className="style-two">
                              <div className="row pb-3">
                                <div className="col-5 col-md-3">
                                  <span className="balance-text grey-text">Bank Name : </span>
                                </div>
                                <div className="col-7 col-md-9">
                                  <span className="balance-amount grey-text">{wires.bankName}</span>
                                </div>
                              </div>
                              <div className="row pb-3">
                                <div className="col-5 col-md-3">
                                  <span className="balance-text grey-text">Account Name : </span>
                                </div>
                                <div className="col-7 col-md-9">
                                  <span className="balance-amount grey-text">{wires.accountName}</span>
                                </div>
                              </div>
                              <div className="row pb-3">
                                <div className="col-5 col-md-3">
                                  <span className="balance-text grey-text">Account : </span>
                                </div>
                                <div className="col-7 col-md-9">
                                  <span className="balance-amount grey-text">{wires.account}</span>
                                </div>
                              </div>
                              <div className="row pb-3">
                                <div className="col-5 col-md-3">
                                  <span className="balance-text grey-text">Routing Number : </span>
                                </div>
                                <div className="col-7 col-md-9">
                                  <span className="balance-amount grey-text">{wires.routingNo}</span>
                                </div>
                              </div>
                              <div className="row pb-3">
                                <div className="col-5 col-md-3">
                                  <span className="balance-text grey-text">Swift Code :</span>
                                </div>
                                <div className="col-7 col-md-9">
                                  <span className="balance-amount grey-text">{wires.swiftCode}</span>
                                </div>
                              </div>
                              <div className="row pb-3">
                                <div className="col-5 col-md-3">
                                  <span className="balance-text grey-text">Payment reference :</span>
                                </div>
                                <div className="col-7 col-md-9">
                                  <span className="balance-amount grey-text">{wires.paymentId}</span>
                                  <span className="balance-amount ml-3 grey-text">(<b>Note:</b> This reference is used specifically for wire transfers)</span>
                                </div>
                              </div>
                              <div className="row pb-3">
                                <div className="col-5 col-md-3">
                                  <span className="balance-text grey-text">Account Address :</span>
                                </div>
                                <div className="col-7 col-md-9">
                                  <span className="balance-amount grey-text">{wires.accountAddress}</span>
                                </div>
                              </div>
                              <div className="row pb-3 pt-3">
                                <div className="col-12">
                                  <button
                                    type="button"
                                    onClick={this.sendWireEmail}
                                    className="thm-btn btn-style-one"
                                    >
                                    Email Instructions
                                  </button>
                                </div>
                              </div>
                            </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {sendWireEmail};

const mapStateToProps = (state) => {
  return  {wires: state.Wallet.wires}
}
export default connect(mapStateToProps, mapDispatchToProps)(Wires);

import axios from "axios";
import jwt_decode from 'jwt-decode';
import EventBus from "eventing-bus";
import { put, all, takeLeading, call } from "redux-saga/effects";

import { setHash } from "../axios";
import { setProvider, web3 } from '../web3';
import { otpLoader, overlayLoader, getProfile } from "../actions/Settings";
import { dashboardData, dashHeaderData, dashChartData, setVerifyUser } from "../actions/Auth";

function* loginRequest({ payload, history }) {
  const { error, response } = yield call(getCall, `/user/getUser/${payload}`);
  if (error) {
    yield put(overlayLoader({status:false, loadingMessage:''}));
    if (web3.currentProvider.apiKey) history.push("/starter-packages");
    else EventBus.publish("error", error["response"]["data"]["message"]);
  }
  if (response) {
    yield put({
      type: "SIGN_MESSAGE",
      data: {
        history,
        type: "login",
        publicAddress: payload,
        nonce: response["data"]["body"]["nonce"]
      }
    });
  }
}

function* signupRequest({ payload }) {
  const { error, response } = yield call(postCall, {
    path: `/user`,
    body: payload
  });
  if (error) {
    EventBus.publish("BasicInfo1");
    let body = error["response"]["data"]["body"];
    if (
      body &&
      !Array.isArray(body) &&
      body.status &&
      body.status === "pending"
    )
      EventBus.publish("openSignUpModal", body.hash);
    else EventBus.publish("error", error["response"]["data"]["message"]);
  }
  if (response) EventBus.publish("BasicInfoEmail", response["data"]["message"]);
}

function* resetProcess({ payload }) {
  const { error, response } = yield call(getCall, `/user/reset/` + payload);
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  if (response) EventBus.publish("restartSignUp");
}

function* continueProcess({ payload }) {
  const { error, response } = yield call( getCall, `/user/continueSignup/${payload}`);
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  if (response) EventBus.publish("success", response["data"]["message"]);
}

function* connectETH({ payload, history }) {
  const { error, response } = yield call(postCall, { path: `/user/addWallet`, body: payload });
  if (error) {
    EventBus.publish("ReviewFinish");
    EventBus.publish("error", error['response']['data']['message']);
    yield put(overlayLoader({status:false, loadingMessage:''}));
  }
  if (response) {
    yield put({
      type: "SIGN_MESSAGE",
      data: {
        history,
        type: "signup",
        publicAddress: payload.publicAddress,
        nonce: response["data"]["body"]["nonce"]
      }
    });
    yield put({ type: "GET_SIGN_DATA", payload: "" });
    EventBus.publish("ReviewFinish");
  }
}

function* handleSignMessage(action) {
  web3.eth.personal.sign(web3.utils.utf8ToHex(`Login to Tokenism - Nonce: ${action["data"]["nonce"]}`), action["data"]["publicAddress"])
    .then(signature => handleloginAuth(signature, action["data"]))
    .catch(e => {
      EventBus.publish("error", e['message'])
      EventBus.publish("loginLoader", false)
    });
}

function handleloginAuth(signature, { publicAddress, type, history }) {
  axios
    .post(`/user/auth`, { publicAddress, signature, type })
    .then(response => {
      if (response.data.body.hash) {
        localStorage.setItem("hash", response.data.body.hash);
        setProvider()
        setHash(response.data.body.hash);
        EventBus.publish("loginAuthTabs", {
          smsVerifyEnabled: response.data.body.smsVerifyEnabled,
          twoFaEnabled: response.data.body.twoFaEnabled
        });
        setTimeout(() => EventBus.publish("loginLoader", false), 500);
        if(type == 'signup') EventBus.publish("signUpAcknowledgment", true);
        // yield put(overlayLoader({status:false, loadingMessage:''}));
      } else {
        setProvider()
        const decoded = jwt_decode(response["data"]["body"]["token"]);
        if (decoded['role'] !== 'user') EventBus.publish("error", "Invalid Login");
        else {
          EventBus.publish("success", response["data"]);
          if(type == 'signup') EventBus.publish("signUpAcknowledgment", true);
          if (history) setTimeout(() => history.push("/dashboard"), 500);
        }
        setTimeout(() => EventBus.publish("loginLoader", false), 500);
      }
    })
    .catch(error => {
      EventBus.publish("error", error["response"]["data"]["message"]);
      setTimeout(() => EventBus.publish("loginLoader", false), 500);
    });
}

function* checkEmail({ payload }) {
  const { error, response } = yield call( getCall, `/user/checkEmail/${payload}` );
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  if (response) EventBus.publish("ContinueMagicLogin", payload);
}

function* contactSignInfo({ payload }) {
  const { error, response } = yield call(postCall, { path: `/user/contactInfo`, body: payload });
  EventBus.publish("BasicInfo2");
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  if (response) yield put({ type: "GET_SIGN_DATA", payload: response["data"]["body"] });
}

function* trustSignInfo({ payload }) {
  console.log('payload', payload);
  const { error, response } = yield call(postCall, { path: `/user/contactInfoTrust`, body: payload });
  EventBus.publish("BasicInfo2Type");
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  if (response) yield put({ type: "GET_SIGN_DATA", payload: response["data"]["body"] });
}

function* jointSignInfo({ payload }) {
  console.log('payload', payload);
  const { error, response } = yield call(postCall, { path: `/user/contactInfoJoint`, body: payload });
  EventBus.publish("BasicInfo2Type");
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  if (response) yield put({ type: "GET_SIGN_DATA", payload: response["data"]["body"] });
}

function* entitySignInfo({ payload }) {
  console.log('payload', payload);
  const { error, response } = yield call(postCall, { path: `/user/contactInfoEntity`, body: payload });
  EventBus.publish("BasicInfo2Type");
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  if (response) yield put({ type: "GET_SIGN_DATA", payload: response["data"]["body"] });
}

function* setAccountType({ payload }) {
  console.log('payload', payload);
  const { error, response } = yield call(postCall, { path: `/user/accountType`, body: payload });
  console.log('response', response["data"]["body"]);

  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  if (response){
    console.log('setAccountType', response["data"]["body"]);
    EventBus.publish("BasicInfo2", response["data"]["body"]["account"] );

    yield put({ type: "GET_SIGN_DATA", payload: response["data"]["body"] });
  }
}

function* documentsSignInfo({ payload }) {
  const { error, response } = yield call(postCall, {
    path: `/user/uploadDoc`,
    body: payload
  });
  EventBus.publish("UploadId");
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  if (response) yield put({ type: "GET_SIGN_DATA", payload: response["data"]["body"] });
}

function* signAddBank({ payload }) {
  const { error, response } = yield call(postCall, { path: `/wallet/addBank`, body: payload });
  EventBus.publish("AccountDetails");
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  if (response) yield put({ type: "GET_SIGN_DATA", payload: response["data"]["body"] });
}

function* plaidUpload({ payload }) {
  const { error, response } = yield call(postCall, { path: `/wallet/plaidInfo`, body: payload });
  EventBus.publish("AccountDetails");
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  if (response) yield put({ type: "GET_SIGN_DATA", payload: response["data"]["body"] });
}

function* signSkipBank({ payload }) {
  const { error, response } = yield call( getCall, `/wallet/skipAccount/${payload}` );
  EventBus.publish("AccountDetails");
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  if (response) yield put({ type: "GET_SIGN_DATA", payload: response["data"]["body"] });
}

function* agreementsChecked({ payload }) {
  const { error, response } = yield call(postCall, { path: `/user/submitAgreement`, body: payload });
  EventBus.publish("Agreements");
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  if (response) yield put({ type: "GET_SIGN_DATA", payload: response["data"]["body"] });
}

function* updateUser({ payload }) {
  const { error, response } = yield call(postCall, { path: `/user/editUser`, body: payload });
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  if (response) yield put({ type: "USER_SIGN_DATA", payload: response["data"]["body"]["info"] });
}

function* getUserSignData({ payload, process }) {
  const { error, response } = yield call(getCall, `/user/userData/${payload["hash"]}`);
  EventBus.publish("signUpMainLoader");
  if (error)  EventBus.publish("error", error["response"]["data"]["message"]);
  if (response && process == "email") yield put({ type: "GET_SIGN_DATA", payload: response["data"]["body"] });
  else if (response) yield put({ type: "GET_USER_FINAL_DATA", payload: response["data"]["body"] });
}

function* getAgreements() {
  const { error, response } = yield call(getCall, "/user/files/Agreement");
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  if (response) yield put({ type: "SAVE_AGREEMENTS", payload: response["data"]["body"] });
}

function* getSinglePropertyDetails({ payload }) {
  const { error, response } = yield call(getCall, `/property/getProperty/${payload}`);
  if (error) EventBus.publish("error", error['response']['data']['message'])
  else if (response) yield put({ type: 'SINGLE_PROPERTY_RESPONSE', payload: response['data']['body'] });
}

function* getCookiesData({ payload }) {
  const { error, response } = yield call(getCall, `/logging/getCookies/${payload}`);
  if (error) yield put({ type: 'GET_COOKIES', payload: error['response']['data']['code'] });
  else if (response) yield put({ type: 'GET_COOKIES', payload: response['data']['code'] });
}


function* setCookiesData({ payload }) {
  const { response } = yield call(postCall, { path: '/logging/setCookies', body: payload });
  if (response) yield put({ type: 'GET_COOKIES_RES', payload: localStorage.getItem('IP') });
}

function* investNow({ payload }) {
  const { error, response } = yield call(postCall, { path: `/property/investNow`, body: payload });
  if (error) {
    EventBus.publish("error", error["response"]["data"]["message"]);
    yield put(overlayLoader({status:false, loadingMessage:''}));
  }
  else if (response) {
    EventBus.publish("success", response["data"]["message"]);
    yield put(overlayLoader({status:false, loadingMessage:''}));
    yield put({ type: 'GET_SINGLE_PROPERTY_DETAILS', payload: response["data"]["body"] });
    yield put({ type: "GET_BALANCE" });
  }
}

function* getAllProperties({ payload }) {
  const { error, response } = yield call(postCall, { path: `/property/allProperties`, body: payload });
  if (error && error["response"]["data"]["code"] == 404)
    yield put({ type: "GET_ALL_PROPERTIES_RESPONSE", payload: "" });
  if (response) {
    if (payload['_id']) yield put({ type: "GET_SINGLE_PROPERTY_RESPONSE", payload: response["data"]["body"] });
    else yield put({ type: "GET_ALL_PROPERTIES_RESPONSE", payload: response["data"]["body"] });
  }
}

function* depositePayment({ payload }) {
  const { error, response } = yield call(postCall, {
    path: "/wallet/stripeDeposit",
    body: payload
  });
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  if (response) {
    yield put({ type: "USER_DASHBOARD" });
    EventBus.publish("success", response["data"]["message"]);
    EventBus.publish("paymentDeposited", "DWHistory");
  }
  EventBus.publish("cardDeposit");
}

function* submitProperty() {
  const { error, response } = yield call(getCall, `/property/submitProperty`);
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  if (response) EventBus.publish("success", response["data"]["message"]);
}

function* paypalDeposit({ payload }) {
  const { error, response } = yield call(postCall, { path: "/wallet/paypalDeposit", body: payload });
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  if (response) EventBus.publish("success", response["data"]["message"]);
  EventBus.publish("paymentDeposited", "DWHistory");
}

function* userDashboard() {
  const { error, response } = yield call(getCall, "/user/userDashboard");
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  if (response) yield put(dashboardData(response["data"]["body"]));
}

function* userDashHeader() {
  const { error, response } = yield call(getCall, "/user/progress");
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  if (response) yield put(dashHeaderData(response["data"]["body"]));
}

function* userDashChart() {
  const { error, response } = yield call(getCall, "/user/chart");
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  if (response) yield put(dashChartData(response["data"]["body"]));
}

function* getUserCards() {
  const { response } = yield call(getCall, "/wallet/cards");
  if (response) yield put({ type: "GET_USER_CARDS_RESPONSE", payload: response["data"]["body"]});
  yield put(overlayLoader({status:false, loadingMessage:''}));
}

function* getWalletData() {
  const { error, response } = yield call(getCall, "/wallet");
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  if (response) yield put({ type: "GET_WALLET_DATA_RESPONSE", payload: response["data"]["body"]});
}

function* getHistoryDW() {
  const { error, response } = yield call(getCall, "/wallet/history");
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  if (response) yield put({ type: "GET_HISTORY_DW_DATA_RESPONSE", payload: response["data"]["body"] });
  yield put(overlayLoader({status:false, loadingMessage:''}));
}

function* getBalance() {
  const { error, response } = yield call(getCall, "/wallet/balance");
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  if (response) yield put({ type: "GET_BALANCE_RESPONSE", payload: response["data"]["body"] });
  yield put(overlayLoader({status:false, loadingMessage:''}));
}

function* plaidInfo() {
  const { error, response } = yield call(getCall, "/wallet/plaidInfo");
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  if (response) yield put({ type: "PLAID_DATA", payload: response["data"]["body"] });
}

function* plaidSetup({payload}) {
  const { error, response } = yield call(getCall, `/wallet/plaidSetup/${payload}`);
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  if (response) yield put({ type: "PLAID_DATA_SIGNUP", payload: response["data"]["body"] });
}

function* tkusdInfo() {
  const { error, response } = yield call(getCall, "/wallet/stableInfo");
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  if (response) yield put({ type: "TKUSD_DATA", payload: response["data"]["body"] });
}

function* submitAccredation() {
  const { error, response } = yield call(getCall, "/user/accreditation");
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  if (response) yield put({ type: "USER_DASHBOARD" });
}

function* onSendSmsAuth() {
  const { error, response } = yield call(getCall, "/user/sendSmsAuth");
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  if (response) EventBus.publish("success", response["data"]["message"]);
}

function* onSendSmsSignUp({payload}) {
  const { error, response } = yield call(postCall,{path:"/user/signupSMS", body: payload} );
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  if (response) EventBus.publish("success", response["data"]["message"]);
}

function* onVerifySmsAuth({ payload, history }) {
  const { error, response } = yield call(postCall, { path: "/user/verifySmsAuth", body: payload });
  if (error) {
    yield put({ type: "OTP_LOADER", payload: false });
    EventBus.publish("error", error["response"]["data"]["message"]);
  }
  if (response) {
    EventBus.publish("success", response["data"]);
    yield put({ type: "HANDLE_LOGIN_AUTH_MODAL", payload: false });
    yield put({ type: "OTP_LOADER", payload: false });
    setTimeout(() => history.push("/dashboard"), 500);
  }
}

function* verify2FAuth({ payload, history }) {
  yield put(otpLoader(true));
  const { error, response } = yield call(postCall, { path: "/user/verify2FAuth", body: payload });
  yield put(otpLoader(false));
  EventBus.publish("invalid2fa", "empty");
  if (error) {
    yield put({ type: "OTP_LOADER", payload: false });
    EventBus.publish("error", error["response"]["data"]["message"]);
  }
  if (response) {
    EventBus.publish("success", response["data"]);
    setTimeout(() => history.push("/dashboard"), 500);
    yield put({ type: "OTP_LOADER", payload: false });
    yield put({ type: "HANDLE_LOGIN_AUTH_MODAL", payload: false });
  }
}

function* getAllBlogs({ payload }) {
  const { error, response } = yield call(postCall, { path: '/blog', body: payload });
  if (error) EventBus.publish("error", error.response.data.message)
  else if (response) {
    yield put({ type: 'GET_BLOGS_RESPONSE', payload: response.data.body })
  }
}

function* getKycStep() {
  const { error, response } =  yield call(getCall, '/user/KycStep');
  if (error) EventBus.publish("error", error.response.data.message)
  else if (response) {
    yield put({ type: 'CURRENT_KYC_STEP', payload: response.data.body.isUploaded })
  }
}

function* updateProfile({ payload }) {
  const { error, response } = yield call(postCall, { path: '/user/dwollaSignup', body: payload });
  if (error) {
    EventBus.publish("error", error.response.data.message)
  }
  else if (response) {
    EventBus.publish("success", response.data.message)
    EventBus.publish("profileUpdated", "profileUpdated")
  }
}

function* submitPremiumRequest({payload}) {
  const { error, response } = yield call(postCall, { path: '/user/premiumRequest', body: payload });
  if (error) {
    EventBus.publish("error", error.response.data.message)
  }
  else if (response) {
    EventBus.publish("success", response.data.message)
    yield put(getProfile());
  }
}

function* favouriteProperties({ payload, onFavourites }) {
  const { error, response } = yield call(getCall, "/property/favProperties");
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  else if (response) {
    if (payload['_id']) {
      yield put({ type: "GET_SINGLE_PROPERTY_RESPONSE", payload: response["data"]["body"] });
      yield put(otpLoader(false));
      yield put(overlayLoader({status:false, loadingMessage:''}));
    }
    else {
      yield put({ type: "GET_ALL_PROPERTIES_RESPONSE", payload: response["data"]["body"]["properties"] });
      yield put({ type: "FAVOURITEES", payload: response["data"]["body"]["favorites"] });
      if (onFavourites) setTimeout(() => EventBus.publish("onFavourites", onFavourites), 1000);
      yield put(otpLoader(false));
      yield put(overlayLoader({status:false, loadingMessage:''}));
    }
  }
}

function* userSubscription({ payload }) {
  const { error, response } = yield call(postCall, {
    path: `/user/subscription`,
    body: payload
  });
  if (error) EventBus.publish("error", error["response"]["data"]["message"]);
  if (response) EventBus.publish("success", response["data"]["message"]);
}

function* signupDwolla({ payload }) {
  yield put(setVerifyUser(true));
  const { error, response } = yield call(getCall, `/user/signupDwolla/${payload}`);
  yield put(setVerifyUser(false));
  if (error){
    EventBus.publish("error", error["response"]["data"]["message"]);
    if(error["response"]["data"]["body"] && !Array.isArray(error["response"]["data"]["body"]))
    yield put({ type: "GET_SIGN_DATA", payload: error["response"]["data"]["body"] });
  }
  if (response){
    EventBus.publish("success", response["data"]["message"]);
    yield put({ type: "GET_SIGN_DATA", payload: response["data"]["body"] });
  }
}

function* retryDwolla({ payload }) {
  yield put(setVerifyUser(true));
  const { error, response } = yield call(getCall, `/user/retryDwolla/${payload}`);
  yield put(setVerifyUser(false));
  if (error) {
    EventBus.publish("error", error["response"]["data"]["message"]);
    if(error["response"]["data"]["body"] && !Array.isArray(error["response"]["data"]["body"]))
      yield put({ type: "GET_SIGN_DATA", payload: error["response"]["data"]["body"] });
  }
  if (response) {
    EventBus.publish("success", response["data"]["message"]);
    yield put({ type: "GET_SIGN_DATA", payload: response["data"]["body"] });
  }
}

function* actionWatcher() {


  yield takeLeading("SEND_SMS_SIGNUP", onSendSmsSignUp);
  yield takeLeading("RETRY_DWOLLA", retryDwolla);
  yield takeLeading("SIGNUP_DWOLLA", signupDwolla);
  yield takeLeading("SUBMIT_ACCREDATION", submitAccredation);
  yield takeLeading("PLAID_INFO", plaidInfo);
  yield takeLeading("PLAID_SETUP", plaidSetup);
  yield takeLeading("TKUSD_INFO", tkusdInfo);
  yield takeLeading("LOGIN_REQUEST", loginRequest);
  yield takeLeading("LOGIN_AUTH", handleloginAuth);
  yield takeLeading("CHECK_EMAIL", checkEmail);
  yield takeLeading("PAYPAL_DEPOSIT", paypalDeposit);
  yield takeLeading("USER_DASHBOARD", userDashboard);
  yield takeLeading("USER_DASH_HEADER", userDashHeader);
  yield takeLeading("USER_DASH_CHART", userDashChart);
  yield takeLeading("SIGNUP_REQUEST", signupRequest);
  yield takeLeading("RESET_PROCESS", resetProcess);
  yield takeLeading("CONTINUE_PROCESS", continueProcess);
  yield takeLeading("SIGNUP_CONTACT_REQUEST", contactSignInfo);
  yield takeLeading("SIGNUP_TRUST_REQUEST", trustSignInfo);
  yield takeLeading("SIGNUP_JOINT_REQUEST", jointSignInfo);
  yield takeLeading("SIGNUP_ENTITY_REQUEST", entitySignInfo);
  yield takeLeading("SET_ACCOUNT_TYPE", setAccountType);
  yield takeLeading("SIGNUP_DOCUMENTS_REQUEST", documentsSignInfo);
  yield takeLeading("SIGNUP_ADD_BANK", signAddBank);
  yield takeLeading("SIGNUP_SKIP_BANK", signSkipBank);
  yield takeLeading("GET_AGREEMENTS", getAgreements);
  yield takeLeading("USER_SIGN_DATA", getUserSignData);
  yield takeLeading("AGREEMENTS_CHECKED", agreementsChecked);
  yield takeLeading("UPDATE_USER", updateUser);
  yield takeLeading("SIGN_MESSAGE", handleSignMessage);
  yield takeLeading("CONNECT_ETH", connectETH);
  yield takeLeading("INVEST_NOW", investNow);
  yield takeLeading("SUBMIT_PROPERTY", submitProperty);
  yield takeLeading("GET_ALL_PROPERTIES", getAllProperties);
  yield takeLeading("DEPOSITE_PAYMENT", depositePayment);
  yield takeLeading("GET_USER_CARDS", getUserCards);
  yield takeLeading("USER_SUBSCRIBE", userSubscription);
  yield takeLeading("GET_WALLET_DATA", getWalletData);
  yield takeLeading("GET_HISTORY_DW", getHistoryDW);
  yield takeLeading("GET_BALANCE", getBalance);
  yield takeLeading("PLAID_UPLOAD", plaidUpload);
  yield takeLeading("VERIFY_SMS_AUTH", onVerifySmsAuth);
  yield takeLeading("SEND_SMS_AUTH", onSendSmsAuth);
  yield takeLeading("VERIFY_2FA_AUTH", verify2FAuth);
  yield takeLeading('GET_ALL_BLOGS', getAllBlogs)
  yield takeLeading('GET_KYC_STEP', getKycStep)
  yield takeLeading("UPDATE_PROFILE", updateProfile);
  yield takeLeading('GET_SINGLE_PROPERTY_DETAILS', getSinglePropertyDetails);
  yield takeLeading('SET_COOKIES', setCookiesData);
  yield takeLeading('GET_COOKIES_RES', getCookiesData);
  yield takeLeading("FAVOURITE_PROPERTIES", favouriteProperties);
  yield takeLeading("SUBMIT_PREMIUM_REQUEST", submitPremiumRequest);
  // yield takeLeading("SEND_SMS_AUTH", onSendSmsAuth);
}

function postCall({ body, path }) {
  return axios
    .post(path, body)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401){
      EventBus.publish("tokenExpired");
      return ;
      }
      return { error };
    });
}

function getCall(path) {
  return axios
    .get(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401){
      EventBus.publish("tokenExpired");
      return ;
      }
      return { error };
    });
}

function putCall({ body, path }) {
  return axios
    .put(path, body)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401){
      EventBus.publish("tokenExpired");
      return ;
      }
      return { error };
    });
}

export default function* rootSaga() {
  yield all([actionWatcher()]);
}

export const getUserTokenBalance = () => ({
  type: 'GET_USER_TOKEN_BALANCE'
});

export const resetExchange = () => ({
  type: 'RESET_EXCHANGE'
});

export const responseUserTokenBalance = (data) => ({
  type: 'RESPONSE_USER_TOKEN_BALANCE',
  payload: data
});

export const getUserListingBalance = () => ({
  type: 'GET_USER_LISTING_BALANCE'
});

export const responseUserListingBalance = (data) => ({
  type: 'RESPONSE_USER_LISTING_BALANCE',
  payload: data
});

export const sellTokens = (data) => ({
  type: 'SELL_TOKENS',
  payload: data
});

export const activeOffers = (data) => ({
  type: 'ACTIVE_OFFERS',
  payload: data
});

export const responseActiveOffers = (data) => ({
  type: 'RESPONSE_ACTIVE_OFFERS',
  payload: data
});

export const cancelOffer = (data) => ({
  type: 'CANCEL_OFFER',
  payload: data
});

export const cancelContract = (data) => ({
  type: 'CANCEL_CONTRACT',
  payload: data
});

export const sellOffers = ({ data }) => ({
  type: 'SELL_OFFERS',
  payload: data
});

export const sellOffersReceived = (data) => ({
  type: 'SELL_OFFERS_RECEIVED',
  payload: data
});

export const transactionHistory = () => ({
  type: 'TRANSACTION_HISTORY'
});

export const responseTransactionHistory = (data) => ({
  type: 'RESPONSE_TRANSACTION_HISTORY',
  payload: data
});

export const buyOffer = (data) => ({
  type: 'BUY_OFFER',
  payload: data
});

export const buyCounter = ({ data, history }) => ({
  type: 'BUY_COUNTER',
  payload: data,
  history
});

export const acceptCounter = (data) => ({
  type: 'ACCEPT_COUNTER',
  payload: data
});

export const rejectCounter = (data) => ({
  type: 'REJECT_COUNTER',
  payload: data
});

export const counterSeller = (data) => ({
  type: 'COUNTER_SELLER',
  payload: data
});

export const counterBuyer = (data) => ({
  type: 'COUNTER_BUYER',
  payload: data
});

import React from "react";
import { connect } from "react-redux";
import { getKyc} from "../../../store/actions/Settings";

const Step5 = ({ handelSteps,handelModal,faceImages, getKyc, history }) => {

  const [isLoader,setLoader] = React.useState(false)

  return (
    <>
      <div className="step1">
        <button className={`back-btn ${faceImages ? " next-btn" : "" }`} onClick={() => handelSteps('Back')}><i className="fas fa-angle-left"></i></button>
        <div className="step-content d-flex justify-content-between">
          <div className="">
            <h5>Submit Your request for identity verification</h5>
          </div>
          <div className="text-right w-50">
            <button onClick={() => { setLoader(true); getKyc({history});}} className="capture-btn">{isLoader ? <i className="fa fa-spinner fa-spin fa-4x fa-fw" style={{ color: '#8cc63f', fontSize:"24px" }} /> : "Submit"}</button>
          </div>
        </div>
        <div className="current-step">
          <h3>05</h3>
          <span>/05</span>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = ({ Wallet }) => {
  let { faceImages } = Wallet;
  return {
    faceImages
  };
};

const mapDispatchToProps = {
  getKyc,
};

export default connect(mapStateToProps, mapDispatchToProps)(Step5);

import React, { Component } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import Banner from '../../components/Banner'
import { getAllBlogs, updateAllBlogs } from "../../store/actions/Auth";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import LazyLoad from 'react-lazyload';
import { put } from "redux-saga/effects";

class Investing101 extends Component
{
  constructor(){
    super()
    this.state = {
        searchText:"",
        filteredArr:[],
        data:[]
    }
    window.scrollTo(0,0);
  }

  componentWillReceiveProps(props) {
    if (props.blogs) {
      // this.state = this.initialState;
      this.setState({data:props.blogs.reverse(), filteredArr:props.blogs.reverse(), blogs:props.blogs.reverse()})
    }
  }

  handleChange = (value) => {
      this.setState({searchText : value })
      let e = value;
      this.setState({ text: e });
      if (this.state.blogs) {
        this.setState({
          filteredArr: this.state.blogs.filter(item => {
            if (item.title) {
              if (
                item.title.toLowerCase().indexOf(e.toLowerCase()) > -1
              ) {
                return item;
              }
            }
          })
        });
      }
  }

  componentDidMount() {
    this.props.getAllBlogs()
  }

  componentWillUnmount() {
    this.props.updateAllBlogs()
  }

  render()
  {
    // let { blogs } = this.props;
    let { blogs } = this.props.blogs ? this.props : {};
    const { searchText } = this.state
    const { handleChange } = this
    return (

        <div className="page-wrapper">

            <div className="preloader"></div>
            <Navbar
                page='investing 101'
                {...this.props}
            />
            <Banner
                subHeading = 'Lorem ipsum dolor sit amet, consectetur adipisicing elit do eiusmod tempor consectetur'
                heading = 'Investing 101'
            />
          <section className="listing-propety-sec">
              <div className="container">
                  <div className="row">
                      <div className="content-side col-lg-9 col-md-12 col-sm-12">

                        {this.state.data.length > 0
                          ? this.state.data.map(blog => (
                          <div className="listing-block">
                            <Link to={`/investing101-details?id=${blog._id}`}>
                              <div className="row cleafix align-items-center">
                                  <div className="image-column col-lg-4 col-md-4 col-sm-12">
                                      <div className="image-box">
                                        <figure><LazyLoad once><img src={blog.thumbnail.location} alt="" /></LazyLoad></figure>
                                      </div>
                                  </div>
                                  <div className="content-column col-lg-8 col-md-8 col-sm-12">
                                      <div className="content-box">
                                          <h3>{blog.title}</h3>
                                          <p>{blog.intro} </p>
                                            <Link to={`/investing101-details?id=${blog._id}`}>
                                               Continue Reading...
                                            </Link>
                                      </div>
                                  </div>
                              </div>
                            </Link>
                          </div>
                        ))
                        : null
                      }

                          {
                            /*
                            <div className="listing-block">
                                <div className="row cleafix">
                                    <div className="image-column col-lg-4 col-md-4 col-sm-12">
                                        <div className="image-box">
                                            <figure><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/14.jpg")} alt="" /></figure>
                                        </div>
                                    </div>
                                    <div className="content-column col-lg-8 col-md-8 col-sm-12">
                                        <div className="content-box">
                                            <h3>Investing on the Tokenism Platform is as simple as a click!</h3>
                                            <ul className="info-post">
                                                <li><a href="#">0 Likes,</a></li>
                                                <li><a href="#">0 Comments,</a></li>
                                                <li><a href="#">0 Shares</a></li>
                                            </ul>
                                            <p>To get started Investors sign-up in a few minutes and browse properties of interest. They can invest after completing a SEC-
                                              mandated KYC &amp; AML process and third-party accreditation check, which can take up to 48 hours.. </p>
                                            <a href="/investing101-details">Continue Reading...</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            */
                          }


                          {/* <div className="pagination">
                              <ul>
                                  <li><a href="#" className="active">1</a></li>
                                  <li><a href="#" className="">2</a></li>
                                  <li><a href="#" className="">3</a></li>
                                  <li><a href="#" className="">4</a></li>
                                  <li><a href="#" className="">5</a></li>
                                  <li><a href="#" className=""><i className="fa fa-angle-right"></i></a></li>
                                  <li><a href="#" className=""><i className="fa fa-angle-double-right"></i></a></li>
                              </ul>
                          </div> */}
                      </div>
                      <div className="sidebar-side col-lg-3 col-md-12 col-sm-12">
                          <div className="sidebar-content">
                              <div className="widget widget-searchbar">
                                  <div className="sidebar-title">
                                      <h4>Search</h4>
                                      <FormControl className="search-form" fullWidth variant="outlined">
                                        <OutlinedInput
                                            id="outlined-adornment-amount"
                                            value={searchText}
                                            onChange={(e) => handleChange(e.target.value)}
                                            startAdornment={<InputAdornment position="end"><i className="fa fa-search"></i></InputAdornment>}
                                        />
                                        </FormControl>
                                  </div>
                              </div>
                              <div className="widget widget-recent-property">
                                  {/* <ul className="tab-btn nav nav-tabs" id="myTab" role="tablist">
                                      <li className="nav-item">
                                          <a className="nav-link active" id="home-tab" data-toggle="tab" href="#tab1" role="tab" aria-controls="home" aria-selected="true">Recent</a>
                                      </li>
                                      <li className="nav-item">
                                          <a className="nav-link" id="profile-tab" data-toggle="tab" href="#tab2" role="tab" aria-controls="profile" aria-selected="false">Popular</a>
                                      </li>
                                      <li className="nav-item">
                                          <a className="nav-link" id="contact-tab" data-toggle="tab" href="#tab3" role="tab" aria-controls="contact" aria-selected="false">Archived</a>
                                      </li>
                                      </ul> */}

                                  <div className="content-area tab-content"  id="myTabContent">
                                      <div className="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="home-tab">
                                        {this.state.filteredArr.length > 0
                                          ? this.state.filteredArr.map(blog => (
                                            <Link to={`/investing101-details?id=${blog._id}`}>
                                            <div className="recent-post">
                                                <div className="image-box">
                                                    <figure><LazyLoad once><img src={blog.thumbnail.location} alt="" /></LazyLoad></figure>
                                                </div>
                                                <div className="content-box">
                                                    <h5>{blog.title}</h5>
                                                    {/* <a href="#" className="thm-btn btn-style-one">6  Comments</a> */}
                                                </div>
                                            </div>
                                          </Link>
                                        ))
                                        : this.state.filteredArr.length > 0
                                        ? this.state.filteredArr.map(blog => (
                                          <Link to={`/investing101-details?id=${blog._id}`}>
                                            <div className="recent-post">
                                              <div className="image-box">
                                                <figure><LazyLoad once><img src={blog.thumbnail.location} alt="" /></LazyLoad></figure>
                                              </div>
                                              <div className="content-box">
                                                <h5>{blog.title}</h5>
                                                {/* <a href="#" className="thm-btn btn-style-one">6  Comments</a> */}
                                              </div>
                                            </div>
                                          </Link>

                                        ))
                                        : null
                                      }

                                      </div>

                                      <div className="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="home-tab">
                                          <div className="recent-post">
                                              <div className="image-box">
                                                  <figure><LazyLoad once><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/17.jpg" alt="" /></LazyLoad></figure>
                                              </div>
                                              <div className="content-box">
                                                  <h5>Lorem ipsum dolor sit amet</h5>
                                                  {/* <a href="#" className="thm-btn btn-style-one">6  Comments</a> */}
                                              </div>
                                          </div>

                                          <div className="recent-post">
                                              <div className="image-box">
                                                  <figure><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/18.jpg" alt="" /></figure>
                                              </div>
                                              <div className="content-box">
                                                  <h5>Lorem ipsum dolor sit amet</h5>
                                                  {/* <a href="#" className="thm-btn btn-style-one">6  Comments</a> */}
                                              </div>
                                          </div>

                                          <div className="recent-post">
                                              <div className="image-box">
                                                  <figure><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/19.jpg" alt="" /></figure>
                                              </div>
                                              <div className="content-box">
                                                  <h5>Lorem ipsum dolor sit amet</h5>
                                                  {/* <a href="#" className="thm-btn btn-style-one">6  Comments</a> */}
                                              </div>
                                          </div>

                                          <div className="recent-post">
                                              <div className="image-box">
                                                  <figure><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/20.jpg" alt="" /></figure>
                                              </div>
                                              <div className="content-box">
                                                  <h5>Lorem ipsum dolor sit amet</h5>
                                                  {/* <a href="#" className="thm-btn btn-style-one">6  Comments</a> */}
                                              </div>
                                          </div>

                                          <div className="recent-post">
                                              <div className="image-box">
                                                  <figure><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/21.jpg" alt="" /></figure>
                                              </div>
                                              <div className="content-box">
                                                  <h5>Lorem ipsum dolor sit amet</h5>
                                                  {/* <a href="#" className="thm-btn btn-style-one">6  Comments</a> */}
                                              </div>
                                          </div>

                                          <div className="recent-post">
                                              <div className="image-box">
                                                  <figure><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/22.jpg" alt="" /></figure>
                                              </div>
                                              <div className="content-box">
                                                  <h5>Lorem ipsum dolor sit amet</h5>
                                                  {/* <a href="#" className="thm-btn btn-style-one">6  Comments</a> */}
                                              </div>
                                          </div>

                                          <div className="recent-post">
                                              <div className="image-box">
                                                  <figure><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/23.jpg" alt="" /></figure>
                                              </div>
                                              <div className="content-box">
                                                  <h5>Lorem ipsum dolor sit amet</h5>
                                                  {/* <a href="#" className="thm-btn btn-style-one">6  Comments</a> */}
                                              </div>
                                          </div>
                                      </div>

                                      <div className="tab-pane fade" id="tab3" role="tabpanel" aria-labelledby="home-tab">
                                          <div className="recent-post">
                                              <div className="image-box">
                                                  <figure><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/17.jpg" alt="" /></figure>
                                              </div>
                                              <div className="content-box">
                                                  <h5>Lorem ipsum dolor sit amet</h5>
                                                  <a href="#" className="thm-btn btn-style-one">6  Comments</a>
                                              </div>
                                          </div>

                                          <div className="recent-post">
                                              <div className="image-box">
                                                  <figure><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/18.jpg" alt="" /></figure>
                                              </div>
                                              <div className="content-box">
                                                  <h5>Lorem ipsum dolor sit amet</h5>
                                                  <a href="#" className="thm-btn btn-style-one">6  Comments</a>
                                              </div>
                                          </div>

                                          <div className="recent-post">
                                              <div className="image-box">
                                                  <figure><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/19.jpg" alt="" /></figure>
                                              </div>
                                              <div className="content-box">
                                                  <h5>Lorem ipsum dolor sit amet</h5>
                                                  <a href="#" className="thm-btn btn-style-one">6  Comments</a>
                                              </div>
                                          </div>

                                          <div className="recent-post">
                                              <div className="image-box">
                                                  <figure><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/20.jpg" alt="" /></figure>
                                              </div>
                                              <div className="content-box">
                                                  <h5>Lorem ipsum dolor sit amet</h5>
                                                  <a href="#" className="thm-btn btn-style-one">6  Comments</a>
                                              </div>
                                          </div>

                                          <div className="recent-post">
                                              <div className="image-box">
                                                  <figure><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/21.jpg" alt="" /></figure>
                                              </div>
                                              <div className="content-box">
                                                  <h5>Lorem ipsum dolor sit amet</h5>
                                                  <a href="#" className="thm-btn btn-style-one">6  Comments</a>
                                              </div>
                                          </div>

                                          <div className="recent-post">
                                              <div className="image-box">
                                                  <figure><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/22.jpg" alt="" /></figure>
                                              </div>
                                              <div className="content-box">
                                                  <h5>Lorem ipsum dolor sit amet</h5>
                                                  <a href="#" className="thm-btn btn-style-one">6  Comments</a>
                                              </div>
                                          </div>

                                          <div className="recent-post">
                                              <div className="image-box">
                                                  <figure><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/23.jpg" alt="" /></figure>
                                              </div>
                                              <div className="content-box">
                                                  <h5>Lorem ipsum dolor sit amet</h5>
                                                  <a href="#" className="thm-btn btn-style-one">6  Comments</a>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          <Footer />
      </div>
    );
  }
}

const mapStateToProps = ({ Auth }) => {
  let { blogs } = Auth;
  return {
    blogs: blogs || []
  };
};

const mapDispatchToProps = { getAllBlogs, updateAllBlogs };

export default connect(mapStateToProps, mapDispatchToProps)(Investing101);

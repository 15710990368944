import EventBus from 'eventing-bus';
import { connect } from "react-redux";
import React, { Component } from 'react';
import PaypalExpressBtn from 'react-paypal-express-checkout';

import './paypal.css';
import { paypalDeposit } from '../../../../../store/actions/Auth';

const client = {
    production: process['env']['REACT_APP_PAYPAL_KEY'],
    sandbox: 'AYVrHnUoMgxrYB2-b7WkPa0TFDCdQ6HgPJIGMW1oYb3RVIXQbnqg8znJiEj4Sn0KmEvvj6moONPfiP_h',
}

let env = process['env']['REACT_APP_SERVER'] === 'production' ? 'production' : 'sandbox';

class Paypal extends Component {
    constructor() {
        super();
        this.state = {
            formData: {
                amount: '',
                currency: "USD",
                scales: false
            }
        }
    }

    handleFormChange = (event)=> {
        const { formData } = this.state;
        if (event.target.name == 'scales')
            formData[event.target.name] = event.target.checked;
        else
            formData[event.target.name] = event.target.value;
        this.setState({ formData });
    }

    onSuccess = (payment)=>
    {
        const { formData } = this.state;
        formData['paymentID'] = payment['paymentID']
        this.props.paypalDeposit(this.state.formData)
    }

    onCancel = (e) => EventBus.publish("error", 'Payment has been Canceled!');
    onError = (e) => EventBus.publish("error", `Something went wrong in Paypal`);

    render()
    {
        const { formData } = this.state
        return (
            <div className="deposite-page">
                <form className="deposit-tab" id="credit">
                    <div className="row clearfix">
                        <div className="col-lg-7 col-md-12 col-12">
                            <div className="title row">
                                <p onClick={() => this.props.onSelectMethod("none")}>
                                    <i className="fa fa-arrow-left text-secondary" aria-hidden="true" />
                                </p>
                                <i><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/54.png" alt="" /></i>
                                <h5 className='pt-3 ml-3'>You are proceeding with the payment through PayPal.</h5>
                            </div>
                            <div className="right-confrom-area style-two">
                                <div className="group-form">
                                    <label>Amount</label>
                                    <input
                                        className="control-form"
                                        maxlength="19"
                                        name="amount"
                                        placeholder="$ 5000"
                                        type="text"
                                        value={formData.amount}
                                        onChange={this.handleFormChange}
                                    />
                                    <span className="user-msg">*Minimum Amount is 1000</span>
                                    {formData.amount.toString().indexOf(".") != -1 ? <span className="user-msg">Should be integer value.</span> : ''}
                                </div>
                                <div className="group-form icon-ok">
                                    <label>You will recieve</label>
                                    <span className="icon-input"><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/49.png" alt="" /></span>
                                    <input disabled className="control-form" value={this.state.formData.amount || 0} name="amount" type="text" />
                                    <i><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/50.png" alt="" /></i>
                                </div>
                                <div className="group-form">
                                    <input type="checkbox" id="scales" name="scales" onChange={this.handleFormChange} />
                                    <label for="scales" className="checkbox-lable pl-1">By clicking continue you agree to the Tokinism Specific <a className="link" href="https://tknismtest.s3.amazonaws.com/non-compressed-images/documents/Tokenism+-+Website+Terms+of+Use.pdf" target="_blank" >Terms and Conditions</a>.</label>
                                </div>
                                <div className="group-form">
                                    {
                                        formData.amount >= 1
                                        // formData.amount >= 1000
                                        && formData.amount !== ''
                                        && formData.scales
                                        && <PaypalExpressBtn
                                            client={client}
                                            style={{ color: "black", size: "medium" }}
                                            total={formData.amount}
                                            currency={formData.currency}
                                            onError={this.onError}
                                            onCancel={this.onCancel}
                                            onSuccess={this.onSuccess}
                                            env = {env}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

const mapDispatchToProps = { paypalDeposit };

// const mapStateToProps = (state) => {
//     return { dashData: state.Auth.dashData || {} }
// }
export default connect(null, mapDispatchToProps)(Paypal);

import EventBus from 'eventing-bus';
import { connect } from "react-redux";
import React, { Component } from 'react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { submitWiresRequest, withdrawlVerification, getEmailVerification, getSmsVerification } from '../../../../../store/actions/Wallet';
import { overlayLoader } from "../../../../../store/actions/Settings";
import { web3, providerInject, magic } from '../../../../../store/web3';
import { TKUSD, TKUSDABI, TKUSDAddress, Owner, networkId } from "../../../../../store/contract";
import Web3 from "web3";
import * as Sentry from '@sentry/browser';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import { Modal, ModalBody } from "reactstrap";


let env = process['env']['REACT_APP_SERVER'] === 'production' ? 'production' : 'sandbox';
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: { marginTop: theme.spacing(2) },
}));


class Wires extends Component {
  constructor() {
    super();
    this.state = {
      isOpen:false,
      emailCode:"",
      numberCode:"",
      formData: {
        routingNo:'',
        bankName:'',
        swiftCode:'',
        accountName:'',
        amount:'',
        type:'account',
        ibanNumber:'',
        accountNumber:''
      }
    }
  }

  handleFormChange = (event)=> {
    const { formData } = this.state;
    if (event.target.name == 'scales')
    formData[event.target.name] = event.target.checked;
    else
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  }

  componentDidMount(){
    ValidatorForm.addValidationRule('isOnlyAlpha', (value) => {
      var pattern = /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/;
      // return .test(event.key)
      return (pattern.test(value))
    })
  }

  getGasPrice = ()=> {
    return new Promise(resolve=> {
      fetch('https://ethgasstation.info/api/ethgasAPI.json')
      .then(response => response.json())
      .then(data => this.setState({ gasPrice: (data['fastest'] / 10).toString() }, ()=> {
        // console.log(`**************this.state.gasPrice = `, this.state.gasPrice);
        resolve();
      }))
      .catch(e=> resolve());
    });
  }

  submitRequest =  async () =>{
    const { formData } = this.state;
    if(formData.type == 'account') {
      delete formData['ibanNumber']
    } else {
      delete formData['swiftCode']
      delete formData['routingNo']
      delete formData['accountNumber']
    }
    this.props.overlayLoader({status:true, loadingMessage:'Submitting Request'})
    try{
      const {amount, routingNo, swiftCode, bankName, accountName} = this.state.formData
      console.log('receipt', amount);

      let from = (await web3.currentProvider.enable())[0];

      let web3Injected = new Web3(providerInject);
      web3Injected.eth.handleRevert = true;
      let TKUSDData = new web3Injected.eth.Contract(TKUSDABI, TKUSDAddress);
      let withdrawFee = (await TKUSDData.methods.transfer(Owner, web3.utils.toHex(amount)).estimateGas({ from })) * 2;

      let userGas = (await web3Injected.eth.getBalance(from));
      if (userGas < withdrawFee) {
        EventBus.publish("error", "Not enough gas to perform this transaction.");
        this.props.overlayLoader({status:false, loadingMessage:''})
        return;
      }

      await this.getGasPrice();
      web3.eth.sendTransaction({
        from,
        value: 0,
        gas:withdrawFee,
        to: TKUSDAddress,
        gasPrice: web3.utils.toHex(web3.utils.toWei(this.state.gasPrice, 'gwei')),
        data: TKUSDData.methods.transfer(Owner, web3.utils.toHex(amount)).encodeABI(),
      })
      .on('transactionHash', (hash) => {
        this.props.overlayLoader({status:true, loadingMessage:'Your transaction is Mining'});
      })
      .on('receipt', (receipt) => {
        let data = {
          ...formData,
          tx: receipt['transactionHash']
        }
        console.log('receipt', data , receipt);
        this.props.submitWiresRequest(data)
      })
      .on('error', (error) => {
        if(error['message'].indexOf('Insufficient funds.'))
        EventBus.publish("error", 'Please add some ethereum to perform this actions');
        else EventBus.publish("error", error['message']);
        Sentry.captureMessage(error['message'] + ' withdraw');
        this.props.overlayLoader({status:false, loadingMessage:''});
      });
    } catch (error) {
      this.setState({ loading: false });
      EventBus.publish("error", error['message']);
      this.props.overlayLoader({status:false, loadingMessage:''});
    }
  }
  
  verifyOTP = () => {   
    var data = {
      smsCode: this.state.numberCode,
      mailCode: this.state.emailCode 
     };
    this.props.withdrawlVerification(data); 
    this.toggleModal()
    console.log('1',this.props.isVerifyWithdrawl);
    setTimeout(() => {
      if(this.props.isVerifyWithdrawl == 200) {
        console.log('2',this.props.isVerifyWithdrawl);
         this.submitRequest()
      }    
    }, 10000);
    console.log('3',this.props.isVerifyWithdrawl);
  }

  verifyWithdrawl = () => this.toggleModal()

  toggleModal = () => this.setState({isOpen:!this.state.isOpen})

  handleChange = (event) =>
  {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    console.log(formData);
    this.setState({ formData });
  }

  handleCodeChange = (event) => {
    const value = event.target.value
    const name = event.target.name
    this.setState({ [name] : value });
  }


  render()
  {
    const { formData, isOpen, emailCode, numberCode } = this.state
    const classes = useStyles;

    return (
      <div className="deposite-page">
        <form className="deposit-tab" id="credit">
          <div className="row clearfix">
            <div className="col-lg-7 col-md-12 col-12">
              <div className="title row">
                <p onClick={() => this.props.onSelectMethod("none")}>
                  <i className="fa fa-arrow-left text-secondary" aria-hidden="true" />
                </p>
                <h5 className='pt-3 ml-3'>Following are the details for wires transfer.</h5>
              </div>
              <ValidatorForm className="validatorForm" onSubmit={this.verifyWithdrawl}>
                <div className="row">
                <div className="group-form col-lg-12 col-md-12">
                    <label>
                      Transfer through <span className="required">*</span>
                  </label>
                  <FormControl variant="outlined" fullWidth className={classes.formControl}>
                    {/* <InputLabel id="demo-simple-select-outlined-label">
                      Select Property
                    </InputLabel> */}
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={formData.type}
                      type="text"
                      name="type"
                      onChange={this.handleChange}
                      // labelWidth={110}
                    >
                      <MenuItem value="account">Account Number</MenuItem>
                      <MenuItem value="iban">IBAN</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                </div>
                <div className="row">
                  <div className="group-form col-lg-6 col-md-12">
                    <label>
                      Account Name <span className="required">*</span>
                  </label>
                  <TextValidator
                    fullWidth
                    className="MyTextField2"
                    onChange={this.handleChange}
                    name="accountName"
                    type="text"
                    margin="dense"
                    variant="outlined"
                    validators={['required', 'isOnlyAlpha']}
                    errormessages={['Account Name can not be empty','Invalid account name']}
                    value={formData.accountName}
                    />
                </div>

                <div className="group-form col-lg-6 col-md-12">
                  <label>
                    Bank Name <span className="required">*</span>
                </label>
                <TextValidator
                  fullWidth
                  className="MyTextField2"
                  onChange={this.handleChange}
                  name="bankName"
                  type="text"
                  margin="dense"
                  variant="outlined"
                  validators={['required', 'isOnlyAlpha']}
                  errormessages={['Account Name can not be empty','Invalid account name']}
                  value={formData.bankName}
                  />
              </div>
            </div>

                {formData.type == "account" ?
                  <div className="row">
                  <div className="group-form col-lg-6 col-md-12">
                    <label>
                      Account Number <span className="required">*</span>
                  </label>
                  <TextValidator
                    fullWidth
                    className="MyTextField2"
                    onChange={this.handleChange}
                    name="accountNumber"
                    type="number"
                    margin="dense"
                    variant="outlined"
                    validators={['required']}
                    errormessages={['Routing number can not be empty']}
                    value={formData.accountNumber}
                    />
                </div>
                <div className="group-form col-lg-6 col-md-12">
                    <label>
                      Routing Number <span className="required">*</span>
                  </label>
                  <TextValidator
                    fullWidth
                    className="MyTextField2"
                    onChange={this.handleChange}
                    name="routingNo"
                    type="number"
                    margin="dense"
                    variant="outlined"
                    validators={['required']}
                    errormessages={['Routing number can not be empty']}
                    value={formData.routingNo}
                    />
                </div>
                  <div className="group-form col-lg-6 col-md-12">
                  <label>
                    Swift Code <span className="required">*</span>
                </label>
                <TextValidator
                  fullWidth
                  maxlength="19"
                  minlength="4"
                  className="MyTextField2"
                  onChange={this.handleChange}
                  name="swiftCode"
                  type="number"
                  margin="dense"
                  variant="outlined"
                  validators={['required']}
                  errormessages={['Swift can not be empty']}
                  value={formData.swiftCode}
                  />
              </div>
                </div>
                  :
                  <div className="row">
                    <div className="group-form col-lg-12 col-md-12">
                    <label>
                      IBAN Number<span className="required">*</span>
                  </label>
                  <TextValidator
                    fullWidth
                    className="MyTextField2"
                    onChange={this.handleChange}
                    name="ibanNumber"
                    type="text"
                    margin="dense"
                    variant="outlined"
                    validators={['required']}
                    errormessages={['IBAN number can not be empty']}
                    value={formData.ibanNumber}
                    />
                </div>
                  </div>
                }
                <div className="row">
                  <div className="group-form col-lg-6 col-md-12">
                    <label>
                      Amount <span className="required">*</span>
                  </label>
                  <TextValidator
                    fullWidth
                    className="MyTextField2"
                    onChange={this.handleChange}
                    name="amount"
                    type="number"
                    margin="dense"
                    variant="outlined"
                    validators={['required']}
                    errormessages={['Amount can not be empty']}
                    value={formData.amount}
                    />
                </div>
                </div>
                <button
                  className="thm-btn btn-style-one"
                  >
                  verify & Continue
                </button>
              </ValidatorForm>
            </div>
          </div>
        </form>
        <Modal
          modalClassName="modal-mini modal-info uploaded verification-modal"
          toggle={this.toggleModal}
          isOpen={isOpen}
          >
                <div className="modal-header justify-content-center">
                  <div className="modal-profile">
                    <img className='modalLogoImg' src="https://tknismtest.s3.amazonaws.com/non-compressed-images/Logo0.png" alt="" />
                  </div>
                  <div className="right">
                    <img
                      onClick={this.toggleModal}
                      className="modalLogoImg"
                      src="https://tknismtest.s3.amazonaws.com/non-compressed-images/close-icon.png"
                      alt=""
                    />
                  </div>
                </div>
                <ModalBody>
              <h5 className="title">Security Verification</h5>
              <p>To secure your account, please complete the following verification.</p>
                  <ValidatorForm className="validatorForm mt-3 d-flex" >
                  <TextValidator
                    fullWidth
                    className="MyTextField2 mr-3 col-8"
                    onChange={this.handleCodeChange}
                    name="emailCode"
                    label="E-mail verification code"
                    type="number"
                    margin="dense"
                    variant="outlined"
                    validators={['required']}
                    errormessages={['Amount can not be empty']}
                    value={emailCode}
                    />
                    <button type="button" className="custom-btn-full my-1" onClick={() => this.props.getEmailVerification()}>send</button>
                  </ValidatorForm>
                  <ValidatorForm className="validatorForm mt-3 d-flex">
                  <TextValidator
                    fullWidth
                    className="MyTextField2 mr-3 col-8"
                    onChange={this.handleCodeChange}
                    name="numberCode"
                    label="Number verification code"
                    type="number"
                    margin="dense"
                    variant="outlined"
                    validators={['required']}
                    errormessages={['Amount can not be empty']}
                    value={numberCode}
                    />
                    <button type="button" className="custom-btn-full my-1" onClick={() => this.props.getSmsVerification()}>send</button>
                  </ValidatorForm>
                  <button type="button" className="custom-btn-full mt-4 mb-2 py-2" onClick={this.verifyOTP}>Submit Request</button>
                </ModalBody>
          </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = { submitWiresRequest, overlayLoader, withdrawlVerification, getSmsVerification, getEmailVerification };

const mapStateToProps = (state) => {
  return  {
    wires: state.Wallet.wires,
    isVerifyWithdrawl: state.Wallet.isVerifyWithdrawl
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Wires);

import React, { Component, Fragment } from "react";
import { Modal, ModalBody } from "reactstrap";

export const InfoModal = ({toggle, isOpen, infoMessage, infoHeading, infoButton }) => {
  return (
    <Modal
    modalClassName="modal-mini modal-info uploaded"
    toggle={toggle}
    isOpen={isOpen}
    >
          <div className="modal-header justify-content-center">
            <div className="modal-profile">
              <img className='modalLogoImg' src="https://tknismtest.s3.amazonaws.com/non-compressed-images/Logo0.png" alt="" />
            </div>
            <div className="right">
              <img
                onClick={toggle}
                className="modalLogoImg"
                src="https://tknismtest.s3.amazonaws.com/non-compressed-images/close-icon.png"
                alt=""
              />
            </div>
          </div>
          <ModalBody>
            <div className="pt-3">
              <h5 className="title">{infoHeading}</h5>
              <p className="para">{infoMessage}</p>
            </div>
          </ModalBody>
          {
            infoButton &&
            <div className="modal-footer">
              <button type="button" onClick={toggle} className="custom-btn-full">{infoButton}</button>
            </div>
          }
    </Modal>
  )
}

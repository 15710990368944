const Countries = [
  {
      "name": "United States",
      "phone": "1",
      "short": "US"
  },
  {
      "name": "Afghanistan",
      "phone": "93",
      "short": "AF"
  },
  {
      "name": "Albania",
      "phone": "355",
      "short": "AL"
  },
  {
      "name": "Algeria",
      "phone": "213",
      "short": "DZ"
  },
  {
      "name": "Alland Islands",
      "phone": "358",
      "short": "AX"
  },
  {
      "name": "American Samoa",
      "phone": "1-684",
      "short": "AS"
  },
  {
      "name": "Andorra",
      "phone": "376",
      "short": "AD"
  },
  {
      "name": "Angola",
      "phone": "244",
      "short": "AO"
  },
  {
      "name": "Anguilla",
      "phone": "1-264",
      "short": "AI"
  },
  {
      "name": "Antarctica",
      "phone": "672",
      "short": "AQ"
  },
  {
      "name": "Antigua and Barbuda",
      "phone": "1-268",
      "short": "AG"
  },
  {
      "name": "Argentina",
      "phone": "54",
      "short": "AR"
  },
  {
      "name": "Armenia",
      "phone": "374",
      "short": "AM"
  },
  {
      "name": "Aruba",
      "phone": "297",
      "short": "AW"
  },
  {
      "name": "Australia",
      "phone": "61",
      "short": "AU"
  },
  {
      "name": "Austria",
      "phone": "43",
      "short": "AT"
  },
  {
      "name": "Azerbaijan",
      "phone": "994",
      "short": "AZ"
  },
  {
      "name": "Bahamas",
      "phone": "1-242",
      "short": "BS"
  },
  {
      "name": "Bahrain",
      "phone": "973",
      "short": "BH"
  },
  {
      "name": "Bangladesh",
      "phone": "880",
      "short": "BD"
  },
  {
      "name": "Barbados",
      "phone": "1-246",
      "short": "BB"
  },
  {
      "name": "Belarus",
      "phone": "375",
      "short": "BY"
  },
  {
      "name": "Belgium",
      "phone": "32",
      "short": "BE"
  },
  {
      "name": "Belize",
      "phone": "501",
      "short": "BZ"
  },
  {
      "name": "Benin",
      "phone": "229",
      "short": "BJ"
  },
  {
      "name": "Bermuda",
      "phone": "1-441",
      "short": "BM"
  },
  {
      "name": "Bhutan",
      "phone": "975",
      "short": "BT"
  },
  {
      "name": "Bolivia",
      "phone": "591",
      "short": "BO"
  },
  {
      "name": "Bosnia and Herzegovina",
      "phone": "387",
      "short": "BA"
  },
  {
      "name": "Botswana",
      "phone": "267",
      "short": "BW"
  },
  {
      "name": "Bouvet Island",
      "phone": "47",
      "short": "BV"
  },
  {
      "name": "Brazil",
      "phone": "55",
      "short": "BR"
  },
  {
      "name": "British Indian Ocean Territory",
      "phone": "246",
      "short": "IO"
  },
  {
      "name": "British Virgin Islands",
      "phone": "1-284",
      "short": "VG"
  },
  {
      "name": "Brunei Darussalam",
      "phone": "673",
      "short": "BN"
  },
  {
      "name": "Bulgaria",
      "phone": "359",
      "short": "BG"
  },
  {
      "name": "Burkina Faso",
      "phone": "226",
      "short": "BF"
  },
  {
      "name": "Burundi",
      "phone": "257",
      "short": "BI"
  },
  {
      "name": "Cambodia",
      "phone": "855",
      "short": "KH"
  },
  {
      "name": "Cameroon",
      "phone": "237",
      "short": "CM"
  },
  {
      "name": "Canada",
      "phone": "1",
      "short": "CA"
  },
  {
      "name": "Cape Verde",
      "phone": "238",
      "short": "CV"
  },
  {
      "name": "Cayman Islands",
      "phone": "1-345",
      "short": "KY"
  },
  {
      "name": "Central African Republic",
      "phone": "236",
      "short": "CF"
  },
  {
      "name": "Chad",
      "phone": "235",
      "short": "TD"
  },
  {
      "name": "Chile",
      "phone": "56",
      "short": "CL"
  },
  {
      "name": "China",
      "phone": "86",
      "short": "CN"
  },
  {
      "name": "Christmas Island",
      "phone": "61",
      "short": "CX"
  },
  {
      "name": "Cocos (Keeling) Islands",
      "phone": "61",
      "short": "CC"
  },
  {
      "name": "Colombia",
      "phone": "57",
      "short": "CO"
  },
  {
      "name": "Comoros",
      "phone": "269",
      "short": "KM"
  },
  {
      "name": "Congo, Democratic Republic of the",
      "phone": "243",
      "short": "CD"
  },
  {
      "name": "Congo, Republic of the",
      "phone": "242",
      "short": "CG"
  },
  {
      "name": "Cook Islands",
      "phone": "682",
      "short": "CK"
  },
  {
      "name": "Costa Rica",
      "phone": "506",
      "short": "CR"
  },
  {
      "name": "Cote dIvoire",
      "phone": "225",
      "short": "CI"
  },
  {
      "name": "Croatia",
      "phone": "385",
      "short": "HR"
  },
  {
      "name": "Cuba",
      "phone": "53",
      "short": "CU"
  },
  {
      "name": "Curacao",
      "phone": "599",
      "short": "CW"
  },
  {
      "name": "Cyprus",
      "phone": "357",
      "short": "CY"
  },
  {
      "name": "Czech Republic",
      "phone": "420",
      "short": "CZ"
  },
  {
      "name": "Denmark",
      "phone": "45",
      "short": "DK"
  },
  {
      "name": "Djibouti",
      "phone": "253",
      "short": "DJ"
  },
  {
      "name": "Dominica",
      "phone": "1-767",
      "short": "DM"
  },
  {
      "name": "Dominican Republic",
      "phone": "1-809",
      "short": "DO"
  },
  {
      "name": "Ecuador",
      "phone": "593",
      "short": "EC"
  },
  {
      "name": "Egypt",
      "phone": "20",
      "short": "EG"
  },
  {
      "name": "El Salvador",
      "phone": "503",
      "short": "SV"
  },
  {
      "name": "Equatorial Guinea",
      "phone": "240",
      "short": "GQ"
  },
  {
      "name": "Eritrea",
      "phone": "291",
      "short": "ER"
  },
  {
      "name": "Estonia",
      "phone": "372",
      "short": "EE"
  },
  {
      "name": "Ethiopia",
      "phone": "251",
      "short": "ET"
  },
  {
      "name": "Falkland Islands (Malvinas)",
      "phone": "500",
      "short": "FK"
  },
  {
      "name": "Faroe Islands",
      "phone": "298",
      "short": "FO"
  },
  {
      "name": "Fiji",
      "phone": "679",
      "short": "FJ"
  },
  {
      "name": "Finland",
      "phone": "358",
      "short": "FI"
  },
  {
      "name": "France",
      "phone": "33",
      "short": "FR"
  },
  {
      "name": "French Guiana",
      "phone": "594",
      "short": "GF"
  },
  {
      "name": "French Polynesia",
      "phone": "689",
      "short": "PF"
  },
  {
      "name": "French Southern Territories",
      "phone": "262",
      "short": "TF"
  },
  {
      "name": "Gabon",
      "phone": "241",
      "short": "GA"
  },
  {
      "name": "Gambia",
      "phone": "220",
      "short": "GM"
  },
  {
      "name": "Georgia",
      "phone": "995",
      "short": "GE"
  },
  {
      "name": "Germany",
      "phone": "49",
      "short": "DE"
  },
  {
      "name": "Ghana",
      "phone": "233",
      "short": "GH"
  },
  {
      "name": "Gibraltar",
      "phone": "350",
      "short": "GI"
  },
  {
      "name": "Greece",
      "phone": "30",
      "short": "GR"
  },
  {
      "name": "Greenland",
      "phone": "299",
      "short": "GL"
  },
  {
      "name": "Grenada",
      "phone": "1-473",
      "short": "GD"
  },
  {
      "name": "Guadeloupe",
      "phone": "590",
      "short": "GP"
  },
  {
      "name": "Guam",
      "phone": "1-671",
      "short": "GU"
  },
  {
      "name": "Guatemala",
      "phone": "502",
      "short": "GT"
  },
  {
      "name": "Guernsey",
      "phone": "44",
      "short": "GG"
  },
  {
      "name": "Guinea",
      "phone": "224",
      "short": "GN"
  },
  {
      "name": "Guinea-Bissau",
      "phone": "245",
      "short": "GW"
  },
  {
      "name": "Guyana",
      "phone": "592",
      "short": "GY"
  },
  {
      "name": "Haiti",
      "phone": "509",
      "short": "HT"
  },
  {
      "name": "Heard Island and McDonald Islands",
      "phone": "672",
      "short": "HM"
  },
  {
      "name": "Holy See (Vatican City State)",
      "phone": "379",
      "short": "VA"
  },
  {
      "name": "Honduras",
      "phone": "504",
      "short": "HN"
  },
  {
      "name": "Hong Kong",
      "phone": "852",
      "short": "HK"
  },
  {
      "name": "Hungary",
      "phone": "36",
      "short": "HU"
  },
  {
      "name": "Iceland",
      "phone": "354",
      "short": "IS"
  },
  {
      "name": "India",
      "phone": "91",
      "short": "IN"
  },
  {
      "name": "Indonesia",
      "phone": "62",
      "short": "ID"
  },
  {
      "name": "Iran, Islamic Republic of",
      "phone": "98",
      "short": "IR"
  },
  {
      "name": "Iraq",
      "phone": "964",
      "short": "IQ"
  },
  {
      "name": "Ireland",
      "phone": "353",
      "short": "IE"
  },
  {
      "name": "Isle of Man",
      "phone": "44",
      "short": "IM"
  },
  {
      "name": "Israel",
      "phone": "972",
      "short": "IL"
  },
  {
      "name": "Italy",
      "phone": "39",
      "short": "IT"
  },
  {
      "name": "Jamaica",
      "phone": "1-876",
      "short": "JM"
  },
  {
      "name": "Japan",
      "phone": "81",
      "short": "JP"
  },
  {
      "name": "Jersey",
      "phone": "44",
      "short": "JE"
  },
  {
      "name": "Jordan",
      "phone": "962",
      "short": "JO"
  },
  {
      "name": "Kazakhstan",
      "phone": "7",
      "short": "KZ"
  },
  {
      "name": "Kenya",
      "phone": "254",
      "short": "KE"
  },
  {
      "name": "Kiribati",
      "phone": "686",
      "short": "KI"
  },
  {
      "name": "Korea, Democratic People's Republic of",
      "phone": "850",
      "short": "KP"
  },
  {
      "name": "Korea, Republic of",
      "phone": "82",
      "short": "KR"
  },
  {
      "name": "Kosovo",
      "phone": "383",
      "short": "XK"
  },
  {
      "name": "Kuwait",
      "phone": "965",
      "short": "KW"
  },
  {
      "name": "Kyrgyzstan",
      "phone": "996",
      "short": "KG"
  },
  {
      "name": "Lao People's Democratic Republic",
      "phone": "856",
      "short": "LA"
  },
  {
      "name": "Latvia",
      "phone": "371",
      "short": "LV"
  },
  {
      "name": "Lebanon",
      "phone": "961",
      "short": "LB"
  },
  {
      "name": "Lesotho",
      "phone": "266",
      "short": "LS"
  },
  {
      "name": "Liberia",
      "phone": "231",
      "short": "LR"
  },
  {
      "name": "Libya",
      "phone": "218",
      "short": "LY"
  },
  {
      "name": "Liechtenstein",
      "phone": "423",
      "short": "LI"
  },
  {
      "name": "Lithuania",
      "phone": "370",
      "short": "LT"
  },
  {
      "name": "Luxembourg",
      "phone": "352",
      "short": "LU"
  },
  {
      "name": "Macao",
      "phone": "853",
      "short": "MO"
  },
  {
      "name": "Macedonia, the Former Yugoslav Republic of",
      "phone": "389",
      "short": "MK"
  },
  {
      "name": "Madagascar",
      "phone": "261",
      "short": "MG"
  },
  {
      "name": "Malawi",
      "phone": "265",
      "short": "MW"
  },
  {
      "name": "Malaysia",
      "phone": "60",
      "short": "MY"
  },
  {
      "name": "Maldives",
      "phone": "960",
      "short": "MV"
  },
  {
      "name": "Mali",
      "phone": "223",
      "short": "ML"
  },
  {
      "name": "Malta",
      "phone": "356",
      "short": "MT"
  },
  {
      "name": "Marshall Islands",
      "phone": "692",
      "short": "MH"
  },
  {
      "name": "Martinique",
      "phone": "596",
      "short": "MQ"
  },
  {
      "name": "Mauritania",
      "phone": "222",
      "short": "MR"
  },
  {
      "name": "Mauritius",
      "phone": "230",
      "short": "MU"
  },
  {
      "name": "Mayotte",
      "phone": "262",
      "short": "YT"
  },
  {
      "name": "Mexico",
      "phone": "52",
      "short": "MX"
  },
  {
      "name": "Micronesia, Federated States of",
      "phone": "691",
      "short": "FM"
  },
  {
      "name": "Moldova, Republic of",
      "phone": "373",
      "short": "MD"
  },
  {
      "name": "Monaco",
      "phone": "377",
      "short": "MC"
  },
  {
      "name": "Mongolia",
      "phone": "976",
      "short": "MN"
  },
  {
      "name": "Montenegro",
      "phone": "382",
      "short": "ME"
  },
  {
      "name": "Montserrat",
      "phone": "1-664",
      "short": "MS"
  },
  {
      "name": "Morocco",
      "phone": "212",
      "short": "MA"
  },
  {
      "name": "Mozambique",
      "phone": "258",
      "short": "MZ"
  },
  {
      "name": "Myanmar",
      "phone": "95",
      "short": "MM"
  },
  {
      "name": "Namibia",
      "phone": "264",
      "short": "NA"
  },
  {
      "name": "Nauru",
      "phone": "674",
      "short": "NR"
  },
  {
      "name": "Nepal",
      "phone": "977",
      "short": "NP"
  },
  {
      "name": "Netherlands",
      "phone": "31",
      "short": "NL"
  },
  {
      "name": "New Caledonia",
      "phone": "687",
      "short": "NC"
  },
  {
      "name": "New Zealand",
      "phone": "64",
      "short": "NZ"
  },
  {
      "name": "Nicaragua",
      "phone": "505",
      "short": "NI"
  },
  {
      "name": "Niger",
      "phone": "227",
      "short": "NE"
  },
  {
      "name": "Nigeria",
      "phone": "234",
      "short": "NG"
  },
  {
      "name": "Niue",
      "phone": "683",
      "short": "NU"
  },
  {
      "name": "Norfolk Island",
      "phone": "672",
      "short": "NF"
  },
  {
      "name": "Northern Mariana Islands",
      "phone": "1-670",
      "short": "MP"
  },
  {
      "name": "Norway",
      "phone": "47",
      "short": "NO"
  },
  {
      "name": "Oman",
      "phone": "968",
      "short": "OM"
  },
  {
      "name": "Pakistan",
      "phone": "92",
      "short": "PK"
  },
  {
      "name": "Palau",
      "phone": "680",
      "short": "PW"
  },
  {
      "name": "Palestine, State of",
      "phone": "970",
      "short": "PS"
  },
  {
      "name": "Panama",
      "phone": "507",
      "short": "PA"
  },
  {
      "name": "Papua New Guinea",
      "phone": "675",
      "short": "PG"
  },
  {
      "name": "Paraguay",
      "phone": "595",
      "short": "PY"
  },
  {
      "name": "Peru",
      "phone": "51",
      "short": "PE"
  },
  {
      "name": "Philippines",
      "phone": "63",
      "short": "PH"
  },
  {
      "name": "Pitcairn",
      "phone": "870",
      "short": "PN"
  },
  {
      "name": "Poland",
      "phone": "48",
      "short": "PL"
  },
  {
      "name": "Portugal",
      "phone": "351",
      "short": "PT"
  },
  {
      "name": "Puerto Rico",
      "phone": "1",
      "short": "PR"
  },
  {
      "name": "Qatar",
      "phone": "974",
      "short": "QA"
  },
  {
      "name": "Reunion",
      "phone": "262",
      "short": "RE"
  },
  {
      "name": "Romania",
      "phone": "40",
      "short": "RO"
  },
  {
      "name": "Russian Federation",
      "phone": "7",
      "short": "RU"
  },
  {
      "name": "Rwanda",
      "phone": "250",
      "short": "RW"
  },
  {
      "name": "Saint Barthelemy",
      "phone": "590",
      "short": "BL"
  },
  {
      "name": "Saint Helena",
      "phone": "290",
      "short": "SH"
  },
  {
      "name": "Saint Kitts and Nevis",
      "phone": "1-869",
      "short": "KN"
  },
  {
      "name": "Saint Lucia",
      "phone": "1-758",
      "short": "LC"
  },
  {
      "name": "Saint Martin (French part)",
      "phone": "590",
      "short": "MF"
  },
  {
      "name": "Saint Pierre and Miquelon",
      "phone": "508",
      "short": "PM"
  },
  {
      "name": "Saint Vincent and the Grenadines",
      "phone": "1-784",
      "short": "VC"
  },
  {
      "name": "Samoa",
      "phone": "685",
      "short": "WS"
  },
  {
      "name": "San Marino",
      "phone": "378",
      "short": "SM"
  },
  {
      "name": "Sao Tome and Principe",
      "phone": "239",
      "short": "ST"
  },
  {
      "name": "Saudi Arabia",
      "phone": "966",
      "short": "SA"
  },
  {
      "name": "Senegal",
      "phone": "221",
      "short": "SN"
  },
  {
      "name": "Serbia",
      "phone": "381",
      "short": "RS"
  },
  {
      "name": "Seychelles",
      "phone": "248",
      "short": "SC"
  },
  {
      "name": "Sierra Leone",
      "phone": "232",
      "short": "SL"
  },
  {
      "name": "Singapore",
      "phone": "65",
      "short": "SG"
  },
  {
      "name": "Sint Maarten (Dutch part)",
      "phone": "1-721",
      "short": "SX"
  },
  {
      "name": "Slovakia",
      "phone": "421",
      "short": "SK"
  },
  {
      "name": "Slovenia",
      "phone": "386",
      "short": "SI"
  },
  {
      "name": "Solomon Islands",
      "phone": "677",
      "short": "SB"
  },
  {
      "name": "Somalia",
      "phone": "252",
      "short": "SO"
  },
  {
      "name": "South Africa",
      "phone": "27",
      "short": "ZA"
  },
  {
      "name": "South Georgia and the South Sandwich Islands",
      "phone": "500",
      "short": "GS"
  },
  {
      "name": "South Sudan",
      "phone": "211",
      "short": "SS"
  },
  {
      "name": "Spain",
      "phone": "34",
      "short": "ES"
  },
  {
      "name": "Sri Lanka",
      "phone": "94",
      "short": "LK"
  },
  {
      "name": "Sudan",
      "phone": "249",
      "short": "SD"
  },
  {
      "name": "Suri'name",
      "phone": "597",
      "short": "SR"
  },
  {
      "name": "Svalbard and Jan Mayen",
      "phone": "47",
      "short": "SJ"
  },
  {
      "name": "Swaziland",
      "phone": "268",
      "short": "SZ"
  },
  {
      "name": "Sweden",
      "phone": "46",
      "short": "SE"
  },
  {
      "name": "Switzerland",
      "phone": "41",
      "short": "CH"
  },
  {
      "name": "Syrian Arab Republic",
      "phone": "963",
      "short": "SY"
  },
  {
      "name": "Taiwan, Province of China",
      "phone": "886",
      "short": "TW"
  },
  {
      "name": "Tajikistan",
      "phone": "992",
      "short": "TJ"
  },
  {
      "name": "Thailand",
      "phone": "66",
      "short": "TH"
  },
  {
      "name": "Timor-Leste",
      "phone": "670",
      "short": "TL"
  },
  {
      "name": "Togo",
      "phone": "228",
      "short": "TG"
  },
  {
      "name": "Tokelau",
      "phone": "690",
      "short": "TK"
  },
  {
      "name": "Tonga",
      "phone": "676",
      "short": "TO"
  },
  {
      "name": "Trinidad and Tobago",
      "phone": "1-868",
      "short": "TT"
  },
  {
      "name": "Tunisia",
      "phone": "216",
      "short": "TN"
  },
  {
      "name": "Turkey",
      "phone": "90",
      "short": "TR"
  },
  {
      "name": "Turkmenistan",
      "phone": "993",
      "short": "TM"
  },
  {
      "name": "Turks and Caicos Islands",
      "phone": "1-649",
      "short": "TC"
  },
  {
      "name": "Tuvalu",
      "phone": "688",
      "short": "TV"
  },
  {
      "name": "US Virgin Islands",
      "phone": "1-340",
      "short": "VI"
  },
  {
      "name": "Uganda",
      "phone": "256",
      "short": "UG"
  },
  {
      "name": "Ukraine",
      "phone": "380",
      "short": "UA"
  },
  {
      "name": "United Arab Emirates",
      "phone": "971",
      "short": "AE"
  },
  {
      "name": "United Kingdom",
      "phone": "44",
      "short": "GB"
  },
  {
      "name": "United Republic of Tanzania",
      "phone": "255",
      "short": "TZ"
  },
  {
      "name": "Uruguay",
      "phone": "598",
      "short": "UY"
  },
  {
      "name": "Uzbekistan",
      "phone": "998",
      "short": "UZ"
  },
  {
      "name": "Vanuatu",
      "phone": "678",
      "short": "VU"
  },
  {
      "name": "Venezuela",
      "phone": "58",
      "short": "VE"
  },
  {
      "name": "Vietnam",
      "phone": "84",
      "short": "VN"
  },
  {
      "name": "Wallis and Futuna",
      "phone": "681",
      "short": "WF"
  },
  {
      "name": "Western Sahara",
      "phone": "212",
      "short": "EH"
  },
  {
      "name": "Yemen",
      "phone": "967",
      "short": "YE"
  },
  {
      "name": "Zambia",
      "phone": "260",
      "short": "ZM"
  },
  {
      "name": "Zimbabwe",
      "phone": "263",
      "short": "ZW"
  }
];


export default Countries;

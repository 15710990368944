import React, { Component } from 'react';
import { setHash } from '../../../../store/axios';
import moment from 'moment';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import FormControl from '@material-ui/core/FormControl';
import { loginRequest, contactSignInfo, logout, sendSMSSignup } from '../../../../store/actions/Auth';
import { connect } from 'react-redux';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import NumberFormat from 'react-number-format';
import InputLabel from '@material-ui/core/InputLabel';

import Select from '@material-ui/core/Select';
import Countries from "../../../../DataFiles/Countries";
import Geocode from "react-geocode";
import EventBus from 'eventing-bus';
import { CountryRegionData } from 'react-country-region-selector';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import validator from 'validator';
import ReactGA from 'react-ga';
import { Link } from "react-router-dom";
class Individual extends Component {
  constructor() {
    super()
    this.state = {
      pageContent: "",
      isLoader:false,
      age:16,
      isDisabled: false,
      agreeCheck: false,
      isValid: true,
      isValidNumber:true,
      selectedDate: new Date('2004-08-18T21:11:54'),
      labelWidth: 90,
      lockFields: true,
      isValidFormat:true,
      country:'',
      isValidCitizenship:true,
      isValidState:true,
      isValidSecurity:true,
      isValidCity:true,
      isValidZip:true,
      isValidOTP:true,
      isValidAddress:true,
      countryCode:'+1',
      countryShortCode:'US',
      regions:[],
      statesToRemove:[{name:'American Samoa'}, {name: 'District of Columbia'}],
      locale:['ar-AE', 'ar-BH', 'ar-DZ', 'ar-EG', 'ar-IQ', 'ar-JO', 'ar-KW', 'ar-SA', 'ar-SY', 'ar-TN','be-BY', 'bg-BG', 'bn-BD', 'cs-CZ', 'de-DE', 'de-AT', 'da-DK',
      'el-GR', 'en-AU', 'en-CA', 'en-GB', 'en-GG', 'en-GH', 'en-HK', 'en-MO', 'en-IE', 'en-IN', 'en-KE', 'en-MT', 'en-MU', 'en-NG', 'en-NZ', 'en-RW', 'en-SG', 'en-UG', 'en-US', 'en-TZ',
      'en-ZA', 'en-ZM', 'en-PK', 'es-EC', 'es-ES', 'es-MX', 'es-PA', 'es-PY', 'es-UY', 'et-EE', 'fa-IR', 'fi-FI', 'fj-FJ', 'fr-FR', 'fr-GF', 'fr-GP', 'fr-MQ', 'fr-RE', 'he-IL', 'hu-HU',
      'id-ID', 'it-IT', 'ja-JP', 'kk-KZ', 'ko-KR', 'lt-LT', 'ms-MY', 'nb-NO', 'ne-NP', 'nl-BE', 'nl-NL', 'nn-NO', 'pl-PL', 'pt-PT', 'pt-BR', 'ro-RO', 'ru-RU', 'sl-SI', 'sk-SK', 'sr-RS',
      'sv-SE', 'th-TH', 'tr-TR', 'uk-UA', 'vi-VN', 'zh-CN', 'zh-HK', 'zh-MO', 'zh-TW'],
      formData: {
        city: '',
        residenceLabel: "",
        state: " ",
        stateName: "",
        citizenshipLabel: 'United States',
        address: '',
        number:'',
        otp:''
      }
    }
    window.scrollTo(0, 0);
  }

  componentDidMount() {
    EventBus.on("BasicInfo2", () => this.setState({ isDisabled: false }));
    console.log('this.props', this.props.allSignData);
    if (this.props.allSignData.step2 && Object.keys(this.props.allSignData.step2).length > 0) {
      const { formData } = this.state;
      let myStep = this.props.allSignData.step2
      formData['city'] = myStep.city;
      formData['stateName'] = myStep.state;
      formData['address'] = myStep.address;
      formData['zipCode'] = myStep.zipCode;
      formData['securityNumber'] = myStep.securityNumber;
      formData['residenceLabel'] = myStep.residence;
      formData['citizenshipLabel'] = myStep.citizenship;
      formData['number'] = myStep.phoneNo;
      this.setState({ formData });
      this.setDate(myStep.dob)
      this.setCountryState(myStep)
    }else{
      this.setDefaults()
    }
    ValidatorForm.addValidationRule('isNumberValid', (value) => {
      if (value) {
        return false;
      }
      return true;
    });
    ReactGA.pageview('/signup-2');
  }

  selectCountry (val) {
    this.setState({ country: val });
  }

  selectRegion (val) {
    this.setState({ region: val });
  }

  submitContact = () => {
    setHash(this.props.signData);
    const { formData } = this.state;
    if(formData.citizenshipLabel && formData.stateName && this.state.isValidNumber && this.state.isValidCity && this.state.isValidAddress && this.state.isValidZip && this.state.isValidOTP ){
      const citizenship = Countries.find(({ name }) => name == this.state.formData.citizenshipLabel)
      const state = this.state.regions.find(({ name }) => name == this.state.formData.stateName)
      var smsVerifyKey = this.state.formData.otp.replace(/-/g, "");
      let phoneNo = '+' + citizenship.phone + this.state.formData.number
      let phoneCode = '+' + citizenship.phone
      formData['citizenship'] = {name: citizenship.name, short:citizenship.short};
      formData['state'] = state;
      formData['dob'] = this.state.selectedDate;
      formData['hash'] = this.props.signData.hash ? this.props.signData.hash : this.props.paramsHash;
      formData['smsVerifyKey'] = smsVerifyKey;

      if (this.state.age >= 18) {
        formData['phoneNo'] = phoneNo
        formData['phoneCode'] = phoneCode
        this.setState({ isDisabled: true })
        console.log('this.state.formData', this.state.formData);
        this.props.contactSignInfo(this.state.formData)
        formData['otp'] = ''
      } else this.setState({ isValid: false })
    }else{
      if(formData.citizenshipLabel) this.setState({isValidCitizenship:true})
      else this.setState({isValidCitizenship:false})
      if(formData.stateName) this.setState({isValidState:true})
      else this.setState({isValidState:false})
      if(this.state.isValidNumber) this.setState({isValidNumber:true})
      else this.setState({isValidNumber:false})
      if(this.state.isValidOTP) this.setState({isValidOTP:true})
      else this.setState({isValidOTP:false})
      // if(this.state.isValidOTP && this.state.formData.otp.length == 7) this.setState({isValidOTP:true})
      // else {
      //   console.log('********', this.state.isValidOTP, this.state.formData.otp.length);
      //   this.setState({isValidOTP:false})
      //   EventBus.publish("error", "Enter a valid verification code.")
      // }
    }
  }

  signUpOtp = () => {
    if(this.state.isValidNumber && this.state.formData.number){
      this.setState({isLoader:!this.state.isLoader})
      const citizenship = Countries.find(({ name }) => name == this.state.formData.citizenshipLabel)
      let phoneNo = '+' + citizenship.phone + this.state.formData.number
      this.props.sendSMSSignup({hash:this.props.signData.hash, phone:phoneNo})
      setTimeout(() => {
        this.setState({isLoader:!this.state.isLoader})
      }, 10000);
    }else{
      EventBus.publish("error", 'Phone Number Invalid')
    }
  }

  checkValidations=()=>{
    if(!this.state.formData.number) this.setState({isValidNumber:false})
    if(!this.state.formData.stateName) this.setState({isValidState:false})
    if(this.state.age < 18) this.setState({isValid:false})
    // if(this.state.formData.citizenshipLabel === 'United States' && !this.state.formData.securityNumber) this.setState({isValidSecurity:false})
  }

  handleFormChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });

    if(event.target.name === 'state'){
      if(event.target.value !== '' && event.target.value !== undefined ) this.setState({isValidState:true})
      else this.setState({isValidState:false})
    }
    if(event.target.name === 'address'){
      if(event.target.value !== '' && event.target.value !== undefined && !/[^a-zA-Z0-9-,.;'&/.()_#* ]/.test(event.target.value)) this.setState({isValidAddress:true})
      else this.setState({isValidAddress:false})
    }
    if(event.target.name === 'city'){
      if(event.target.value !== '' && event.target.value !== undefined && !/[^a-zA-Z ]/.test(event.target.value)) this.setState({isValidCity:true})
      else this.setState({isValidCity:false})
    }
    if(event.target.name === 'zipCode'){
      if(event.target.value !== '' && event.target.value !== undefined && !/[^a-zA-Z0-9]/.test(event.target.value)) this.setState({isValidZip:true})
      else this.setState({isValidZip:false})
    }
    if(event.target.name === 'otp'){
      if(event.target.value !== '' && event.target.value.length == 7 && event.target.value !== undefined) this.setState({isValidOTP:true})
      else this.setState({isValidOTP:false})
    }

  }

  handleFormChangeCountry = (event) => {
    if(event.target.value){
      let filteredCountry = ''
      let filteredCountries = CountryRegionData.filter(x=>{
        if(x[0] == event.target.value) filteredCountry = x
      })
      /// old function
      let splittedCountry = event.target.value.split(',');
      let regions = []
      // splittedCountry[2].split("|").map(regionPair=>{
      filteredCountry[2].split("|").map(regionPair=>{
        let [name, regionShortCode] = regionPair.split("~");
        if(name !== "American Samoa")
        regions.push({
          name: name,
          short: regionShortCode,
        });
      })

      let filteredCode = Countries.filter(({ name }) => name == event.target.value)
      let countryCode = filteredCode[0].phone
      this.setState({regions:regions, lockFields:false, countryShortCode:filteredCountry[1], countryCode:countryCode})
      const { formData } = this.state;
      formData[event.target.name] = event.target.value;
      this.setState({ formData });
    }
  }

  setCountryState =(e)=>{
    let filteredCountry = ''
    let filteredCountries = CountryRegionData.filter(x=>{
      if(x[0] == e.citizenship) filteredCountry = x
    })
    let regions = []

    filteredCountry[2].split("|").map(regionPair=>{
      let [name, regionShortCode] = regionPair.split("~");
      if(name !== "American Samoa")
      regions.push({
        name: name,
        short: regionShortCode,
      });
    })
    let filteredCode = Countries.filter(({ name }) => name == e.citizenship)
    let countryCode = filteredCode[0].phone
    this.setState({regions:regions, lockFields:false, countryShortCode:filteredCountry[1], countryCode:countryCode})
  }

  setDefaults =(e)=>{
    let filteredCountry = ''
    let filteredCountries = CountryRegionData.filter(x=>{
      if(x[0] == 'United States') filteredCountry = x
    })
    let regions = []
    filteredCountry[2].split("|").map(regionPair=>{
      let [name, regionShortCode] = regionPair.split("~");
      if(name !== "American Samoa")
      regions.push({
        name: name,
        short: regionShortCode,
      });
    })
    this.setState({regions:regions, lockFields:false,})
  }

  setDate = (e) => {
    this.setState({ selectedDate: new Date(e) })
    let today = new Date();
    let birthDate = new Date(e);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    const validFormat = moment(new Date(e), 'MM/DD/YYYY',true).isValid()
    var start = moment(today, "MM/DD/YYYY");
    var end = moment(e, "MM/DD/YYYY");
    let checkValid = moment.duration(start.diff(end)).asDays()
    if (!validFormat) this.setState({isValidFormat:false})
    else if (checkValid <= 6575){
      this.setState({isValidFormat:true});
      age--;
    }
    else this.setState({isValidFormat:true})
     this.setState({ age });
     if (age < '18') this.setState({ isValid: false })
    else this.setState({ isValid: true })
  }

  countryToFlag(isoCode) {
    return typeof String.fromCodePoint !== 'undefined'
      ? isoCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397))
      : isoCode;
  }

  // getDataFromZipCode = async () => {
  //   console.log('in getDataFromZipCode', this.state.formData.zipCode);
  //   if (this.state.formData.zipCode) {
  //     let url = 'https://maps.googleapis.com/maps/api/geocode/json?address=' + this.state.formData.zipCode + '&key='
  //     fetch(url)
  //     let response = await fetch(url)
  //       .then((response) => {
  //         if (response['status'] == 200) return response.json()
  //       })
  //       .then((responseJson) => {
  //         console.log('responseJson', responseJson);
  //         if (responseJson.results.length > 0) {
  //           let addressComponent = responseJson.results[0].address_components
  //           const city = addressComponent.find(({ types }) => types[0] === "locality");
  //           const residenceLabel = addressComponent.find(({ types }) => types[0] === 'country');
  //           const state = addressComponent.find(({ types }) => types[0] === 'administrative_area_level_1');
  //
  //           const { formData } = this.state;
  //           if (city) formData['city'] = city.long_name;
  //           if (residenceLabel) formData['residenceLabel'] = residenceLabel.long_name;
  //           // if (residenceLabel) {
  //           //   this.setState({ lockFields: true })
  //           //   formData['citizenshipLabel'] = residenceLabel.long_name;
  //           // }
  //           this.setState({ formData });
  //         } else this.setState({ lockFields: false })
  //       })
  //       .catch(err => {
  //         this.setState({ lockFields: false })
  //         console.log('###error api', err)
  //       })
  //   } else {
  //     this.setState({ lockFields: false })
  //   }
  // }

  goBack = () => {
    // let path = '/installment-plans/' + this.props.signData.hash
    // this.props.onBack(path);
    EventBus.publish("emptyTab", 'Empty Tab')
  }

  handlePhoneChange = (event, countryData)=>
  {
    let filteredCode = Countries.filter(({ name }) => name == this.state.formData.citizenshipLabel)
    let countryCode = filteredCode[0].phone
    // let number = event.replace(/-/g,"").replace(/ /g, "").replace("(","").replace(")","")
    let number = '+' + countryCode + parseInt(event.target.value, 10)
    let isValidPhoneNumber = validator.isMobilePhone(number, this.state.locale)
    this.isValidNumber = isValidPhoneNumber
    this.setState({isValidNumber:isValidPhoneNumber})
    const { formData } = this.state;
    formData['number'] = parseInt(event.target.value, 10);
    this.setState({ formData });
  }

  render() {
    const { country, region, isLoader } = this.state;
    return (
      <section className="signUp-sec">
                <div>
                  <div className="pb-2">
                    <h5 >Individual Information:</h5>
                  </div>
                <ValidatorForm className="validatorForm" onSubmit={this.submitContact}>
                  <div className="row">
                    <div className="col-12">
                      <TextValidator
                        className="MyTextField"
                        fullWidth
                        label="Address"
                        error={!this.state.isValidAddress}
                        onChange={this.handleFormChange}
                        name="address"
                        type="text"
                        margin="dense"
                        variant="outlined"
                        validators={['required']}
                        errormessages={['Address Name can not be empty']}
                        value={this.state.formData.address}
                        autoComplete='off'
                      />
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6 pt-3">
                      <FormControl variant="outlined"
                        label="Country of citizenshipLabel"
                        margin="dense"
                        className="MyTextField"
                        fullWidth
                      >
                        <InputLabel htmlFor="outlined-age-native-simple">
                          Country
                          </InputLabel>
                        <Select
                          native
                          error={!this.state.isValidCitizenship}
                          labelWidth={this.state.labelWidth -22}
                          type="text"
                          name="citizenshipLabel"
                          validators={['required']}
                          value={this.state.formData.citizenshipLabel}
                          onChange={this.handleFormChangeCountry}
                          autoComplete='off'
                        >
                          <option value="" className="pl-2 selectOptions"></option>
                          {
                            Countries.map(option => {
                              return <option value={option.name} className="pl-2 selectOptions">
                                {/*this.countryToFlag(option[1])} {option[0]*/}
                                {option.name}
                              </option>
                            })
                          }
                        </Select>
                      </FormControl>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-6 pt-3">

                      <FormControl variant="outlined"
                        label="State"
                        margin="dense"
                        className={this.state.lockFields ? "cursor-not-allowed MyTextField" :'MyTextField'}
                        fullWidth
                      >
                        <InputLabel htmlFor="outlined-age-native-simple">
                          State
                          </InputLabel>
                        <Select
                          native
                          error={!this.state.isValidState}
                          disabled={this.state.lockFields}
                          labelWidth={this.state.labelWidth - 40}
                          type="text"
                          name="stateName"
                          value={this.state.formData.stateName}
                          onChange={this.handleFormChange}
                          validators={['required']}
                          autoComplete='off'
                        >
                        <option value="" className="pl-2 selectOptions"></option>
                        {
                          this.state.regions.map(option =>{
                            return <option value={option.name} className="pl-2 selectOptions">
                              {option.name}
                            </option>
                          })
                        }
                        </Select>
                      </FormControl>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-6 pt-3">
                      <TextValidator
                        className="MyTextField"
                        fullWidth
                        label="City"
                        onChange={this.handleFormChange}
                        name="city"
                        type="text"
                        error={!this.state.isValidCity}
                        margin="dense"
                        variant="outlined"
                        validators={['required']}
                        errormessages={['City can not be empty']}
                        value={this.state.formData.city}
                        autoComplete='off'
                      />
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-6 pt-3">
                      <TextValidator
                        className="MyTextField"
                        fullWidth
                        inputProps={{ maxLength:20 }}
                        label="Zip/Postal code"
                        onChange={this.handleFormChange}
                        error={!this.state.isValidZip}
                        name="zipCode"
                        type="text"
                        margin="dense"
                        variant="outlined"
                        validators={['required']}
                        errormessages={['Zip Code can not be empty']}
                        value={this.state.formData.zipCode}
                        autoComplete='off'
                      />
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-8 pt-4 ">
                      <FormControl variant="outlined">
                        <InputLabel>
                          Phone
                        </InputLabel>
                        <OutlinedInput
                          className="signup-phone"
                          id="outlined-error-helper-text"
                          fullWidth
                          error={!this.state.isValidNumber}
                          label="number"
                          labelWidth={35}
                          name="number"
                          type="number"
                          margin="dense"
                          validators={['isNumberValid', 'required']}
                          value={this.state.formData.number}
                          onChange={this.handlePhoneChange}
                          errormessages={['Number can not be empty']}
                          autoComplete='off'
                          startAdornment={<InputAdornment position="start"
                            >
                            {this.state.countryCode} {this.countryToFlag(this.state.countryShortCode)}
                          </InputAdornment>}
                          endAdornment={
                            <InputAdornment position="end">
                                            <button type="button" onClick={this.signUpOtp} className="custom-btn-otp" disabled={isLoader} >{isLoader ? <i className="fa fa-spinner fa-spin fa-4x fa-fw" style={{fontSize:"24px" }} /> : "Verify"}</button>
                            </InputAdornment>}

                          aria-describedby="outlined-weight-helper-text"
                          />
                          {!this.state.isValidNumber ? <span className="errorMessage">Invalid Number</span>:''}
                      </FormControl>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-4 pt-3 group-form">
                      <NumberFormat error={!this.state.isValidOTP} validators={['required']} placeholder=" OTP " variant="outlined" name='otp' value={this.state.formData.otp}
                        onChange={this.handleFormChange} className=' numberFormat-Form numberFormat-OTP' format="#-#-#-#" />
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-6 pt-2">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          error={!this.state.isValid}
                          className="MyTextField"
                          fullWidth
                          margin="normal"
                          id="date-picker-dialog"
                          label="DOB (MM/DD/YYYY)"
                          format="MM/dd/yyyy"
                          inputVariant="outlined"
                          value={this.state.selectedDate}
                          onChange={(selectedDate) => this.setDate(selectedDate)}
                          autoComplete='off'
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                        {!this.state.isValidFormat ? <span className="errorMessage">Invalid Date Format</span> : ""}
                        {!this.state.isValid ? <span className="errorMessage">Age must be above 18</span> : ""}
                      </MuiPickersUtilsProvider>
                    </div>

                    {this.state.formData.citizenshipLabel === 'United States' ?
                      <div className="col-12 pt-1"><hr /></div>
                      : ''}

                    {this.state.formData.citizenshipLabel === 'United States' ?
                      <div className="col-sm-12 col-md-12 col-lg-12 pt-1 group-form">
                        <div className="pt-1">
                          <h5 >Tax Reporting Information:</h5>
                          <div className="pt-2 pb-1 pl-1 formBlurHeading">
                            This information is required by the IRS for tax reporting purposes.<br />
                          We take every step to keep your personal information safe.  Investor personal information is encrypted and stored in a secure vault away from business systems. We are General Data Protection Regulation (GDPR) and California Consumer Privacy Act (CCPA) compliant. To view our Privacy Policy <Link className="click-here" href='/privacy-policy' target='_blank' >click here</Link>
                              </div>
                          <div className="pb-1 pl-1 formBoldHeading">
                            Social Security Number
                              </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-8 pt-1 group-form d-flex align-items-center">
                          <NumberFormat error={!this.state.isValidNumber} validators={['required']} placeholder="    ___-__-____    " variant="outlined" name='securityNumber' value={this.state.formData.securityNumber} onChange={this.handleFormChange} className='numberFormat-Form mt-0' format="###-##-####" />
                          {/*
                            <div className="info-tooltip">
                               <i class="fas fa-info-circle"></i>
                               <p className='tooltip'>
                               We take every step to keep your personal information safe.  Investor personal information is encrypted and stored in a secure vault away from business systems. We are General Data Protection Regulation (GDPR) and California Consumer Privacy Act (CCPA) compliant. To view our Privacy Policy click <a href='/privacy-policy' target='_blank' >here</a>.
                               </p>
                            </div>
                          */}
                        </div>
                      </div>
                      : ''
                    }
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-8 pt-3">
                      <div className="loginBtnDiv">
                        <div className="outer-box" >
                          <button type="button" name="skipButton" value="skipButton" onClick={() => this.goBack()} className="thm-btn btn-style-one-outline mr-1" disabled={this.state.isDisabled}>
                            {!this.state.isDisabled ? 'Back' : <i className="fa fa-spinner fa-spin fa-1x fa-fw" />}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-4 pt-3">
                      <div className="loginBtnDiv">
                        <div className="outer-box" >
                          <button className="thm-btn btn-style-one mr-1" onClick={this.checkValidations} disabled={this.state.isDisabled}>
                            {!this.state.isDisabled ? 'Continue' : <i className="fa fa-spinner fa-spin fa-1x fa-fw" />}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                </ValidatorForm>
              </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.Auth.auth,
    signData: state.Auth.signData ? state.Auth.signData.info : [],
    allSignData: state.Auth.signData
  }
}

const mapDispatchToProps = { loginRequest, contactSignInfo, logout, sendSMSSignup };
export default connect(mapStateToProps, mapDispatchToProps)(Individual);

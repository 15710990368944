import React from "react";
import { Link } from "react-router-dom";

export const Step1 = ({handelSteps}) => {
    return (
        <div className="step1">
            <div className="step-content">
            <h3>Welcome!</h3>
            <h5>We need to verify your identity before you can start investing.</h5>
            <p className="para">
            For your protection and the protection of other investors we require a few simple verification measures. You can continue through our streamlined verification steps now or come back later, just remember you will need to upload the required documents and photos prior to investing.
            </p>
            </div>
            <button className="custom-btn" onClick={() => handelSteps('Next')} >Continue <i className="fas fa-angle-right"></i></button>
            <div className="current-step">
                <h3>01</h3>
                <span>/04</span>
            </div>
            <div className="w-100">
                <Link to="/dashboard"><i className="fas fa-chevron-left"></i> Back To Dashboard</Link>
            </div>
        </div>
    )
}
var initialState = {
  userBanks: [],
  wires:[],
  frontImage:false,
  backImage:false,
  faceImages:false,
  isVerifyWithdrawl:false,
  imageMessage:"",
};
const Wallet = (state = initialState, { type, payload }) => {
  switch (type) {
    case "GET_WIRES_RESPONSE":
      return {
        ...state,
        wires: payload
      };
    case "RESET_WALLET":
      return initialState

    case "GET_USER_BANKS_RESPONSE":
      return {
        ...state,
        userBanks: payload
      };
      case "WITHDRAWL_VERIFIED":
      return {
        ...state,
        isVerifyWithdrawl: payload
      };
      
     case "IMAGE_UPLOADED" :
       return {
         ...state,
         imageMessage:payload.message,
         [payload.imageType]:true
       }

    default:
      return state;
  }
};
export default Wallet;

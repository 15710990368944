import Auth from "./Auth";
import Wallet from "./Wallet";
import Settings from "./Settings";
import Exchange from "./Exchange";
import { combineReducers } from "redux";

export default combineReducers({
  Auth,
  Wallet,
  Settings,
  Exchange
});

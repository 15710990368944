import React, { Component } from 'react'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import Banner from '../../components/Banner'
import { Player } from 'video-react';
import { getMedia } from './../../store/actions/Settings'
import { connect } from "react-redux"
import moment from "moment-timezone";


class Events extends Component {
    state = {
        expandedTab: 0,
        expanded: false,
    }

    componentDidMount(){
        this.props.getMedia()
    }

    render(){
        const { mediaList } = this.props
        console.log('mediaList', mediaList);
        return (
            <div className="page-wrapper">
                <Navbar
                    page='media'
                    {...this.props}
                />
                <Banner
                    heading='Events & Media'
                />
                <div className="events-wrapper">
                    <div className="upcomming">
                        <div className="container">
                            <div className="events">
                                 <h2>Upcoming Events</h2>
                                 {mediaList['events']  && mediaList['events'].map( events => {
                                     return(
                                         <div className="event">
                                            <div className="date">
                                                <h3>{new Date(events.startDate).getDate()}</h3>
                                                <span>{new Date(events.startDate).toLocaleString('default', { month: 'short' })}</span>
                                            </div>
                                            <div className="name">
                                                <span>NEW</span>
                                                <h4>{events.title}</h4>
                                                <p>{events.description}</p>
                                            </div>
                                            <div className="time"><h6>{new Date(events.startDate).toLocaleTimeString() + ' ' + moment.tz(events.timeZone).format('z') + ' - ' + new Date(events.endDate).toLocaleTimeString() + ' ' + moment.tz(events.timeZone).format('z')}</h6></div>
                                            
                                            <a href={events.signUp} target='_blank' className="custom-btn">SIGN UP</a>
                                        </div>
                                     )
                                  })}
                            </div>
                        </div>
                    </div>
                    <div className="webinars-seminars">
                        <div className="container">
                            <div className="webinars">
                                <h2>Media</h2>
                                {mediaList['media'] && mediaList['media'].map( webinar => {
                                   return (
                                       <>
                                        <div className="webinar">
                                            <div className="player-wrapper">
                                                <Player>
                                                    <source src={webinar.link} />
                                                </Player>
                                            </div>
                                            <div>
                                                <h3>{webinar.title}</h3>
                                                 <p>{webinar.description}</p>
                                                <h4 className="date">{new Date(webinar.date).getDate() + " " + new Date(webinar.date).toLocaleString('default', { month: 'short' })} </h4>
                                            </div>
                                        </div>
                                        </>
                                   )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                 <Footer/>
            </div>
        );
    }
}

const mapStateToProps = ({ Settings }) => {
  return {
    mediaList : Settings.mediaList
  }
}

const  mapDispatchToProps = {
    getMedia,
}

export default connect(mapStateToProps, mapDispatchToProps)(Events);

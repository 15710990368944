import React, { Component } from 'react';
import {TextValidator} from 'react-material-ui-form-validator';
import { CountryRegionData } from 'react-country-region-selector';
import ReactFlagsSelect from 'react-flags-select';
import Countries from "../../../DataFiles/Countries";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

export default class EditableField extends Component {

  constructor()
  {
    super()
    this.state = {
      firstName:'',
      regions:[],

    }
  }

  handleFormChangeCountry = (event) => {
    if(event.target.value){
      let filteredCountry = ''
      let filteredCountries = CountryRegionData.filter(x=>{
        if(x[0] == event.target.value) filteredCountry = x
      })
      /// old function
      let splittedCountry = event.target.value.split(',');
      let regions = []
      // splittedCountry[2].split("|").map(regionPair=>{
      filteredCountry[2].split("|").map(regionPair=>{
        let [regionName] = regionPair.split("~");
        regions.push(regionName)
      })
      this.setState({regions:regions})
      this.props.onChangeValue(event.target.name, event.target.value);
    }
  }


  handleFormChange = (event)=>
  {
    this.props.onChangeValue(event.target.name, event.target.value);
  }

  render() {
    if(this.props.title == 'state' || this.props.title == 'citizenship'){
      return(
        <div>
        <FormControl variant="outlined"
          label="Country of citizenshipLabel"
          margin="dense"
          className="MyTextField"
          fullWidth
        >
          <InputLabel htmlFor="outlined-age-native-simple">
            {this.props.labelName}
            </InputLabel>
          <Select
            native
            disabled={this.state.lockFields}
            error={!this.state.isValidCitizenship}
            labelWidth={this.state.labelWidth -22}
            type="text"
            name={this.props.title}
            value={this.props.value}
            onChange={this.handleFormChangeCountry}
            autoComplete='off'
          >
            {
              Countries.map(option => {
                return <option value={option.name} className="pl-2 selectOptions">
                  {/*this.countryToFlag(option[1])} {option[0]*/}
                  {option.name}
                </option>
              })
            }
          </Select>
        </FormControl>
        <FormControl variant="outlined"
          label="State"
          margin="dense"
          className="MyTextField"
          fullWidth
        >
          <InputLabel htmlFor="outlined-age-native-simple">
            {this.props.labelName}
            </InputLabel>
          <Select
            native
            error={!this.state.isValidState}
            disabled={this.state.lockFields}
            labelWidth={this.state.labelWidth - 40}
            type="text"
            name={this.props.title}
            value={this.props.value}
            onChange={(value) => this.handleFormChange(value)}
            validators={['required']}
            autoComplete='off'
          >
          <option value="" className="pl-2 selectOptions"></option>
          {
            this.state.regions.map(option =>{
              return <option value={option} key={option} className="pl-2 selectOptions">
                {option}
              </option>
            })
          }
          </Select>
        </FormControl>
        </div>
      );
    }else{
      return (
        <TextValidator
          fullWidth
          inputProps=
          {this.props.title == 'firstName' || this.props.title == 'lastName' ?
          { minLength:2, maxLength: 26 } : this.props.title ==  'zipCode' ? { minLength: 1, maxLength:20 } :
          this.props.title ==  'securityNumber' ? { minLength: 9, maxLength:9 } : { minLength: 1 }}
          label={this.props.labelName}
          className="MyTextField2"
          onChange={(value) => this.handleFormChange(value)}
          name={this.props.title}
          type="text"
          margin="dense"
          variant="outlined"
          validators={['required']}
          value={this.props.value}
        />
      );
    }
  }
}

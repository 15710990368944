export const handleUploadImage = data => ({
    type: "UPLOAD_AVATAR",
    payload: data
});

export const getProfile = () => ({
    type: 'GET_PROFILE',
});

export const resetSettings = () => ({
    type: 'RESET_SETTINGS'
});

export const getInvestorPreferences = () => ({
    type: 'GET_INVESTOR',
});

export const getMedia = () => ({
    type: 'GET_MEDIA',
});

export const profileResponse = (payload) => ({
    type: 'GET_PROFILE_RESPONSE',
    payload
});

export const getPreferences = data => ({
    type: "GET_PREFERENCES",
    payload: data
});

export const googleAuthEnable = data => ({
    type: "GOOGLE_AUTHENTICATION",
    payload: data
});

export const verify2Factor = data => ({
    type: "VERIFY_TWO_FACTOR",
    payload: data
});


export const contactUs = ({ data, history }) => ({
    type: "USER_SUPPORT",
    payload: data,
    history
});

export const handleGAModal = data => ({
    type: "SHOW_GA_MODAL",
    payload: data
});

export const disableGoogleAuth = data => ({
    type: "DISABLE_GOOGLE_AUTH",
    payload: data
});

export const sendSMS = data => ({
    type: "SEND_SMS",
    payload: data
});

export const sendSMSDeposit = data => ({
    type: "SEND_SMS_DEPOSIT",
    payload: data
});

export const authSMS = data => ({
    type: "AUTH_SMS",
    payload: data
});

export const handleSMSModal = data => ({
    type: "SMS_MODAL",
    payload: data
});

export const handleVerificationModal = data => ({
    type: "VERIFICATION_MODAL",
    payload: data
});

export const verifyOTP = data => ({
    type: "VERIFY_OTP",
    payload: data
});

export const disableSmsAuth = data => ({
    type: "DISABLE_SMS_AUTH",
    payload: data
});

export const otpLoader = data => ({
    type: "OTP_LOADER",
    payload: data
});

export const overlayLoader = (data) => ({
    type: "OVERLAY_LOADER",
    payload: data
});

export const withdrawAuthenticated = (data) => ({
    type: "WITHDRAW_AUTHENTICATED",
    payload: data
});

export const gaLoader = data => ({
    type: "GA_LOADER",
    payload: data
});

export const addInvestorPreferences = (data) => ({
    type: 'INVESTOR_PREFERENCES',
    payload: data,
});

export const addEmailPreferences = (data) => ({
    type: 'EMAIL_PREFERENCES',
    payload: data,
});

export const getAllPreferences = (data) => ({
    type: 'GET_ALL_PREFERENCES',
    payload: data,
});

export const verifySMSLink = (data) => ({
    type: 'VERIFY_SMS_LINK',
    payload: data,
});

export const getKyc = ({ history }) => ({
    type: 'GET_KYC',
    history
});

export const getAllNotifications = () => ({
    type: 'GET_ALL_NOTIFICATIONS'
});


export const setAllNotifications = (data) => ({
    type: 'ALL_NOTIFICATIONS',
    payload: data,
});

export const getGroupNotifications = () => ({
    type: 'GET_GROUP_NOTIFICATIONS'
});

export const viewAllNotifications = () => ({
    type: 'VIEW_ALL_NOTIFICATIONS'
});

export const deleteNotification = (data) => ({
    type: 'DELETE_NOTIFICATION',
    payload: data,
});

export const viewSingleNotification = (data) => ({
    type: 'VIEW_SINGLE_NOTIFICATION',
    payload: data,
});

export const favourite = ({ payload, onFavourites }) => ({
    type: 'FAVOURITE_ITEM',
    payload,
    onFavourites
});

export const favouriteProperties = (data) => ({
    type: 'FAVOURITE_PROPERTIES',
});

export const getUserDocs = (data) => ({
    type: 'GET_USER_DOCS',
});
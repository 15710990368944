import React, { Component } from "react";
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import DashboardLayout from "./../DashboardLayout";
import {
  getUserDocs,
  overlayLoader
} from "../../../store/actions/Settings";
import { connect } from "react-redux";
import YearPicker from "react-year-picker";
import moment from 'moment';
import { Link } from 'react-router-dom';
import TablePagination from '@material-ui/core/TablePagination';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

class Documents extends Component {

  state = {
    searchText :"",
    year:"",
    offering:"",
    investing:"",
    file1:false,
    file2:false,
    checkedAll:false,
    userDocs:[],
    filterDocs:[],
    count:1,
    date:'',
    tab:'All',
    rowsPerPage:10,
    page:0
  }

  componentDidMount(){
    this.props.overlayLoader({status:true, loadingMessage:'Loading Your Documents'})
    this.props.getUserDocs()
  }

  componentWillReceiveProps({ userDocs }) {
    if (Array.isArray(userDocs)) {
    this.setState({ filterDocs: userDocs, userDocs:userDocs });
    }
  }

  handelTab = (activeNo, tab, date) => {
    this.setState({count:activeNo})
    if (tab == 'All') {
      var filtered = this.state.userDocs.filter(function(item) {
        return (
          item.year == date
        );
      });
      if(date) this.setState({ filterDocs: filtered, count:activeNo, tab:tab, date:date });
      else this.setState({ filterDocs: this.state.userDocs, count:activeNo, tab:tab, date:date });
    }else if(tab == 'Personal Documents'){
      var filtered = this.state.userDocs.filter(function(item) {
        return (
          item.type == tab
        );
      });
      this.setState({ filterDocs: filtered, count:activeNo, tab:tab, date:date });
    }else{
      var filtered = this.props.userDocs.filter(function(item) {
        if(date){
          return (
            item.year == date &&
            item.type === tab
          );
        }else{
          return (
            item.type === tab
          );
        }
      });
      this.setState({ filterDocs: filtered, tab:tab });
      this.setState({ filterDocs: filtered, count:activeNo, tab:tab, date:date });
    }
  }

  handleSearch = (val) => {
    this.setState({ [val.target.name]: val.target.value });
    let e = val.target.value;
    this.setState({ text: e });
    if (this.state.userDocs) {
      this.setState({
        filterDocs: this.state.userDocs.filter(item => {
          if (item.name) {
            if (
              item.name.toLowerCase().indexOf(e.toLowerCase()) > -1
            ) {
              return item;
            }
          }
        })
      });
    }

  }

  checkAll = () => {
    this.setState({
      checkedAll:!this.state.checkedAll,
      file1:!this.state.checkedAll,
      file2:!this.state.checkedAll
    })
  }

  handelFile = (event) => {
    this.setState({
      [event.target.name]:!this.state[event.target.name]
    })
  }

  handleChangePage = (event, newPage) => {
    this.setState({page:newPage})
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage : parseInt(event.target.value, 10) });
    this.setState({page:0})
  }


  render() {
    const classes = useStyles;
    const { handleSearch, handelTab } = this;
    const { searchText, year, count, file1, file2, checkedAll, date, tab, rowsPerPage, page } = this.state;
    return(
      <>
      <DashboardLayout active="documents" {...this.props}>
      <section className="documents">
        <div className="container">
          <ul className="documnet-tabs">
            <li className={count == 1 ? ' active' : ""} onClick={() =>handelTab(1, 'All', date)}>All</li>
            <li className={count == 2 ? ' active' : ""} onClick={() =>handelTab(2, 'Statements', date)}>Statements</li>
            <li className={count == 3 ? ' active' : ""} onClick={() =>handelTab(3, 'Tax Document', date)}>Tax Documents</li>
            <li className={count == 4 ? ' active' : ""} onClick={() =>handelTab(4, 'General Document', date)}>General Document</li>
            <li className={count == 5 ? ' active' : ""} onClick={() =>handelTab(5, 'Personal Documents', date)}>Personal Documents</li>

          </ul>
          <div className="filters">
            <YearPicker onChange={(value) => handelTab(count, tab, value)} />

            <FormControl className="search-form" variant="outlined">
              <OutlinedInput
                id="outlined-adornment-amount"
                value={searchText}
                placeholder="Search"
                name="searchText"
                onChange={handleSearch}
                startAdornment={<InputAdornment position="start"><i className="fa fa-search"></i></InputAdornment>}
                />
            </FormControl>
          </div>
          <div className="scroll-div">
            <div className="document-tabel">
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {/* <TableCell><CheckBox handelCheckBox={checkAll} checked={checkedAll} />Name</TableCell> */}
                    <TableCell>Name</TableCell>
                    <TableCell>Token Name</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>year</TableCell>
                    <TableCell>Uploaded on</TableCell>
                  </TableRow>
                </TableHead >
                <TableBody>
                  {this.state.filterDocs && this.state.filterDocs.length > 0 ?
                  (rowsPerPage > 0
                    ? this.state.filterDocs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : this.state.filterDocs
                  ).map((docs)=>{
                    let date = new Date(docs.uploadDate)
                    return <TableRow>
                        {/* }<TableCell><CheckBox checked={file1} name={"file1"} handelCheckBox={handelFile} /><span className="file">{docs.name}</span></TableCell> */}
                        <TableCell><a className="file" href={docs.location}>{docs.name}</a></TableCell>
                        <TableCell>
                          <Link to={`/single-property?id=${docs.propertyId}`}>
                          {docs.symbol ? docs.symbol : '--'}
                        </Link>
                        </TableCell>
                        <TableCell>{docs.type}</TableCell>
                        <TableCell>{docs.year ? docs.year : '--'}</TableCell>
                        <TableCell>{moment(date).format('ll')}</TableCell>
                      </TableRow>
                  }) :
                  <TableRow>
                    <TableCell colSpan={6}>
                      <div className={'col-12 text-center mt-5'}>
                        <img
                          src="https://tknismtest.s3.amazonaws.com/non-compressed-images/47.png"
                          alt=""
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                }
                </TableBody>
              </Table>
              <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              colSpan={3}
              count={this.state.filterDocs.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              ActionsComponent={this.TablePaginationActions}
            />
            </div>
          </div>
        </div>
      </section>
      </DashboardLayout>
      </>
  )
}
}


const mapDispatchToProps = {
  getUserDocs,
  overlayLoader
};

const mapStateToProps = state => {
  let { userDocs } = state.Settings;
  return { userDocs };
};

export default connect(mapStateToProps, mapDispatchToProps)(Documents);

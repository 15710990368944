import { connect } from "react-redux";
import React, { Component } from "react";
import { showIframeFn } from "../../../../store/actions/Auth";

class AccreditationVerification extends Component {
  state = {
    expand: false
  };

  toggle = () => this.setState({ expand: !this.state.expand });

  render() {
    let { expand } = this.state;
    let { step } = this.props.dashHeadData || {};
    let { profile } = this.props || {};
    let { firstName, id, email, lastName } = this.props;

    let url = `https://www.earlyiq.com/partner/tokenism/investor/one/getstarted?page_template=modal&email=${email}&first_name=${firstName}&last_name=${lastName}&type=tokenism-inv-001&api_key1=nIOPcvJVUnD%2BdcKXh62xygxt%2BTkrixw%2BWOG9S7Tl8fw%3D&partner_ref_id=${id}`;

    return (
      <div className="row" id="accreditation">
        <div className="col-12">
          <div className="auth-container">
            <div className="profile-header" onClick={this.toggle}>
              <i
                className={
                  expand
                    ? "fa fa-chevron-down fa-rotate-270"
                    : "fa fa-chevron-down"
                }
              ></i>
              <h4 style={{ color: "#1A1E2C", marginLeft: "20px" }}>
                Accreditation Verification
              </h4>
            </div>
            <div hidden={expand} className="row profile-detail-container">
              <div className="col-lg-10 col-md-9 col-sm-8 col-12">
                <p className="auth-desc">
                  In order to protect your account and ensure the security of
                  your assets, verify your identity for withdrawl
                </p>
              </div>
              <div className="col-md-3 col-lg-2 col-sm-4 col-12 mt-3 mt-sm-0">

                {
                  step == 1 ? (
                    <button className="custom-btn-danger tooltip-accredation" style={{cursor:"not-allowed"}}>Verify
                      <span className="tooltiptext-accredation">Complete Your Identity Verification First</span>
                    </button>
                  ) :
                  step > 2 ? (
                    <button className="custom-btn-disabled">Verified</button>
                  )
                  : (
                    <button
                      className="custom-btn-danger"
                      onClick={() => window.open(url)}
                    >
                      Verify

                    </button>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ Auth }) => {
  let { dashHeadData } = Auth;
  return { dashHeadData };
};
const mapDispatchToProps = {
  showIframeFn
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccreditationVerification);

import moment from "moment";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import React, { Component } from "react";
import { Doughnut } from "react-chartjs-2";
import DashboardLayout from "../DashboardLayout";
import { userDashboard, showIframeFn, handleDwollaModal, handelUserMessage, userDashHeader, userDashChart } from "../../../store/actions/Auth";
import $ from 'jquery'
import NumberFormat from 'react-number-format';
import {
  isSafari,
  isMobile,
  isFirefox
} from "react-device-detect";
import { set } from "date-fns";
const options = { legend: { position: "right" } };

const data = {
  labels: ["TK-C607", "TK-M810", "TK-T420"],
  datasets: [
    {
      data: [300, 50, 100],
      backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
      hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"]
    }
  ]
};


class UserDashboard extends Component {
  constructor() {
    super()
    this.state = { showBalance: false, active: "dashboard" }
  }

  componentDidMount() {
    $('.scroll-to-top').on('click', function () {
      $('html, body').animate({
        scrollTop: 0
      }, 1500);
    })
  }



  componentWillReceiveProps(nextProps){
    if(nextProps) {
        localStorage.setItem('STEP', this.props.dashHeadData.step)
    }
  }

  handleDepositCard = () => {
    document.addEventListener("pt.bootstrap.ready", function () {
      var initializeEvent = new CustomEvent("pt.bootstrap.initialize", {
        detail: {
          app: "credit-card",
          target: document.getElementById("credit-card-target"),
          //   resourceTokenHash will change every time
          resourceTokenHash: "89qY3qqlZ4q6SyHeMJjjimfInqBkajkp"
        }
      });
      document.dispatchEvent(initializeEvent);
    });

    document.addEventListener("pt.app.ready", function () {
      console.log("UI ready");
    });

    document.addEventListener("pt.app.verified", function (e) {
      // e.detail is an object containing the keys: "contactId", "fundsTransferMethodId"
      // console.log(e.detail);
    });

    const script = document.createElement("script");

    script.src =
      "https://sandbox--primetrust-bootstrapper.netlify.com/bootstrap.js";
    script.async = true;

    document.body.appendChild(script);
  };
  test = () => {
    this.props.handleDwollaModal(true)
  }

  handelUserMessage = (e, infoMessage,infoHeading) => {
    e.preventDefault()
    const data = {
      isOpen: !this.props.isOpen,
      infoMessage,
      infoHeading,
    }
    this.props.handelUserMessage(data)
  }


  render() {
    let { id, email, lastName, firstName, dashHeadData, dashChartData } = this.props;
    let { overview, chartData, wallets } = dashChartData;
    let { loginHistory, security, dwollaVerification, step } = dashHeadData;
    security = security || {};
    let url = `https://www.earlyiq.com/partner/tokenism/investor/one/getstarted?page_template=modal&email=${email}&first_name=${firstName}&last_name=${lastName}&type=tokenism-inv-001&api_key1=nIOPcvJVUnD%2BdcKXh62xygxt%2BTkrixw%2BWOG9S7Tl8fw%3D&partner_ref_id=${id}`;

    return (
      <div className="page-wrapper">
          <DashboardLayout active={this.state.active} {...this.props}>
          <section className="balance-detail-area sec-padding2">
            <div className="container">
              <div className="row">
                <div className={data.labels.length > 5 ? "col-lg-12 col-md-12" : "col-lg-7 col-md-12"} >
                  <div className="balance-box">
                    <div className="top-area">
                      <div className="title-area">
                        <h4>Balance Detail</h4>
                      </div>
                      <div className="right-btn-area">
                        {step < 3 ?
                          (<>
                            <a href="#" className="mr-1" onClick={(e) => this.handelUserMessage(e, 'In order to view your wallet and portfolio make sure your identity and accreditation are confirmed. If you need assistance or have a concern please contact us.', step == 1 ? 'Please complete your identity verification to access this feature!' : "Please complete your Accreditation Verification to access this feature!")} > Deposit </a>
                            <a href="#" className="mr-1" onClick={(e) => this.handelUserMessage(e, 'In order to view your wallet and portfolio make sure your identity and accreditation are confirmed. If you need assistance or have a concern please contact us.', step == 1 ? 'Please complete your identity verification to access this feature!' : "Please complete your Accreditation Verification to access this feature!")} > Withdraw </a>
                          </>):(<>
                          <Link
                              to={{
                                pathname: "/dashboard/wallet",
                                state: { forPage: "Deposit" }
                              }}
                              className="mr-1 "
                            >
                              Deposit
                          </Link>
                          <Link
                            to={{
                              pathname: "/dashboard/wallet",
                              state: { forPage: "Withdraw" }
                            }}
                            className="mr-1 "
                          >
                            Withdraw
                        </Link>
                          </>)
                        }

                      </div>
                    </div>

                    <div id="credit-card-target"></div>

                    <div className="bottom-area">
                      <div className="row clearfix">
                        <div className={data.labels.length > 5 ? "col-lg-2 col-md-12" : "col-lg-5 col-md-12"}>
                          <div className="account-area">
                            <h3>
                              Account Balance
                              {!this.state.showBalance ? (
                                <i
                                  className="ml-1 fa fa-eye-slash"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.setState({ showBalance: true })
                                  }
                                ></i>
                              ) : (
                                  <i
                                    className="ml-1 fa fa-eye"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.setState({ showBalance: false })
                                    }
                                  ></i>
                                )}
                            </h3>
                            <h2>
                              ${this.state.showBalance
                                ? <NumberFormat
                                  value={parseFloat(overview).toFixed(2)}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                />
                                : "*************"}{" "}
                              <sub><small>TKUSD</small></sub>
                            </h2>
                          </div>
                        </div>
                        {wallets && Array.isArray(wallets) && wallets.length > 0 &&
                          <div className={data.labels.length > 5 ? "col-lg-10 col-md-12" : "col-lg-7 col-md-12"}>
                            <div className="chart-box">
                              <Doughnut data={chartData} options={options} />
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-12">
                  <div className="secuirty-sec">
                    <div className="title-area">
                      <h3>
                        Security Level {security['level']}/4
                          <Link to='/dashboard/settings'>
                          <span className="fa fa-angle-right" />
                        </Link>
                      </h3>
                    </div>
                    <div className="identy-area">
                      <ul className="top-area">
                        <li>
                          Google Authentication <Link to='/dashboard/settings/#auth'>{security['twoFAVerification'] ? 'Turn Off' : 'Turn On'}</Link>
                        </li>
                        <li className="devider"></li>
                        <li>
                          Identity Verification
                          {security['identityVerification'] ? <Link to='/dashboard/settings/#identity'>Verified</Link> : <Link to='/dashboard/settings/#identity' className='text-danger'>Verify</Link>}
                        </li>
                      </ul>
                      <ul className="bottom-area">
                        <li>
                          Text Authentication<Link to='/dashboard/settings/#auth'>{security['smsVerification'] ? 'Turn Off' : 'Turn On'}</Link>
                        </li>
                        <li className="devider"></li>
                        <li>
                          Accreditation Verification
                        {security['accreditedVerification'] && step > 2 ? <Link to='/dashboard/settings/#accreditation'>Verified</Link> : <Link to='/dashboard/settings/#accreditation' className='text-danger'>Verify</Link>}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 col-md-12">
                  <div className="balance-box">
                    <div className="top-area">
                      <div className="title-area">
                        <h4>Activity </h4>
                        {/* <span>|</span> <h4 className="device">Devices</h4> */}
                      </div>
                    </div>
                    {!loginHistory || loginHistory.length === 0
                      ? null
                      : loginHistory.map(history => (
                        <div
                          className="devices-box"
                          key={history["accessTime"]}
                        >
                          <div className="device-inner">
                            <div className="left-area">
                              <h4>
                                {history["userAgent"]}{" "}
                                <span>{history["ipAddress"]}</span>
                              </h4>
                            </div>
                            <div className="right-area">
                              <h4>
                                {history["city"]}, {history["countryName"]}
                                <span>
                                  {moment(history["accessTime"]).format("lll")}
                                </span>
                              </h4>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="col-lg-5 col-md-12">
                  <div className="secuirty-sec">
                    <div className="title-area">
                      <h3>
                        Distribution <span className="fa fa-angle-right"></span>
                      </h3>
                    </div>
                    <div className="record-sec">
                      <div className="record-inner">
                        <figure>
                          <img
                            src="https://tknismtest.s3.amazonaws.com/non-compressed-images/47.png"
                            alt=""
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          </DashboardLayout>
      </div>
      );
  }
}


const mapDispatchToProps = { userDashboard, showIframeFn, handleDwollaModal, handelUserMessage, userDashHeader, userDashChart};

const mapStateToProps = ({ Auth }) => {
  let { showIframe, id, email, lastName, firstName, step, showMessage, isOpen, dashHeadData, dashChartData } = Auth;
  return {
    id,
    step,
    email,
    lastName,
    firstName,
    showIframe,
    showMessage,
    isOpen,
    dashHeadData: dashHeadData ? dashHeadData : '',
    dashChartData: dashChartData ? dashChartData : ''
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDashboard);

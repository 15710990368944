import Web3 from "web3";
import EventBus from "eventing-bus";
import { connect } from "react-redux";
import React, { Component } from "react";
import * as Sentry from '@sentry/browser';
import NumberFormat from 'react-number-format';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import { ValidatorForm } from 'react-material-ui-form-validator';

import "./bankAccount.css";
import CardDetails from "../../CardDetails";
import ExistingAccount from "../../ExistingAccount";
import AppLoader from '../../../../../components/AppLoader'
import { getBanks } from '../../../../../store/actions/Wallet';
import { web3, providerInject, magic } from '../../../../../store/web3';
import { overlayLoader, sendSMSDeposit, authSMS, withdrawAuthenticated } from "../../../../../store/actions/Settings";
import { userDashHeader, userDashChart, getCards } from "../../../../../store/actions/Auth";
import { TKUSD, TKUSDABI, TKUSDAddress, Owner, networkId } from "../../../../../store/contract";
import { addBankAccount, withdraw, deleteBank, depositeBankPayment, verifyMicro } from "../../../../../store/actions/Wallet";
import { Modal, ModalBody } from "reactstrap";

class BankAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gasPrice: '65',
      isMatched: false,
      showExisting: props.userBanks.length ? true : false,
      confirmAccountNo: "",
      isDisabled: false,
      amount: '',
      feePercent: 0.02, // 40 Percent
      selectedBank: "",
      loading: false,
      backOption: true,
      isOpen:false,
      v1: "",
      v2: "",
      v3: "",
      v4: "",
      v5: "",
      v6: "",
      seconds: 59,
      formData: {
        accountType: "savings",
        bankName: "",
        routingNo: "",
        accountNo: ""
      }
    };

    props.getBanks();
    props.userDashChart();
    props.overlayLoader({status:true, loadingMessage:'Loading your Banks.'})

    EventBus.on("withdrawLoader", () => this.setState({ loading: false }));
    // EventBus.on("withdrawAuthenticated", () => this.handleSubmit());
  }

  toggleModal=()=>{
    clearInterval(this.interval);
    this.setState({isOpen:!this.state.isOpen});
  }

  handleSubmitSmsAuth = () => {
    this.toggleModal()
    const { v1, v2, v3, v4 } = this.state;
    this.props.authSMS({smsVerifyKey: v1 + v2 + v3 + v4})
  }

  getGasPrice = ()=> {
    return new Promise(resolve=> {
      fetch('https://ethgasstation.info/api/ethgasAPI.json')
      .then(response => response.json())
      .then(data => this.setState({ gasPrice: (data['fastest'] / 10).toString() }, ()=> {
        // console.log(`**************this.state.gasPrice = `, this.state.gasPrice);
        resolve();
      }))
      .catch(e=> resolve());
    });
  }

  handleSubmit = async () => {
    clearInterval(this.interval);
    // this.setState({isOpen:!this.state.isOpen})
    try {
      let { amount } = this.state;
      web3.eth.handleRevert = true;
      // this.setState({ loading: true });

      const isLoggedIn = await magic.user.isLoggedIn();
      if(!isLoggedIn) {
        this.props.overlayLoader({status:false, loadingMessage:''});
        this.props.history.push('/');
        EventBus.publish('tokenExpired');
        return;
      }

      if(amount > this.props.dashChartData.overview) {
        EventBus.publish("error", "You have insufficient amount of TKUSD");
        return;
      }

      this.props.overlayLoader({status:true, loadingMessage:'Transaction Processing'});

      let from = (await web3.currentProvider.enable())[0];

      let web3Injected = new Web3(providerInject);
      web3Injected.eth.handleRevert = true;
      let TKUSDData = new web3Injected.eth.Contract(TKUSDABI, TKUSDAddress);
      let withdrawFee = (await TKUSDData.methods.transfer(Owner, web3.utils.toHex(amount)).estimateGas({ from })) * 2;

      let userGas = (await web3Injected.eth.getBalance(from));
      if (userGas < withdrawFee) {
        EventBus.publish("error", "Not enough gas to perform this transaction.");
        this.props.overlayLoader({status:false, loadingMessage:''})
        this.props.withdrawAuthenticated({status:false})
        return;
      }

      await this.getGasPrice();
      web3.eth.sendTransaction({
        from,
        value: 0,
        gas:withdrawFee,
        to: TKUSDAddress,
        gasPrice: web3.utils.toHex(web3.utils.toWei(this.state.gasPrice, 'gwei')),
        data: TKUSDData.methods.transfer(Owner, web3.utils.toHex(amount)).encodeABI(),
      })
        .on('transactionHash', (hash) => {
          this.props.overlayLoader({status:true, loadingMessage:'Your transaction is Mining'});
        })
        .on('receipt', (receipt) => {
          let data = {
            NetwordId: networkId,
            bankId: this.state.selectedBank,
            transactionHash: receipt['transactionHash']
          }
          this.props.withdraw(data);
        })
        .on('error', (error) => {
          if(error['message'].indexOf('Insufficient funds.'))
            EventBus.publish("error", 'Please add some ethereum to perform this actions');
          else EventBus.publish("error", error['message']);
          Sentry.captureMessage(error['message'] + ' withdraw');
          this.props.overlayLoader({status:false, loadingMessage:''});
          this.props.withdrawAuthenticated({status:false})
        });
    } catch (error) {
      this.setState({ loading: false });
      EventBus.publish("error", error['message']);
      this.props.overlayLoader({status:false, loadingMessage:''});
      this.props.withdrawAuthenticated({status:false})
    }
  };

    handleAddAccount = () => {
      if (this.state.formData.accountNo.length < 4) EventBus.publish("error", "Account number must be greater than 3 digits.");
      else if (this.state.formData.accountNo !== this.state.formData.confirmAccountNo) EventBus.publish("error", "Account number did not match.");
      else this.props.addBankAccount(this.state.formData)
      const formData = {
        bankName: "",
        routingNo: "",
        accountNo: "",
        accountType: "savings",
      }
      setTimeout(() => this.setState({ formData }), 500);
    };

    handleFormChange = e => {
      const { formData } = this.state;

      formData[e.target.name] = e.target.value;
      this.setState({ formData });

      if (
        formData.accountNo !== "" &&
        formData.accountNo == formData.confirmAccountNo
      )
      this.setState({ isMatched: true });
      else this.setState({ isMatched: false });
    };

    componentWillReceiveProps(props) {
      if (props.userBanks.length) this.setState({ showExisting: true, isDisabled: false });
      else this.setState({ showExisting: false, isDisabled: false });
      if(props.withdrawAuth) this.handleSubmit()
    }

    onDelete = e => this.props.deleteBank(e);
    selectedCard = bank => this.setState({ selectedBank: bank._id, selectedBankFull:bank });

    withdrawMax = (overview) => {
      this.setState({ amount: overview })
    }
    goBack = () => {
      if (this.props.userBanks.length) this.setState({ showExisting: true });
      else this.props.onSelectMethod("none");
    };

    handleFundingSource = () =>{
      let modalHeading = "Money Transfer"
      let modalMessage = "You are receiving amount $ " + this.state.amount + " into Account " + this.state.selectedBankFull.name + ' - ' +  this.state.selectedBankFull.category
      this.setState({isOpen:true, modalHeading:modalHeading, modalMessage:modalMessage, v1: "", v2: "", v3: "", v4: "", v5: "", v6: ""})
      clearInterval(this.interval);
      this.props.sendSMSDeposit('Withdraw');
      this.setState({ seconds: 59, smsLoading: true }, () => {
        if (this.state.seconds > 1) {
          this.interval = setInterval(() => this.tick(), 1000);
        }
      });
    }

    tick() {
      if (this.state.seconds >= 1) {
        this.setState({seconds: this.state.seconds-1})
      } else {
        clearInterval(this.interval);
      }
    }

    handleSendSMS = () => {
      clearInterval(this.interval)
      this.props.sendSMSDeposit('Withdraw');
      this.setState({ seconds: 59, smsLoading: true }, () => {
        if (this.state.seconds > 1) {
          this.interval = setInterval(() => this.tick(), 1000);
        }
      });
    };

    handleVerificationCode = e => {
      if (e.target.name === "v1") {
        var input = document.getElementById("v2");
        input.focus();
        input.select();
      } else if (e.target.name === "v2") {
        var input = document.getElementById("v3");
        input.focus();
        input.select();
      } else if (e.target.name === "v3") {
        var input = document.getElementById("v4");
        input.focus();
        input.select();
      }

      this.setState({ [e.target.name]: e.target.value }, () => {
        // const { v1, v2, v3, v4 } = this.state;
        // if (v1 !== "" && v2 !== "" && v3 !== "" && v4 !== "") {
        //   this.setState({ showOtpLoader: true });
        //   clearInterval(this.interval)
        //   this.props.verifyOTP({ sms_code: v1 + v2 + v3 + v4 });
        // }
      });
    };

    render() {
      let { dashChartData } = this.props;
      let { overview } = dashChartData;
      let { amount, feePercent, isOpen, modalHeading, modalMessage, v1, v2, v3, v4 } = this.state;
      let amountShow = amount || 0;
      return (
        <div className="deposite-page tab-content">

          <Modal
            modalClassName="modal-mini modal-info uploaded"
            toggle={this.toggleModal}
            isOpen={isOpen}
            >
            <div className="modal-header justify-content-center">
              <div className="modal-profile">
                <img className='modalLogoImg' src="https://tknismtest.s3.amazonaws.com/non-compressed-images/Logo0.png" alt="" />
              </div>
              <div className="right">
                <img
                  onClick={this.toggleModal}
                  className="modalLogoImg"
                  src="https://tknismtest.s3.amazonaws.com/non-compressed-images/close-icon.png"
                  alt=""
                  />
              </div>
            </div>
            <ModalBody>
              <div className="pt-3">
                <h5 className="title">{modalHeading}</h5>
                <p className="para">{modalMessage}</p>
                  {this.state.seconds === 0 ? (
                    <p className="resend-title" onClick={this.handleSendSMS}>
                      Resend verification code
                    </p>
                  ) : (
                    <p className="resend-title">
                      Resend verification code in {this.state.seconds}
                    </p>
                  )}
                  <div className="code-input-container">
                    <input
                      name="v1"
                      id="v1"
                      onChange={this.handleVerificationCode}
                      className="code-input"
                      type="text"
                      maxLength={1}
                    />
                    <input
                      name="v2"
                      id="v2"
                      onChange={this.handleVerificationCode}
                      className="code-input"
                      type="text"
                      maxLength={1}
                    />
                    <input
                      name="v3"
                      id="v3"
                      onChange={this.handleVerificationCode}
                      className="code-input"
                      type="text"
                      maxLength={1}
                    />
                    <input
                      name="v4"
                      id="v4"
                      onChange={this.handleVerificationCode}
                      className="code-input"
                      type="text"
                      maxLength={1}
                    />
                  </div>
              </div>
            </ModalBody>
            <div className="modal-footer">
              <button
                onClick={this.toggleModal}
                className="custom-btn no-bg"
                >
                Cancel
              </button>
              {
                v1, v2, v3, v4
                ?
                <button
                  onClick={this.handleSubmitSmsAuth}
                  className="custom-btn ml-2"
                  >
                  Authorize
                </button>
                :
                <button
                  disabled={true}
                  className="thm-btn btn-style-one"
                  >
                  <i className="fa fa-ban fa-1x fa-fw" />
                </button>
              }
            </div>
          </Modal>

          <div className="withdraw-tab">
            <div className="row clearfix">
              {!this.state.showExisting ? (
                <CardDetails
                  goBack={this.goBack}
                  onChange={value => this.handleFormChange(value)}
                  onSubmit={this.handleAddAccount}
                  hidden={this.state.backOption}
                  {...this.state}
                  {...this.props}
                  />
              ) : (
                <ExistingAccount
                  selectedCard={value => this.selectedCard(value)}
                  addCard={() => this.setState({ showExisting: false, backOption: false })}
                  userBanks={this.props.userBanks}
                  onDelete={this.onDelete}
                  {...this.props}
                  {...this.state}
                  />
              )}

              {this.state.showExisting && <div className="col-lg-5 offset-lg-1 col-md-12 col-12 pt-5">
                <div className="right-confrom-area pb-2">
                  {this.state.loading ?
                    <AppLoader message='Transaction Processing' />
                    :
                    <div className="group-form">
                      <div className="row pb-3">
                        <div className="col-9">
                          <span className="balance-text grey-text">Available Balance :</span>
                          {/* <label className="label-default">Available Balance</label> */}
                        </div>
                        <div className="col-3">
                          <NumberFormat
                            value={parseFloat(overview || 0).toFixed(0)}
                            displayType={'text'}
                            thousandSeparator={true}
                            renderText={value => <span className="balance-amount grey-text">{value}</span>}
                            />
                          {/* <label className="label-default">{parseFloat(overview || 0).toFixed(0)}</label> */}
                        </div>
                      </div>

                      <ValidatorForm className="validatorForm pt-2" onSubmit={this.handleFundingSource}>
                        <div className="row">
                          <div className="col-md-12 col-lg-12">
                            <FormControl variant="outlined" className="MyTextField2Max">
                              <InputLabel>
                                Amount
                              </InputLabel>
                              <OutlinedInput
                                fullWidth
                                id="outlined-error-helper-text"
                                error={amount > 5000}
                                label="amount"
                                labelWidth={30}
                                name="amount"
                                type="text"
                                validators={['required']}
                                value={amount}
                                onChange={e => this.setState({ amount: e.target.value })}
                                endAdornment={<InputAdornment position="end" className="pointer" onClick={(value) => this.withdrawMax(overview)}>MAX</InputAdornment>}
                                startAdornment={<InputAdornment position="start"
                                  >
                                  <img
                                    src="https://tknismtest.s3.amazonaws.com/non-compressed-images/49.png"
                                    alt=""
                                    />
                                </InputAdornment>}
                                aria-describedby="outlined-weight-helper-text"
                                />
                              {amount > 5000 ? <FormHelperText id="outlined-error-helper-text" className="helperText">Should be less than 5,000.</FormHelperText> : ''
                              }
                              {amount < 0 ? <FormHelperText id="outlined-error-helper-text" className="helperText">Should be positive value.</FormHelperText> : ''}
                              {amount.indexOf(".") != -1 ? <FormHelperText id="outlined-error-helper-text" className="helperText">Should be integer value.</FormHelperText> : ''}
                            </FormControl>
                          </div>
                        </div>
                        <div className="row justify-content-end">
                          <div className="col-lg-8 col-md-8 pt-4">
                            {/* <div className="row">
                              <div className="col-12">
                              <div className="row">
                              <div className="col-md-10">
                              <span className="label-custom">Withdraw Amount: </span>
                              </div>
                              <div className="col-md-2 nopadding ">
                              <NumberFormat
                              value={Number.parseFloat(amountShow).toFixed(0)}
                              displayType={'text'}
                              thousandSeparator={true}
                              renderText={value => <span className="label-custom pull-right">{value}</span>}
                              />
                              </div>
                              </div>
                              </div>
                              <div className="col-12 pt-1">
                              <div className="row">
                              <div className="col-lg-8 col-md-8">
                              <span className="label-custom">Transaction Fees: </span>
                              </div>
                              <div className="col-lg-4 col-md-4 nopadding">
                              <NumberFormat
                              value={Number.parseFloat(amountShow * feePercent).toFixed(0)}
                              displayType={'text'}
                              thousandSeparator={true}
                              renderText={value => <span className="label-custom-red pull-right">{value}</span>}
                              />
                              </div>
                              </div>
                              </div>
                              </div>
                              <hr className="divider" /> */}
                              <div className="group-form">
                                <div className="row">
                                  <div className="col-lg-8 col-md-8">
                                    <span className="label-custom">You Will Receive: </span>
                                  </div>
                                  <div className="col-lg-4 col-md-4 nopadding ">
                                    <NumberFormat
                                      value={Number.parseFloat(amountShow).toFixed(0)}
                                      displayType={'text'}
                                      thousandSeparator={true}
                                      renderText={value => <span className="label-custom pull-right">{value}</span>}
                                      />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="group-form d-flex justify-content-center pt-3">
                            {amountShow > 5000 || amountShow < 0 || amountShow == 0 || amount.indexOf(".") != -1
                              ?
                              <button
                                disabled={true}
                                className="thm-btn btn-style-one"
                                >
                                <i className="fa fa-ban fa-1x fa-fw" />
                              </button>
                              :
                              <button
                                disabled={this.state.isDisabled}
                                className="thm-btn btn-style-one"
                                >
                                {!this.state.isDisabled ? (
                                  "Submit"
                                ) : (
                                  <i className="fa fa-spinner fa-spin fa-1x fa-fw" />
                                )}
                              </button>
                            }

                          </div>
                        </ValidatorForm>
                      </div>
                    }
                  </div>
                </div>}
              </div>
            </div>
          </div>
        );
      }
    }

    const mapDispatchToProps = { getCards, getBanks, addBankAccount, withdraw, deleteBank, depositeBankPayment, userDashHeader, userDashChart, overlayLoader, sendSMSDeposit, authSMS, withdrawAuthenticated, verifyMicro };

    const mapStateToProps = state => {
      return { userBanks: state.Wallet.userBanks || [], dashChartData: state.Auth.dashChartData || {}, withdrawAuth: state.Settings.withdrawAuth };
    };
    export default connect(mapStateToProps, mapDispatchToProps)(BankAccount);

import authSagas from './Auth';
import settingSagas from './Settings';
import exchangeSagas from './Exchange';
import walletSagas from './Wallet.api';
import Sockets from './Wallet.sockets';

import { all } from 'redux-saga/effects';

export default function* rootSaga(getState) {
  yield all([
    Sockets(),
    authSagas(),
    walletSagas(),
    settingSagas(),
    exchangeSagas(),
  ]);
}

import { setToken } from '../axios';
import { PURGE } from "redux-persist";
import { updateID } from '../sagas/Wallet.sockets';

import { emptyProvider } from '../web3';

var initialState = {
  signData: '',
  TKUSDData: '',
  plaidData: '',
  plaidDataSignUp: '',
  userCards: '',
  walletData: '',
  userSignData: '',
  agreementsData: '',
  showIframe: false,
  singleProperty: '',
  singlePropertyData: '',
  listedProperties: '',
  dummy: false,
  showModal: false,
  isOpen: false,
  infoMessage: "",
  infoHeading:"",
  infoButton:"",
  loginAuthModal: '',
  showLoginModal: false,
  showloginAuthModal: false,
  isCookies: "",
  favourites: "",
  dashHeadData:'',
  dashChartData:'',
  toggle: false,
  currentKycStep: false,
  isVerifyUser: false,
  id: localStorage.getItem('id'),
  auth: localStorage.getItem('token'),
  email: localStorage.getItem('email'),
  lastName: localStorage.getItem('lastName'),
  firstName: localStorage.getItem('firstName'),
}

const Auth = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE:
    return initialState;

    case 'LOGOUT':
    setToken();
    updateID('');
    emptyProvider();
    localStorage.clear();
    return {
      signData: '',
      TKUSDData: '',
      plaidData: '',
      plaidDataSignUp: '',
      userCards: '',
      walletData: '',
      userSignData: '',
      agreementsData: '',
      showIframe: false,
      singleProperty: '',
      singlePropertyData: '',
      listedProperties: '',
      dummy: false,
      showModal: false,
      isOpen: false,
      infoMessage: "",
      loginAuthModal: '',
      showLoginModal: false,
      showloginAuthModal: false,
      isCookies: "",
      favourites: "",
    }

    case 'SHOW_IFRAME':
    return {
      ...state,
      showIframe: payload
    };

    case 'SHOW_USER_RESTRICT':
    return {
      ...state,
      isOpen: payload.isOpen,
      infoMessage: payload.infoMessage,
      infoHeading: payload.infoHeading,
      infoButton: payload.infoButton ? payload.infoButton : ''
    };

    case 'WALLET_MODAL':
    return {
      ...state,
      showModal: payload
    };

    case 'LOGIN_WALLET_MODAL':
    return {
      ...state,
      showLoginModal: payload
    };
    case 'DWOLLA_MODAL':
    return {
      ...state,
      dwollaModal: payload
    };

    case 'LOGIN_SUCCESS':
    updateID(payload['id']);
    localStorage.setItem('id', payload['id']);
    localStorage.setItem('email', payload['email']);
    localStorage.setItem('lastName', payload['lastName']);
    localStorage.setItem('firstName', payload['firstName']);

    setToken(payload['token']);
    localStorage.setItem('token', payload['token']);
    sessionStorage.setItem('token', payload['token']);
    return {
      ...state,
      auth: payload['token'],

      id: payload['id'],
      step: payload['step'],
      email: payload['email'],
      lastName: payload['lastName'],
      firstName: payload['firstName'],
    };

    case 'GET_ALL_PROPERTIES_RESPONSE':
    return {
      ...state,
      listedProperties: payload
    };

    case 'GET_SINGLE_PROPERTY_RESPONSE':
    return {
      ...state,
      singleProperty: payload
    };

    case 'SINGLE_PROPERTY_RESPONSE':
    return {
      ...state,
      singlePropertyData: payload
    };
    case 'GET_USER_CARDS_RESPONSE':
    return {
      ...state,
      userCards: payload
    };

    case 'GET_WALLET_DATA_RESPONSE':
    return {
      ...state,
      walletData: payload
    };

    case 'GET_HISTORY_DW_DATA_RESPONSE':
    return {
      ...state,
      walletHistoryData: payload
    };

    case 'GET_BALANCE_RESPONSE':
    return {
      ...state,
      walletBalance: payload
    };

    case "UPDATE_DEPOSIT":
    let walletData = newDeposit(state.walletData, payload);

    return {
      ...state,
      dummy: !state.dummy,
      walletData: walletData,
    }
    case 'DASHBOARD_DATA':
    return {
      ...state,
      dashData: payload
    };

    case 'DASH_HEADER_DATA':
    return {
      ...state,
      dashHeadData: payload
    };

    case 'DASH_CHART_DATA':
    return {
      ...state,
      dashChartData: payload
    };

    case 'GET_SIGN_DATA':
    return {
      ...state,
      signData: payload,
      toggle: !state['toggle']
    };

    case 'SAVE_AGREEMENTS':
    return {
      ...state,
      agreementsData: payload
    };

    case 'GET_USER_FINAL_DATA':
    return {
      ...state,
      userSignData: payload
    }

    case 'SET_VERIFY_USER':
    return {
      ...state,
      isVerifyUser: payload
    }

    case 'PLAID_DATA':
    return {
      ...state,
      plaidData: payload
    };

    case 'PLAID_DATA_SIGNUP':
    return {
      ...state,
      plaidDataSignUp: payload
    };

    case 'TKUSD_DATA':
    return {
      ...state,
      TKUSDData: payload
    };

    case 'SHOW_LOGIN_AUTH_TABS':
    return {
      ...state,
      loginAuthModal: payload,
      showloginAuthModal: true
    };

    case 'HANDLE_LOGIN_AUTH_MODAL':
    return {
      ...state,
      showloginAuthModal: payload
    };

    case 'CURRENT_KYC_STEP':
    return {
      ...state,
      currentKycStep: payload
    };

    case 'GET_BLOGS_RESPONSE':
    {
      return {
        ...state,
        blogs: payload
      };
    }
    ;

    case 'GET_COOKIES':
    {
      return {
        ...state,
        isCookies: payload
      };
    }

    case 'FAVOURITEES':
    {
      return {
        ...state,
        favourites: payload
      };
    }

    default:
    return state;
  }
};
export default Auth;

function newDeposit(walletData, newEntry) {

  let depositArray = walletData && walletData.hasOwnProperty('history')
  && Array.isArray(walletData['history']['Deposit'])
  ? walletData['history']['Deposit'] : [];

  let index = depositArray.findIndex(({ _id }) => _id === newEntry['_id']);
  if (index >= 0) depositArray[index] = newEntry;
  else depositArray.unshift(newEntry);

  return walletData;
}

import React, { Component } from 'react';

import $ from 'jquery';
import Web3 from 'web3';
import moment from 'moment';
import EventBus from 'eventing-bus';
import { Player } from 'video-react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Timer from 'react-compound-timer';
import * as Sentry from '@sentry/browser';
import Lightbox from 'react-image-lightbox';
import { Modal, ModalBody } from 'reactstrap';
import NumberFormat from 'react-number-format';
import AliceCarousel from 'react-alice-carousel'
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import ReactHtmlParser, { processNodes } from 'react-html-parser';

// import './singleProperty.css'
import 'react-image-lightbox/style.css';
import 'react-alice-carousel/lib/alice-carousel.css'

import ModalCustom from '../ModalCustom';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import BuyToken from './../../components/BuyToken';
import { investNow } from '../../store/actions/Property';
import { overlayLoader } from '../../store/actions/Settings';
import { web3, providerInject, magic } from '../../store/web3';
import { TKUSDABI, TKUSDAddress, networkId } from '../../store/contract';
import { getAllProperties, getSingleProperty, handleLoginWalletModal, userDashChart, singleProperty } from '../../store/actions/Auth';

function transform(node, index) {
  if (node.name === 'p') return <p key={index} style={{ fontFamily: 'Pragati Narrow !important' }}>{processNodes(node.children, transform)}</p>;
}

const options = {
  decodeEntities: true,
  transform
};

class SingleProperty extends Component {
  constructor(props) {
    super(props);

    const { query } = require('url').parse(document.location.href, true);
    props.singleProperty()
    if (!query['id']) props.history.push('/')
    else props.getSingleProperty(query['id'])

    this.state = {
      gasPrice: '65',
      propertyId: query['id'],
      photoIndex: 0,
      images: {},
      isOpen: false,
      modalLogin: false,
      modalView: '',
      currentIndex: 0,
      itemsInSlide: 1,
      price: 0,
      sliderPrice: 1,
      quantity: 1,
      modalInvest: false,
      loading: false,
      responsive: { 0: { items: 1 } },
      isBuy: false
    }
    window.scrollTo(0, 0);
  }

  componentDidMount() {
    if (localStorage.getItem('token'))
      setTimeout(() => this.props.userDashChart(), 2000);

    $('.scroll-to-top').on('click', function () {
      $('html, body').animate({ scrollTop: 0 }, 500);
    });
  }

  componentWillReceiveProps(props) {
    if (!props.auth && this.state.isBuy) {
      this.setState({ isBuy: false })
    }
  }

  toggle = () => {
    if (!this.props.auth) this.setState({ modalLogin: !this.state.modalLogin });
  }

  toggleInvest = () => {
    if (this.props.auth) this.setState({ modalInvest: !this.state.modalInvest });
  }

  openImage = (allImages, imageSrc) => {
    this.setState({ allImages: [] });

    // let image = [imageSrc];
    this.setState({ images: allImages, photoIndex: imageSrc });

    setTimeout(() => this.setState({ isOpen: true }), 10);
  }

  handleLoginSignup = async (e) => {
    this.toggle();
    this.setState({ selectedMethod: "login" });
    this.props.handleLoginWalletModal(true);
  }

  changePrice = (e) => {
    if (e == 'subtract' && this.state.quantity > 0)
      this.setState({ quantity: --this.state.quantity });
    else this.setState({ quantity: ++this.state.quantity });
  }

  openModal = () => this.setState({ modalView: <ModalCustom loginModal={true} /> });
  handleFormChange = (event) => this.setState({ [event.target.name]: event.target.value });
  buyMax = async (balance, price) => this.setState({ quantity: parseInt(balance / (price + (price * 0.02))) });

  getGasPrice = ()=> {
    return new Promise(resolve=> {
      fetch('https://ethgasstation.info/api/ethgasAPI.json')
      .then(response => response.json())
      .then(data => this.setState({ gasPrice: (data['fastest'] / 10).toString() }, ()=> {
        resolve();
      }))
      .catch(e=> resolve());
    });
  }

  buyTokens = async () => {
    try {
      let { contractAddress, abi, price } = this.props['singlePropertyData']['sto'];
      let amount = price * this.state.quantity;
      // let amount = web3.utils.toWei(`${price * this.state.quantity}`, 'ether');
      // let totalAmount = (amount + (amount * 0.02)).toString();
      let totalAmount = (amount).toString();

      if (this.props.dashChartData.overview < amount) {
        EventBus.publish("error", 'You have insufficient Balance')
        return;
      }

      this.setState({ loading: true });
      if(localStorage.getItem('ETH-PROVIDER') == 'metamask') this.props.overlayLoader({status:true, loadingMessage:'Please Sign Transaction to Buy Tokens'})
      else this.props.overlayLoader({status:true, loadingMessage:'Investing Tokens'})

      if(localStorage.getItem('ETH-PROVIDER') == 'formatic'){
        const isLoggedIn = await magic.user.isLoggedIn();
        if(!isLoggedIn) {
          this.props.history.push('/');
          EventBus.publish('tokenExpired');
          return;
        }
      }

      let web3Injected = new Web3(providerInject);
      let from = (await web3Injected.currentProvider.enable())[0];
      let TKUSDData = new web3Injected.eth.Contract(TKUSDABI, TKUSDAddress);

      let buyFee = (await TKUSDData.methods.transferWithData(
        contractAddress,
        web3.utils.toHex(totalAmount),
        web3.utils.toHex(this.state.quantity)).estimateGas({ from })) * 2;

      let userGas = (await web3Injected.eth.getBalance(from));
      if (userGas < buyFee) {
        EventBus.publish("error", "Not enough gas to perform this transaction.");
        this.setState({ loading: false })
        this.props.overlayLoader({status:false, loadingMessage:''})
        return;
      }

      await this.getGasPrice();
      web3Injected.eth.sendTransaction({
        from,
        value: 0,
        to: TKUSDAddress,
        gas: buyFee,
        gasPrice: web3.utils.toHex(web3.utils.toWei(this.state.gasPrice, 'gwei')),

        data: TKUSDData.methods.transferWithData(
          contractAddress,
          web3.utils.toHex(totalAmount),
          web3.utils.toHex(this.state.quantity)).encodeABI(),
        // data: TKUSD.methods.transferWithData(contractAddress, web3.utils.toHex(totalAmount), web3.utils.hexToBytes(web3.utils.toHex(this.state.quantity))).encodeABI(),
      })
        .on('transactionHash', (hash) => {
          this.props.overlayLoader({status:true, loadingMessage:'Your transaction is Mining'})
          // EventBus.publish("info", 'Your transaction is Mining')
        })
        .on('receipt', (receipt) => {
          let data = {
            NetwordId: networkId,
            quantity: this.state.quantity,
            transactionHash: receipt['transactionHash'],
            id: this.props.singlePropertyData.property['_id'],
          }
          this.props.overlayLoader({status:true, loadingMessage:'We are recording your data from blockchain.'})
          this.props.investNow(data);
          this.toggleInvest();
          this.setState({ loading: false });
        })
        .on('error', async (error) => {
          this.setState({ loading: false });
          this.props.overlayLoader({status:false, loadingMessage:''})
          if(error['message'].indexOf('Insufficient funds.'))
            EventBus.publish("error", 'Something went wrong please contact customer support.');
          else EventBus.publish("error", error['message']);
        });
    } catch (e) {
      if(e['message'].indexOf('Insufficient funds.'))
        EventBus.publish("error", 'Something went wrong please contact customer support.');
      else EventBus.publish("error", e['message']);
      Sentry.captureMessage(e['message'] + ' buyTokens');
      this.setState({ loading: false })
      this.props.overlayLoader({status:false, loadingMessage:''})
    }
  }

  componentWillUpdate() {
    if (this.state.isBuy) {
      const scrollToEl = document.querySelector("#isBuy");
      const topOfElement = window.pageYOffset + scrollToEl.getBoundingClientRect().top;
      window.scroll({ top: topOfElement, behavior: "smooth" });
    }
  }

  render() {
    let { overview } = this.props.dashChartData || null;
    const { photoIndex, images, isBuy } = this.state;
    let { property, token, sto } = this.props.singlePropertyData || {};
    let tokenAvailable = sto && sto.tokenOnSale ? sto.tokenUnSold : 0
    let tokenSold = sto && sto.tokenOnSale ? parseInt(sto.tokenSold) + (sto.totalTokens - sto.tokenOnSale) : 0
    let soldPercentage = sto && sto.tokenOnSale ? (tokenSold / sto.totalTokens) * 100 : 0

    let today = new Date();
    let startDate = sto && sto.startDate ? new Date(sto['startDate']) : 0;
    let time = (startDate - today);
    return (
      <div className="page-wrapper">
        <Navbar stickyNavbar={true} />
        {property ?
          <div>
            <section className="page-title style-two" style={{ backgroundImage: `url(${property.banner.location})` }}>
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="page-content sp-title text-left">
                      <h1>{property.title}</h1>
                      {isBuy ? (
                        <a href="#" className="back-btn" onClick={() => this.setState({ isBuy: !isBuy })}><i className="fas fa-chevron-left"></i> Back To Property Page</a>
                      ) : ( <Link className="back-btn" to="/listing"><i className="fas fa-chevron-left"></i> To Listings</Link> )
                      }
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {!isBuy && (
              <section className="stcikybanner">
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <div className="inner-invest">
                        <div className="row clearfix">
                          <div className="col-lg-9 col-md-9 col-sm-12">
                            <div className="invest-box">
                              <div className="inner-invest-box">
                                <div className="icon-area">
                                  <figure><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/Logo0.png" alt="" /></figure>
                                </div>
                                <div className="text-area">
                                  <h4>TOKEN NAME</h4>
                                  <h3>{token.symbol}</h3>
                                </div>
                              </div>
                              <div className="inner-invest-box">
                                <div className="icon-area">
                                  <figure><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/18.png" alt="" /></figure>
                                </div>
                                <div className="text-area">
                                  <h4>TOKEN PRICE</h4>
                                  <h3>$ {<NumberFormat value={sto.price} displayType={'text'} thousandSeparator={true} />}<sub> TKUSD</sub></h3>
                                </div>
                              </div>
                              <div className="inner-invest-box">
                                <div className="icon-area">
                                  <figure><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/19.png" alt="" /></figure>
                                </div>
                                <div className="text-area">
                                  <h4>TOTAL LIQUIDITY</h4>
                                  <h3>$ {<NumberFormat value={token.totalLiquidity} displayType={'text'} thousandSeparator={true} />}<sub> TKUSD</sub></h3>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-3 col-sm-12">

                            {sto['status'] == 'Canceled' &&
                              < div className="invest-btn-area">
                                <p className='text-white'>
                                  Property Closed
                                </p>
                              </div>
                            }

                            {(sto['status'] == 'Pre-Goal' || ((sto['status'] == 'Post-Goal' || sto['status'] == 'Sold') && !property['secondaryLaunched'])) &&
                              < div className="invest-btn-area">
                                <a href="#" id="singleProperty-header-primary-invest" onClick={this.props.auth ? this.toggleInvest : this.toggle} className="thm-btn btn-style-one">Invest Now </a>
                              </div>
                            }

                            {(sto['status'] == 'Post-Goal' || sto['status'] == 'Sold') && property['secondaryLaunched'] &&
                              < div className="invest-btn-area">
                                <a href="#" id="singleProperty-header-secondary-invest" onClick={() => this.props.auth ? this.setState({ isBuy: !isBuy }) : this.toggle()} className="thm-btn btn-style-one">Invest Now </a>
                              </div>
                            }

                            {sto['status'] == 'Pre-Launch' &&
                              <div className="offering-area-white" style={{ padding: "11px 22px" }}>
                                <span className="offering-area-white"> <i className="fas fa-stopwatch"></i> Offering Starts In </span>

                                {
                                  sto['daysRemaining'] >= 1
                                    ?
                                      <span>{sto['daysRemaining']} Day{sto['daysRemaining'] > 1 && 's'}</span>
                                    :
                                    <div>
                                      {
                                        time > 5999999
                                          ?
                                          <Timer
                                            initialTime={time}
                                            direction="backward"
                                            checkpoints={[
                                              {
                                                time: 2000,
                                                callback: () => this.props.getSingleProperty(this.state.propertyId),
                                              }
                                            ]}
                                          >
                                            {() => (
                                              <React.Fragment>
                                                <Timer.Hours /> {time > 5999999 ? 'hours, ' : 'hour, '}
                                                <Timer.Minutes /> minutes
                                              </React.Fragment>

                                            )}
                                          </Timer>
                                          :
                                          <Timer
                                            initialTime={time}
                                            direction="backward"
                                          >
                                            {() => (
                                              <React.Fragment>
                                                <Timer.Minutes /> {time > 60000 ? 'minutes, ' : 'minute, '}
                                                <Timer.Seconds /> seconds
                                              </React.Fragment>
                                            )}
                                          </Timer>
                                      }
                                    </div>
                                }
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}
            <section className={`invest-sec ${isBuy ? "is-buy" : ""}`}>
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="inner-invest">
                      <div className="row clearfix">
                        <div className={`col-md-12 col-sm-12 ${isBuy ? "col-lg-12" : "col-lg-9"}`}>
                          <div className="invest-box">
                            <div className="inner-invest-box">
                              <div className="icon-area">
                                <figure><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/Logo0.png" alt="" /></figure>
                              </div>
                              <div className="text-area">
                                <h4>TOKEN NAME</h4>
                                <h3>{token.symbol}</h3>
                              </div>
                            </div>
                            <div className="inner-invest-box">
                              <div className="icon-area">
                                <figure><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/18.png" alt="" /></figure>
                              </div>
                              <div className="text-area">
                                <h4>TOKEN PRICE</h4>
                                <h3>$ {<NumberFormat value={sto.price} displayType={'text'} thousandSeparator={true} />}<sub> TKUSD</sub></h3>
                              </div>
                            </div>
                            <div className="inner-invest-box">
                              <div className="icon-area">
                                <figure><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/19.png" alt="" /></figure>
                              </div>
                              <div className="text-area">
                                <h4>TOTAL LIQUIDITY</h4>
                                <h3>$ {<NumberFormat value={token.totalLiquidity} displayType={'text'} thousandSeparator={true} />}<sub> TKUSD</sub></h3>
                              </div>
                            </div>
                          </div>
                        </div>
                        {!isBuy && (
                          <div className="col-lg-3 col-md-12 col-sm-12">
                            {sto['status'] == 'Canceled' &&
                              <div className="invest-btn-area pt-2">
                                <strong>
                                  Property Closed
                                </strong>
                              </div>
                            }

                            {(sto['status'] == 'Pre-Goal' || ((sto['status'] == 'Post-Goal' || sto['status'] == 'Sold') && !property['secondaryLaunched'])) &&
                              <div className="invest-btn-area">
                                <a id="singleProperty-primary-invest" href="#" onClick={this.props.auth ? this.toggleInvest : this.toggle} className="thm-btn btn-style-one">Invest Now </a>
                              </div>
                            }

                            {(sto['status'] == 'Post-Goal' || sto['status'] == 'Sold') && property['secondaryLaunched'] &&
                              <div className="invest-btn-area">
                                <a id="singleProperty-secondary-invest" href="#" onClick={() => this.props.auth ? this.setState({ isBuy: !isBuy }) : this.toggle()} className="thm-btn btn-style-one">Invest Now </a>
                              </div>
                            }

                            {sto['status'] == 'Pre-Launch' &&
                              <div className="offering-area" style={{ padding: "8px 9px" }}>
                                <span className="offering-area"> <i className="fas fa-stopwatch"></i> Offering starts in </span>
                                {
                                  sto['daysRemaining'] >= 1
                                    ?
                                      <span>{sto['daysRemaining']} Day{sto['daysRemaining'] > 1 && 's'}</span>
                                    :
                                    <div>
                                      {
                                        time > 5999999
                                          ?
                                          <Timer
                                            initialTime={time}
                                            direction="backward"
                                          >
                                            {() => (
                                              <React.Fragment>
                                                <Timer.Hours /> {time > 5999999 ? 'hours, ' : 'hour, '}
                                                <Timer.Minutes /> minutes
                                              </React.Fragment>

                                            )}
                                          </Timer>
                                          :
                                          <Timer
                                            initialTime={time}
                                            direction="backward"
                                            checkpoints={[
                                              {
                                                time: 1500,
                                                callback: () => this.props.getSingleProperty(this.state.propertyId),
                                              }
                                            ]}
                                          >
                                            {() => (
                                              <React.Fragment>
                                                <Timer.Minutes /> {time > 60000 ? 'minutes, ' : 'minute, '}
                                                <Timer.Seconds /> seconds
                                              </React.Fragment>
                                            )}
                                          </Timer>
                                      }
                                    </div>
                                }
                              </div>
                            }
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section id="isBuy" className={`property-single ${isBuy ? "is-buy" : ""}`}>
              <div className="container">
                <div className="row">
                  <div className={`content-column ${isBuy ? "col-lg-12" : "col-lg-8"} col-md-12 col-sm-12`}>
                    <div className="content-area" id='isBuy'>

                      {sto['status'] !== 'Sold' &&
                        <div className="range-slider">
                          <h3>Token Status</h3>
                          <div className="rangeslider-inner">
                            <div className="rabgelsie-bar">
                              <span className="defult"></span>
                              <span className="active" style={soldPercentage < 4 ? { width: 4 + '%' } : { width: soldPercentage + '%' }}></span>
                              {soldPercentage >= 10 &&
                                <span className="value value-1">
                                  <h4>Total</h4>
                                  <i>{sto.totalTokens}</i>
                                </span>
                              }
                              {!isBuy && (
                                <span className="value value-4 pt-3" style={{ position: 'absolute', left: Number(sto['goalPercent'] + (98 - sto['capPercent'])) + '%' }}>
                                  <h4>Goal <i>{<NumberFormat decimalScale="2" value={Number((sto.totalTokens * sto.goalPercent) / 100) + Number(sto.totalTokens - sto.tokenOnSale)} displayType={'text'} thousandSeparator={true} />}</i></h4>
                                </span>
                              )}
                              <span className="value " style={{ position: 'absolute', left: soldPercentage - 2 + '%' }}>
                                <h4>Sold</h4>
                                <i>{<NumberFormat decimalScale="2" value={Number(parseInt(sto.tokenSold) + (sto.totalTokens - sto.tokenOnSale))} displayType={'text'} thousandSeparator={true} />}</i>
                              </span>
                              {soldPercentage < 90 &&
                                <span className="value value-3">
                                  <h4>Available</h4>
                                  <i>{<NumberFormat decimalScale="2" value={tokenAvailable} displayType={'text'} thousandSeparator={true} />}</i>
                                </span>
                              }
                            </div>
                          </div>
                        </div>
                      }
                      {isBuy ?
                        <BuyToken propertyData={this.props.singlePropertyData} dashChartData={this.props.dashChartData} {...this.props} />
                        :
                        <>
                          <div className="slider-area">
                            {this.state.isOpen && (
                              <Lightbox
                                mainSrc={images[photoIndex].location}
                                nextSrc={images[(photoIndex + 1) % images.length].location}
                                prevSrc={images[(photoIndex + images.length - 1) % images.length].location}
                                onMovePrevRequest={() =>
                                  this.setState({
                                    photoIndex: (photoIndex + images.length - 1) % images.length,
                                  })
                                }
                                onMoveNextRequest={() =>
                                  this.setState({
                                    photoIndex: (photoIndex + 1) % images.length,
                                  })
                                }

                                onCloseRequest={() => this.setState({ isOpen: false, images: [] })}
                              />
                            )}
                            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                              {/*
                                <div >
                                  <span className="icon" onClick={() => this.Carousel.slidePrev()}><img src="https://res.cloudinary.com/sndtech/image/upload/v1582288901/left_slider_xevbtd.png" /></span>
                                </div>
                                <div style={{ width: "100%" }}>

                              */}
                              <div style={{ position:'absolute', left: '-6%' }}>
                                <span className="icon" onClick={() => this.Carousel.slidePrev()}><img src="https://res.cloudinary.com/sndtech/image/upload/v1582288901/left_slider_xevbtd.png" /></span>
                              </div>
                                <AliceCarousel
                                  slideToIndex={this.state.currentIndex}
                                  responsive={this.state.responsive}
                                  onInitialized={this.handleOnSlideChange}
                                  onSlideChanged={this.handleOnSlideChange}
                                  onResized={this.handleOnSlideChange}
                                  dotsDisabled={true}
                                  buttonsDisabled={true}
                                  ref={(el) => (this.Carousel = el)}
                                >
                                  {Array.isArray(property.carousel) ? property.carousel.map((i, idx, z) => {
                                    return (
                                      <div onClick={(value) => this.openImage(property.carousel, idx)}>
                                        <img src={i.location} alt={i.name} height={500} />
                                      </div>
                                    )
                                  })
                                    : null}
                                </AliceCarousel>
                                <div style={{ position:'absolute', right: '-6%' }}>
                                  <span className="icon" onClick={() => this.Carousel.slideNext()}><img src="https://res.cloudinary.com/sndtech/image/upload/v1582288901/right_slider_cfle7w.png" /></span>
                                </div>
                                {/*
                                </div>
                                <div >
                                  <span className="icon" onClick={() => this.Carousel.slideNext()}><img src="https://res.cloudinary.com/sndtech/image/upload/v1582288901/right_slider_cfle7w.png" /></span>
                                </div>
                                */}

                            </div>
                            <Modal
                              modalClassName="modal-invest"
                              toggle={this.toggleInvest}
                              isOpen={this.state.modalInvest}
                            >
                              <div className="modal-header justify-content-center">
                                <div className="modal-profile">
                                  <img className='modalLogoImgBig' src="https://tknismtest.s3.amazonaws.com/non-compressed-images/Logo0.png" alt="" />
                                </div>
                              </div>
                              <div className="text-right modalCloseIcon">
                                <i className="fa fa-close" onClick={this.toggleInvest}></i>
                              </div>
                              <ModalBody>
                                <div className="scroll-div-invest-modal">
                                  <div className="row">
                                    <div className="col-2 my-auto">
                                      <img className='modalPropertyImage' src={property.banner.location} alt="" />
                                    </div>
                                    <div className="col-10">
                                      <div className="row text-left">
                                        <div className="col-2">
                                          <h6>Token Name</h6>
                                          <span className="modalDescription">{token.symbol}</span>
                                        </div>
                                        <div className="col-2">
                                          <h6>Token Price</h6>
                                          <span className="modalDescription">$ {<NumberFormat value={sto.price} displayType={'text'} thousandSeparator={true} />}<sub> TKUSD</sub></span>
                                        </div>
                                        <div className="col-3">
                                          <h6>Token Available</h6>
                                          <span className="modalDescription">{<NumberFormat value={tokenAvailable} displayType={'text'} thousandSeparator={true} />}<sub> {token.symbol}</sub></span>
                                        </div>
                                        {/* <div className="col-2">
                                          <h6>Invest Fee</h6>
                                          <span className="modalDescription">2%</span>
                                        </div> */}
                                        <div className="col-3">
                                          <h6>Available Balance</h6>
                                          <span className="modalDescription">$ {<NumberFormat value={overview || 0} displayType={'text'} thousandSeparator={true} />}<sub> TKUSD</sub></span>
                                        </div>
                                      </div>
                                      <div className="row pt-3">
                                        <div className="col-4 text-left">
                                          <h5>{property.title.substring(0, 12) + "..."}</h5>
                                          <span className="modalDescription">{property.description.substring(0, 45) + "..."}</span>
                                        </div>
                                        <div className="col-5 group-form p-3">
                                          <div className="row">
                                            <div className="col-6">
                                              <span className="colorBlack">$ {sto.price}</span>
                                              {/* <span className="colorBlack">$ {sto.price + (sto.price * 0.02)}</span> */}
                                            </div>
                                            <div className="col-6">
                                              <span>
                                                <span id="singleProperty-modal-subtractQuantity" className="signs pr-2" onClick={() => this.changePrice('subtract')}>-</span>
                                                <NumberFormat id="singleProperty-modal-changeQuantity" placeholder="Price" variant="outlined" name="quantity" value={this.state.quantity} onChange={this.handleFormChange} className='colorBlack' thousandSeparator={true} />
                                                <span id="singleProperty-modal-addQuantity" className="signs" onClick={() => this.changePrice("add")}>+</span>
                                              </span>
                                            </div>
                                          </div>
                                          <div className="row pt-4">
                                            <div className="col-12">
                                              <button id="singleProperty-modal-buyMax" onClick={() => this.buyMax(overview, sto.price)} className="custom-btn-disabled">BUY MAX</button>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-3 group-form p-3">
                                          <div className="row">
                                            <div className="col-12">
                                              <span className="colorBlackBig">$ {(this.state.quantity * sto.price) || 0}</span>
                                              {/* <span className="colorBlackBig">$ {(this.state.quantity * sto.price + (this.state.quantity * sto.price * 0.02)) || 0}</span> */}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </ModalBody>
                              <div className="modal-footer row">
                                <div className="invest-btn-area col-12 offset pt-3 pb-3">
                                  <button className="custom-btn" id="singleProperty-modal-buyToken" onClick={this.buyTokens} disabled={this.state.loading}>
                                    {!this.state.loading ? 'Buy Token' : <i className="fa fa-spinner fa-spin fa-1x fa-fw" />}
                                  </button>
                                </div>
                              </div>
                            </Modal>

                            <Modal
                              modalClassName="modal-mini modal-info"
                              toggle={this.toggle}
                              isOpen={this.state.modalLogin}
                            >
                              <div className="modal-header justify-content-center">
                                <div className="modal-profile">
                                  <img className='modalLogoImg' src="https://tknismtest.s3.amazonaws.com/non-compressed-images/Logo0.png" alt="" />
                                </div>
                              </div>
                              <ModalBody>
                                <p>Look's like you are not logged in.</p>
                              </ModalBody>
                              <div className="modal-footer row">
                                <button onClick={() => this.handleLoginSignup("login")} className="custom-btn modal-mini-button">Login</button>
                                <Link to='/starter-packages'><button className="custom-btn modal-mini-button">Get Started</button></Link>
                              </div>
                            </Modal>
                          </div>
                          <div className="propety-overview">
                            <h3>Property Overview</h3>
                            <div className="propety-detail">
                              <div className="propety-inner">
                                <h4>Targeted IRR</h4>
                                <p>{property.tagetedROR} %</p>
                              </div>
                              <div className="propety-inner">
                                <h4>NOI</h4>
                                <p>$ <NumberFormat value={property.noiPercent} displayType={'text'} thousandSeparator={true} /></p>
                              </div>
                              <div className="propety-inner">
                                <h4>Debt</h4>
                                <p>{property.deptSwap} %</p>
                              </div>
                              <div className="propety-inner">
                                <h4>Total Units</h4>
                                <p>{property.units}</p>
                              </div>
                              <div className="propety-inner">
                                <h4>Min Investment</h4>
                                <p>$ <NumberFormat value={1000} displayType={'text'} thousandSeparator={true} /></p>
                              </div>
                              <div className="propety-inner">
                                <h4>Total Tokens</h4>
                                <p><NumberFormat value={(sto.propertyValue/1000)} displayType={'text'} thousandSeparator={true} /></p>
                              </div>
                              <div className="propety-inner">
                                <h4>Tokens Sold</h4>
                                <p><NumberFormat value={Number(parseInt(sto.tokenSold) + (sto.totalTokens - sto.tokenOnSale))} displayType={'text'} thousandSeparator={true} /></p>
                              </div>
                              <div className="propety-inner">
                                <h4>Available Tokens</h4>
                                <p><NumberFormat value={(sto.price * sto.tokenOnSale)/1000} displayType={'text'} thousandSeparator={true} /></p>
                              </div>
                              <div className="propety-inner">
                                <h4>Days Remaining</h4>
                                <p>{sto.daysRemaining} Days</p>
                              </div>
                              <div className="propety-inner">
                                <h4>Offering Duration</h4>
                                <p>{sto.durationDays > 30 ? Math.round(sto.durationDays / 30) +  ' Months' : sto.durationDays + ' Days'} </p>
                              </div>
                              {sto['status'] === 'Pre-Launch' &&
                                <div className="propety-inner">
                                  <h4>Offering Start Date</h4>
                                  <p>{moment(sto.startDate).format('ll')}</p>
                                </div>
                              }
                              <div className="propety-inner">
                                <h4>Offering End Date</h4>
                                <p>{moment(sto.endDate).format('ll')}</p>
                              </div>

                              <div className="propety-inner">
                                <h4>Exchange Opens On</h4>
                                <p>{moment(property.secondaryLaunch).format('ll')}</p>
                              </div>

                              <div className="propety-inner">
                                <h4>Project Cost</h4>
                                <p>$ <NumberFormat value={property.projectCost ? property.projectCost : ''} displayType={'text'} thousandSeparator={true} /></p>
                              </div>

                            </div>
                          </div>
                          <div className="sidebar html-sections">
                            {property.sections && property.sections.length !== 0 ?
                              property.sections.map((property) => <div className="widget doucment-widget"> {ReactHtmlParser(property, options)} </div>) : null}
                          </div>
                          <div className="map-area">
                            <h3>Location</h3>
                            <div className="map-outer">
                              <Map
                                zoom={10}
                                google={this.props.google}
                                style={{ width: "100%", height: "100%" }}
                                initialCenter={{
                                  lat: parseFloat(property.latitudes),
                                  lng: parseFloat(property.longitude),
                                }}
                              >
                                <Marker
                                  position={{
                                    lat: parseFloat(property.latitudes),
                                    lng: parseFloat(property.longitude),
                                  }}
                                />
                              </Map>
                            </div>
                          </div>
                        </>
                      }
                    </div>
                  </div>
                  {!isBuy &&
                    <aside className="col-lg-4 col-md-12 col-sm-12">
                      <div className="sidebar">
                        <div className="widget propety-widget">
                          <div className="title-area">
                            <div className="icon-area">
                              <figure><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/22.png" alt="" /></figure>
                            </div>
                            <h3>{property.title}</h3>
                            <p>{property.description}
                            </p>
                          </div>
                        </div>

                        <div className="widget add-widget">
                          <div className="add-area player-wrapper">
                            <figure><img src={property.historyGraph ? property.historyGraph : ''} alt="" /></figure>
                          </div>
                        </div>

                        <div className="widget doucment-widget">
                          <div className="title-area">
                            <div className="icon-area">
                              <figure><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/23.png" alt="" /></figure>
                            </div>
                            <h3>Documents</h3>
                            {
                              this.props.auth ?
                                <ul className="list">
                                  {property.documents && property.documents.map(i => { return <li><a href={i.location}>{i.name}</a></li> })}
                                </ul>
                                :
                                <ul className="list cursor-pointer">
                                  {property.documents && property.documents.map(i => { return <li><a href="#" onClick={this.toggle}>{i.name}</a></li> })}
                                </ul>
                            }
                          </div>
                        </div>
                        <div className="widget add-widget">
                          <div className="add-area player-wrapper">
                            {property.videoURL ?
                              <Player>
                                <source src={property.videoURL} />
                              </Player>
                             :
                              <p> To join the webinar Please signup <a href={property.webinarURL} target="_blank">here</a>.
                              <span>{moment(property.webinarDate).format('lll')}</span>
                              </p>
                            }
                          </div>
                        </div>
                      </div>
                    </aside>
                  }
                </div>
              </div>
            </section>
          </div>
          :
          <div className="center text-center my-5 py-5">
            <i className="fa fa-spinner fa-spin fa-4x fa-fw" style={{ color: '#8cc63f' }} />
            <br /> <br />
            Loading
          </div>
        }
        <Footer />
        <div className="scroll-to-top scroll-to-target">
          <i className="fa fa-arrow-up" aria-hidden="true" />
        </div>
      </div >
    );
  }
}
const mapStateToProps = ({ Auth }) => {
  let { singlePropertyData, auth, dashChartData } = Auth;
  return { singlePropertyData, auth, dashChartData: dashChartData || {} }
}
const mapDispatchToProps = { getAllProperties, getSingleProperty, userDashChart, handleLoginWalletModal, investNow, singleProperty, overlayLoader };

export default connect(mapStateToProps, mapDispatchToProps)
(GoogleApiWrapper({
  apiKey: process.env.REACT_APP_MAP_KEYS
})(SingleProperty));

import EventBus from 'eventing-bus';
import { connect } from "react-redux";
import CardDetails from '../../CardDetails';
import React, { Component } from 'react';
import ExistingAccount from './ExistingAccount';
import { depositePayment, userDashboard } from '../../../../../store/actions/Auth';
import { ValidatorForm } from 'react-material-ui-form-validator';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import AppLoader from '../../../../../components/AppLoader'
import FormHelperText from '@material-ui/core/FormHelperText';
import NumberFormat from 'react-number-format'


class DebitCard extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {
      isDisabled: false,
      feePercent: 0.02, // 40 Percent
      formData: {
        currency: "USD",
        amount: ''
      },
      showExisting: props.userCards.length ? true : false
    }
  }

  componentDidMount()
  {
    EventBus.on("cardDeposit", ()=> this.setState({isDisabled: false}));
  }

  handleFormChange = (event,r) =>
  {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  }

  handleSubmit = ()=>
  {
    this.setState({isDisabled: true});
    const { formData } = this.state;
    if (formData.expiry)
    {
      let expiry = formData.expiry.split("/")
      formData['expMonth'] = expiry[0]
      formData['expYear'] = expiry[1]
    }

    if(this.state.showExisting){

      if(!this.state.formData.cardNumber){
        EventBus.publish("error", 'Select A Card')
        this.setState({isDisabled: false})
        return ;
      }

      if(!this.state.formData.amount){
        EventBus.publish("error", 'Fill In amount please')
        this.setState({isDisabled: false})
        return ;
      }

      this.setState({isDisabled: true})
      this.props.depositePayment(this.state.formData)

    }else if (!this.state.showExisting){
      this.setState({isDisabled: true})
      if(this.state.formData.expMonth > 12){
        EventBus.publish("error", 'Invalid Inputs')
        this.setState({isDisabled: false})
      }else{
        this.props.depositePayment(this.state.formData)
      }
    }
    // this.props.depositePayment(this.state.formData)
  }

  selectedCard = (card) =>{
    let amount = this.state.formData.amount
    card['amount'] = amount
    this.setState({ formData: card })
  }

  componentWillReceiveProps(props)
  {
    if (props.userCards.length) this.setState({ showExisting: true })
    else this.setState({ showExisting: false })
  }

  goBack=()=>
  {
    if(this.props.userCards.length) this.setState({ showExisting: true });
    else this.props.onSelectMethod("none")
  }

  render() {
    let { dashChartData } = this.props;
    let { overview } = dashChartData;
    let { feePercent, formData } = this.state;
    let amountShow = formData.amount || 0;

    const { userCards } = this.props;
    return (
      <div className="deposite-page tab-content">
        <form className="deposit-tab" id="credit">
          <div className="row clearfix">
            {
              !this.state.showExisting
              ? <CardDetails goBack={this.goBack} onChange={(value) => this.handleFormChange(value)} {...this.state} {...this.props}/>
            : <ExistingAccount selectedCard={(value) => this.selectedCard(value)} addCard={() => this.setState({ showExisting: false })} cardsData={userCards} {...this.props} {...this.state} />
        }
        <div className="col-lg-5 offset-lg-1 col-md-12 col-12">
          <div className="right-confrom-area pb-2">
            {this.state.loading ?
              <AppLoader message='Transaction Processing' />
              :
              <div className="group-form">
                <div className="row pb-3">
                  <div className="col-6">
                    <span className="balance-text grey-text">Current Balance :</span>
                  </div>
                  <div className="col-6">
                    <span className="balance-amount grey-text"> <NumberFormat value={parseFloat(overview || 0).toFixed(0)} displayType={'text'} thousandSeparator={true} /> <span className="grey-text label-small">TKUSD</span></span>
                    {/* <label className="label-default">{parseFloat(overview || 0).toFixed(0)}</label> */}
                  </div>
                </div>

                <ValidatorForm className="validatorForm pt-2" onSubmit={this.handleSubmit}>
                  <div className="row">
                    <div className="col-md-12 col-lg-12">
                      <FormControl variant="outlined">
                        <InputLabel>
                          Amount
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-error-helper-text"
                          error={formData.amount > 5000}
                          className="MyTextField2"
                          label="amount"
                          labelWidth={30}
                          name="amount"
                          type="number"
                          min="1"
                          step="1"
                          validators={['required']}
                          value={formData.amount}
                          onChange={this.handleFormChange}
                          startAdornment={<InputAdornment position="start"
                            >
                            $
                          </InputAdornment>}
                          aria-describedby="outlined-weight-helper-text"
                          />
                        {formData.amount > 5000 ? <FormHelperText id="outlined-error-helper-text" className="helperText">Should be less than 5000.</FormHelperText> : ''
                        }
                        {formData.amount.indexOf(".") != -1 ? <FormHelperText id="outlined-error-helper-text" className="helperText">Should be integer value.</FormHelperText> : ''}
                      </FormControl>
                    </div>
                  </div>
                  <div className="row justify-content-end">
                    <div className="col-12 col-md-8 pt-4">
                      <div className="row">
                        <div className="col-12 mb-3">
                          <div className="row">
                            <div className="col-8">
                              <span className="label-custom pull-right">Deposit Amount: </span>
                            </div>
                            <div className="col-4 nopadding ">
                              <span className="label-custom pull-right"><NumberFormat value={Number.parseFloat(amountShow).toFixed(0)} displayType={'text'} thousandSeparator={true} /></span>
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-12 pt-1">
                          <div className="row">
                            <div className="col-8">
                              <span className="label-custom pull-right">Transaction Fees: </span>
                            </div>
                            <div className="col-4 nopadding">
                              <span className="label-custom-red pull-right"><NumberFormat value={Number.parseFloat(amountShow).toFixed(0)} displayType={'text'} thousandSeparator={true} /></span>
                            </div>
                          </div>
                        </div> */}
                      </div>
                      <hr className="divider" />
                      <div className="group-form">
                        <div className="row">
                          <div className="col-8">
                            <span className="label-custom pull-right">You Will Receive: </span>
                          </div>
                          <div className="col-4 nopadding ">
                            <span className="label-custom pull-right">
                              <span className="grey-text label-small">TKUSD</span> <NumberFormat value={Number.parseFloat(amountShow).toFixed(0)} displayType={'text'} thousandSeparator={true} />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="group-form d-flex justify-content-center pt-3">
                    {amountShow > 5000 || formData.amount.indexOf(".") != -1
                      ?
                      <button
                        disabled={true}
                        className="thm-btn btn-style-one"
                        >
                        <i className="fa fa-ban fa-1x fa-fw" />
                      </button>
                      :
                      <button
                        disabled={this.state.isDisabled}
                        className="thm-btn btn-style-one"
                        >
                        {!this.state.isDisabled ? (
                          "Confirm & Pay"
                        ) : (
                          <i className="fa fa-spinner fa-spin fa-1x fa-fw" />
                        )}
                      </button>
                    }
                  </div>
                </ValidatorForm>
              </div>
            }
          </div>
        </div>
      </div>
    </form>
  </div>
);
}
}

const mapDispatchToProps = { depositePayment, userDashboard };

const mapStateToProps = (state) => {
  return { userCards: state.Auth.userCards || [], dashChartData: state.Auth.dashChartData || {} }
}
export default connect(mapStateToProps, mapDispatchToProps)(DebitCard);

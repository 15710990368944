import 'date-fns';
import EventBus from 'eventing-bus';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import {ValidatorForm} from 'react-material-ui-form-validator';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckBox from './../../../components/CheckBox/CheckBox';

import { loginRequest, getAgreements, agreementsChecked, logout } from '../../../store/actions/Auth';
import { Link } from "react-router-dom";

class Agreements extends Component
{
  constructor()
  {
    super()
    this.state = {
      pageContent: '',
      isDisabled: false,
      agreeCheck:false,
      showButton: false,
      selectedDate:new Date('2014-08-18T21:11:54'),
      formData: {
        checks:{
          "point1": {"value": false, "description": "I understand that there is no guarantee of any financial returns on this investment(s) and that I run the risk of loss in my investment(s); and that I have not been provided tax advice by Tokenism LLC.", required: true},
          "point2": {"value": false, "description": <div> I have reviewed the terms of use, privacy policy and the cookies policies for Tokenism LLC, as well as our partner Dwolla's <a style={{color:'#8cc63f'}} href="https://www.dwolla.com/legal/tos/" target="_blank"> Terms of Service </a> and <a style={{color:'#8cc63f'}} href="https://www.dwolla.com/legal/privacy/" target="_blank"> Privacy Policy</a>. </div>, required: true},
          "point3": {"value": false, "description": "I certify that the information provided is true and correct and understand it will be used in the W-9. I have reviewed and acknowledge the W-9. ", required: true},
        }
      }
    }
    window.scrollTo(0,0);
  }

  componentDidMount()
  {
    if(!this.props.agreementsData)
    this.props.getAgreements()
    if (this.props.allSignData.step4 && Object.keys(this.props.allSignData.step4).length > 0) {
      const { formData } = this.state;
      let myStep = this.props.allSignData.step4
      // formData['checks']['point1']['value'] = myStep['point1']['value'];
      // formData['checks']['point2']['value'] = myStep['point2']['value'];
      // formData['checks']['point3']['value'] = myStep['point3']['value'];
      this.setState({ formData });
    }
    EventBus.on("Agreements", ()=> this.setState({isDisabled: false}));
  }

  agreementSubmit=()=>
  {
    const { formData } = this.state;
    let bodyJson = JSON.parse(JSON.stringify(formData.checks));
    bodyJson['hash'] = this.props.signData.hash;

    this.setState({isDisabled: true});
    this.props.agreementsChecked(bodyJson)
  }

  handleCheck = (check, key)=>
  {
    const { formData } = this.state;
    formData.checks[check.target.name].value = key;
    this.setState({ formData });
  }

  goBack=()=>{
    if(this.props.allSignData.step2.citizenship == "United States"){
      this.props.onBack(3);
    } else {
      this.props.onBack(2);
    }
  }

  render()
  {
    const item = this.props.agreementsData ? this.props.agreementsData : {}
    return (
      <section className="signUp-sec">
        <div className="container">
          <div className="row pt-3">
            <div className="col-12">
              <div className="content-area">
                <div className="pb-2">
                  <h1 className="signUpInnerTitles">Agreements</h1>
                  <hr />
                </div>
                <ValidatorForm className="validatorForm" onSubmit={this.agreementSubmit}>
                  <div className="row">
                    <div className="col-12" >
                      <span className="formBlurHeading">The following documents are provided for your reference.</span>
                    </div>

                    <div className="col-12 pl-4 pt-2">
                      <div className="row">
                            <div className="col-4">
                              <img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/23.png" alt="" className="pr-1" />
                              <Link to="/terms-of-use" target="_blank">Terms of Use</Link>
                            </div>
                            <div className="col-4">
                              <img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/23.png" alt="" className="pr-1" />
                              <Link to="/privacy-policy" target="_blank">Privacy Policy</Link>
                            </div>
                            <div className="col-4">
                              <img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/23.png" alt="" className="pr-1" />
                              <Link to="/cookies-policy" target="_blank">Cookies Policy</Link>
                            </div>
                      </div>
                    </div>
                    <div className="col-12 pt-3">
                      <h4>Acknowledgments:</h4>
                      <span className="formBlurHeading">Please indicate agreement with the following.</span>
                    </div>
                    <div className="col-12">
                      {
                        Object.keys(this.state.formData.checks).map(key=>
                          {
                            let check = this.state.formData.checks[key];
                            return<div>
                                <FormControlLabel
                                  value={check.value}
                                  onChange={(check, key) => this.handleCheck(check,key)}
                                  className="formHeading"
                                  control={<Checkbox color="primary" />}
                                  labelPlacement="end"
                                  validators={['required']}
                                  errormessages={['Please agree to the terms']}
                                  name={key}
                                  label={check.description}
                                  />

                            </div>
                          })
                        }
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <div className="loginBtnDiv pt-3">
                          <div className="outer-box" >
                            <button type="button" name="skipButton" value="skipButton" onClick={()=>this.goBack()} className="thm-btn btn-style-one-outline mr-1" disabled = {this.state.isDisabled}>
                              {!this.state.isDisabled ? 'Back' : <i className="fa fa-spinner fa-spin fa-1x fa-fw"/>}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <div className="loginBtnDiv pt-3">
                          <div className="outer-box" >
                            {
                              this.state.formData.checks.point1.value && this.state.formData.checks.point2.value && this.state.formData.checks.point3.value
                              ?
                              <button disabled = {this.state.isDisabled} className="thm-btn btn-style-one ml-auto d-block">
                                {!this.state.isDisabled ? 'Continue' : <i className="fa fa-spinner fa-spin fa-1x fa-fw"/>}
                              </button>
                              :
                              <button disabled className="thm-btn btn-style-one ml-auto d-block">
                                <i className="fa fa-ban fa-1x fa-fw"/>
                              </button>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </ValidatorForm>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    }
  }

  const mapStateToProps = ({Auth})=>
  {
    let {auth, agreementsData} = Auth;
    return {
      auth, agreementsData,
      signData: Auth.signData ? Auth.signData.info : [],
      allSignData:Auth.signData
    }
  }

  const mapDispatchToProps= {loginRequest, getAgreements, agreementsChecked, logout};
  export default connect(mapStateToProps, mapDispatchToProps)(Agreements);

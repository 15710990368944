import React, { Component, Suspense, lazy } from 'react';
import EventBus from "eventing-bus";
import { connect } from "react-redux";
import { createBrowserHistory } from "history";
import { ToastContainer, toast } from "react-toastify";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import FadeLoader from "react-spinners/FadeLoader";
import { css } from "@emotion/core";
import {
  Button,
  Row,
  Col,
  Container,
  FormGroup,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Alert
} from "reactstrap";

import { Link } from 'react-router-dom';
import Plaid from "./Plaid";
import SignUp from "./SignUp";
import Wallet from "./AppDashboard/Wallet";
import Settings from "./AppDashboard/Settings";
import DealRoom from "./AppDashboard/DealRoom";
import Listing from "./Listing";
import OurTeam from "./OurTeam";
import Benefits from "./Benefits";
import HelpCenter from "./HelpCenter";
import Technology from "./Technology";
import HowItWorks from "./HowItWorks";
import ContactUs from './ContactUs'
import UserDashboard from "./AppDashboard/Dashboard";
import Kyc from "./Kyc";
import Investing101 from "./Investing101";
import SignUpProcess from "./SignUpProcess";
import InvestmentPlans from "./InvestmentPlan";
import SingleProperty from "./SingleProperty";
import ReviewsAndRatings from "./ReviewsAndRatings";
import Investing101Detail from "./Investing101Detail";
import Notifications from "./Notifications";
import Documents from "./AppDashboard/Documents";
import Favorites from "./AppDashboard/Favorites";
import TknError from "./404Page";
import CookiesPolicy from "./CookiesPolicy";
import Events from "./Events";
import Maintenance from "./Maintenance";
import { InfoModal } from './../components/InfoModal'
import Loader from './../components/Loader'
import Web3 from 'web3'
// import "./app.css";

import PrivateRoute from "../store/PrivateRoute";
import {
  loginSuccess,
  logout,
  getSignData,
  handleWalletModal,
  handleLoginAuthModal,
  loginAuthTabs,
  verifySmsAuth,
  sendSmsAuth,
  verify2FAuth,
  userDashHeader,
  userDashChart,
  handelUserMessage,
} from "../store/actions/Auth";
import { otpLoader, overlayLoader } from "../store/actions/Settings";
import "../theme/css/owl.css";
import "../theme/js/script.js";
// import "../theme/sass/style.css";
import "./../styles/css/style.css";
import "../theme/css/responsive.css";
import "../theme/css/bootstrap.min.css";
// import '../theme/sass/_dashboard.scss';
import * as Sentry from '@sentry/browser';
import "jquery/dist/jquery.min.js";
import "react-rangeslider/lib/index.css";
import "react-toastify/dist/ReactToastify.css";
import { Modal, ModalBody } from "reactstrap";
import Intercom from 'react-intercom';
import { resetExchange } from "../store/actions/Exchange";
import { resetSettings } from "../store/actions/Settings";
import { resetWallet } from "../store/actions/Wallet";
import { Offline, Online } from "react-detect-offline";
import IdleTimer from 'react-idle-timer'
import TermsOfUse from "./TermsOfUse";
import PrivacyPolicy from "./PrivacyPolicy";
import ReactGA from 'react-ga';

const Landing = lazy(() => import('./Landing'));


const trackingId = "UA-175225968-1";

const user = {
  user_id: "",
  email: "",
  name: ""
};
const hist = createBrowserHistory();

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class App extends Component {
  constructor(props) {
    super(props);
    this.initialState = {
      activeTab: props.showGoogleAuth ? "GA" : "SA",
      v1: "",
      v2: "",
      v3: "",
      v4: "",
      v5: "",
      v6: "",
      count: 0,
      seconds: 59,
    };
    this.lastActiveTime = new Date()
    this.idleTimer = null
    this.handleOnIdle = this.handleOnIdle.bind(this)
    this.handleOnAction = this.handleOnAction.bind(this)
    this.handleOnActive = this.handleOnActive.bind(this)
    this.state = this.initialState;

    if(window.location.host == 'localhost:3000'){

    }else{
      this.sentryEnvironment = process.env.REACT_APP_SERVER + ' Front-End-' + window.location.host
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY,
        environment: this.sentryEnvironment,
      });
    }

    if (process.env.REACT_APP_SERVER === 'production') {
    console.log = function () {};
    }
  }

  componentDidMount() {
    setTimeout(() => {
      if (this.props.showGoogleAuth)
        this.setState({ activeTab: "GA" });
      else this.setState({ activeTab: "SA" });
    }, 2000);
    this.checkEthereum();
    EventBus.on("invalid2fa", this.invalid2fa);
    EventBus.on("info", this.handleInfo);
    EventBus.on("error", this.handleError);
    EventBus.on("success", this.handleSuccess);
    EventBus.on("signData", this.handlesignData);
    EventBus.on("loginAuthTabs", this.handleTabs);
    EventBus.on("loginLoader", this.handleLoginLoader);
    EventBus.on("signUpAcknowledgment", this.handleSignUpAcknowledgment);
    EventBus.on("tokenExpired", () => {
      if(this.props.auth){
          this.handleError('Your session has expired');
          this.props.logout()
          this.props.resetExchange();
          this.props.resetSettings();
          this.props.resetWallet();
          // setTimeout(() => window.location.reload(), 500);
      }
    });
    // this.setupBeforeUnloadListener();
    this.checkToken();
    this.initializeAnalytics();
  }

  initializeAnalytics = () => {
    const { REACT_APP_SERVER } = process['env'];
    let trackingId = 'UA-175225968-1'
    if(REACT_APP_SERVER == 'development'){
      trackingId = 'UA-175225968-1'
    }else if(REACT_APP_SERVER == 'production'){
      trackingId = 'UA-179426394-1'
    }else if(REACT_APP_SERVER == 'prototype'){
      trackingId = 'UA-175896372-1'
    }
    ReactGA.initialize(trackingId);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  handleSignUpAcknowledgment = () =>{
    let infoHeading = "New Account Created"
    let infoButton = 'Ok'
    let infoMessage = <div> Welcome to Tokenism your new account is successfully enabled. <br/ > <br/ >This also confirms that you've read and accepted our payment partner Dwolla's <a style={{color:'#8cc63f'}} href="https://www.dwolla.com/legal/tos/" target="_blank"> Terms of Service </a> and <a style={{color:'#8cc63f'}} href="https://www.dwolla.com/legal/privacy/" target="_blank"> Privacy Policy</a>, and have created a Dwolla account.</div>
    const data = {
      isOpen: true,
      infoMessage,
      infoHeading,
      infoButton
    }
    this.props.handelUserMessage(data)
  }

  checkToken = () =>{
    if(!sessionStorage.getItem('token')) {
      this.props.logout()
    }
  }

  setupBeforeUnloadListener = () => {
    window.addEventListener("beforeunload", (ev) => {
      ev.preventDefault();
      this.props.logout()
    });
  };

  handleLoginLoader = e =>{
    this.props.overlayLoader({status:false, loadingMessage:''})
  }

  invalid2fa = e => this.setState({ v1: "", v2: "", v3: "", v4: "", v5: "", v6: "" });

  handleSuccess = e => {
    if (e.message !== undefined && e.message !== null) {
      if (
        e.message == "Sucessfully logged In!" ||
        e.message == "Registered successfully!"
      )
        this.props.loginSuccess(e.body);
        this.props.userDashHeader();
        this.props.userDashChart();

      toast.success(e.message, {
        autoClose: 10000,
        draggable: true,
        closeOnClick: true,
        pauseOnHover: false,
        hideProgressBar: true,
        position: "bottom-left"
      });
    } else
      toast.success(e, {
        autoClose: 10000,
        draggable: true,
        closeOnClick: true,
        pauseOnHover: false,
        hideProgressBar: true,
        position: "bottom-left"
      });
  };

  handleError = e =>
    toast.error(e, {
      position: "bottom-left",
      autoClose: 10000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true
    });

  handleInfo = e =>
    toast.info(e, {
      position: "bottom-left",
      autoClose: 10000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true
    });

  checkEthereum = () => {
    if (typeof window.ethereum !== "undefined") {
      let ethereum = new Web3(window.ethereum);
      // if (ethereum.currentProvider.isMetaMask) {
      if (localStorage.getItem('ETH-PROVIDER') == 'metamask') {
        window.ethereum.on("accountsChanged", accounts => {
          if (this.props.auth && localStorage.getItem('ETH-ID') !== accounts[0]) {
            this.props.logout();
            this.props.resetExchange();
            this.props.resetSettings();
            this.props.resetWallet();
            EventBus.publish("info", "Account has been changed");
            setTimeout(() => window.location.reload(), 500);
          }
          // Time to reload your interface with accounts[0]!
        });
        window.ethereum.on("networkChanged", netId => {
          if (this.props.auth) {
            this.props.logout();
            this.props.resetExchange();
            this.props.resetSettings();
            this.props.resetWallet();
            EventBus.publish("info", "Network has changed");
            setTimeout(() => window.location.reload(), 500);
          }
          // Time to reload your interface with netId
        });
      }
    }
  };

  handlesignData = e => this.props.getSignData(e);

  handleVerificationCode = e => {
    if (e.target.name === "v1") {
      var input = document.getElementById("v2");
      input.focus();
      input.select();
    } else if (e.target.name === "v2") {
      var input = document.getElementById("v3");
      input.focus();
      input.select();
    } else if (e.target.name === "v3") {
      var input = document.getElementById("v4");
      input.focus();
      input.select();
    } else if (this.state.activeTab === "GA" && e.target.name === "v4") {
      var input = document.getElementById("v5");
      input.focus();
      input.select();
    } else if (this.state.activeTab === "GA" && e.target.name === "v5") {
      var input = document.getElementById("v6");
      input.focus();
      input.select();
    }

    this.setState({ [e.target.name]: e.target.value }, () => {
      const { v1, v2, v3, v4, v5, v6 } = this.state;
      if (this.state.activeTab === "GA") {
        if (
          v1 !== "" &&
          v2 !== "" &&
          v3 !== "" &&
          v4 !== "" &&
          v5 !== "" &&
          v6 !== ""
        ) {
          let data = {
            token_code: v1 + v2 + v3 + v4 + v5 + v6,
            hash: localStorage.getItem("hash")
          };
          this.props.otpLoader(true);
          clearInterval(this.interval);
          EventBus.publish(`2faApi`, data);
          this.setState({});
          // let history = hist;
          // this.props.verify2FAuth({data, history});
        }
      } else {
        if (v1 !== "" && v2 !== "" && v3 !== "" && v4 !== "") {
          this.props.otpLoader(true);
          let data = {
            sms_code: v1 + v2 + v3 + v4,
            hash: localStorage.getItem("hash")
          };
          clearInterval(this.interval);
          EventBus.publish(`verifySmsAuth`, data);
          // this.props.verifySmsAuth({
          //   sms_code: v1 + v2 + v3 + v4,
          //   hash: localStorage.getItem("hash")
          // });
        }
      }
    });
  };

  componentWillReceiveProps(props) {
    if (props.showloginAuthModal && !props.showOtpLoader) {
      // this.state = this.initialState;
      if (props.showGoogleAuth)
        this.setState({ activeTab: "GA" });
      else this.setState({ activeTab: "SA" });
      // setTimeout(() => console.log(`************latest state = `, this.state), 50)
    }
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   if (this.props === nextProps) {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // }

  handleTabs = ev => this.props.loginAuthTabs(ev);

  toggleVerificationModal = () => {
    this.setState({seconds:'', v1: "", v2: "", v3: "", v4: "", v5: "", v6: "", count:0 })
    this.props.handleLoginAuthModal(!this.props.showloginAuthModal);
  };

  sendSms = () => {
    this.props.sendSmsAuth();
    this.setState({ count: 1, seconds: 59 }, () => {
      if (this.state.seconds > 1) {
        this.interval = setInterval(() => this.tick(), 1000);
      }
    });
  };

  tick() {
    if (this.state.seconds >= 1) {
      this.setState({ seconds: this.state.seconds - 1 });
    } else {
      clearInterval(this.interval);
    }
  }

  renderModal = () => {
    const { showGoogleAuth, showSMSAuth } = this.props;
    return (
      <>
      {/* <Intercom appID="oxys8iou" /> */}

      <Modal
        modalClassName="modal-mini modal-info settings-rule modal-barCode auth-modal"
        toggle={this.toggleVerificationModal}
        isOpen={this.props.showloginAuthModal}
      >
        <Row>
          <Col md="12">
            <Nav tabs>
              {showGoogleAuth ? (
                <NavItem
                  style={
                    showGoogleAuth && !showSMSAuth
                      ? { width: "100%" }
                      : { background: "transparent" }
                  }
                >
                  <NavLink
                    active={this.state.activeTab === "GA"}
                    disabled={this.state.activeTab === "GA"}
                    onClick={() => {
                      this.setState({ activeTab: "GA" });
                    }}
                    style={{ borderTopLeftRadius: "23px", overflow: "hidden" }}
                  >
                    <p
                      style={{
                        fontSize: "14px",
                        textAlign: "center",
                        whiteSpace: "nowrap"
                      }}
                    >
                      Google Authentication
                    </p>
                  </NavLink>
                </NavItem>
              ) : null}

              {showSMSAuth ? (
                <NavItem
                  style={
                    showSMSAuth && !showGoogleAuth
                      ? { width: "100%" }
                      : { background: "transparent" }
                  }
                >
                  <NavLink
                    active={this.state.activeTab === "SA"}
                    disabled={this.state.activeTab === "SA"}
                    onClick={() => {
                      this.setState({ activeTab: "SA" });
                    }}
                    style={{ borderTopRightRadius: "23px", overflow: "hidden" }}
                  >
                    <Col md={12} className="text-left">
                      <p
                        style={{
                          fontSize: "14px",
                          textAlign: "center",
                          whiteSpace: "nowrap"
                        }}
                      >
                        Text Authentication
                      </p>
                    </Col>
                  </NavLink>
                </NavItem>
              ) : null}
            </Nav>
          </Col>
        </Row>
        <ModalBody>
          <div className="modal-body-container">
            <p
              className="verification-title"
              style={{ marginTop: "10px", width: "80%" }}
            >
              {this.state.activeTab === "SA"
                ? "Input the 4-digit code sent to your mobile number"
                : "Input the 6-digit code in your google authentication app"}
            </p>
            {this.state.activeTab === "SA" ? (
              this.state.count === 0 ? (
                <button onClick={this.sendSms} className="custom-btn mt-3">Send Verification Code</button>

              ) : this.state.seconds === 0 ? (
                <p className="resend-title" onClick={this.sendSms}>
                  Resend verification code
                </p>
              ) : (
                    <p className="resend-title">
                      Resend verification code in {this.state.seconds}
                    </p>
                  )
            ) : (
                <p className="resend-title"></p>
              )}
            <div className="code-input-container">
              <input
                name="v1"
                id="v1"
                onChange={this.handleVerificationCode}
                className="code-input"
                type="text"
                value={this.state.v1}
                maxLength={1}
              />
              <input
                name="v2"
                id="v2"
                onChange={this.handleVerificationCode}
                className="code-input"
                type="text"
                value={this.state.v2}
                maxLength={1}
              />
              <input
                name="v3"
                id="v3"
                value={this.state.v3}
                onChange={this.handleVerificationCode}
                className="code-input"
                type="text"
                maxLength={1}
              />
              <input
                name="v4"
                id="v4"
                onChange={this.handleVerificationCode}
                className="code-input"
                type="text"
                value={this.state.v4}
                maxLength={1}
              />
              {this.state.activeTab !== "SA" ? (
                <div>
                  <input
                    name="v5"
                    id="v5"
                    onChange={this.handleVerificationCode}
                    className="code-input"
                    value={this.state.v5}
                    type="text"
                    maxLength={1}
                  />
                  <input
                    name="v6"
                    id="v6"
                    value={this.state.v6}
                    onChange={this.handleVerificationCode}
                    className="code-input"
                    type="text"
                    maxLength={1}
                  />
                </div>
              ) : null}
            </div>
            <div className="loading-container">
              <FadeLoader
                css={override}
                size={150}
                color={"#8cc63f"}
                loading={this.props.showOtpLoader}
              />
            </div>
          </div>
        </ModalBody>
      </Modal>
      </>
    );
  };

  handleOnIdle = (event) =>{
    if(this.props.auth) {
      EventBus.publish("info", "User logged out due to inactivity");
      this.props.logout();
      setTimeout(() => window.location.reload(), 500);
    }
  }

  handleOnAction (event) {
    if(event.type == 'visibilitychange'){
      document.addEventListener("visibilitychange", event => {
        if (document.visibilityState == "visible") {
          let lastActiveSeconds = (this.idleTimer.getLastActiveTime() - this.lastActiveTime) / 1000
          if(lastActiveSeconds > 900 && this.props.auth) {
            EventBus.publish("info", "User logged out due to inactivity");
            this.props.logout();
            setTimeout(() => window.location.reload(), 500);
          }else if(lastActiveSeconds > 120 && !this.props.auth){
            // window.location.reload(false)
          }
        }
      })
    }
    this.lastActiveTime = this.idleTimer.getLastActiveTime()
    // this.setState({lastActiveTime:this.idleTimer.getLastActiveTime()})
  }

  handleOnActive (event) {
    // console.log('react-idle-timer user is active', event)
    // console.log('react-idle-timer last active', this.idleTimer.getLastActiveTime(), new Date(this.idleTimer.getLastActiveTime()))
    // console.log('react-idle-timer time remaining', this.idleTimer.getRemainingTime(), new Date(this.idleTimer.getRemainingTime()))
  }

  render() {
    const { showGoogleAuth, showSMSAuth, isOpen, infoMessage, infoHeading, infoButton, handelUserMessage } = this.props;
    return (
      <div>
        {/* <Offline>
          <Router history={hist}>
            <Switch>
              <Route
                exact
                path="/"
                render={props => <TknError {...props} />}
              />
              <Route
                path="/"
                render= {props =>  <Redirect to="/" /> }
              />
            </Switch>
          </Router>
        </Offline> */}
          {/* <Online> */}
            <IdleTimer
              ref={ref => { this.idleTimer = ref }}
              timeout={900000}
              onIdle={this.handleOnIdle}
              onAction={this.handleOnAction}
              onActive={this.handleOnActive}
              debounce={250}
              />

            <ToastContainer
              position="bottom-center"
              autoClose={5000}
              hideProgressBar
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange={false}
              draggable
              pauseOnHover={false}
            />

          <InfoModal isOpen={isOpen} toggle={handelUserMessage} infoMessage={infoMessage} infoHeading={infoHeading} infoButton={infoButton} />

            <Loader />
            {(showGoogleAuth || showSMSAuth) && this.renderModal()}


          <Suspense fallback={<div>Loading...</div>}>
            <Router history={hist}>
          <Switch>
            <Route exact path="/" render={props => <Landing {...props} />} />
            <Route
              path="/listing"
              render={props => <Listing {...props} />}
            />
            <Route
              path="/events"
              render={props => <Events {...props} />}
            />
            <Route path="/about-us" render={props => <OurTeam {...props} />} />
            <Route
              path="/benefits"
              render={props => <Benefits {...props} />}
            />
            <Route
              path="/cookies-policy"
              render={props => <CookiesPolicy {...props} />}
            />
            <Route
              exact
              path="/terms-of-use"
              render={props => <TermsOfUse {...props} />}
            />
            <Route
              exact
              path="/privacy-policy"
              render={props => <PrivacyPolicy {...props} />}
            />
            <Route
              path="/technology"
              render={props => <Technology {...props} />}
            />
            <Route
              path="/help-center"
              render={props => <HelpCenter {...props} />}
            />
            <Route
              path="/contact-us"
              render={props => <ContactUs {...props} />}
            />
            <Route
              path="/how-it-works"
              render={props => <HowItWorks {...props} />}
            />
            <Route
              path="/investing101"
              render={props => <Investing101 {...props} />}
            />
            <Route
              path="/single-property"
              render={props => <SingleProperty {...props} />}
            />
            <Route
              path="/reviews-and-ratings"
              render={props => <ReviewsAndRatings {...props} />}
            />
            <Route
              path="/investing101-details"
              render={props => <Investing101Detail {...props} />}
            />
            <Route
              path="/starter-packages"
              render={props => <SignUpProcess {...props} />}
            />
            <Route
              path="/sign-up/:hash"
              render={props => <SignUp {...props} />}
            />
            <Route
              path="/sign-up"
              render={props => <SignUp {...props} />}
            />
            <Route
              path="/investment-plans"
              render={props => <InvestmentPlans {...props} />}
            />
            <Route path="/plaid" render={props => <Plaid {...props} />} />

            <PrivateRoute
              exact
              path="/dashboard/wallet"
              component={props => <Wallet {...props} />}
            />
            <PrivateRoute
              path="/dashboard/portfolio"
              component={props => <DealRoom {...props} />}
            />
            <PrivateRoute
              path="/kyc"
              component={props => <Kyc {...props} />}
            />
            <PrivateRoute
              path="/dashboard/settings"
              component={props => <Settings {...props} />}
            />
            <PrivateRoute
              exact
              path="/dashboard"
              component={props => <UserDashboard {...props} />}
            />
            <PrivateRoute
              path="/notifications"
              component={props => <Notifications {...props} />}
            />
            <PrivateRoute
              path="/dashboard/documents"
              component={props => <Documents {...props} />}
            />
            <PrivateRoute
              path="/dashboard/favorites"
              component={props => <Favorites {...props} />}
            />
            <Route render={props => <Landing {...props} />} />
          </Switch>
        </Router>
        </Suspense>
      {/* </Online> */}
      </div>
    );
  }
}

const mapStateToProps = ({ Auth, Settings, Exchange }) => {
  let { showOtpLoader } = Settings;
  let { activeOffersData } = Exchange;
  let { auth, showloginAuthModal, loginAuthModal, isOpen, isCookies, infoMessage , infoHeading, infoButton, step} = Auth;
  return {
    auth,
    isCookies,
    showloginAuthModal,
    isOpen,
    step,
    infoMessage,
    infoHeading,
    infoButton,
    showOtpLoader,
    activeOffersData,
    showGoogleAuth: loginAuthModal ? loginAuthModal.twoFaEnabled : true,
    showSMSAuth: loginAuthModal ? loginAuthModal.smsVerifyEnabled : true
  };
};

const mapDispatchToProps = {
  loginSuccess,
  logout,
  getSignData,
  handleWalletModal,
  handleLoginAuthModal,
  loginAuthTabs,
  verifySmsAuth,
  sendSmsAuth,
  verify2FAuth,
  otpLoader,
  userDashHeader,
  userDashChart,
  handelUserMessage,
  resetExchange,
  resetSettings,
  resetWallet,
  overlayLoader
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

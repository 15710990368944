import React, { Component } from 'react';
import { connect } from "react-redux";
import EventBus from 'eventing-bus';
import { Modal, ModalBody } from 'reactstrap';
import DashboardLayout from "./../DashboardLayout";
import Avtivity from './Activity'
import TransactionHistory from './TransactionHistory'
import InvestHistory from './InvestHistory'
import ListTokens from './ListTokens'
import Balance from './Balance'
import { overlayLoader } from "../../../store/actions/Settings";

class DealRoom extends Component {

  constructor(props) {
    super(props)
    this.state = {
      activeTab: "dealRoom",
      isOpen: false
    }
  }

  setActiveTab = (activeTab) => {
    this.setState({ activeTab })
  }

  renderComponent = (state) => {
    if (state.activeTab === "dealRoom")
    return <Avtivity />
    else if (state.activeTab === "transactionHistory")
    return <TransactionHistory />
    else if (state.activeTab === "investHistory")
    return <InvestHistory />
    else if (state.activeTab === "listTokens")
    return <ListTokens />
    else if (state.activeTab === "balance")
    return <Balance />
  }

  componentDidMount() {
    EventBus.on("tokensListed", this.setEventActiveTab);
    // const { step } = this.props.dashHeadData
    // if (step != undefined && step == 1 || step == 2 && this.props.location.pathname == '/dashboard/wallet') {
    //   this.props.history.push("/dashboard")
    // }
  }


  setEventActiveTab = e => {
    this.setState({ activeTab: e })
  }

  toggle = e => {
  };

  render() {
    let { activeTab, isOpen } = this.state
    return (
      <div className="page-wrapper">
        <DashboardLayout active='deal' {...this.props}>
          <section className="tabs-wallet-sec">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item"><a onClick={() => this.setActiveTab("balance")} className={activeTab === 'balance' ? "nav-link active" : "nav-link"} >Balance</a></li>
                  <li className="nav-item"><a onClick={() => this.setActiveTab("dealRoom")} className={activeTab === 'dealRoom' ? "nav-link active" : "nav-link"} >Deal Room</a></li>
                  <li className="nav-item"><a onClick={() => this.setActiveTab("transactionHistory")} className={activeTab === 'transactionHistory' ? "nav-link active" : "nav-link"} >Transaction History</a ></li>
                  <li className="nav-item"><a onClick={() => this.setActiveTab("investHistory")} className={activeTab === 'investHistory' ? "nav-link active" : "nav-link"} >Invest History</a ></li>
                  <li className="nav-item"><a onClick={() => this.setActiveTab("listTokens")} className={activeTab === 'listTokens' ? "nav-link active" : "nav-link"} id="list-tokens">List Tokens</a></li>
                </ul>
                {this.renderComponent(this.state)}
              </div>
            </div>
          </div>
          <Modal
            modalClassName="modal-mini modal-info"
            toggle={this.toggle}
            isOpen={isOpen}
          >
            <div className="modal-header justify-content-center">
              <div className="modal-profile">
                <img className='modalLogoImg' src="https://tknismtest.s3.amazonaws.com/non-compressed-images/favicon.png.png" alt="" />
              </div>
            </div>
            <ModalBody>
              <h6>Your account verification has failed.</h6>
              <button className="custom-btn" onClick={this.toggle}>Update</button>
            </ModalBody>
          </Modal>
        </section>
        </DashboardLayout>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { dashHeadData: state.Auth.dashHeadData || {}, activeOffersData: state.Exchange.activeOffersData }
}
const mapDispatchToProps = { overlayLoader };

export default connect(mapStateToProps, mapDispatchToProps)(DealRoom);

export const loginRequest = ({ data, history }) => ({
  type: 'LOGIN_REQUEST',
  payload: data,
  history
});

export const logout = () => ({
  type: 'LOGOUT'
});

export const loginSuccess = (data) => ({
  type: 'LOGIN_SUCCESS',
  payload: data,
});

export const signupRequest = (data) => ({
  type: 'SIGNUP_REQUEST',
  payload: data,
});

export const resetProcess = (data) => ({
  type: 'RESET_PROCESS',
  payload: data,
});

export const continueProcess = (data) => ({
  type: 'CONTINUE_PROCESS',
  payload: data,
});

export const setAccountType = (data) => ({
  type: 'SET_ACCOUNT_TYPE',
  payload: data,
});

export const contactSignInfo = (data) => ({
  type: 'SIGNUP_CONTACT_REQUEST',
  payload: data,
});

export const trustSignInfo = (data) => ({
  type: 'SIGNUP_TRUST_REQUEST',
  payload: data,
});

export const jointSignInfo = (data) => ({
  type: 'SIGNUP_JOINT_REQUEST',
  payload: data,
});

export const entitySignInfo = (data) => ({
  type: 'SIGNUP_ENTITY_REQUEST',
  payload: data,
});

export const documentsSignInfo = (data) => ({
  type: 'SIGNUP_DOCUMENTS_REQUEST',
  payload: data,
});

export const signAddBank = (data) => ({
  type: 'SIGNUP_ADD_BANK',
  payload: data,
});

export const signSkipBank = (data) => ({
  type: 'SIGNUP_SKIP_BANK',
  payload: data,
});

export const getAgreements = () => ({
  type: 'GET_AGREEMENTS',
});

export const userSubscription = (data) => ({
  type: 'USER_SUBSCRIBE',
  payload: data,
});

export const getUserSignData = ({ payload, process }) => ({
  type: 'USER_SIGN_DATA',
  payload,
  process
});

export const userSignData = (e) => ({
  type: 'GET_USER_FINAL_DATA',
  payload: e
});

export const agreementsChecked = (data) => ({
  type: 'AGREEMENTS_CHECKED',
  payload: data,
});

export const updateUser = (data) => ({
  type: 'UPDATE_USER',
  payload: data,
});

export const updateProfile = (data) => ({
  type: 'UPDATE_PROFILE',
  payload: data,
});

export const submitPremiumRequest = (data) => ({
  type: 'SUBMIT_PREMIUM_REQUEST',
  payload: data,
});

export const paypalDeposit = (data) => ({
  type: 'PAYPAL_DEPOSIT',
  payload: data
});

export const plaidUpload = (data) => ({
  type: 'PLAID_UPLOAD',
  payload: data
});

export const submitProperty = () => ({
  type: 'SUBMIT_PROPERTY'
});

export const userDashboard = () => ({
  type: 'USER_DASHBOARD'
});

export const dashboardData = (data) => ({
  type: 'DASHBOARD_DATA',
  payload: data,
});

export const userDashHeader = (data) => ({
  type: 'USER_DASH_HEADER',
  payload: data,
});

export const dashHeaderData = (data) => ({
  type: 'DASH_HEADER_DATA',
  payload: data,
});

export const userDashChart = (data) => ({
  type: 'USER_DASH_CHART',
  payload: data,
});

export const dashChartData = (data) => ({
  type: 'DASH_CHART_DATA',
  payload: data,
});

export const getAllProperties = (data) => ({
  type: 'GET_ALL_PROPERTIES',
  payload: data
});

export const handelUserMessage = (data) => ({
  type: 'SHOW_USER_RESTRICT',
  payload: data
});

export const getSingleProperty = (data) => ({
  type: 'GET_SINGLE_PROPERTY_DETAILS',
  payload: data
});

export const singleProperty = (data) => ({
  type: 'SINGLE_PROPERTY_RESPONSE',
  payload: data
});

export const setCookies = (data) => ({
  type: 'SET_COOKIES',
  payload: data
});

export const getCookies = (data) => ({
  type: 'GET_COOKIES_RES',
  payload: data
});

export const depositePayment = (data) => ({
  type: 'DEPOSITE_PAYMENT',
  payload: data
});

export const getCards = (data) => ({
  type: 'GET_USER_CARDS',
  payload: data
});

export const getWalletData = () => ({
  type: 'GET_WALLET_DATA',
});

export const getWalletDataResponse = () => ({
  type: 'GET_WALLET_DATA_RESPONSE',
});

export const getHistoryDW = () => ({
  type: 'GET_HISTORY_DW',
});

export const getBalance = () => ({
  type: 'GET_BALANCE',
});

export const getSignData = (e) => ({
  type: 'GET_SIGN_DATA',
  payload: e
});

export const connectETH = ({ payload, history }) => ({
  type: 'CONNECT_ETH',
  payload,
  history
});

export const getAgreementsData = (e) => ({
  type: 'SAVE_AGREEMENTS',
  payload: e
});

export const signupDwolla = (payload) => ({
  payload,
  type: 'SIGNUP_DWOLLA',
});

export const retryDwolla = (payload) => ({
  payload,
  type: 'RETRY_DWOLLA',
});

export const plaidInfo = () => ({
  type: 'PLAID_INFO'
});

export const setVerifyUser = (payload) => ({
  payload,
  type: 'SET_VERIFY_USER'
});

export const plaidSetup = (data) => ({
  type: 'PLAID_SETUP',
  payload: data,
});

export const tkusdInfo = () => ({
  type: 'TKUSD_INFO'
});

export const showIframeFn = (data) => ({
  type: 'SHOW_IFRAME',
  payload: data,
  user: localStorage.getItem('user')
});

export const submitAccredation = () => ({
  type: 'SUBMIT_ACCREDATION',
});

export const handleWalletModal = (data) => ({
  type: 'WALLET_MODAL',
  payload: data
});

export const handleLoginWalletModal = (data) => ({
  type: 'LOGIN_WALLET_MODAL',
  payload: data
});

export const handleDwollaModal = (data) => ({
  type: 'DWOLLA_MODAL',
  payload: data
});

export const handleLoginAuthModal = (data) => ({
  type: 'HANDLE_LOGIN_AUTH_MODAL',
  payload: data
});

export const loginAuthTabs = (data) => ({
  type: 'SHOW_LOGIN_AUTH_TABS',
  payload: data
});

export const checkEmail = (data) => ({
  type: 'CHECK_EMAIL',
  payload: data
});

export const verifySmsAuth = ({ data, history }) => ({
  type: 'VERIFY_SMS_AUTH',
  payload: data,
  history
});

export const sendSmsAuth = () => ({
  type: 'SEND_SMS_AUTH',
});

export const sendSMSSignup = (data) => ({
  type: 'SEND_SMS_SIGNUP',
  payload: data
});

export const verify2FAuth = ({ data, history }) => ({
  type: 'VERIFY_2FA_AUTH',
  payload: data,
  history
});

export const getAllBlogs = (data) => ({
  type: 'GET_ALL_BLOGS',
  payload: data,
});

export const updateAllBlogs = () => ({
  type: 'GET_BLOGS_RESPONSE',
  payload: []
});

export const favouriteProperties = ({status, onFavourites}) => ({
  type: 'FAVOURITE_PROPERTIES',
  payload: status,
  onFavourites
});

export const getKycStep = () => ({
      type: 'GET_KYC_STEP',
});

/* -- set app title --*/
const AppTitle = 'Tokenism';
const { REACT_APP_SERVER } = process['env'];

/* -- set app mode -- */
// const AppMode = [''];
const AppMode = [REACT_APP_SERVER];

/* -- set API URLs --*/
const staging = "https://sserver.tknism.io";
const prototype = 'https://server.tknism.io';
const development = 'https://dserver.tknism.io';
const production = 'https://productionapi.tknism.io';

let SocketUrl;
switch (AppMode[0]) {
  case 'development':
    SocketUrl = development;
    break;
  case 'prototype':
    SocketUrl = prototype;
    break;
  case 'staging':
    SocketUrl = staging;
    break;
  case 'production':
    SocketUrl = production;
    break;
  default:
    SocketUrl = 'http://localhost:4000';
}

let ApiUrl = `${SocketUrl}/api`;
let envoirnment = AppMode[0] ? AppMode[0] : 'development';

export { AppTitle, ApiUrl, SocketUrl, envoirnment };

import React, { Component } from 'react';
import { Link } from "react-router-dom";

class Maintenance extends Component {
    
    render() { 
        return ( 
        <>    
            <Link className="error-logo" to="/">
                <img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/logo.png" alt="logo" />
            </Link>
            <div className='tkn-error'>
                <img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/maintenance-panel.gif" alt="logo" />
                <p>System Under Maintenance</p>
                <p>We're prepairing to serve you better</p>
            </div>
        </> 
        );
    }
}
 
export default Maintenance;
import io from 'socket.io-client';
import EventBus from 'eventing-bus';
import { eventChannel } from 'redux-saga';
import { take, call, put } from 'redux-saga/effects';

import { SocketUrl } from '../config';
import { updateDeposit, imageUploaded } from '../actions/Wallet';
import { getAllNotifications, setAllNotifications } from '../actions/Settings';

let socket;
let id = localStorage.getItem('id');

export const updateID = (newId) => id = newId;

const connect = () => {
  socket = io(SocketUrl);
  return new Promise((resolve) => socket.on('connect', () => resolve(socket)));
};

const createSocketHello = socket => eventChannel((emit) => {

  // socket.emit('hello', 'Mousa');
  // socket.on('hello', (data) => {
  //   EventBus.publish('success', data);
  //   return;
  // });

  /************** User Notification Message **************/
  socket.on(`notification`, ({ userId, status, message }) => {
    if (userId == id) {
      emit(getAllNotifications());
      EventBus.publish(status, message);
    }
    return;
  });

  /************** Updated Notifications **************/
  socket.on(`notifications`, (data) => {
    if (data.length > 0 && data[0]['userId'] == id) {
    // if (userId == id) emit(updateDeposit(data));
    emit(setAllNotifications(data));
  }
    return
  });

  /************** Deposit Update **************/
  socket.on(`deposit:update`, ({ userId, data }) => {
    if (userId == id) emit(updateDeposit(data));
    return
  });

  socket.on(`image:submit`, ({ userId, data }) => {
    if (userId == id) emit(imageUploaded(data));
    return
  });

  socket.on(`deposit:micro`, ({ userId, data }) => {
    if (userId == id) EventBus.publish('success', `${data} ETH has been Deposited`);
    return;
  });

  return () => {
    socket.off('notifications', () => emit());
    socket.off(`notification`, () => emit());
    socket.off(`deposit:update`, () => emit());
  }
});

export default function* listenSockets() {
  const socket = yield call(connect);
  const socketChannel = yield call(createSocketHello, socket);
  while (true) {
    let action = yield take(socketChannel);
    if (action) yield put(action);
  }
};

// const disconnect = () => {
//   socket = io(SocketUrl);
//   return new Promise((resolve) => socket.on('disconnect', () => resolve(socket)));
// };

// const reconnect = () => {
//   socket = io(SocketUrl);
//   return new Promise((resolve) => socket.on('reconnect', () => resolve(socket)));
// };

import React from 'react';
import moment from "moment";
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import TableContainer from '@material-ui/core/TableContainer';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { transactionHistory } from '../../../../store/actions/Exchange';
import { otpLoader, overlayLoader } from "../../../../store/actions/Settings";
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import TablePagination from '@material-ui/core/TablePagination';
import Loader from '../../../../components/AppLoader';

class TransactionHistory extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      filter: "1y",
      rowsPerPage:10,
      page:0,
      transactionHistoryData:this.props.transactionHistoryData,
      message:'Loading your Transaction History',
      loader: false,

    }
    // props.otpLoader(true)
    props.overlayLoader({status:true, loadingMessage:'Loading your Transaction History.'})
  }

  componentDidMount() {
    this.props.transactionHistory();
  }

  componentWillReceiveProps(props) {
    if(props.transactionHistoryData) this.setState({transactionHistoryData: props.transactionHistoryData, loader:false})
  }

  handleChangePage = (event, newPage) => {
    this.setState({page:newPage})
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage : parseInt(event.target.value, 10) });
    this.setState({page:0})
  }

  // static getDerivedStateFromProps = (nextProps, prevState) => {
  //   if (nextProps.transactionHistoryData !== prevState.transactionHistoryData) {
  //     let { transactionHistoryData } = nextProps;
  //     if (transactionHistoryData) return { transactionHistoryData };
  //   }
  //   else return null; // Triggers no change in the state
  // }

  selectTimePeriod = (value) => this.setState({ value })

  handleFilter = e =>{
    this.setState({ [e.target.name]: e.target.value });
    let today = new Date();
    let filterDate = new Date();
    let array = []

    if(e.target.value == "Td"){
      let pastDate = today.getDate()
      filterDate.setDate(pastDate)
      this.props.transactionHistoryData.map(x=>{
        if(moment(filterDate).format("ll") == moment(new Date(x.updatedAt)).format("ll")){
          array.push(x)
        }
      })
      this.setState({transactionHistoryData: array})
    }else if(e.target.value == "Ow"){
      let pastDate = today.getDate() - 7
      filterDate.setDate(pastDate)
      this.props.transactionHistoryData.map(x=>{
        if(new Date(x.updatedAt) < filterDate){
          console.log('break');
        }else{
          array.push(x)
        }
      })
      this.setState({transactionHistoryData: array})
    }else if(e.target.value == "1m"){
      let pastDate = today.getDate() - 30
      filterDate.setDate(pastDate)
      this.props.transactionHistoryData.map(x=>{
        if(new Date(x.updatedAt) < filterDate){
          console.log('break');
        }else{
          array.push(x)
        }
      })
      this.setState({transactionHistoryData: array})
    }else if(e.target.value == "3m"){
      let pastDate = today.getDate() - 90
      filterDate.setDate(pastDate)
      this.props.transactionHistoryData.map(x=>{
        if(new Date(x.updatedAt) < filterDate){
          console.log('break');
        }else{
          array.push(x)
        }
      })
      this.setState({transactionHistoryData: array})
    }else if(e.target.value == "1y"){
      let pastDate = today.getDate() - 365
      filterDate.setDate(pastDate)
      this.props.transactionHistoryData.map(x=>{
        if(new Date(x.updatedAt) < filterDate){
          console.log('break');
        }else{
          array.push(x)
        }
      })
      this.setState({transactionHistoryData: array})
    }

  }

  render() {

    const classes = {};
    const { value, rowsPerPage, page, message, loader } = this.state;
    const { selectTimePeriod, handleFilter } = this;
    let { transactionHistoryData, showOtpLoader } = this.props;
    return (
      <div className="scroll-div">
        <TableContainer component={Paper} className="transaction-tabel">
          {showOtpLoader || loader ? <Loader message={message} /> :
            <>
          <div className="transaction-header">
            <FormControl component="fieldset" className="radio-lists">
              <RadioGroup aria-label="gender" className="flex-row" name="gender1" value={value} onChange={(e) => selectTimePeriod(e.target.value)}>
                <FormControlLabel value="Td" onChange={handleFilter} name="filter" control={<Radio />} label="Today" />
                <FormControlLabel value="Ow" onChange={handleFilter} name="filter" control={<Radio />} label="One Week" />
                <FormControlLabel value="1m" onChange={handleFilter} name="filter" control={<Radio />} label="One Month" />
                <FormControlLabel value="3m" onChange={handleFilter} name="filter" control={<Radio />} label="Three Months" />
                <FormControlLabel value="1y" onChange={handleFilter} name="filter" control={<Radio />} label="One Year" />
              </RadioGroup>
            </FormControl>
          </div>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Property Name</TableCell>
                <TableCell align="center">Date</TableCell>
                <TableCell align="center">Type</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">PRICE</TableCell>
                <TableCell align="center">Quantity</TableCell>
                <TableCell align="center">Fee</TableCell>
                <TableCell align="center">Total</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {this.state.transactionHistoryData.length > 0 ?
                (rowsPerPage > 0
                  ? this.state.transactionHistoryData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : this.state.transactionHistoryData
                ).map((row) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {row.name}
                      <div className="about-propert ">
                        <img src={row['propertyId']['banner']['location']} />
                        <div>
                          <span>{row['userId']['firstName']}</span>
                          <h5>
                            {row['propertyId']['title']}
                          </h5>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell align="center">{moment(row['updatedAt']).format("lll")}</TableCell>
                    <TableCell align="center">{row['action']}</TableCell>
                    <TableCell align="center">{row['status']}</TableCell>
                    <TableCell align="center"><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/49.png" alt="" />{row['unitPrice']}</TableCell>
                    <TableCell align="center">{row['amount']}</TableCell>
                    <TableCell align="center"><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/49.png" alt="" />{parseFloat((row['unitPrice'] * row['amount']) * (row['fee'] / 100)).toFixed(0)}</TableCell>
                    <TableCell align="center"><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/49.png" alt="" />{(row['unitPrice'] * row['amount']) + ((row['unitPrice'] * row['amount']) * 0.02)}</TableCell>
                  </TableRow>
                ))
                :
                <TableRow>
                  <TableCell colSpan={8}>
                    <div className={'col-12 text-center mt-5'}>
                      <img
                        src="https://tknismtest.s3.amazonaws.com/non-compressed-images/47.png"
                        alt=""
                        />
                    </div>
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
          <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              colSpan={3}
              count={this.state.transactionHistoryData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
              ActionsComponent={this.TablePaginationActions}
            />
          </>
          }
        </TableContainer>
      </div>
    );
  }
}


const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

const TablePaginationActions = (props) => {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}


const mapStateToProps = ({ Exchange, Settings }) => {
  let { transactionHistoryData } = Exchange;
  let { showOtpLoader } = Settings;
  return { transactionHistoryData, showOtpLoader };
};
const mapDispatchToProps = { transactionHistory, otpLoader, overlayLoader };
export default connect(mapStateToProps, mapDispatchToProps)(TransactionHistory);

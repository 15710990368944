import EventBus from 'eventing-bus';
import BasicInfo1 from './BasicInfo1';
import BasicInfo2 from './BasicInfo2';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import AccountDetails from './AccountDetails';

import Agreements from './Agreements';
import ReviewFinish from './ReviewFinish';
import { setHash } from '../../store/axios';
import Footer from '../../components/Footer';
import Navbar from '../../components/Navbar';
import DashSignUp from '../../components/SignupSlider';
import DashHeader from '../../components/DashHeader';
import { loginRequest, signupRequest, resetProcess, continueProcess, getUserSignData, logout } from '../../store/actions/Auth';

class SignUp extends Component {
  constructor() {
    super()
    this.state = {
      signData: '',
      pageContent: '',
      isDisabled: false,
      signUpModal: false,
      activeTab: "BasicInfo1",
      activeTabStep: "3",
      signUpMainLoader: false,
      formData: {
        email: '',
        phone: '',
        password: '',
        lastName: '',
        firstName: '',
        publicAddress: '',
        passwordConfirm: '',
      }
    }
    EventBus.on("openSignUpModal", this.signUpModal)
    window.scrollTo(0, 0);
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    if (nextProps.signData !== prevState.signData) {
      let { signData, match, history, location } = nextProps;
      if (signData.step === 2) return { signData, activeTab: "BasicInfo2", activeTabStep: 2 };
      else if (signData.step === 3) return { signData, activeTab: "AccountDetails", activeTabStep: 3 };
      else if (signData.step === 4) return { signData, activeTab: "Agreements", activeTabStep: 4 };
      else if (signData.step === 5) return { signData, activeTab: "ReviewFinish", activeTabStep: 5 }
      // else if (signData.step === 2 && !location.state) history.push({pathname: '/installment-plans/'+signData.hash})
    }
    else return null; // Triggers no change in the state
  }

  signUpModal = (e) => {
    setHash(e);
    this.setState({ signUpModal: !this.state.signUpModal, hash: e })
  }

  setPackage = () => this.props.history.push('/');

  componentDidMount() {
    if (this.props.match.params.hash) {
      this.setState({ signUpMainLoader: true })
      this.props.getUserSignData({ payload: { hash: this.props.match.params.hash }, process: "email" });
    }
    else if (!this.props.location.state || !this.props.location.state.plan || !this.props.location.state.package) this.props.history.push('/starter-packages');
    else if (this.props.location.state && this.props.location.state.package && this.props.location.state.plan) this.setState({ starterDetails: this.props.location.state });
    EventBus.on("signUpMainLoader", () => this.setState({ signUpMainLoader: false }));

  }

  handleSignUp = () => {
    this.setState({ isDisabled: true });
    this.props.signupRequest(this.state.formData)
    setTimeout(() => this.setState({ isDisabled: false }), 2000);
  }

  handleFormChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  }

  handlePhoneChange = (event) => {
    const { formData } = this.state;
    formData['phone'] = event;
    this.setState({ formData });
  }

  handleLoginSignup = (e) => {
    if (typeof window.ethereum === 'undefined')
      alert('Looks like you need to install MetaMask to get started.')
    else {
      window.ethereum.enable().then((response) => {
        const { formData } = this.state;
        formData['publicAddress'] = response[0];
        this.setState({ formData });
      }).catch((reason) => {
        if (reason === 'User rejected provider access') alert('Login Request Rejected')
        else alert('There was an issue signing you in.')
      });
    }
  }

  renderComponent = ({ activeTab }) => {
    if (activeTab === "BasicInfo1")
      return <BasicInfo1 step1={this.handleStep1} starterDetails={this.state.starterDetails ? this.state.starterDetails : ''} />
    else if (activeTab === "BasicInfo2") return <BasicInfo2 onBack={this.handleGoBack} investmentPlan={this.state.investmentPlan ? this.state.investmentPlan.plan : ''} paramsHash={this.props.match.params.hash} />
    else if (activeTab === "AccountDetails") return <AccountDetails onBack={this.handleGoBackTab} />
    else if (activeTab === "Agreements") return <Agreements onBack={this.handleGoBackTab} />
    else if (activeTab === "ReviewFinish") return <ReviewFinish onBack={this.handleGoBackTab} {...this.props} />
  }

  handleGoBack = (e) => this.props.history.push(e)

  handleGoBackTab = (step) => {
    let activeTabStep = this.state.activeTabStep - 1
    if(step) activeTabStep = step;
      
    if (activeTabStep === 3) this.setState({ activeTab: "AccountDetails", activeTabStep: 3 })
    else if (activeTabStep === 4) this.setState({ activeTab: "Agreements", activeTabStep: 4 })
    else if (activeTabStep === 5) this.setState({ activeTab: "ReviewFinish", activeTabStep: 5 })
    else if (activeTabStep === 2) this.setState({ activeTab: "BasicInfo2", activeTabStep: 2 })
  }

  handleStep1 =(e)=>{
    this.setState({email:e.email})
  }

  restartProcess = () => {
    this.props.resetProcess(this.state.hash)
    this.setState({ signUpModal: !this.state.signUpModal })
  }

  sendEmail = () => {
    this.props.continueProcess(this.state.email)
    this.setState({ signUpModal: !this.state.signUpModal })
  }

  render() {
    return (
      <div className="page-wrapper">
        <Navbar stickyNavbar={true} {...this.props}/>
        <DashSignUp />
        <section style={{ backgroundColor: 'white' }}>
          <div className="container">
            <div className="row">
              {
                this.state.signUpMainLoader
                  ?
                  <div className="col-md-7 col-lg-7 sm-10 content-area">
                    <div className="center text-center my-5 py-5">
                      <i className="fa fa-spinner fa-spin fa-5x fa-fw" style={{ color: '#8cc63f' }} />
                      <br /> <br />
                    </div>
                  </div>
                  :
                  <div className="col-md-7 col-lg-7 sm-10" >
                    {this.renderComponent(this.state)}
                  </div>
              }
              <Modal
                modalClassName="modal-mini modal-info"
                toggle={this.signUpModal}
                isOpen={this.state.signUpModal}
              >
                <div className="modal-header justify-content-center">
                  <div className="modal-profile">
                    <img className='modalLogoImg' src="https://tknismtest.s3.amazonaws.com/non-compressed-images/favicon.png.png" alt="" />
                  </div>
                </div>
                <ModalBody>
                  <p>Do you want to continue through Email?</p>
                </ModalBody>

                <div className="modal-footer row">
                  {/*
                    <div className="invest-btn-area col-col pull-left">
                      <a href="#" onClick={this.restartProcess} className="thm-btn btn-style-modal-SignUp">Restart</a>
                    </div>
                  */}

                  <div className="invest-btn-area col-col pull-right">
                    <a href="#" onClick={this.sendEmail} className="thm-btn btn-style-modal-SignUp">Send Email</a>
                  </div>
                  <div className="invest-btn-area col-col pull-right">
                  </div>

                </div>

              </Modal>
              <div className="d-none d-md-block d-lg-block col-md-5 col-lg-5" >
                <div className="signUp-sec">
                  <div className="container center text-center">
                    <img className="signUpSideImage" src="https://tknismtest.s3.amazonaws.com/compressedImages/infographic2-min.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log(state);
  return {
    auth: state.Auth.auth,
    signData: state.Auth.signData ? state.Auth.signData.info : [],
    allSignData: state.Auth.signData
  }
}

const mapDispatchToProps = { loginRequest, signupRequest, logout, resetProcess, continueProcess, getUserSignData };
export default connect(mapStateToProps, mapDispatchToProps)(SignUp);

import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {
  logout
} from "../../store/actions/Auth";
import { connect } from "react-redux";

class TknError extends Component {

  componentDidMount() {
    this.props.logout()
  }

  componentWillUnmount() {
    window.location.reload();
  }

    render() {
        return (
        <>
            <Link className="error-logo" to="/">
                <img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/logo.png" alt="logo" />
            </Link>
            <div className='tkn-error'>
                <h2>404 Not Found</h2>
                <p>Oh no! Page not found.</p>
                <p>Please check your Network or Refresh the page</p>
            </div>
        </>
        );
    }
}

const mapDispatchToProps = {
  logout
};

export default connect(null, mapDispatchToProps)(TknError);

export const faqData = [
    {
        "aboutTokenism" : [
            {
                "question" : "What is Tokenism?", 
                "answer" : "Tokenism is an online real estate investment platform that uses blockchain technology to create a secure and intuitive marketplace. We enable investors and asset owners to take advantage of real estate investment opportunities with maximized efficiency. Our goal is to improve the investing experience for everyone by minimizing fees, maximizing security, and increasing liquidity."
            },
            {
                "question" : "What is Tokenism offering?", 
                "answer" : "<b>We let you invest in any property of your choosing with as little as $1000</b> by using a unique integrated digital model that uses proprietary blockchain technology to remove the entrenched barriers within the real estate market and increase the security and speed of transactions."
            },
            {
                "question" : "How does the platform work?", 
                "answer" : "We built our platform to serve individuals looking to invest in premium real estate, financial advisors or wealth managers that have clients interested in investing, as well as asset owners looking to list their assets or raise equity for a new development. You can learn more about how Tokenism would work for you in our <a href='/how-it-works' target='_blank'>How It Works</a> section "
            },
            {
                "question" : "What makes Tokenism secure?", 
                "answer" : "Our process emphasizes security and privacy using blockchain technology, an immutable ledger of transactions and ownership records. The use of security tokens, and multi-signature wallets work together to complete the unchangeable record of ownership recorded on the blockchain and eliminates fraud."
            },
            {
                "question" : "What makes Tokenism different?", 
                "answer" : "Historically, the commercial real estate industry has had a high barrier to entry, preventing investors from entering the market unless they have a large amount of capital to deploy. In addition, low liquidity  causes capital to be tied up for years and transferring ownership can be costly or burdensome.<br/><br/>Our minimum investment threshold is incredibly affordable at $1000 USD. Using Security tokens enables liquidity in a previously illiquid investment class, and allows for trading in our secondary marketplace, Tokenism investors won’t experience real lock up periods and can benefit from single direct ownership in a diverse portfolio of assets."
            },
            {
                "question" : "What am I investing in?", 
                "answer" : "When you invest on the Tokenism platform, you are investing into specific assets of your choosing. We research, identify, acquire, perform due diligence, and manage these investments on your behalf, while allowing you to diversify your portfolio per your needs or preferences across geographies and investment niches."
            },
            {
                "question" : "What happens if Tokenism goes out of business?", 
                "answer" : "An immutable record on Blockchain exists and represents ownership interest in each asset – protecting investors in the unlikely event Tokenism ever ceases operations. Legally, the Token Holders own each individual asset through ownership in an independent legal entity. Our unique structure ensures & safeguards the owners’ rights and investments from any adverse actions against Tokenism or any single asset."
            },
            {
                "question" : "What’s the legal standing of Token Holders in relation to asset ownership?", 
                "answer" : "Each asset is owned by a separate legal entity (LLC, corp, etc). Similar to a shareholder in a company, tokens represent shares in that entity."
            },
            {
                "question" : "Do you have a mobile app?", 
                "answer" : "Our platform can easily be accessed on all your mobile devices but Tokenism does not currently have a mobile-app. We are working to develop an app soon so you can more effectively manage your investments on the go."
            },
            {
                "question" : "How do I contact Tokenism?", 
                "answer" : "For help investing or general inquiries, feel free to reach out to our support team at any time by connecting with the concierge at the bottom left corner of each page, filling out our <a href='/contact-us' target='_blank' >contact form</a> or emailing <a href='mailto:Investorrelations@tknism.io'>Investorrelations@tknism.io</a>. </br></br> Our Investor Relations team is available Monday through Friday from 9:00 AM to 9:00 PM ST, excluding holidays. We’ll do our best to get back to you as soon as possible or within 24 hours.</br></br>For media inquiries, contact<a href='mailto:media@tknism.io'>media@tknism.io</a>. </br></br>If you have any ideas or feedback you can also find us on <a href='https://www.linkedin.com/company/tokenism/' target='_blank'>LinkedIn</a> and <a href='https://twitter.com/Tokenismllc' target='_blank'>Twitter</a>, We'd love to hear from you!</br></br>Our mailing address is 3825 Edwards Rd, #103, Cincinnati, OH 45209"
            },
            {
                "question" : "Does each property have its own unique token?", 
                "answer" : "Yes, each property will have its own unique ERC-1400 tokens. Tokens for each property also have a distinct title and are color coded for easy visibility on your dashboard. Once you invest, you will receive unique tokens legally representing your share of the property you've just invested in. For more information on ERC-1400 tokens, please visit this <a href='https://github.com/ConsenSys/ERC1400' target='_blank'>link</a>."
            },
            {
                "question" : "What is the structure for each asset?", 
                "answer" : "TBA"
            },
            {
                "question" : "How long does a crowd sale last?", 
                "answer" : "We call this phase an Initial Offering period. That period typically lasts 90 days or more as defined by the asset owner or until the minimum goal of tokens sold is met. This, however, can vary. Oftentimes, a higher priced property or project may last longer, so there is a better chance to be successfully funded."
            },
            {
                "question" : "What happens if a crowd sale goal is not met?", 
                "answer" : "If the minimum goal of tokens sold is not met, we release the funds back to the investors and the property will remain with the current owner."
            },
            {
                "question" : "What are ERC 1400 Tokens?", 
                "answer" : "The Tokenism platform uses ERC- 1400 standard for its Security token on the Ethereum network. For more information on ERC-1400 tokens, <a href='https://github.com/ConsenSys/ERC1400' target='_blank'>please visit this link</a>."
            },
            {
                "question" : "Where can I list the real estate tokens?", 
                "answer" : "Start by navigating to your portfolio located on your dashboard and clicking the “List Tokens” tab. Select a property from the drop down menu, the price you’d like to sell each token for, and the number of tokens you want to list, then click “Sell”. If you’re still unsure about listing tokens you can watch a quick video <a href='/how-it-works' target='_blank'>here</a>."
            },
            {
                "question" : "Can tokens be listed at any exchanges?", 
                "answer" : "No. In order for us to remain compliant and in the best interest of the token holders the tokens are only tradable and transferable on the Tokenism platform."
            },
            {
                "question" : "What is a Stable Coin?", 
                "answer" : "A stable coin is a type of cryptocurrency that offers price stability and is backed by an asset. Stable coins offer the instant processing and security of cryptocurrencies, and the volatility-free stable valuations of fiat currencies."
            },
            {
                "question" : "Crypto currencies are known to be volatile. How does that affect the stable coin?", 
                "answer" : "Our technology is not related to Bitcoin or other cryptocurrency markets. The underlying blockchain technology is shared with cryptocurrency, but the digital assets created on Tokenism are independent of the prices and network behind various cryptocurrencies."
            },
            {
                "question" : "Why is the stable coin needed?", 
                "answer" : "We use a stable coin that is native to our platform to ensure the speed and security of a transaction without the negative characteristics of cryptocurrencies. Our stable coin (TKUSD) can only be bought and redeemed on our platform and is not tradeable otherwise."
            },
            {
                "question" : "Can I send the coin to any one directly?", 
                "answer" : "Our native stable coin can only be used within our platform for transactions. Stable coins cannot be traded from one investor to another and can only be used for the selling and purchasing of security tokens, receiving your quarterly dividends or for redeeming them on the platform by transferring back into your bank account."
            },
            {
                "question" : "How long does it take to convert the digital currency into USD?", 
                "answer" : "While all regular transactions are transferred into your linked bank account on the next business day we offer an option for a same day transfer available for a small fee."
            },
            {
                "question" : "How is a stable coin stable?", 
                "answer" : "A stable coin offers price stability. It remains stable because it is backed by a reserve asset and its value is tied to an external reference that should remain relatively stable over long time periods and is free from undue volatility.  We are keeping our stable coin <b>100% backed by USD</b> held in a specific account which will be regularly audited with each statement made public."
            },
            {
                "question" : "What backs Tokenism’s stable coin?", 
                "answer" : "Our native stable-coin is backed 1-to-1 by US dollars!"
            },
            {
                "question" : "Who has access to the backing money?", 
                "answer" : "The money is held in a specific secure bank account where only automated transactions (deposits & withdrawals) will occur therefore nearly eliminating all human interaction and control over the funds."
            },
            {
                "question" : "What happens to our money if Tokenism goes bankrupt?", 
                "answer" : "The account and entity that holds the stable coin is independent of Tokenism to ensure and safeguard your interest incase of any eventuality for our platform."
            },
            {
                "question" : "Who manages the properties?", 
                "answer" : "All assets will be managed professionally by providers verified and acceptable to Tokenism."
            },
            {
                "question" : "Any plans to tokenize property assets in other regions/countries? ", 
                "answer" : "Tokenism hopes to expand and include assets from other regions of the world in the future. Currently, we are focused on serving the US market only."
            },
            {
                "question" : "What happens if the asset is destroyed?", 
                "answer" : "Tokenism Management will ensure that each asset has appropriate insurance coverage at replacement cost and reserves which can provide adequate coverage in case of any such incidents."
            },
            {
                "question" : "Who is insured?", 
                "answer" : "The insurance policy for an asset provides coverage for the legal entity holding the asset."
            },
            {
                "question" : "Who is liable?", 
                "answer" : "The structure of the holding is such that not only do you have a hands-off investment but your liability is also minimized."
            },
            {
                "question" : "What happens if one asset goes bankrupt? How are all the other assets on the platform affected?", 
                "answer" : "Assets are held in separate legal entities to ensure and safeguard against such situations and will not affect one another."
            },
        ]
    },
    {
        "investingBasics" : [
            {
                "question" : "What is tokenization?", 
                "answer" : "Tokenization describes the process of transforming traditional equity holdings into digital shares that represent a real, tradable asset—in this case, the ownership of a piece of real estate. We refer to these digital shares as “tokens”. Tokens allow investors to buy, sell, and transfer private assets online. At Tokenism we also refer to tokens as security tokens, and digital securities."
            },
            {
                "question" : "Why tokenize real estate?", 
                "answer" : "Digitization of real estate addresses several challenges present in traditional real estate investment management. By wrapping a fraction of a traditional asset inside a tradable piece of code (a token), digitized securities lower the monetary barrier to entry for investors, reduce administrative costs for owners, and compress the closing and settlement period of assets to mere seconds instead of days. Tokenization makes the world’s largest investment class more accessible to investors worldwide."
            },
            {
                "question" : "How do you tokenize real estate?", 
                "answer" : "Real estate owners and developers offer smaller investment denominations by fractionalizing (hyperlink to glossary/wordbank)a property through a blockchain-based system and creating shares. This simultaneously expands distribution to a broader and more diverse investor group and lowers the monetary threshold investors must reach to participate. Check out our blog Tokenized Real Estate 101 to learn more about this process."
            },
            {
                "question" : "Are these deals done in cash or bitcoin?", 
                "answer" : "Deals are not done in bitcoin. All transactions in the marketplace are made using smart contracts utilizing our native stablecoin. Our stablecoin is backed 1-to-1 by US dollars, for example $1000 USD equals 1000 Tokenism stablecoins (TKUSD). With the minimum Investment being brought down to $1000, each security tokenbacked by a specific property can be purchased for 1000 TKUSD.</br></br> Our technology is not related to Bitcoin or other crypto currencies. The underlying blockchain technology is shared with cryptocurrency, but the digital assets created on Tokenism are independent of the prices and volatility inherent in bitcoin and other cryptocurrencies."
            },
            {
                "question" : "How do real estate owners, portfolio owners, and fund managers benefit from tokenization?", 
                "answer" : "The primary benefits for issuers of Security Tokens are:<ul><li>Superior ROI</li><li>Increased liquidity without affecting project capitalization </li><li>Significant Cost Reduction</li><li>A reduced time horizon, allowing for quicker settlement due to more buyers and smaller shares</li><li>Reduced dependency on intermediaries, leading to reduced administrative costs and trading taxes</li><li>Improved investor & data management with digital reporting</li><li>Improved resolution of procedural in efficiencies</li><li>Flexible entry & exit</li></ul></br></br>For more information about benefits check out our <a href='/investing101' target='_blank' >blog</a>"
            },
            {
                "question" : "How do real estate investors benefit from tokenization?", 
                "answer" : "Primary benefits for investors in Security Tokens  are:<ul><li>A more liquid investment with a lower minimum investment and increased flexibility</li><li>Increased security using block-chain technology </li><li>Automated processing to allow for real-time management and a streamlined process to view holdings and analyze asset performance unlike traditional paper-based systems</li><li>Increased transferability and liquidity on private investments</li><li>Increased investment opportunities due to ability to source investments online</li></ul>"
            },
            {
                "question" : "How does tokenization provide liquidity for real estate assets?", 
                "answer" : "Transforming traditional real estate equity into digital assets or “tokens” creates a transferable digital security and speeds the entire investment process. This allows investors to buy and sell on our marketplace with a wider pool of potential investors, and at a much faster rate. By storing ownership data of each security token using the Blockchain, information and ownership can also be transferred quickly along with the funds associated."
            },
            {
                "question" : "Is tokenizing real estate legal?", 
                "answer" : "Yes! We simply digitize the ownership interest within the entity holding the real-world assets using digital security tokens, similar to investing in stocks. Security tokens represent these rights and allow the owner to transfer those rights to someone else in a legal way."
            },
            {
                "question" : "Who can buy the tokenized real estate?", 
                "answer" : "Due to regulatory compliance, our platform is currently only open to accredited Investors (link to wordbank or glossary). However, Tokenism is working diligently to open up the marketplace to all investors. Tokenism has integrated KYC and AML compliance services & Accreditation Verification into our <a href='/starter-packages' target='_blank'>sign-up</a> process to automate the investor background check."
            },
            {
                "question" : "How do traditional REITs differ from a tokenized asset? ", 
                "answer" : "Our tokenized model offers advantages over Real Estate Investment Trusts (REITs)(hyperlink to glossary/wordbank) as follows:<ul><li>Ability to have real direct ownership in single assets</li><li>Ability to diversify your portfolio per your desire</li><li>take as much risk as you can handle, take a conservative approach, and/or choose to focus on certain asset classes or geographies</li><li>Finally, REITs provide significantly lower returns due to involvement of multiple intermediaries who charge high fees</li><li>Digital processes improve cost efficiency and accessibility</li></ul>"
            },
            {
                "question" : "Can you refinance an asset through tokenization?", 
                "answer" : "Tokenization can at times be used as an effective alternative to refinancing. In the event a refinance is needed, a tokenization offering could be presented to eliminate the existing debt obligation via capital provided by new investors. Note: Tokenization provides equity and not debt. This means that your effective ownership in the asset reduces while eliminating the debt obligation."
            },
            {
                "question" : "Do I need prior knowledge of “blockchain” to interact with tokenized assets?", 
                "answer" : "Not at all. Our platform is made with you in mind and user experience is optimized for everyone. While we have built our platform using blockchain technology, it is like using any other website and you don't need any knowledge of blockchain to start investing with Tokenism."
            },
            {
                "question" : "When dealing with digital real estate, who verifies the capital structure of a company so that investors can trust the integrity of the deal?", 
                "answer" : "Blockchain provides an immutable ledger of ownership and the entire capitalization table and ownership record is maintained on the blockchain. Tokenism has leveraged this technology to ensure the integrity of each deal. Simultaneously, Tokenism Management acts as an agent on behalf of all token holders to ensure everyone’s interests are looked after."
            },
            {
                "question" : "Do all real estate assets being digitized need to have an SEC audit?", 
                "answer" : "TBA"
            },
            {
                "question" : "Are there any restrictions on when holders can sell ownership once an asset is tokenized?", 
                "answer" : "Tokenism offers liquidity as quickly as possible, keeping in line with all relevant laws and regulations. While the tokens are not tradable during the initial offering (90 to 180 days), they become tradable as soon as the initial offering period finishes."
            },
            {
                "question" : "Does Tokenism offer tokenization for Single family residences?", 
                "answer" : "Currently, our goal is to democratize investing for the commercial real estate industry. Tokenism lists seven types of properties:<ul><li>Office<li></li>Multifamily</li><li>Mixed use</li><li>Hospitality</li><li>Retail</li><li>Warehouse</li><li>Industrial</li></ul>"
            },
            {
                "question" : "Is there a minimum investment?", 
                "answer" : "The minimum investment that can be made on the platform is $1,000 USD."
            },
            {
                "question" : "What is my minimum deposit?", 
                "answer" : "There is no limit on the amount you can deposit into your account. However, the minimum amount you must have in your account to make an investment is $1,000 USD."
            },
            {
                "question" : "How do I fund my investment?", 
                "answer" : "The primary way to fund your investment is via an ACH transaction from a linked bank account. You can link your bank account by 1) manually entering your routing and account numbers or 2) using your online banking credentials. Tokenism cannot see your bank login or account information."
            },
            {
                "question" : "How does single direct investment work? ", 
                "answer" : "A single real estate asset is defined as a single property excluding residential properties with fewer than 4 units. Our platform allows you to invest directly into single assets with no intermediaries or middlemen."
            },
            {
                "question" : "How do I diversify my investments?", 
                "answer" : "You can diversify your investment portfolio by selecting a varied group of property types to invest in. Tokenisms lists assets including office buildings, multifamily, mixed use, hospitality, retail, warehouse, and industrial assets."
            },
            {
                "question" : "How long should I expect to hold my investment?", 
                "answer" : "The choice is yours. Expect to hold investments at least until the initial offering period closes. Digital security tokens allow you to trade or sell your ownership whenever you choose."
            },
            {
                "question" : "What's the difference between an ICO & STO?", 
                "answer" : "Both initial coin offerings (ICOs) and security token offerings (STOs) follow a similar structure where investors get a crypto coin or token that represents their investment. STOs, however, are registered with required government bodies and meet all legal requirements. This is a contrast from ICOs that do not launch with governance in mind and raise funds in an unregulated environment with a lot of risks.</br></br>Some additional key differences are listed below:<ul><li>Unlike an ICO coin, an STO security token is always backed by a real asset</li><li>100% regulated offerings in STOs ensure investor security</li><li>ICOs can experience high volatility and manipulation of the crypto market</li><li>ICOs often have low liquidity</li><li>STOs can be limited to accredited investors only until further approval, whereas some ICOs allow for anonymous participation.</li></ul>"
            },
            {
                "question" : "What type of user accounts can you set up?", 
                "answer" : "Currently only individual accounts are supported but we will be offering business/institutional, joint/survivor, entity, 401 (k) and IRA accounts very soon."
            },
            {
                "question" : "Can my Financial Advisor (FA) or Wealth Manager (WM) set up an account on my behalf?", 
                "answer" : "Yes! Your Financial advisor (FA) or Wealth Manager (WM) can set up an account on your behalf and even trade on your behalf as long as they have all the required information and your consent. This can be done by contacting our Investor Relations concierge (link to support team email or landing page) for more assistance."
            },
            {
                "question" : "How do I give my broker, Financial Advisor (FA) or Wealth Manager (WM) access to trade on my behalf?", 
                "answer" : "You can give access to your FA or WM through your account <a href='/dashboard' target='_blank'>settings</a> or by contacting our <a href='mailto:advisorrelations@tknism.io'>Investor Relations team</a> for more assistance."
            },
            {
                "question" : "I am a Financial Advisor/Wealth Manager, can I set up an account on behalf of my clients?", 
                "answer" : "Yes! To setup an account for managing multiple clients contact our Advisor Relations team at <a href='mailto:advisorrelations@tknism.io'>advisorrelations@tknism.io</a>"
            },
            {
                "question" : "Who is eligible to invest?", 
                "answer" : "Currently only accredited investors will be able to invest. We will be availableto retail investors at a later date. Tokenism is integrated with accreditation verification (link to early iq)KYC and AML (hyperlink to KYC and AML FAQs) compliance services to automate the investor background check."
            },
            {
                "question" : "What is KYC & AML?", 
                "answer" : "Regulators require us to know our customers. Our Know Your Customer (KYC) & Anti-Money Laundering (AML) policy is a combination of legal requirements and our team’s chosen measures to prevent illegal activity. Before using our platform, you first need to be verified and approved. The entire process is completed online via the Tokenism website. You will be prompted to provide or verify any required information (KYC/AML), as well as make the necessary acknowledgments electronically."
            },
            {
                "question" : "Do I need to be accredited to invest?", 
                "answer" : "Currently, yes. Tokenism is only open to verified accredited investors until we open for retail investor participation in the near future."
            },
            {
                "question" : "Who can invest with Tokenism?", 
                "answer" : "Any US or International citizen can invest with us as long as our third-party accreditation verification system approves and confirms their accreditation status."
            },
            {
                "question" : "Can international investors use Tokenism or only US citizens?", 
                "answer" : "Our digital model allows Tokenism to be open to investors all over the world."
            },
            {
                "question" : "Why do I need to give my Social Security number?", 
                "answer" : "This is relevant for US investors only. For tax reporting purposes, we are legally required to report your investments to the IRS or appropriate regulators. We also use this information when sending out Tax forms at the end of each period. This is requested from all US investors and is an identity verification measure as well."
            },
            {
                "question" : "How is this security token categorized?", 
                "answer" : "The Tokenism platform uses ERC- 1400 standard for its security token on the Ethereum network. For more information on ERC-1400 tokens, please visit this link.  All of our security tokens are registered securities with the SEC.  Each offering is made under the most appropriate regulation and appropriate exemptions."
            },
            {
                "question" : "What authorities regulate Tokenism and its offerings?", 
                "answer" : "Tokenism is regulated by the Financial Industry Regulatory Authority (FINRA) and the U.S. Securities and Exchange Commission (SEC). For more information on these regulators please visit the respective hyperlinks."
            },
            {
                "question" : "How can Tokenism sell US securities? ", 
                "answer" : "We are offering private placements currently and are in the process of getting the required licensing from the SEC & FINRA."
            },
            {
                "question" : "What regulations does Tokenism fall under?", 
                "answer" : "Under the SEC we fall under Regulation D and Rule 506 C."
            },
            {
                "question" : "What is SEC Regulation D?", 
                "answer" : "Regulation D of the Securities Act provides several exemptions from the registration requirements, allowing some companies to offer and sell their securities without having to register the offering with the SEC. For more information on Regulation D please visit this <a href='https://www.investopedia.com/terms/r/regulationd.asp' target='_blank' >link</a>."
            },
            {
                "question" : "What is Rule 506 C?", 
                "answer" : "Rule 506 of Regulation D provides two distinct exemptions from registration for companieswhen they offer and sell securities. Companies relying on the Rule 506 exemptions can raise an unlimited amount of money. For more information on Rule 506 C please visit this link."
            },
            {
                "question" : "What sources can I use to deposit money into my account?", 
                "answer" : "Investors can connect a debit card, bank account, and/or Paypal to deposit money into their digital wallets."
            },
            {
                "question" : "Why do I need to connect a bank account?", 
                "answer" : "Connecting a bank account allows you to transfer funds into or out of your online wallet/ account. The primary method to transfer funds is via ACH transactions from your connected bank account. In order to fund your account or to withdraw money from your account we need to have a linked bank account on file."
            },
            {
                "question" : "How do I change a bank account?", 
                "answer" : "If you have multiple accounts saved in your digital wallet you will be able to select which account you wish to withdraw from or deposit into before funding your online wallet."
            },
            {
                "question" : "How do I add or remove an account?", 
                "answer" : "You can add a <a href='/how-it-works' target='_blank'>bank account</a> by navigating to the “Wallet” section of your dashboard, clicking the “Deposit” tab and selecting the “Continue payment”button. Add your bank account by entering the required information and clicking “Submit”.</br></br>To delete an account simply navigate to your bank information, select the account you would like to remove and click the trash icon to delete."
            },
            {
                "question" : "Can I use a savings account to invest?", 
                "answer" : "Yes! All investors can use both Checking and Savings Accounts to withdraw and deposit funds."
            },
            {
                "question" : "Can I auto deposit for savings?", 
                "answer" : "Currently the Tokenism platform does not have the automatic deposit function."
            },
            {
                "question" : "How do I earn returns on investments?", 
                "answer" : "Our unique digital model enables you to invest in fractions of premium commercial properties with a possible higher potential income and ROI than other investment assets. Stable income assets will have quarterly distributions while redevelopment and ground up developments will have distributions as specified in the offering. The platform provides opportunities to invest in a diverse portfolio of stable income and redevelopment properties, as well as ground-up developments."
            },
            {
                "question" : "When should I expect these returns?", 
                "answer" : "You can expect returns in the form of quarterly distributions or as specified in each offering.  Please note that the first distribution for any asset will not happen until at least two quarters have passed after the minimum goal is met. This is done to ensure that a sufficient reserve is built for the property to eliminate the need for future capital calls."
            },
            {
                "question" : "How often are returns distributed?", 
                "answer" : "This depends on the kind of asset you are investing in. For stable income assets, investors will get quarterly distributions. However, the first distribution is after tokenization of an asset is held back for building a cash reserve for each asset."
            },
            {
                "question" : "Where do I verify the accuracy of returns? ", 
                "answer" : "Tokenism is developing an AI backed system for reporting and projections. With an integrated system across the board, you will be able to review monthly & quarterly financials, multiple performance reports and asset details on the token owner details page once the initial offering closes."
            },
            {
                "question" : "How do I access asset status and current situation data for a property?", 
                "answer" : "You can view a summary of how an asset is performing and review all due diligence documents by navigating to the respective listing page. For quicker access from your Dashboard add the asset to your Favorites by clicking the heart in the top right corner when viewing <a href='/listing' target='_blank'>Listings</a>. To see monthly/quarterly performance and progress reports and financials for a project, you can access the property details page once you purchase the security token for that listing."
            },
            {
                "question" : "What fees does tokenism charge?", 
                "answer" : "Tokenism is offering $0 in fees during the initial launch phase. Select fees will be charged ata later stage."
            },
            {
                "question" : "Are there any additional fees for investing through my IRA or 401k etc?", 
                "answer" : "Tokenism will not charge you any additional fees for investing through IRA and 401k."
            },
            {
                "question" : "Do I have to pay advisory fees?", 
                "answer" : "No. Tokenism does not charge advisory fees."
            },
            {
                "question" : "Is there a fee for a premium account?", 
                "answer" : "Premium accounts will have access to premium features such as reduced or zero fees for trading and multiple account management. Sign up <a href='/contact-us' target='_blank'>here</a> to stay in the know or join our premium member waitlist"
            },
            {
                "question" : "Are there any rewards for every investment I make?", 
                "answer" : "Every investment is a building block in your investment portfolio towards a more secure future. Having said that, keep an eye out for our soon to launch rewards program!"
            },
            {
                "question" : "Are there any rewards for selling my tokens?", 
                "answer" : "Watch this space for our soon to launch rewards program! "
            },
            {
                "question" : "Are there rewards for trading frequently?", 
                "answer" : "Watch this space for our soon to launch rewards program!"
            },
            {
                "question" : "What are the benefits of each category of account?", 
                "answer" : "This information will be made available as the account types are announced."
            },
        ]
    },
    {
        "accountManagement" : [
            {
                "question" : "What is the sign-up process like?", 
                "answer" : "You can begin the sign-up process by clicking the “Get Started” button and creating your account. Select a Starter, Core, or Advanced account as well as an investment plan based on your predicted initial investment. Then enter your email address and some basic contact information to complete sign up. Watch a quick video explaining <a href='/how-it-works' target='_blank' >Sign Up and Creating an Account here</a>."
            },
            {
                "question" : "Can I change the name on my account?", 
                "answer" : "To change the name on your account please contact the investor relations team at <a href='mailto:investorrelations@tknism.io'>investorrelations@tknism.io</a>. Please be aware that identity verification measures may need to be repeated."
            },
            {
                "question" : "Can I transfer my investment from one property to another?", 
                "answer" : "Once you invest and purchase tokens from a listed property you cannot transfer that investment. You can, however, trade or sell your tokens in our secondary marketplace."
            },
            {
                "question" : "How do I add a beneficiary?", 
                "answer" : "If you would like to add a beneficiary to your account please contact our investor relations team at <a href='mailto:investorrelations@tknism.io'>investorrelations@tknism.io</a>"
            },
            {
                "question" : "How do I change my email address?", 
                "answer" : "You can change your email address by navigating to your account settings located on your dashboard and editing your profile information as necessary. Be on the lookout for a verification email and follow the instructions to properly change your email address."
            },
            {
                "question" : "How do I update my address?", 
                "answer" : "To update your address, navigate to your account settings located on your dashboard and edit your profile information accordingly."
            },
            {
                "question" : "Where can I find my account identifier?", 
                "answer" : "You can find your account identifier in your account settings located on your dashboard."
            },
            {
                "question" : "Where are my account statements?", 
                "answer" : "Account statements are located in the “Documents” section of your dashboard under “Statements”."
            },
            {
                "question" : "Can I see all my investments at a glance?", 
                "answer" : "Yes. You can view all of your current investments at a glance by viewing your “Balance Detail” on your dashboard. For a more detailed record of your investments click the “InvestHistory” tab located in your portfolio."
            },
            {
                "question" : "What are the different account levels?", 
                "answer" : "Tokenism offers a basic account and premium account level."
            },
            {
                "question" : "Are there different qualifications associated with each account level?", 
                "answer" : "TBA"
            },
            {
                "question" : "Are there costs to have different types of account levels?", 
                "answer" : "There will be an additional cost for Premium accounts, which will have access to premium features such as reduced or zero fees for trading and multiple account management. Signup <a href='/contact-us' target='_blank'>here</a> to stay in the know or join our premium member waitlist"
            },
            {
                "question" : "What are the benefits of each account level?", 
                "answer" : "<ul><li>Benefits of basic account</li><li>Benefits of premium account</li></ul>"
            },
            {
                "question" : "What is a wallet?", 
                "answer" : "Your tokenism digital wallet holds information regarding all of your transactions on our platform. You can view your token balance, deposit funds, withdraw your dividends and view your history "
            },
            {
                "question" : "Who has access to my wallet?", 
                "answer" : "Only you will be able to access your wallet and account. Tokenism will only exercise access to your account for requested support issues."
            },
            {
                "question" : "What if I lose my private key?", 
                "answer" : "While cryptocurrencies are notorious for being lost if you lose the private key, Tokenism has the ability to ensure the safety of your investment and burn and reissue those tokens to a new wallet. However, this would require extensive identity verification and/or legal documentation so please keep your private key safe! If you have lost your private key or forgotten your password please <a href='/contact-us' target='_blank'>contact Tokenism</a>."
            },
            {
                "question" : "What if my wallet gets hacked? ", 
                "answer" : "Tokens on the platform can only be owned by verified users of the platform and in the caseof a hack we have the immutable ledger of blockchain to revert back any mishaps."
            },
            {
                "question" : "Can I transfer money in my wallet to anyone else?", 
                "answer" : "No. The stable coin is designed in a way that it can only be used for the purpose of transactions as defined on the platform."
            },
            {
                "question" : "Can I set up inheritance measures?", 
                "answer" : "For inheritance we will need to have the appropriate paperwork and legal proceedings to make sure the rightful heirs have access to your investment. If you’d like to set up inheritance measures please contact our investor relations team at <a href='mailto:investorrelations@tknism.io'>investorrelations@tknism.io</a> for support."
            },
            {
                "question" : "How do I withdraw my money?", 
                "answer" : "Simply navigate to your Tokenism wallet and select the “Withdraw” tab. Type the amount you wish to withdraw and click “Submit”. It’s that easy!"
            },
            {
                "question" : "Can I reinvest my dividends?", 
                "answer" : "Absolutely! In fact we encourage you to continue building your long term wealth by reinvesting."
            },
            {
                "question" : "Are there any fees associated with reinvestment?", 
                "answer" : "There are no new or hidden fees associated with reinvesting. Your dividends will be added to your account balance along with any funds you’ve deposited."
            },
            {
                "question" : "Can I choose what assets I want to reinvest my dividends in?", 
                "answer" : "Of course! The choice is yours. Any asset listed on our platform is fair game for your reinvestment."
            },
            {
                "question" : "Can I transfer my tokens to another Tokenism user without receiving any fees?", 
                "answer" : "Transfers of tokens on the platform can only occur through buying and listing tokens. Any transfers made on the Tokenism platform will incur a 2% fee after the initial no fee period."
            },
            {
                "question" : "Are there any fees associated with token transfering?", 
                "answer" : "Any transfers made on the Tokenism platform will incur a 2% fee after the initial no fee period."
            },
            {
                "question" : "How long does the transfer take?", 
                "answer" : "Our digital model allows transfers to take mere minutes. Oftentimes your transfer will be recorded on the Blockchain and completed in less than 60 seconds."
            },
            {
                "question" : "Can I sell tokens I own?", 
                "answer" : "Yes! You can sell tokens you own by listing your tokens on our secondary marketplace through your dashboard. Click this <a href='/how-it-works' target='_blank'>link</a> to see how!"
            },
            {
                "question" : "Can I buy tokens owned by someone else?", 
                "answer" : "Yes. Just navigate to the asset you are interested in and see if any tokens are currently for sale. If someone has listed their tokens, you can purchase the tokens at the asking price or counter with an offer of your choosing."
            },
            {
                "question" : "Is the list price on a token negotiable?", 
                "answer" : "The price listed by Tokenism is the initial offering price and is not negotiable. However, if someone has listed tokens they already own, you can counter their offer or purchase the tokens from them at their asking price."
            },
            {
                "question" : "How long do I have to negotiate on a token available?", 
                "answer" : "Each party can counter offer a maximum of 2 times per trade. Offers expire after 48 hours with no activity."
            },
            {
                "question" : "How quickly are tokens transferred after an accepted offer?", 
                "answer" : "Tokens are transferred immediately. We use smart contracts to make accepting offers easyand secure. Once an offer is accepted it takes less than 60 seconds for tokens to transfer ownership."
            },
            {
                "question" : "How quickly will I get my money after selling a token?", 
                "answer" : "The money from the sale will transfer to your Tokenism wallet immediately with no restrictions or delays. Unlike with traditional securities or bank accounts you don’t have to wait until the next day or 48 hours for the transaction to settle and you can access your money right away."
            },
            {
                "question" : "Where can I see tokens trading currently in real time?", 
                "answer" : "After you’ve invested in an asset you can view current tokens being traded in the “Invest Now” section of that property. Once there you can counter any offers you find attractive."
            },
            {
                "question" : "What's the difference between traditional redemption and Tokenism?", 
                "answer" : "There is no concept of redemptions with Tokenism. Our secondary marketplace allows youto simply list and sell. You don't have to pay hefty fees or deal with the hassle of project sponsors/fund managers, lengthy paperwork, etc."
            },
            {
                "question" : "Can I sell my tokens at any time?", 
                "answer" : "Yes. Once you have purchased tokens from an asset you can sell then at any time even after the initial offering period is closed."
            },
            {
                "question" : "How can I sell my tokens?", 
                "answer" : "List your tokens to sell by clicking the “List Tokens” tab located within your portfolio. Selectthe asset you wish to list from and type the quantity of tokens you want to list as well as the price you’d be willing to sell them for. Once you’re satisfied, click “Sell”. Watch this quick video to see how to list tokens."
            },
            {
                "question" : "How do I withdraw my dividends or sale proceeds?", 
                "answer" : "Your dividends will be added to your account balance along with any funds you’ve deposited are garnered through sales. Simply navigate to your Tokenism wallet and select the “Withdraw” tab. Type the amount you wish to withdraw and click “Submit”. It’s that easy!"
            },
            {
                "question" : "How long does it take to process a sale?", 
                "answer" : "Once an offer is accepted it takes less than 60 seconds for a sale to process, allowing you to receive the proceeds."
            },
            {
                "question" : "How long does it take for money to reach my bank account from my wallet?", 
                "answer" : "Money will be transferred out of your digital wallet immediately. Depending on your bank, however, funds will be available in your bank account for use anywhere from 24-48 hours later."
            },
            {
                "question" : "How do I make an investment?", 
                "answer" : "Buying a property-backed token is as easy as a few clicks. Browse through our intuitive listings page and view investment opportunities across various categories. You can filter by type of asset, location, or token price. Once you have decided on an asset to invest in simply click “Invest Now” and enter the number of tokens you would like to buy for the property."
            },
            {
                "question" : "How long does it take for my investment to close/order to complete?", 
                "answer" : "Once an offer is accepted, or an investment is placed, it takes less than 60 seconds for a sale to process."
            },
            {
                "question" : "Can I cancel my order/investment?", 
                "answer" : "You can cancel any pending orders from the transactions section of your dashboard. Once your order is complete, you will not be able to cancel it."
            },
            {
                "question" : "Can I add more to my investment later?", 
                "answer" : "Yes. You can invest more at any point from your dashboard as long as tokens in the asset are available. However, no additional investment is required."
            },
            {
                "question" : "Is there a maximum amount I can invest per asset?", 
                "answer" : "Currently there is no maximum investment limitation."
            },
        ]
    },
    {
        "assetOwners" : [
            {
                "question" : "I am a property owner. How can I list my property for sale?", 
                "answer" : "Simply submit some basic information about yourself & your property by clicking the “Submit your Property” button found on the Tokenism Homepage. Our online listing questionnaire takes about 5 minutes to fill out. Someone from the Tokenism team will contact you within 48 hours of your submission. If you still need help figuring out to submit, check out this <a href='/how-it-works' target='_blank'>short video</a> or contact our support team."
            },
            {
                "question" : "How is my property evaluated?", 
                "answer" : "We undergo a thorough selection and due-diligence process before a property gets listed on our platform. All submitted listings are carefully vetted by our team of experts. After the asset owner submits a request Tokenism will require financial document submission, site inspection, market analysis, and potentially more specific requirements based on the project. If you’d like more information about property evaluation, feel free to email <a href='mailto:investorrelations@tknism.io'>assetrelations@tknism.io</a>."
            },
            {
                "question" : "How long does due diligence and evaluation take?", 
                "answer" : "After submitting a request for your property, Tokenism will begin due diligence and contactyou within 48 hours.</br></br>The overall process, however, may happen as quickly or as long as it takes for the owner to provide necessary documentation. Typically the process lasts anywhere from 30 to 90 days."
            },
            {
                "question" : "What kind of properties does Tokenism list?", 
                "answer" : "Tokenism lists Commercial RE in seven categories <ul><li>Office</li><li>Multifamily</li><li>Mixed use</li><li>Hospitality</li><li>Retail</li><li>Warehouse</li><li>Industrial</li></ul>"
            },
            {
                "question" : "I am a developer. How can I get my project listed on the platform?", 
                "answer" : "Simply submit some basic information about yourself & your property by clicking the “Submit your Property” button found on the Tokenism Homepage. Our online listing questionnaire takes about 5 minutes to fill out. Someone from the Tokenism team will contact you within 48 hours of your submission. If you still need help figuring out to submit, check out this <a href='/how-it-works' target='_blank'>short video</a> or contact our support team."
            },
            {
                "question" : "Do I have to sell all of my asset or can I retain partial ownership?", 
                "answer" : "No. When the asset is tokenized the owner or developer can sell shares of the asset, while retaining some equity if desired."
            },
            {
                "question" : "How can I Tokenize my existing asset with investors?", 
                "answer" : "If your asset has existing investors there's no problem as long as they are accredited investors. They can easily be onboarded to our platform."
            },
            {
                "question" : "How can tokenization help my existing asset/project?", 
                "answer" : "There are a multitude of ways tokenization can help including:<ul><li>Increased liquidity</li><li>Elimination of costly intermediaries</li><li>A reduced time horizon </li><li>Potential for quicker and easier closing due to more buyers and smaller shares </li><li>Flexible entry and exit </li><li>Significantly lower listing fees</li><li>Robust property data and information</li><li>Increased security reducing the risk of fraud</li></ul>To learn more about the benefits of tokenizing your asset check out our <a href='/investing101'>blog</a>."
            },
            {
                "question" : "How long does an initial offering period last?", 
                "answer" : "We call this phase an Initial Offering period. That period typically lasts between 90 to 180 days or until the minimum goal of tokens sold is met. This, however, can vary. Oftentimes, a higher priced property or project may stay open longer, so there is a better chance to be successfully funded."
            },
            {
                "question" : "What happens after an offering reaches its goal?", 
                "answer" : "Once the minimum number of shares have been sold, the funds are released to Tokenism Management LLC. Tokenism Management performs a traditional closing and the asset is transferred to the SPV for the asset. The SPV is wholly owned by the token owners and Tokenism Management acts as on behalf  appoints new management for the asset."
            },
            {
                "question" : "What are the fees for selling on Tokenism?", 
                "answer" : "Tokenism is currently waiving the 3% sale price fee that we typically charge owners. However, we still require fixed due diligence fees that vary per asset. Approximate fees are: <br/> <img src='https://tknismtest.s3.amazonaws.com/non-compressed-images/csd.png'/>"
            },
            {
                "question" : "Who is responsible for all SEC offering compliance?", 
                "answer" : "Tokenism will do the necessary due diligence and registrations to ensure compliance before listing on the site and beginning the offering period."
            },
            {
                "question" : "How does Tokenism ensure compliance post offering launch?", 
                "answer" : "Will answer later "
            },
            {
                "question" : "Can the property owner market the sale?", 
                "answer" : "The sale is on a public platform and property owners can share the listing profile and market as they choose."
            },
            {
                "question" : "How can we tokenize a portfolio with multiple investors?", 
                "answer" : "If the investors in your portfolio are approved accredited investors they can easily be onboarded to our platform and your portfolio can be tokenized accordingly."
            },
            {
                "question" : "Is there a mechanism for offering redemptions in the form of tokens for assets being tokenized?", 
                "answer" : "There are no redemptions given to investors. Our secondary market trading ensures a stable cash flow for the project without the worry of redemptions. liquidity and eliminates the need for redemptions. secondary market trading which ensures a stable cash flow for the project without the worry of redemptions."
            },
            {
                "question" : "Who holds funds during development?", 
                "answer" : "During development, funds are held by Tokenism management LLC in an SPV that is created for the asset as soon as it is tokenized. Funds are released in tranches as the work progresses."
            },
            {
                "question" : "How do redemptions affect a project's financing?", 
                "answer" : "There are no redemptions given to investors. Our secondary market trading ensures a stable cash flow for the project without the worry of redemptions. liquidity and eliminates the need for redemptions. secondary market trading which ensures a stable cash flow for the project without the worry of redemptions."
            },
            {
                "question" : "How is funding released to a developer?", 
                "answer" : "In the case of a development project, Tokenism LLC releases funds in tranches directly linked to work completed and after verification via site inspection. Funds are held within the <a href='#' target='_blank'>SPV</a> account during this time."
            },
            {
                "question" : "What control mechanisms exist for development funds?", 
                "answer" : "We protect development funding by instituting tranches. Funds are only released after a certain percentage of materials or work is completed on site. We verify progress through inspections."
            },
            {
                "question" : "Will I be managing the asset even after selling it?", 
                "answer" : "After a sale, the SPV is wholly owned by the token owners and Tokenism Management will appoint new management for the asset. If your asset was previously owned or managed by an institutional industry expert, we will not take that management away from you unless you so choose. If the asset had no management or was not overseen by industry experts Tokenism will appoint new management for you."
            },
            {
                "question" : "Will I retain control of the development?", 
                "answer" : "Yes, you will be in full control of direction, construction, and completion of the development project."
            },
            {
                "question" : "Is there a minimum dollar amount or percentage the developer needs to participatein the capital stack of an asset being tokenized?", 
                "answer" : "Yes. Every developer has a certain percentage they will be required to invest in the project. This amount will vary and be based on factors like the experience of the developer, the project size, and current project stage."
            },
            {
                "question" : "What happens after the completion of the project?", 
                "answer" : "What happens after completion really depends on the type project. The asset, however, is still wholly owned by the token holders, and they will retain those ownership rights regardless."
            },
            {
                "question" : "Do I continue to own the asset in the development entity or is there a new entity required?", 
                "answer" : "A new entity will be required. We create an SPV for the asset as soon as it is tokenized."
            },
            {
                "question" : "Can anyone list a project?", 
                "answer" : "Anyone can list a project as long as it meets Tokenism’s standards and  due diligence process."
            },
            {
                "question" : "What are the sponsor experience requirements?", 
                "answer" : "To know more about sponsor experience requirements, please contact sales at <a href='mailto:sales@tknism.io'>sales@tknism.io</a>"
            },
            {
                "question" : "What are the various project sizes that can be listed?", 
                "answer" : "There is no size minimum or maximum for projects."
            },
            {
                "question" : "Can I list my fix and flip to raise funds? ", 
                "answer" : "No. Our platform is not for single family rentals. We list only commercial properties that fall under the categories of stable-income properties, redevelopment properties, and ground-up developments."
            },
            {
                "question" : "I am a first time developer. Can I list my project?", 
                "answer" : "Unfortunately, no. For development projects, a developer needs to meet certain criteria and have experience at length."
            },
            {
                "question" : "What geographic locations does Tokenism operate in?", 
                "answer" : "Tokenism currently operates and lists properties solely in the United States."
            },
            {
                "question" : "What countries does Tokenism operate in?", 
                "answer" : "Tokenism currently operates and lists properties solely in the United States."
            },
            {
                "question" : "What is Tokenism’s underwriting process?", 
                "answer" : "We undergo a thorough underwriting process before a property gets listed on our platformthat includes site inspection, appraisal, and potentially more specific requirements based on the project. If you’d like more information about our underwriting process, feel free to email <a href='mailto:assetrelations@tknism.io'>assetrelations@tknism.io</a>."
            },
            {
                "question" : "What is the Tokenism due diligence process?", 
                "answer" : "We undergo an extensive due diligence process before a property gets listed on our platform that includes document submission, site inspection, and potentially more specific requirements based on the project. If you’d like more information about our underwriting process, feel free to email <a href='mailto:assetrelations@tknism.io'>assetrelations@tknism.io</a>."
            },
            {
                "question" : "Who holds the deed to the asset?", 
                "answer" : "The SPV created for the asset will hold the deed."
            },
            {
                "question" : "How do you ensure each SPV owns the deed on its property?", 
                "answer" : "We perform a traditional closing when the minimum goal of shares sold is achieved."
            },
            {
                "question" : "What happens if the tenants do not pay rent?", 
                "answer" : "The management company appointed at the end of the sale will take care of day to day issues."
            },
            {
                "question" : "What happens if there is a major repair needed who foots the bill?", 
                "answer" : "Tokenism will maintain a capital reserve to address these problems."
            },
        ]
    },
    {
        "security" : [
            {
                "question" : "What information does Tokenism see?", 
                "answer" : "Tokenism handles personal information with the utmost care and seriousness. While we have access to and retain minimalistic information for each customer, we ensure its security and have partnered industry leaders like Acuant and Early IQ to keep your sensitive personal information safe without us having access to it."
            },
            {
                "question" : "What information does Tokenism retain?", 
                "answer" : "We retain minimalistic personal information that is stored in a secure, isolated server."
            },
            {
                "question" : "How secure is my information?", 
                "answer" : "Investor personal information is encrypted and stored in a secure vault away from business systems."
            },
            {
                "question" : "What is your privacy policy?", 
                "answer" : "We are General Data Protection Regulation (GDPR) and California Consumer Privacy Act (CCPA) compliant. To view our Privacy Policy click <a target='_blank' href='/privacy-policy' >here</a>."
            },
            {
                "question" : "What is 2FA?", 
                "answer" : "Two Factor Authentication, or 2FA, is an extra layer of protection used to ensure the security of online accounts beyond just a username and password."
            },
            {
                "question" : "What kinds of 2FA does Tokenism offer?", 
                "answer" : "We use a third-party wallet application, Fortmatic, that enables users to use a phone number or email address as authentication, without the need for extensions. We provide Google authentication and text authentication as our options for 2FA."
            },
            {
                "question" : "How do I enable 2FA?", 
                "answer" : "You can enable 2FA on your account by navigating to your account settings and clicking “Multi-factor Authentication”. Once there, you can enable the authentication method you prefer."
            },
            {
                "question" : "How do I disable 2FA?", 
                "answer" : "You can disable 2FA on your account by navigating to your account settings and clicking “Multi-Factor Authentication”. Once there, you can disable any authentication method(s) you no longer wish to use."
            },
            {
                "question" : "What if I lose access to my virtual key app or device?", 
                "answer" : "If you lose access or forget your key you will not lose your money. However you will need to prove that the account is yours through identity verification measures. If ownership is proven, we will burn your existing tokens and reissue you new ones. This is a lengthy process and would require extensive identity verification and/or legal documentation.  If you think you may have lost access to your account, please <a href='/contact-us' target='_blank' >contact Tokenism</a> for support."
            },
        ]
    }
]
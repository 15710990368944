import { connect } from 'react-redux';
import React, { Component } from 'react';
import { getCards, handleDwollaModal } from '../../../../../store/actions/Auth';
import { getBanks, getWires } from '../../../../../store/actions/Wallet';

class Main extends Component {
  constructor(props) {
    super(props)
    // props.getCards()
    props.getBanks()
    props.getWires()
  }

  render() {
    let { dwollaVerification, showBank } = this.props.dashHeadData;
    let { wires } = this.props;
    console.log('$$$wires', wires);
    return (
      <div className='payment-page'>
        <div className='title'>
          <h4>Select your payment method</h4>
        </div>
        {/*
                <div className='payment-box'>
                    <div className='icon-box'>
                        <figure><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/52.png" alt='' /></figure>
                    </div>
                    <div className='title-box'>
                        <h4>Debit/ Credit Card</h4>
                    </div>
                    <div className='confrom-payment'>
                        <a onClick={() => this.props.onSelectMethod('debitCard')} className='thm-btn btn-style-one'>Continue Payment</a>
                    </div>
                </div>
                */}
        {
          showBank &&
          <div className='payment-box'>
            <div className='icon-box'>
              <figure><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/53.png" alt='' /></figure>
            </div>
            <div className='title-box'>
              <h4>Bank Account</h4>
            </div>
            <div className='confrom-payment'>
              <a id="toBankAccount" onClick={() => dwollaVerification == "Active" ? this.props.onSelectMethod('bankAccount') : this.props.handleDwollaModal(true)} className='thm-btn btn-style-one'>Continue Payment</a>
            </div>
          </div>
        }

        <div className='payment-box'>
          <div className='icon-box'>
            <figure><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/53.png" alt='' /></figure>
          </div>
          <div className='title-box'>
            <h4>Wire Transfer</h4>
          </div>
          <div className='confrom-payment'>
            <a id="toBankAccount" onClick={() => this.props.onSelectMethod('wires')} className='thm-btn btn-style-one'>Continue Payment</a>
          </div>
        </div>


{/*
          <div className='payment-box'>
            <div className='icon-box'>
              <figure><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/54.png" alt='' /></figure>
            </div>
            <div className='title-box'>
              <h4>PayPal</h4>
            </div>
            <div className='confrom-payment'>
              <a id="toPaypal" onClick={() => this.props.onSelectMethod('paypal')} className='thm-btn btn-style-one'>Continue Payment</a>
            </div>
          </div>
        */}

      </div>
    );
  }
}

const mapDispatchToProps = { getCards, getBanks, handleDwollaModal, getWires };

const mapStateToProps = (state) => {
  return { userCards: state.Auth.userCards || [], dashHeadData: state.Auth.dashHeadData || {}, wires: state.Wallet.wires}
}
export default connect(mapStateToProps, mapDispatchToProps)(Main);

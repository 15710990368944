import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Redirect } from 'react-router';
import {
  logout,
} from "../../store/actions/Auth";
import EventBus from 'eventing-bus'
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import Footer from '../../components/Footer'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import PopOver  from './../../components/PopOver'
import {getGroupNotifications, viewAllNotifications} from "../../store/actions/Settings";
import moment from 'moment';
import DashHeader from "../../components/DashHeader";
import JavascriptTimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import ReactTimeAgo from 'react-time-ago'

JavascriptTimeAgo.addLocale(en)

class Notifications extends Component {

  constructor(props) {
    super(props);
    this.state = {
      limit :5
    };
    props.getGroupNotifications();
    props.viewAllNotifications();
  }

  onNotificationClick = (value) =>{
    if(value.method == "new-listing" || value.method == "counter-tokens"){
      setTimeout(() => {
        this.props.history.push("/dashboard/portfolio");
        setTimeout(() => EventBus.publish("tokensListed", "dealRoom"), 300)
      }, 500);
    }else if(value.method == "tkusd-withdraw"){
      setTimeout(() => {
        this.props.history.push("/dashboard/wallet");
        setTimeout(() => EventBus.publish("paymentWithdrawed", "DWHistory"), 300)
      }, 500);
    }else if(value.method == "tkusd-deposit"){
      setTimeout(() => {
        this.props.history.push("/dashboard/wallet");
        setTimeout(() => EventBus.publish("paymentDeposited", "DWHistory"), 300)
      }, 500);
    }else if(value.method == "buy-tokens"){
      setTimeout(() => {
        this.props.history.push("/dashboard/portfolio");
        setTimeout(() => EventBus.publish("tokensListed", "transactionHistory"), 300)
      }, 500);
    }else if(value.method == "counter-tokens"){
      setTimeout(() => {
        this.props.history.push("/dashboard/wallet");
        setTimeout(() => EventBus.publish("tokensListed", "dealRoom"), 300)
      }, 500);
    }else if(value.method == "accept-counter"){
      setTimeout(() => {
        this.props.history.push("/dashboard/portfolio");
        setTimeout(() => EventBus.publish("tokensListed", "transactionHistory"), 300)
      }, 500);
    }
  }

  render(){
    let { groupNotifications, allNotifications } = this.props;
    let today = new Date();
    return(
      <>
      <DashHeader {...this.props} onlyNav={true} />

        <section className="notifications">
          <div className="title">
            <div className="container">
              <h2>Notifications</h2>
            </div>
          </div>
          <div className="container">

              <div className="card">
                <span className="notification-time">New</span>
                  {groupNotifications["new"] && groupNotifications["new"].length > 0 ? groupNotifications["new"].map((notification) => (
                    <div className="notification">
                      <figure className="img">
                        <img src={notification.avatar} />
                      </figure>
                      <p className="line cursor-pointer" onClick={(value) => this.onNotificationClick(notification)}>{notification.message}</p>
                      <span className="time">{moment(notification.updatedAt).format('LT')}</span>
                      <PopOver {...notification}/>
                    </div>
                  )) :
                  <div className="notification">
                    <p className="line">No New Notification.</p>
                  </div>
                }
              </div>

            {
              groupNotifications["weeks"] && Object.keys(groupNotifications.weeks).map((key, index, z)=>
                {
                  let check = groupNotifications[key];
                  if(index < this.state.limit){

                    return<div className="card">
                      <span className="notification-time">{key == 'new' ? "New" : "Week " + key}</span>
                      {groupNotifications.weeks[key] && groupNotifications.weeks[key].map((notification) => (
                        <div className="notification">
                          <figure className="img">
                            <img src={notification.avatar} />
                          </figure>
                          <p className="line cursor-pointer" onClick={(value) => this.onNotificationClick(notification)}>{notification.message}</p>
                          <span className="time">{key == 'new' ? <ReactTimeAgo date={new Date(notification.updatedAt)}/> :moment(notification.updatedAt).format('ll')}</span>
                          <PopOver {...notification}/>
                        </div>
                      ))}
                    </div>
                  }

                })
              }
              {Object.keys(groupNotifications).length > 5 && <button className="view-more" onClick={() => this.setState({limit:1000})}><KeyboardArrowDownIcon />Load More</button>}

          </div>
        </section>
        <Footer />
        </>
    )
  }
}

const mapStateToProps = ({ Settings }) => {
  let { groupNotifications, allNotifications } = Settings;
  return { groupNotifications, allNotifications };
};

const mapDispatchToProps = {
  logout,
  getGroupNotifications,
  viewAllNotifications
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications)

import React, { Component } from 'react';
import { Link } from "react-router-dom";

// import FooterBackground from '../../theme/images/resrource/footer-bg.png'
class Footer extends Component
{
  constructor(){
    super()
    this.state = {
    }
  }

  render()
  {
    return (
        <footer className="footer">
            <div className="footer-content">
                <ul className="contact-details">
                    <li>
                        <a href="tel:+18442856476">
                            <span className="circle"><i className="fa fa-phone"></i></span>
                        </a>
                        <span>+1 844 285 6476</span>
                    </li>
                    <li>
                        <a href="mailto:info@tknism.io">
                            <span className="circle"><i className="fa fa-envelope"></i></span>
                        </a>
                        <span>info@tknism.io</span>
                    </li>
                    <li>
                        <a href="https://www.google.fr/maps/place/Rookwood+Exchange,+3825+Edwards+Rd+%23103,+Cincinnati,+OH+45209" target="_blank">
                            <span className="circle"><i className="fa fa-map-marker"></i></span>
                        </a>
                            <span>3825 Edwards Rd,<br/>#103, Cincinnati, OH 45209</span>
                    </li>
                </ul>
                <ul className="social-links">
                   <li>
                       <a href="https://twitter.com/Tokenismllc" className="circle" target="_blank">
                           <i className="fa fa-twitter"></i>
                        </a>
                   </li>
                    <li>
                        <a href="https://www.youtube.com/channel/UCQHC42tw1N2sBrJHPMyD3hQ" className="circle"  target="_blank">
                            <i className="fa fa-youtube"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.linkedin.com/company/tokenism/" className="circle"  target="_blank">
                            <i className="fa fa-linkedin"></i>
                        </a>
                    </li>
                </ul>
            </div>
            <figure className="figure">
                <img className="img" src="https://tknismtest.s3.amazonaws.com/compressedImages/footer-bg-min+2.png" alt="footer-img" />
            </figure>
            <div className="copy-right">
                    <p>
                    Tokenism, LLC (“Tokenism“) operates a website at <a href="https://tknism.io/" target="_blank" >tknism.io</a>. By using the Platform, you accept our <Link to="/terms-of-use" target="_blank" >Terms of use</Link> and <Link to="/privacy-policy" target="_blank">Privacy Policy</Link>.
                    </p>
                    <p><strong>©</strong> 2020 Tokenism, LLC. All Rights Reserved.</p>
            </div>
        </footer>
    );
  }
}

export default Footer;

import React, { Component } from 'react'

import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import Banner from '../../components/Banner'
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem'
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import CheckBox from './../../components/CheckBox/CheckBox'
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { contactUs } from './../../store/actions/Settings'
import { userSubscription } from './../../store/actions/Auth'
import { connect } from "react-redux";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';


class ContactUs extends Component{
    state = {
        name:"",
        email:"",
        phoneNo:"",
        subscriptionMail:"",
        contactType: "Support",
        issueType:'mail',
        message: "",
        allow:false,
        isAllow:false,
        receive:false,
        isReceive:false,
        accept:false,
        isAccept:false,
    }

    handelChange = (event) => {
         this.setState({[event.target.name]:event.target.value})
    }

    componentDidMount() {
        ValidatorForm.addValidationRule('isEmail', (value) => {
            const isValid = (/\S+@\S+\.\S+/).test(value)
            return isValid
          })
    }

    handelCheckBox = event => {
        this.setState({[event.target.name]:event.target.value})
        if(event.target.name == 'allow'){
            this.setState({isAllow:false})
        }else if(event.target.name == 'receive'){
            this.setState({isReceive:false})
        }else if(event.target.name == 'accept'){
            this.setState({isAccept:false})
        }
    }

    hangelContactUs = data => {
      const { allow, receive, accept } = this.state
      if(!allow) {
        this.setState({isAllow:true})
      }
      if(!receive) {
        this.setState({isReceive:true})
      }
      if(!accept) {
        this.setState({isAccept:true})
      }
      if(allow && receive && accept){
        this.props.contactUs({data:data, history:this.props.history})
        // this.setState({
        //   name:"",
        //   email:"",
        //   phoneNo:"",
        //   subscriptionMail:"",
        //   contactType: "Support",
        //   issueType:'mail',
        //   message: "",
        //   allow:false,
        //   isAllow:false,
        //   receive:false,
        //   isReceive:false,
        //   accept:false,
        //   isAccept:false,
        // })
      }
    }

    render(){
        const { fullName, name, email, phoneNo, contactType, subscriptionMail, issueType, message, accept, allow, receive, isAllow, isAccept, isReceive } = this.state
        const { handelChange, hangelContactUs, handelCheckBox } = this
        const { userSubscription } = this.props
        return (
            <div className="page-wrapper">
                <Navbar
                    page='help-center'
                    {...this.props}
                />
                <Banner
                    heading='Contact Us'
                />
                <div className="contact-us">
                    <div className="contact-form">
                        <div className="container">
                            <div className="card p-3 p-md-5">
                                <FormControl variant="outlined">
                                    <Select
                                        value={contactType}
                                        fullWidth
                                        onChange={handelChange}
                                        name="contactType"
                                    >
                                    <MenuItem value='Support'>Support</MenuItem>
                                    <MenuItem value='Sales'>Sales</MenuItem>
                                    <MenuItem value='General'>General</MenuItem>
                                    </Select>
                                </FormControl>
                                <ValidatorForm className="validatorForm my-3">
                                  <div className="row">
                                      <div className="col-md-6">
                                        <TextValidator
                                        className="MyTextField"
                                        placeholder="Full Name"
                                        name="name"
                                        type="text"
                                        margin="dense"
                                        variant="outlined"
                                        validators={['required']}
                                        value={name}
                                        onChange={handelChange}
                                        />
                                      </div>
                                      <div className="col-md-6">

                                        <TextValidator
                                          className="MyTextField"
                                          placeholder="Email"
                                          name="email"
                                          type="email"
                                          margin="dense"
                                          variant="outlined"
                                          validators={['required','isEmail']}
                                          errorMessages={['Email is required', 'Email is not valid']}
                                          value={email}
                                          onChange={handelChange}
                                          />
                                      </div>
                                      <div className="col-md-6 my-3">
                                        <TextValidator
                                          className="MyTextField"
                                          placeholder="Phone No"
                                          name="phoneNo"
                                          type="number"
                                          margin="dense"
                                          variant="outlined"
                                          validators={['required']}
                                          errorMessages={['Phone number is required']}
                                          value={phoneNo}
                                          onChange={handelChange}
                                          />
                                      </div>

                                      <div className="col-md-6 my-4">
                                        <FormControl variant="outlined">
                                          <Select
                                            value={issueType}
                                            fullWidth
                                            onChange={handelChange}
                                            name="issueType"
                                            >
                                            <MenuItem value='call'>Please give me a call</MenuItem>
                                            <MenuItem value='mail'>Please write me an email</MenuItem>
                                          </Select>
                                        </FormControl>
                                      </div>

                                  </div>
                                </ValidatorForm>

                                <TextareaAutosize
                                    className="mt-3"
                                    rowsMax={5}
                                    value={message}
                                    onChange={handelChange}
                                    name="message"
                                    rowsMin={5}
                                    aria-label="maximum height"
                                    placeholder="Enter your Message"
                                    />
                                <div className="pt-3">
                                    <p>Tokenism   is committed to protecting and respecting your privacy. We will only store and use your personal information to contact you.</p>
                                    <FormControlLabel
                                        control={
                                        <Checkbox
                                        onChange={handelCheckBox}
                                        name="allow"
                                        value={allow}
                                        />
                                        }
                                        label="I agree to allow Tokenism   to store and process my personal data."
                                    />
                                    {isAllow &&
                                        <small>Required</small>
                                    }
                                    <br/>
                                    <FormControlLabel
                                        control={
                                        <Checkbox
                                        onChange={handelCheckBox}
                                        name="receive"
                                        value={receive}
                                        />
                                        }
                                        label="I agree to receive email/telephonic communications from Tokenism."
                                    />
                                    {isReceive &&
                                        <small>Required</small>
                                    }
                                    <p>For more information on our privacy practices, and how we are committed to protecting and respecting your privacy, please review our Privacy Policy and tick the checkbox below.</p>
                                    <FormControlLabel
                                        control={
                                        <Checkbox
                                        onChange={handelCheckBox}
                                        name="accept"
                                        value={accept}
                                        />
                                        }
                                        label="I accept the Privacy Policy of Tokenism."
                                    />
                                    {isAccept &&
                                        <small>Required</small>
                                    }
                                    <button className="custom-btn py-2 px-5 mx-auto d-block" onClick={() => hangelContactUs(this.state)} >Send</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mail-to">
                        <div className="container">
                          <h2>Send Us an Email</h2>
                          <a href="mailto:info@tknism.io" className="custom-btn">info@tknism.io</a>
                       </div>
                    </div>
                    <div className="tkn-updates">
                      <div className="container">
                        <h2>Receive Tokenism Updates</h2>
                            <ValidatorForm className="validatorForm" onSubmit={() => userSubscription({email : subscriptionMail})}>
                            <TextValidator
                                value={subscriptionMail}
                                placeholder="Email"
                                name="subscriptionMail"
                                onChange={handelChange}
                                validators={['required', 'isEmail']}
                                variant="outlined"
                                errorMessages={['Email is required', 'Email is not valid']}
                                InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                          <button type="submit" className="custom-btn">Send</button>
                                      </InputAdornment>
                                    )
                              }}
                            />
                            </ValidatorForm>
                            <CheckBox
                                name="I consent to receive Tokenism  newsletters via email. I have read and accept the Privacy Policy about Data Protection and I am aware that data processing takes place at a service provider in the USA."
                            />
                      </div>
                    </div>
                    <div className="toll-free">
                        <div className="container">
                            <h2>Give Us a Call</h2>
                            <button className="custom-btn number"><i className="fa fa-phone"></i> +1 844 285 6476</button>
                            <h3 className="number"></h3>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

  const mapDispatchToProps = {
    contactUs,
    userSubscription
  };

export default connect(null, mapDispatchToProps)(ContactUs);

import { connect } from "react-redux";
import React, { Component } from 'react';

class ExistingAccount extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {
          selectedItem: "",
          selectedItemIndex: 0
        }
    }

    toggleClass = (id, item)=>
    {
        this.setState({ selectedItemIndex: id });
        this.props.selectedCard(item)
    }

    componentDidMount() {
      if(this.props.cardsData && this.props.cardsData.length > 0) this.toggleClass(0, this.props.cardsData[0])
    }


    render()
    {
        return (
            <div className="col-lg-6 col-md-12 col-12">
                <div className="title-area row">
                    <div className='col-md-1'>
                        <p onClick={() => this.props.onSelectMethod("none")}>
                            <i className="fa fa-arrow-left text-secondary" aria-hidden="true"/>
                        </p>
                    </div>
                    <div className='col-md-7'>
                        <h5 className='pt-1'>Your Cards</h5>
                    </div>

                </div>
                <div className="title-area row">
                    <div className='col-md-7'>
                        <label className='greyText' >Please Select Your Card</label>
                    </div>
                    <div className='col-md-4'>
                        <a href="#" className='pt-1' onClick={()=> this.props.addCard()}>
                            <i className="fa fa-plus"/>Add Account
                        </a>
                    </div>
                </div>
                {this.props.cardsData.length !== 0 ?
                    this.props.cardsData.map((item, index)=>
                    {
                        return (
                            <div
                                className={this.state.selectedItemIndex == index ? "card-information active" : "card-information"}
                                key={index}
                                onClick={() => this.toggleClass(item.index, item)} >
                                <div className="icon-box">
                                    <figure><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/51.png" alt="" /></figure>
                                </div>
                                <div className="text-area">
                                    <h4>{item.cardNumber.substr(0, 4)} **** **** {item.cardNumber.substr(item.cardNumber.length - 4)}</h4>
                                    <p>Card number</p>
                                </div>
                                <div className="detail-area">
                                    <a href="#">...</a>
                                </div>
                            </div>
                        )
                    })
                : null}

                {/* <div className="card-information active">
                    <div className="icon-box">
                        <figure><img src={require("../../../../../theme/images/icons/52.png")} alt="" /></figure>
                    </div>
                    <div className="text-area">
                        <h4>4670 ****  ****  9065   </h4>
                        <p>Card number</p>
                    </div>
                    <div className="detail-area">
                        <a href="#">...</a>
                    </div>
                </div> */}
            </div>
        );
    }
}
export default connect(null, null)(ExistingAccount);

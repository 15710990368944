import React, { Component } from 'react'

import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import { getSignData, getUserSignData } from '../../store/actions/Auth';
import { connect } from 'react-redux';
import InvestmentPlans from "../../DataFiles/InvestmentPlans";

class InvestmentPlan extends Component
{
  constructor()
  {
    super()
    this.state = {}
    window.scrollTo(0,0);
  }

  componentDidMount() {
    if (!this.props.location.state) this.props.history.push('/starter-packages');
  }

  setPlan =(e)=>{
    this.props.history.push({
      pathname: '/Sign-Up',
      state: { plan: e, package: this.props.location.state.package}
    })
  }

  render()
  {
    return (
      <div className="page-wrapper">
        <Navbar page= 'signup-process' {...this.props}/>
        <section className="tob-banner style-three" style={{backgroundColor: '#f1f2f7'}}>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="banner-where-area">
                  <h2>Select your investment plan</h2>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="investment-sign-up">
          <div className="container">
            <div className="row">
              <div className="row clearfix">
                <div className="col-10 col-lg-3 col-md-6 col-sm-10">
                  <div className="service-inner text-center">
                    <h3>{InvestmentPlans[0].heading}</h3>
                    <p>{InvestmentPlans[0].description}</p>
                    <div className="bottom-area-one">
                      <div className="row">
                        <div className="col-6 titles">
                          {InvestmentPlans[0].sub1}
                        </div>
                        <div className="col-6">
                          <div className="barContainer">
                            <div className="BarDivident"></div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 titles">
                          {InvestmentPlans[0].sub2}
                        </div>
                        <div className="col-6">
                          <div className="barContainer">
                            <div className="BarAppreciation"></div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 titles">
                          {InvestmentPlans[0].sub3}
                        </div>
                        <div className="col-6">
                          <div className="barContainer">
                            <div className="BarTotal"></div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 titles">
                          {InvestmentPlans[0].sub4}
                        </div>
                        <div className="col-6 titlesDetails">
                          {InvestmentPlans[0].risk}
                        </div>
                      </div>

                      <a href="#" onClick={() =>{this.setPlan('StableCash')}} className="select-btn thm-btn btn-style-one">Select</a>
                    </div>
                    <a href="#" className="round-btn"><i className="fa fa-angle-right"></i></a>
                  </div>
                </div>
                <div className="col-10 col-lg-3 col-md-6 col-sm-10">
                  <div className="service-inner text-center">
                    <h3>{InvestmentPlans[1].heading}</h3>
                    <p>{InvestmentPlans[1].description}</p>
                    <div className="bottom-area-two">
                      <div className="row">
                        <div className="col-6 titles">
                          {InvestmentPlans[1].sub1}
                        </div>
                        <div className="col-6">
                          <div className="barContainer">
                            <div className="BarDivident"></div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 titles">
                          {InvestmentPlans[1].sub2}
                        </div>
                        <div className="col-6">
                          <div className="barContainer">
                            <div className="BarAppreciation"></div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 titles">
                          {InvestmentPlans[1].sub3}
                        </div>
                        <div className="col-6">
                          <div className="barContainer">
                            <div className="BarTotal"></div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 titles">
                          {InvestmentPlans[1].sub4}
                        </div>
                        <div className="col-6 titlesDetails">
                          {InvestmentPlans[1].risk}
                        </div>
                      </div>
                      <a href="#" onClick={() =>{this.setPlan('MediumPortfolio')}} className="select-btn thm-btn btn-style-one">Select</a>
                    </div>
                    <a className="round-btn"><i className="fa fa-angle-right"></i></a>
                  </div>
                </div>
                <div className="col-10 col-lg-3 col-md-6 col-sm-10">
                  <div className="service-inner text-center">
                    <h3>{InvestmentPlans[2].heading}</h3>
                    <p>{InvestmentPlans[2].description}</p>
                    <div className="bottom-area-three">
                      <div className="row">
                        <div className="col-6 titles">
                          {InvestmentPlans[2].sub1}
                        </div>
                        <div className="col-6">
                          <div className="barContainer">
                            <div className="BarDivident"></div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 titles">
                          {InvestmentPlans[2].sub2}
                        </div>
                        <div className="col-6">
                          <div className="barContainer">
                            <div className="BarAppreciation"></div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 titles">
                          {InvestmentPlans[2].sub3}
                        </div>
                        <div className="col-6">
                          <div className="barContainer">
                            <div className="BarTotal"></div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 titles">
                          {InvestmentPlans[2].sub4}
                        </div>
                        <div className="col-6 titlesDetails">
                          {InvestmentPlans[2].risk}
                        </div>
                      </div>
                      <a href="#" onClick={() =>{this.setPlan('ShortTerm')}} className="select-btn thm-btn btn-style-one">Select</a>
                    </div>
                    <a className="round-btn"><i className="fa fa-angle-right"></i></a>
                  </div>
                </div>

                <div className="col-10 col-lg-3 col-md-6 ">
                  <div className="service-inner text-center">
                    <h3>{InvestmentPlans[3].heading}</h3>
                    <p>{InvestmentPlans[3].description}</p>
                    <div className="bottom-area-four">
                      <div className="row">
                        <div className="col-6 titles">
                          {InvestmentPlans[3].sub1}
                        </div>
                        <div className="col-6">
                          <div className="barContainer">
                            <div className="BarDivident"></div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 titles">
                          {InvestmentPlans[3].sub2}
                        </div>
                        <div className="col-6">
                          <div className="barContainer">
                            <div className="BarAppreciation"></div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 titles">
                          {InvestmentPlans[3].sub3}
                        </div>
                        <div className="col-6">
                          <div className="barContainer">
                            <div className="BarTotal"></div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 titles">
                          {InvestmentPlans[3].sub4}
                        </div>
                        <div className="col-6 titlesDetails">
                          {InvestmentPlans[3].risk}
                        </div>
                      </div>
                      <a onClick={() =>{this.setPlan('LongTerm')}} className="select-btn thm-btn btn-style-one">Select</a>
                    </div>
                    <a className="round-btn"><i className="fa fa-angle-right"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state)=>
{
  return {
    signData: state.Auth.signData
  }
}

const mapDispatchToProps= {getSignData, getUserSignData};
export default connect(mapStateToProps, mapDispatchToProps)(InvestmentPlan);

import Web3 from 'web3';
import axios from "axios";
import EventBus from 'eventing-bus';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import { Modal, ModalBody } from 'reactstrap';
import NumberFormat from 'react-number-format';
import Collapse from '@material-ui/core/Collapse';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import LastPageIcon from '@material-ui/icons/LastPage';
import FormControl from '@material-ui/core/FormControl';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import TableContainer from '@material-ui/core/TableContainer';
import InputAdornment from '@material-ui/core/InputAdornment';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import Loader from '../../../../components/AppLoader';
import { otpLoader, overlayLoader } from "../../../../store/actions/Settings";
import { web3, providerInject, providerDev, magic } from '../../../../store/web3';
import { TKUSDAddress, network, TKUSDABI } from '../../../../store/contract';
import { activeOffers, cancelContract, acceptCounter, rejectCounter, counterSeller, counterBuyer, cancelOffer } from '../../../../store/actions/Exchange';

class Activity extends Component {

  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      data: [],
      loader: false,
      isOpen: false,
      searchText: "",
      filteredArr: [],
      rowsPerPage: 10,
      counterValue: "",
      isCounterd: false,
      selectedOffer: {},
      message: 'Loading your Open Offers',
    }
    props.overlayLoader({ status: true, loadingMessage: 'Loading your Open Offers.' })
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.activeOffers();
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    if (nextProps !== prevState) {
      let { activeOffersData } = nextProps;
      if (activeOffersData) {
        return { filteredArr: activeOffersData, data: activeOffersData }
      }
    }
    else return null; // Triggers no change in the state
  }

  handelActions = (actionType, index) => {
    if (actionType == "counter") {
      this.props.activeOffersData['action'] = ["accept", "reject", "counter"];
      this.props.activeOffersData[index]['action'] = [];
      this.setState({ isCounterd: index });
    } else if (actionType == "accept") this.acceptOffer(index);
    else if (actionType == "reject") this.rejectOffer(index);
  }

  handelModal = (selectedOffer) => {
    this.setState({ selectedOffer: selectedOffer });
    if (this.state.isOpen) this.props.activeOffersData[this.state.isCounterd]['action'] = ["accept", "reject", "counter"]
    this.setState({ isOpen: !this.state.isOpen })
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage })
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
    this.setState({ page: 0 })
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  handleSearch = (val) => {
    this.setState({ [val.target.name]: val.target.value });
    let e = val.target.value;
    this.setState({ text: e });
    if (this.state.data) {
      this.setState({
        filteredArr: this.state.data.filter(item => {
          if (item.aboutProperty.propertyName) {
            if (
              item.aboutProperty.propertyName.toLowerCase().indexOf(e.toLowerCase()) > -1
            ) {
              return item;
            }
          }
        })
      });
    }
  }

  getNonce = () => {
    return new Promise(async (resolve, reject) => {
      try {
        let { data } = await axios.get(`/wallet/getNonce`);
        return resolve(data['body']);
      } catch (e) { reject(e) }
    })
  }

  acceptOffer = async (index) => {
    try {
      let offerData = this.props.activeOffersData[index];
      // this.props.otpLoader(true)
      // this.setState({ message: 'Please Sign Transaction to Accept this Offer' });
      if (localStorage.getItem('ETH-PROVIDER') == 'metamask') this.props.overlayLoader({ status: true, loadingMessage: 'Please Sign Transaction to Accept this Offer.' })
      else this.props.overlayLoader({ status: true, loadingMessage: 'Accepting Offer.' })

      let { exchangeContract, exchangeABI, type, aboutProperty, counterDetails } = offerData;

      if(localStorage.getItem('ETH-PROVIDER') == 'formatic'){
        const isLoggedIn = await magic.user.isLoggedIn();
        if(!isLoggedIn) {
          this.props.history.push('/');
          EventBus.publish('tokenExpired');
          return;
        }
      }

      let webDev = new Web3(providerDev);
      let web3Injected = new Web3(providerInject);
      let from = (await webDev.eth.getAccounts())[0];
      console.log('from', from);
      if (type === 'Sell') {
        console.log('in sell');
        let seller = (await web3Injected.currentProvider.enable())[0];
        const ExchangeInstance = new web3Injected.eth.Contract(exchangeABI, exchangeContract);
        // let acceptReceipt = await ExchangeInstance.methods.acceptCounter(counterDetails['buyerWallet']).send({ from: seller });
        console.log('before acceptCounterFeeSell');

        let acceptCounterFeeSell = (await ExchangeInstance.methods.acceptCounter(counterDetails['buyerWallet']).estimateGas({ from: seller })) * 2;
        console.log('acceptCounterFeeSell', acceptCounterFeeSell);
        let userGas = await web3Injected.eth.getBalance(from);
        if (userGas < acceptCounterFeeSell) {
          EventBus.publish("error", "Not enough gas to perform this transaction.");
          this.setState({ loading: false })
          return;
        }
        console.log('userGas', userGas);

        let acceptReceipt = await web3Injected.eth.sendTransaction({
          value: 0,
          gas: acceptCounterFeeSell,
          gasPrice: web3.utils.toHex(web3.utils.toWei('100', 'gwei')),
          from: seller,
          to: exchangeContract,
          data: ExchangeInstance.methods.acceptCounter(counterDetails['buyerWallet']).encodeABI(),
        });

        let data = {
          network,
          counterId: counterDetails['_id'],
          transactionHash: acceptReceipt['transactionHash'],
        }
        this.props.acceptCounter(data);
        // this.props.otpLoader(false)
        this.setState({ message: '' })
        // setTimeout(() => this.setState({ loader: false, message: '' }), 2000);

      } else if (type === 'Buy') {
        console.log('in sell');

        const buyer = (await web3Injected.currentProvider.enable())[0];

        let priceDiff = counterDetails['sellerPrice'] - counterDetails['buyerPrice'];
        let totalDiff = priceDiff * counterDetails['quantity'];
        // let fee = (totalDiff * offerData['fee']) / 100;
        // let buyerPayable = parseFloat(totalDiff + fee).toFixed(0);
        let buyerPayable = parseFloat(totalDiff).toFixed(0);

        let TKUSDData = new web3Injected.eth.Contract(TKUSDABI, TKUSDAddress);
        await TKUSDData.methods.approve(exchangeContract, buyerPayable).send({ from: buyer });

        const ExchangeInstance = new web3Injected.eth.Contract(exchangeABI, exchangeContract);
        // let acceptReceipt = await ExchangeInstance.methods.acceptCounter(counterDetails['buyerWallet']).send({ from: buyer });
        // let acceptCounterFeeBuy = (await ExchangeInstance.methods.acceptCounter(counterDetails['buyerWallet']).estimateGas({ from: buyer })) * 2;

        let acceptReceipt = await web3Injected.eth.sendTransaction({
          value: 0,
          // gas:acceptCounterFeeBuy,
          // gasPrice: web3.utils.toHex(web3.utils.toWei('100', 'gwei')),
          from: buyer,
          to: exchangeContract,
          data: ExchangeInstance.methods.acceptCounter(counterDetails['buyerWallet']).encodeABI(),
        });

        // const ExchangeInstance = new webDev.eth.Contract(exchangeABI, exchangeContract);
        // let acceptFee = (await ExchangeInstance.methods.acceptCounter(counterDetails['buyerWallet']).estimateGas({ from })) * 2;
        // let acceptReceipt = await ExchangeInstance.methods.acceptCounter(counterDetails['buyerWallet']).send({ from, gas: acceptFee });
        let data = {
          network,
          transactionHash: acceptReceipt['transactionHash'],
          counterId: counterDetails['_id']
        }
        this.props.acceptCounter(data);
        // this.props.otpLoader(false)
        this.setState({ message: '' })
        // setTimeout(() => this.setState({ loader: false, message: '' }), 2000);
      }
    } catch (e) {
      this.setState({ loader: false, message: '' });
      this.props.overlayLoader({ status: false, loadingMessage: '' })
      // this.props.otpLoader(false)
      Sentry.captureMessage(e['message'] + ' acceptOffer');
      EventBus.publish("error", e['message']);
      // EventBus.publish("error", `Error while Accepting Offer`);
    }
  }

  rejectOffer = async (index) => {
    try {
      let offerData = this.props.activeOffersData[index];
      // this.props.otpLoader(true)
      if (localStorage.getItem('ETH-PROVIDER') == 'metamask') this.props.overlayLoader({ status: true, loadingMessage: 'Please Sign Transaction to Reject this Offer.' })
      else this.props.overlayLoader({ status: true, loadingMessage: 'Rejecting Offer.' })

      // this.setState({ message: 'Please Sign Transaction to Reject this Offer' })
      // this.setState({ loader: true, message: 'Please Sign Transaction to Reject this Offer' });

      const isLoggedIn = await magic.user.isLoggedIn();
      if(!isLoggedIn) {
        this.props.history.push('/');
        EventBus.publish('tokenExpired');
        return;
      }

      let { exchangeContract, exchangeABI, counterDetails } = offerData;
      let from = (await web3.eth.getAccounts())[0];

      let web3Injected = new Web3(providerInject);
      let user = (await web3Injected.currentProvider.enable())[0];
      let ExchangeInstance = new web3Injected.eth.Contract(exchangeABI, exchangeContract);
      // let rejectReceipt = await ExchangeInstance.methods.rejectCounter(counterDetails['buyerWallet']).send({ from: user });
      let rejectFee = (await ExchangeInstance.methods.rejectCounter(counterDetails['buyerWallet']).estimateGas({ from })) * 2;

      let userGas = (await web3Injected.eth.getBalance(
        from));
      if (userGas < rejectFee) {
        EventBus.publish("error", "Not enough gas to perform this transaction.");
        this.setState({ loading: false })
        return;
      }

      let rejectReceipt = await web3Injected.eth.sendTransaction({
        value: 0,
        gas: rejectFee,
        gasPrice: web3.utils.toHex(web3.utils.toWei('100', 'gwei')),
        from: user,
        to: exchangeContract,
        data: ExchangeInstance.methods.rejectCounter(counterDetails['buyerWallet']).encodeABI(),
      });

      let data = {
        network,
        counterId: counterDetails['_id'],
        transactionHash: rejectReceipt['transactionHash'],
      }
      this.props.rejectCounter(data);
      // setTimeout(() => this.setState({ loader: false, message: '' }), 2000);
      // this.props.otpLoader(false)
      this.setState({ message: '' })

    } catch (e) {
      // this.props.otpLoader(false)
      this.props.overlayLoader({ status: false, loadingMessage: '' })
      this.setState({ message: '' })
      // this.setState({ loader: false, message: '' });
      Sentry.captureMessage(e['message'] + ' rejectOffer');

      EventBus.publish("error", e['message']);
      // EventBus.publish("error", `Error while Rejecting Offer`);
    }
  }

  submitCounter = async () => {
    try {
      // this.props.otpLoader(true)
      this.setState({ isOpen: !this.state.isOpen });

      if (localStorage.getItem('ETH-PROVIDER') == 'metamask') this.props.overlayLoader({ status: true, loadingMessage: 'Please Sign Transaction to place Counter Offer' })
      else this.props.overlayLoader({ status: true, loadingMessage: 'Placing Counter Offer' })

      let { counterValue } = this.state;
      let { exchangeContract, exchangeABI, type, counterDetails, fee } = this.state.selectedOffer;

      if(localStorage.getItem('ETH-PROVIDER') == 'formatic'){
        const isLoggedIn = await magic.user.isLoggedIn();
        if(!isLoggedIn) {
          this.props.history.push('/');
          EventBus.publish('tokenExpired');
          return;
        }
      }

      let webDev = new Web3(providerDev);
      let web3Injected = new Web3(providerInject);

      webDev.eth.handleRevert = true;
      web3Injected.eth.handleRevert = true;

      let from = (await webDev.eth.getAccounts())[0];

      if (type == 'Buy') {
        if (parseFloat(counterValue) <= parseFloat(counterDetails['buyerPrice'])) {
          this.props.otpLoader(false)
          this.setState({ message: '' })
          EventBus.publish("error", `Price must be greater than previous buy offer`);
          return;
        }

        if (parseFloat(counterValue) >= parseFloat(counterDetails['sellerPrice'])) {
          this.props.otpLoader(false)
          this.setState({ message: '' })
          EventBus.publish("error", `Price must be less than seller offer`);
          return;
        }

        if(localStorage.getItem('ETH-PROVIDER') == 'formatic'){
          const isLoggedIn = await magic.user.isLoggedIn();
          if(!isLoggedIn) {
            this.props.history.push('/');
            EventBus.publish('tokenExpired');
            return;
          }
        }

        let buyer = (await web3Injected.currentProvider.enable())[0];

        let priceDiff = counterValue - counterDetails['buyerPrice'];
        let totalDiff = priceDiff * counterDetails['quantity'];
        // let totalFee = (totalDiff * fee) / 100;
        // let buyerPayable = parseFloat(totalDiff + totalFee).toFixed(0);
        let buyerPayable = parseFloat(totalDiff).toFixed(0);

        let TKUSDData = new web3Injected.eth.Contract(TKUSDABI, TKUSDAddress);
        await TKUSDData.methods.approve(exchangeContract, buyerPayable).send({ from: buyer });

        let ExchangeInstance = new webDev.eth.Contract(exchangeABI, exchangeContract);

        let counterData = await ExchangeInstance.methods.counterOffers(counterDetails['buyerWallet']).call();
        if (counterData['counter'] >= 4) {
          this.props.otpLoader(false);
          this.setState({ message: '' });
          EventBus.publish("error", `No more counters left you can accept or reject this offer only`);
          return;
        }

        let counterBuyerFee = (await ExchangeInstance.methods.counterBuyer(counterValue, buyer).estimateGas({ from })) * 2;
        // let counterReceipt = await ExchangeInstance.methods.counterBuyer(counterValue, buyer).send({ from, gas: buyFee });

        let nonce = await this.getNonce();
        // let nonce = (await webDev.eth.getTransactionCount(from, 'pending'));
        let userGas = (await webDev.eth.getBalance(from));
        if (userGas < counterBuyerFee) {
          EventBus.publish("error", "Not enough gas to perform this transaction.");
          this.setState({ loading: false })
          return;
        }

        let counterReceipt = await webDev.eth.sendTransaction({
          from,
          nonce,
          value: 0,
          gas: counterBuyerFee,
          gasPrice: web3.utils.toHex(web3.utils.toWei('100', 'gwei')),
          to: exchangeContract,
          data: ExchangeInstance.methods.counterBuyer(webDev.utils.toHex(counterValue), buyer).encodeABI(),
        })

        let data = {
          network,
          counterId: counterDetails['_id'],
          transactionHash: counterReceipt['transactionHash'],
        }

        this.props.counterBuyer(data);
        // this.props.otpLoader(false)
        this.setState({ message: '' })
        // setTimeout(() => this.setState({ loader: false, message: '' }), 2000);
      } else {
        if (parseFloat(counterValue) <= parseFloat(counterDetails['buyerPrice'])) {
          this.props.otpLoader(false);
          this.setState({ message: '' });
          EventBus.publish("error", `Price must be greater than offered price`);
          return;
        }

        let seller = (await web3Injected.currentProvider.enable())[0];
        let ExchangeInstance = new web3Injected.eth.Contract(exchangeABI, exchangeContract);

        let counterData = await ExchangeInstance.methods.counterOffers(counterDetails['buyerWallet']).call();
        if (counterData['counter'] >= 4) {
          EventBus.publish("error", `No more counters left you can accept or reject this offer only`);
          this.props.otpLoader(false);
          this.setState({ message: '' });
          return;
        }

        if (counterData['sellPrice'] == 0) {
          if (parseFloat(counterValue) > parseFloat(await ExchangeInstance.methods.price().call())) {
            EventBus.publish('error', 'Price must be less than sell price you had set before');
            this.props.otpLoader(false);
            this.setState({ message: '' });
            return;
          }
        } else {
          if (parseFloat(counterValue) >= parseFloat(counterData['sellPrice'])) {
            EventBus.publish('error', 'Price must be less than sell price you had set before');
            this.props.otpLoader(false);
            this.setState({ message: '' });
            return;
          }
        }

        let sellerCounterFee = (await ExchangeInstance.methods.counterSeller(web3.utils.toHex(counterValue), counterDetails['buyerWallet']).estimateGas({ from: seller })) * 2;

        let userGas = (await web3Injected.eth.getBalance(seller));
        if (userGas < sellerCounterFee) {
          EventBus.publish("error", "Not enough gas to perform this transaction.");
          this.setState({ loading: false })
          return;
        }

        let counterReceipt = await web3Injected.eth.sendTransaction({
          value: 0,
          from: seller,
          to: exchangeContract,
          gas: sellerCounterFee,
          gasPrice: web3.utils.toHex(web3.utils.toWei('100', 'gwei')),
          data: ExchangeInstance.methods.counterSeller(web3.utils.toHex(counterValue), counterDetails['buyerWallet']).encodeABI(),
        })

        let data = {
          network,
          counterId: counterDetails['_id'],
          transactionHash: counterReceipt['transactionHash'],
        }

        this.props.counterSeller(data);
        // this.props.otpLoader(false)
        this.setState({ message: '' })
        // setTimeout(() => this.setState({ loader: false, message: '' }), 2000);
      }
    } catch (e) {
      // this.props.otpLoader(false)
      // this.setState({ message: '' })
      this.props.overlayLoader({ status: false, loadingMessage: '' })

      // this.setState({ loader: false, message: '' });
      Sentry.captureMessage(e['message'] + ' submitCounter');
      EventBus.publish("error", e['message']);
      // EventBus.publish("error", `Error while Placing Counter Offer`);
    }
  }

  cancelContract = async (offer) => {
    try {
      let { exchangeContract, exchangeABI, type } = offer;

      // this.props.otpLoader(true);
      // this.setState({ message: 'Please Sign Transaction to Cancel your Offer' });

      if (localStorage.getItem('ETH-PROVIDER') == 'metamask') this.props.overlayLoader({ status: true, loadingMessage: 'Please Sign Transaction to Cancel your Offer' })
      else this.props.overlayLoader({ status: true, loadingMessage: 'Cancelling Offer' });

      if (type !== 'Sell') {
        EventBus.publish("error", `Only Seller can Cancel Order`);
        return;
      }

      if(localStorage.getItem('ETH-PROVIDER') == 'formatic'){
        const isLoggedIn = await magic.user.isLoggedIn();
        if(!isLoggedIn) {
          this.props.history.push('/');
          EventBus.publish('tokenExpired');
          return;
        }
      }

      let web3Injected = new Web3(providerInject);
      web3Injected.eth.handleRevert = true;
      let from = (await web3Injected.currentProvider.enable())[0];
      let ExchangeInstance = new web3Injected.eth.Contract(exchangeABI, exchangeContract);
      let cancelFee = (await ExchangeInstance.methods.cancelContract().estimateGas({ from })) * 2;

      let userGas = (await web3Injected.eth.getBalance(from));
      if (userGas < cancelFee) {
        EventBus.publish("error", "Not enough gas to perform this transaction.");
        this.setState({ loading: false })
        return;
      }

      let cancelReceipt = await web3Injected.eth.sendTransaction({
        from,
        value: 0,
        gas: cancelFee,
        gasPrice: web3.utils.toHex(web3.utils.toWei('100', 'gwei')),
        to: exchangeContract,
        data: ExchangeInstance.methods.cancelContract().encodeABI(),
      })

      this.props.cancelContract({ id: offer['_id'], cancelHash: cancelReceipt['transactionHash'] });
      // this.props.otpLoader(false)
      this.setState({ message: '' })
      // setTimeout(() => this.setState({ loader: false, message: '' }), 2000);
    } catch (e) {
      // this.props.otpLoader(false)
      // this.setState({ message: '' })
      this.props.overlayLoader({ status: false, loadingMessage: '' })
      Sentry.captureMessage(e['message'] + ' cancelContract');
      // this.setState({ loader: false, message: '' });
      EventBus.publish("error", e['message']);
      // EventBus.publish("error", `Error while Canceling your listing`);
    }
  }

  cancelOffer = async (offer) => {
    try {
      let { exchangeContract, exchangeABI, type, counterDetails } = offer;

      if (localStorage.getItem('ETH-PROVIDER') == 'metamask') this.props.overlayLoader({ status: true, loadingMessage: 'Please Sign Transaction to Cancel your Offer' })
      else this.props.overlayLoader({ status: true, loadingMessage: 'Cancelling Offer' })

      if(localStorage.getItem('ETH-PROVIDER') == 'formatic'){
        const isLoggedIn = await magic.user.isLoggedIn();
        if(!isLoggedIn) {
          this.props.history.push('/');
          EventBus.publish('tokenExpired');
          return;
        }
      }

      let web3Injected = new Web3(providerInject);
      web3Injected.eth.handleRevert = true;
      let from = (await web3Injected.currentProvider.enable())[0];

      let ExchangeInstance = new web3Injected.eth.Contract(exchangeABI, exchangeContract);
      let userGas = (await web3Injected.eth.getBalance(from));

      if (type === 'Sell') {
        console.log(`*********This is Sell Offer = `,offer);

        let cancelFee = Math.round((await ExchangeInstance.methods.cancelSeller(counterDetails['buyerWallet']).estimateGas({ from })) * 1.2);

        if (userGas < cancelFee) {
          EventBus.publish("error", "Not enough gas to perform this transaction.");
          this.setState({ loading: false })
          return;
        }

        console.log(`***********Gas Estimate = ${cancelFee}`);
        let cancelReceipt = await web3Injected.eth.sendTransaction({
          from,
          value: 0,
          gas: cancelFee,
          to: exchangeContract,
          gasPrice: web3.utils.toHex(web3.utils.toWei('100', 'gwei')),
          data: ExchangeInstance.methods.cancelSeller(counterDetails['buyerWallet']).encodeABI(),
        });

        let data = {
          network,
          offerId: offer['_id'],
          transactionHash: cancelReceipt['transactionHash'],
        }
        this.props.cancelOffer(data);
        this.setState({ message: '' });
      } else if (type === 'Buy') {
        console.log(`*********This is Buy Offer = `, offer);
        let cancelFee = Math.round((await ExchangeInstance.methods.cancelBuyer().estimateGas({ from })) * 1.2);

        if (userGas < cancelFee) {
          EventBus.publish("error", "Not enough gas to perform this transaction.");
          this.setState({ loading: false })
          return;
        }

        console.log(`***********Gas Estimate = ${cancelFee}`);
        let cancelReceipt = await web3Injected.eth.sendTransaction({
          from,
          value: 0,
          gas: cancelFee,
          to: exchangeContract,
          gasPrice: web3.utils.toHex(web3.utils.toWei('100', 'gwei')),
          data: ExchangeInstance.methods.cancelBuyer().encodeABI(),
        });
        console.log(`***********cancelReceipt = `, cancelReceipt);

        let data = {
          network,
          offerId: offer['_id'],
          transactionHash: cancelReceipt['transactionHash'],
        }
        this.props.cancelOffer(data);
        this.setState({ message: '' });
      }
    } catch (e) {
      EventBus.publish("error", e['message']);
      Sentry.captureMessage(e['message'] + ' cancelContract');
      this.props.overlayLoader({ status: false, loadingMessage: '' });
    }
  }

  render() {
    let { activeOffersData, TKUSDBalance, showOtpLoader } = this.props;
    const { handelActions, handelModal, handleChange, cancelContract, handleSearch, cancelOffer } = this;
    const { counterValue, isOpen, searchText, loader, selectedOffer, message, data, filteredArr, rowsPerPage, page } = this.state
    console.log(filteredArr, 'this.props.activeOffersData');
    return (
      <>
        <TableContainer component={Paper} className="activity-tabel">
          {showOtpLoader || loader ? <Loader message={message} /> :
            <>
              <div className="activity-header">
                <div className="balance"><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/coins-icon.png" alt="" /> TOTAL BALANCE: <span>TKUSD <NumberFormat value={TKUSDBalance} displayType={'text'} thousandSeparator={true} /></span></div>
                <FormControl className="search-form" variant="outlined">
                  <OutlinedInput
                    id="outlined-adornment-amount"
                    value={searchText}
                    placeholder="Search"
                    name="searchText"
                    onChange={handleSearch}
                    startAdornment={<InputAdornment position="start"><i className="fa fa-search"></i></InputAdornment>}
                  />
                </FormControl>
              </div>
              <div className="scroll-div">
                <Table aria-label="collapsible table">
                  <TableHead className="mb-3">
                    <TableRow>
                      <TableCell align="center">Offer Detail</TableCell>
                      <TableCell align="center">Status</TableCell>
                      <TableCell align="center">Type</TableCell>
                      <TableCell align="center">Price</TableCell>
                      <TableCell align="center">Quantity</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>

                    {filteredArr.length === 0 ?
                      <TableRow>
                        <TableCell colSpan={6}>
                          <div className={'col-12 text-center mt-5'}>
                            <img
                              src="https://tknismtest.s3.amazonaws.com/non-compressed-images/47.png"
                              alt=""
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                      :
                      (rowsPerPage > 0
                        ? filteredArr.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : filteredArr
                      ).map((row, index) => (
                        <Row handelActions={handelActions} handleChange={handleChange} cancelOffer={cancelOffer} cancelContract={cancelContract} index={index} handelModal={handelModal} counterValue={counterValue} key={row.name} row={row} />
                      ))
                    }
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                  colSpan={3}
                  count={filteredArr.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  ActionsComponent={this.TablePaginationActions}
                />
                {/* Popup Modal Starts */}
                <Modal
                  modalClassName="modal-mini modal-info uploaded confirm-counter"
                  isOpen={isOpen}
                >
                  <div className="modal-header justify-content-center">
                    <div className="modal-profile">
                      <img className='modalLogoImg' src="https://tknismtest.s3.amazonaws.com/non-compressed-images/Logo0.png" alt="" />
                    </div>
                    <div className="right">
                      <img
                        onClick={handelModal}
                        className="modalLogoImg"
                        src="https://tknismtest.s3.amazonaws.com/non-compressed-images/close-icon.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <ModalBody>
                    <Table aria-label="table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center"></TableCell>
                          <TableCell align="center">Seller</TableCell>
                          <TableCell align="center">Property</TableCell>
                          <TableCell align="center">Fee</TableCell>
                          <TableCell align="center">Quantity</TableCell>
                          <TableCell align="center">Offer Price</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell align="center">
                            <img
                              style={{
                                width: '40px',
                                height: '40px',
                                margin: '0 10px 0 0',
                                borderRadius: '25px'
                              }}
                              src={selectedOffer['aboutProperty'] && selectedOffer['aboutProperty']['image']}
                              alt="Property Image"
                            />
                          </TableCell>
                          <TableCell align="center">{selectedOffer['aboutProperty'] && selectedOffer['aboutProperty']['fullName']}</TableCell>
                          <TableCell align="center">{selectedOffer['aboutProperty'] && selectedOffer['aboutProperty']['propertyName']}</TableCell>
                          <TableCell align="center">{(selectedOffer['quantity'] * counterValue) * (selectedOffer['fee'] / 100)}</TableCell>
                          <TableCell align="center">{selectedOffer['quantity']}</TableCell>
                          <TableCell align="center">{counterValue}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    <button id="confirmButton" className="custom-btn" onClick={this.submitCounter}>confirm</button>
                    <button id="cancelButton" className="btn" onClick={handelModal}>Cancel</button>
                  </ModalBody>
                </Modal>
              </div>
            </>
          }
        </TableContainer>
      </>
    );
  }
}

function Row(props) {
  const classes = useRowStyles();
  const [open, setOpen] = React.useState(false);
  const { row, handelActions, handleChange, cancelContract, cancelOffer, counterValue, index, handelModal } = props;

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell className="d-flex align-items-center" component="th" scope="row">
          {Array.isArray(row.history) && row.history.length > 0 &&
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowDownIcon /> : <ChevronRightIcon />}
            </IconButton>
          }
          <Link to={row['aboutProperty'] ? `/single-property?id=${row['aboutProperty']['_id']}` : '#'} style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
            <div className="about-propert ">
              <img src={row.aboutProperty.image} />
              <div>
                <span>{row.aboutProperty.name}</span>
                <h5>
                  {row.aboutProperty.propertyName}
                </h5>
              </div>
            </div>
          </Link>
        </TableCell>
        <TableCell align="left" className={row.status.toLowerCase()} >
          {row.status == "Open" && <img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/open-icon.png" alt="" />}
          {row.status == "Offered" && <img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/offered icon.png" alt="" />}
          {row.status == "Countered" && <img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/countered icon.png" alt="" />}
          {row.status}
        </TableCell>
        <TableCell align="center">{row.type}</TableCell>
        <TableCell align="center"><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/icons/49.png" alt="" /> {row.price}</TableCell>
        <TableCell align="center">{row.quantity}</TableCell>
        <TableCell align="center">
          {row.action.length == 0 ?
            <div>
              <FormControl variant="outlined">
                <OutlinedInput
                  max={12}
                  type="number"
                  margin="dense"
                  name="counterValue"
                  value={counterValue}
                  onChange={handleChange}

                />
              <button onClick={() => handelModal(row)} className="action-btn" id={`counterButton${index + 1}`}>
                  counter
                </button>
              </FormControl>
            </div>
            :
            <>
              {row['status'] === 'Open' ?
                <>
                  <button type='button' onClick={() => cancelContract(row)} className={`action-btn  reject`} id={`cancelButton${index + 1}`}>
                    Cancel
                  </button>
                </>
                :
                <>
                  {row.status == "Countered" &&
                    <button type='button' onClick={() => cancelOffer(row)} className={`action-btn reject`} id={`counteredCancelButton${index + 1}`}>
                      {'Cancel'}
                    </button>
                  }
                  {row.action.map(item => {
                    if((row.status == "Countered" && item != "reject") || row.status == "Offered" ) {
                      return (
                        <button id={`button${item}${index + 1}`} type='button' onClick={() => handelActions(item, index)} className={`action-btn  ${row.status == "Countered" ? "disabled-btn" : item}`}>
                          {item}
                        </button>
                    )}
                  })}
                </>
              }
            </>
          }
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box style={{ paddingBottom: 20, paddingTop: 20 }} margin={1}>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  {row.history.map((historyRow) => (
                    <TableRow key={historyRow.date}>
                      <TableCell align="center" component="th" scope="row">
                        {historyRow.date}
                      </TableCell>
                      <TableCell>{historyRow.offerStatus}</TableCell>
                      <TableCell></TableCell>
                      <TableCell align="center">{historyRow.amount}</TableCell>
                      <TableCell align="center">1</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

const TablePaginationActions = (props) => {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

const mapStateToProps = ({ Exchange, Settings }) => {
  let { showOtpLoader } = Settings;
  let { activeOffersData, TKUSDBalance } = Exchange;
  return { activeOffersData, TKUSDBalance, showOtpLoader };
};
const mapDispatchToProps = { cancelOffer, activeOffers, cancelContract, acceptCounter, rejectCounter, counterSeller, counterBuyer, otpLoader, overlayLoader };
export default connect(mapStateToProps, mapDispatchToProps)(Activity);

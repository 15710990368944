import { connect } from "react-redux";
import React, { Component, Fragment } from 'react';
// import './signUpSlider.css';

class SignUpSlider extends Component
{
    render()
    {
        let {step} = this.props.signData.info ? this.props.signData.info : this.props.signData;
        if(!step) step =1;
        // let step =2
        return (
            <Fragment>
                <section className="tob-banner-SignUp">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="signup-setup">
                                    <ul>
                                          <li className={step === 1 ? "active" : step > 1 ? "complete"  : 'panding'}>
                                            <i className="complete"><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/info-active.png" alt="" /></i>
                                            <i className="active"><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/info-active.png" alt="" /></i>
                                            <i className="panding"><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/info-pending.png" alt="" /></i>
                                            <img alt="" src={step === 1
                                                ? "https://tknismtest.s3.amazonaws.com/non-compressed-images/active-tick.jpg"
                                                : step < 1 ? "https://tknismtest.s3.amazonaws.com/non-compressed-images/33.png"
                                                : "https://tknismtest.s3.amazonaws.com/non-compressed-images/active-tick.jpg"}
                                            />

                                            <span>Basic Info</span>
                                        </li>
                                        <li className={step === 2 ? "active" : step > 2 ? "complete"  : 'panding'}>
                                            <i className="complete"><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/kyc-active.png" alt="" /></i>
                                            <i className="active"><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/kyc-active.png" alt="" /></i>
                                            <i className="panding"><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/kyc-pending.png" alt="" /></i>
                                            <i><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/34.png" alt="" /></i>
                                            <img alt="" src={step === 2
                                                ? "https://tknismtest.s3.amazonaws.com/non-compressed-images/32.png"
                                                : step < 2 ? "https://tknismtest.s3.amazonaws.com/non-compressed-images/33.png"
                                                : "https://tknismtest.s3.amazonaws.com/non-compressed-images/32.png"}
                                            />

                                            <span>Contact Info</span>
                                        </li>
                                        <li className={step === 3 ? "active" : step > 3 ? "complete"  : 'panding'}>
                                            <i className="complete"><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/44.png" alt="" /></i>
                                            <i className="active"><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/44.png" alt="" /></i>
                                            <i className="panding"><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/45.png" alt="" /></i>
                                            <i><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/34.png" alt="" /></i>
                                            <img alt="" src={step === 3
                                                ? "https://tknismtest.s3.amazonaws.com/non-compressed-images/32.png"
                                                : step < 3 ? "https://tknismtest.s3.amazonaws.com/non-compressed-images/33.png"
                                                : "https://tknismtest.s3.amazonaws.com/non-compressed-images/32.png"}
                                            />
                                            <span>Account Details </span>
                                        </li>
                                        <li className={step === 4 ? "active" : step > 4 ? "complete hand-icon"  : 'panding hand-icon'}>
                                            <i className="complete"><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/agreement-active.png" alt="" /></i>
                                            <i className="active"><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/agreement-active.png" alt="" /></i>
                                            <i className="panding"><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/agreement-pending.png" alt="" /></i>
                                            <i><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/34.png" alt="" /></i>
                                            <img alt="" src={step === 4
                                                ? "https://tknismtest.s3.amazonaws.com/non-compressed-images/32.png"
                                                : step < 4 ? "https://tknismtest.s3.amazonaws.com/non-compressed-images/33.png"
                                                : "https://tknismtest.s3.amazonaws.com/non-compressed-images/32.png"}
                                            />

                                            <span>Agreements</span>
                                        </li>
                                        <li className={step === 5 ? "active" : step > 5 ? "complete"  : 'panding'}>
                                            <i className="complete"><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/finish-active.png" alt="" /></i>
                                            <i className="active"><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/finish-active.png" alt="" /></i>
                                            <i className="panding"><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/finish-pending.png" alt="" /></i>
                                            <i><img src="https://tknismtest.s3.amazonaws.com/non-compressed-images/34.png" alt="" /></i>
                                            <img alt="" src={step === 5
                                                ? "https://tknismtest.s3.amazonaws.com/non-compressed-images/32.png"
                                                : step < 5 ? "https://tknismtest.s3.amazonaws.com/non-compressed-images/33.png"
                                                : "https://tknismtest.s3.amazonaws.com/non-compressed-images/32.png"}
                                            />

                                            <span>Review Info</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Fragment>
        );
    }
}

const mapStateToProps = ({Auth})=>
{
    let { signData } = Auth;
    return { signData }
}

export default connect(mapStateToProps, null)(SignUpSlider);
